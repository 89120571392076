// Customizable Area Start
import {
    Box, 
    InputLabel,
    withStyles,
    Dialog,
    Button, 
    Grid, 
    Chip, 
    MenuProps, 
    Tooltip, 
    TextField,
    Checkbox
} from "@material-ui/core";
import moment from "moment";
import { styled } from '@material-ui/styles';
import React, { Component } from 'react';
import ProjectText from './ProjectText';
import LandingPageTextField, { ProjectTextField, OptionForSelect, AutocompleteStyle } from './ProjectTextField';
import { getStorageData, setStorageData } from 'framework/src/Utilities';
const close = require("./close.png");
const add = require("./add.png");
const remove = require("./remove.png");
const add_black = require("./add_black.png")
const orangeSucessIcon = require("./orangeSuccessTick.png");
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../framework/src/Messages/MessageEnum";
import { Message } from "../../framework/src/Message";
import { IBlock } from "../../framework/src/IBlock";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Formik, FormikErrors, FormikTouched } from "formik";
import * as Yup from "yup";
import { ContainedButton } from "./GlobalButton";
import { Delete } from "@material-ui/icons";
import Spinner from "./ProjectLoader";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Theme } from "@material-ui/core/styles";
import { AutocompleteRenderOptionState } from "@material-ui/lab/Autocomplete";
import { toast } from "react-toastify";
import { SectionLIstingWrapper } from "./AddListing";




// Customizable Area End

// Customizable Area Start
export interface BuyerToggelProps extends RouteComponentProps {
    buyerRequestApiCall?:any;
    apiCall?:boolean
}

interface PortData {
    id: string;
    type: string;
    attributes: {
      port_code: string;
      port_description: string;
      port_region: string;
      enabled: boolean;
      port_country: string[];
    };
  }

interface BUyerToggleState {
    switch: boolean;
    buyerRequestSnackbarOpen: boolean,
    show: boolean,
    addBuyerListing: boolean,
    reinitialisedForm: boolean,
    authToken: string,
    isMatchesFound: boolean;
    buyerAddedListingID: string;
    buyerListingAdded: boolean;
    buyerLoginData: any;
    selectedContainer: any;
    isAddContainerClicked: boolean;
    buyerListings: any;
    buyerContainerData: any;
    buyersPriceAndTermData: any;
    buyerPrinicpalOrgList: any;
    buyerPolData: any,
    buyerEtaDate: string,
    selectedBuyerContainerOneSize: any,
    triggerEditForm: boolean,
    anchorElDropdown: any,
    isEditContainerClicked: boolean,
    buyerEtbDate: string,
    buyerFilter: {
        listingID: Array<{ label: string; isChecked: boolean }>;
        pol: Array<{ label: string; isChecked: boolean }>;
        pod: Array<{ label: string; isChecked: boolean }>;
        type: Array<{ label: string; isChecked: boolean }>;
        startDate: string;
        endDate: string
    };
    loading: boolean,
    valuein: string,
    newPOLTerminalValue: any,
    newPODTerminalValue: any,

}
// Customizable Area End
class BuyerToggelComponent extends Component<BuyerToggelProps, BUyerToggleState> {
    // Customizable Area Start
    subScribedMessages: string[];
    // Customizable Area End
    constructor(props: BuyerToggelProps) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.state = {
            switch: true,
            buyerRequestSnackbarOpen: false,
            show: false,
            addBuyerListing: false,
            reinitialisedForm: false,
            authToken: "",
            isMatchesFound: false,
            buyerAddedListingID: "",
            buyerListingAdded: false,
            buyerLoginData: {},
            selectedContainer: [],
            isAddContainerClicked: false,
            buyerListings: [],
            buyerContainerData: [],
            buyersPriceAndTermData: [],
            buyerPrinicpalOrgList: [],
            buyerPolData: [],
            buyerEtaDate: "",
            selectedBuyerContainerOneSize: "",
            triggerEditForm: false,
            anchorElDropdown: null,
            isEditContainerClicked: false,
            buyerEtbDate: "",
            buyerFilter: {
                listingID: [],
                pol: [],
                pod: [],
                type: [],
                startDate: '',
                endDate: ""
            },
            loading: true,
            valuein: 'All',
            newPOLTerminalValue: null,
            newPODTerminalValue: null,
        };
        runEngine.attachBuildingBlock(this as unknown as IBlock, this.subScribedMessages);
        // Customizable Area End
    }
    // Customizable Area Start
    onRoleSwitchAPICallID: string = "";
    createBuyerListingApiCallID: string = "";
    getBuyerListingDataApiCallID: string = "";
    getBuyerContainerListApiCallID: string = "";
    getBuyersPolData: string = "";
    getBuyersPriceAndTearmsDataApiCallID: string = "";
    getBuyerPrincipalOrganisationDataApiCallID: string = "";

    async componentDidMount() {
        let buyerLoginData = await getStorageData("loginData");
        let LocationToken = await getStorageData("authToken");
        let obj = JSON.parse(buyerLoginData)
        this.setState({ buyerLoginData: obj })
        this.OnloadApicall(LocationToken)
    }

    OnloadApicall = (LocationToken: string) => {
        this.setState({ authToken: LocationToken });
        this.getBuyerListingData(LocationToken);
        this.getBuyerPrincipalOrganisation(LocationToken);
        this.getBuyerContainerList(LocationToken);
        this.getBuyersPolDataFunc(LocationToken)
        this.getBuyersPriceAndTerms()
    };

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        switch (apiRequestCallId) {
            case this.createBuyerListingApiCallID:
                if (responseJson.message || responseJson[0].message) {
                    this.setState({ buyerAddedListingID: responseJson?.request?.request_uid ?? responseJson[0]?.request?.request_uid, addBuyerListing: false, buyerListingAdded: true, selectedContainer: {}, isAddContainerClicked: false, loading: false });
                    this.getBuyerListingData(this.state.buyerLoginData.token);
                    if (responseJson[0].message === "Matches found and offer created ") { this.setState({ isMatchesFound: true, buyerAddedListingID: responseJson[0].request.request_uid}) } else { this.setState({ isMatchesFound: false }) }
                }
                break;
            case this.getBuyerListingDataApiCallID:
                this.setState({ buyerListings: responseJson, loading: false });
                break;
            case this.getBuyerPrincipalOrganisationDataApiCallID:
                this.setState({ buyerPrinicpalOrgList: responseJson?.data });
                break;
            case this.getBuyerContainerListApiCallID:
                const Data = responseJson.data?.map((item: {
                    attributes: {
                        container_type: string;
                        request_box_sizes: Array<string>;
                        codes: Array<string>;
                    };
                }) => {
                    return {
                        buyerContainer_type: item.attributes.container_type,
                        buyerBox_sizes: item.attributes.request_box_sizes,
                        buyerCodes: item.attributes.codes,
                    };
                });
                this.setState({ buyerContainerData: Data });
                break;
            case this.getBuyersPolData:
                if(responseJson?.data){
                    const uniquePorts = responseJson?.data?.filter((port : PortData, index : number, self : PortData[]) => {
                    return index === self.findIndex((p) => p.attributes.port_code === port.attributes.port_code);
                    }).map((port : PortData) => {
                    const uniqueCountries = [...new Set(port.attributes.port_country)];return { ...port, attributes: { ...port.attributes, port_country: uniqueCountries } };});
                    this.setState({ buyerPolData: uniquePorts });
                } else {this.setState({ buyerPolData: [] });}
                break;
            case this.getBuyersPriceAndTearmsDataApiCallID:
                let catachPriceandtermsdata = responseJson?.data.map((item: {
                    id: string, attributes: {
                        box_size: string,
                        container_type: string, id: number
                        port_of_destination: string,
                        port_of_location: string
                        price_and_currency: string,
                        terms: string
                    }
                }) => {
                    return {
                        id: item.id,
                        BoxSize: item.attributes.box_size,
                        Container: item.attributes.container_type,
                        PortOfDestination: item.attributes.port_of_destination,
                        PortOfLocation: item.attributes.port_of_location,
                        Rate: item.attributes.price_and_currency,
                        TermsOne: item.attributes.terms.split('/')[0],
                        TermsTwo: item.attributes.terms.split('/')[1],
                    }
                })

                this.setState({ buyersPriceAndTermData: catachPriceandtermsdata });
                break;
            default:
                break;
        }

        this.onReceiveRoleData(apiRequestCallId, responseJson)
    }


    getBuyerPrincipalOrganisation = (token: string) => {

        const header = {
            "Content-Type": "application/json",
            token: token,
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.getBuyerPrincipalOrganisationDataApiCallID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_profile/principal_organizations"
        );

        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));


        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getBuyerListingData = (token: string) => {
        const header = {
            "Content-Type": "application/json", token: token,
        };


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getBuyerListingDataApiCallID = requestMessage.messageId;


        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_listdemandpickflow/pending_requests"
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage), "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    getBuyerContainerList = (token: string) => {
        const header = { "Content-Type": "application/json", token: token, };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getBuyerContainerListApiCallID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_content_management/container_managements");

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    getBuyersPolDataFunc = (token: string) => {
        const header = {
            "Content-Type": "application/json",
            token: token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getBuyersPolData = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_content_management/ports"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    getBuyersPriceAndTerms = async () => {
        let token = await getStorageData("authToken");
        const header = {
            "Content-Type": "application/json",
            token: token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getBuyersPriceAndTearmsDataApiCallID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_admin/price_managements`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    onFilteredGetDataForListing = (responseJson: any) => {
        let temp = {
            listingID: responseJson?.map(
                (val: any) => val.request.data.attributes.request_uid
            ),
            pol: responseJson?.map((val: any) => val.request.data.attributes.pol),
            pod: responseJson?.map((val: any) => val.request.data.attributes.pod),
            type: responseJson?.map(
                (val: any) => val?.container?.container_type
            ),
            startDate: responseJson?.map(
                (val: any) => val.request.data.attributes.container_loading_range_start
            ),
            endDate: responseJson?.map(
                (val: any) => val.request.data.attributes.container_loading_range_end
            ),
        };
        let d1 = [...new Set(temp.listingID)]
        let d2 = [...new Set(temp.pol)]
        let d3 = [...new Set(temp.pod)]
        let d4 = [...new Set(temp.type)]
        let arrayData = {
            listingID: d1?.map((str: any) => ({
                label: str,
                isChecked: false,
            })),
            pol: d2?.map((str: any) => ({ label: str, isChecked: false })),
            pod: d3?.map((str: any) => ({ label: str, isChecked: false })),
            type: d4?.map((str: any) => ({
                label: str,
                isChecked: false,
            })),
            startDate: "",
            endDate: "",
        };

        this.setState({
            buyerFilter: arrayData,
        });
    }
    spinnLoader = () => {
        if (this.state.loading) {
          return <Spinner spinnerModal={this.state.loading} />
        }
      }

    onReceiveRoleData = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.onRoleSwitchAPICallID) {
            if (!responseJson.message) {
                this.setState({ buyerRequestSnackbarOpen: true });
            }
            else {
                setStorageData("UserRole", "seller")
                this.props.history.push("SellerListing");
            }
        }
    }
    onBuyerRequestRoleSwitch = async () => {
        let authToken = await getStorageData("authToken");
        const header = {
            "Content-Type": "application/json",
            token: authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.onRoleSwitchAPICallID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/account_block/accounts/switch_role`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "PUT"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    NewBuyerListingSchema = () => {
        return Yup.object().shape({
            ...(this.state.buyerPrinicpalOrgList?.length !== 0 && {
                BUYERPRINCIPALORGANISATION: Yup.string().required("Principal Organisation Required"),
            }),
            BUYERPOD: Yup.string().required("POD Required"),
            PODTERMINAL: Yup.string().required("POD Terminal Required"),
            POL: Yup.string().required("POL Required"),
            POLTERMINAL: Yup.string().required("POL Terminal Required"),
            ETBATPOL: Yup.string().required("Container Loading Range (From) Required"),
            ETAATPOD: Yup.string().required("Container Loading Range (To) Required"),

            CONTAINERTYPE: Yup.string().required("Container Status Required"),
            CONTAINERCODES: Yup.array().required("Container Type Required"),
            CONTAINERSIZE: Yup.string().required("Container Size  Required"),

            AVERAGETONSBOX: Yup.number().typeError('Must Be Number').required("AVG. Tons/Container Required").min(1, 'Tons/Container Must Be Greater Than Zero'),
            TOTALQUANTITY: Yup.number().typeError('Must Be Number').required("Quantity Required").min(1, 'Quantity Must Be Greater Than Zero'),
            RATE: Yup.number().typeError('Must Be Number').required("Buyer Price Required").min(1, 'Buyer Price Must Be Greater Than Zero'),
            TERMS: Yup.string().required("Terms Required"),
            TERMSTWO: Yup.string().required("Terms Required"),

            ...(this.state.isAddContainerClicked && {
                CONTAINERSIZETWO: Yup.string().required("Container Size  Required"),
                AVERAGETONSBOXTWO: Yup.number().typeError('Must Be Number').required("AVG. Tons/Container Required").min(1, 'Tons/Container Must Be Greater Than Zero'),
                TOTALQUANTITYTWO: Yup.number().typeError('Must Be Number').required("Quantity Required").min(1, 'Quantity Must Be Greater Than Zero'),
                RATETWO: Yup.number().typeError('Must Be Number').required("Buyer Price Required").min(1, 'Buyer Price Must Be Greater Than Zero'),
                TERMSTHREE: Yup.string().required("Terms Required"),
                TERMSFOUR: Yup.string().required("Terms Required"),
            }),
        });
    };
    createBuyerListing = (values: {
        BUYERPRINCIPALORGANISATION: string;
        BUYERPOD: string;
        PODTERMINAL: string;
        POL: string;
        POLTERMINAL: string;
        ETBATPOL: string;
        ETAATPOD: string;
        TOTALQUANTITY: string;
        CONTAINERTYPE: string;
        CONTAINERCODES: string[];
        CONTAINERSIZE: string;
        AVERAGETONSBOX: any;
        RATE: string;
        TERMS: string;
        TERMSTWO: string;

        CONTAINERSIZETWO: string;
        TOTALQUANTITYTWO: string;
        AVERAGETONSBOXTWO: any;
        RATETWO: string;
        TERMSTHREE: string;
        TERMSFOUR: string;
    }) => {
        const header = {
            "Content-Type": "application/json",
            token: this.state.authToken,
        };
        this.setState({
            loading: true
        })

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );


        interface ContainerDetail {
            container_size: string;
            quantity: string;
            tons_per_teu_extra: number;
            desired_rates: string;
            terms: string;

        }

        const createContainerDetail = (
            size: string,
            quantity: string,
            tonsTeu: number,
            rate: string,
            terms: string,
            termsTwo: string,
        ): ContainerDetail => ({
            container_size: size,
            quantity: quantity,
            tons_per_teu_extra : tonsTeu,
            desired_rates: rate,
            terms: `${terms} / ${termsTwo}`,
        });


        const httpBody = {
            request: {
                principal_organization_name: values.BUYERPRINCIPALORGANISATION,
                pol: values.POL,
                pol_terminal: values.POLTERMINAL,
                pod: values.BUYERPOD,
                pod_terminal: values.PODTERMINAL,
                container_loading_range_start: values.ETBATPOL,
                container_loading_range_end: values.ETAATPOD
            },
            container: {
                container_type: values.CONTAINERTYPE,
                codes: values.CONTAINERCODES.map(data => data.trim()),
            },
            container_details: [
                createContainerDetail(
                    values.CONTAINERSIZE,
                    values.TOTALQUANTITY,
                    Number(values.AVERAGETONSBOX),
                    values.RATE,
                    values.TERMS,
                    values.TERMSTWO,
                ),
            ]
        }

        if (values.CONTAINERSIZETWO && values.TOTALQUANTITYTWO && values.AVERAGETONSBOXTWO && values.RATETWO && values.TERMSTHREE && values.TERMSFOUR) {
            httpBody.container_details.push(
                createContainerDetail(
                    values.CONTAINERSIZETWO,
                    values.TOTALQUANTITYTWO,
                    Number(values.AVERAGETONSBOXTWO),
                    values.RATETWO,
                    values.TERMSTHREE,
                    values.TERMSFOUR,
                )
            );
        }

        this.createBuyerListingApiCallID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_listdemandpickflow/pending_requests"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleClose = () => {
        this.setState({
            show: false, selectedContainer: {},
            isAddContainerClicked: false
        })
    }
    onSelectBuyerContainer = (e: any) => {
        let temp = this.state.buyerContainerData.filter(
            (data: any) => data.buyerContainer_type === e.target.value
        );

        this.setState({ selectedContainer: temp[0] });
    };

    renderCommanFieldState = (setFieldValue: (
        field: string,
        value: string,
        shouldValidate?: boolean | undefined
    ) => void,) => {
        setFieldValue("RATE", "")
        setFieldValue('TERMS', "");
        setFieldValue('TERMSTWO', "");

        setFieldValue("RATETWO", "")
        setFieldValue('TERMSTHREE', "");
        setFieldValue('TERMSFOUR', "");
    }

    getAddFilterData = (containerType: string, pod: string, pol: string, BoxSize: string, setFieldValue: (
        field: string,
        value: string,
        shouldValidate?: boolean | undefined
    ) => void) => {
        let filterData = this.state.buyersPriceAndTermData.filter((ItemData: { Container: string, PortOfDestination: string, PortOfLocation: string, BoxSize: string }) => {
            if (ItemData.Container.includes(containerType) && ItemData.PortOfDestination.includes(pod) && ItemData.PortOfLocation.includes(pol) && ItemData.BoxSize.includes(BoxSize)) {
                return ItemData
            }
        })

        setFieldValue("RATE", filterData?.[0]?.Rate || "")
        setFieldValue('TERMS', filterData?.[0]?.TermsOne || "");
        setFieldValue('TERMSTWO', filterData?.[0]?.TermsTwo || "");
    }

    AddBuyerListingFormTextFieldChange = (
        e:
            | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            | React.ChangeEvent<{
                value: any;
            }>,
        setFieldValue: (
            field: string,
            value: string,
            shouldValidate?: boolean | undefined
        ) => void,
        keyname: string,
        value: any,
    ) => {
        if (keyname === "CONTAINERTYPE") {
            setFieldValue("CONTAINERCODES", [] as never)
            setFieldValue(`${keyname}`, `${e.target.value}`)
            this.onSelectBuyerContainer(e);
            setFieldValue("CONTAINERSIZE", "")
            setFieldValue("CONTAINERSIZETWO", "")

            this.renderCommanFieldState(setFieldValue)
        }
        if (keyname === "CONTAINERCODES") {
            let event = e.target.value
            setFieldValue("CONTAINERCODES", event);
        }
        if (keyname === "CONTAINERSIZE") {
            this.renderCommanFieldState(setFieldValue)

            let containerType = value.CONTAINERTYPE
            let pod = value.BUYERPOD
            let pol = value.POL
            let BoxSize = e.target.value

            this.getAddFilterData(containerType, pod, pol, BoxSize, setFieldValue)
        }

        if (keyname === "CONTAINERSIZETWO") {
            setFieldValue('CONTAINERSIZETWO', e.target.value);
            let containerTypeTwo = value.CONTAINERTYPE
            let podTwo = value.BUYERPOD
            let polTwo = value.POL
            let BoxSizeTwo = e.target.value

            let filterDataTwo = this.state.buyersPriceAndTermData.filter((ItemData: { Container: string, PortOfDestination: string, PortOfLocation: string, BoxSize: string }) => {
                if (ItemData.Container.includes(containerTypeTwo) && ItemData.PortOfDestination.includes(podTwo) && ItemData.PortOfLocation.includes(polTwo) && ItemData.BoxSize.includes(BoxSizeTwo)) {
                    return ItemData
                }
            })

            setFieldValue("RATETWO", filterDataTwo?.[0]?.Rate || "")
            setFieldValue('TERMSTHREE', filterDataTwo?.[0]?.TermsOne || "");
            setFieldValue('TERMSFOUR', filterDataTwo?.[0]?.TermsTwo || "");
        }
        else {
            if (keyname !== "CONTAINERCODES") {
                setFieldValue(`${keyname}`, `${e.target.value}`);
            }
        }
    };

    renderPrincipleOrgBlock = () => {
        if (!Array.isArray(this.state.buyerPrinicpalOrgList)) {
            return (
                <OptionForSelect
                    value={this.state.buyerPrinicpalOrgList?.attributes?.name}
                    key={this.state.buyerPrinicpalOrgList?.id}
                    data-test-id="option1"
                >
                    <ProjectText
                        variant="h6"
                        TextSize="14px"
                        fontweight="600"
                    >
                        {this.state.buyerPrinicpalOrgList?.attributes?.name}
                    </ProjectText>
                </OptionForSelect>
            );
        } else {
            return this.state.buyerPrinicpalOrgList?.map((val, index) => (
                <OptionForSelect
                    value={val.attributes.name}
                    key={val.id}
                    data-test-id={`option${index + 1}`}
                >
                    <ProjectText
                        variant="h6"
                        TextSize="14px"
                        fontweight="600"
                    >
                        {val.attributes.name}
                    </ProjectText>
                </OptionForSelect>
            ));
        }
    };

    BuyerErrorRender = (BuyerErrorMessage: string | undefined | string[] | FormikErrors<any> | FormikErrors<any>[], BuyerTextShow: boolean) => {
        return (
            BuyerErrorMessage !== undefined && BuyerTextShow &&
            <Box className="ErrorTextContainer">
                <ProjectText className={`TextError ${BuyerTextShow ? "" : "TextHidden"}`}>
                    {typeof BuyerErrorMessage === "string" ? BuyerErrorMessage : ""}
                </ProjectText>
            </Box>
        );
    };

    PolAndPodAutoComplateOnChange = (setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void,
        value: any,
        KeyName: string) => {
            if (KeyName === "POL" && value.attributes?.port_code) {
                setFieldValue("POLTERMINAL", "")
            }
            if (KeyName === "BUYERPOD" && value.attributes?.port_code) {
                setFieldValue("PODTERMINAL", "")
            }
    }

    renderVasselBlock = (
        errors: FormikErrors<{
            POL: string;
            POLTERMINAL: string;
            ETBATPOL: string;
            ETAATPOD: string;
            BUYERPOD: string;
            PODTERMINAL: string;
        }>,
        touched: FormikTouched<{
            POL: string | boolean;
            POLTERMINAL: string | boolean;
            ETBATPOL: string | boolean;
            ETAATPOD: string | boolean;
            BUYERPOD: string | boolean;
            PODTERMINAL: string | boolean;
        }>,
        values: {
            POL: string;
            POLTERMINAL: string;
            ETBATPOL: string;
            ETAATPOD: string;
            BUYERPOD: string;
            PODTERMINAL: string;
        },
        setFieldValue: (
            field: string,
            value: string,
            shouldValidate?: boolean
        ) => void
    ) => {
        return (
            <section style={webStyle.vesselContainer}>
                <h3 style={webStyle.vesselHeading}>Vessel Information</h3>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                    <Grid item xs={12} lg={6}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} lg={6}>
                                <InputLabel htmlFor="POL">
                                    <ProjectText
                                        Upercase
                                        variant="subtitle1"
                                        TextSize="14px"
                                        fontweight="900"
                                        textcolor="black"
                                    >
                                        POL
                                    </ProjectText>
                                </InputLabel>
                                <AutocompleteStyle
                                id="combo-box-demo"
                                size="small"
                                getOptionSelected={(option: unknown, value) => (option as {attributes: any; PortName: string }).attributes?.port_code === values.POL.split(",")[0]}
                                options={this.state.buyerPolData.filter((item: { attributes: {port_code: string} }) => item.attributes?.port_code !== values.BUYERPOD.split(",")[0])}
                                getOptionLabel={(option: unknown) => (option as {
                                    attributes: any; PortName: string }).attributes?.port_code}
                                onChange={(event: React.ChangeEvent<{}>, value) => {
                                    this.PolAndPodAutoComplateOnChange(setFieldValue, value, "POL")
                                }}
                                FormError={
                                    errors.POL !== undefined && touched.POL !== undefined && values.POL === ""
                                }
                                inputValue={values.POL.split(",")[0]}
                                onInputChange={(event, newInputValue) => {                                    
                                    if (event !== null) {
                                        setFieldValue("POL", newInputValue)
                                    }
                                }}
                                clearOnBlur={false}
                                closeIcon={false}
                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                renderOption={(option: unknown, state: AutocompleteRenderOptionState) => (
                                    <ProjectText
                                        TextSize="14px"
                                        variant="h6"
                                        fontweight="600"
                                    >
                                        {(option as {attributes: any; PortName: string}).attributes?.port_code ?? ""}
                                    </ProjectText>
                                )}
                            />
                                {this.BuyerErrorRender(errors.POL, errors.POL !== undefined && touched.POL !== undefined)}

                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <InputLabel htmlFor="POLTERMINAL">
                                    <ProjectText
                                        TextSize="14px"
                                        Upercase
                                        variant="subtitle1"
                                        textcolor="black"
                                        fontweight="900"
                                    >
                                        POL TERMINAL
                                    </ProjectText>
                                </InputLabel>
                                <AutocompleteStyle
                                id="combo-box-demo"
                                size="small"
                                options={this.state.buyerPolData?.filter((item: any) => (
                                    values.POL === item.attributes.port_code
                                )).map((item: {attributes: {port_country: string[]}}) => item?.attributes?.port_country)?.flatMap((array: any) => array)}
                                getOptionSelected={(option: unknown, value) => option as string === values.POLTERMINAL}
                                getOptionLabel={(option: unknown) => option as string ?? ""}
                                onChange={(event: React.ChangeEvent<{}>, value) => {
                                    this.PolAndPodAutoComplateOnChange(setFieldValue, value, "POLTERMINAL")
                                    this.setState({newPOLTerminalValue: value})
                                }}
                                inputValue={values.POLTERMINAL}
                                onInputChange={(event, newInputValue) => {
                                    if (event !== null ) {
                                        setFieldValue("POLTERMINAL", newInputValue)
                                    }
                                }}
                                clearOnBlur={false}
                                FormError={errors.POLTERMINAL !== undefined && touched.POLTERMINAL !== undefined && values.POLTERMINAL === ""}
                                closeIcon={false}
                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                renderOption={(option: unknown, state: AutocompleteRenderOptionState) => (
                                    <ProjectText
                                        TextSize="14px"
                                        variant="h6"
                                        fontweight="600"
                                    >
                                        {option as string ?? ""}
                                    </ProjectText>
                                )}
                            />

                                {this.BuyerErrorRender(
                                    errors.POLTERMINAL,
                                    errors.POLTERMINAL !== undefined &&
                                    touched.POLTERMINAL !== undefined
                                )}
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item xs={12} lg={6}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} lg={6}>
                                <InputLabel htmlFor="BUYERPOD">
                                    <ProjectText
                                        Upercase
                                        variant="subtitle1"
                                        TextSize="14px"
                                        fontweight="900"
                                        textcolor="black"
                                    >
                                        POD
                                    </ProjectText>
                                </InputLabel>
                                <AutocompleteStyle
                                id="combo-box-demo"
                                size="small"
                                options={this.state.buyerPolData.filter((item: { attributes: any; PortName: string }) => item?.attributes?.port_code !== values.POL.split(",")[0])}
                                getOptionLabel={(option: unknown) => (option as { attributes: any; PortName: string }).attributes?.port_code}
                                onChange={(event: React.ChangeEvent<{}>, value) => {
                                    this.PolAndPodAutoComplateOnChange(setFieldValue, value, "BUYERPOD")
                                }}
                                clearOnBlur={false}
                                FormError={
                                    errors.BUYERPOD !== undefined && touched.BUYERPOD !== undefined && values.BUYERPOD === ""
                                }
                                inputValue={values.BUYERPOD.split(",")[0]}
                                onInputChange={(event, newInputValue) => {
                                    if (event !== null) {
                                        setFieldValue("BUYERPOD", newInputValue)
                                    }
                                }}
                                closeIcon={false}
                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                renderOption={(option: unknown, state: AutocompleteRenderOptionState) => (
                                    <ProjectText
                                        TextSize="14px"
                                        variant="h6"
                                        fontweight="600"
                                    >
                                        {(option as {attributes: any; PortName: string}).attributes?.port_code ?? ""}
                                    </ProjectText>
                                )}
                            />
                                {this.BuyerErrorRender(errors.BUYERPOD, errors.BUYERPOD !== undefined && touched.BUYERPOD !== undefined)}
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <InputLabel htmlFor="PODTERMINAL">
                                    <ProjectText
                                        Upercase
                                        variant="subtitle1"
                                        TextSize="14px"
                                        fontweight="900"
                                        textcolor="black"
                                    >
                                        POD TERMINAL
                                    </ProjectText>
                                </InputLabel>
                                <AutocompleteStyle
                                id="combo-box-demo"
                                size="small"
                                options={this.state.buyerPolData?.filter((item: any) => (
                                    values.BUYERPOD === item.attributes.port_code
                                )).map((item: {attributes: {port_country: string[]}}) => item?.attributes?.port_country)?.flatMap((array: any) => array)}
                                getOptionLabel={(option: unknown) => option as string ?? ""}
                                onChange={(event: React.ChangeEvent<{}>, value) => {
                                    this.PolAndPodAutoComplateOnChange(setFieldValue, value, "PODTERMINAL")
                                    this.setState({newPODTerminalValue: value})
                                }}
                                clearOnBlur={false}
                                inputValue={values.PODTERMINAL}
                                onInputChange={(event, newInputValue) => {
                                    if (event !== null) {
                                        setFieldValue("PODTERMINAL", newInputValue)
                                    }
                                }}
                                FormError={
                                    errors.PODTERMINAL !== undefined &&
                                    touched.PODTERMINAL !== undefined && values.PODTERMINAL === ""
                                }
                                closeIcon={false}
                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                renderOption={(option: unknown, state: AutocompleteRenderOptionState) => (
                                    <ProjectText
                                        TextSize="14px"
                                        variant="h6"
                                        fontweight="600"
                                    >
                                        {option as string ?? ""}
                                    </ProjectText>
                                )}
                            />
                                {this.BuyerErrorRender(
                                    errors.PODTERMINAL,
                                    errors.PODTERMINAL !== undefined &&
                                    touched.PODTERMINAL !== undefined
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={4} style={{ marginBottom: "10px" }}>
                    <Grid item xs={12} lg={6}>
                        <InputLabel htmlFor="ETBATPOL">
                            <ProjectText
                                variant="subtitle1"
                                TextSize="14px"
                                Upercase
                                fontweight="900"
                                textcolor="black"
                            >
                                CONTAINER LOADING RANGE (FROM)
                            </ProjectText>
                        </InputLabel>
                        <LandingPageTextField
                            BoderRadius="10px"
                            inputProps={{
                                "data-test-id": "ETBATPOL",
                                "min": moment.parseZone().format("YYYY-MM-DD"),
                                style: { height: '26px' },

                            }}
                            
                            variant="outlined"
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                            ) => {
                                this.AddBuyerListingFormTextFieldChange(
                                    e,
                                    setFieldValue,
                                    "ETBATPOL",
                                    values
                                );
                                setFieldValue("ETAATPOD", "")
                            }}
                            size="small"
                            FormError={
                                errors.ETBATPOL !== undefined && touched.ETBATPOL !== undefined && values.ETBATPOL === ""
                            }
                            type="Date"
                            value={values.ETBATPOL}
                            id="ETBATPOL"
                            name="ETBATPOL"
                            fullWidth
                        />
                        {this.BuyerErrorRender(errors.ETBATPOL, errors.ETBATPOL !== undefined && touched.ETBATPOL !== undefined && values.ETBATPOL === "")}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <InputLabel htmlFor="ETAATPOD">
                            <ProjectText
                                Upercase
                                variant="subtitle1"
                                TextSize="14px"
                                fontweight="900"
                                textcolor="black"
                            >
                                CONTAINER LOADING RANGE (TO)
                            </ProjectText>
                        </InputLabel>
                        <LandingPageTextField
                            BoderRadius="10px"
                            inputProps={{
                                "data-test-id": "ETAATPOD",
                                min: moment.parseZone(values.ETBATPOL).add(1, 'd').format("YYYY-MM-DD"),
                                style: { height: '26px' }
                            }}
                            variant="outlined"
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                            ) => {
                                this.setState({ buyerEtaDate: e.target.value });
                                this.AddBuyerListingFormTextFieldChange(
                                    e,
                                    setFieldValue,
                                    "ETAATPOD",
                                    values
                                );
                            }}
                            size="small"
                            FormError={
                                errors.ETAATPOD !== undefined && touched.ETAATPOD !== undefined && values.ETAATPOD === ""
                            }
                            type="Date"
                            value={values.ETAATPOD}
                            id="ETAATPOD"
                            name="ETAATPOD"
                            fullWidth
                        />
                        {this.BuyerErrorRender(errors.ETAATPOD, errors.ETAATPOD !== undefined && touched.ETAATPOD !== undefined && values.ETAATPOD === "")}
                    </Grid>
                </Grid>
            </section>
        );
    };
    renderAdditionContainerFirstBlock = (
        errors: FormikErrors<{
            CONTAINERTYPE: string,
            CONTAINERCODES: any,
            CONTAINERSIZE: string,
            TOTALQUANTITY: string;
            AVERAGETONSBOX: string;
            RATE: string;
            TERMS: string;
            TERMSTWO: string;
        }>,
        touched: FormikTouched<{
            CONTAINERTYPE: string | boolean;
            CONTAINERCODES: any;
            CONTAINERSIZE: string | boolean;
            TOTALQUANTITY: string | boolean;
            AVERAGETONSBOX: string | boolean;
            RATE: string | boolean;
            TERMS: string | boolean;
            TERMSTWO?: string | boolean;
        }>,
        values: {
            CONTAINERTYPE: string;
            CONTAINERCODES: any[];
            CONTAINERSIZE: string;
            TOTALQUANTITY: string;
            AVERAGETONSBOX: string;
            RATE: string;
            TERMS: string;
            TERMSTWO: string;
        },
        setFieldValue: (
            field: string,
            value: string | any[],
            shouldValidate?: boolean
        ) => void
    ) => {
        return (
            <>
                <Grid
                    container
                    spacing={2}
                    style={{ marginBottom: "10px" }}
                >
                    <Grid item xs={12} lg={6}>
                        <InputLabel htmlFor="CONTAINERTYPE">
                            <ProjectText
                                Upercase
                                variant="subtitle1"
                                TextSize="14px"
                                fontweight="900"
                                textcolor="black"
                            >
                                Container Status
                            </ProjectText>
                        </InputLabel>
                        <ProjectTextField
                            style={{ height: '47px' }}
                            MenuProps={projectTextFieldProps}
                            variant="outlined"
                            fullWidth
                            defaultValue="all"
                            FormError={
                                errors.CONTAINERTYPE !== undefined &&
                                touched.CONTAINERTYPE !== undefined
                            }
                            inputProps={{
                                "data-test-id": "CONTAINERTYPE",
                            }}
                            value={values.CONTAINERTYPE}
                            id="CONTAINERTYPE"
                            name="CONTAINERTYPE"
                            onChange={(
                                e: React.ChangeEvent<{ value: unknown }>
                            ) => {
                                this.AddBuyerListingFormTextFieldChange(
                                    e,
                                    setFieldValue,
                                    "CONTAINERTYPE",
                                    values
                                );
                            }}
                        >
                            {this.state.buyerContainerData?.map(
                                (data: { buyerContainer_type: string }) => {
                                    let keyCounter = 1
                                    return (
                                        <OptionForSelect
                                            value={data.buyerContainer_type}
                                            key={`${data.buyerContainer_type}_${keyCounter++}`}
                                        >
                                            <ProjectText
                                                variant="h6"
                                                TextSize="14px"
                                                fontweight="600"
                                            >
                                                {data.buyerContainer_type}
                                            </ProjectText>
                                        </OptionForSelect>
                                    );
                                }
                            )}
                        </ProjectTextField>
                        {this.BuyerErrorRender(
                            errors.CONTAINERTYPE,
                            errors.CONTAINERTYPE !== undefined &&
                            touched.CONTAINERTYPE !== undefined
                        )}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <InputLabel htmlFor="CONTAINERSIZE">
                            <ProjectText
                                Upercase
                                variant="subtitle1"
                                TextSize="14px"
                                fontweight="900"
                                textcolor="black"
                            >
                                CONTAINER SIZE 
                            </ProjectText>
                        </InputLabel>
                        <ProjectTextField
                            style={{ height: '47px', fontWeight: 600 }}
                            MenuProps={projectTextFieldProps}
                            variant="outlined"
                            fullWidth
                            defaultValue="all"
                            type="text"
                            inputProps={{
                                "data-test-id": "CONTAINERSIZE",
                            }}
                            FormError={
                                errors.CONTAINERSIZE !== undefined &&
                                touched.CONTAINERSIZE !== undefined
                            }
                            value={values.CONTAINERSIZE}
                            id="CONTAINERSIZE"
                            name="CONTAINERSIZE"
                            onChange={(
                                e: React.ChangeEvent<{ value: unknown }>
                            ) => {
                                this.AddBuyerListingFormTextFieldChange(
                                    e,
                                    setFieldValue,
                                    "CONTAINERSIZE",
                                    values
                                );
                                this.setState({
                                    selectedBuyerContainerOneSize: e.target.value,
                                });
                            }}
                        >
                            {this.state.selectedContainer?.buyerBox_sizes?.map(
                                (val: { size: string }) => {
                                    let counter = 1
                                    return (
                                        <OptionForSelect value={val?.size} key={`${val?.size}_${counter++}`}>
                                            <ProjectText
                                                variant="h6"
                                                TextSize="14px"
                                                fontweight="600"
                                            >
                                                {val?.size}
                                            </ProjectText>
                                        </OptionForSelect>
                                    );
                                }
                            )}
                        </ProjectTextField>
                        {this.BuyerErrorRender(
                            errors.CONTAINERSIZE,
                            errors.CONTAINERSIZE !== undefined &&
                            touched.CONTAINERSIZE !== undefined
                        )}
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    style={{ marginBottom: "10px" }}
                >
                    <Grid item xs={12} lg={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} lg={6}>
                                <InputLabel htmlFor="TOTALQUANTITY">
                                    <ProjectText
                                        Upercase
                                        variant="subtitle1"
                                        TextSize="14px"
                                        fontweight="900"
                                        textcolor="black"
                                    >
                                        QUANTITY
                                    </ProjectText>
                                </InputLabel>
                                <LandingPageTextField
                                    BoderRadius="10px"
                                    inputProps={{
                                        "data-test-id": "TOTALQUANTITY",
                                        style: { height: '26px' }
                                    }}
                                    variant="outlined"
                                    onChange={(
                                        e: React.ChangeEvent<
                                            HTMLInputElement | HTMLTextAreaElement
                                        >
                                    ) => {
                                        this.AddBuyerListingFormTextFieldChange(
                                            e,
                                            setFieldValue,
                                            "TOTALQUANTITY",
                                            values
                                        );
                                    }}
                                    size="small"
                                    FormError={
                                        errors.TOTALQUANTITY !== undefined &&
                                        touched.TOTALQUANTITY !== undefined
                                    }
                                    value={values.TOTALQUANTITY}
                                    id="TOTALQUANTITY"
                                    name="TOTALQUANTITY"
                                    fullWidth
                                />
                                {this.BuyerErrorRender(
                                    errors.TOTALQUANTITY,
                                    errors.TOTALQUANTITY !== undefined &&
                                    touched.TOTALQUANTITY !== undefined
                                )}
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <InputLabel htmlFor="AVERAGETONSBOX">
                                    <ProjectText
                                        // Upercase
                                        variant="subtitle1"
                                        TextSize="14px"
                                        fontweight="900"
                                        textcolor="black"
                                    >
                                      AVG. TONS/CONTAINER
                                    </ProjectText>
                                </InputLabel>
                                <LandingPageTextField
                                    BoderRadius="10px"
                                    inputProps={{
                                        "data-test-id": "AVERAGETONSBOX",
                                        style: { height: '26px' }
                                    }}
                                    variant="outlined"
                                    onChange={(
                                        e: React.ChangeEvent<
                                            HTMLInputElement | HTMLTextAreaElement
                                        >
                                    ) => {
                                        this.AddBuyerListingFormTextFieldChange(
                                            e,
                                            setFieldValue,
                                            "AVERAGETONSBOX",
                                            values
                                        );
                                    }}
                                    FormError={
                                        errors.AVERAGETONSBOX !== undefined &&
                                        touched.AVERAGETONSBOX !== undefined
                                    }
                                    type="text"
                                    size="small"
                                    value={values.AVERAGETONSBOX}
                                    id="AVERAGETONSBOX"
                                    name="AVERAGETONSBOX"
                                    fullWidth
                                />
                                {this.BuyerErrorRender(
                                    errors.AVERAGETONSBOX,
                                    errors.AVERAGETONSBOX !== undefined &&
                                    touched.AVERAGETONSBOX !== undefined
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <InputLabel htmlFor="CONTAINERCODES">
                            <ProjectText
                                Upercase
                                variant="subtitle1"
                                TextSize="14px"
                                fontweight="900"
                                textcolor="black"
                            >
                                Container Types
                            </ProjectText>
                        </InputLabel>
                        <ProjectTextField
                            MenuProps={projectTextFieldProps}
                            variant="outlined"
                            fullWidth
                            FormError={
                                errors.CONTAINERCODES !== undefined &&
                                touched.CONTAINERCODES !== undefined
                            }
                            value={values.CONTAINERCODES}
                            multiple
                            style={{
                                zIndex: 1,
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                height: '47px'
                            }}
                            className="MultiSelect"
                            id="CONTAINERCODES"
                            name="CONTAINERCODES"
                            renderValue={() => {
                                return (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', zIndex: 900 }}>
                                        {Array.isArray(values.CONTAINERCODES) && values.CONTAINERCODES?.map((val: string) => {
                                            return <Box style={{ display: "flex" }} key={val?.trim()}>
                                                        <Chip variant="outlined" className="DeleteChip" key={val?.trim()} label={val?.trim()} />
                                                    </Box>
                                        })}
                                    </Box>
                                )
                            }}
                            inputProps={{
                                "data-test-id": "CONTAINERCODES"
                            }}
                            onChange={(
                                e: React.ChangeEvent<{ value: unknown }>
                            ) => {
                                this.AddBuyerListingFormTextFieldChange(
                                    e,
                                    setFieldValue,
                                    "CONTAINERCODES",
                                    values
                                );
                            }}
                        >
                            {this.state.selectedContainer?.buyerCodes?.map(
                                (val: string) => {
                                    return (
                                        <OptionForSelect value={val} key={val} style={{ display: "flex", justifyContent: "space-between" }}>
                                            <ProjectText
                                                variant="h6"
                                                TextSize="14px"
                                                fontweight="600"
                                            >
                                                {val}
                                            </ProjectText>
                                             <Checkbox    style={{color:'#1653DD'}} checked={values.CONTAINERCODES?.includes(val)}/>
                                        </OptionForSelect>
                                    );
                                })}
                        </ProjectTextField>
                        {this.BuyerErrorRender(
                            errors.CONTAINERCODES,
                            errors.CONTAINERCODES !== undefined &&
                            touched.CONTAINERCODES !== undefined
                        )}
                    </Grid>
                </Grid>
                {this.renderAdditionContainerRateTermsFirstBlock(
                    errors,
                    touched,
                    values,
                    setFieldValue
                )}
            </>
        );
    };
    renderUSDFieldAdornment = () => {
        return (
            <Box style={{ display: "flex" }}>
                <Box
                    style={{
                        backgroundColor: "white",
                        paddingTop: "10px",
                        marginRight: "0.8rem",
                    }}
                >
                    <ProjectText
                        TextSize="1rem"
                        fontweight="700"
                    >
                        USD
                    </ProjectText>
                </Box>
                <div style={{ background: "#CDCDCD", width: "1px", height: "44px", marginRight: "6px" }}></div>
            </Box>
        )
    }
    renderAdditionContainerRateTermsFirstBlock = (
        errors: FormikErrors<{
            RATE: string;
            TERMS: string;
            TERMSTWO: string;
        }>,
        touched: FormikTouched<{
            RATE: string | boolean;
            TERMS: string | boolean;
            TERMSTWO: string | boolean;
        }>,
        values: {
            CONTAINERSIZE: string;
            RATE: string;
            TERMS: string;
            TERMSTWO: string;
        },
        setFieldValue: (
            field: string,
            value: string | any[],
            shouldValidate?: boolean
        ) => void
    ) => {
        return (
            <Grid
                container
                spacing={4}
                style={{ marginBottom: "10px" }}
            >
                <Grid item xs={12} lg={6}>
                    <InputLabel htmlFor="RATE">
                        <ProjectText
                            // Upercase
                            TextSize="14px"
                            fontweight="900"
                            textcolor="black"
                            variant="subtitle1"
                        >
                            BUYER PRICE
                        </ProjectText>
                    </InputLabel>
                    <LandingPageTextField
                        inputProps={{
                            "data-test-id": "RATE",
                            style: { height: '26px' }
                        }}
                        className="inputHeight"
                        onChange={(
                            e: React.ChangeEvent<
                                HTMLInputElement | HTMLTextAreaElement
                            >
                        ) => {
                            this.AddBuyerListingFormTextFieldChange(
                                e,
                                setFieldValue,
                                "RATE",
                                values
                            );
                        }}
                        FormError={
                            errors.RATE !== undefined &&
                            touched.RATE !== undefined
                        }
                        variant="outlined"
                        BoderRadius="10px"
                        style={{
                            backgroundColor: "white",
                            borderRadius: "10px",
                        }}
                        size="small"
                        type="text"
                        value={values.RATE}
                        id="RATE"
                        name="RATE"
                        InputProps={{
                            endAdornment: (
                                <Box style={{ backgroundColor: "white" }}>
                                    <ProjectText
                                        textcolor="#ACACAC"
                                        TextSize="1rem"
                                    >
                                        {values.CONTAINERSIZE ? '/' +values.CONTAINERSIZE?.split(' ').join('') : ''}
                                    </ProjectText>
                                </Box>
                            ),
                            startAdornment: (
                                this.renderUSDFieldAdornment()
                            ),
                        }}
                        fullWidth
                    />
                    {this.BuyerErrorRender(
                        errors.RATE,
                        errors.RATE !== undefined &&
                        touched.RATE !== undefined
                    )}
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Box style={{ display: "flex" }}>
                        <InputLabel htmlFor="TERMS">
                            <ProjectText
                                Upercase
                                variant="subtitle1"
                                textcolor="black"
                                fontweight="900"
                                TextSize="14px"
                            >
                                TERMS
                            </ProjectText>
                        </InputLabel>
                        {/* <LightTooltip
                            title="Full legal name of your organisation as registered with the local authority."
                            placement="right"
                            className="tooltip"
                        >
                            <InfoOutlinedIcon className="infoIcon" style={{fontSize:"20px",paddingLeft:"5px"}} />
                        </LightTooltip> */}
                    </Box>
                    <Grid container spacing={2} justifyContent="center" className="SlashClassContainer">
                        <Grid item lg={6}>
                            <LandingPageTextField
                                BoderRadius="10px"
                                inputProps={{
                                    "data-test-id": "TERMS",
                                    style: { height: '26px' }
                                }}
                                onChange={(
                                    e: React.ChangeEvent<
                                        HTMLInputElement | HTMLTextAreaElement
                                    >
                                ) => {
                                    this.AddBuyerListingFormTextFieldChange(
                                        e,
                                        setFieldValue,
                                        "TERMS",
                                        values
                                    );
                                }}
                                variant="outlined"
                                FormError={
                                    errors.TERMS !== undefined &&
                                    touched.TERMS !== undefined && values.TERMS === ""
                                }
                                size="small"
                                type="text"
                                value={
                                    values.TERMS
                                }
                                id="TERMS"
                                name="TERMS"
                                fullWidth
                            />
                            {this.BuyerErrorRender(
                                errors.TERMS,
                                errors.TERMS !== undefined &&
                                touched.TERMS !== undefined && values.TERMS === ""
                            )}
                        </Grid>
                        <Box className="SlashClass">
                        <Box>
                            <span>
                                /
                            </span>
                        </Box>
                    </Box>
                        <Grid item  lg={6}>
                            <LandingPageTextField
                                inputProps={{
                                    "data-test-id": "TERMS1",
                                    style: { height: '26px' }
                                }}
                                variant="outlined"
                                BoderRadius="10px"
                                onChange={(
                                    e: React.ChangeEvent<
                                        HTMLInputElement | HTMLTextAreaElement
                                    >
                                ) => {
                                    this.AddBuyerListingFormTextFieldChange(
                                        e,
                                        setFieldValue,
                                        "TERMSTWO",
                                        values
                                    );
                                }}
                                size="small"
                                value={
                                    values.TERMSTWO
                                }
                                FormError={
                                    errors.TERMSTWO !== undefined &&
                                    touched.TERMSTWO !== undefined
                                }
                                type="text"
                                id="TERMSTWO"
                                name="TERMSTWO"
                                fullWidth
                            />
                            {this.BuyerErrorRender(
                                errors.TERMSTWO,
                                errors.TERMSTWO !== undefined &&
                                touched.TERMSTWO !== undefined
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    handleAddContainerClick = () => {
        this.setState({
            isAddContainerClicked: true
        })
    }

    addContainerSizeButton = (isSecondContainerClicked: boolean,
        values: {
            CONTAINERTYPE: string;
            CONTAINERCODES: any[];
            CONTAINERSIZE: string;
            AVERAGETONSBOX: string;
            RATE: string;
            TERMS: string;
            TERMSTWO: string;
        },
        setFieldValue: (field: string, value: { CONTAINERSIZE: string; AVERAGETONSBOX: string; RATE: string; TERMS: string; TERMSTWO: string }, shouldValidate?: boolean | undefined) => void
    ) => {
        let notdata = values.CONTAINERSIZE && values.AVERAGETONSBOX && values.RATE && values.TERMS && values.TERMSTWO
        return !isSecondContainerClicked && (
            <div style={{ ...webStyle.dFlexJCenter, marginTop: "5px" }}>
                <ContainedButton onClick={() => this.handleAddContainerClick()} MarginFalse
                    BorderColor={!notdata ? 'rgba(0, 0, 0, 0.26)' : "black"}
                    style={{ padding: '8px 16px' }}
                    TextColor={!notdata ? 'rgba(0, 0, 0, 0.26)' : "black"} bgcolor="transparent"
                    HoverColor="transparent"
                    data-test-id="ADDCONTAINERSIZEBTN"
                    disabled={!notdata}
                    startIcon={<img src={add_black} className="BlackPlusIcon" alt="" style={{ ...webStyle.btnImgDiv, opacity: !notdata ? .25 : 1 }} />}
                >
                    ADD CONTAINER SIZE
                </ContainedButton>
            </div>
        )
    }

    handleRemoveAction = (setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
    ) => void) => {
        this.setState({
            isAddContainerClicked: false
        })

        this.setState({
            reinitialisedForm: true
        }, () => {
            setTimeout(() => {
                this.setState({
                    reinitialisedForm: false
                })
            }, 1000);
        })

        setFieldValue("CONTAINERSIZETWO", "")
        setFieldValue("TOTALQUANTITYTWO", "")
        setFieldValue("AVERAGETONSBOXTWO", "")
        setFieldValue("RATETWO", "")
        setFieldValue("TERMSTHREE", "")
        setFieldValue("TERMSFOUR", "")

    }

    renderAdditionContainerSecondBlock = (
        errors: FormikErrors<{
            CONTAINERSIZETWO: string,
            TOTALQUANTITYTWO: string;
            AVERAGETONSBOXTWO: string;
            RATETWO: string;
            TERMSTHREE: string;
            TERMSFOUR: string;
        }>,
        touched: FormikTouched<{
            CONTAINERSIZETWO: string | boolean;
            TOTALQUANTITYTWO: string | boolean;
            AVERAGETONSBOXTWO: string | boolean;
            RATETWO: string | boolean;
            TERMSTHREE: string | boolean;
            TERMSFOUR?: string | boolean;
        }>,
        values: {
            CONTAINERSIZETWO: string;
            TOTALQUANTITYTWO: string;
            AVERAGETONSBOXTWO: string;
            RATETWO: string;
            TERMSTHREE: string;
            TERMSFOUR: string;
            CONTAINERSIZE: string;
        },
        setFieldValue: (
            field: string,
            value: string,
            shouldValidate?: boolean
        ) => void
    ) => {
        return (
            <>
                <Grid
                    container
                    spacing={2}
                    style={{ marginBottom: "10px", marginTop: '11px', paddingTop: '11px', borderTop: '1px solid rgba(205, 205, 205, 1)' }}
                >
                    <Grid item xs={12} md={6} lg={6}>
                        <Grid container spacing={1}>
                            <Grid item xs md={6} lg={6}>
                                <InputLabel htmlFor="TOTALQUANTITYTWO">
                                    <ProjectText
                                        Upercase
                                        TextSize="14px"
                                        variant="subtitle1"
                                        fontweight="900"
                                        textcolor="black"
                                    >
                                        QUANTITY
                                    </ProjectText>
                                </InputLabel>
                                <LandingPageTextField
                                    BoderRadius="10px"
                                    inputProps={{
                                        "data-test-id": "TOTALQUANTITYTWO",
                                        style: { height: '26px' }
                                    }}
                                    variant="outlined"
                                    onChange={(
                                        e: React.ChangeEvent<
                                            HTMLInputElement | HTMLTextAreaElement
                                        >
                                    ) => {
                                        this.AddBuyerListingFormTextFieldChange(
                                            e,
                                            setFieldValue,
                                            "TOTALQUANTITYTWO",
                                            values
                                        );
                                    }}
                                    size="small"
                                    FormError={
                                        errors.TOTALQUANTITYTWO !== undefined &&
                                        touched.TOTALQUANTITYTWO !== undefined
                                    }
                                    value={values.TOTALQUANTITYTWO}
                                    id="TOTALQUANTITYTWO"
                                    name="TOTALQUANTITYTWO"
                                    fullWidth
                                />
                                {this.BuyerErrorRender(
                                    errors.TOTALQUANTITYTWO,
                                    errors.TOTALQUANTITYTWO !== undefined &&
                                    touched.TOTALQUANTITYTWO !== undefined
                                )}
                            </Grid>
                            <Grid item xs lg={6}>
                                <InputLabel htmlFor="AVERAGETONSBOXTWO">
                                    <ProjectText
                                        // Upercase
                                        TextSize="14px"
                                        variant="subtitle1"
                                        fontweight="900"
                                        textcolor="black"
                                    >
                                       AVG. TONS/CONTAINER
                                    </ProjectText>
                                </InputLabel>
                                <LandingPageTextField
                                    BoderRadius="10px"
                                    variant="outlined"
                                    inputProps={{
                                        "data-test-id": "AVERAGETONSBOXTWO",
                                        style: { height: '26px' }
                                    }}
                                    onChange={(
                                        e: React.ChangeEvent<
                                            HTMLInputElement | HTMLTextAreaElement
                                        >
                                    ) => {
                                        this.AddBuyerListingFormTextFieldChange(
                                            e,
                                            setFieldValue,
                                            "AVERAGETONSBOXTWO",
                                            values
                                        );
                                    }}
                                    size="small"
                                    FormError={
                                        errors.AVERAGETONSBOXTWO !== undefined &&
                                        touched.AVERAGETONSBOXTWO !== undefined
                                    }
                                    type="text"
                                    value={values.AVERAGETONSBOXTWO}
                                    id="AVERAGETONSBOXTWO"
                                    name="AVERAGETONSBOXTWO"
                                    fullWidth
                                />
                                {this.BuyerErrorRender(
                                    errors.AVERAGETONSBOXTWO,
                                    errors.AVERAGETONSBOXTWO !== undefined &&
                                    touched.AVERAGETONSBOXTWO !== undefined
                                )}
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item xs={12} md={6} lg={6}>
                        <div style={webStyle.removeBtnBox}>
                            <InputLabel htmlFor="CONTAINERSIZETWO">
                                <ProjectText
                                    Upercase
                                    TextSize="14px"
                                    variant="subtitle1"
                                    fontweight="900"
                                    textcolor="black"
                                >
                                   Container Size 
                                </ProjectText>
                            </InputLabel>
                            <Button variant="text"
                                data-test-id="addRemoveContainer"
                                onClick={() => this.handleRemoveAction(setFieldValue)}
                                style={{
                                    position: "absolute",
                                    right: "8px"
                                }} startIcon={<img src={remove} alt="delte" />}>
                                <ProjectText variant="h6" fontweight="700" textcolor="#1653DD" TextSize=".875rem">
                                    Remove
                                </ProjectText>
                            </Button>
                        </div>
                        <ProjectTextField
                            style={{ height: '47px' }}
                            MenuProps={projectTextFieldProps}
                            fullWidth
                            variant="outlined"
                            defaultValue="all"
                            type="text"
                            inputProps={{
                                "data-test-id": "CONTAINERSIZETWO",
                            }}
                            FormError={
                                errors.CONTAINERSIZETWO !== undefined &&
                                touched.CONTAINERSIZETWO !== undefined && values.CONTAINERSIZETWO === ""
                            }
                            value={values.CONTAINERSIZETWO}
                            id="CONTAINERSIZETWO"
                            name="CONTAINERSIZETWO"
                            onChange={(
                                e: React.ChangeEvent<{ value: unknown }>
                            ) => {
                                this.AddBuyerListingFormTextFieldChange(
                                    e,
                                    setFieldValue,
                                    "CONTAINERSIZETWO",
                                    values
                                );
                            }}
                        >
                            {this.state.selectedContainer?.buyerBox_sizes?.filter((value: { size: string }) =>
                                value.size !== values.CONTAINERSIZE
                            )?.map(
                                (val: { size: string }) => {
                                    return (
                                        <OptionForSelect value={val?.size} key={val?.size}>
                                            <ProjectText
                                                variant="h6"
                                                TextSize="14px"
                                                fontweight="600"
                                            >
                                                {val?.size}
                                            </ProjectText>
                                        </OptionForSelect>
                                    );
                                }
                            )}
                        </ProjectTextField>
                        {this.BuyerErrorRender(
                            errors.CONTAINERSIZETWO,
                            errors.CONTAINERSIZETWO !== undefined &&
                            touched.CONTAINERSIZETWO !== undefined && values.CONTAINERSIZETWO === ""

                        )}
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    style={{ marginBottom: "10px" }}
                >
                    <Grid item xs={12} md={6} lg={6}>
                        <InputLabel htmlFor="RATETWO">
                            <ProjectText
                                TextSize="14px"
                                // Upercase
                                variant="subtitle1"
                                textcolor="black"
                                fontweight="900"
                            >
                                BUYER PRICE
                            </ProjectText>
                        </InputLabel>
                        <LandingPageTextField
                            BoderRadius="10px"
                            inputProps={{
                                "data-test-id": "RATETWO",
                                style: { height: '26px' }
                            }}
                            variant="outlined"
                            onChange={(
                                e: React.ChangeEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                >
                            ) => {
                                this.AddBuyerListingFormTextFieldChange(
                                    e,
                                    setFieldValue,
                                    "RATETWO",
                                    values
                                );
                            }}
                            FormError={
                                errors.RATETWO !== undefined &&
                                touched.RATETWO !== undefined
                            }
                            style={{
                                backgroundColor: "white",
                                borderRadius: "10px",
                            }}
                            type="text"
                            size="small"
                            value={values.RATETWO}
                            id="RATETWO"
                            name="RATETWO"
                            InputProps={{
                                endAdornment: (
                                    <Box style={{ backgroundColor: "white" }}>
                                        <ProjectText
                                            TextSize="1rem"
                                            textcolor="#ACACAC"
                                        >
                                            /{values.CONTAINERSIZETWO?.split(' ').join('') || ""}
                                        </ProjectText>
                                    </Box>
                                ),
                                startAdornment: (
                                    this.renderUSDFieldAdornment()
                                ),
                            }}
                            fullWidth
                        />
                        {this.BuyerErrorRender(
                            errors.RATETWO,
                            errors.RATETWO !== undefined &&
                            touched.RATETWO !== undefined
                        )}

                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Box style={{ display: "flex" }}>
                            <InputLabel htmlFor="TERMSTHREE">
                                <ProjectText
                                    Upercase
                                    TextSize="14px"
                                    variant="subtitle1"
                                    textcolor="black"
                                    fontweight="900"
                                >
                                    TERMS
                                </ProjectText>
                            </InputLabel>
                            {/* <LightTooltip
                                title="Full legal name of your organisation as registered with the local authority."
                                placement="right"
                                className="tooltip"
                            >
                                <InfoOutlinedIcon className="infoIcon" style={{fontSize:"20px",paddingLeft:"5px"}} />
                            </LightTooltip> */}
                        </Box>
                        <Grid container spacing={2} justifyContent="center" className="SlashClassContainer">
                            <Grid item xs lg={6}>
                                <LandingPageTextField
                                    BoderRadius="10px"
                                    inputProps={{
                                        "data-test-id": "TERMSTHREE",
                                        style: { height: '26px' }
                                    }}
                                    variant="outlined"
                                    onChange={(
                                        e: React.ChangeEvent<
                                            HTMLInputElement | HTMLTextAreaElement
                                        >
                                    ) => {
                                        this.AddBuyerListingFormTextFieldChange(
                                            e,
                                            setFieldValue,
                                            "TERMSTHREE",
                                            values
                                        );
                                    }}
                                    size="small"
                                    FormError={
                                        errors.TERMSTHREE !== undefined &&
                                        touched.TERMSTHREE !== undefined
                                    }
                                    type="text"
                                    value={
                                        values.TERMSTHREE
                                    }
                                    id="TERMSTHREE"
                                    name="TERMSTHREE"
                                    fullWidth
                                />
                                {this.BuyerErrorRender(
                                    errors.TERMSTHREE,
                                    errors.TERMSTHREE !== undefined &&
                                    touched.TERMSTHREE !== undefined
                                )}
                            </Grid>
                            <Box className="SlashClass"><Box><span>/</span></Box></Box>
                            <Grid item lg={6}>
                                <LandingPageTextField
                                    BoderRadius="10px"
                                    inputProps={{
                                        "data-test-id": "TERMSFOUR",
                                        style: { height: '26px' }
                                    }}
                                    variant="outlined"
                                    onChange={(
                                        e: React.ChangeEvent<
                                            HTMLInputElement | HTMLTextAreaElement
                                        >
                                    ) => {
                                        this.AddBuyerListingFormTextFieldChange(
                                            e,
                                            setFieldValue,
                                            "TERMSFOUR",
                                            values
                                        );
                                    }}
                                    size="small"
                                    FormError={
                                        errors.TERMSFOUR !== undefined &&
                                        touched.TERMSFOUR !== undefined
                                    }
                                    type="text"
                                    value={
                                        values.TERMSFOUR
                                    }
                                    id="TERMSFOUR"
                                    name="TERMSFOUR"
                                    fullWidth
                                />
                                {this.BuyerErrorRender(
                                    errors.TERMSFOUR,
                                    errors.TERMSFOUR !== undefined &&
                                    touched.TERMSFOUR !== undefined
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    };
    handleCloseAddEditSuccessModal = () => {
        this.setState({ show: false, buyerListingAdded: false, triggerEditForm: false, anchorElDropdown: null, buyerAddedListingID: "", isAddContainerClicked: false, isEditContainerClicked: false, isMatchesFound: false })
        
        if(this.props.apiCall){
            this.props?.buyerRequestApiCall()
        }
        window.location.reload()
    }
    handleMatchingOffersNavigations = () => {
        this.setState({show: false})
        this.props.history.push("BuyersOffer");
        window.location.reload();
    }


    buyerListAddedModal = () => {
        let content;
        if (this.state.isMatchesFound) {
            content = (
                <>
                    <h1 style={webStyle.listingHeading}>Matching Listing Available For Your Request!</h1><p style={webStyle.listingIdPara}>Your request (ID: {this.state.buyerAddedListingID}) has been submitted successfully.</p>
                    <p style={webStyle.listingIdPara}>Automatic offers have been extended to matching listing on the platform.</p><p style={webStyle.listingIdPara}>View and manage the offers below.</p>
                    <Button onClick={() => this.handleMatchingOffersNavigations()} style={{ color: "#fff", background: "#FF5C00", fontSize: "16px", fontWeight: 700, fontFamily: "Asap", borderRadius: "45px", padding: "16px 58px", marginTop: "20px" }}>VIEW OFFER</Button>
                </>
            );
        } else if (!this.state.isMatchesFound && this.state.buyerAddedListingID) {
            content = (
                <>
                    <h1 style={webStyle.listingHeading}>Request Submitted!</h1><p style={webStyle.listingIdPara}>Your request (ID: {this.state.buyerAddedListingID}) has been submitted successfully and shared with the sellers.</p>
                    <p style={webStyle.listingIdPara}>You will be notified when you receive offers.</p>
                </>
            );
        }
        return (
            <Box style={webStyle.listingSubmittedBox} data-test-id="buyerListAdded"><div style={webStyle.textEnd}><img src={close} alt="close" onClick={this.handleCloseAddEditSuccessModal} /></div>
                <div style={{ ...webStyle.textCenter, ...webStyle.pTB50 }}><div style={webStyle.mb20}><img style={webStyle.successImg} src={orangeSucessIcon} alt="success image" /></div>{content}</div>
            </Box>
        );
    }

    onRequestFormSubmit = (values: any) => {
        const isPOLTerminalValid = this.state.buyerPolData?.filter((item: any) => (
            values.POL === item.attributes.port_code
        )).map((item: {attributes: {port_country: string[]}}) => item?.attributes?.port_country)?.flatMap((array: any) => array)?.includes(values.POLTERMINAL)
        
        const isPODTerminalValid = this.state.buyerPolData?.filter((item: any) => (
            values.BUYERPOD === item.attributes.port_code
        )).map((item: {attributes: {port_country: string[]}}) => item?.attributes?.port_country)?.flatMap((array: any) => array)?.includes(values.PODTERMINAL)
        if(isPOLTerminalValid && isPODTerminalValid){
            this.createBuyerListing(values);
        }
        if(
            !isPOLTerminalValid
            ){
            toast.warning("Please select POL Terminal value")
        }
        if(!isPODTerminalValid){
            toast.warning("Please select POD Terminal value")
        }
    }

    BuyerAddListinForm = () => {
        return (
            <StyledFormDialog open={this.state.show} maxWidth="md" fullWidth style={{ borderRadius: "24px" }}>
                {this.state.addBuyerListing && (
                    <StyleModel>
                        <Formik

                            enableReinitialize={this.state.reinitialisedForm}
                            validationSchema={() => this.NewBuyerListingSchema()}
                            validateOnChange={true}
                            initialValues={{
                                BUYERPRINCIPALORGANISATION: (!Array.isArray(this.state.buyerPrinicpalOrgList) && this.state.buyerPrinicpalOrgList?.attributes?.name) || "",
                                BUYERPOD: "",
                                PODTERMINAL: "",
                                POL: "",
                                POLTERMINAL: "",
                                ETBATPOL: "",
                                ETAATPOD: "",
                                CONTAINERTYPE: "",
                                CONTAINERCODES: [],
                                CONTAINERSIZE: "",
                                TOTALQUANTITY: "",
                                AVERAGETONSBOX: "",
                                RATE: "",
                                TERMS: "",
                                TERMSTWO: "",
                                CONTAINERSIZETWO: "",
                                TOTALQUANTITYTWO: "",
                                AVERAGETONSBOXTWO: "",
                                RATETWO: "",
                                TERMSTHREE: "",
                                TERMSFOUR: "",
                            }}
                            onSubmit={(values) => {
                                this.onRequestFormSubmit(values);
                            }}
                            validateOnBlur={true}
                        >
                            {({
                                errors,
                                touched,
                                setFieldValue,
                                handleSubmit,
                                isValid,
                                values,

                            }) => {
                                return (
                                    <form
                                        onSubmit={handleSubmit}
                                        autoComplete="off"
                                        className="FormOrganisationDetails"
                                    >
                                        <Box style={webStyle.addListingModal}>
                                            <Box
                                                style={webStyle.close}
                                                onClick={this.handleClose}
                                                data-test-id="close-btn"
                                            >
                                                <img src={close} alt="" />
                                            </Box>
                                            <h1 style={webStyle.title}>Add New Request</h1>
                                            <section style={webStyle.organisation}>
                                                <InputLabel htmlFor="BUYERPRINCIPALORGANISATION">
                                                    <ProjectText
                                                        Upercase
                                                        variant="subtitle1"
                                                        TextSize="14px"
                                                        fontweight="900"
                                                        textcolor="black"
                                                    >
                                                        PRINCIPAL ORGANISATION NAME
                                                    </ProjectText>
                                                </InputLabel>
                                                <ProjectTextField
                                                    style={{ height: '47px' }}
                                                    MenuProps={projectTextFieldProps}
                                                    variant="outlined"
                                                    fullWidth
                                                    defaultValue="all"
                                                    FormError={
                                                        errors.BUYERPRINCIPALORGANISATION !== undefined &&
                                                        touched.BUYERPRINCIPALORGANISATION !== undefined
                                                    }
                                                    value={values.BUYERPRINCIPALORGANISATION}
                                                    inputProps={{
                                                        "data-test-id": "BUYERPRINCIPALORGANISATION",
                                                    }}
                                                    id="BUYERPRINCIPALORGANISATION"
                                                    name="BUYERPRINCIPALORGANISATION"
                                                    onChange={(e) =>
                                                        this.AddBuyerListingFormTextFieldChange(
                                                            e,
                                                            setFieldValue,
                                                            "BUYERPRINCIPALORGANISATION",
                                                            values
                                                        )
                                                    }
                                                    disabled = {!Array.isArray(this.state.buyerPrinicpalOrgList)}
                                                >
                                                    {
                                                        this.renderPrincipleOrgBlock()
                                                    }
                                                </ProjectTextField>
                                                {this.BuyerErrorRender(
                                                    errors.BUYERPRINCIPALORGANISATION,
                                                    errors.BUYERPRINCIPALORGANISATION !== undefined &&
                                                    touched.BUYERPRINCIPALORGANISATION !== undefined
                                                )}
                                            </section>

                                            {this.renderVasselBlock(
                                                errors,
                                                touched,
                                                values,
                                                setFieldValue
                                            )}

                                            <section style={webStyle.vesselContainer}>
                                                <h3 style={webStyle.vesselHeading}>
                                                    Container & Pricing Details
                                                </h3>

                                                {this.renderAdditionContainerFirstBlock(
                                                    errors,
                                                    touched,
                                                    values,
                                                    setFieldValue
                                                )}

                                                {this.addContainerSizeButton(this.state.isAddContainerClicked, values, setFieldValue)}


                                                {/* 2nd container size addition part */}

                                                {this.state.isAddContainerClicked && this.renderAdditionContainerSecondBlock(
                                                    errors,
                                                    touched,
                                                    values,
                                                    setFieldValue
                                                )}

                                            </section>

                                            <div style={webStyle.dFlexJCenter}>
                                                <ContainedButton
                                                    type="submit"
                                                    data-test-id="BuyerSubmitForm"
                                                    style={webStyle.btnTheme}
                                                >
                                                    Submit
                                                </ContainedButton>
                                            </div>
                                        </Box>
                                    </form>
                                );
                            }}
                        </Formik>
                    </StyleModel>
                )}

                {/* add buyer success popup */}

                {this.state.buyerListingAdded && this.buyerListAddedModal()}

            </StyledFormDialog>
        );
    };
    onClickBuyerAddListing = () => {
        this.setState({ show: true, addBuyerListing: true, buyerListingAdded: false });
    };

    // Customizable Area End
    render() {
        // Customizable Area Start        
        // Customizable Area End
        return (
            // Customizable Area Start  
            <>   
            {this.state.loading ? this.spinnLoader() :    
                <Grid item xs lg={6} style={buyerStyle.textEnd}>
                    <SectionLIstingWrapper>
                        <div className="btnDivBox" style={buyerStyle.btnDiv}>
                            <button className="TempButton" data-test-id="addBuyerRequest" style={buyerStyle.addRequest} onClick={() => this.onClickBuyerAddListing()}><img src={add} alt="" style={buyerStyle.btnImgDiv} />Add Request</button>
                        </div>
                    </SectionLIstingWrapper>
                {this.BuyerAddListinForm()}
                </Grid>
                } 
                </> 
            // Customizable Area End
        );
    }
}
// Customizable Area Start
export default withRouter(BuyerToggelComponent);

const buyerStyle = {
    root: {
        background: "#eee",
        padding: "30px 0",
    },
    selectSlotItem: {
        display: 'flex',
        width: '100%',
        height: '2.25rem',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '1rem'
    },
    slotsSection: {
        marginBottom: "50px"
    },
    leftBar: {
        display: "flex",
        alignItems: "center",
    },
    slotsFor: {
        margin: 0,
        fontFamily: `"Asap", "sans-serif"`,
        fontWeight: 800,
        fontSize: "28px",
        color: "#1E1E1E"
    },
    selectSlot: {
        width: "400px",
        marginLeft: "15px"
    },
    btnDiv: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    deleteText: {
        fontFamily: "Asap",
        fontSize: "14px",
        color: "#DC2626",
        margin: 0,
        cursor: 'pointer',
    },
    editText: {
        fontFamily: "Asap",
        fontSize: "14px",
        color: "#1E1E1E",
        margin: "0 0 15px 0",
        cursor: 'pointer',
    },
    uncontrolledBox: {
        display: "inline-block",
        padding: "15px",
        minWidth: "150px",
        textAlign: "end" as "end",
    },
    addRequest: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: ".875rem",
        background: "#FF5C00",
        border: "none",
        borderRadius: "2rem",
        padding: ".5625rem 1.125rem",
        color: "#FFFFFF",
        marginRight: "1.875rem",
        fontWeight: 600,
        height: "3rem",
        width: "16rem",
        textTransform: "uppercase" as "uppercase",
        cursor: "pointer",
    },
    addRequestContainer: {
        display: "flex",
        border: "1px solid #000",
        alignItems: "center",
        fontSize: "14px",
        fontFamily: 'Asap',
        justifyContent: "center",
        textTransform: "uppercase" as "uppercase",
        color: "black",
        borderRadius: "32px",
        padding: "9px 18px",
        width: "256px",
        fontWeight: 600,
        marginRight: "30px",

        height: "48px",
    },
    btnImgDiv: {
        marginRight: "8px",
    },
    textEnd: {
        justifyContent: "end",
        display: "flex",
    },
    org: {
        color: "#1E1E1E",
        background: "#B8D5F9",
        fontSize: "15px",
        fontFamily: 'Asap',
        padding: "6px 12px",
        borderRadius: "4px",
        fontWeight: 500,
    },
    tableContent: {
        color: "#363636",
        fontSize: "15px",
        fontFamily: 'Asap',
        fontWeight: 500,
    },
    highlightedTableContent: {
        padding: '8px 0px',
        textAlign: 'center' as 'center',
        width: '100%'
    },
    totalRate: {
        color: "#363636",
        fontSize: "15px",
        fontFamily: 'Asap',
        fontWeight: 800,
    },
    typeDiv: {
        display: "flex",
        color: "#1E1E1E",
        fontFamily: "Asap",
        fontSize: "15px",
        fontWeight: 500,
        background: "#B8D5F9",
        justifyContent: "space-between",
        padding: "6px 12px",
        border: "1px solid #6495D2",
        borderRadius: "4px",
        marginBottom: "3px"
    },
    pipe: {
        position: "absolute" as "absolute",
        background: "#6495D2",
        height: "32px",
        width: "1px",
        top: 0,
        left: "78px"
    },
    quantityDiv: {
        alignItems: "center",
        border: "1px solid #DADADA",
        borderRadius: "8px",
        display: "flex",
        marginRight: "15px",
        padding: "10px 20px",
    },
    available: {
        fontSize: "15px",
        color: "#3C7C09",
        fontFamily: "Asap",
        fontWeight: 500,
    },
    availableQuantity: {
        fontSize: "18px",
        color: "#3C7C09",
        fontFamily: "Asap",
        fontWeight: 500,
        margin: 0
    },
    total: {
        color: "#1E1E1E",
        fontFamily: "Asap",
        fontWeight: 500,
        background: "#EAEAEA",
        padding: "5px 10px",
        fontSize: "15px",
        borderRadius: "4px"
    },
    bookedAmount: {
        fontSize: "15px",
        fontWeight: 500,
        textAlign: "center" as "center",
        fontFamily: "Asap",
        color: "#DB2525",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        marginTop: "10px"
    },
    mr15: {
        marginRight: "15px"
    },
    tableLeftBorder: {
        borderRadius: "6px",
        borderLeft: "3px solid #B8D5F9",
        textAlign: "center" as "center"
    },
    tableRightBorder: {
        borderTopRightRadius: "6px",
        borderBottomRightRadius: "6px",
        textAlign: "center" as "center",
        borderRight: "3px solid #ffffff",
    },
    dFlex: {
        listStyle: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    textCenter: {
        textAlign: "center" as "center"
    },
    MainDiv: {
        height: "192px",
        marginLeft: "33%",
        width: "304px",
        display: "inline-flex",
        flexDirection: "column" as const,
        alignItems: "center",
        marginTop: "6%"
    },
    InnerDiv: {
        width: "180%",
        height: "100%",
        marginLeft: "40%"
    },
    headerImagess: {
        width: "100px",
        height: "100px",
        marginLeft: "30%"
    },
    headingNotFound: {
        color: "#1E1E1E",
        fontFamily: "Asap",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
    },
    headingChanges: {
        color: "#363636",
        fontFamily: "Asap",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
        width: "284px",
        height: "18px",
        marginTop: "auto"
    },
    tableYellowBorder: {
        borderRadius: "6px",
        borderLeft: "3px solid #D0B655",
        textAlign: "center" as "center"
    },
    orgYellow: {
        fontSize: "15px",
        padding: "6px 12px",
        color: "#1E1E1E",
        fontWeight: 500,
        fontFamily: "Asap",
        background: "#FFECA5",
        borderRadius: "4px"
    },
    typeDivYellow: {
        display: "flex",
        justifyContent: "space-between",
        color: "#1E1E1E",
        background: "#FFECA5",
        border: "1px solid #FFECA5",
        fontSize: "15px",
        fontFamily: "Asap",
        fontWeight: 500,
        borderRadius: "4px",
        padding: "6px 12px",
        marginBottom: "3px"
    },
    tableGreenBorder: {
        borderLeft: "3px solid #7DBD43",
        textAlign: "center" as "center",
        borderRadius: "6px",
    },
    orgGreen: {
        background: "#CFF0B0",
        fontWeight: 500,
        fontFamily: "Asap",
        fontSize: "15px",
        padding: "6px 12px",
        borderRadius: "4px",
        color: "#1E1E1E",
    },
    typeDivGreen: {
        justifyContent: "space-between",
        display: "flex",
        background: "#CFF0B0",
        border: "1px solid #7DBD43",
        fontSize: "15px",
        color: "#1E1E1E",
        fontFamily: "Asap",
        padding: "6px 12px",
        borderRadius: "4px",
        fontWeight: 500,
        marginBottom: "3px"
    },
    tableDarkBlueBorder: {
        borderRadius: "6px",
        textAlign: "center" as "center",
        borderLeft: "3px solid #6AA2C6",
    },
    orgDarkBlue: {
        background: "#C2E7FF",
        fontWeight: 500,
        fontFamily: "Asap",
        fontSize: "15px",
        padding: "6px 12px",
        borderRadius: "4px",
        color: "#1E1E1E",
    },
    typeDivDarkBlue: {
        display: "flex",
        justifyContent: "space-between",
        color: "#1E1E1E",
        fontSize: "15px",
        fontWeight: 500,
        borderRadius: "4px",
        fontFamily: "Asap",
        background: "#C2E7FF",
        padding: "6px 12px",
        border: "1px solid #6AA2C6",
        marginBottom: "3px"
    },
    tablePinkBorder: {
        textAlign: "center" as "center",
        borderLeft: "3px solid #FF8276",
        borderRadius: "6px",
    },
    orgPink: {
        fontWeight: 500,
        background: "#F5B7B1",
        fontFamily: "Asap",
        fontSize: "15px",
        color: "#1E1E1E",
        padding: "6px 12px",
        borderRadius: "4px"
    },
    typeDivPink: {
        background: "#F5B7B1",
        display: "flex",
        color: "#1E1E1E",
        justifyContent: "space-between",
        fontSize: "15px",
        fontFamily: "Asap",
        padding: "6px 12px",
        fontWeight: 500,
        borderRadius: "4px",
        border: "1px solid #FF8276",
        marginBottom: "3px"
    },
    tablePurpleBorder: {
        textAlign: "center" as "center",
        borderRadius: "6px",
        borderLeft: "3px solid #D87DFF",
    },
    orgPurple: {
        background: "#D7BDE2",
        fontFamily: "Asap",
        color: "#1E1E1E",
        padding: "6px 12px",
        fontWeight: 500,
        fontSize: "15px",
        borderRadius: "4px"
    },
    typeDivPurple: {
        display: "flex",
        justifyContent: "space-between",
        color: "#1E1E1E",
        background: "#D7BDE2",
        fontSize: "15px",
        padding: "6px 12px",
        fontFamily: "Asap",
        border: "1px solid #D87DFF",
        borderRadius: "4px",
        fontWeight: 500,
        marginBottom: "3px"
    },
    tableMustardBorder: {
        textAlign: "center" as "center",
        borderLeft: "3px solid #FFAA62",
        borderRadius: "6px",
    },
    orgMustard: {
        fontSize: "15px",
        fontWeight: 500,
        padding: "6px 12px",
        color: "#1E1E1E",
        background: "#F5CBA7",
        fontFamily: "Asap",
        borderRadius: "4px"
    },
    typeDivMustard: {
        display: "flex",
        justifyContent: "space-between",
        background: "#F5CBA7",
        fontSize: "15px",
        fontWeight: 500,
        border: "1px solid #FFAA62",
        padding: "6px 12px",
        color: "#1E1E1E",
        fontFamily: "Asap",
        borderRadius: "4px",
        marginBottom: "3px"
    },
    ellipsis: {
        cursor: "pointer"
    },

    addListingModal: {
        padding: "20px 10px",
        borderRadius: "24px",
    },
    p_10_b_30: {
        padding: "10px 30px"
    },
    close: {
        cursor: "pointer",
        textAlign: "end" as "end",
    },
    title: {
        fontSize: "28px",
        margin: "0 0 15px 0",
        fontFamily: "Asap, sans-serif",
        color: "#1E1E1E",
        textAlign: "center" as "center"
    },
    organisation: {
        margin: "0 20px 20px 20px"
    },
    principalLabel: {

        fontWeight: 600,
        fontSize: "14px",
        color: "#363636",
        fontFamily: "Asap, sans-serif",
    },
    principalLabelAverageBox: {
        color: "#363636",
        fontWeight: 600,
        fontFamily: "Asap, sans-serif",
        fontSize: "14px",
        display: "flex",
        justifyContent: "space-between"
    },
    Removes: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        color: "#DB2525",
        fontFamily: "Asap",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "24px",
    },
    icons: {
        width: "5%",
        height: "2%",
        marginLeft: "52%"
    },
    vesselContainer: {
        background: "#E3E6ED",
        padding: "25px 10px",
        borderRadius: "18px",
        marginBottom: "15px"
    },
    vesselHeading: {
        color: "#1E1E1E",
        fontSize: "18px",
        fontFamily: "asap, sans-serif",
        textAlign: "center" as "center",
        margin: "0 0 25px 0"
    },
    inputText: {
        border: "1px solid #CBD5E1",
        height: "44px",
        width: "100%",
        borderRadius: "5px",
        padding: "0 8px"
    },


    bBottom: {
        margin: "0 2px 20px 2px",
        border: "1px solid #CBD5E1",
    },
    rateBox: {
        alignItems: "center",
        border: "1px solid #CBD5E1",
        display: "flex",
        background: "#fff",
        borderRadius: "8px",
    },
    borderRight: {
        height: "41px",
        borderRight: "1px solid #CDCDCD",
    },

    rateDiv: {
        fontSize: "14px",
        color: "#1E1E1E",
        fontFamily: "Asap, sans-serif"
    },
    usd: {
        margin: "0 10px",
    },
    inputRateText: {
        height: "44px",
        border: "none",
        borderTopLeftRadius: "5px",
        width: "40px",
        padding: "0 8px",
        borderBottomLeftRadius: "5px",
    },
    slash: {
        position: "relative" as "relative",
        color: "gray",
        left: "12px"
    },
    ml5: {
        width: "4%",
        marginRight: '85%',
    },
    dFlexJCenter: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    submitBtn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "16px",
        background: "#AA4649",
        border: "none",
        borderRadius: "32px",
        padding: "9px 18px",
        color: "#FFFFFF",
        fontWeight: 600,
        height: "45px",
        width: "256px",
        textTransform: "uppercase" as "uppercase",
        cursor: "pointer",
        fontFamily: 'Asap',
    },
    BuyerSubmittedBox: {
        padding: "10px 20px"
    },
    mb20: {
        marginBottom: "20px"
    },
    BuyerHeading: {
        color: "#1E1E1E",
        fontSize: "28px",
        fontFamily: "Asap, sans-serif",
        fontWeight: 700
    },
    successBtn: {
        width: "10%"
    },

    pTB50: {
        padding: "50px 0"
    },
    noDataShow: {
        fontFamily: "Asap",
        paddingTop: "6rem",
    },
    noDataImgShow: {
        height: "5rem",
    },
    noDtataContent: {
        fontFamily: "Asap",
        fontSize: "2rem",
        margin: "0.5rem",
    },
    dataMessage: {
        fontFamily: "Asap",
        margin: "0rem",
        fontSize: "1rem",
    },
}

const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      boxShadow: "0rem .375rem .9375rem -0.1875rem rgba(0, 0, 0, 0.25)",
      backgroundColor: theme.palette.common.white,
      color: "black",
      fontSize: "12px",
      display: " inline-flex",
      justifyContent: "center",
      borderRadius: ".5rem",
      alignItems: "center",
      fontFamily: "Asap, sans-serif",
      padding: ".5rem 1rem",
    },
    arrow: {
      color: "white"
    }
  }))(Tooltip);
const projectTextFieldProps: Partial<MenuProps> = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    getContentAnchorEl: null
}
const StyledFormDialog = styled(Dialog)({
    "& .MuiDialog-paperWidthMd": {
        borderRadius: "24px"
    }
})
const StyleModel = styled(Box)({
    "& .inputHeight": {
        height: "initial !important"
    },
    "& .ErrorTextContainer": {
        display: "flex",
        alignItems: "center",
        marginTop: "5px",
        "& .TextHidden": {
            visibility: "hidden"
        },
        "& .TextError": {
            fontSize: "16px",
            fontFamily: "Asap, sans-serif",
            color: "red",
            marginBottom: "0px",
            transition: "width 1s ease-out ",
        },
        "& .ErrorIconContainer": {
            "& .MuiSvgIcon-root": {
                color: "red"
            },
            marginRight: "6px",
        },
    },
    "& .MultiSelect": {
        "& .MuiOutlinedInput-input": {
            padding: "5px 12px",
            height: "33px"
        }
    },
    "& .dial-code": {
        fontFamily: "Asap, sans-serif",
        fontWeight: "500",
        color: "black !important",
    },
    "& .country-name": {
        fontFamily: "Asap, sans-serif",
    },
    "& .PhoneInputPackgeClass": {
        height: "40px",
        fontFamily: "Asap, sans-serif",
        width: "100%",
        borderRadius: "10px",
        fontWeight: 500,
        fontSize: "15px",
        backgroundColor: "white",
        "@media (max-width: 1200px)": {
            height: "30px",
            fontSize: "12px",
        }
    },
    "& .no-entries-message": {
        color: "black !important",
        fontFamily: "Asap, sans-serif",
        fontWeight: "500",
    },

    "& .ErrorPhoneClass": {
        "& .selected-flag": {
            backgroundColor: "#FBE9E9"
        },
        "& .PhoneInputPackgeClass": {
            backgroundColor: "#FBE9E9"
        },
        "& .flag-dropdown": {
            backgroundColor: "#FBE9E9"
        },
    },
    "& .selected-flag": {
        backgroundColor: "white",
        borderRadius: "10px"
    },
    "& .flag-dropdown": {
        borderRadius: "10px 0px 0px 10px",
        borderRight: "none",
    },
    "& .PhoneInput": {
        position: "relative",
        "& .PhoneError": {
            color: "red",
            fontSize: "18px",
            position: "absolute",
            margin: "6px 14px",
        }
    },
    "& .CodesMultiSelect": {
        height: "56px"
    },
    "& .AutoComplateTextField": {
        color: "#3CA8D6",
    },
    "& .DeleteChip": {
        margin: "0 2px",
        backgroundColor: "#e3e6ed",
        color: "black",
        borde: "none",
        fontWeight: "700",
        fontFamily: "Asap",
    },
   
    "& .MuiAutocomplete-inputRoot":{    
        fontFamily:'asap'
      },
    "& .ViewlessContainer": {
        "& .MuiInputBase-input .Mui-disabled": {
            color: "black"
        }
    },
    "& .SellerMultipleSelect": {
        "& .SellerReactSelects__control": {
            borderRadius: "10px",
        }
    },
    "& .centerGrid": {
        justifyContent: "center"
    },
    "& .AddContainerFirstButton": {
        display: "flex",
        justifyContent: "flex-end",
        whiteSpace: "nowrap",
        "& .Mui-disabled": {
            backgroundColor: "lightgrey",
            color: "grey",
            "& .BlackPlusIcon": {
                filter: "brightness(1)"
            },
        },
        "@media (max-width: 960px)": {
            display: "flex",
            justifyContent: "center"
        }
    },
    "& .SlashClassContainer": {
        position: "relative",
        "& .SlashClass": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            transform: "translate(40%, 100%)"
        },
    },
    "& .CenterAddContainer": {
        display: "flex",
        justifyContent: "center !important",
        "& .Mui-disabled": {
            backgroundColor: "lightgrey",
            color: "grey"
        },
        "@media (max-width: 960px)": {
            display: "flex",
            justifyContent: "center"
        }
    },
    "& .CurrencyBox": {
        marginRight: "5px"
    },
    "& .ViewMoreContainer": {
        position: "absolute",
        right: "20px",
        "@media (max-width: 600px)": {
            marginTop: "20px"
        }
    },
    "& .BlackPlusIcon": {
        filter: "brightness(0)"
    },
    "& .AddContainerSecondButton": {
        display: "flex",
        justifyContent: "flex-start",
        "& .Mui-disabled": {
            backgroundColor: "lightgrey",
            color: "grey"
        }
    },
    "& .AddContainerSecondButtonCenter": {
        display: "flex",
        justifyContent: "flex-start",
        "& .Mui-disabled": {
            backgroundColor: "lightgrey",
            color: "grey"
        },
        "@media (max-width: 960px)": {
            display: "flex",
            justifyContent: "center"
        }
    },
    "& .DivedSection": {
        "& .MuiDivider-root": {
            backgroundColor: "white",
            height: "5px"
        }
    },
});
const webStyle = {

    btnImgDiv: {
        marginRight: "8px",
    },

    addListingModal: {
        padding: "20px 10px",
    },
    title: {
        fontSize: "28px",
        color: "#1E1E1E",
        fontFamily: "Asap, sans-serif",
        margin: "0 0 15px 0",
        textAlign: "center" as "center",
    },
    close: {
        textAlign: "end" as "end",
        cursor: "pointer",
    },
    organisation: {
        margin: "0 20px 20px 20px",
    },
    principalLabel: {
        color: "#363636",
        fontSize: "14px",
        fontFamily: "Asap, sans-serif",
        fontWeight: 600,
    },
    vesselHeading: {
        color: "#1E1E1E",
        fontSize: "18px",
        fontFamily: "asap, sans-serif",
        textAlign: "center" as "center",
        margin: "0 0 25px 0",
    },
    vesselContainer: {
        background: "#E3E6ED",
        padding: "25px 10px",
        borderRadius: "16px",
        marginBottom: "8px",
    },

    inputText: {
        border: "1px solid #CBD5E1",
        height: "44px",
        width: "100%",
        borderRadius: "5px",
        padding: "0 8px",
    },
    validityBox: {
        display: "flex",
        alignItems: "center",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
    },
    beforeETB: {
        color: "#797979",
        fontSize: "14px",
        fontFamily: "Asap, sans-serif",
    },

    etbDiv: {
        marginLeft: "10px",
    },

    expiryDate: {
        color: "#1E1E1E",
        fontSize: "14px",
        fontFamily: "Asap, sans-serif",
    },

    bBottom: {
        border: "1px solid #CBD5E1",
        margin: "0 2px 20px 2px",
    },
    validtyInputText: {
        border: "none",
        height: "44px",
        width: "40px",
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "5px",
        padding: "0 8px",
    },
    rateBox: {
        display: "flex",
        alignItems: "center",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        background: "#fff",
    },
    expiryDateDiv: {
        display: "flex",
        alignItems: "center",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        height: "40px",
        paddingLeft: "10px",
    },
    borderRight: {
        borderRight: "1px solid #CDCDCD",
        height: "41px",
    },

    rateDiv: {
        color: "#1E1E1E",
        fontSize: "14px",
        fontFamily: "Asap, sans-serif",
    },

    mb20: {
        marginBottom: "20px",
    },
    usd: {
        margin: "0 10px",
    },

    slash: {
        color: "gray",
        position: "relative" as "relative",
        left: "12px",
    },
    inputRateText: {
        border: "none",
        height: "44px",
        width: "40px",
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "5px",
        padding: "0 8px",
    },
    ml5: {
        marginLeft: "5px",
    },
    dFlexJCenter: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    listingSubmittedBox: {
        padding: "10px 20px",
    },

    btnTheme: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "16px",
        background: "#FF5C00",
        border: "none",
        borderRadius: "32px",
        padding: "9px 18px",
        color: "#FFFFFF",
        fontWeight: 600,
        height: "45px",
        width: "256px",
        textTransform: "uppercase" as "uppercase",
        cursor: "pointer",
        marginTop: "10px"
    },



    monthlyPricing: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "14px",
        background: "transparent",
        border: "1px solid #1E1E1E",
        borderRadius: "32px",
        color: "#1E1E1E",
        marginRight: "30px",
        fontWeight: 600,
        height: "48px",
        width: "256px",
        textTransform: "uppercase" as "uppercase",
        cursor: "pointer",
        fontFamily: "Asap"
    },
    listingIdPara: {
        fontSize: "16px",
        fontFamily: "Asap, sans-serif",
        color: "#363636",
        margin: "0 0 5px 0",
    },

    textEnd: {
        display: "flex",
        justifyContent: "end",
    },
    textCenter: {
        textAlign: "center" as "center",
    },

    pTB50: {
        padding: "50px 0",
    },
    listingHeading: {
        fontSize: "28px",
        fontFamily: "Asap, sans-serif",
        color: "#1E1E1E",
        fontWeight: 700,
    },
    deletReqId: {
        color: "#1E1E1E",
        fontWeight: 700,
    },
    successImg: {
        width: '100px',
        height: '100px',
    },
    deleteText: {
        color: "#1E1E1E",
        fontFamily: "Asap",
        fontSize: "14px",
        margin: 0,
        cursor: "pointer",
    },
    editText: {
        fontSize: "14px",
        color: "#1E1E1E",
        fontFamily: "Asap",
        margin: "0 0 15px 0",
        cursor: "pointer",
    },
    uncontrolledBox: {
        display: "inline-block",
        padding: "15px",
        minWidth: "100px",
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        textAlign: "end" as "end",
        borderRadius: "8px"
    },

    // Delete modal css

    cancelBtn: {
        color: "#000000",
        fontSize: "16px",
        border: "1px solid #000000",
        borderRadius: "32px",
        background: "#FFFFFF",
        padding: "9px 18px",
        fontWeight: 600,
        height: "45px",
        cursor: "pointer",
        width: "212px",
        textTransform: "uppercase" as "uppercase",
    },
    deleteBtn: {
        fontSize: "16px",
        color: "#FFFFFF",
        background: "#DB2525",
        border: "none",
        padding: "9px 18px",
        cursor: "pointer",
        borderRadius: "32px",
        fontWeight: 600,
        height: "45px",
        width: "212px",
        textTransform: "uppercase" as "uppercase",
        marginRight: "15px",
    },
    // Delete modal css


    removeBtnBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: 'relative' as 'relative'
    }
};
// Customizable Area End