// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { RouteComponentProps } from "react-router";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
import { toast } from "react-toastify";
import moment from "moment";
import { DateRange } from "materialui-daterange-picker";
import { FilterOptionsState } from "@material-ui/lab/useAutocomplete";

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  currentTab: number;
  anchorEl: any;
  page: number;
  rowsPerPage: number;
  popup: boolean;
  switch: boolean;
  show: boolean;
  addListing: boolean;
  listingAdded: boolean;
  matchingRequestFound: boolean;
  listUpdated: boolean;
  listDeleted: boolean;
  showGuideVedios: boolean;
  token: string;
  vesselVoyageData: Array<{
    id: number;
    vessel_code: string;
    in_voyage_code: string;
    out_voyage_code: string;
  }>;
  voyageData: Array<{
    id: string | number,
    vessel_code: string;
    in_voyage_code: string;
    out_voyage_code: string;
  }>;
  containerData: Array<{
    container_type: string;
    seller_box_sizes: Array<string>;
    codes: Array<string>;
  }>;
  selectedContainer: any;
  sellerListings: any;
  showEditDeletePopUp: boolean;
  prinicpalOrgList: any;
  etbDate: string;
  etaDate: string;
  priceAndTermData: any;
  addedListingID: string;
  expiry_date: any;
  avarageTons: string;
  loginData: any;
  loading: boolean;
  isViewLess: boolean;
  FormikFormReenableReinitialize: boolean,
  PolDropDownData: Array<{
    PortId: string,
    Type: string,
    PortName: string,
    PortCountry: string,
    PortRegion: string,
    PortDescription: string
  }>,
  AllPriceTermsData: Array<{
    id: string,
    BoxSize: string
    Container: string
    PortOfDestination: string
    PortOfLocation: string
    Rate: string
    TermsOne: string,
    TermsTwo: string,
  }>
  polData: any
  sellerListingSnackbarOpen: boolean,
  DeleteTablePopUpIndex: number
  isSlotFilterOpen: boolean,
  activeSlotFilter: any,
  FormEditData: {
    PrincipalOrganizationName: string,
    Vessel: string,
    Voyage: string,
    Pod: string,
    PodTerminal: string,
    Pol: string,
    PolTerminal: string,
    Email: string,
    EtaPod: string,
    EtbPol: string,
    FullName: string,
    OrganizationCode: string,
    ContactNumber: string,
    DistributionEmail: string,
    Expiry: string,
    TotalTeu: string,
    Validity: string
  },
  ContainerEditDetails: Array<
    {
      CONTAINERTYPE: string,
      CONTAINERCODES: string,
      ContainerId: string,
      CONTAINERSIZEARRAY: Array<{
        ContainerSizeId: string,
        CONTAINERSIZE: string,
        AVERAGETONSBOX: string,
        RATE: string,
        TERMSONE: string,
        TERMSTWO: string,
      }>
    }
  >,
  FormIsEdit: boolean,
  DeleteModel: boolean,
  DeleteErrorModel: boolean,
  DeleteId: string,
  DeleteListingUid: string,
  DeleteContainer: Array<{
    DeleteContainerType?: number,
    DeleteContainerSize?: Array<number>
  }>,
  EditFormId: string,
  ListingOpen: boolean,
  offerExpanded: boolean;
  offerExpandedPol: boolean,
  offerExpandedPod: boolean;
  offerExpandedtype: boolean;
  filter: {
    listingID: Array<{ label: string; isChecked: boolean }>;
    pol: Array<{ label: string; isChecked: boolean }>;
    pod: Array<{ label: string; isChecked: boolean }>;
    vessel: Array<{ label: string; isChecked: boolean; vesselId?:number }>;
    startDate: string;
    endDate: string;
    principalOrgFilter: Array<string>
  };
  open: boolean,
  StartDate: string,
  EndDate: string,
  RenderStart: string,
  RenderEndDate: string,
  PrincipalOrganizationSelectedName: string,
  EmailDistributionListData: Array<string>,
  PolTerminalDropDown: Array<string>,
  PodTerminalDropDown: Array<string>,
  DynamicCount: {
    listing?: string | number,
    requst?: string | number,
    offers?: string | number,
    booking?: string | number
  },
  DateRangeStartDate: Date | undefined,
  DateRangeEndDate: Date | undefined
}

export interface SS {
  id: any;
}

export default class SellerListingController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.PostDetailDataMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    (async () => {
      let listingLoginData = await getStorageData("UserRole");
      if(!listingLoginData){
        window.location.href = "/"
      } else if(listingLoginData == "buyer"){
        window.location.href = "/BuyersRequest"
      }
    })()

    this.state = {
      currentTab: 1,
      anchorEl: null,
      page: 0,
      rowsPerPage: 10,
      popup: false,
      switch: true,
      show: false,
      addListing: false,
      listingAdded: false,
      matchingRequestFound: false,
      listUpdated: false,
      listDeleted: false,
      showGuideVedios: false,
      token: "",
      vesselVoyageData: [],
      voyageData: [],
      containerData: [],
      selectedContainer: [],
      sellerListings: [],
      showEditDeletePopUp: false,
      prinicpalOrgList: [],
      etbDate: "",
      etaDate: "",
      priceAndTermData: {},
      addedListingID: "",
      expiry_date: "",
      avarageTons: "",
      loginData: {},
      loading: true,
      isViewLess: false,
      polData: [],
      FormikFormReenableReinitialize: false,
      PolDropDownData: [],
      AllPriceTermsData: [],
      sellerListingSnackbarOpen: false,
      DeleteTablePopUpIndex: -1,
      isSlotFilterOpen: false,
      activeSlotFilter: [],
      FormEditData: {
        PrincipalOrganizationName: "",
        Vessel: "",
        Voyage: "",
        Pod: "",
        PodTerminal: "",
        Pol: "",
        PolTerminal: "",
        Email: "",
        EtaPod: "",
        EtbPol: "",
        FullName: "",
        OrganizationCode: "",
        ContactNumber: "",
        DistributionEmail: "",
        Expiry: "",
        TotalTeu: "",
        Validity: ""
      },
      ContainerEditDetails: [
        {
          CONTAINERTYPE: "",
          CONTAINERCODES: "",
          ContainerId: "",
          CONTAINERSIZEARRAY: [{
            ContainerSizeId: "",
            CONTAINERSIZE: "",
            AVERAGETONSBOX: "",
            RATE: "",
            TERMSONE: "",
            TERMSTWO: "",
          }]
        }
      ],
      FormIsEdit: false,
      DeleteModel: false,
      DeleteErrorModel: false,
      DeleteId: "",
      DeleteListingUid: "",
      DeleteContainer: [],
      EditFormId: "",
      ListingOpen: false,
      offerExpanded: false,
      offerExpandedPol: false,
      offerExpandedPod: false,
      offerExpandedtype: false,
      filter: {
        listingID: [],
        pol: [],
        pod: [],
        vessel: [],
        startDate: '',
        endDate: "",
        principalOrgFilter: []
      },
      open: false,
      StartDate: "",
      EndDate: "",
      RenderStart: "",
      RenderEndDate: "",
      PrincipalOrganizationSelectedName: "",
      EmailDistributionListData: [],
      PolTerminalDropDown: [],
      PodTerminalDropDown: [],
      DynamicCount: {
        listing: "0",
        requst: "0",
        offers: "0",
        booking: "0"
      },
      DateRangeStartDate: new Date(),
      DateRangeEndDate: new Date()
    };
  }

  async componentDidMount() {
    let loginData = await getStorageData("loginData");
    let LocationToken = await getStorageData("authToken");
    let obj = JSON.parse(loginData)
    this.setState({ loginData: obj })
    this.OnloadApicall(LocationToken);
  }

  OnloadApicall = (LocationToken: string) => {
    this.setState({ token: LocationToken });
    this.getSellerListingData();
    this.getVesselVoyages();
    this.getContainerList(LocationToken);
    this.getPrincipalOrganisation(LocationToken);
    this.getDateData(LocationToken);
    this.getPolData(LocationToken)
    this.getPriceAndTerms()
  };

  getSellerListingDataApiCallID: string = "";
  getContainerListApiCallID: string = "";
  getDateDataApiCallID: string = "";
  getPrincipalOrganisationDataApiCallID: string = "";
  getPriceAndTearmsDataApiCallID: string = "";
  getVesselVoyagesApiCallID: string = "";
  createSellerListingApiCallID: string = "";
  deleteSellerListingApiCallID: string = "";
  getPolDataApiCallID: string = "";
  onSellerListingSwitchRoleAPICallID: string = "";
  applyFilterApiCallID: string = "";

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getVesselVoyagesApiCallID) {
      const DataAPIresposne = responseJson.data?.map(
        (item: {
          id: number,
          attributes: {
            vessel_code: string;
            in_voyage_code: string;
            out_voyage_code: string;
          };
        }) => {
          return {
            id: item.id,
            vessel_code: item.attributes.vessel_code,
            in_voyage_code: item.attributes.in_voyage_code,
            out_voyage_code: item.attributes.out_voyage_code,
          };
        }
      );
      this.setState({ vesselVoyageData: DataAPIresposne });
      setStorageData("voyageData", JSON.stringify(DataAPIresposne))
    }
    if (apiRequestCallId === this.applyFilterApiCallID) {
      this.setState({ sellerListings: responseJson, open: false, DynamicCount: { ...this.state.DynamicCount, listing: responseJson.length ||0 } });
    }
    if (apiRequestCallId === this.getContainerListApiCallID) {
      const Data = responseJson?.data?.map(
        (item: {
          attributes: {
            container_type: string;
            seller_box_sizes: Array<string>;
            codes: Array<string>;
          };
        }) => {
          return {
            container_type: item?.attributes?.container_type,
            seller_box_sizes: item?.attributes?.seller_box_sizes,
            codes: item?.attributes?.codes,
          };
        }
      );
      this.setState({ containerData: Data });
      setStorageData("containerData", JSON.stringify(Data))
    }
    if (apiRequestCallId === this.getSellerListingDataApiCallID) {
      this.setState({ sellerListings: responseJson, loading: false });
      let temp = {
        listingID: responseJson.map(
          (val: { seller_listing: { data: { attributes: { listing_uid: string } } } }) => val.seller_listing.data.attributes.listing_uid
        ),
        pol: responseJson.map((val: { seller_listing: { data: { attributes: { pol: string } } } }) => val.seller_listing.data.attributes.pol),
        pod: responseJson.map((val: { seller_listing: { data: { attributes: { pod: string } } } }) => val.seller_listing.data.attributes.pod),
        vessel: responseJson.map(
          (val: { seller_listing: { data: { attributes: { vessel: string, voyage: string, vessel_voyage_id: number } } } }) => { return { name: `${val.seller_listing.data.attributes.vessel}/${val.seller_listing.data.attributes.voyage}`, id: val.seller_listing.data.attributes.vessel_voyage_id } }
        ),
        startDate: responseJson.map((val: { seller_listing: { data: { attributes: { etb_pol: any } } } }) => val.seller_listing.data.attributes.etb_pol),
        endDate: responseJson.map((val: { seller_listing: { data: { attributes: { eta_pod: any } } } }) => val.seller_listing.data.attributes.eta_pod),
      };
      // let a1 = [...new Set(temp.listingID)]
      const a1 = temp.listingID.filter((item: string, index: number) => {
        return temp.listingID.indexOf(item) == index;
      })
      // let a2 = [...new Set(temp.pol)]
      const a2 = temp.pol.filter((item: string, index: number) => {
        return temp.pol.indexOf(item) == index;
      })
      // let a3 = [...new Set(temp.pod)]
      const a3 = temp.pod.filter((item: string, index: number) => {
        return temp.pod.indexOf(item) == index;
      })
      // let a4 = [...new Set(temp.vessel)]
      const a4 = temp.vessel.filter((item: string, index: number) => {
        return temp.vessel.indexOf(item) == index;
      })
      let arr = {
        listingID: a1.map((str: string) => ({
          label: str,
          isChecked: false,
        })),
        pol: a2.map((str: string) => ({ label: str, isChecked: false })),
        pod: a3.map((str: string) => ({ label: str, isChecked: false })),
        vessel: Array.from(new Set(a4.map((str:any) => str.name))).map(name => {
          const found = a4.find((v:any) => v.name === name);
          return {
              label: found.name,
              vesselId: found.id,
              isChecked: false
          };
      }),
        startDate: "",
        endDate: "",
        principalOrgFilter: []
      };
      this.setState({
        filter: arr,
        DynamicCount: { ...this.state.DynamicCount, listing: responseJson.length || 0 }
      });
    }
    if (apiRequestCallId === this.getPrincipalOrganisationDataApiCallID) {
      this.setState({ prinicpalOrgList: responseJson?.data });
      setStorageData("PrincipalOrganisation", JSON.stringify(responseJson?.data))
    }
    if (apiRequestCallId === this.getPriceAndTearmsDataApiCallID) {
      let Priceandtermsdata = responseJson?.data.map((item: {
        id: string, attributes: {
          box_size: string,
          container_type: string, id: number
          port_of_destination: string,
          port_of_location: string
          price_and_currency: string,
          terms: string
        }
      }) => {
        return {
          id: item.id,
          BoxSize: item.attributes.box_size,
          Container: item.attributes.container_type,
          PortOfDestination: item.attributes.port_of_destination,
          PortOfLocation: item.attributes.port_of_location,
          Rate: item.attributes.price_and_currency,
          TermsOne: item.attributes.terms.split(/[\/,]/)[0]?.trim(),
          TermsTwo: item.attributes.terms.split(/[\/,]/)[1]?.trim(),
        }
      })
      this.setState({ priceAndTermData: responseJson, AllPriceTermsData: Priceandtermsdata });
    }
    if (apiRequestCallId === this.getDateDataApiCallID) {
      this.setState({
        etbDate: responseJson.container_loading_range_end,
        etaDate: responseJson.container_loading_range_start,
      });
    }

    this.OnSellerListingSwitch(apiRequestCallId, responseJson)
    this.DeletApiCallResponseSet(apiRequestCallId, responseJson)
  }

  OnSellerListingSwitch = (apiRequestCallId: string, responseJson: { message: string }) => {
    if (apiRequestCallId === this.onSellerListingSwitchRoleAPICallID) {
      if (!responseJson.message) {
        this.setState({ sellerListingSnackbarOpen: true })
      } else {
        setStorageData("UserRole", "buyer")
        this.props.navigation.navigate("BuyersRequest")
      }
    }
  }

  DeletApiCallResponseSet = (apiRequestCallId: string, responseJson: { message: string }) => {
    if (apiRequestCallId === this.deleteSellerListingApiCallID) {
      if (responseJson?.message === "Seller Listing deleted successfully") {
        this.OnloadApicall(this.state.token)
        toast.success(responseJson?.message)
        this.DeleteModelOpenClose("", "")
      }
      else if (responseJson?.message === "This listing can not be deleted, as there is a booking under process for this.") {
        this.OnloadApicall(this.state.token)
        this.DeleteModelOpenClose("", "")
        this.DeleteErrorModelOpenClose()
      }
    }
  }

  getSellerListingData = async () => {
    let LocationToken = await getStorageData("authToken");
    const header = {
      "Content-Type": "application/json",
      token: LocationToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSellerListingDataApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_listdemandpickflow/seller_listings"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPrincipalOrganisation = (token: string) => {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPrincipalOrganisationDataApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/principal_organizations"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getDateData = (token: string) => {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDateDataApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_listdemandpickflow/pending_requests/set_date"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onSellerListingSwitchRole = async () => {
    let authToken = await getStorageData("authToken");
    const header = {
      "Content-Type": "application/json",
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.onSellerListingSwitchRoleAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/switch_role`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPriceAndTerms = async () => {
    let token = await getStorageData("authToken");
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPriceAndTearmsDataApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_admin/price_managements`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getContainerList = (token: string) => {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getContainerListApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_content_management/container_managements"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getVesselVoyages = async () => {
    let LocationToken = await getStorageData("authToken");
    const header = {
      "Content-Type": "application/json",
      token: LocationToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVesselVoyagesApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/vessel_voyages?principal_organization_name=${this.state.PrincipalOrganizationSelectedName}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  DeleteModelOpenClose = (id: string, Uid: string) => {
    this.setState({
      DeleteModel: !this.state.DeleteModel,
      DeleteId: id,
      DeleteListingUid: Uid
    })
  }
  DeleteErrorModelOpenClose = () => {
    this.setState({
      DeleteErrorModel: !this.state.DeleteErrorModel,
    })
  }

  DeleteSellerListing = async (id: string) => {
    let LocationToken = await getStorageData("authToken");
    const header = {
      "Content-Type": "application/json",
      token: LocationToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteSellerListingApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_listdemandpickflow/seller_listings/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPolData = (token: string) => {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPolDataApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_content_management/ports"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleTabChange = (event: any, newValue: any) => {
    this.setState({ currentTab: newValue });
  };

  SwitchChnages = () => {
    this.onSellerListingSwitchRole()
  };

  EditFuncationOnclick = async (id: string) => {
    const EditData = this.state.sellerListings.filter((data: { seller_listing: { data: { id: string } } }) => {
      if (data.seller_listing.data.id === id) {
        return data
      }
    })
    this.setState({
      EditFormId: EditData[0].seller_listing.data.id,
    }, () => {
      this.setState({
        EditFormId: "",
      })
    })
  }

  HandelCloseDeleteAndEdit = (e: React.MouseEvent<HTMLDivElement | Document, MouseEvent>, id: number) => {
    this.setState({
      showEditDeletePopUp: !this.state.showEditDeletePopUp,
      anchorEl: e,
      DeleteTablePopUpIndex: id
    })
  }

  handleSnackbarClose = () => { this.setState({ sellerListingSnackbarOpen: false }) };

  handleSlotFilter = (principalOrg: any, isChecked: boolean) => {
    console.log("called",principalOrg);
    
    const hasActiveFilter = this.state.activeSlotFilter.find((slot: any) => slot.attributes.code === principalOrg.attributes.code && slot.attributes.name === principalOrg.attributes.name)
    if (isChecked && !hasActiveFilter) {
      this.setState(prevState => ({ activeSlotFilter: [...prevState.activeSlotFilter, principalOrg] }))
      setTimeout(() => {
        this.applyFilter()
      }, 1000)
      return
    }
    this.setState(prevState => ({ activeSlotFilter: prevState.activeSlotFilter.filter((item: any) => item.attributes.code !== principalOrg.attributes.code && item.attributes.name !== principalOrg.attributes.name) }));
    setTimeout(() => {
      this.applyFilter()
    }, 1000)
  }

  applyFilter = async () => {
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const reqUidData = this.state.filter.listingID?.reduce(
      (acc: string[], item: { label: string; isChecked: boolean }) => {
        if (item.isChecked) acc.push(item.label);
        return acc;
      },
      []
    );

    const polsData = this.state.filter.pol?.reduce(
      (acc: string[], item: { label: string; isChecked: boolean }) => {
        if (item.isChecked) acc.push(item.label);
        return acc;
      },
      []
    );

    const podsData = this.state.filter.pod?.reduce(
      (acc: string[], item: { label: string; isChecked: boolean }) => {
        if (item.isChecked) acc.push(item.label);
        return acc;
      },
      []
    );

    const typesData = this.state.filter.vessel?.reduce(
      (acc: string[], item: { label: string; isChecked: boolean }) => {
        if (item.isChecked) acc.push(item.label);
        return acc;
      },
      []
    );
    const typesDataId = this.state.filter.vessel?.reduce(
      (acc: number[], item: { label: string; vesselId?: number; isChecked: boolean }) => {
        if (item.isChecked) acc.push(item.vesselId || 0);
        return acc;
      },
      []
    );
    const principalOrganisation = this.state.activeSlotFilter?.map((elem: any) => elem?.attributes?.name)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.applyFilterApiCallID = requestMessage.messageId;

    const queryParams = {
      seller_ids: reqUidData,
      pods: podsData,
      pols: polsData,
      vessels: typesData,
      etb_pol_beginning: this.state.filter.startDate,
      etb_pol_end: this.state.filter.endDate,
      vessel_voyage_ids: typesDataId,
    };
    const formattedParams = Object.fromEntries(
      Object.entries(queryParams).map(([key, value]) => {
        return [
          key,
          value,
        ]
      }
      )
    );
    const principalOrganizationParam = principalOrganisation.length > 0 ? `principal_organization_names=${JSON.stringify(principalOrganisation)}` : '';

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_listdemandpickflow/seller_listings/apply_seller_filters?seller_ids=${JSON.stringify(
        formattedParams.seller_ids
      )}
      &pols=${JSON.stringify(formattedParams.pols)}
      &pods=${JSON.stringify(formattedParams.pods)}
      &vessels=${JSON.stringify(formattedParams.vessels)}
      &etb_pol_end=${formattedParams.etb_pol_end}
      &etb_pol_beginning=${formattedParams.etb_pol_beginning}
      &vessel_voyage_ids=${JSON.stringify(formattedParams.vessel_voyage_ids)}
      &${principalOrganizationParam}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleOfferExpanded = (val: string) => {
    if (val === "pol") {
      this.setState({ offerExpandedPol: this.state.filter.pol?.length > 0 });
    }
    if (val === "listingID") {
      this.setState({ offerExpanded: this.state.filter.listingID?.length > 0 });
    }
    if (val === "vessel") {
      this.setState({ offerExpandedtype: this.state.filter.vessel?.length > 0 });
    }
    if (val === "pod") {
      this.setState({ offerExpandedPod: this.state.filter.pod?.length > 0 });
    }
  };

  handleOfferChange = (
    event: any,
    values: Array<{ label: string; isChecked: boolean }>,
    arrName: string
  ) => {

    const { name, checked } = event.target;

    const updatedOptions = Array.isArray(values) ? values?.map(
      (item: { label: string; isChecked: boolean; vesselId?: number }) => {
        return item.label === name ? { ...item, isChecked: checked } : item;
      }
    ) : values;
    if (arrName === "listingID") {
      let temp = { ...this.state.filter, listingID: updatedOptions };
      this.setState({ filter: temp }, () => {
        this.applyFilter();
      });
    }
    if (arrName === "pol") {
      let temp = { ...this.state.filter, pol: updatedOptions };
      this.setState({ filter: temp }, () => {
        this.applyFilter();
      });
    }
    if (arrName === "pod") {
      let temp = { ...this.state.filter, pod: updatedOptions };
      this.setState({ filter: temp }, () => {
        this.applyFilter();
      });
    }
    if (arrName === "vessel") {
      let temp = { ...this.state.filter, vessel: updatedOptions };
      this.setState({ filter: temp }, () => {
        this.applyFilter();
      });
    }
  };
  handleOfferDelete = (
    option: {
      label: string;
      isChecked: boolean;
    },
    values: Array<{ label: string; isChecked: boolean }>, arrName: string
  ) => {
    const updatedOptions = values?.map((item: any) => {
      return item.label === option.label ? { ...item, isChecked: false } : item;
    });
    if (arrName === "pod") {
      let temp = { ...this.state.filter, pod: updatedOptions };
      this.setState({ filter: temp }, () => {
        this.applyFilter();
      });
    }
    if (arrName === "vessel") {
      let temp = { ...this.state.filter, vessel: updatedOptions };
      this.setState({ filter: temp }, () => {
        this.applyFilter();
      });
    }
    if (arrName === "listingID") {
      let temp = { ...this.state.filter, listingID: updatedOptions };
      this.setState({ filter: temp }, () => {
        this.applyFilter();
      });
    }
    if (arrName === "pol") {
      let temp = { ...this.state.filter, pol: updatedOptions };
      this.setState({ filter: temp }, () => {
        this.applyFilter();
      });
    }

  };

  TogolFilter = () => {
    this.setState({
      open: !this.state.open,
    });
  }

  SetDateFilterData = (range: DateRange) => {
    this.setState({
      RenderStart: moment(range.startDate).format("DD-MMM-YYYY"), RenderEndDate: moment(range.endDate).format("DD-MMM-YYYY"),
      StartDate: moment(range.startDate).format(), EndDate: moment(range.endDate).format(),
      DateRangeStartDate:range.startDate,DateRangeEndDate:range.endDate,
      filter: { ...this.state.filter, startDate: moment(range.startDate).subtract(1,"d").format("YYYY-MM-DD"), endDate: moment(range.endDate).format("YYYY-MM-DD") },
    })
    
    
  }

  FilterDateSelect = () => {
    const { StartDate, EndDate } = this.state
    if (StartDate === "") {
      toast.warning("Select start date")
    } else if (EndDate === "") {
      toast.warning("Select end date")
    } else {
      this.applyFilter()
    }
  }

  ClearFilterData = () => {
    this.setState({
      filter: { ...this.state.filter, startDate: "", endDate: "" },
      open: false,
      DateRangeStartDate:new Date(),
      DateRangeEndDate:new Date(),
      RenderStart: "", RenderEndDate: "",
      StartDate: "", EndDate: "",
    }, () => {
      this.applyFilter()
    })
  }

  goToTermsAndConditionPage = async (route: string) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        route
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage),
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  DynamicDataSet = (e: { listing?: string, requst?: string, offers?: string, booking?: string }) => { this.setState({ DynamicCount: e }) }

}
// Customizable Area End