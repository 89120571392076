// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { 
  RouteComponentProps
 } from "react-router";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { 
  runEngine
 } from "../../../framework/src/RunEngine";
import { 
  Message
 } from "../../../framework/src/Message";
import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
import moment from "moment";
import { 
  toast
 } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

interface S {
  // Customizable Area Start
  currentTab: number;
  sellerListings: any;
  switch: boolean;
  offerModal: boolean;
  offerAdded: boolean;
  addOffer: boolean;
  offerUID: string;
  loginData: any;
  loading: boolean;
  sellerPendingReq: any;
  vesselVoyageData: Array<{
    id: number | string;
    vessel_code: string;
    in_voyage_code: string;
    out_voyage_code: string;
  }>;
  voyageData: Array<{
    id: number | string;
    vessel_code: string;
    in_voyage_code: string;
    out_voyage_code: string;
  }>;
  principalOrgList: Array<{
    container_type: string;
    box_sizes: Array<string>;
    codes: Array<string>;
  }>;
  selectedContainer: any;
  selectedPendingReq: any;
  polData: any;
  podData: any;
  anchorElDropdown: any;
  etbDate: string;
  etaDate: string;
  expiry_date: any;
  prinicpalOrgList: any;
  containerData: Array<{
    container_type: string;
    box_sizes: Array<string>;
    codes: Array<string>;
  }>;
  avarageTons: string;
  offerOptions: any;
  offerExpanded: boolean;
  offerExpandedPol: boolean;
  offerExpandedPod: boolean;
  offerExpandedtype: boolean;
  filter: {
    listingID: Array<{ label: string; isChecked: boolean }>;
    pol: Array<{ label: string; isChecked: boolean }>;
    pod: Array<{ label: string; isChecked: boolean }>;
    type: Array<{ label: string; isChecked: boolean }>;
    startDate: string;
    endDate: string;
    principalOrgFilter: Array<string>;
  };
  sellerListingSnackbarOpen: boolean;
  isSlotFilterOpen: boolean;
  activeSlotFilter: any;
  SelectPrincipalorganisationName: string;
  open: boolean;
  StartDate: string;
  EndDate: string;
  RenderStart: string;
  RenderEndDate: string;
  DynamicCount: {
    listing?: string;
    requst?: string;
    offers?: string;
    booking?: string;
  };
  minimumValidity: string;
  PrincipalOrganizationSelectedName: any;
  VesselDropDownOpen: any;
  VoyageDropDownData: Array<{
    inVoyN: string,
    outVoyN: string,
    vslM: string,
    name: string
}>,
PortNetApiAllData: Array<{
  inVoyN: string,
  outVoyN: string,
  vslM: string,
  name: string
}>,
IsVesselSelected: boolean,
InVoyogeAndOutVoyoge: string,
SelectedVesselVoyage: {
  inVoyN?: string,
  outVoyN?: string,
  vslM?: string,
  name?: string
},
VesselDropDownData: Array<{
  inVoyN: string,
  outVoyN: string,
  vslM: string,
  name: string
}>,
VesselDropDownString: string,
FormIsEdit: boolean;
VoyageDropDownOpen: boolean,
SelectedPol: string,
SelectedPolTerminal: string,
SelectedPod: string,
SelectedPodTerminal: string,
PolTerminalDropDown: Array<string>,
PodTerminalDropDown: Array<string>,
FormEditData: {
  PrincipalOrganizationName: string,
  Vessel: string,
  Voyage: string,
  Pod: string,
  PodTerminal: string,
  Pol: string,
  PolTerminal: string,
  Email: string,
  EtaPod: string,
  EtbPol: string,
  FullName: string,
  OrganizationCode: string,
  ContactNumber: string,
  DistributionEmail: string,
  Expiry: string,
  TotalTeu: string,
  Validity: string,
  out_voyage: string,
  in_voyage: string,
},
DateRangeStartDate: Date | undefined,
DateRangeEndDate: Date | undefined,
voyageValue: any,
FormikFormReenableReinitialize: boolean;
offeredQuantity: any;
offeredRate: any;
offeredTermsOne:any;
offeredTermsTwo:any;
secondOfferedQuantity: any;
secondOfferedrate: any;
secondOfferedTermsOne: any;
secondOfferedTermsTwo: any;
firstContainerActive: boolean;
secondContainerActive: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SellerPendingRequestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  setValuesRef: (field: string, value: string, shouldValidate?: boolean | undefined) => void = () => { };
  timeoutId: any;
  debounceDelay = 1000;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    (async () => {
      let pendingRequestLoginData = await getStorageData("UserRole");
      if(!pendingRequestLoginData){
        window.location.href = "/"
      } else if(pendingRequestLoginData == "buyer"){
        window.location.href = "/BuyersRequest"
      }
    })()

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      currentTab: 0,
      sellerListings: [],
      switch: true,
      offerModal: false,
      offerAdded: false,
      addOffer: false,
      offerUID: "",
      principalOrgList: [],
      selectedContainer: [],
      vesselVoyageData: [],
      voyageData: [],
      loginData: {},
      loading: false,
      sellerPendingReq: [],
      selectedPendingReq: [],
      polData: [],
      podData: [],
      anchorElDropdown: null,
      etbDate: "",
      etaDate: "",
      expiry_date: '',
      prinicpalOrgList: [],
      containerData: [],
      avarageTons: "",
      offerExpanded: false,
      offerExpandedPol: false,
      offerExpandedPod: false,
      offerExpandedtype: false,
      offerOptions: [
        { label: "Offer 1", isChecked: false },{ label: "Offer 2", isChecked: false },
        { label: "Offer 3", isChecked: false },{ label: "Offer 4", isChecked: false },
        { label: "Offer 5", isChecked: false },
      ],
      filter: {
        listingID: [],pol: [],
        pod: [],type: [],
        startDate: "",endDate: "",
        principalOrgFilter: [],
      },
      sellerListingSnackbarOpen: false,
      isSlotFilterOpen: false,
      activeSlotFilter: [],
      SelectPrincipalorganisationName: "",
      open: false,
      StartDate: "",
      EndDate: "",
      RenderStart: "",
      RenderEndDate: "",
      DynamicCount: {
        listing: "0",requst: "0",
        offers: "0",booking: "0",
      },
      minimumValidity: "",
      PrincipalOrganizationSelectedName: "",
      VesselDropDownOpen: false,
      VoyageDropDownData: [],
      PortNetApiAllData: [],
      IsVesselSelected: false,
      InVoyogeAndOutVoyoge: "",
      SelectedVesselVoyage: {
        inVoyN: "",
        outVoyN: "",
        vslM: "",
        name: ""
    },
    VesselDropDownData: [],
    VesselDropDownString: "",
    FormIsEdit: false,
    VoyageDropDownOpen: false,
    SelectedPol: "",
    SelectedPod: "",
    SelectedPolTerminal: "",
    SelectedPodTerminal: "",
    PolTerminalDropDown: [],
    PodTerminalDropDown: [],
    FormEditData: {
      PrincipalOrganizationName: "",
      Vessel: "",
      Voyage: "",
      Pod: "",
      PodTerminal: "",
      Pol: "",
      PolTerminal: "",
      Email: "",
      EtaPod: "",
      EtbPol: "",
      FullName: "",
      OrganizationCode: "",
      ContactNumber: "",
      DistributionEmail: "",
      Expiry: "",
      TotalTeu: "",
      Validity: "",
      out_voyage: "",
      in_voyage: "",
  },
  DateRangeStartDate: new Date(),
  DateRangeEndDate: new Date(),
  voyageValue: null,
  FormikFormReenableReinitialize: false,
  offeredQuantity: "",
  offeredRate: "",
  offeredTermsOne:"",
  offeredTermsTwo:"",
  secondOfferedQuantity: "",
  secondOfferedrate: "",
  secondOfferedTermsOne: "",
  secondOfferedTermsTwo: "",
  firstContainerActive: false,
  secondContainerActive: false,
    };
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getSellerPendingRequestData();
    let val = await getStorageData("PrincipalOrganisation");
    const promise1 = Promise.resolve(val);
    promise1.then((value: string) => {
      let result = JSON.parse(value);
      this.setState({ prinicpalOrgList: result });
    });
    let loginData = await getStorageData("loginData");
    let obj = JSON.parse(loginData);
    this.setState({ loginData: obj });
  }

  getSellerPendigRequestataApiCallID: string = "";
  makeOfferApiCallID: string = "";
  onSwitchRoleChangeAPICallID: string = "";
  applyFilterApiCallID: string = "";
  getVesselVoyagesApiCallID: string = "";
  GetVesselVoyagePostApi: string = "";
  GetEtaETbAPiId: string = "";

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getSellerPendigRequestataApiCallID) {
      this.setState({
        sellerPendingReq: responseJson,
        loading: false,
        DynamicCount: {
          ...this.state.DynamicCount,
          requst: responseJson.length,
        },
      });
      let temp = {
        listingID: responseJson.map(
          (val: any) => val.request.data.attributes.request_uid
        ),
        pol: responseJson.map((val: any) => val.request.data.attributes.pol),
        pod: responseJson.map((val: any) => val.request.data.attributes.pod),
        type: responseJson.map((val: any) => val?.container?.container_type),
        startDate: responseJson.map(
          (val: any) =>
            val.request.data.attributes.container_loading_range_start
        ),
        endDate: responseJson.map(
          (val: any) => val.request.data.attributes.container_loading_range_end
        ),
      };
      let a1 = [...new Set(temp.listingID)];
      let a2 = [...new Set(temp.pol)];
      let a3 = [...new Set(temp.pod)];
      let a4 = [...new Set(temp.type)];
      let arr = {
        listingID: a1.map((str: any) => ({ label: str, isChecked: false })),
        pol: a2.map((str: any) => ({ label: str, isChecked: false })),
        pod: a3.map((str: any) => ({ label: str, isChecked: false })),
        type: a4.map((str: any) => ({ label: str, isChecked: false })),
        startDate: "",
        endDate: "",
        principalOrgFilter: [],
      };
      this.setState({ filter: arr });
    }
    if (apiRequestCallId === this.applyFilterApiCallID) {
      this.setState({
        sellerPendingReq: responseJson,
        open: false,
        DynamicCount: {
          ...this.state.DynamicCount,
          requst: responseJson.length || 0,
        },
      });
    }
    if (apiRequestCallId === this.makeOfferApiCallID) {
      if (responseJson.status === "success") {
        this.setState({
          offerUID: responseJson.data.offer.data.attributes.offer_uid,
          addOffer: false,
          offerAdded: true,
          loading: false,
        });
      }
    }
    if (apiRequestCallId === this.onSwitchRoleChangeAPICallID) {
      if (!responseJson.message) {
        this.setState({ sellerListingSnackbarOpen: true });
      } else {
        setStorageData("UserRole", "buyer");
        this.props.navigation.navigate("BuyersRequest");
      }
    }

    
  this.getVesselVoyagevalues(apiRequestCallId,responseJson)

  this.GetVesselVoyagePostApiDataresponseSet(apiRequestCallId,responseJson)
  this.GetEtaEtbAPiResponse(apiRequestCallId, responseJson);

  }

  GetEtaEtbAPiResponse = (apiRequestCallId: string, data: { errors: Array<{ [key: string]: string }>, results: Array<{ vslM: string, inVoyN: string, outVoyN: string, portOfLoad: string, portofDisc: string, etbDt: string, etuDt: string, etaDt: string }> }) => {
    if (apiRequestCallId === this.GetEtaETbAPiId) {
        if (Array.isArray(data.errors)) {this.setState({loading: false}); this.ErrorHandelResponse(data.errors)}
        if (Array.isArray(data.results) && data.results.length !== 0) {
            if (data.results[0].etbDt && data.results[0].etaDt) {this.setValuesRef("ETBATPOL", moment.parseZone(data.results[0].etbDt).format('YYYY-MM-DD')); this.setValuesRef("ETAATPOD", moment.parseZone(data.results[0].etaDt).format('YYYY-MM-DD'))
                this.setState({loading: false,etbDate: moment.parseZone(data.results[0].etbDt).format('YYYY-MM-DD'),etaDate: moment.parseZone(data.results[0].etaDt).format('YYYY-MM-DD'),}, () => {const dateFrom = moment(this.state.etbDate).subtract(this.state.loginData.minimum_validity_of_listing, "d").parseZone().format("YYYY-MM-DD"); this.setState({ expiry_date: dateFrom, loading: false, FormikFormReenableReinitialize: false });})}}}}

ErrorHandelResponse = (data: Array<{ [key: string]: string }>) => { if (typeof data === "string") {toast.error(data);}this.ErrorForLoop(data)};

otherErrors = (data: Array<{ [key: string]: string }>, obj: any) => {if (Array.isArray(data)) {if (data[0].message) {toast.error("The respective data does not exist.");} else {for (const key in obj) {toast.error(obj[key]);}}}}

ErrorForLoop = (data: Array<{ [key: string]: string }>) => {for (const obj of Object.values(data)) {if (typeof data === "object") {toast.error(obj);if (Array.isArray(obj)) {for (const key in obj) {toast.error(`${Object.entries(obj[key])[0][0].toString().replace("_", " ").replace(/\b\w/g, char => char.toUpperCase())} ${Object.entries(obj[key])[0][1].toString().replace(",", " ")}.`);}}}; this.otherErrors(data, obj)}}

  getVesselVoyagevalues = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getVesselVoyagesApiCallID) {
      const DataAPIresposne = responseJson.data?.map((item: {attributes: {vessel_code: string;in_voyage_code: string;out_voyage_code: string;};}) => {return { vessel_code: item.attributes.vessel_code, in_voyage_code: item.attributes.in_voyage_code, out_voyage_code: item.attributes.out_voyage_code, } } );
      const ReposneData = Array.isArray(responseJson.data) ? responseJson.data.map((item: { id: number, attributes: { vessel_code: string; in_voyage_code: string; out_voyage_code: string; } }) => { return { name: item.attributes.vessel_code, inVoyN: item.attributes.in_voyage_code, outVoyN: item.attributes.out_voyage_code, vslM: item.attributes.vessel_code, } }) : []
      const Data = ReposneData.filter((value: { name: string }, index: number, self: Array<{}>) => index === self.findIndex((obj: { name?: string }) => obj.name === value.name));
      this.setState({ VesselDropDownData: Data, PortNetApiAllData: ReposneData, loading: false })
      this.setState({ vesselVoyageData: DataAPIresposne, loading: false, }); setStorageData("voyageData", JSON.stringify(DataAPIresposne))
  }
  }

  getSellerPendingRequestData = async () => {
    const token = await getStorageData("authToken");
    this.setState({ loading: true });
    const header = { "Content-Type": "application/json", token: token };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSellerPendigRequestataApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_listdemandpickflow/pending_requests"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  applyFilter = async () => {
    const token = await getStorageData("authToken");
    const header = { "Content-Type": "application/json", token: token };
    const reqUidData = this.state.filter.listingID?.reduce(
      (acc: string[], item: { label: string; isChecked: boolean }) => {
        if (item.isChecked) acc.push(item.label);
        return acc;
      },
      []
    );
    const polsData = this.state.filter.pol?.reduce(
      (acc: string[], item: { label: string; isChecked: boolean }) => {
        if (item.isChecked) acc.push(item.label);
        return acc;
      },
      []
    );
    const podsData = this.state.filter.pod?.reduce(
      (acc: string[], item: { label: string; isChecked: boolean }) => {
        if (item.isChecked) acc.push(item.label);
        return acc;
      },
      []
    );
    const typesData = this.state.filter.type?.reduce(
      (acc: string[], item: { label: string; isChecked: boolean }) => {
        if (item.isChecked) acc.push(item.label);
        return acc;
      },
      []
    );
    const principalOrganisation = this.state.activeSlotFilter?.map(
      (elem: any) => elem?.attributes?.name
    );
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.applyFilterApiCallID = requestMessage.messageId;
    const principalOrganizationParam =
      principalOrganisation.length > 0
        ? `principal_organization_names=${JSON.stringify(
            principalOrganisation
          )}`
        : "";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_listdemandpickflow/pending_requests/apply_filters?request_ids=${JSON.stringify(
        reqUidData
      )}&pols=${JSON.stringify(polsData)}&pods=${JSON.stringify(
        podsData
      )}&container_types=${JSON.stringify(
        typesData
      )}&container_loading_range_start=${this.state.filter.startDate}
      &container_loading_range_end=${this.state.filter.endDate}
      &${principalOrganizationParam}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  MakeOffer = async (values: {
    PRINCIPALORGANISATION: "";
    VESSEL: string;
    VOYAGE: string;
    POD: string;
    PODTERMINAL: string;
    POL: string;
    POLTERMINAL: string;
    ETBATPOL: string;
    ETAATPOD: string;
    VALIDITYOFLISTING: string;
    EXPIRYDATE: string;
    TOTALQUANTITY: number;
    TOTALQUANTITYTWO: number;
    OFFEREDRATE: string;
    OFFEREDRATETWO: string;
    OFFEREDTERM: string;
    OFFEREDTERMTWO: string;
    OFFEREDTERMTHREE: string;
    OFFEREDTERMFOUR: string;
  }) => {
    let authToken = await getStorageData("authToken");
    const header = { "Content-Type": "application/json", token: authToken };
    this.setState({ loading: true });
    let { request, container, container_details } =
      this.state.selectedPendingReq;
      const body = {
        pending_request_id: request.data.id, container_management_id: container.id, organization_id: this.state.loginData.organization_id,
        principal_organization_name: values.PRINCIPALORGANISATION, vessel: this.state.SelectedVesselVoyage.vslM, in_voyage: this.state.SelectedVesselVoyage.inVoyN, out_voyage: this.state.SelectedVesselVoyage.outVoyN,
        pol: this.state.selectedPendingReq?.request?.data?.attributes?.pol, pol_terminal: this.state.selectedPendingReq?.request?.data?.attributes?.pol_terminal, pod: this.state.selectedPendingReq?.request?.data?.attributes?.pod, pod_terminal: this.state.selectedPendingReq?.request?.data?.attributes?.pod_terminal,
        etb_pol: values.ETBATPOL, eta_pod: values.ETAATPOD, listing_validity: values.VALIDITYOFLISTING,
        expiry_date: moment(this.state.expiry_date).format('YYYY-MM-DD HH:mm:ss'),  
        offer_seller_rate: values.OFFEREDRATE || "", //integer
        offer_container_type: container.container_type, offer_quantity: values.TOTALQUANTITY || "",
        offer_tons_teu: container_details[0]?.container_size == "20 FT" ?  values.TOTALQUANTITY *1 : values.TOTALQUANTITY*2, offer_rate_by_buyer: container_details[0].desired_rates,
        offer_terms_buyers: container_details[0].terms, //array
        offer_container_codes: container.codes,
        offer_container_sizes: container_details[0].container_size, 
        offer_terms_sellers: `${values.OFFEREDTERM} / ${values.OFFEREDTERMTWO}` || null,
        ...(container_details[1] && values.TOTALQUANTITYTWO &&  {
          offer_sec_terms_buyers: container_details[1] ? container_details[1].terms : null,
            offer_sec_quantity: container_details[1] ? values.TOTALQUANTITYTWO : null,
            offer_sec_container_sizes: container_details[1] ? container_details[1].container_size : null,
            offer_sec__tons_teu: container_details[1]?.container_size == "40 FT" ? values.TOTALQUANTITYTWO *2 : values.TOTALQUANTITYTWO *1,
            offer_sec_terms_sellers: container_details[1] ? `${values.OFFEREDTERMTHREE} / ${values.OFFEREDTERMFOUR}` : null,
            offer_sec_rate_by_buyer: container_details[1] ? container_details[1].desired_rates : null,
            offer_sec_rate_by_seller: container_details[1] ? values.OFFEREDRATETWO : null,
        })
      };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.makeOfferApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_listdemandpickflow/make_an_offers"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  GetEtaETbAPiFuncation = async () => {
    const token = await getStorageData("authToken");
    const header = {"Content-Type": "application/json",token: token,};
    this.setState({loading: true})
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const httpBody = { userId: this.state.loginData.email, orgCode: this.state.PrincipalOrganizationSelectedName, vslM: this.state.SelectedVesselVoyage.vslM?.toUpperCase(), inVoyN: this.state.SelectedVesselVoyage.inVoyN?.toUpperCase(), outVoyN: this.state.SelectedVesselVoyage.outVoyN?.toUpperCase(), portOfLoad: this.state.SelectedPol?.toUpperCase(), portOfDisc: this.state.SelectedPod?.toUpperCase() };
    this.GetEtaETbAPiId = requestMessage.messageId;
    requestMessage.addData( getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody) );
    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage), "/bx_block_apiintegration52/portnets/get_etb_info" );
    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header) );
    requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage), "POST" );
    runEngine.sendMessage(requestMessage.id, requestMessage);
};

  onSwitchRole = async () => {
    let authToken = await getStorageData("authToken");
    const header = { "Content-Type": "application/json", token: authToken };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.onSwitchRoleChangeAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/switch_role`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getVesselVoyages = async () => {
    let LocationToken = await getStorageData("authToken");
    const header = { "Content-Type": "application/json", token: LocationToken };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVesselVoyagesApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/vessel_voyages?principal_organization_name=${this.state.SelectPrincipalorganisationName}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleTabChange = (event: any, newValue: any) => {
    this.setState({ currentTab: newValue });
  };

  SwitchChnages = () => {
    this.onSwitchRole();
  };

  handleClose = () => {
    this.setState({ offerModal: false });
  };

  handleOfferClick = async (list: any) => {
    const PolData = localStorage.getItem("PolData") as never;
    const vesseldata = localStorage.getItem("voyageData") as never;
    const principalorglist = localStorage.getItem(
      "PrincipalOrganisation"
    ) as never;
    let vesseldata1 = JSON.parse(vesseldata);
    let principalorglist1 = JSON.parse(principalorglist);
    this.setState({
      vesselVoyageData: vesseldata1,
      polData: JSON.parse(PolData),
      prinicpalOrgList: principalorglist1,
      firstContainerActive: false,
      secondContainerActive: false,
      VesselDropDownString:"",
      InVoyogeAndOutVoyoge:"",
      offeredQuantity: "",
      offeredRate: "",
      offeredTermsOne: "",
      offeredTermsTwo: "",
      secondOfferedQuantity: "",
      secondOfferedrate: "",
      secondOfferedTermsOne: "",
      secondOfferedTermsTwo: "",
    });
    if(list.container_details.length>1 && list.container_details[1].quantity){
      this.setState({secondContainerActive: true,secondOfferedQuantity:list.container_details[1]?.quantity,offeredQuantity:list.container_details[0]?.quantity})
    }
    let loginData = await getStorageData("loginData");
    let obj = JSON.parse(loginData);
    this.setState({
      offerModal: true,
      offerAdded: false,
      addOffer: true,
      selectedPendingReq: list,
      minimumValidity: obj?.minimum_validity_of_listing,
    });
  };
  onSubmitHandler = async (values: any) => {
    let loginData = await getStorageData("loginData");
    let obj = JSON.parse(loginData);
    this.setState({ loginData: obj });
    this.MakeOffer(values);
  };
  AddListingFormTextFieldChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<{ value: unknown }>,
    setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean | undefined
    ) => void,
    keyname: string
  ) => {
    setFieldValue(`${keyname}`, `${e.target.value}`);
    if(keyname === "PRINCIPALORGANISATION"){
      this.setState({ SelectPrincipalorganisationName: `${e.target.value}` })
    }
  };

  handleClick = (event: any) => {
    this.setState({ anchorElDropdown: event.currentTarget });
  };

  handleCloseDropdown = () => {
    this.setState({ anchorElDropdown: null });
  };

  handleOfferExpanded = (val: string) => {
    if (val === "pod") {
      this.setState({ offerExpandedPod: this.state.filter.pod?.length > 0 });
    }
    if (val === "type") {
      this.setState({ offerExpandedtype: this.state.filter.type?.length > 0 });
    }
    if (val === "listingID") {
      this.setState({ offerExpanded: this.state.filter.listingID?.length > 0 });
    }
    if (val === "pol") {
      this.setState({ offerExpandedPol: this.state.filter.pol?.length > 0 });
    }
  };

  handleOfferChange = (
    event: any,
    values: Array<{ label: string; isChecked: boolean }>,
    arrName: string
  ) => {
    const { name, checked } = event.target;
    const updatedOptions = values?.map(
      (item: { label: string; isChecked: boolean }) => {
        return item.label === name ? { ...item, isChecked: checked } : item;
      }
    );
    if (arrName === "pod") {
      let temp = { ...this.state.filter, pod: updatedOptions };
      this.setState({ filter: temp }, () => {
        this.applyFilter();
      });
    }
    if (arrName === "listingID") {
      let temp = { ...this.state.filter, listingID: updatedOptions };
      this.setState({ filter: temp }, () => {
        this.applyFilter();
      });
    }
    if (arrName === "type") {
      let temp = { ...this.state.filter, type: updatedOptions };
      this.setState({ filter: temp }, () => {
        this.applyFilter();
      });
    }
    if (arrName === "pol") {
      let temp = { ...this.state.filter, pol: updatedOptions };
      this.setState({ filter: temp }, () => {
        this.applyFilter();
      });
    }
  };

  handleSlotFilter = (principalOrg: any, isChecked: boolean) => {
    const hasActiveFilter = this.state.activeSlotFilter.find(
      (slot: any) =>
        slot.attributes.code === principalOrg.attributes.code &&
        slot.attributes.name === principalOrg.attributes.name
    );
    if (isChecked && !hasActiveFilter) {
      this.setState((prevState) => ({
        activeSlotFilter: [...prevState.activeSlotFilter, principalOrg],
      }));
      setTimeout(() => {
        this.applyFilter();
      }, 1000);
      return;
    }
    this.setState((prevState) => ({
      activeSlotFilter: prevState.activeSlotFilter.filter(
        (item: any) =>
          item.attributes.code !== principalOrg.attributes.code &&
          item.attributes.name !== principalOrg.attributes.name
      ),
    }));
    setTimeout(() => {
      this.applyFilter();
    }, 1000);
  };

  handleOfferDelete = (
    option: any,
    values: Array<{ label: string; isChecked: boolean }>,
    arrName: string
  ) => {
    const updatedOptions = values?.map((item: any) => {
      return item.label === option.label ? { ...item, isChecked: false } : item;
    });
    if (arrName === "pod") {
      let temp = { ...this.state.filter, pod: updatedOptions };
      this.setState({ filter: temp }, () => {
        this.applyFilter();
      });
    }
    if (arrName === "type") {
      let temp = { ...this.state.filter, type: updatedOptions };
      this.setState({ filter: temp }, () => {
        this.applyFilter();
      });
    }
    if (arrName === "listingID") {
      let temp = { ...this.state.filter, listingID: updatedOptions };
      this.setState({ filter: temp }, () => {
        this.applyFilter();
      });
    }
    if (arrName === "pol") {
      let temp = { ...this.state.filter, pol: updatedOptions };
      this.setState({ filter: temp }, () => {
        this.applyFilter();
      });
    }
  };


  TogolFilter = () => { 
    this.setState({
      open: !this.state.open,
    });
   }

  SetDateFilterData = (range: any) => {
    this.setState({
      RenderStart: moment(range.startDate).format("DD-MMM-YYYY"),
      RenderEndDate: moment(range.endDate).format("DD-MMM-YYYY"),
      StartDate: moment(range.startDate).format(),
      EndDate: moment(range.endDate).format(),
      DateRangeStartDate:range.startDate,DateRangeEndDate:range.endDate,
      filter: {
        ...this.state.filter,
        startDate: moment(range.startDate).format("YYYY-MM-DD"),
        endDate: moment(range.endDate).format("YYYY-MM-DD"),
      },
    });
  };

  FilterDateSelect = () => {
    const { StartDate, EndDate } = this.state;
    if (StartDate === "") {
      toast.warning("Select start date");
    } else if (EndDate === "") {
      toast.warning("Select end date");
    } else {
      this.applyFilter();
    }
  };

  ClearFilterData = () => {
    this.setState(
      {
        filter: { ...this.state.filter, startDate: "", endDate: "" },
        open: false,
        RenderStart: "",
        RenderEndDate: "",
        StartDate: "",
        EndDate: "",
        DateRangeStartDate:new Date(),
      DateRangeEndDate:new Date(),
      },
      () => {
        this.applyFilter();
      }
    );
  };
  DynamicDataSet = (e: {
    listing?: string;
    requst?: string;
    offers?: string;
    booking?: string;
  }) => {
    this.setState({ DynamicCount: e });
  };

  checkNameIncludesVessel = (item: any) => {
    if (item.name.includes(this.state.FormEditData.Vessel)) {
      return item;
    }
  };

  checkVoyageIncludesInVoyage = (item: any) => {
    if (item.inVoyN.includes(this.state.FormEditData.in_voyage)) {
      return item;
    }
  };

  onSetVesselDropdownState = (Data: any, ReposneData: any) => {
    this.setState(
      {
        VesselDropDownData: Data,
        PortNetApiAllData: ReposneData,
        loading: !!this.state.FormIsEdit,
      },
      () => {
        if (this.state.FormIsEdit) {
          const VoyageData = this.state.PortNetApiAllData.filter(
            (item: { name: string; inVoyN: string; outVoyN: string }) => {
              {
                this.checkNameIncludesVessel(item);
              }
            }
          );
          const DataSet = this.state.PortNetApiAllData.filter(
            (item: { name: string; inVoyN: string; outVoyN: string }) => {
              {
                this.checkVoyageIncludesInVoyage(item);
              }
            }
          );
          this.setState({
            VoyageDropDownData: VoyageData,
            SelectedVesselVoyage: DataSet.length !== 0 ? DataSet[0] : {},
            loading: false,
          });
        }
      }
    );
  };

  GetVesselVoyagePostApiDataresponseSet = (
    apiRequestCallId: string,
    responseJson: {
      errors?: Array<{ [key: string]: string }>;
      results: Array<{ inVoyN: string; outVoyN: string; vslM: string }>;
    }
  ) => {
    if (apiRequestCallId === this.GetVesselVoyagePostApi) {
      if (
        responseJson.errors !== null ||
        responseJson.results === null && responseJson.errors === null
      ) {
        this.getVesselVoyages();
        this.setState({
          loading: false,
        });
      } else {
        const ReposneData = Array.isArray(responseJson.results)
          ? responseJson.results.map(
              (item: { inVoyN: string; outVoyN: string; vslM: string }) => {
                return {
                  name: item.vslM,
                  inVoyN: item.inVoyN,
                  outVoyN: item.outVoyN,
                  vslM: item.vslM,
                };
              }
            )
          : [];
        const Data = ReposneData.filter(
          (value, index, self) =>
            index === self.findIndex((obj) => obj.name === value.name)
        );
          this.onSetVesselDropdownState(Data, ReposneData);
      }
    }
  };

  OnOpenDropDown = () => {
    if (this.state.PrincipalOrganizationSelectedName !== "") {
      this.setState({
        VesselDropDownOpen: !this.state.VesselDropDownOpen,
      });
    } else {
      toast.warning("Please select principal oragnisation");
    }
  };

  AutocompleteOnChange = (
    setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean
    ) => void,
    value: unknown,
    KeyName: string
  ) => {
    const data = value as {
      name: string;
      inVoyN: string;
      outVoyN: string;
      vslM: string;
    };
    if (KeyName === "VESSEL") {
      const VoyageData = this.state.PortNetApiAllData.filter(
        (item: { name: string }) => {
          if (item.name.includes(data.name)) {
            return item;
          }
        }
      );

      this.setState({
        VoyageDropDownData: VoyageData,
        IsVesselSelected: true,
        InVoyogeAndOutVoyoge: "",
        SelectedPod: "",
        SelectedPol: "",
        SelectedPodTerminal: "",
        SelectedPolTerminal: "",
        PolTerminalDropDown: [],
        PodTerminalDropDown: [],
        SelectedVesselVoyage: {
          inVoyN: "",
          outVoyN: "",
          vslM: "",
          name: "",
        },
      });
      setFieldValue(KeyName, `${data?.name.toUpperCase()}`);
      setFieldValue("VOYAGE", "");
      setFieldValue("POD", "");
      setFieldValue("POL", "");
      setFieldValue("PODTERMINAL", "");
      setFieldValue("POLTERMINAL", "");
    }
    if (KeyName === "VOYAGE") {
      this.setState({
        InVoyogeAndOutVoyoge: `In ${data.inVoyN} Out ${data.outVoyN}`,
        SelectedVesselVoyage: data, SelectedPod: this.state.selectedPendingReq?.request?.data?.attributes?.pod, SelectedPol: this.state.selectedPendingReq?.request?.data?.attributes?.pol,SelectedPodTerminal: this.state.selectedPendingReq?.request?.data?.attributes?.pod_terminal, SelectedPolTerminal: this.state.selectedPendingReq?.request?.data?.attributes?.pod_terminal, }, () => {this.GetEtaETbAPiFuncation()});
      setFieldValue(KeyName, data?.inVoyN); setFieldValue("POD", this.state.selectedPendingReq?.request?.data?.attributes?.pod); setFieldValue("POL", this.state.selectedPendingReq?.request?.data?.attributes?.pol); setFieldValue("PODTERMINAL", this.state.selectedPendingReq?.request?.data?.attributes?.pod_terminal); setFieldValue("POLTERMINAL", this.state.selectedPendingReq?.request?.data?.attributes?.pod_terminal);
    }
  };
  AutocompleteTextfieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let values = event.target.value;
    if (
      3 <= event.target.value.length &&
      this.state.PrincipalOrganizationSelectedName !== ""
    ) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.debouncedFunction(values);
      }, this.debounceDelay);
    }
  };

  debouncedFunction = (value: string) => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(() => {
      this.OnchangeOfAutoComplate(value);
    }, 1000);
  };

  OnchangeOfAutoComplate = async (values: string) => {
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const httpBody = {
      userId: `${this.state.loginData?.email}`,
      orgCode: `${this.state.PrincipalOrganizationSelectedName}`,
      vslM: `${values?.toUpperCase()}`,
      partialMatch: true,
    };

    this.GetVesselVoyagePostApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_apiintegration52/portnets/get_vessel_voyage"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  OnOpenDropDownVoyage = () => {
    if (this.state.IsVesselSelected) {
      this.setState({
        VoyageDropDownOpen: !this.state.VoyageDropDownOpen,
      });
    } else {
      toast.warning("Please select Vessel first");
    }
  };

  // Customizable Area End
}