// Customizable Area Start
import React, { Component, RefObject } from 'react';
import { Box, Typography } from '@material-ui/core';
import ReactPlayer from 'react-player';
import { styled } from '@material-ui/styles';
const play = require("./imagebutton_play.png");
// Customizable Area End

// Customizable Area Start
export interface VideoPlayerProps {
  videoUrl: string;
  posterUrl: string;
  title: string;
}

interface VideoPlayerState {
  isPlaying: boolean;
  currentTime: number;
  totalTime: number;
}
// Customizable Area End
class VideoPlayer extends Component<VideoPlayerProps, VideoPlayerState> {
  // Customizable Area Start
  private videoRef: RefObject<ReactPlayer>;

  constructor(props: VideoPlayerProps) {
    super(props);
    // Customizable Area Start
    this.state = {
      isPlaying: false,
      currentTime: 0,
      totalTime: 0,
    };

    this.videoRef = React.createRef();
    // Customizable Area End
  }
  // Customizable Area Start
  handleTimeUpdate = (e: { playedSeconds: number }) => {
    this.setState({ currentTime: e.playedSeconds });
  };

  handleMetadataLoad = (player: ReactPlayer) => {
    const duration = player.getDuration();
    if (!isNaN(duration)) {
      this.setState({ totalTime: duration });
    }
  };

  handlePlay = () => {
    this.setState({ isPlaying: true });
  };

  handlePause = () => {
    this.setState({ isPlaying: false });
  };

  togglePlayPause = () => {
    this.setState((prevState) => ({ isPlaying: !prevState.isPlaying }));
  };

  formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { videoUrl, posterUrl } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <VideoPlayerStyle>
        <Box className="video-container">
          <ReactPlayer
            ref={this.videoRef}
            url={videoUrl}
            playing={this.state.isPlaying}
            controls
            width="100%"
            height="100%"
            style={{ borderRadius: '10px', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
            onProgress={this.handleTimeUpdate}
            onReady={() => this.handleMetadataLoad(this.videoRef.current as ReactPlayer)}
            onStart={this.handlePlay}
            onPause={this.handlePause}
            onDuration={(duration: any) => this.setState({ totalTime: duration })}
          />

          {!this.state.isPlaying && (
            <img onClick={this.togglePlayPause} src={play} alt="" className="play-icon" />
          )}

          <Box className="textBox">
            <Typography className="titleText">{this.props.title}</Typography>
            <Typography className="timeText">
              {this.formatTime(this.state.currentTime)}/{this.formatTime(this.state.totalTime)}
            </Typography>
          </Box>
        </Box>
      </VideoPlayerStyle>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export default VideoPlayer;

const VideoPlayerStyle = styled(Box)({
  "& .video-container": {
    position: "relative",
  },
  "& .videoStyle": {
    width: "100%",
    borderRadius: "10px",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
  },
  "& .textBox": {
    margin: "3%"
  },
  "& .titleText": {
    color: "#1E1E1E",
    fontSize: "16px",
    fontFamily: "Asap",
    fontWeight: 700,
    lineHeight: "16px",
    margin: "2% 0%"
  },
  "& .timeText": {
    color: "#1E1E1E",
    fontSize: "14px",
    fontFamily: "Asap",
    fontWeight: 700,
    lineHeight: "16px",
  },

  "& .play-icon": {
    width: "50px",
    height: "50px",
    position: "absolute",
    top: "32%",
    left: "44%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    zIndex: 9999
  }
});
// Customizable Area End
