import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from 'yup';
import { ToastContentProps, toast } from "react-toastify";
import { ReactChild, ReactFragment, ReactPortal, ReactNode } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleClose:()=>void,
  RoleOfUser:string
  roleName:{
    "token":string
    "refresh_token":string,
    "id":number,
    "role_name":string,
    "full_name":string,
    "email":string,
    "full_phone_number":string,
    "organization_id":number
  }
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  email: string;
  phoneNumber: string;
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;
  ContactformData: Array<{ key: string, value: string }>;
  loading: boolean;
  ViewDemoModel: boolean;
  GetInTouchModel: boolean;
  ContactUsdata: Array<{ email: string, phone: string, description: string, address: string, id: string }>,
  ResetForm: boolean,
  Fullname: string,
  companyName: string,
  emailContact: string,
  PhoneNumber: string,
  message: string,
  subject: string,
  AdminNo:string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  ContactdataApiId: string = "";
  ContactUsApiID: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";

    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
      ContactformData: [],
      loading: false,
      ViewDemoModel: false,
      GetInTouchModel: false,
      ContactUsdata: [],
      ResetForm: false,
      Fullname: "",
      companyName: "",
      emailContact: "",
      PhoneNumber: "+65",
      AdminNo:"",
      message: "",
      subject: "",
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
   
    this.contactusApiFuncation()
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
     if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getContactUsList(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);

      if (apiRequestCallId === this.ContactdataApiId) {
        let ResposneData = responseJson.data?.map((item: {
          attributes: {
            address: string, description: string, email: string, phone_number: string
          }, id: string
        }) => {
          return {
            email: item.attributes.email,
            phone: item.attributes.phone_number,
            description: item.attributes.description,
            address: item.attributes.address,
            id: item.id
          }
        })
        this.setState({
          ContactUsdata: ResposneData
        })
  
      }

      if (apiRequestCallId === this.ContactUsApiID) {
      this.handleContactdataResponse(responseJson)
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleContactdataResponse=(responseJson: { errors: { contact: (boolean | ReactChild | ReactFragment | ReactPortal | ((props: ToastContentProps<unknown>) => ReactNode) | null | undefined)[]; }[]; error: any; })=>{
    if (responseJson.errors ?? responseJson.error) {
      toast.warning(responseJson.errors[0].contact[0])
    } else {
      toast.success("Query Successfully submitted")
      this.setState({
        GetInTouchModel: false,
        Fullname: "",
        companyName: "",
        emailContact: "",
        PhoneNumber: "+65",
        message: "",
        subject: ""
      },
      ()=>{this.handleClose()}
    )
    }
  }
  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtNameProps.value = text;
    },
  };

  txtEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtEmailProps.value = text;
    },
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phoneNumber: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
    // keyboardType: "phone-pad"
  };
  txtCommentsProps = {
    multiline: true,
    onChangeText: (text: string) => {
      this.setState({ comments: text });

      //@ts-ignore
      this.txtCommentsProps.value = text;
    },
  };

  setName = (text: string) => {
    this.setState({ name: text });
  };

  setEmail = (text: string) => {
    this.setState({ email: text });
  };

  setPhoneNumber = (text: string) => {
    this.setState({ phoneNumber: text });
  };

  setComments = (text: string) => {
    this.setState({ comments: text });
  };

  addQuery = () => {
    this.props.navigation.navigate("AddContactus");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeName: item.attributes.name,
      activeEmail: item.attributes.email,
      activeDescription: item.attributes.description,
      activePhoneNumber: item.attributes.phone_number,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail = (Email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(Email) === false) {
      return false;
    } else {
      return true;
    }
  };

  addQueryApi = () => {
    if (
      this.isStringNullOrBlank(this.state.name) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.phoneNumber) ||
      this.isStringNullOrBlank(this.state.comments)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (!this.isValidEmail(this.state.email.trim())) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    } else {
      let data = {
        data: {
          name: this.state.name.trim(),
          email: this.state.email.trim(),
          phone_number: this.state.phoneNumber.trim(),
          description: this.state.comments.trim(),
        },
      };

      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getContactUsAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteContactUs = (id: number) => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getContactUsList = (token: string) => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  btnSubmitProps = {
    onPress: () => this.addQueryApi(),
  };

  btnBackProps = {
    onPress: () => this.props.navigation.goBack(),
  };
  OnchangeTextfield = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void) => {
    const existingIndex = this.state.ContactformData.findIndex((item: { key: string }) => item.key === e.target.name);
    let key = e.target.name
    let value = e.target.value
    setFieldValue(`${e.target.name}`, e.target.value)
    if (existingIndex !== -1) {
      const newData = [...this.state.ContactformData];
      newData[existingIndex] = { key, value };
      this.setState({
        ...this.state, ContactformData: newData,
        [key]: value
      })
    } else {
      this.setState({
        ...this.state, ContactformData: [...this.state.ContactformData, { key, value }],
        [key]: value
      })
    }
  }
  PhoneInputNumber = (dialCode: string, number: string, setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void) => {
    let phone = `+${dialCode} ${number.slice(
      dialCode.length
    )}`;
    let numbers = number
    this.setState({
      AdminNo: number,
      PhoneNumber: phone
    })
    setFieldValue("PhoneNumber", `${numbers}`);
  }
  ContactUsApi = async (values: {

    Fullname: string,
    companyName: string,
    emailContact: string,
    PhoneNumber: string,
    message: string,
    subject: string
  }) => {
    const header = {
      "Content-Type": "application/json",
      token:this.props.roleName.token
    };
    const { Fullname, companyName, emailContact, PhoneNumber, message, subject } = values
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ContactUsApiID = requestMessage.messageId;
    let Body = {
      "data": {
        "name": Fullname,
        "email": emailContact,
        "phone_number": this.state.PhoneNumber,
        "description": message,
        "subject": subject,
        "company": companyName
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ContactAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.ContactUsApiMethod_POST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  contactusApiFuncation = async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ContactdataApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FooterAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.FooterApiMethod_GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  Validation = () => {
    return Yup.object().shape({
      Fullname: Yup.string().required("Full Name Required"),
      companyName: Yup.string().required("Company Required"),
      emailContact: Yup.string().email("Enter valid email").required("Email Address Required"),
      PhoneNumber: Yup.string().required("Contact Number Required").min(10, "Contact Number Required").max(15, "Contact Number Required").nullable(),
      message: Yup.string().required("Message Required"),
      subject: Yup.string().required("Subject Required")
    })
  };
  handleClose=()=>{
    this.props.handleClose()
  }
  // Customizable Area End
}
