export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const download = require("../assets/download.png");
export const update = require("../assets/image_.png");
export const dropdown= require("../assets/image_dropdown.png")
export const help = require('../assets/view_help.png')
export const vector = require('../assets/Vector.png')
export const dropup = require('../assets/image_dropdown(1).png')
export const whitedropdown=require('../assets/image_dropdown(2).png')
export const arrowDown =require('../assets/image_icon.png')
export const arrowUp= require('../assets/image_icon(1).png')