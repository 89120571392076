// Customizable Area Start

import React from "react";
import { styled } from "@material-ui/styles";
import { Box, Button, Grid, InputLabel } from "@material-ui/core";
import GenericCard from "../../../components/src/GenericCard.web";
import ForgotPasswordController from "./ForgotPasswordController.web";
import AppBackground from "../../../components/src/AppBackground.web";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ProjectText from "../../../components/src/ProjectText";
import LandingPageTextField from "../../../components/src/ProjectTextField";
import { Formik } from "formik";
import * as Yup from "yup";
import { ContainedButton } from "../../../components/src/GlobalButton";
import { image_no, image_close } from "./assets";
import ErrorIcon from '@material-ui/icons/Error';
export default class ForgotPassword extends ForgotPasswordController {
  ForgotPasswordSchema = () => {
    return Yup.object().shape({
      email: Yup.string()
        .email("Email address looks incorrect!")
        .required("Email is required")
    });
  };
  renderResetLink = () => {
    return (
      <Grid container justifyContent="center" spacing={2}>
        <Box className="CloseIcons" onClick={this.NavigateToLandingPage}>
          <img src={image_close} />
        </Box>
        <Grid item xs={12}>
          <Box className="ErrorIcon">
            <img src={image_no} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ProjectText
            variant="h6"
            align="center"
            fontweight="700"
            TextSize="1.75rem"
          >
            Reset link sent!
          </ProjectText>
        </Grid>
        <Grid item xs={12}>
          <ProjectText
            variant="h1"
            TextSize="1rem"
            fontweight="500"
            align="center"
          >
            We've just emailed you the password reset link.
          </ProjectText>
        </Grid>
      </Grid>
    );
  };
  renderForgotPassword() {
    return (
      <Layout>
        <BackButton onClick={this.handleImageClick}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row"
            }}
          >
            <ArrowBackIosIcon />
            <ProjectText variant="h6" fontweight="900" TextSize="1rem">
              Back
            </ProjectText>
          </Box>
        </BackButton>
        <Formik
          validationSchema={() => this.ForgotPasswordSchema()}
          validateOnChange={true}
          initialValues={{
            email: ""
          }}
          onSubmit={values => {
            this.forgotPassword(values);
          }}
          validateOnBlur={true}
        >
          {({ errors, touched, setFieldValue, handleSubmit }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <ProjectText variant="h4" fontweight="700" space="both" TextSize="28px" align="center"
              >
                Forgot your Password?
              </ProjectText>
              <ProjectText
                variant="h6"
                space="both"
                fontweight="500"
                TextSize="14px"
                align="center"
                textcolor="#363636"
              >
                Enter the email address associated with your account,
                <br />
                and we'll send you a password reset link.
              </ProjectText>
              <Grid container justifyContent="center">
              <Grid item xs={12}>
              <Box className="TextBoxconatiner">

                <InputLabel htmlFor="email">
                  <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                    EMAIL ADDRESS
                  </ProjectText>
                </InputLabel>
                <LandingPageTextField
                  variant="outlined"
                  fullWidth
                  id="email"
                  className="ForgotInputfield"
                  name="email"
                  BoderRadius="8px"
                  inputProps={{
                    "data-test-id": "emailTextField"
                  }}
                  value={this.state.emailId}
                  FormError={errors.email !== undefined && touched.email !== undefined}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => this.handleEmailChange(e, setFieldValue)}
                />
                {errors.email !== undefined && touched.email !== undefined &&
                  <Box className="ErrorMessageContainer">
                    <ErrorIcon />
                    <ProjectText type="error" noWrap fontweight="500" TextSize="1rem">
                      {errors.email}
                    </ProjectText>
                  </Box>
                }
              </Box>
              </Grid>
                </Grid>
              <Grid container justifyContent="center">
                <Grid item xs={10}>
                  <Box className="ButtonConatiner">
                    <ContainedButton
                      data-test-id={"btnEmailLogIn"}
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                    >
                      SEND RESET LINK
                    </ContainedButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Layout>
    );
  }
  render() {
    return (
      <AppBackground>
        <Grid
          container
          justifyContent="center"
          className="GridContainerStyle"
          alignItems="center"
        >
          <Grid item xs={10} sm={10} md={8} lg={6}>
            <GenericCard>
              <Layout>
                <Box data-test-id="emailaccount">
                  {this.state.emailResetSuccess
                    ? this.renderResetLink()
                    : this.renderForgotPassword()}
                </Box>
              </Layout>
            </GenericCard>
          </Grid>
        </Grid>
      </AppBackground>
    );
  }
}

const Layout = styled(Box)({
  "& .ForgotInputfield": {
    height: '56px',
    "@media (max-width: 1200px)": {
      height: '56px !important'
    },
  },
  padding: ".625rem",
  "& .ErrorIcon": {
    display: "flex",
    justifyContent: "center"
  },
  "& .ButtonConatiner": {
    display: "flex",
    justifyContent: "center",
    marginTop: ".875rem",
    marginBottom: ".875rem",
    whiteSpace: 'nowrap'
  },
  "& .ErrorMessageContainer": {
    display: "flex",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      color: "red",
      marginRight: "5px"
    }
  },
  "& .TextBoxconatiner": {
    marginTop: "20px",
    marginBottom: "10px"
  },
  "& .CloseIcons": {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer"
  }
});
const BackButton = styled(Button)({
  position: "absolute",
  left: "1.25rem",
  top: "1.25rem"
});
const emailInputStyle = {
  borderRadius: 8,
  border: ".0625rem solid #CDCDCD",
  height: 56,
  marginBottom: 8,
  marginTop: 8,
  fontFamily: "Asap, sans-serif"
};

// Customizable Area End
