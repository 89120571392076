import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgBell } from "./assets";
import { getStorageData } from "framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any;
  selectedData: any;
  token: any;
  anchorElDropdown:any;
  notificationsData:any;
  loader:boolean;
  HandelMarkAllReadAndDeleteNotificationPopUp:boolean;
  popUpIndex:number;
  isBuyerRole:boolean;
  notificationsResult:any;
  markOneasReadResponse:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  markAsReadCallId: string = "";
  deleteCallId: string = "";
  getNotificationsDataCallId: string = "";
  markAllasReadCallId: string = "";
  markSingleAsReadCallId: string = "";
  deleteNotificationsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    (async () => {
      let notificationLoginData = await getStorageData("loginData");
      if(!notificationLoginData){
        window.location.href = "/"
      }
    })()

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [ {
        "color": "purple",
        "type": "minivan",
        "registration": new Date('2017-01-03'),
        "capacity": 7
      },
      {
        "color": "red",
        "type": "station wagon",
        "registration": new Date('2018-03-03'),
        "capacity": 5
      },],
      selectedData: null,
      token: "",
      anchorElDropdown: null,
      notificationsData:[],
      loader:false,
      HandelMarkAllReadAndDeleteNotificationPopUp:false,
      popUpIndex:-1,
      isBuyerRole:false,
      notificationsResult:{},
      markOneasReadResponse:{}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    let LocationToken = await getStorageData("authToken");
    this.getNotifications(LocationToken);
    const UserRole = await getStorageData("UserRole") === "buyer"
    this.setState({
      isBuyerRole: UserRole
    })
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getNotificationsDataCallId) {
      if(responseJson){
        this.setState({ notificationsData: responseJson, loader: false });
        this.getGroupedNotifications();
      }
    }
    if (apiRequestCallId === this.markAllasReadCallId) {
      if(responseJson){
        toast.success(responseJson?.message);
        let LocationToken = await getStorageData("authToken");
        this.getNotifications(LocationToken);
      }
    }
    if (apiRequestCallId === this.markSingleAsReadCallId) {
      if(responseJson){
        toast.success(responseJson?.meta?.message);
        this.setState({markOneasReadResponse:responseJson})
      }
    }
    if (apiRequestCallId === this.deleteNotificationsCallId) {
      if(responseJson?.message){
        toast.success(responseJson?.message);
        let LocationToken = await getStorageData("authToken");
        this.getNotifications(LocationToken);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  iconBellProps = {
    source: imgBell,
  };

  getNotifications = (token: string) => {
    this.setState({ loader: true });
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotificationsDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  markAsRead(id: number) {
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }
  
  timeSince(date: string) {
    var seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }
  convertDate(inputFormat: string) {
    function pad(s: any) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  }

  HandelMarkAllReadAndDeleteNotification = (e: React.MouseEvent<HTMLDivElement | Document, MouseEvent>, id: number) => {
    this.setState({
      HandelMarkAllReadAndDeleteNotificationPopUp: !this.state.HandelMarkAllReadAndDeleteNotificationPopUp,
      anchorElDropdown: e,
      popUpIndex: id
    })
  }

  markAllasRead = async () => {
    let authToken = await getStorageData("authToken");
    const header = {
      "Content-Type": "application/json",
      token: authToken,
    }; 

    const requestbody = {
      "account":{
        "is_read": true
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.markAllasReadCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMarkAllApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestbody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  markSingleAsRead = async (id:number) => {
    let authToken = await getStorageData("authToken");
    const header = {
      "Content-Type": "application/json",
      token: authToken,
    }; 

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.markSingleAsReadCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getNotificationApiEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteNotifications = async (id:number) => {
    let authToken = await getStorageData("authToken");
    const header = {
      "Content-Type": "application/json",
      token: authToken,
    }; 

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteNotificationsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getNotificationApiEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getGroupedNotifications = () => {
    const groupedNotifications: { [date: string]: Notification[] } = {};
    this.state.notificationsData?.data?.forEach((notification:any) => {
        const date = new Date(notification?.attributes?.created_at)?.toISOString()?.split('T')[0];
        if (!groupedNotifications[date]) {
            groupedNotifications[date] = [];
        }
        groupedNotifications[date]?.push(notification);
    });
    this.setState({notificationsResult:groupedNotifications})
  }

  // Customizable Area End
}
