// Customizable Area Start
import { TextField, TextFieldProps, Select, MenuItem, MenuItemProps } from "@material-ui/core";
import Autocomplete, { AutocompleteGetTagProps } from "@material-ui/lab/Autocomplete";
import { styled } from "@material-ui/styles";

interface Props {
    FormError?: boolean,
    DefulatProps?: TextFieldProps,
    ErrorColor?: string,
    BoderRadius?: string,
    size?: string,
    BgColor?: string,
    BorderTopLeftRadius?: string;
    BorderBottomLeftRadius?: string;
}

const LandingPageTextField = styled(TextField)((props: Props) => (
    {
        borderColor: "#B6CEE2",
        ...(typeof props.BoderRadius === "string" ? {
            "& .MuiInputBase-root": {
                borderRadius: props.BoderRadius,
            },
        } : {
            borderRadius: "4px",
        }),
        ...(typeof props.BorderTopLeftRadius === "string" && {
            "& .MuiInputBase-root": {
                borderTopLeftRadius: props.BorderTopLeftRadius,
            },
        }),
        ...(typeof props.BorderBottomLeftRadius === "string" && {
            "& .MuiInputBase-root": {
                borderBottomLeftRadius: props.BorderBottomLeftRadius,
            },
        }),
        "& input": {
            fontWeight: 600,
            fontFamily: "Asap, sans-serif",
            ...(props.size == "small" && {
                backgroundColor: props.FormError ? "#FBE9E9" : "white",
                borderRadius: "10px"
            }),
            ...(typeof props.BgColor === "string" && {
                backgroundColor: props.BgColor
            }),
        },
        "& .MuiOutlinedInput-root": {
            ...(props.FormError && {
                backgroundColor: typeof props.ErrorColor === "string" ? props.ErrorColor : "#FBE9E9",
                '&.Mui-focused fieldset': {
                    borderColor: props.FormError ? "red" : 'black',
                },
            }),
            fontFamily: "Asap, sans-serif",
            fontWeight: 500,
            '&:hover fieldset': {
                borderColor: props.FormError ? "red" : 'black',
            },
            '&.Mui-focused fieldset': {
                borderColor: props.FormError ? "red" : 'black',
            },
        },
        '& .Mui-focused': {
            outline: "none",
            border: "none"
        },
        "& .MuiFormHelperText-contained": {
            color: "red",
            fontSize: "16px",
            transition: "width 1s ease-out ",
            fontFamily: "Asap, sans-serif",
            marginLeft:"0px"
        },
        "& .Mui-disabled": {
            backgroundColor: "unset !important"
        },
        "@media (max-width: 1200px)": {
            height: "30px"
        },
        ...(props.size !== "small" && {
            height: "56px",
            backgroundColor: "white",
        }),
        fontFamily: "Asap, sans-serif",
    }))

interface ProjectTextFieldProps {
    BgColor?: string,
    ErrorColor?: string,
    BoderRadius?: string,
    FormError?: boolean
}

const ProjectTextField = styled(Select)((props: ProjectTextFieldProps) => ({
    ...(typeof props.BgColor === "string" && {
        backgroundColor: props.BgColor
    }),
    ...(typeof props.BgColor === "undefined" && {
        backgroundColor: "#fff"
    }),
    "& option": {
        padding: "10px"
    },
    position: "relative",
    "& .MuiOutlinedInput-input": {
        padding: "12px"
    },
    borderRadius: "10px",
    "& .MuiSelect-root": {
        fontSize: "26px",
        ...(props.FormError && {
            backgroundColor: typeof props.ErrorColor === "string" ? props.ErrorColor : "#FBE9E9",
            '&.Mui-focused fieldset': {
                borderColor: props.FormError ? "red" : 'black',
            },
            "& .MuiSelect-root": {
                backgroundColor: typeof props.ErrorColor === "string" ? props.ErrorColor : "#FBE9E9",
            }
        }),
        fontFamily: "Asap, sans-serif",
        fontWeight: 500,
        '&:hover fieldset': {
            borderColor: props.FormError ? "red" : 'black',
        },
        '&.Mui-focused fieldset': {
            borderColor: props.FormError ? "red" : 'black',
        },
    },
    "& .MuiSelect-selectMenu": {
        minHeight: "unset"
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1"
    }
}))


interface AutoCompleteProps extends AutocompleteGetTagProps {
    FormError?: boolean,
    DefulatProps?: TextFieldProps,
    ErrorColor?: string,
    BoderRadius?: string,
    size?: string,
    BgColor?: string,
    BorderTopLeftRadius?: string;
    BorderBottomLeftRadius?: string;
}

const AutocompleteStyle = styled(Autocomplete)((props: AutoCompleteProps ) => (
    {
        ...(typeof props.BgColor === "string" && {
            backgroundColor: props.BgColor
        }),
        ...(typeof props.BgColor === "undefined" && {
            backgroundColor: "#fff"
        }),
        "& option": {
            padding: "10px"
        },
        position: "relative",
        "& .MuiOutlinedInput-input": {
            padding: "12px"
        },
        borderRadius: "10px",
        "& .MuiInputBase-root":{
            fontWeight:900,
            ...(props.FormError && {
                backgroundColor: typeof props.ErrorColor === "string" ? props.ErrorColor : "#FBE9E9",
            }),
            "& .MuiChip-label, .MuiChip-deleteIcon":{
                color: "black",
                fontWeight:"bold"
            }
        },
        "& .MuiSelect-root": {
            fontSize: "26px",
            ...(props.FormError && {
                backgroundColor: typeof props.ErrorColor === "string" ? props.ErrorColor : "#FBE9E9",
                '&.Mui-focused fieldset': {
                    borderColor: props.FormError ? "red" : 'black',
                },
                "& .MuiSelect-root": {
                    backgroundColor: typeof props.ErrorColor === "string" ? props.ErrorColor : "#FBE9E9",
                }
            }),
            fontFamily: "Asap, sans-serif",
            fontWeight: 500,
            '&:hover fieldset': {
                borderColor: props.FormError ? "red" : 'black',
            },
            '&.Mui-focused fieldset': {
                borderColor: props.FormError ? "red" : 'black',
            },
        },
        "& .MuiSelect-selectMenu": {
            minHeight: "unset"
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CBD5E1"
        }
    }))

interface CustomMenuItemProps extends MenuItemProps {
    borderColor?: string
}

const OptionForSelect = styled(MenuItem)((props: CustomMenuItemProps) => ({
    fontSize: "22px",
    borderLeft: `4px solid ${props.borderColor}`,
    marginTop: "5px",
    textTransform: "none",
    marginBottom: "5px",
    '& .Mui-focused': {
        outline: "none"
    },
}))


export default LandingPageTextField;
export { LandingPageTextField, ProjectTextField, OptionForSelect, AutocompleteStyle }
// Customizable Area End