export const ellipsis = require("../assets/Ellipsis.png");
export const ic_editinfo = require("../assets/ic_editinfo.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const image_download = require("../assets/download.png");
export const image_editinfo = require("../assets/image_editinfo.png");
export const image_eyeoff = require("../assets/image_eyeoff.png");
export const image_eyeon = require("../assets/image_eyeon.png");
export const image_update = require("../assets/image_update.png");
export const imagebutton_dropdown = require("../assets/imagebutton_dropdown.png");
export const imagebutton_help = require("../assets/imagebutton_help.png");
export const image_seller_info = require("../assets/image_seller_info.png");
export const threedot = require("../assets/image_kabab.png");
export const closeBtn = require("../assets/close.png");
export const checkbox = require("../assets/image_tick_white.png");
export const subCheckbox = require("../assets/image_tick_green.png");
export const bueryImage = require("../assets/image_.png");
export const sellerImage = require("../assets/image_no result found.png");