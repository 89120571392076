import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    InputAdornment,
    IconButton,
    Grid,
    InputLabel,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import GenericCard from "../../../components/src/GenericCard.web";
import AppBackground from "../../../components/src/AppBackground.web";
import NewPasswordController, { } from "../src/NewPasswordController.web";
import ProjectText from "../../../components/src/ProjectText";
import LandingPageTextField from "../../../components/src/ProjectTextField";
import { ContainedButton } from "../../../components/src/GlobalButton";
import { Formik } from 'formik';
import * as Yup from "yup";
import ErrorIcon from '@material-ui/icons/Error';
import { eyeIconoff, eyeIconopen, success } from "./assets";
// Customizable Area End

export default class NewPassword extends NewPasswordController {
    ErrorRender = (ErrorMessage: string | undefined, TextShow: boolean) => {
        return (
            <Box className="ErrorTextContainer">
                <Box className={`ErrorIconContainer ${TextShow ? "" : "TextHidden"}`}>
                    <ErrorIcon />
                </Box>
                <ProjectText className={`TextError ${TextShow ? "" : "TextHidden"}`}>
                    {typeof ErrorMessage === "string" ? ErrorMessage : ""}
                </ProjectText>
            </Box>
        )
    }
    renderInputButton = () => {
        return (
            <Formik
                initialValues={{
                    NewPassword: "",
                    ConfirmPassword: ""
                }}
                validateOnBlur={true}
                validateOnChange={true}
                validationSchema={() => this.ResetPassoword()}
                onSubmit={(values) => {
                    this.resetPassword()
                }}>
                {({ handleSubmit, setFieldValue, touched, errors, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container justifyContent="center" alignItems="center">
                            <Grid item xs={12}>
                                <ProjectText variant="h4" fontweight="700" space="both" TextSize="28px" align="center">Create a New Password</ProjectText>
                            </Grid>
                            <Grid item xs={12}>
                                <ProjectText fontweight="500" space="both" TextSize="16px" align="center">Set up your new password to access your account.</ProjectText>
                            </Grid>
                            <Grid container spacing={1} justifyContent="center" alignItems="center">
                                <Grid item xs={12} md={8}>
                                    <InputLabel htmlFor="Confirmpassword">
                                        <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                                            NEW PASSWORD
                                        </ProjectText>
                                    </InputLabel>
                                    <LandingPageTextField
                                        variant="outlined"
                                        id="Password"
                                        fullWidth
                                        BoderRadius="8px"
                                        name="password"
                                        type={!this.state.enableNewPasswordField ? 'text' : 'password'}
                                        inputProps={{ "data-test-id": "password" }}
                                        value={this.state.newPassword}
                                        className={this.state.enableNewPasswordField ? "PasswordTextField NewPasswordInputfield" : ""}
                                        onCopy={(e: React.ClipboardEvent<HTMLDivElement>) => this.StopCopyPaste(e)}
                                        onPaste={(e: React.ClipboardEvent<HTMLDivElement>) => this.StopCopyPaste(e)}
                                        onCut={(e: React.ClipboardEvent<HTMLDivElement>) => this.StopCopyPaste(e)}
                                        FormError={errors.NewPassword !== undefined && touched.NewPassword !== undefined}
                                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => this.setnewPassword(e.target.value, setFieldValue)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        data-test-id="SHowpassword"
                                                        aria-label="toggle password visibility"
                                                        onClick={this.handleClickShowNewPasswords}
                                                        edge="end"
                                                    >
                                                        {this.state.enableNewPasswordField ? (
                                                            <div>
                                                                <img
                                                                    src={eyeIconoff}
                                                                    alt="off"
                                                                    className="Icon"
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                {" "}
                                                                <img
                                                                    src={eyeIconopen}
                                                                    alt="on"
                                                                    className="Icon"
                                                                />
                                                            </div>
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {this.ErrorRender(errors.NewPassword, touched.NewPassword !== undefined && errors.NewPassword !== undefined)}
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <InputLabel htmlFor="Confirmpassword">
                                        <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                                            CONFIRM NEW PASSWORD
                                        </ProjectText>
                                    </InputLabel>
                                    <LandingPageTextField
                                        variant="outlined"
                                        fullWidth
                                        id="Password"
                                        BoderRadius="8px"
                                        name="Confirmpassword"
                                        onCopy={(e: React.ClipboardEvent<HTMLDivElement>) => this.StopCopyPaste(e)}
                                        onPaste={(e: React.ClipboardEvent<HTMLDivElement>) => this.StopCopyPaste(e)}
                                        onCut={(e: React.ClipboardEvent<HTMLDivElement>) => this.StopCopyPaste(e)}
                                        type={!this.state.enableConfirmPasswordField ? 'text' : 'password'}
                                        inputProps={{ "data-test-id": "Confirmpassword" }}
                                        FormError={errors.ConfirmPassword !== undefined && touched.ConfirmPassword !== undefined}
                                        value={this.state.confirmPassword}
                                        className={this.state.enableConfirmPasswordField ? "PasswordTextField NewPasswordInputfield" : ""}
                                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => this.setconfirmPassword(e.target.value, setFieldValue)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={this.handleClickShowConfirmPasswords}
                                                        edge="end"
                                                        data-test-id="handleClickShowConfirmPasswords"
                                                    >
                                                        {this.state.enableConfirmPasswordField ? (
                                                            <div>
                                                                <img
                                                                    src={eyeIconoff}
                                                                    alt="off"
                                                                    className="Icon"
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                {" "}
                                                                <img
                                                                    src={eyeIconopen}
                                                                    alt="on"
                                                                    className="Icon"
                                                                />
                                                            </div>)
                                                        }
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {this.ErrorRender(errors.ConfirmPassword, errors.ConfirmPassword !== undefined && touched.ConfirmPassword !== undefined)}
                                </Grid>
                                </Grid>
                            <Grid item xs={10}>
                            <Box className="newPasswordBtnContainer">
                                <ContainedButton type="submit" className="RestPassword" data-test-id={"btnResetPassword"} variant="contained" fullWidth>
                                    RESET PASSWORD
                                </ContainedButton>
                            </Box>
                            </Grid>
                        </Grid>
                    </form>)}
            </Formik>
        )
    }
    renderResetSuccessMsg = () => {
        return (
            <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={4}>
                    <Box className="CheckCircleIconConatainer">
                        <img src={success} className="CheckCircleIcon" />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <ProjectText align="center" fontweight="700" TextSize="28px">
                        Password Reset Successfully!
                    </ProjectText>
                </Grid>
                <Grid item xs={12}>
                    <ProjectText align="center" fontweight="500" TextSize="16px">
                        You can now login using your new password.
                    </ProjectText>
                </Grid>
                <Grid item xs={4}>
                    <ContainedButton
                        data-test-id={"btnLogin"}
                        variant="contained"
                        color="primary"
                        onClick={this.navigateToLogin}
                        fullWidth
                    >LOGIN</ContainedButton>
                </Grid>
            </Grid>
        )
    }

    ResetPassoword = () => {
        const PasswordRegexTwoUpperCase = /^(?:[^A-Z]*[A-Z]){2,}[^A-Z]*$/;
        const PasswordRegexTwoLowerCase = /^(?:[^a-z]*[a-z]){2,}[^a-z]*$/;
        const PasswordRegexTwoSpecialCharacters = /^(?:[^\s!@#$%^&*()_+\-={}[\]:;<>,.?/~]*[!@#$%^&*()_+\-={}[\]:;<>,.?/~]){2,}[^\s!@#$%^&*()_+\-={}[\]:;<>,.?/~]*$/;
        const PasswordRegexTwoNumbers = /^(?:[^\d]*\d){2,}[^\d]*$/;
        return Yup.object().shape({
            NewPassword: Yup.string()
                .required('Password is required')
                .matches(PasswordRegexTwoUpperCase, 'Password must contain at least 2 uppercase letters')
                .matches(PasswordRegexTwoLowerCase, 'Password must contain at least 2 lowercase letters')
                .matches(PasswordRegexTwoSpecialCharacters, 'Password must contain exactly two special characters')
                .matches(PasswordRegexTwoNumbers, 'Password must contain exactly two numbers'),
            ConfirmPassword: Yup.string().required("Confirm new passwords is required").oneOf([Yup.ref('NewPassword'), null], "Passwords do not match! "),
        })
    };
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            // Required for all blocks
            <AppBackground>
                <Grid container justifyContent="center" className="GridContainerStyle">
                    <Grid item xs={10} sm={10} md={8} lg={6}>
                        <GenericCard>
                            <Layout data-test-id="emailaccount">

                                {this.state.APIResponseSuccess ? this.renderResetSuccessMsg() : this.renderInputButton()}
                                
                            </Layout>
                        </GenericCard>
                    </Grid>
                </Grid>
            </AppBackground>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const Layout = styled(Box)({
    "& .NewPasswordInputfield": {
        height: '56px',
        "@media (max-width: 1200px)": {
          height: '56px !important'
        },
      },
    "& .RestPassword": {
        whiteSpace: 'nowrap'
    },
    "& .newPasswordBtnContainer": {
        display: "flex",
        justifyContent: "center",
        padding: '25px 100px',
        "@media (max-width: 960px)": {
          padding: '8px 30px'
        },
      },
    "& .CheckCircleIconConatainer": {
        display: "flex",
        justifyContent: "center",
        "& .CheckCircleIcon": {
            width: 100, height: 100, color: "#1653DD", background: "#ffff", borderRadius: "6.25rem"
        }
    },
    "& .Icon": {
        height: "1.4rem",
    },
    "& .PasswordTextField": {
        "& input": {
            fontFamily: 'monospace',
            fontSize: "xx-large",
            height: "18px"
        }
    },
    "& .ErrorTextContainer": {
        display: "flex",
        marginTop: "5px",
        alignItems: "center",
        "& .TextHidden": {
            visibility: "hidden"
        },
        "& .ErrorIconContainer": {
            marginRight: "6px",
            "& .MuiSvgIcon-root": {
                color: "red"
            }
        },
        "& .TextError": {
            color: "red",
            fontSize: "16px",
            marginBottom: "0px",
            transition: "width 1s ease-out ",
            fontFamily: "Asap, sans-serif",
        },
    },
});

const EmailLabel = styled(Box)(
    {
        color: "#363636",
        fontWeight: 700,
        fontSize: ".875rem",
        marginTop: ".3125rem"
    }
)
const PasswordLabel = styled(Box)(
    {
        color: "#363636",
        fontWeight: 700,
        fontSize: ".875rem",
        marginTop: ".625rem"
    }
)
const LabelTitle = styled(Box)(
    {
        fontWeight: 700,
        fontSize: "1.3125rem",
        color: "#1E1E1E"
    }
)

// Customizable Area End