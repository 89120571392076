import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from "react-toastify";
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  enablePasswordField: boolean;
  password: string;
  email: string;
  btnTxtSocialLogin: string;
  placeHolderPassword: string;
  placeHolderEmail: string;
  labelOr: string;
  btnTxtLogin: string;
  labelHeader: string;
  APIResponseError: boolean;
  accountSuspended: boolean;
  organizationSuspended: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationApiCallId: string = "";
  apiEmailLoginCallId: string = "";
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    this.state = {
      APIResponseError: false,
      enablePasswordField: false,
      email: "",
      password: "",
      placeHolderPassword: configJSON.placeHolderPassword,
      placeHolderEmail: configJSON.placeHolderEmail,
      labelOr: configJSON.labelOr,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelHeader: configJSON.labelHeader,
      accountSuspended: false,
      organizationSuspended: false,
    };
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };
  // Customizable Area End

  responseErrorsCell = async (responseJson: {
    errors: Array<{
      organization: string;
      account: string;
      failed_login: string;
    }>;
  }) => {
    const errors = responseJson.errors;
    toast.warning(responseJson.errors);
    if (
      errors.some(
        (error: { organization?: string }) =>
          error.organization === "is suspended"
      )
    ) {
      this.setState({ organizationSuspended: true });
    }
    if (
      errors.some(
        (error: { account: string }) => error.account === "is suspended"
      )
    ) {
      this.setState({ accountSuspended: true });
    }
  };
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.apiEmailLoginCallId) {
        this.apiEmailLoginCallIdResponse(responseJson);
      }
    }
    // Customizable Area End
  }


  apiEmailLoginCallIdResponse = (responseJson: {
    meta: {
      full_phone_number: string;
      role_name: string; token: string
      full_name: string;
      organization_id: string;
      email: string,
      portnet_url:string,
      price_terms:string
    };
    errors: Array<{
      organization: string;
      account: string;
      failed_login: string;
    }>;
  }) => {
    if (responseJson && responseJson.errors) {
      this.responseErrorsCell(responseJson);
      this.ErrorMessageHandelResponse(responseJson.errors)
      this.setState({
        APIResponseError: true,
      });
    }
    if (responseJson.meta) {
      const data = JSON.stringify(responseJson.meta)
      setStorageData("authToken", responseJson.meta.token);
      setStorageData("loginData", data);
      setStorageData("UserRole", responseJson.meta.role_name);
      setStorageData("priceTerm", responseJson.meta.price_terms);
      setStorageData("portnetUrl", responseJson.meta.portnet_url);
      if (responseJson.meta.role_name === "buyer") {
        this.props.navigation.navigate("BuyersRequest");
      }
      if (responseJson.meta.role_name === "seller") {
        this.props.navigation.navigate("SellerListing");
      }
    }
  };

  ErrorMessageHandelResponse = (ErrorData: Array<{ [key: string]: string }>) => {
    if (typeof ErrorData === "string") {
      toast.error(ErrorData);
    }
    this.ErrorMessageForLoop(ErrorData)
  };
  ErrorMessageForLoop = (NewData: Array<{ [key: string]: string }>) => {
    for (const obj of Object.values(NewData)) {
      if (typeof NewData === "object") {
        toast.error(obj);
        if (Array.isArray(obj)) {
          for (const key in obj) {
            toast.error(`${Object.entries(obj[key])[0][0].toString().replace("_", " ")} ${Object.entries(obj[key])[0][1].toString().replace(",", " ")}`);
          }
        }
      }
      if (Array.isArray(NewData)) {
        for (const key in obj) {
          toast.error(obj[key]);
        }
      }
    }
  }

  NavigateToLandingPage = () => {
    this.props.navigation.navigate("LandingPage");
  }

  goToForgotPasswordBlog = () => {
    this.props.navigation.navigate("ForgotPassword");
  };

  SetFieldSVlaues = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
    setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    if (name === "password") {
      let values = e.target.value.replace(/\s/g, '');
      setFieldValue(name, values);
    } else {
      setFieldValue(name, e.target.value);
    }
  };

  doEmailLogIn = (values: { email: string; password: string }) => {
    const attrs = {
      email: values.email,
      password: values.password,
    };
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const httpBody = {
      data: data,
    };

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
