import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  newPassword: string,
  confirmPassword: string,
  emailResetSuccess: boolean,
  enableNewPasswordField: boolean,
  enableConfirmPasswordField: boolean,
  APIResponseSuccess: boolean,

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NewPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  newPasswordApiCallID: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      // Customizable Area Start
      newPassword: "",
      confirmPassword: "",
      emailResetSuccess: false,
      enableNewPasswordField: true,
      enableConfirmPasswordField: true,
      APIResponseSuccess: false
    };


    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let ResponseData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.newPasswordApiCallID) {
        if (
          ResponseData.errors === undefined &&
          ResponseData.error === undefined
        ) {
          if (ResponseData?.message) {
            this.setState({ APIResponseSuccess: true })
          }
        } else {
          this.ErrorHandelResponse(ResponseData.errors ?? ResponseData.error);
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  ErrorHandelResponse = (ErrorApidata: Array<{ [key: string]: string }>) => {
    if (typeof ErrorApidata === "string") {
      toast.error(ErrorApidata);
    }
    this.ErrorForLoop(ErrorApidata)
  };

  ErrorForLoop = (GetErrordata: Array<{ [key: string]: string }>) => {
    for (const obj of Object.values(GetErrordata)) {
      if (typeof GetErrordata === "object") {
        toast.error(obj);
        if (Array.isArray(obj)) { for (const key in obj) { toast.error(`${Object.entries(obj[key])[0][0].toString().replace("_", " ")} ${Object.entries(obj[key])[0][1].toString().replace(",", " ")}`); } }
      }
      if (Array.isArray(GetErrordata)) {
        for (const key in obj) {
          toast.error(obj[key]);
        }
      }
    }
  }
  // Customizable Area End
  // Customizable Area Start
  resetPassword = async () => {
    let TokenFromUrl = window.location.href.split("token=")[1]
    const header = {
      "Content-Type": "application/json",
      "token": TokenFromUrl
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.newPasswordApiCallID = requestMessage.messageId;
    let Body = {
      data: {
        new_password: this.state.newPassword
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.Reset_Password_End_Point
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.Reset_Password_Method_Type
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  StopCopyPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault()
  }
  handleClickShowNewPasswords = () => {
    this.setState({
      enableNewPasswordField: !this.state.enableNewPasswordField,
    });
  };

  handleClickShowConfirmPasswords = () => {
    this.setState({
      enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
    });
  };
  setnewPassword = (text: string, setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void) => {
    text = text.replace(/\s/g, '')
    setFieldValue("NewPassword", text)
    this.setState({
      newPassword: text,
    });
  };
  setconfirmPassword = (text: string, setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void) => {
    text = text.replace(/\s/g, '')
    setFieldValue("ConfirmPassword", text)
    this.setState({
      confirmPassword: text,
    });
  };

  navigateToLogin = () => {
    this.props.navigation.navigate("EmailAccountLogin");
  };
  // Customizable Area End
}