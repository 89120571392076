export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgClose = require("../assets/reject1.png");
export const imgTerm = require("../assets/terms.png");
export const SuccessImage = require("../assets/SuccessImage.png");
export const addImg = require("../assets/addReqImg.png");
export const dots = require("../assets/dots.png");
export const addControllerImg = require("../assets/addControllerImg.png");
export const AddcloseImg = require("../assets/AddcloseImg.png");
export const terms = require("../assets/terms.png");
export const RemoveIcon = require("../assets/RemoveIcon.png");
export const resultNotFound = require("../assets/resultNotFound.png");
export const resultNotFound1 = require("../assets/image_noresultfound1.png");
export const resultNotFoundRed = require("../assets/resultNotFoundRed.png");
export const DateTimeIcon = require("../assets/DateTimeIcon.png");
export const arrow = require("../assets/arrow.png");
export const add = require("../assets/add.png");
export const price = require("../assets/price.png");
export const ellipsis = require("../assets/Ellipsis.png");
export const close = require("../assets/close.png");
export const tooltip = require("../assets/tooltip.png");
export const add_black = require("../assets/add_black.png");
export const circleAdd = require("../assets/circleadd.png");
export const add_transparent = require("../assets/add_transparent.png");
export const image_success = require("../assets/image_success.png");
export const redQue = require("../assets/redQue.png");
export const blueQue = require("../assets/blueQue.png");
export const orangeQue = require("../assets/orangeQue.png");
export const reject = require("../assets/Reject.png");
export const arraw = require("../assets/arraw.png");
export const Matches = require("../assets/Matches.png");
export const reloadImg = require("../assets/reloadImg.png");
export const download = require("../assets/download.png");
export const image_dropdown=require('../assets/dropdownArrow.png');
export const blue=require('../assets/blue.png');
export const yellow=require('../assets/yellow.png');
export const checked=require('../assets/image_checkbox_.png');
export const unchecked=require('../assets/checkbox_.png');
export const help=require('../assets/view_help.png');
export const Vector=require('../assets/Vector.png');
export const closeicon= require("../assets/image_close.png");
export const remove = require("../assets/remove.png");
export const downIMg = require("../assets/image_down.png");
export const upIMg = require("../assets/image_up.png");
export const uploadIMg = require("../assets/image_upload.png");
export const pdf = require("../assets/image_pdf.png");
export const deleteImg = require("../assets/image_remove.png");
export const calender = require("../assets/calender.png");
export const upload = require("../assets/image_upload.png");
export const orangeSucessIcon = require("../assets/orangeSuccessTick.png");
export const SuccessGrad = require("../assets/redTick.jpeg");
