import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    emailId: string,
    isEmailVerified: boolean,
    emailResetSuccess: boolean
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SignupScreenController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    forgotPasswordApiCallID: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.state = {
            // Customizable Area Start
            emailId: "",
            isEmailVerified: true,
            emailResetSuccess: false
            // Customizable Area End
        };

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        // Customizable Area End

        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }


    // Customizable Area Start

    // Customizable Area End
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)

            );
            if (apiRequestCallId === this.forgotPasswordApiCallID) {
                if (
                    responseJson.errors === undefined &&
                    responseJson.error === undefined
                ) {
                    this.setState({
                        emailResetSuccess: true
                    })
                } else {
                    this.ErrorHandelResponse(responseJson.errors ?? responseJson.error);
                }
            }

        }
        // Customizable Area End
    }
    // Customizable Area Start
    ErrorHandelResponse = (data: Array<{ [key: string]: string }>) => {
        if (typeof data === "string") {
            toast.error(data);
        }
        this.ErrorForLoop(data)
    };
    NavigateToLandingPage = () => {
        this.props.navigation.navigate("LandingPage");
    }

    ErrorForLoop = (Errordata: Array<{ [key: string]: string }>) => {
        for (const obj of Object.values(Errordata)) {
            if (typeof Errordata === "object") {
                toast.error(obj);
                if (Array.isArray(obj)) {
                    for (const key in obj) {
                        toast.error(`${Object.entries(obj[key])[0][0].toString().replace("_", " ")} ${Object.entries(obj[key])[0][1].toString().replace(",", " ")}`);
                    }
                }
            }
            if (Array.isArray(Errordata)) {
                for (const key in obj) {
                    toast.error(obj[key]);
                }
            }
        }
    }
    forgotPassword = async (values: { email: string }) => {
        let httpBody = {
            data: {
                email: values.email
            }
        }

        const header = {
            "Content-Type": configJSON.Forgot_Password_Content_Type,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.forgotPasswordApiCallID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.Forgot_Password_End_Point
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.Forgot_Password_Method_Type
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handleEmailChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void) => {
        this.setState({ emailId: e.target?.value })
        this.setState({ isEmailVerified: true })
        setFieldValue("email", e.target.value)
    }


    handleImageClick = () => {
        this.props.navigation?.goBack()
    }

    // Customizable Area End

}