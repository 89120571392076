// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
}

export interface S {
  showPassword: boolean;
  token: string;
  orgName: string;
  EmailId: string,
  FullName: string,
  showPopup: boolean;
  loading: boolean;
}

export interface SS {
  id: any;
}

export default class OrganizationUserSignUpController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      showPassword: false,
      token: "",
      orgName: "",
      EmailId: "",
      FullName: "",
      showPopup: false,
      loading: true,
    };
  }
  async componentDidMount() {
    let token = this.props.location.search.split("token=");
    this.setState({ token: token[1] });
    this.getPrefillData(token[1]);
    super.componentDidMount();
  }

  userSignUpApiCallID: string = "";
  prefilledUserSignUpApiCallID: string = "";

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.userSignUpApiCallID) {
      if (responseJson.errors === undefined && responseJson.error === undefined) {
        this.setState({ showPopup: true });
      } else {
        this.ErrorHandelResponse(responseJson.errors ?? responseJson.error);
      }
    }
    if (apiRequestCallId === this.prefilledUserSignUpApiCallID) {
      if (responseJson?.prefilled?.organization.name) {
        this.setState({ loading: false });
        this.setState({ orgName: responseJson.prefilled.organization.name });
      }
      if (responseJson?.prefilled?.user) {
        this.setState({ loading: false });
        this.setState({
          FullName: responseJson?.prefilled?.user.full_name,
          EmailId: responseJson?.prefilled?.user.email
        });
      }
    }
  }


  ErrorForLoop = (ErrorResponse: Array<{ [key: string]: string }>) => {
    for (const obj of Object.values(ErrorResponse)) {
      if (typeof ErrorResponse === "object") {
        toast.error(obj);
        if (Array.isArray(obj)) {
          for (const key in obj) {
            toast.error(`${Object.entries(obj[key])[0][0].toString().replace("_", " ")} ${Object.entries(obj[key])[0][1].toString().replace(",", " ")}`);
          }
        }
      }
      if (Array.isArray(ErrorResponse)) {
        for (const key in obj) {
          toast.error(key.replace("_"," ") + ' ' + obj[key]);
        }
      }
    }
  }

  ErrorHandelResponse = (ErrorResponse: Array<{ [key: string]: string }>) => {
    if (typeof ErrorResponse === "string") {
      toast.error(ErrorResponse);
    }
    this.ErrorForLoop(ErrorResponse)
  };

  UserSignUp = (values: {
    email: string,
    password: string,
    designation: string,
    department: string,
    name: string,
    phone: string
  }) => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userSignUpApiCallID = requestMessage.messageId;

    let Body = {
      data: {
        type: "email_account",
        attributes: {
          email: values.email,
          password: values.password,
          designation: values.designation,
          department: values.department,
          full_name: values.name,
          full_phone_number: `${values.phone}`,
        },
      },
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/accounts"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPrefillData = (token: string) => {
    const header = {
      "Content-Type": "application/json",
      token: token,
      prefilled: true,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.prefilledUserSignUpApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  PhoneInputNumber = (dialCode: string, number: string, setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void) => {
    let phone = `+${dialCode} ${number.slice(
      dialCode.length
    )}`;
    setFieldValue("phone", `${phone}`);
  }

  TextfieldChnage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void) => {
    if (e.target.name === "password") {
      let value = e.target.value
      value = value.replace(/\s/g, '');
      setFieldValue(`${e.target.name}`, value)
    } else {
      setFieldValue(`${e.target.name}`, e.target.value)
    }
  }


}
// Customizable Area End
