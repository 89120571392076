// Customizable Area Start
import { 
  BlockComponent 
} from "../../../framework/src/BlockComponent";
import { 
  RouteComponentProps,
} from "react-router";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { 
  Message 
} from "../../../framework/src/Message";
import { 
  runEngine 
} from "../../../framework/src/RunEngine";
import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
import moment from "moment";
import { 
  toast,
} from "react-toastify";

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: Record<string, string>;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

interface S {
  // Customizable Area Start
  newVesalVoyageData: [],
  currentTab: number,
  sellerListings: any,
  switch: boolean,
  offerModal: boolean,
  listingAdded: boolean,
  addListing: boolean,
  containerData: any,
  selectedContainer: any,
  viewPopup: boolean,
  offerUpdated: boolean,
  withdrawOffer: boolean,
  vesselVoyageData: any,
  loginData: any,
  token: string,
  loading: boolean,
  sellerOfferData: any,
  anchorElDropdown: any,
  selectedOrg: any,
  sellerViewOfferData: any,
  isViewing: boolean,
  isUpdating: boolean,
  openWithdrawModal: boolean,
  openUpdateModal: boolean,
  withdrawableItem: any,
  refreshKey: number,
  offerExpanded: boolean,
  offerExpandedPol: boolean,
  offerExpandedPod: boolean,
  offerExpandedtype: boolean,
  offerOptions: any,
  polExpanded: boolean,
  polOptions: any,
  podExpanded: boolean,
  podOptions: any,
  typeExpanded: boolean,
  typeOptions: any,
  voyageData: Array<{
    vessel_code: string,
    in_voyage_code: string,
    out_voyage_code: string,
  }>,
  snackbarOpen: boolean,
  switchAlert: boolean,
  expandedId: any,
  principalOrgList: any,
  prinicpalOrgList: any,
  polData: any,
  tempPodTerminal: any,
  pol: any,
  pol_terminal: any,
  organization_id: any,
  principal_organization_name: any,
  pod: any,
  pod_terminal: any,
  etb_pol: any,
  eta_pod: any,
  listing_validity: any,
  offer_seller_rate: any,
  offer_quantity: any,
  offer_terms_sellers: any,
  offer_terms_sellers2: any,
  offer_sec_container_type: any,
  offer_sec__tons_teu: any,
  offer_sec_quantity: any,
  offer_sec_terms_sellers: any,
  offer_sec_terms_sellers2: any,
  offer_sec_rate_by_seller: any,
  vesselData: any,
  inVoyageData: any,
  outVoyageData: any,
  podData: any,
  polTerminalData: any,
  podTerminalData: any,
  tempValue: any,
  VesselDropDownOpen: boolean,
  filter: {
    listingID: Array<{
       label: string; 
       isChecked: boolean 
      }>;
    pol: Array<{ 
      label: string; 
      isChecked: boolean 
    }>;
    pod: Array<{ 
      label: string; 
      isChecked: boolean 
    }>;
    type: Array<{ 
      label: string; 
      isChecked: boolean 
    }>;
    startDate: string,
    endDate: string,
    principalOrganisation: Array<{ 
      label: string; 
      isChecked: boolean ,
      attributes?:any}>;
  },
  offerIdFilterOptions: (string | undefined)[],
  polFilterOptions: (string | undefined)[],
  podFilterOptions: (string | undefined)[],
  typeFilterOptions: (string | undefined)[],
  isSlotFilterOpen: boolean,
  activeSlotFilter: any,
  activePrincipalOrganization: any,
  storedVesselData: any,
  vesselVoyageId: string | number,
  open: boolean,
  StartDate: string,
  EndDate: string,
  RenderStart: string,
  RenderEndDate: string,
  DynamicCount: {
    listing?: string | number,
    requst?: string | number,
    offers?: string | number,
    booking?: string | number,
  },
  PrincipalOrganizationSelectedName: any,
  VoyageDropDownData: Array<{
    vslM: string;
    name: string;
    inVoyN: string;
    outVoyN: string;
}>;
PortNetApiAllData: Array<{
  inVoyN: string;
  vslM: string;
  outVoyN: string;
  name: string;
}>;
InVoyogeAndOutVoyoge: string;
IsVesselSelected: boolean;
SelectedVesselVoyage: {
  inVoyN?: string;
  name?: string;
  outVoyN?: string; 
  vslM?: string;
};
VesselDropDownData: Array<{
  outVoyN: string; 
  name: string;
  vslM: string;
  inVoyN: string;
}>;
VesselDropDownString: string;
VoyageDropDownOpen: boolean;
FormIsEdit: boolean;
DateRangeStartDate: Date | undefined;
  DateRangeEndDate: Date | undefined;
  newVoyageValue: any;
  expiry_date: Date | string;
  FormikFormReenableReinitialize: boolean,
  offeredQuantity: any,
offeredRate: any,
offeredTermsOne:any,
offeredTermsTwo:any,
secondOfferedQuantity: any,
secondOfferedrate: any,
secondOfferedTermsOne: any,
secondOfferedTermsTwo: any,
firstContainerActive: boolean,
secondContainerActive: boolean,
PortNetAPiCall:boolean,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SellerPendingOffersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  setValuesRef: (field: string, value: string, shouldValidate?: boolean | undefined) => void = () => { };
  timeoutId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    (async () => {
      let pendingOffersLoginData = await getStorageData("UserRole");
      if(!pendingOffersLoginData){
        window.location.href = "/"
      } else if(pendingOffersLoginData == "buyer"){
        window.location.href = "/BuyersRequest"
      }
    })()
    
    this.state = {
      newVesalVoyageData: [], currentTab: 2,
      sellerListings: [], switch: true,
      offerModal: false, listingAdded: false,
      addListing: false, containerData: [],
      selectedContainer: [], viewPopup: false,
      offerUpdated: false, withdrawOffer: false,
      vesselVoyageData: [], token: "",
      loginData: {}, loading: true,
      sellerOfferData: [], anchorElDropdown: null,
      selectedOrg: "", sellerViewOfferData: {},
      isViewing: false, isUpdating: false,
      openWithdrawModal: false, openUpdateModal: false,
      withdrawableItem: {}, refreshKey: 0,
      offerExpanded: false, offerOptions: [
        { label: "Offer A", isChecked: false },
        { label: "Offer B", isChecked: false },
        { label: "Offer C", isChecked: false },
        { label: "Offer D", isChecked: false },
        { label: "Offer E", isChecked: false },
      ],
      polExpanded: false,
      polOptions: [
        { label: "Pol A", isChecked: false },
        { label: "Pol B", isChecked: false },
        { label: "Pol C", isChecked: false },
        { label: "Pol D", isChecked: false },
        { label: "Pol E", isChecked: false },
      ],
      podExpanded: false,
      podOptions: [
        { label: "Pod A", isChecked: false },
        { label: "Pod B", isChecked: false },
        { label: "Pod C", isChecked: false },
        { label: "Pod D", isChecked: false },
        { label: "Pod E", isChecked: false },
      ],
      typeExpanded: false,
      typeOptions: [
        { label: "Type A", isChecked: false },
        { label: "Type B", isChecked: false },
        { label: "Type C", isChecked: false },
        { label: "Type D", isChecked: false },
        { label: "Type E", isChecked: false },
      ],
      voyageData: [], snackbarOpen: false,
      switchAlert: false, expandedId: null,
      principalOrgList: [], prinicpalOrgList: [],
      polData: [], tempPodTerminal: [],
      pol: "", pol_terminal: "",
      organization_id: 1, principal_organization_name: "hi",
      pod: "", pod_terminal: "",
      etb_pol: "", eta_pod: "",
      listing_validity: "", offer_seller_rate: "",
      offer_quantity: "", offer_terms_sellers: "",
      offer_terms_sellers2: "", offer_sec_container_type: "",
      offer_sec__tons_teu: "", offer_sec_quantity: "",
      offer_sec_terms_sellers: "", offer_sec_terms_sellers2: "",
      offer_sec_rate_by_seller: "", vesselData: [],
      inVoyageData: [], outVoyageData: [],
      podData: [], polTerminalData: [],
      podTerminalData: [], tempValue: {},
      filter: { listingID: [],
        pol: [], pod: [],
        type: [], startDate: "",
        endDate: "", principalOrganisation: [],
      },
      offerExpandedPol: false, offerExpandedPod: false,
      offerExpandedtype: false, polFilterOptions: [],
      offerIdFilterOptions: [], podFilterOptions: [],
      typeFilterOptions: [], isSlotFilterOpen: false,
      activeSlotFilter: [], activePrincipalOrganization: [],
      storedVesselData: [], vesselVoyageId: "",
      open: false, StartDate: "",
      EndDate: "", RenderStart: "",
      RenderEndDate: "", DynamicCount: {
        listing: "0", requst: "0",
        offers: "0", booking: "0",
      }, PrincipalOrganizationSelectedName: "",
      VesselDropDownOpen: false, VoyageDropDownData: [],
      PortNetApiAllData: [], IsVesselSelected: true,
      InVoyogeAndOutVoyoge: "",  SelectedVesselVoyage: {
        inVoyN: "", outVoyN: "",
        vslM: "", name: ""
    }, VesselDropDownData: [],
    VesselDropDownString: "", FormIsEdit: false,
    VoyageDropDownOpen: false, DateRangeStartDate: new Date(),
    DateRangeEndDate: new Date(), newVoyageValue: null,
    expiry_date: new Date(), FormikFormReenableReinitialize: false,
    firstContainerActive: false, secondContainerActive: false,
      offeredQuantity: "", offeredRate: "",
      offeredTermsOne: "", offeredTermsTwo: "",
      secondOfferedQuantity: "", secondOfferedrate: "",
      secondOfferedTermsOne: "", secondOfferedTermsTwo: "",
      PortNetAPiCall: false
    };
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    let loginData = await getStorageData("loginData");
    let LocationToken = await getStorageData("authToken");
    let PolData = await getStorageData("PolData");
    let storedVesselVoyageData = await getStorageData("voyageData");
    let obj = JSON.parse(loginData);
    this.setState({
      loginData: obj,
      storedVesselData: JSON.parse(storedVesselVoyageData),
    });
    this.OnloadApicall(LocationToken);
    let principal = await getStorageData("PrincipalOrganisation");
    let obj1 = JSON.parse(principal);
    let tempPodTerminal: any[] = [];
    PolData = JSON.parse(PolData)?.map((item: any) => {
      tempPodTerminal.push(item?.attributes?.port_country);
      return item;
    });
    this.setState({ tempPodTerminal: [...this.state.tempPodTerminal, ...tempPodTerminal], loginData: obj, principalOrgList: obj1, polData: PolData });
  }

  OnloadApicall = (LocationToken: string) => {
    this.setState({ token: LocationToken });
    this.getSellerOfferData();
    this.getVesselVoyageData();
  };

  DynamicDataSet = (e: {
    listing?: string;
    requst?: string;
    offers?: string;
    booking?: string;
  }) => {
    this.setState({ DynamicCount: e });
  };

  getSellerOfferDataApiCallID: string = ""
  getViewOfferDataApiCallID: string = ""
  onWithdrawOfferDataApiCallID: string = ""
  onUpdateOfferDataApiCallID: string = ""
  onSwitchRoleApiCallID: string = ""
  getVesselVoyageDataApiCallID: string = ""
  applyFilterApiCallID: string = ""
  getFilteredOfferDataApiCallID: string = ""
  getVesselVoyagesApiCallID: string = ""
  GetVesselVoyagePostApi: string = ""
  GetEtaETbAPiId: string = ""

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getSellerOfferDataApiCallID) {
      if (responseJson.data) {
        this.setState({
          sellerOfferData: responseJson.data,
          loading: false,
          DynamicCount: {
            ...this.state.DynamicCount,
            offers: responseJson.data.length || 0,
          },
        });
        let temp = {
          listingID: responseJson.data.map(
            (val: any) => val.attributes.offer_uid
          ),
          pol: responseJson.data.map((val: any) => val.attributes.pol),
          principalOrgList: Array.isArray(this.state.principalOrgList)
            ? this.state.principalOrgList?.map((val: any) => val.attributes)
            : [this.state.principalOrgList?.attributes],
          pod: responseJson.data.map((val: any) => val.attributes.pod),
          type: responseJson.data.map(
            (val: any) => val.attributes.offer_container_type
          ),
          startDate: responseJson.data.map(
            (val: any) => val.attributes.etb_pol
          ),
          endDate: responseJson.data.map((val: any) => val.attributes.eta_pod),
        };
        let a1 = [...new Set(temp.listingID)];
        let a2 = [...new Set(temp.pol)];
        let a3 = [...new Set(temp.pod)];
        let a4 = [...new Set(temp.type)];
        let a5 = [...new Set(temp.principalOrgList)];
        let arr = {
          listingID: a1.map((str: any) => ({
            label: str,
            isChecked: false,
          })),
          pol: a2.map((str: any) => ({ label: str, isChecked: false })),
          pod: a3.map((str: any) => ({ label: str, isChecked: false })),
          type: a4.map((str: any) => ({
            label: str,
            isChecked: false,
          })),
          startDate: "",
          endDate: "",
          principalOrganisation: a5.map((str: any) => ({
            label: str.name,
            isChecked: false,
            attributes: str,
          })),
        };
        this.setState({
          filter: arr,
        });
      }
    }
    if (apiRequestCallId === this.onWithdrawOfferDataApiCallID) {
      this.getSellerOfferData();
    }
    this.onReceiveViewOfferData(apiRequestCallId, responseJson);
    this.onReceiveUpdateOfferData(apiRequestCallId, responseJson);
    this.onReceiveSwitchRoleData(apiRequestCallId, responseJson);
    this.onReceiveVesselVoyageData(apiRequestCallId, responseJson);
    this.onReceiveFilteredOfferData(apiRequestCallId, responseJson);
    this.GetVesselVoyagePostApiDataresponseSet(apiRequestCallId, responseJson);
    this.getVesselVoyageResponseData(apiRequestCallId, responseJson);
    this.GetEtaEtbAPiResponse(apiRequestCallId, responseJson);

  }

  GetEtaEtbAPiResponse = (apiRequestCallId: string, data: { errors: Array<{ [key: string]: string }>, results: Array<{ vslM: string, inVoyN: string, outVoyN: string, portOfLoad: string, portofDisc: string, etbDt: string, etuDt: string, etaDt: string }> }) => {
    if (apiRequestCallId === this.GetEtaETbAPiId) {if (Array.isArray(data.errors)) {this.setState({loading: false});this.ErrorHandelResponse(data.errors)}
        if (Array.isArray(data.results) && data.results.length !== 0) {if (data.results[0].etbDt && data.results[0].etaDt) {this.setValuesRef("ETBATPOL", moment.parseZone(data.results[0].etbDt).format('YYYY-MM-DD'));this.setValuesRef("ETAATPOD", moment.parseZone(data.results[0].etaDt).format('YYYY-MM-DD'))
                this.setState({ loading: false, etb_pol: moment.parseZone(data.results[0].etbDt).format('YYYY-MM-DD'), eta_pod: moment.parseZone(data.results[0].etaDt).format('YYYY-MM-DD') }, () => { const dateFrom = moment(this.state.etb_pol).subtract(this.state.loginData.minimum_validity_of_listing, "d").parseZone().format("YYYY-MM-DD"); this.setState({ expiry_date: dateFrom, loading: false, FormikFormReenableReinitialize: false });})}}}
}

ErrorHandelResponse = (data: any) => {
  if (typeof data === "string") {
      toast.error(data);
  }
  this.ErrorForLoop(data)
};

renderOtherTypeError = (data: Array<{ [key: string]: string }>, obj: any) => {
  if (Array.isArray(data)) {
    if (data[0].message) {
        toast.error("The respective data does not exist.");
    } else {
        for (const key in obj) {
            toast.error(obj[key]);
        }
    }
}
}

ErrorForLoop = (data: Array<{ [key: string]: string }>) => {
  for (const obj of Object.values(data)) {
      if (typeof data === "object") {
          toast.error(obj);
          if (Array.isArray(obj)) {
              for (const key in obj) { toast.error(`${Object.entries(obj[key])[0][0].toString().replace("_", " ").replace(/\b\w/g, char => char.toUpperCase())} ${Object.entries(obj[key])[0][1].toString().replace(",", " ")}.`);}}
      }
      this.renderOtherTypeError(data, obj)
  }
}

  getVesselVoyageResponseData = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getVesselVoyagesApiCallID) {
      const DataAPIresposne = responseJson.data?.map(
        (item: {
          attributes: {
            vessel_code: string;
            in_voyage_code: string;
            out_voyage_code: string;
          };
        }) => {
          return {
            vessel_code: item.attributes.vessel_code,
            in_voyage_code: item.attributes.in_voyage_code,
            out_voyage_code: item.attributes.out_voyage_code,
          };
        }
      );
      const ReposneData = Array.isArray(responseJson.data)
        ? responseJson.data.map(
            (item: {
              id: number;
              attributes: {
                vessel_code: string;
                in_voyage_code: string;
                out_voyage_code: string;
              };
            }) => {
              return {
                name: item.attributes.vessel_code,
                inVoyN: item.attributes.in_voyage_code,
                outVoyN: item.attributes.out_voyage_code,
                vslM: item.attributes.vessel_code,
              };
            }
          )
        : [];

      const Data = ReposneData.filter(
        (value: { name: string }, index: number, self: Array<{}>) =>
          index ===
          self.findIndex((obj: { name?: string }) => obj.name === value.name)
      );

      this.setState({
        VesselDropDownData: Data,
        PortNetApiAllData: ReposneData,
        loading: false,
        VesselDropDownString: "",
      });
      this.setState({ vesselVoyageData: DataAPIresposne, loading: false });
      setStorageData("voyageData", JSON.stringify(DataAPIresposne));
    }
  };

  onReceiveViewOfferData = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getViewOfferDataApiCallID) {
      if (responseJson.data) {
        this.setState({
          sellerViewOfferData: responseJson.data,
          loading: false,
          isViewing: true,
          VesselDropDownString: responseJson.data.attributes.vessel_code || "",
          InVoyogeAndOutVoyoge: "In " +
            responseJson.data.attributes.in_voyage +
            " " + "Out " +
            responseJson.data.attributes.out_voyage,
          newVoyageValue: responseJson.data.attributes.in_voyage,
          organization_id: responseJson.data.attributes.organization_id,
          principal_organization_name:
            responseJson.data.attributes.principal_organization_name,
          pol: responseJson.data.attributes.pol,
          pol_terminal: responseJson.data.attributes.pol_terminal,
          pod: responseJson.data.attributes.pod,
          pod_terminal: responseJson.data.attributes.pod_terminal,
          etb_pol: moment
            .parseZone(responseJson.data.attributes.etb_pol)
            .format("DD-MMM-YYYY"),
          eta_pod: moment
            .parseZone(responseJson.data.attributes.eta_pod)
            .format("DD-MMM-YYYY"),
          listing_validity: responseJson.data.attributes.listing_validity,
          offer_seller_rate: responseJson.data.attributes.offer_seller_rate,
          offer_quantity: responseJson.data.attributes.offer_quantity,
          offer_terms_sellers:
            responseJson.data.attributes.offer_terms_sellers?.split("/")[0],
          offer_terms_sellers2:
            responseJson.data.attributes.offer_terms_sellers?.split("/")[1],
          offer_sec_container_type:
            responseJson.data.attributes.offer_sec_container_type,
          offer_sec__tons_teu: responseJson.data.attributes.offer_sec__tons_teu,
          offer_sec_quantity: responseJson.data.attributes.offer_sec_quantity,
          offer_sec_terms_sellers:
            responseJson.data.attributes.offer_sec_terms_sellers?.split("/")[0],
          offer_sec_terms_sellers2:
            responseJson.data.attributes.offer_sec_terms_sellers?.split("/")[1],
          offer_sec_rate_by_seller:
            responseJson.data.attributes.offer_sec_rate_by_seller,
            offeredQuantity: responseJson.data.attributes.offer_quantity || "",
            secondOfferedQuantity: responseJson.data.attributes.offer_sec_quantity || "",
            offeredRate: responseJson.data.attributes.offer_seller_rate || "",
            secondOfferedrate: responseJson.data.attributes.offer_sec_rate_by_seller || "",
            offeredTermsOne: responseJson.data.attributes.offer_terms_sellers?.split("/")[0]?.trim() || "",
            offeredTermsTwo: responseJson.data.attributes.offer_terms_sellers?.split("/")[1]?.trim() || "",
            secondOfferedTermsOne: responseJson.data.attributes.offer_sec_terms_sellers?.split("/")[0]?.trim() || "",
            secondOfferedTermsTwo: responseJson.data.attributes.offer_sec_terms_sellers?.split("/")[1]?.trim() || "",

        });
      }
    }
  };

  onReceiveUpdateOfferData = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.onUpdateOfferDataApiCallID) {
      if (responseJson?.data) {
        this.setState({
          openUpdateModal: true,
          isViewing: false,
          isUpdating: false,
          offerModal: false,
        });
      }
    }
  };

  onReceiveSwitchRoleData = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.onSwitchRoleApiCallID) {
      if (!responseJson.message) {
        this.setState({ switchAlert: true, snackbarOpen: true });
      } else {
        setStorageData("UserRole", "buyer");
        this.props.navigation.navigate("BuyersRequest");
      }
    }
  };

  onReceiveFilteredOfferData = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getFilteredOfferDataApiCallID) {
      const tempFilteredData = Array.isArray(responseJson) ?  responseJson?.map((item: any) => {
        return item?.offer?.data;
      }) : [];
      this.setState({
        open: false,
        sellerOfferData: tempFilteredData,
        DynamicCount: {
          ...this.state.DynamicCount,
          offers: tempFilteredData.length,
        },
      });
    }
  };


  getDate(){
    return this.state.StartDate !== "" && this.state.EndDate !== "" ? `${this.state.RenderStart} - ${this.state.RenderEndDate}` : "" 
  }

  onReceiveVesselVoyageData = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.GetVesselVoyagePostApi) {
      this.setState({ newVesalVoyageData: responseJson.results });
    }
  };

  OnchangeOfAutoComplate = async (values: string) => {
    this.setState({PortNetAPiCall: true})

    const token = await getStorageData("authToken")

    const header = {
      "Content-Type": "application/json",
      token: token };

    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage) );

    const httpBody = {
      userId: this.state.loginData.email, orgCode: this.state.sellerViewOfferData.attributes.organization_name,
      vslM: values.toUpperCase(), partialMatch: true,
    };

    this.GetVesselVoyagePostApi = requestMessage.messageId
    requestMessage.addData( getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody) );

    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_apiintegration52/portnets/get_vessel_voyage" );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST" );

    runEngine.sendMessage(requestMessage.id, requestMessage)

  };

  getSellerOfferData = async () => {

    let LocationToken = await getStorageData("authToken")


    const header = { token: LocationToken,
      "Content-Type": "application/json", };

    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getSellerOfferDataApiCallID = requestMessage.messageId

    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_listdemandpickflow/make_an_offers" );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET" );

    runEngine.sendMessage(requestMessage.id, requestMessage)

  }

  GetEtaETbAPiFuncation = async () => {

    const token = await getStorageData("authToken");

    const header = {
      token: token,
        "Content-Type": "application/json" 
      };

    this.setState(
      {
        loading: true
      }
    )

    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage)
    )
    

    const httpBody = {
        userId: this.state.loginData.email, orgCode: this.state.PrincipalOrganizationSelectedName,
        vslM: this.state.SelectedVesselVoyage.vslM, inVoyN: this.state.SelectedVesselVoyage.inVoyN,
        outVoyN: this.state.SelectedVesselVoyage.outVoyN, portOfLoad: this.state.pol,
        portOfDisc: this.state.pod,
    }

    this.GetEtaETbAPiId = requestMessage.messageId

    requestMessage.addData( getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody) );

    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
        "/bx_block_apiintegration52/portnets/get_etb_info" );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header) );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST" )

    runEngine.sendMessage(requestMessage.id, requestMessage)

}

  onSwitchRole = async () => {

    let authToken = await getStorageData("authToken")

    const header = {
      "Content-Type": "application/json",
      token: authToken
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage) );

    this.onSwitchRoleApiCallID = requestMessage.messageId

    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/switch_role` );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT" );

    runEngine.sendMessage(requestMessage.id, requestMessage)

  };

  getViewOfferData = async (selectedId: any) => {

    let offerToken = await getStorageData("authToken")

    const header = {
      "Content-Type": "application/json",
      token: offerToken, 
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage) );

    this.getViewOfferDataApiCallID = requestMessage.messageId


    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_listdemandpickflow/make_an_offers/${selectedId}` );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET" );

    runEngine.sendMessage(requestMessage.id, requestMessage)

  };

  onWithdrawOfferData = async (selectedId: any) => {

    let withdrawToken = await getStorageData("authToken");

    const header = {
      token: withdrawToken,
      "Content-Type": "application/json"
    }

    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage) );

    this.onWithdrawOfferDataApiCallID = requestMessage.messageId

    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_listdemandpickflow/make_an_offers/${selectedId}` );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) );

    requestMessage.addData(  getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE" );

    runEngine.sendMessage(requestMessage.id, requestMessage)

  };

  getVesselVoyageData = async () => {
    let vesselVoyageToken = await getStorageData("authToken");

    const header = { "Content-Type": "application/json",
      token: vesselVoyageToken
    };

    const requestMessage = new Message(  getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getVesselVoyageDataApiCallID = requestMessage.messageId

    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/vessel_voyages` );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET" );

    runEngine.sendMessage(requestMessage.id, requestMessage)

  };

  getVesselVoyages = async () => {
    this.setState({PortNetAPiCall: true})

    let vesselToken = await getStorageData("authToken");

    const header = { "Content-Type": "application/json",
      token: vesselToken,
    };

    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getVesselVoyagesApiCallID = requestMessage.messageId

    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/vessel_voyages?principal_organization_name=${this.state.sellerViewOfferData.attributes.principal_organization_name}`);

    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET" );

    runEngine.sendMessage(requestMessage.id, requestMessage)

  };

  onUpdateOfferData = async () => {

    let updateOfferToken = await getStorageData("authToken");

    const header = {
      "Content-Type": "application/json",
      token: updateOfferToken,
    };

    const httpBody = {
      pol: this.state.pol, pol_terminal: this.state.pol_terminal,
      pod: this.state.pod, pod_terminal: this.state.pod_terminal,
      etb_pol: this.state.etb_pol, eta_pod: this.state.eta_pod,
      listing_validity: this.state.listing_validity, offer_seller_rate: this.state.offer_seller_rate,
      offer_quantity: this.state.offer_quantity, offer_terms_sellers: `${this.state.offer_terms_sellers}/ ${this.state.offer_terms_sellers2}`,
      offer_sec_container_type: this.state.offer_sec_container_type, offer_sec__tons_teu: this.state.offer_sec__tons_teu,
      offer_sec_quantity: this.state.offer_sec_quantity, offer_sec_terms_sellers: `${this.state.offer_sec_terms_sellers}/${this.state.offer_sec_terms_sellers2}`,
      offer_sec_rate_by_seller: this.state.offer_sec_rate_by_seller, vessel: this.state.SelectedVesselVoyage.vslM,
      in_voyage: this.state.SelectedVesselVoyage.inVoyN, out_voyage: this.state.SelectedVesselVoyage.outVoyN,
      organization_id: this.state.organization_id, principal_organization_name: this.state.principal_organization_name,
    };

    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage) );

    this.onUpdateOfferDataApiCallID = requestMessage.messageId

    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_listdemandpickflow/make_an_offers/${this.state.selectedOrg}` );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody) );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT" );

    runEngine.sendMessage(requestMessage.id, requestMessage)

  };

  getFilteredOfferData = async (temp: any) => {
    let filteredToken = await getStorageData("authToken");

    const header = { "Content-Type": "application/json",
      token: filteredToken,
    };

    const reqUidData = temp.listingID ?.filter((item: { label: string; isChecked: boolean }) => {
        if (item.isChecked === true) 
        {
          return item.label;
        }
      })
      .map((val: { 
        label: string; 
        isChecked: boolean 
      }) => val.label);

    const polsData = temp.pol
      ?.filter((
        item: { 
        label: string; 
        isChecked: boolean }) => {
        if (item.isChecked === true) 
        {
          return item.label
        }
      })
      .map((
        val: 
        { 
          label: string; 
          isChecked: boolean; 
        }
        ) => val.label
        );

    const podsData = temp.pod ?.filter((
      item: { 
        label: string; 
        isChecked: boolean 
      }
      ) => {
        if (item.isChecked === true) 
        {
          return item.label
        }
      }) .map((
        val: { 
          label: string; 
          isChecked: boolean; }) => 
          val.label
          );

    const typesData = temp.type ?.filter((item: { label: string; isChecked: boolean }) => {
        if (item.isChecked === true) {
          return item.label;
        }
      })
      .map((val: { label: string; isChecked: boolean }) => val.label);

    const principalOrg = temp.principalOrganisation
      ?.filter((item: { label: string; isChecked: boolean }) => {
        if (item.isChecked === true) {
          return item.label;
        }
      })
      .map((val: { label: string; isChecked: boolean }) => val.label);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage) );

    const principalOrganizationParam = principalOrg.length > 0
        ? 
        `principal_organization_names=${JSON.stringify(principalOrg)}`
        : 
        "";

    this.getFilteredOfferDataApiCallID = requestMessage.messageId

    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_listdemandpickflow/make_an_offers/apply_offer_filters?offer_ids=${JSON.stringify(reqUidData)}
      &pols=${JSON.stringify(polsData)}&pods=${JSON.stringify(podsData)}
      &offer_container_types=${JSON.stringify(typesData)}&etb_pol_beginning=${this.state.filter.startDate}
      &etb_pol_end=${this.state.filter.endDate}&${principalOrganizationParam}`
    )

    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET" );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  handleTabChange = (
    event: any, 
    newValue: any
    ) => {
    this.setState({ 
      currentTab: newValue 
    });
  };

  SwitchChnages = () => {
    this.onSwitchRole();
  };

  handleClose = () => {
    this.setState({
      offerModal: false,
      addListing: false,
      isUpdating: false,
      isViewing: false,
    });
  };

  onSubmitHandler = (e: any) => {
    // Your logic here
  };

  handleClick = (
    event: any, 
    selectedItem: any
    ) => {
    this.setState({
      anchorElDropdown: event.currentTarget,
      withdrawableItem: selectedItem,
      selectedOrg: selectedItem.id,
    });
    this.getViewOfferData(selectedItem.id);
  };

  handleCloseDropdown = () => {
    this.setState({ 
      anchorElDropdown: null 
    });
  };

  handleViewOfferCick = () => {
    this.setState({
      offerModal: true,
      addListing: true,
      isViewing: true,
      anchorElDropdown: null,
      open: false,
    });
  };

  onWithdrawOfferClick = async () => {
    this.setState({
      openWithdrawModal: true,
      offerModal: false,
      addListing: false,
      isViewing: false,
      anchorElDropdown: null,
      open: false,
    });
  };

  onUpdateOfferClick = () => {
    this.setState({
      PortNetAPiCall: true,
      isUpdating: true,
      offerModal: true,
      addListing: true,
      FormIsEdit: true,
      anchorElDropdown: null,
      open: false,
      IsVesselSelected: true,
      VesselDropDownString: this.state.sellerViewOfferData?.attributes?.vessel_code,
      InVoyogeAndOutVoyoge: `In ${this.state.sellerViewOfferData?.attributes?.in_voyage} Out ${this.state.sellerViewOfferData?.attributes?.out_voyage}`
    }
    , ()=> this.OnchangeOfAutoComplate(this.state.sellerViewOfferData?.attributes?.vessel_code)
    );
  };

  onFormUpdate = () => {
    this.onUpdateOfferData();
  };

  openWithdrawModalCloseAndOpen = () => {
    this.setState({ openWithdrawModal: !this.state.openWithdrawModal });
  };

  openUpdateModalCloseAndOpen = () => {
    this.setState({ openUpdateModal: !this.state.openUpdateModal });
    window.location.reload();
  };

  withdrawFunction = async (selectedId: string) => {
    this.onWithdrawOfferData(selectedId);
    this.setState({ openWithdrawModal: false, anchorElDropdown: null });
  };

  AddListingFormTextFieldChange = (
    e: any,
    setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean | undefined
    ) => void,
    keyname: string
  ) => {
    setFieldValue(`${keyname}`, `${e.target.value}`);
    if (keyname === "VESSEL") {
      let temp = this.state.storedVesselData.filter(
        (val: any) => val.vslM === e.target.value
      );
      this.setState({ voyageData: temp });
    }
  };

  handlePolExpanded = () => {
    this.setState({ polExpanded: true });
  };

  handlePolChange = (event: any) => {
    const { name, checked } = event.target;
    const updatedOptions = this.state.polOptions.map((item: any) => {
      return item.label === name ? { ...item, isChecked: checked } : item;
    });
    this.setState({ polOptions: [...updatedOptions] });
  };

  handlePolDelete = (option: any) => {
    const updatedOptions = this.state.polOptions.map((item: any) => {
      return item.label === option.label ? { ...item, isChecked: false } : item;
    });
    this.setState({ polOptions: [...updatedOptions] });
  };

  handlePodExpanded = () => {
    this.setState({ podExpanded: true });
  };

  handlePodChange = (event: any) => {
    const { name, checked } = event.target;
    const updatedOptions = this.state.podOptions.map((item: any) => {
      return item.label === name ? { ...item, isChecked: checked } : item;
    });
    this.setState({ podOptions: [...updatedOptions] });
  };

  handlePodDelete = (option: any) => {
    const updatedOptions = this.state.podOptions.map((item: any) => {
      return item.label === option.label ? { ...item, isChecked: false } : item;
    });
    this.setState({ podOptions: [...updatedOptions] });
  };

  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false, switchAlert: false });
  };

  // #################################################################################################

  handleOfferExpanded = (val: string) => {
    if (val === "listingID") {
      this.setState({ offerExpanded: this.state.filter.listingID?.length > 0 });
    }
    if (val === "pol") {
      this.setState({ offerExpandedPol: this.state.filter.pol?.length > 0 });
    }
    if (val === "pod") {
      this.setState({ offerExpandedPod: this.state.filter.pod?.length > 0 });
    }
    if (val === "type") {
      this.setState({ offerExpandedtype: this.state.filter.type?.length > 0 });
    }
  };

  handleOfferChange = async (
    event: any,
    values: Array<{ label: string; isChecked: boolean }>,
    arrName: string
  ) => {
    const { name, checked } = event.target;
    const updatedOptions = values?.map(
      (item: { label: string; isChecked: boolean }) => {
        return item.label === name ? { ...item, isChecked: checked } : item;
      }
    );
    if (arrName === "listingID") {
      let temp = { ...this.state.filter, listingID: updatedOptions };
      this.setState({ filter: temp });
      this.getFilteredOfferData(temp);
    }
    if (arrName === "pol") {
      let temp = { ...this.state.filter, pol: updatedOptions };
      this.setState({ filter: temp });
      this.getFilteredOfferData(temp);
    }
    if (arrName === "pod") {
      let temp = { ...this.state.filter, pod: updatedOptions };
      this.setState({ filter: temp });
      this.getFilteredOfferData(temp);
    }
    if (arrName === "type") {
      let temp = { ...this.state.filter, type: updatedOptions };
      this.setState({ filter: temp });
      this.getFilteredOfferData(temp);
    }
    if (arrName === "principalOrganisation") {
      let temp = {
        ...this.state.filter,
        principalOrganisation: updatedOptions,
      };
      this.setState({ filter: temp });
      this.getFilteredOfferData(temp);
    }
  };

  onDateChange = (args: any): void => {
    let startDate = moment(args.value[0]).format("DD-MMM-YYYY");
    let endDate = moment(args.value[1]).format("DD-MMM-YYYY");
    const tempData = {
      ...this.state.filter,
      startDate: startDate,
      endDate: endDate,
    };
    this.setState({ filter: tempData });
    this.getFilteredOfferData(tempData);
  };

  handleOfferDelete = (
    option: any,
    values: Array<{ label: string; isChecked: boolean }>,
    arrName: string
  ) => {
    const updatedOptions = values?.map((item: any) => {
      return item.label === option.label ? { ...item, isChecked: false } : item;
    });
    if (arrName === "pod") {
      let temp = { ...this.state.filter, pod: updatedOptions };
      this.setState({ filter: temp });
      this.getFilteredOfferData(temp);
    }
    if (arrName === "type") {
      let temp = { ...this.state.filter, type: updatedOptions };
      this.setState({ filter: temp });
      this.getFilteredOfferData(temp);
    }
    if (arrName === "listingID") {
      let temp = { ...this.state.filter, listingID: updatedOptions };
      this.setState({ filter: temp });
      this.getFilteredOfferData(temp);
    }
    if (arrName === "pol") {
      let temp = { ...this.state.filter, pol: updatedOptions };
      this.setState({ filter: temp });
      this.getFilteredOfferData(temp);
    }
  };

  handleSlotFilter = (principalOrg: any, isChecked: boolean) => {
    const hasActiveFilter = this.state.activeSlotFilter.find(
      (slot: any) =>
        slot.attributes.code === principalOrg.attributes.code &&
        slot.attributes.name === principalOrg.attributes.name
    );
    if (isChecked && !hasActiveFilter) {
      this.setState((prevState) => ({
        activeSlotFilter: [...prevState.activeSlotFilter, principalOrg],
      }));
      return;
    }
    this.setState((prevState) => ({
      activeSlotFilter: prevState.activeSlotFilter.filter(
        (item: any) =>
          item.attributes.code !== principalOrg.attributes.code &&
          item.attributes.name !== principalOrg.attributes.name
      ),
    }));
  };

TogolFilter = () => {
  this.setState({open: !this.state.open,
  });
}

  SetDateFilterData = (range: any) => {
    this.setState({
      RenderStart: moment(range.startDate).format("DD-MMM-YYYY"),
      RenderEndDate: moment(range.endDate).format("DD-MMM-YYYY"),
      StartDate: moment(range.startDate).format(),
      EndDate: moment(range.endDate).format(),
      DateRangeStartDate:range.startDate,DateRangeEndDate:range.endDate,
      filter: {
        ...this.state.filter,
        startDate: moment(range.startDate).subtract(1,"d").format("YYYY-MM-DD"),
        endDate: moment(range.endDate).format("YYYY-MM-DD"),
      },
    });
  };

  FilterDateSelect = () => {
    const { StartDate, EndDate } = this.state;
    if (StartDate === "") {
      toast.warning("Select start date");
    } else if (EndDate === "") {
      toast.warning("Select end date");
    } else {
      this.getFilteredOfferData(this.state.filter);
    }
  };

  ClearFilterData = () => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          startDate: "",
          endDate: "",
        },
        open: false,
        RenderStart: "",
        RenderEndDate: "",
        StartDate: "",
        EndDate: "",
        DateRangeStartDate:new Date(),
      DateRangeEndDate:new Date(),
      },
      () => {
        this.getFilteredOfferData(this.state.filter);
      }
    );
  };

  checkVoyageIncludesInVoyage = (item: any, Data: any) => {
    if (
      item.inVoyN.includes(
        Data[0].inVoyN
      )
    ) {
      return item;
    }
  };

  onSetVesselDropdownState = (Data: any, ReposneData: any) => {
    this.setState(
      {
        VesselDropDownData: Data,
        PortNetApiAllData: ReposneData,
        loading: !!this.state.FormIsEdit,
        PortNetAPiCall: false,
      },
      () => {
        if (this.state.FormIsEdit) {
          const VoyageData = Array.isArray(this.state.PortNetApiAllData) ?  this.state.PortNetApiAllData?.filter(
            (item: { name: string; inVoyN: string; outVoyN: string }) => 
              item?.name?.toLowerCase() == Data[0]?.name?.toLowerCase()
          ): [];
          const DataSet = Array.isArray(this.state.PortNetApiAllData) ? this.state.PortNetApiAllData.filter(
            (item: { name: string; inVoyN: string; outVoyN: string }) => {
              {
                this.checkVoyageIncludesInVoyage(item, Data);
              }
            }
          ): [];
          this.setState({
            VoyageDropDownData: VoyageData,
            SelectedVesselVoyage: DataSet.length !== 0 ? DataSet[0] : {},
            loading: false,
          });
        }
      }
    );
  };

  GetVesselVoyagePostApiDataresponseSet = (
    apiRequestCallId: string,
    responseJson: {
      errors?: Array<{ [key: string]: string }>;
      results: Array<{
        inVoyN: string;
        outVoyN: string;
        vslM: string;
      }>;
    }
  ) => {
    if (this.GetVesselVoyagePostApi === apiRequestCallId) {
      if (
        responseJson.errors !== null ||
        (responseJson.results === null && responseJson.errors === null)
      ) {
        this.getVesselVoyages();
        this.setState({ loading: false });
      } else {
        const ReposneData = Array.isArray(responseJson.results)
          ? responseJson.results.map(
              (item: { inVoyN: string; outVoyN: string; vslM: string }) => {
                return {
                  name: item.vslM,
                  inVoyN: item.inVoyN,
                  outVoyN: item.outVoyN,
                  vslM: item.vslM,
                };
              }
            )
          : [];
        const Data = ReposneData.filter(
          (value, index, self) =>
            index === self.findIndex((obj) => obj.name === value.name)
        );
        {
          this.onSetVesselDropdownState(Data, ReposneData);
        }
      }
    }
  };

  OnOpenDropDown = () => {
    if (this.state.sellerViewOfferData?.attributes?.organization_name !== "") {
      this.setState({ VesselDropDownOpen: !this.state.VesselDropDownOpen });
    } else {
      toast.warning("Please select principal oragnisation");
    }
  };

  AutocompleteOnChange = (
    setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean
    ) => void,
    value: unknown,
    KeyName: string
  ) => {
    const data = value as {
      name: string;
      inVoyN: string;
      outVoyN: string;
      vslM: string;
    };
    if (KeyName === "VESSEL") {
      const VoyageData = Array.isArray(this.state.PortNetApiAllData) ?  this.state.PortNetApiAllData.filter(
        (item: { name: string }) => {
          if (item.name.toLowerCase().includes(data.name.toLowerCase())) {
            return item;
          }
        }
      ): [];

      this.setState({
        VoyageDropDownData: VoyageData,
        IsVesselSelected: true,
        InVoyogeAndOutVoyoge: "",
        SelectedVesselVoyage: {
          inVoyN: "",
          outVoyN: "",
          vslM: "",
          name: "",
        },
      });
      setFieldValue(KeyName, `${data?.name}`);
      setFieldValue("VOYAGE", "");
    }
    if (KeyName === "VOYAGE") {
      this.setState({
        InVoyogeAndOutVoyoge: `In ${data.inVoyN} Out ${data.outVoyN}`,
        SelectedVesselVoyage: data,
      }, () => {this.GetEtaETbAPiFuncation()});
      setFieldValue(KeyName, data?.inVoyN);
    }
  };
  AutocompleteTextfieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let values = event.target.value;
    if (
      3 <= event.target.value.length &&
      this.state.sellerViewOfferData.attributes.organization_name !== ""
    ) {
      this.OnchangeOfAutoComplate(values);
    }
  };

  OnOpenDropDownVoyage = () => {
    if (this.state.IsVesselSelected) {
      this.setState({
        VoyageDropDownOpen: !this.state.VoyageDropDownOpen,
      });
    } else {
      toast.warning("Please select Vessel first");
    }
  };

  // Customizable Area End
}