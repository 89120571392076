// Customizable Area Start
import React, { ReactNode } from "react";
import { styled } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
    children: ReactNode;
    onClose?: () => void;
    ActiveCloseIcon?: boolean
}

class GenericCard extends React.Component<Props> {
    render() {
        const { onClose, ActiveCloseIcon } = this.props;
        return (
            <CardLayout>
                <CardWraper className="CardWraper">
                    {ActiveCloseIcon &&
                        <IconButton size='small' className='IconButtonBgColor' onClick={onClose}>
                            <CloseIcon className='BtnColor' />
                        </IconButton>}
                    {this.props.children}
                </CardWraper>
            </CardLayout>
        );
    }
};

export default GenericCard;

const CardLayout = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    position: "relative",
    "& .IconButtonBgColor": {
        position: "absolute",
        top: "10px",
        right: "10px",
        backgroundColor: "black"
    },
    "& .BtnColor": {
        color: "white",
        fontSize: "1rem"
    }
});

const CardWraper = styled(Box)({
    backgroundColor: "#FFF",
    padding: 40,
    display: "flex",
    justifyContent: "center",
    width: "100%",
    borderRadius: "24px",
    position: "relative"
});

// Customizable Area End