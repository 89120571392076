// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import * as Yup from "yup";
import { Message } from "../../../framework/src/Message";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import moment from "moment";
import { DateRange } from "materialui-daterange-picker";

// Customizable Area End

export const configJSON = require("./config");

interface Status {
  pending: number;
  rejected: number;
}
export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

interface S {
  // Customizable Area Start
  currentTab: number; anchorEl: any;
  page: number; rowsPerPage: number;
  popup: boolean; profilePop: boolean;
  columns: any; rows: any;
  switch: boolean; show: boolean;
  showGuideVedios: boolean; RejectModal: boolean;
  SuccessModal: boolean; nominationModal: boolean;
  trackingModal: boolean; curr_booking: any
  currBookingIndex: number| undefined; nominationCheckbox: boolean;
  cancelBooking: boolean; sucessOpenmodal: boolean;
  submittingState: boolean; ConsigneeCountry: string;
  DraftBLstate: boolean; revisionrequest: boolean;
  revisionSucess: boolean; paymentconfiguration: boolean;
  POLTERMINAL: string; submittingSucess: boolean;
  verifySucess: boolean; paymentSucess: boolean;
  ConsigneeCompanyName: string; ConsigneeState: string;
  activeButtonvalue: string[]; ConsigneeAddressLine: string;
  activeState: number; ContainerSize: string;
  POD: string; PODTERMINAL: string;
  ShipperContact: string; POL: string;
  ShipperCompanyName: string; ShipperPersonInCharge: string;
  Address: string; AddressLine: string;
  Country: string;
  State: string;
  Postal: string|number;
  ConsigneePersonInCharge: string;
  ConsigneeAddress: string;
  City: string;
  ConsigneeContact: string;
  ConsigneeCity: string;
  ConsigneePostal: string|number;
  ContainerNumber: string;
  SealNumber: string;
  HsCode: string;
  PackingType: string;
  PackingCount: string|number;
  AverageTons: string;
  GWT: string|number;
  CargoDescription: string;
  ContainerType: string;
  submittingformData: Array<{ key: string; value: string }>;
  typeData: { [key: string]: string }[];
  containers: { id: 1 }[];
  accordionOpen: boolean[];
  ShipperEmail: string;
  TaxId:string;
  shipperAccordion: boolean;
  consigneeAccordion: boolean;
  openPop: boolean;
  ConsigneeEmail: string;
  ConsigneeTaxID: string;
  slotsData: {
    org: string;
    requestId: string;
    dateRange: string;
    pol1: string;
    pol2: string;
    carrier: string;
    pod1: string;
    pod2: string;
    type: string;
    codes: string;
    rate: string[];
    total: string;
    status: Status;
  }[];
  trackingData: {
    id: number;
  }[];
  bookingData : {
    "id": string,
  "type": string,
  "attributes": {
    "booking_uid": string,
    "make_an_offer_id": number,
    "pol_terminal": string,
    "pod_terminal": string,
    "etb": string,
    "eta": string,
    "container_type": string,
    "is_nominated": boolean,
    "vessel_voyage": string,
    "container_code": string[],
    "booking_reference_number": null | number,
    "co_authorizer": boolean,
    "request_revision": null|number,
    "draft_bl_status": boolean,
    "submit_si_status": boolean,
    "payment_status": boolean,
    "invoice_status": boolean,
    "final_bl_status": boolean,
    "seller_booking_status": null | unknown,
    "buyer_booking_status": null | unknown,
    "account_id": 226,
    "payment_confirmation_file": null | unknown,
    "seller_account_id": 220,
    "draft_bl": null | unknown,
    "final_bl": null | unknown,
    "upload_invoice": null | unknown,
    "submitted_si_at": null | unknown,
    "payment_confirmed_at": null | unknown,
    "nominated_at": null | unknown,
    "verified_draft_bl_at": null | unknown,
    "co_authorizer_at": null | unknown,
    "booking_reference_number_at": null | unknown,
    "upload_draft_bl_at": null | unknown,
    "upload_invoice_at": null | unknown,
    "upload_final_bl_at": null | unknown,
    "seller_sec_offer_rate": number,
    "buyer_sec_offer_rate": number,
    "seller_offer_rate": string,
    "buyer_offer_rate": number,
    "in_voyage": string,
    "out_voyage": string,
    "offer_quantity": number,
    "offer_sec_quantity": number
  },
  currentStep: number

}[],
  selectedBooing : any,
  AccordianOpenIndex:number,
  reasonText: string | unknown,
  UploadPaymentData: Array<{
    UploadFile:any,
    name:string
  }>,
  DragStart:boolean,
  anchorElDropdown: any,
  buyerPrinicpalOrgList: any,
  buyerRequestSnackbarOpen: boolean,
  addBuyerListing: boolean,
  buyerListingAdded: boolean,
  buyerExpanded : boolean,
    buyerExpandedPol : boolean,
    buyerExpandedPod : boolean,
    buyerExpandedType : boolean,
    buyerFilter: {
      listingID: Array<{ label: string; isChecked: boolean }>;
      pol: Array<{ label: string; isChecked: boolean }>;
      pod: Array<{ label: string; isChecked: boolean }>;
      type: Array<{ label: string; isChecked: boolean }>;
      startDate: string;
      endDate: string
    },
    sizeData: any,
    codesData: any,
    activeSlotFilter: any,
    isSlotFilterOpen: boolean,
    open: boolean,
    StartDate: string,
  EndDate: string,
  portnetUrlData:string,
  RenderStart: string,
  RenderEndDate: string,
  DynamicCount: {
    listing?: string | number,
    requst?: string | number,
    offers?: string | number,
    booking?: string | number
  },
  DateRangeStartDate: Date | undefined,
  DateRangeEndDate: Date | undefined,
  loading: boolean,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class BuyerMatchesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    (async () => {
      let buyerMatchesLoginData = await getStorageData("UserRole");
      if(!buyerMatchesLoginData){
        window.location.href = "/"
      } else if(buyerMatchesLoginData == "seller"){
        window.location.href = "/SellerListing"
      }
    })()

    this.state = {
      DateRangeStartDate: new Date(),
      DateRangeEndDate: new Date(),
      StartDate: "",
      EndDate: "",
      RenderStart: "",
      RenderEndDate: "",
      open: false,
      currentTab: 2,
      activeSlotFilter: [],
    isSlotFilterOpen: false,
      trackingData: [
        {
          id: 1,
        },
        {
          id: 2,
        },
        {
          id: 3,
        },
        {
          id: 4,
        },
      ],
      anchorEl: null,
      SuccessModal: false,
      page: 0,
      popup: false,
      switch: true,
      rowsPerPage: 10,
      showGuideVedios: false,
      profilePop: false,
      RejectModal: false,
      slotsData: [],
      nominationModal: false,
      nominationCheckbox: false,
      cancelBooking: false,
      trackingModal: false,
      curr_booking: null,
      sucessOpenmodal: false,
      submittingState: false,
      submittingSucess: false,
      verifySucess: false,
      paymentSucess: false,
      revisionSucess: false,
      DraftBLstate: false,
      revisionrequest: false,
      paymentconfiguration: false,
      activeButtonvalue:[ "Pending Confirmation"],
      activeState: 0,
      portnetUrlData:"",
      POLTERMINAL: "",
      POD: "",
      PODTERMINAL: "",
      POL: "",
      ShipperCompanyName: "",
      ShipperPersonInCharge: "",
      Address: "",
      AddressLine: "",
      City: "",
      State: "",
      Postal: "",
      Country: "",
      TaxId:"",
      ShipperEmail: "",
      ShipperContact: "",
      ConsigneeCompanyName: "",
      ConsigneePersonInCharge: "",
      ConsigneeAddress: "",
      ConsigneeAddressLine: "",
      ConsigneeCity: "",
      ConsigneeState: "",
      ConsigneePostal: "",
      ConsigneeCountry: "",
      ConsigneeEmail: "",
      ConsigneeTaxID: "",
      currBookingIndex:-1,
      ConsigneeContact: "",
      ContainerNumber: "",
      ContainerType: "",
      ContainerSize: "",
      AverageTons: "",
      SealNumber: "",
      HsCode: "",
      PackingType: "",
      PackingCount: "",
      GWT: "",
      CargoDescription: "",
      shipperAccordion: true,
      consigneeAccordion: true,
      submittingformData: [],
      containers: [{ id: 1 }],
      accordionOpen: [false],
      typeData: [],
      sizeData: [],
      codesData: [],

      openPop: false,
      columns: [
        {
          id: "org_",
          label: "ORG",
          minWidth: "auto",
        },
        {
          label: "MATCH ID",
          id: "match_id",
          minWidth: "auto",
        },
        {
          minWidth: "auto",
          label: " DATE RANGE",
          id: "date_time",
        },
        {
          minWidth: "auto",
          label: "POL",
          id: "pol_terminal",
        },
        {
          minWidth: "auto",
          id: "pod_terminal",
          label: "POD",
        },
        {
          label: "TYPE",
          minWidth: "auto",
          id: "type_",
        },
        {
          id: "org_code",
          label: "Org Code",
          minWidth: "auto",
        },
        {
          minWidth: "auto",
          label: "Container Codes",
          id: "container_codes",
        },
        {
          id: "quantity",
          minWidth: "auto",
          label: "Quantity",
        },
        {
          label: "Requested Price (USD)",
          id: "requested_price",
          minWidth: "auto",
        },
        {
          id: "total",
          label: "Total (USD)",
          minWidth: "auto",
        },
        {
          id: "terms",
          label: "Terms",
          minWidth: "auto",
        },
      ],
      rows: [
        {
          org: "HMM",
          matchId: "30711",
          dateRange: "2 JUN - 2 SEP",
          pol1: "SGSIN",
          pol2: "TCN",
          carrier: "Arrow",
          pod1: "INMUN",
          pod2: "BCN",
          type: "EMPTY",
          codes: "MT,MH,RM",
          rate: ["20FT 100 $180/20FT CY/CY", "20FT 100 $180/20FT CY/CY"],
          total: "$36,000",
          status: {
            pending: 2,
            rejected: 1,
          },
        },
      ],
      show: false,
      bookingData : [],
      selectedBooing : {},
      AccordianOpenIndex:0,
      reasonText: "",
      UploadPaymentData:[],
      DragStart:false,
      anchorElDropdown: null,
      buyerPrinicpalOrgList: [],
      buyerRequestSnackbarOpen: false,
      addBuyerListing: false,
      buyerListingAdded: false,
      buyerExpanded : false,
      buyerExpandedPol : false,
      buyerExpandedPod : false,
      buyerExpandedType : false,
      buyerFilter: {
        listingID: [],
        pol: [],
        pod: [],
        type: [],
        startDate:"",
        endDate:""
      },
      DynamicCount: {
        listing:"0",
        requst:"0",
        offers:"0",
        booking:"0"
      },
      loading: true,
    };
  }

  async componentDidMount() {
    let LocationToken = await getStorageData("authToken");
    let portnetUrl =await getStorageData("portnetUrl");
    if(portnetUrl!=null){this.setState({portnetUrlData:portnetUrl})}
    this.getBuyerBookingData()
    this.getBuyerPrincipalOrganisation(LocationToken)
  }

  getBuyerBookingDataApiCallID : string = ""
  nominateContainerApiCallID : string = ""
  verifyDraftApiCallID : string = ""
  requestRevisionApiCallID : string = ""
  PaymentConfirmationApiCallID : string = ""
  SellerInstructionApiCallID : string = ""
  applyBuyerFilterApiCallID: string = ""
  getBuyerPrincipalOrganisationDataApiCallID : string = "";
  buyerCancelBookingpiCallID: string = ""
  onRoleSwitchAPICallID : string = "";

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
   
 
    if (apiRequestCallId === this.getBuyerBookingDataApiCallID) {
      if (responseJson.data) {
        let nBookingData = responseJson.data.map((bookingItem: any, index: number | undefined)=> this.getCurrentStep(bookingItem, index))
        this.setState({bookingData: nBookingData, loading: false})
        this.onFilteredGetDataForListing(responseJson?.data)
      }}
      if (apiRequestCallId === this.getBuyerPrincipalOrganisationDataApiCallID) {
              this.setState({ buyerPrinicpalOrgList: responseJson?.data });
      }
    
      this.onReceiveNomination(apiRequestCallId, responseJson)
      
      this.onReceiveSellerInstruction(apiRequestCallId, responseJson)

      this.onVerifyDraft(apiRequestCallId, responseJson)
      
      this.onRequestRevision(apiRequestCallId, responseJson)
      
      this.onPaymentConfirmation(apiRequestCallId, responseJson)
      this.onReceiveFilteredListingData(apiRequestCallId, responseJson)
      this.onCancelBuyerBooking(apiRequestCallId, responseJson)
      this.onReceiveRoleData(apiRequestCallId, responseJson)
      
  }

  onCancelBuyerBooking = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.buyerCancelBookingpiCallID) { this.setState({ cancelBooking: false, trackingModal : false });
      toast.success("Booking Cancelled Succesfully");
      this.getBuyerBookingData(); }
      
  }
  onReceiveRoleData = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.onRoleSwitchAPICallID) {
      if(!responseJson.message) {
        this.setState({buyerRequestSnackbarOpen: true});
      } 
      else{
        setStorageData("UserRole", "seller")
        this.props.navigation.navigate("SellerListing");
      }}
    }
  getBuyerPrincipalOrganisation = (token: string) => {
    const header = {
      "Content-Type": "application/json",  token: token, };  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));  
    this.getBuyerPrincipalOrganisationDataApiCallID =
     requestMessage.messageId;  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/principal_organizations"
    );  
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header));
  
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSlotFilter = (principalOrg: any, isChecked: boolean) => {
    const hasActiveFilter = this.state.activeSlotFilter.find((slot: any) => slot.attributes.code === principalOrg.attributes.code && 
    slot.attributes.name === principalOrg.attributes.name)
    if (isChecked && 
      !hasActiveFilter) {
      this.setState(prevState => ({ activeSlotFilter: [...prevState.activeSlotFilter, principalOrg] }))
      setTimeout(()=> { this.applyBuyerFilter() },1000) 
      return
    }
    this.setState(prevState => ({ activeSlotFilter: prevState.activeSlotFilter.filter((item: any) => item.attributes.code !== 
      principalOrg.attributes.code 
      && 
      item.attributes.name !== principalOrg.attributes.name) }))
    setTimeout(()=> {this.applyBuyerFilter() },1000)
  }

  onFilteredGetDataForListing = (responseJson : any) => {
    let temp = { listingID: responseJson?.map((val: any) => val.attributes.booking_uid
      ),
      pol: responseJson?.map((val: any) => val.attributes.pol),
      pod: responseJson?.map((val: any) => val.attributes.pod),
      type: responseJson?.map(
        (val: any) =>  val?.attributes.container_type
      ),
      startDate: responseJson?.map(
        (val: any) =>  val.attributes.container_loading_range_start
      ),
      endDate: responseJson?.map(
        (val: any) =>  val.attributes.container_loading_range_end
      ),
    };
    let d1 = [...new Set(temp.listingID)]
    let d2 = [...new Set(temp.pol)]
    let d3 = [...new Set(temp.pod)]
    let d4 = [...new Set(temp.type)]
    let arrayData = {
      listingID: d1?.map((str: any) => ({ label: str, isChecked: false, })),
      pol: d2?.map((str: any) => ({ label: str, isChecked: false })),
      pod: d3?.map((str: any) => ({ label: str, isChecked: false })),
      type: d4?.map((str: any) => ({
        label: str,
        isChecked: false,
      })),
      startDate: "",
      endDate: "",
    };
   
    this.setState({
      buyerFilter: arrayData,
    });
  }

  SetDateFilterData = (range: DateRange) => {
    this.setState({
      RenderStart: 
      moment(range.startDate).format("DD-MMM-YYYY"), 
      RenderEndDate: moment(range.endDate).format("DD-MMM-YYYY"),
      StartDate: 
      moment(range.startDate).format(), DateRangeStartDate:range.startDate,DateRangeEndDate:range.endDate,
      EndDate: moment(range.endDate).format(),
      buyerFilter: { ...this.state.buyerFilter,
         startDate: moment(range.startDate).format("YYYY-MM-DD"), 
         endDate: moment(range.endDate).format("YYYY-MM-DD") },
    })
  }

  FilterDateSelect = () => {
    const { StartDate,
       EndDate } = this.state
    if (StartDate === "") 
    {
      toast.warning("Select start date")
    } else if (EndDate === "")
     {
      toast.warning("Select end date")
    } else {
      this.applyBuyerFilter();
      this.TogolFilter()
    }
  }

  ClearFilterData = () => {
    this.setState({
      buyerFilter: { 
        ...this.state.buyerFilter,
         startDate: "", 
         endDate: "" 
        },
      open: 
      false,
      RenderStart: "", 
      RenderEndDate: "",
      StartDate: "",EndDate: "",DateRangeStartDate:new Date(),
      DateRangeEndDate:new Date(),
    }, () => {
      this.applyBuyerFilter()
    })
  }

  TogolFilter = () => {

    this.setState({
      open: !this.state.open,
    });
  }

  onReceiveFilteredListingData = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.applyBuyerFilterApiCallID) {
      if(responseJson?.data){
        let nBookingData = responseJson.data.map((bookingItem: any, index: number | undefined)=> this.getCurrentStep(bookingItem, index))
        this.setState({bookingData: nBookingData, DynamicCount:{...this.state.DynamicCount,booking:nBookingData.length}})
      } else{
         this.setState({bookingData:[]})
      }
    }
  }

  onReceiveNomination = (apiRequestCallId1: any,responseJson1: any) => {
    if (apiRequestCallId1 === this.nominateContainerApiCallID) {

      if(responseJson1.data){
    this.getBuyerBookingData()

        this.setState({
          sucessOpenmodal: true,
          nominationModal: false,})
      }
       else if(responseJson1.message){
        toast.error(responseJson1.message);
      }
      else {
        toast.error(responseJson1.errors[0]);
      }

     }
  }

  onDownloadBL = () => {
    if(this.state.selectedBooing.attributes?.final_bl){
      window.open(this.state.selectedBooing.attributes.final_bl, '_blank')
    } else {
      toast.error("Final B/L is not uploaded yet")
    }
    this.getBuyerBookingData();
  }

  getCurrentStep = (objDetails:any, index?:number) =>{
  try {
      const verifiedValues=[
        "co_authorizer",
        "booking_reference_number",
        "draft_bl",
        "invoice_status",
        "final_bl"
      ]
      const StepperValues = [
        "is_nominated",
        "submit_si_status",
        "draft_bl_status",
        "payment_status",
        "invoice_status",
        "final_bl",
      ];
  
      let step = -1;
      let _activeButtonvalue = "Pending Confirmation";
      let isDisabled = !objDetails.attributes[verifiedValues[0]]; 
      if(objDetails.attributes.booking_confirmation_status === "Booking Confirmed"){
        step = 0;
        isDisabled = !objDetails.attributes[verifiedValues[0]];
        _activeButtonvalue = "Nominate Containers";
      }
      if (objDetails.attributes[StepperValues[0]]) {
        step = 1;
        isDisabled = !objDetails.attributes[verifiedValues[1]];
        _activeButtonvalue = "Submit S.I";
      }
      if (objDetails.attributes[StepperValues[1]]) {
        step = 2;
        _activeButtonvalue = "CONFIRM draft b/l";
          if(!objDetails.attributes.draft_bl){ isDisabled = !objDetails.attributes[verifiedValues[2]]}
          if(objDetails.attributes.draft_bl) { if(objDetails.attributes.draft_file_status){ isDisabled=false;}
           else{ isDisabled=true}
          }
      }
      if (objDetails.attributes[StepperValues[2]]) {
        step = 3;
        _activeButtonvalue = "Submit payment details";
      isDisabled = !objDetails.attributes[verifiedValues[3]];
      }
      if (objDetails.attributes[StepperValues[3]]) {
        step = 4;
        _activeButtonvalue = "Download Final b/l";
      isDisabled = !objDetails.attributes[verifiedValues[4]];
      }
      if (objDetails.attributes[StepperValues[5]]) {
        step = 6;
        _activeButtonvalue = "Download Final b/l";
      isDisabled = objDetails.attributes[verifiedValues[4]] == null;
      }

      objDetails.currentStep = step;
      objDetails.activeButtonvalue = _activeButtonvalue;
      objDetails.isDisabled = isDisabled; 
      return objDetails;
    } catch (error) {
      return 0;
    }

  }

  onVerifyDraft = (apiRequestCallId2: any,responseJson2: any) => {
    if (apiRequestCallId2 === this.verifyDraftApiCallID) {
      if(responseJson2.data){ this.getBuyerBookingData()
        this.setState({
          verifySucess: responseJson2.data?.attributes?.draft_bl_status,
          DraftBLstate: false,
        }); } else if(responseJson2.message){
        toast.error(responseJson2.message);
      }
      else {
        toast.error(responseJson2.errors[0]);
      }
     }
  }
  
  onReceiveSellerInstruction = (apiRequestCallId3: any,responseJson3: any) => {
    if (apiRequestCallId3 === this.SellerInstructionApiCallID) {
      if(responseJson3.shipper){ this.getBuyerBookingData()
        this.setState({
          submittingSucess: true,
          submittingState: false,
        });
      }else if(responseJson3.message){
        responseJson3?.errors?.map((item: string) => { toast.error(item); })
      }
      else {
        toast.error(responseJson3.errors[0]);
      }
   }
  }


  onRequestRevision = (apiRequestCallId4: any,responseJson4: any) => {
    if (apiRequestCallId4 === this.requestRevisionApiCallID) {
      if(responseJson4.data){ this.getBuyerBookingData()
        this.setState({
          revisionSucess: true,
          revisionrequest: false,
        });
      }else if(responseJson4.message){
        toast.error(responseJson4.message);
      }
      else {
        toast.error(responseJson4.errors[0]);
      }
   }
  }

  onPaymentConfirmation = (apiRequestCallId5: any,responseJson5: any) => {

    if (apiRequestCallId5 === this.PaymentConfirmationApiCallID) {
      if(responseJson5.data){ this.getBuyerBookingData()
        this.setState({
          paymentSucess: true,
          paymentconfiguration: false,
        });
      }else if(responseJson5.message){
        toast.error(responseJson5.message);
      }
      else {
        toast.error(responseJson5.errors[0]);
      }
   }
  }


  getBuyerBookingData = async () => {
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBuyerBookingDataApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_listdemandpickflow/bookings"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onSubmitShippingForm = async (values: any) => {
    const shippingToken = await getStorageData("authToken");
    const shippingHeader = {
      token: shippingToken,
      "Content-Type" : "application/json" ,
    };

    const shippingBody = {
      booking: {
        booking_id: this.state.selectedBooing.id,
        shipper_company_name: values.ShipperCompanyName,
        shipper_in_charge: values.ShipperPersonInCharge,
        shipper_address: values.Address,
        shipper_street_address: values.AddressLine,
        shipper_city: values.City,
        shipper_state: values.State,
        shipper_postal_code: values.Postal,
        shipper_country: values.Country,
        shipper_email: values.ShipperEmail,
        shipper_phone_number: values.ShipperContact,
        cosnginee_name: values.ConsigneeCompanyName,
        consignee_in_charge: values.ConsigneePersonInCharge,
        cosnginee_address: values.ConsigneeAddress,
        cosnginee_street_address: values.ConsigneeAddressLine,
        cosnginee_city: values.ConsigneeCity,
        cosnginee_state: values.ConsigneeState,
        cosnginee_postal_code: values.ConsigneePostal,
        cosnginee_country: values.ConsigneeCountry,
        cosnginee_email: values.ConsigneeEmail,
        cosnginee_phone_number: values.ConsigneeContact,
        cosnginee_tax_id: values.ConsigneeTaxID || "",
        containers: values.ContainerDetails.map((item: any) => {
            return {
              shipper_container_type: this.state.selectedBooing?.attributes?.container_type,
              container_number: item.ContainerNumber,
              hs_code: item.HsCode,
              seal_number: item.SealNumber,
              packing_type: item.PackingType,
              package_count: item.PackingCount,
              gwt: item.GWT,
              cargo_description: item.CargoDescription,
              shipper_container_size: item.ContainerSize || item.ContainerSize[0],
              shipper_container_codes: this.state.selectedBooing?.attributes?.container_code
            }
          })
      }
    }

    const requestMessage1 = new Message(getName(MessageEnum.RestAPIRequestMessage)
    );
    this.SellerInstructionApiCallID = requestMessage1.messageId;

    requestMessage1.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_listdemandpickflow/bookings/submit_shippment_instruction"
    );

    requestMessage1.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(shippingHeader)
    );

    requestMessage1.addData( getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(shippingBody)
    );

    requestMessage1.addData( getName(MessageEnum.RestAPIRequestMethodMessage), "POST"
    );

    runEngine.sendMessage(requestMessage1.id, requestMessage1);
  };

  nominateContainer = async () => {
    const nominateToke = await getStorageData("authToken");
    const nominateHeader = {
      "Content-Type": "application/json",
      token: nominateToke,
    };
    const nominateBody = {
      "booking":{
        "is_nominated": true,
        "id": this.state.selectedBooing.id ,
    }
    }
    const requestMessage2 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.nominateContainerApiCallID = requestMessage2.messageId;

    requestMessage2.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_listdemandpickflow/bookings/booking_nomination"
    );

    requestMessage2.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(nominateHeader)
    );

    requestMessage2.addData( getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(nominateBody)
    );

    requestMessage2.addData( getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage2.id, requestMessage2);
  };

  onVerifyApiCall = async (receivedFlag : boolean,receiveFlagReject:boolean) => {
    const verifyToken = await getStorageData("authToken");

    const verifyHeader ={
      token: verifyToken,
      "Content-Type" : "application/json",
    };

    const verifyBody = {
      "booking" : {
        "draft_bl_status": receivedFlag,
        "id" : this.state.selectedBooing.id,
        "draft_file_status":receiveFlagReject
    }
    }
    const requestMessage3= new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyDraftApiCallID = requestMessage3.messageId;

     requestMessage3.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_listdemandpickflow/bookings/verify_draft_bl"
    );

    requestMessage3.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(verifyHeader)
    );

    requestMessage3.addData( getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(verifyBody)
    );

    requestMessage3.addData( getName(MessageEnum.RestAPIRequestMethodMessage),"PUT"
    );

     runEngine.sendMessage(requestMessage3.id, requestMessage3)
  };

  onRequestRevisionApiCall = async () => {
    const authToken = await getStorageData("authToken") ;
    const revisionHeader= {
      "Content-Type": "application/json",
      token: authToken,
    };
    const reVisionBody = {
      "booking":{
        "id": this.state.selectedBooing.id,
        "request_revision": this.state.reasonText
    }
    }
    const requestMessage4 = new Message( getName(MessageEnum.RestAPIRequestMessage)
    );
    this.requestRevisionApiCallID = requestMessage4.messageId;

    requestMessage4.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_listdemandpickflow/bookings/draft_bl_request_revision"
    );

    requestMessage4.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(revisionHeader)
    );

    requestMessage4.addData( getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reVisionBody)
    );

    requestMessage4.addData(  getName(MessageEnum.RestAPIRequestMethodMessage), "PUT"
    );

    runEngine.sendMessage(requestMessage4.id, requestMessage4);
  };

  onPaymentConfirmationApiCall = async () => {
    const loginToken = await getStorageData("authToken");
    const paymentHeader = {
      token: loginToken,
      contentType : "multipart/form-data"
    };

    const formData = new FormData();

    formData.append('booking[id]', this.state.selectedBooing.id);
    this.state.UploadPaymentData.forEach((item:{ UploadFile:File, })=>{
      formData.append('booking[payment_confirmation_file]', item.UploadFile)
    })
    const requestMessage5 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PaymentConfirmationApiCallID = requestMessage5.messageId;

    requestMessage5.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_listdemandpickflow/bookings/upload_payment_confirmation"
    );

    requestMessage5.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(paymentHeader)
    );

    requestMessage5.addData( getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage5.addData( getName(MessageEnum.RestAPIRequestMethodMessage), "PUT"
    );

    runEngine.sendMessage(requestMessage5.id, requestMessage5);
  };

  applyBuyerFilter = async () => {
    const token = await getStorageData("authToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };

    const reqUidData = this.state.buyerFilter.listingID?.reduce((acc: string[], item: { label: string; isChecked: boolean }) => {
      if (item.isChecked) acc.push(item.label);
      return acc;
    }, []);

    const polsData = this.state.buyerFilter.pol?.reduce((acc: string[], item: { label: string; isChecked: boolean }) => {
      if (item.isChecked) acc.push(item.label);
      return acc;
    }, []);

    const podsData = this.state.buyerFilter.pod?.reduce((acc: string[], item: { label: string; isChecked: boolean }) => {
        if (item.isChecked) acc.push(item.label);
        return acc;
      }, []);

    const typesData = this.state.buyerFilter.type?.reduce((acc: string[], item: { label: string; isChecked: boolean }) => {
        if (item.isChecked) acc.push(item.label);
        return acc;
      }, []);
      const principalOrganisation = this.state.activeSlotFilter?.map((elem:any)=> elem?.attributes?.name)


    const requestMessage5 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.applyBuyerFilterApiCallID = requestMessage5.messageId;

    requestMessage5.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_listdemandpickflow/bookings/apply_booking_filters?booking_ids=${JSON.stringify(reqUidData)}&pols=${JSON.stringify(polsData)}
      &pods=${JSON.stringify(podsData)}&container_types=${JSON.stringify(typesData)}&${principalOrganisation.length > 0 ? `request_principal_organization_names=${JSON.stringify(principalOrganisation)}`: ``}
      &eta_pod=${this.state.buyerFilter.endDate}
      &etb_pol=${this.state.buyerFilter.startDate}` 
    );

    requestMessage5.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage5.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage5.id, requestMessage5);
  };


  onBuyerRequestRoleSwitch = async() => {
    let authToken = await getStorageData("authToken");
    const header = {"Content-Type": "application/json",
      token: authToken,
    }; 
    const requestMessage = 
    new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.onRoleSwitchAPICallID = 
    requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/switch_role`
    );
    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT" );
    runEngine.sendMessage(
      requestMessage.id, 
      requestMessage);
  }

  buyerCancelBooking = async () => {
    let LocationToken = await getStorageData("authToken");
    const header = {
      token: LocationToken,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const body = {
      booking: {
        id: this.state.selectedBooing.id,
        booking_status: "rejected",
      },
    };
    this.buyerCancelBookingpiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_listdemandpickflow/bookings/booking_cancelled_by_seller"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  SwitchChnages = () => {
    this.onBuyerRequestRoleSwitch();
  };

  handleOpentrackingModal = (value: string,booking:any,index:number) => {
    switch (value) {
      case "Nominate Containers":
        this.setState({
          curr_booking: booking,
          currBookingIndex: index,
          trackingModal: true,
        });
        break;
      case "Submit S.I":
        this.setState({
          submittingState: true,
        });
        break;
      case "CONFIRM draft b/l":
        this.setState({
          DraftBLstate: true,
        });
        break;
      case "Submit payment details":
        this.setState({
          paymentconfiguration: true,
        });
        break;
      case "Download Final b/l":
        this.onDownloadBL()
        break;
    }
  };
  handleViewDetails = (booking:any) => {
    this.setState({
      curr_booking:booking,
      trackingModal: true,
      openPop: !this.state.openPop,
    });
  };

  handleNominationmodal = () => {
    this.setState({
      nominationModal: true,
      trackingModal: false,
    });
  };
  handleClose = (Key: string) => {
    switch (Key) {
      case "nominationModal":
        this.setState({
          nominationModal: false,
        });
        break;
      case "submittingState":
        this.setState({
          submittingState: false,
        });
        break;
      case "trackingModal":
        this.setState({
          trackingModal: false,
        });
        break;
      case "DraftBLstate":
        this.setState({
          DraftBLstate: false,
        });
        break;

      case "paymentconfiguration":
        this.setState({
          paymentconfiguration: false,
        });
        break;

      case "revisionRequest":
        this.setState({
          revisionrequest: false,
          DraftBLstate: true,
        });
        break;

      case "Nomination Successful!":
        this.setState({
          sucessOpenmodal: false,
        });
        break;
      case "Submission Successful!":
        this.setState({
          submittingSucess: false,
        });
        break;
      case "Draft B/L Verified!":
        this.setState({
          verifySucess: false,
        });
        break;
      case "Request Revision Sucessful":
        this.setState({
          revisionSucess: false,
        });
        break;
      case "Payment Confirmation Sent!":
        this.setState({
          paymentSucess: false,
        });
        break;
    }
  };

  handleCheckbox = (event:any) => {
    this.setState({
      nominationCheckbox: event.target.checked,
    });
  };
  handleNominationsucess = () => {
    if (this.state.nominationCheckbox) {
      this.nominateContainer()
    }
  };
  handleSubmitsucess = () => {
    this.setState({
      submittingSucess: true,
      submittingState: false,
      activeState: this.state.activeState + 1,
    });
  };
  handleVerifysucess = () => {
    this.onVerifyApiCall(true,true);
  };
  handleRevisionrequest = () => {
    this.setState({
      revisionrequest: true,
      DraftBLstate: false,
    });
  };
  handleRevisionsucess = async () => {
    if(!this.state.reasonText){
      toast.error("Reason For Revision is Required")
    }
    else{
      await this.onVerifyApiCall(false,false);
    
    setTimeout(() => {
      this.onRequestRevisionApiCall();
    }, 1000);
    }
    
  };
  handlePaymentsucess = () => {
    this.onPaymentConfirmationApiCall();
  };
  handleCancelbooking = () => {
    this.setState({
      cancelBooking: true,
    });
  };
  Cancelbooking = () => {
    this.buyerCancelBooking();
  };
  handleNocancel = () => {
    this.setState({
      cancelBooking: false,
    });
  };



  AddBuyerListingFormTextFieldChange = (
    e:any,
    keyname: string,
    setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    setFieldValue(`${keyname}`, e.target?.value);
  };
  PhoneInputOnChange = (
    number: number | string,
    keyname: string,
    setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    setFieldValue(`${keyname}`, `${number}`);
  };
  
  SubmittShippingApi = (values: any) => {
    this.onSubmitShippingForm(values);
  };

  openPopover = (e: any, booking:any, index?:number) => {
    this.setState({
      curr_booking: booking,
      currBookingIndex: index,
      openPop: !this.state.openPop,
      anchorEl: e.currentTarget,
      selectedBooing: booking,
    });
  };
  closePopover = () => {
    this.setState({
      openPop: false,
    });
  };

  Validation = () => {
    return Yup.object().shape({
      ShipperCompanyName: Yup.string().required(
        "Shipper Company Name Required"
      ),
      ShipperPersonInCharge: Yup.string().required(
        "Shipper Person-In-Charge Required"
      ),
      Address: Yup.string().required("Street Address Required"),
      AddressLine: Yup.string().required("Street Address Line 2 Required"),
      City: Yup.string().required("City Required"),
      State: Yup.string().required("State/Province Required"),
      Postal: Yup.string().required("Postal/Zip Code Required")
      .test(
        'is-valid-length',
        'Postal/Zip Code should be between 4 and 10 digits',
        value => value ? (value.length >= 4 && value.length <= 10) : true
      )
      .matches(/^(?=.*\d)[a-zA-Z0-9]+$/, 'Postal/Zip Code must be alphanumeric')
      ,
      Country: Yup.string().required("Country Required"),
      ShipperEmail: Yup.string().email('Invalid email address').required("Shipper Email Address Required"),
      ShipperContact: Yup.string().min(10, 'Contact number must be at least 10 digits')
      .max(13, 'Contact number must not exceed 13 digits').required("Shipper Contact Number Required"),

      ConsigneeCompanyName: Yup.string().required("Consignee Name Required"),
      ConsigneePersonInCharge: Yup.string().required(
        "Consignee Person-In-Charge Required"
      ),
      ConsigneeAddress: Yup.string().required("Consignee Street Address Required"),
      ConsigneeAddressLine: Yup.string().required(
        "Consignee Address Line Required"
      ),
      ConsigneeCity: Yup.string().required("Consignee City Required"),
      ConsigneeState: Yup.string().required("Consignee State/Province Required"),
      ConsigneeCountry: Yup.string().required("Consignee Country Required"),
      ConsigneeEmail: Yup.string().email('Invalid email address').required("Consignee Email Address Required"),
      ConsigneeTaxID: Yup.string(),
      ConsigneeContact: Yup.string().min(10, 'Contact number must be at least 10 digits')
      .max(13, 'Contact number must not exceed 13 digits').required("Consignee Contact Number Required"),
      ConsigneePostal: Yup.string().required("Consignee Postal/Zip Code Required")
      .test(
        'is-valid-length',
        'Consignee Postal/Zip Code should be between 4 and 10 digits',
        value => value ? (value.length >= 4 && value.length <= 10) : true
      )
      .matches(/^(?=.*\d)[a-zA-Z0-9]+$/, 'Postal/Zip Code must be alphanumeric'),

      ContainerDetails:Yup.array().of(
        Yup.object().shape({
          ContainerNumber: Yup.string().required("Container Number Required"),
          ContainerSize: Yup.string().required("Container Size  Required"),
          SealNumber: Yup.string().required("Seal Number Required"),
          HsCode: Yup.string().required("HS Code Required"),
          PackingType: Yup.string().required("Packing Type Required"),
          PackingCount: Yup.number().typeError('Invalid Packing Count').required("Package Count Required"),
          GWT: Yup.number().typeError('Invalid GWT (kgs)').required("GWT (kgs) Required"),
          CargoDescription: Yup.string().required("Cargo Description Required"),
        }))
    });
  };
  addContainer = (value:Array<{
    AverageTons: string | never[] | number; 
    ContainerSize: string;
    ContainerNumber: string;
    PackingCount: string|number;
    SealNumber: string;
    CargoDescription: string;
    HsCode: string; 
    GWT: string| number;
    ContainerType: string;
    PackingType: string;
  }>,

  setFieldValue: (field: string, value: Array<{ 
    ContainerSize: string;
    ContainerType: string;
    AverageTons: string | never[] | number;
    GWT: string|number;
    SealNumber: string;
    HsCode: string;
    CargoDescription: string;
    PackingType: string;
    PackingCount: string|number;
    ContainerNumber: string;
  }>, shouldValidate?: boolean | undefined) => void
  ) => {
    const data = [...value]
    data.push({
      ContainerNumber: "",
      ContainerType: "",
      ContainerSize: "",
      AverageTons: "",
      SealNumber: "",
      HsCode: "",
      PackingType: "",
      PackingCount: "",
      GWT: "",
      CargoDescription: ""
    })
    setFieldValue("ContainerDetails", data)
    const Index = value.length
    this.setState({
      AccordianOpenIndex: Index
    })
  };

  toggleAccordion = (index: number) => {
    this.setState((prevState) => {
      const newAccordionOpen = [...prevState.accordionOpen];
      newAccordionOpen[index] = !newAccordionOpen[index];
      return { accordionOpen: newAccordionOpen };
    });
    this.setState({
      AccordianOpenIndex: this.state.AccordianOpenIndex === index ? -1:index
    })
  };

  removeContainer = (index:number,
    value:Array<{
      GWT: string | number;
      AverageTons: string | never[];
      ContainerNumber: string;
      SealNumber: string;
      HsCode: string;
      PackingType: string;
      PackingCount: string| number;
      ContainerSize: string;
      CargoDescription: string;
      ContainerType: string;
    }>,
  
    setFieldValue: (field: string, value: Array<{
      ContainerType: string;
      ContainerNumber: string;
      HsCode: string;
      PackingCount: string |number;
      ContainerSize: string;
      GWT: string | number;
      CargoDescription: string;
      SealNumber: string;
      PackingType: string;
      AverageTons: string | never[];
    }>, shouldValidate?: boolean | undefined) => void
  ) => {
    const data = [...value];
    if (1 < data.length) {
      data.splice(index, 1);
    }
    setFieldValue("ContainerDetails", data)
  };

  ContainerDetaisOnChange = (
    e:any,
    keyname:string, 
    setFieldValue: (
    field: string,
    value: Array<{ 
      ContainerNumber: string;
      ContainerType: string;
      ContainerSize: string;
      AverageTons: string | never[];
      SealNumber: string;
      HsCode: string;
      PackingType: string;
      PackingCount: string;
      GWT: string;
      CargoDescription: string;
    }>,
    shouldValidate?: boolean | undefined
  ) => void,
  index:number
  
  ) =>{
    setFieldValue(`ContainerDetails.${index}.${keyname}`, `${e.target.value}`as never)
  }

  handleShipperaccordion = () => {
    this.setState({
      shipperAccordion: !this.state.shipperAccordion,
    });
  };
  handleConsigneeaccordion = () => {
    this.setState({
      consigneeAccordion: !this.state.consigneeAccordion,
    });
  };
  UploadPaymentDoc = (e: any) => {
    const lastIndex = e.target.files[0].name.lastIndexOf(".");
    const AfterSeprator = e.target.files[0].name.substring(lastIndex + 1)
    let FileType = ""
    FileType = AfterSeprator
    if (e.target.files![0].size > 10 * 1024 * 1024) {
      toast.error("You can't upload file bigger than 10 Mb");
    }
    if (['pdf'].includes(FileType.toLowerCase())) {
      const UploadFileData = {
        UploadFile: e.target.files[0],
        name: e.target.files[0].name
      }
      let fileList = [...this.state.UploadPaymentData];
      fileList.push(UploadFileData)
      this.setState({
        UploadPaymentData: fileList
      })
    } else {
      toast.error(`You can't upload ${FileType} File Here`);
    }
  }
  HandelRemoveUploadPaymentDocument = (index:number) =>{
    const UploadedData = [...this.state.UploadPaymentData]    
    if(UploadedData.length == 1){
      const NewData = UploadedData.filter((data:{name:string})=>{
        return data.name !== UploadedData[index].name
      })
      this.setState({
        UploadPaymentData:NewData
      });
    }
  }

  HandleDragFuncation = (e:any)=>{
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      this.setState({
        DragStart:true
      })
    }else if (e.type === "dragleave") {
      this.setState({
        DragStart:false
      })
    }
  }

  HandleFilUploadDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files) {
      const lastIndex = e.dataTransfer.files[0].name.lastIndexOf(".");
      const AfterSeprator = e.dataTransfer.files[0].name.substring(lastIndex + 1)
      let FileType = ""
      FileType = AfterSeprator
      if (e.dataTransfer.files[0].size > 10 * 1024 * 1024) {
        toast.error("You can't upload file bigger than 10 Mb");
      }
      if (['pdf'].includes(FileType.toLowerCase())) {
        const UploadFileData = {
          UploadFile: e.dataTransfer.files[0],
          name: e.dataTransfer.files[0].name
        }
        let fileList = [...this.state.UploadPaymentData];
        fileList.push(UploadFileData)
        this.setState({
          UploadPaymentData: fileList,
          DragStart: false
        })
      }
      else {
        toast.error(`You can't upload ${FileType} File Here`);
      }
    }
  }

  handleTabChange = (event: any, newValue: any) => {
    this.setState({ currentTab: newValue });
  };

  handleSnackbarClose = () => { this.setState({buyerRequestSnackbarOpen: false}) };

  onClickBuyerAddListing = () => {
    this.setState({ show: true, 
      addBuyerListing : true, 
      buyerListingAdded: false });
  };

  handleBuyerOfferExpanded = (val:string) => {
    if(val ==="listingID"){ this.setState({ buyerExpanded: this.state.buyerFilter.listingID?.length > 0 })
    }
    if(val ==="pol"){ this.setState({ buyerExpandedPol: this.state.buyerFilter.pol?.length > 0 })
    }
    if(val ==="pod"){ this.setState({ buyerExpandedPod: this.state.buyerFilter.pod?.length > 0 })
    }
    if(val ==="type"){ this.setState({ buyerExpandedType: this.state.buyerFilter.type?.length > 0 })
    }
  };

  handleBuyerOfferChange = (
    event: any,
    values: Array<{ label: string; isChecked: boolean }>,
    arrName: string ) => {
    const { 
      name, 
      checked 
    } = event.target;
    
    const updatedSelectOptions = values?.map(
      (item: { 
        label: string; 
        isChecked: boolean 
      }) => {
        return item.label === name ? { ...item, isChecked: checked } : item;
      }
    );
    if (arrName === "listingID") {
      let temp = { ...this.state.buyerFilter, listingID: updatedSelectOptions };
      this.setState({ buyerFilter: temp }, 
        ()=> {
        this.applyBuyerFilter();
      });
    }
    if (arrName === "pol") 
    {
      let temp = { ...this.state.buyerFilter, pol: updatedSelectOptions };
      this.setState({ buyerFilter: temp }, 
        ()=> {
        this.applyBuyerFilter();
      });
    }
    if (arrName === "pod") 
    {
      let temp = { ...this.state.buyerFilter, pod: updatedSelectOptions };
      this.setState({ buyerFilter: temp }, 
        ()=> {
        this.applyBuyerFilter();
      });
    }
    if (arrName === "type")
     {
      let temp = { ...this.state.buyerFilter, type: updatedSelectOptions };
      this.setState({ buyerFilter: temp }, 
        ()=> {
        this.applyBuyerFilter();
      }); }
  };

  handleBuyerOfferDelete = (
    option: any, values: Array<{label: string; isChecked: boolean }>, 
      arrName: string
  ) => {
    const updatedSelectOptionsAfterDelet = values?.map((item: any) => {
      return item.label === option.label ? { ...item, isChecked: false } : item;
    });

    if (arrName === "listingID") {
      let temp = { ...this.state.buyerFilter, listingID: updatedSelectOptionsAfterDelet }
      this.setState({ buyerFilter: temp }, ()=> {
        this.applyBuyerFilter();
      });
    }

    if (arrName === "pol") {
      let temp = { ...this.state.buyerFilter, pol: updatedSelectOptionsAfterDelet }
      this.setState({ buyerFilter: temp }, ()=> {
        this.applyBuyerFilter();
      });
     }


    if (arrName === "type") {
      let temp = { ...this.state.buyerFilter, type: updatedSelectOptionsAfterDelet }
      this.setState({ buyerFilter: temp }, ()=> {
        this.applyBuyerFilter();
      });
    }

    if (arrName === "pod") {
      let temp = { ...this.state.buyerFilter, pod: updatedSelectOptionsAfterDelet }
      this.setState({ buyerFilter: temp }, ()=> {
        this.applyBuyerFilter();
      });
    }

   
  };

  goToBuyerMatchesPage = async (routes: string) => {
    const messageBuyer: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    messageBuyer.addData(
        getName(MessageEnum.NavigationTargetMessage),
        routes
    );
    messageBuyer.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage),
    );
    messageBuyer.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(messageBuyer);
  };

  handleReasonFieldChange = (e : React.ChangeEvent<{ value: unknown }> )=>{
    this.setState({
      reasonText: e.target.value
    })
  }
  DynamicDataSet = (e: { listing?: string, requst?: string, offers?: string, booking?: string }) => { this.setState({ DynamicCount: e }) }

  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End