import React from "react";

// Customizable Area Start
import {
  Container,
  Box, Grid, Paper, Snackbar, SnackbarContent, ClickAwayListener, styled
} from "@material-ui/core";
import { ellipsis, mark } from "./assets";
import NavBarWeb from "../../../components/src/NavBar.web";
import ProjectText from "../../../components/src/ProjectText";
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  MarkAllReadAndNotificationBox = (elem:any) => {
    return (
         <>
          {this.state.HandelMarkAllReadAndDeleteNotificationPopUp && this.state.popUpIndex === Number(elem?.attributes?.id) &&
            <div style={{ position: "relative" }}>
              <Paper style={{position:"absolute", top:0, right: 0}}>
                <ClickAwayListener onClickAway={(e) => this.HandelMarkAllReadAndDeleteNotification(e, -1)}>
                  <UncontrolledBox>
                    {!elem?.attributes?.is_read && <button className="editText" onClick={()=> this.markSingleAsRead(elem?.attributes?.id)} data-testId="markSingleAsRead">MARK AS READ</button>}
                    <button className="deleteText" onClick={()=> this.deleteNotifications(elem?.attributes?.id)} data-testId="deleteNotification">DELETE NOTIFICATION</button>
                  </UncontrolledBox>
                </ClickAwayListener>
              </Paper>
            </div>
          }
        </>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <section>
        <NavBarWeb SellerPendingScreen isBuyerScreen={this.state.isBuyerRole} AppBarTransparent={false} />
        <NotificationsContainer>
          <Container maxWidth="xl">
            <NotificationsBox>
              <Grid container justifyContent="center">
                <Grid item className="responsiveContainer" lg={8}>
                  <NotificationsTitle>
                    <div className="ptb20">
                      <ProjectText variant="h6" TextSize="28px" fontweight="700" textcolor="#000">Notifications</ProjectText>
                    </div>
                  </NotificationsTitle>
                  {Object.keys(this.state.notificationsResult)?.length > 0 && 
                  <MarkAllasRead>
                    <div className="dFlex" onClick={this.markAllasRead} data-testId="markAllasRead">
                      <img src={mark} alt="" className="w20"/>
                      <ProjectText variant="h6" TextSize="14px" fontweight="700" textcolor="#1653DD" marginLeft="10px">MARK ALL AS READ</ProjectText>
                    </div>
                  </MarkAllasRead>}
                  {console.log(this.state.markOneasReadResponse, "iiiiiii" )}
                  {Object.keys(this.state.notificationsResult)?.length > 0 ? Object.keys(this.state.notificationsResult)?.sort((a: any, b: any) => (a < b) ? 1 : -1)?.map((date:any) => {
                    const res = new Date(date);
                    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                    const formattedDate = `${res.getDate()} ${monthNames[res.getMonth()]} ${res.getFullYear()}`;
                    return(
                        <div key={date}>
                          <ProjectText variant="h6" TextSize="14px" fontweight="700" textcolor="#000" marginBottom="10px">{formattedDate}</ProjectText>
                          {this.state.notificationsResult[date]?.map((notification:any, index:number) => {const timeResult = this.timeSince(notification?.attributes?.created_at)
                            return (<div key={index}>
                            <div className={`${((notification?.attributes?.id == this.state.markOneasReadResponse?.data?.attributes?.id) || notification?.attributes?.is_read) ? "transparentBox" : "blueBox"}`}>
                              <DflexBetween>
                                <DflexBetween>
                                  <div>
                                    <div className="blueDot"></div>
                                  </div>
                                  <div>
                                    <h6 className={`headings ${notification?.attributes?.notification_color == "green" && "colorGreen" } ${notification?.attributes?.notification_color == "yellow" && "colorYellow" } ${notification?.attributes?.notification_color == "red" && "colorRed" } `} style={{textTransform:"capitalize"}}>{notification?.attributes?.headings}</h6>
                                    <ProjectText variant="h6" TextSize="14px" fontweight="700" textcolor="#000" style={{textTransform:"capitalize"}}>{notification?.attributes?.contents}</ProjectText>
                                  </div>
                                </DflexBetween>
                                <div>
                                  <DFlexAlignCenter>
                                  <ProjectText variant="h6" TextSize="13px" fontweight="700" textcolor="#797979" marginRight="25px" Upercase>{timeResult} AGO</ProjectText>
                                  <div style={{cursor:"pointer"}} onClick={(e) => this.HandelMarkAllReadAndDeleteNotification(e, Number(notification?.attributes?.id))} data-testId="ellipsisIcon">
                                    <img src={ellipsis} alt="" className="ThirDots" />
                                  </div>
                                    {this.MarkAllReadAndNotificationBox(notification)}
                                  </DFlexAlignCenter>
                                </div>
                              </DflexBetween>
                            </div>
                          </div>)}
                          )}
                        </div>
                  )}) :  <div style={{position:"absolute", left:"47%", top:"60%"}}> <ProjectText variant="h6" TextSize="14px" fontweight="700" textcolor="#000">
                            NO NOTIFICATIONS
                    </ProjectText> </div>}
                </Grid>
              </Grid>
            </NotificationsBox>
          </Container>
        </NotificationsContainer>
      </section>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const NotificationsContainer = styled(Box)({
  background: "#eee", padding: "30px 0",  height:"100vh"
})

const NotificationsBox = styled(Box)({
  background:"#fff", borderRadius:"8px", minHeight:"100vh",
  "& .responsiveContainer": {
    "@media (max-width: 600px)": {
      padding: '0 20px'
    },
  },
  "& .transparentBox":{ background:"#fff", border:"1px solid #CDCDCD", padding: "15px 40px", marginBottom:"15px", borderRadius:"4px" },
  "& .blueBox":{ background:"#DBE6FF", border:"1px solid #CDCDCD", padding: "15px 40px", marginBottom:"15px", borderRadius:"4px" },
})

const NotificationsTitle = styled(Box)({
  textAlign:"center" as "center",
  "& .ptb20":{ padding:"20px 0" },
})

const MarkAllasRead = styled(Box)({
  display:"flex", alignItems:"center", justifyContent:"end", marginBottom:"20px", position:"relative", top:"40px",
  "& .dFlex":{ display:"flex", cursor:"pointer" },
  "& .w20":{ height:"20px", width:"20px"},
})

const DflexBetween = styled(Box)({
  display:"flex",
  alignItems:"center",
  justifyContent:"space-between",
  "& .blueDot":{
    height: "16px",
    width: "16px",
    background: "#1653DD",
    borderRadius: "100%",
    marginRight: "15px"
  },
  "& .headings":{
    fontSize:"15px",
    fontFamily:"Asap",
    margin:"0 0 2px 0",
    fontWeight:700
  },
  "& .colorGreen":{
    color:"#18B80A"
  },
  "& .colorRed":{
    color:"#DC2626"
  },
  "& .colorYellow":{
    color:"#EE8F00"
  },
})

const DFlexAlignCenter = styled(Box)({
  display:"flex",
  alignItems:"center",
})

const UncontrolledBox = styled(Box)({
  background: "#fff",
  display: "flex",
  flexDirection:"column" as "column",
  padding: "15px",
  minWidth: "185px",
  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  borderRadius:"8px",
  "& .editText":{
    fontFamily:"Asap",
    fontSize:"14px",
    color:"#1E1E1E",
    margin: "0 0 15px 0",
    cursor:"pointer",
    background: "transparent",
    border: "none",
    textAlign:"left",
    fontWeight:700
  },
  "& .deleteText":{
    fontFamily:"Asap",
    fontSize:"14px",
    color:"#DC2626",
    margin:0,
    cursor:"pointer",
    background: "transparent",
    border: "none",
    textAlign:"left",
    fontWeight:700
  },
})
// Customizable Area End
