// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  IconButton,
  InputLabel,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import PhoneInput from "react-phone-input-2";
import { Formik } from "formik";
import { closeImg, MailIcon, phone } from "./assets"
import { ContainedButton } from "../../../components/src/GlobalButton";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ProjectText from "../../../components/src/ProjectText";
import LandingPageTextField from "../../../components/src/ProjectTextField";


import ContactusController, { Props, configJSON } from "./ContactusController";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  handleGrid=()=>{
    return(
      <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
      <Box className="LeftBox">
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={6} xs={6}>

            <Grid item lg={6} md={6} sm={6} xs={6}>
              <ProjectText variant="h6" filter={false} fontweight="700" className={this.props.RoleOfUser === "seller" ? "ModalTitleseller" : "ModalTitlebuyer"} >
                {configJSON.Title}
              </ProjectText>
            </Grid>
            <Grid item xs={12} className="contactHeading">
              <ProjectText variant="h4" fontweight="700" TextSize="54px">
                {configJSON.Heading}<span style={{ color:this.props.RoleOfUser === "seller"? '#1653DD': '#FF5C00'}} >.</span>
              </ProjectText>
            </Grid>
            {this.state.ContactUsdata?.map((item: { description: string, id: string }) => {
              return (
                <Grid item lg={12} md={12} sm={12} xs={12} key={item.id}>
                  <ProjectText variant="h6" fontweight="400" LineHeight="1.8">
                    {item.description}
                  </ProjectText>
                </Grid>
              )
            })}

          </Grid>
          <Grid item style={{ paddingLeft: "10%" }} lg={6} md={6} sm={6} xs={6}>
            {this.state.ContactUsdata?.map((item: { email: string, phone: string, description: string, address: string, id: string }) => {
              return (
                <Grid item lg={6} md={6} sm={6} xs={6} key={item.id}>
                  <Box className="ContactUsBox">
                    <Grid container>
                      <Grid item xs={12}>
                        <Box className="SubBox">
                          <Box>
                            <IconButton aria-label="delete" className="IconButton">
                              <img src={MailIcon} alt="MailIcon" />
                            </IconButton>
                          </Box>
                          <Box>
                            <Box>
                              <Box className="EmailTextConatiner">
                                <Box>
                                  <ProjectText variant="h6" fontweight="400">
                                    {configJSON.Sendemail}
                                  </ProjectText>
                                  <Box className="TextBox">
                                    <ProjectText
                                      variant="h6"
                                      fontweight="700"
                                      TextSize="28px">
                                      {item.email}
                                    </ProjectText>
                                  </Box>
                                </Box>
                                <Box>
                                  <ArrowRightAltIcon fontSize="large" className={this.props.RoleOfUser === "seller" ? "BlueArrow" : "RedArrow"} />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box className="SubBox">
                          <Box>
                            <IconButton aria-label="delete" className="IconButton">
                              <img src={phone} alt="MailIcon" />
                            </IconButton>
                          </Box>
                          <Box>
                            <Box>
                              <Box>
                                <ProjectText variant="h6" fontweight="400">
                                  {configJSON.Givecall}
                                </ProjectText>
                                <Box className="TextBox">
                                  <ProjectText variant="h6" fontweight="700" TextSize="28px">
                                    {item.phone}
                                  </ProjectText>
                                  <Box>
                                    <ArrowRightAltIcon fontSize="large" className={this.props.RoleOfUser === "seller" ? "BlueArrow" : "RedArrow"} />
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Box>
    </Grid>
    )
  }
  // Customizable Area End


  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container>
          <ContactStyle>
            <Grid className="closeGrid">
              <Typography>
                <img onClick={this.props.handleClose} className="closeImg" src={closeImg} alt="close" />
              </Typography>
            </Grid>
            <Box className="ModalContainer">
              <Grid container spacing={2}>
               {this.handleGrid()}
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Box className="ModalBlackContainer">
                    <Formik
                      data-test-id="formik"
                      validationSchema={this.Validation}
                      initialValues={{
                        Fullname: this.state.Fullname || "",
                        companyName: this.state.companyName || "",
                        emailContact: this.state.emailContact || "",
                        PhoneNumber: this.state.PhoneNumber || "",
                        message: this.state.message || "",
                        subject: this.state.subject || "",
                      }}
                      validateOnChange={true}
                      validateOnBlur={true}
                      onSubmit={(values) => {
                        this.ContactUsApi(values);
                      }}
                    >
                      {({ errors, touched, setFieldValue, handleSubmit, isValid, values }) => {
                        return (
                          <form onSubmit={handleSubmit} autoComplete="off">
                            <Grid container spacing={6}>
                              <Grid item xs={6}>
                                <InputLabel htmlFor="Fullname" className="textlabelStyle">
                                  <ProjectText Upercase variant="subtitle1" textcolor="white" TextSize="14px" fontweight="700">
                                    {configJSON.Fullname}
                                  </ProjectText>
                                </InputLabel>
                                <LandingPageTextField value={this.state.Fullname} data-testid="FullName"
                                className="textfieldStyle"
                                  variant="outlined"
                                  helperText={errors.Fullname !== undefined && touched.Fullname && errors.Fullname}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                    this.OnchangeTextfield(e, setFieldValue)
                                  }}
                                  type='text'
                                  id="Fullname"
                                  name="Fullname"
                                  fullWidth />
                              </Grid>
                              <Grid item xs={6}>
                                <InputLabel htmlFor="companyName">
                                  <ProjectText Upercase variant="subtitle1" textcolor="white" TextSize="14px" fontweight="700">
                                    {configJSON.Company}
                                  </ProjectText>
                                </InputLabel>
                                <LandingPageTextField
                                 className="textfieldStyle"
                                  data-test-id="companyName"
                                  helperText={errors.companyName !== undefined && touched.companyName && errors.companyName}
                                  value={this.state.companyName}
                                  variant="outlined" onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                    this.OnchangeTextfield(e, setFieldValue)
                                  }} type='text' id="companyName" name="companyName" fullWidth />
                              </Grid>
                              <Grid item xs={6}>
                                <InputLabel htmlFor="emailContact">
                                  <ProjectText Upercase variant="subtitle1" textcolor="white" TextSize="14px" fontweight="700">
                                    {configJSON.emailAddress}
                                  </ProjectText>
                                </InputLabel>
                                <LandingPageTextField
                                 className="textfieldStyle"
                                inputProps={{
                                BoderRadius:"20px",
                                }}
                                  data-test-id="emailContact"
                                  value={this.state.emailContact}
                                  helperText={errors.emailContact !== undefined && touched.emailContact && errors.emailContact}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                    this.OnchangeTextfield(e, setFieldValue)
                                  }} variant="outlined" type='text' id="emailContact" name="emailContact" fullWidth />
                              </Grid>
                              <Grid item xs={6} className="PhoneInputGrid">
                                <InputLabel htmlFor="PhoneNumber">
                                  <ProjectText Upercase variant="subtitle1" textcolor="white" TextSize="14px" fontweight="700">
                                    {configJSON.ContactNumber}
                                  </ProjectText>
                                </InputLabel>
                                <PhoneInput
                                  country={"sg"}
                                  placeholder={""}
                                  value={this.state.PhoneNumber}
                                  onChange={(number: string, data: { dialCode: string }) => {
                                    this.PhoneInputNumber(data.dialCode, number, setFieldValue)
                                  }}
                                  data-test-id="PhoneNumber"
                                  inputProps={{
                                    required: true,
                                    id: "PhoneNumber",
                                    name: "PhoneNumber",

                                  }}
                                  enableSearch={true}
                                  disableSearchIcon={true}
                                  jumpCursorToEnd={true}
                                  inputClass={`PhonePackageClass`}
                                />
                                {errors.PhoneNumber !== undefined && touched.PhoneNumber !== undefined &&
                                  <ProjectText variant="h6"
                                    className="PhoneError"
                                    fontweight="400" TextSize="16px" LineHeight="1.8">
                                    {errors.PhoneNumber}
                                  </ProjectText>
                                }
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel htmlFor="subject">
                                  <ProjectText Upercase variant="subtitle1" textcolor="white" TextSize="14px" fontweight="700">
                                    {configJSON.Subject}
                                  </ProjectText>
                                </InputLabel>
                                <LandingPageTextField
                                 className="textfieldStyle"
                                  value={this.state.subject}
                                  data-test-id="subject"
                                  helperText={errors.subject !== undefined && touched.subject && errors.subject} onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                    this.OnchangeTextfield(e, setFieldValue)
                                  }} variant="outlined" type='text' id="subject" name="subject" fullWidth />
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel htmlFor="message">
                                  <ProjectText Upercase variant="subtitle1" textcolor="white" TextSize="14px" fontweight="700">
                                    {configJSON.Message}
                                  </ProjectText>
                                </InputLabel>
                                <LandingPageTextField
                                 className="textfieldStyle"
                                  value={this.state.message}
                                  helperText={errors.message !== undefined && touched.message && errors.message}
                                  data-test-id="message"
                                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                    this.OnchangeTextfield(e, setFieldValue)
                                  }} variant="outlined" type='text' id="message" name="message" fullWidth />
                              </Grid>
                              <Grid item xs={4}>
                                <ContainedButton className="sendMessagebutton" type="submit" data-test-id="SubmitBtn" bgcolor={this.props.RoleOfUser === "seller" ? "#1653DD" : "#FF5C00"} 
                                HoverColor={this.props.RoleOfUser === "seller" ? "#1653DD" : "#FF5C00"}>
                                  {configJSON.Sendmessage}
                                </ContainedButton>
                              </Grid>
                            </Grid>
                          </form>
                        )
                      }

                      }
                    </Formik>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </ContactStyle>
        </Container>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const ContactStyle = styled(Box)({
  "& .contactHeading":{
   margin:"1% 0%"
  },
  "& .sendMessagebutton":{
   fontSize:"16px"
  },
  "& .textfieldStyle":{
  borderRadius:"8px",
  },
  "& .closeGrid": {
    textAlign: "end",
    margin: "1%"
  },
    "& .closeImg":{
      cursor: "pointer",
    },
  "& .flag-dropdown": {
    boder: "none !important"
  },
  "& .PhoneInputGrid": {
    position: "relative",
    "& .PhoneError": {
      color: "red",
       margin: "6px 0px",
      position: "absolute",
      fontSize: "16px",
      fontFamily: "Asap",
    }
  },
  "& .PhoneInputGrid .country-list": {
    fontFamily: "Asap",
    color: '#1b1b1b',
  },
  "& .PhoneInputGrid .country-list .dial-code": {
    fontFamily: "Asap",
    color: '#1b1b1b',
  },
  "& .ModalTitlebuyer": {
    color: "#FF5C00"
  },
  "& .ModalTitleseller": {
    color: "#1653DD"
  },
  "& .PhonePackageClass": {
    width: "100%",
    height: "56px",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 700,
    boder: "none !important",
    backgroundColor: "white",
    fontFamily: "Asap, sans-serif",
    "@media (max-width: 1200px)": {
      fontSize: "12px",
      height: "30px"
    }
  },

  "& .EmailTextConatiner": {
    display: "flex",
    alignItems: "center"
  },
  "& .ModalContainer": {
    padding: "0px",
    "@media (max-width: 770px)": {
      padding: "unset"
    },
    "& .ModalBlackContainer": {
      backgroundColor: "black",padding: "30px",
      paddingBottom:"0px"
    },
    "& .LeftBox": {
      padding: "0 36px",

      "& .ContactUsBox": {

        "& .IconButton": {
          color: "white",
          marginRight: "10px",
        },

        "& .BlueArrow": {
          color: "#1653DD",
          marginLeft: "1.25rem"
        },
        "& .MuiSvgIcon-fontSizeLarge": {
          fontSize: "50px"
        },
        "& .RedArrow": {
          color: "#FF5C00",
          marginLeft: "1.25rem"
        },
        "& .SubBox": {
          display: "flex",
          alignItems: "center",
          marginTop: "10px", 
          marginBottom: "10px",
          "& .MuiTypography-root": {
            lineHeight: "1"
          },
          "& .TextBox": {
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap"
          }
        },
      },
    }
  },
})

// Customizable Area End