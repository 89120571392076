// Customizable Area Start
import React from "react"
import { styled } from "@material-ui/styles"
import {
  Box,
  Button,
  Grid,
  InputLabel,
  IconButton,
  DialogContent
} from "@material-ui/core"
import Slider from "react-slick"
import Navbar from "../../../components/src/NavBar.web"
import Carousel from "react-material-ui-carousel"
import ProjectText from "../../../components/src/ProjectText"
import PhoneInput from "react-phone-input-2"
import 'react-phone-input-2/lib/style.css'
import { 
  Formik 
} from "formik"
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow"
import Accordion from '@material-ui/core/Accordion'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import Divider from '@material-ui/core/Divider'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import LandingPageController, {

 } from "./LandingPageController.web"
import { 
  ContainedButton
 } from "../../../components/src/GlobalButton"
import LandingPageTextField from "../../../components/src/ProjectTextField"
const manypart = require("../assets/manypart.png")
const manypart2 = require("../assets/manypart2.png")
const SlotXpertLogo = require("../../../components/src/logo.png")
const FooterImg = require("../assets/FooterImg.png")
const MailIcon = require("../assets/Mail.png")
const Phone = require("../assets/Phone.png")
import * as Yup from 'yup'
import Spinner from "../../../components/src/ProjectLoader"
import ProjectModel from "../../../components/src/ProjectModel"

class LandingPage extends LandingPageController {
  GetInTouch = () => {
    return Yup.object().shape({ Fullname: Yup.string().required("Full Name  Required"),
      company: Yup.string().required("Company Required"),
      ContactNumber: 
      Yup.string()
      .required("Contact Number Required").min(8, "The Contact Number you have entered is invalid").max(12, "The Contact Number you have entered is invalid").nullable(),
      email: Yup.string().email("Enter valid email").required("Email Address Required"),
      message: Yup.string().required("Message Required"),
      subject: Yup.string().required("Subject Required")
    })
  };

  GetInTouchComponent = () => {
    return ( <LandingPageStyle>
        <Box className="GetInTouchContainerTop">
          <Grid container>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
              <Box className="LeftContainer">
                <Grid container spacing={2}>
                 <Grid item xs={12} md={6}> 
                 <Grid item xs={12} >
                    <ProjectText variant="h6" filter={false} fontweight="700" textcolor="#1653DD">
                      GET IN TOUCH/
                    </ProjectText>
                  </Grid>
                  <Grid item xs={12}>
                    <ProjectText variant="h4" className="GetTouchTitle" fontweight="700" TextSize="54px">
                      Reach out to
                    </ProjectText>
                    <ProjectText variant="h4" className="GetTouchTitle" fontweight="700" TextSize="54px">
                      request a demo<span className="BlueDot">.</span>
                    </ProjectText>
                  </Grid>
                  {this.state.FooterAndContactUsdata?.map((item: { description: string, id: string }) => {
                    return (
                       <Grid item xs={11} key={item.id}>
                        <ProjectText variant="h6" fontweight="400" LineHeight="1.8">
                          {item.description}
                        </ProjectText>
                      </Grid>
                    )
                  })}</Grid>

                  {this.state.FooterAndContactUsdata?.map((item: { email: string, phone: string, description: string, address: string, id: string }) => {
                    return (  
                    <Grid item xs={6} key={item.id}>
                        <Box className="ContactUsContainer">
                          <Grid container>
                            <Grid item xs={12}>
                              <Box className="Subonatiner">
                                <Box>
                                  <IconButton aria-label="delete" className="RoundButton">
                                    <img src={MailIcon} className="SubonatinerImg" alt="MailIcon" />
                                  </IconButton>
                                </Box>
                                <Box>
                                  <Box>
                                    <Box className="EmailAndCallTextConatiner">
                                      <Box>
                                        <ProjectText variant="h6" fontweight="400">
                                          Send us an email
                                        </ProjectText>
                                        <Box className="TextContainer">
                                          <ProjectText
                                            variant="h6"
                                            fontweight="700"
                                            className="contactTitle"
                                            TextSize="28px">
                                            {item.email}
                                          </ProjectText>
                                          <ArrowRightAltIcon fontSize="large" className="BlueArror" />
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Box className="Subonatiner">
                                <Box>
                                  <IconButton aria-label="delete" className="RoundButton">
                                    <img src={Phone} className="SubonatinerImg" alt="MailIcon" />
                                  </IconButton>
                                </Box>
                                <Box>
                                  <Box>
                                    <Box>
                                      <ProjectText variant="h6" fontweight="400">
                                        Give us a call
                                      </ProjectText>
                                      <Box className="TextContainer">
                                        <ProjectText variant="h6" className="contactTitle" fontweight="700" TextSize="28px">
                                          {item.phone}
                                        </ProjectText>
                                        <ArrowRightAltIcon fontSize="large" className="BlueArror" />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
              <Box className="GetInTouchBlackContainer">
                <Formik
                  validationSchema={this.GetInTouch}
                  initialValues={{
                    Fullname: this.state.Fullname || "",
                    company: this.state.company || "",
                    email: this.state.email || "",
                    ContactNumber: this.state.ContactNumber || "",
                    message: this.state.message || "",
                    subject: this.state.subject || "",
                  }}
                  validateOnChange={true}
                  validateOnBlur={true}
                  onSubmit={(values) => {
                    this.ContactUsApiFuncation(values)
                  }}
                >
                  {({ errors, touched, setFieldValue, handleSubmit, isValid, values }) => {
                    return (
                      <form onSubmit={handleSubmit} autoComplete="off">
                        <Grid container spacing={6}>
                          <Grid item xs={12} md={6}>
                            <InputLabel htmlFor="Fullname">
                              <ProjectText Upercase variant="subtitle1" textcolor="white">
                                Full name
                              </ProjectText>
                            </InputLabel>
                            <LandingPageTextField value={this.state.Fullname} inputProps={{
                              "data-test-id": "FULL NAMEModel"
                            }}
                              variant="outlined"
                              helperText={errors.Fullname !== undefined && touched.Fullname && errors.Fullname}
                              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                this.TextfieldChnage(e, setFieldValue)
                              }}
                              type='text'
                              id="Fullname"
                              name="Fullname"
                              className="GetTouchfield" fullWidth />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <InputLabel htmlFor="company">
                              <ProjectText Upercase variant="subtitle1" textcolor="white">
                                COMPANY
                              </ProjectText>
                            </InputLabel>
                            <LandingPageTextField inputProps={{
                              "data-test-id": "companyModel"
                            }} helperText={errors.company !== undefined && touched.company && errors.company}
                              value={this.state.company}
                              variant="outlined" onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                this.TextfieldChnage(e, setFieldValue)
                              }} type='text' id="company" name="company" className="GetTouchfield" fullWidth />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <InputLabel htmlFor="email">
                              <ProjectText Upercase variant="subtitle1" textcolor="white">
                                EMAIL ADDRESS
                              </ProjectText>
                            </InputLabel>
                            <LandingPageTextField
                              inputProps={{
                                "data-test-id": "emailModel"
                              }}
                              value={this.state.email}
                              helperText={errors.email !== undefined && touched.email && errors.email}
                              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                this.TextfieldChnage(e, setFieldValue)
                              }} variant="outlined" type='text' id="EMAIL" name="email" className="GetTouchfield" fullWidth />
                          </Grid>
                          <Grid item xs={12} md={6} className="PhoneInput">
                            <InputLabel htmlFor="ContactNumber">
                              <ProjectText Upercase variant="subtitle1" textcolor="white">
                                Contact Number
                              </ProjectText>
                            </InputLabel>
                            <PhoneInput
                              country={"sg"}
                              placeholder={""}
                              value={this.state.ContactNumber}
                              onChange={(number: string, data: { dialCode: string }) => {
                                this.PhoneInputChange(number, data.dialCode, setFieldValue)
                              }}
                              inputProps={{
                                required: true,
                                id: "ContactNumber",
                                name: "ContactNumber",
                                "data-test-id": "PhoneNumberModel"
                              }}
                              countryCodeEditable={false}
                              enableSearch={true}
                              disableSearchIcon={true}
                              jumpCursorToEnd={true}
                              inputClass={`PhoneInputPackgeClass`}
                              containerStyle={{
                                fontWeight: "bold"
                              }}
                            />
                            {errors.ContactNumber !== undefined && touched.ContactNumber !== undefined &&
                              <ProjectText variant="h6"
                                className="PhoneError"
                                fontweight="400" TextSize="16px" LineHeight="1.8">
                                {errors.ContactNumber}
                              </ProjectText>
                            }
                          </Grid>
                          <Grid item xs={12}>
                            <InputLabel htmlFor="subject">
                              <ProjectText Upercase variant="subtitle1" textcolor="white">
                                Subject
                              </ProjectText>
                            </InputLabel>
                            <LandingPageTextField
                              value={this.state.subject}
                              inputProps={{
                                "data-test-id": "SubjectModel"
                              }} helperText={errors.subject !== undefined && touched.subject && errors.subject} onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                this.TextfieldChnage(e, setFieldValue)
                              }} variant="outlined" type='text' id="subject" name="subject" className="GetTouchfield" fullWidth />
                          </Grid>
                          <Grid item xs={12}>
                            <InputLabel htmlFor="message">
                              <ProjectText Upercase variant="subtitle1" textcolor="white">
                                MESSAGE
                              </ProjectText>
                            </InputLabel>
                            <LandingPageTextField
                              value={this.state.message}
                              helperText={errors.message !== undefined && touched.message && errors.message}
                              inputProps={{
                                "data-test-id": "messageModel"
                              }}
                              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                this.TextfieldChnage(e, setFieldValue)
                              }} variant="outlined" type='text' id="message" name="message" className="GetTouchfield" fullWidth />
                          </Grid>
                          <Grid item xs={4}>
                            <ContainedButton style={{ whiteSpace: 'nowrap' }} type="submit" data-test-id="SubmitBtnModel">
                              SEND MESSAGE
                            </ContainedButton>
                          </Grid>
                        </Grid>
                      </form>
                    )
                  }

                  }
                </Formik>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </LandingPageStyle>
    )
  }

  MainBaner = () => {
    var settings = { dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoPlay:true,
      autoPlaySpeed:2000
    };
    return (  <Box className="MainBanner" id="HOME">
        <Navbar AppBarTransparent={true} LandingPageNavBar={true} />
        <Box style={{ overflow: "hidden" }}>
          <Slider {...settings}>
            {this.state.CarouselData?.map((item: {
              MainHeading: string, description: string, ImagesUrl: string, Id: number
            }, index: number) => {
              let bluedottext = "";
              let span = <></>;
              if (item.MainHeading.endsWith(".")) { bluedottext = item.MainHeading.slice(0, -1)
                span = <span className="BlueDot">.</span>
              } else {
                bluedottext = item.MainHeading
                span = <>
                </>
              }
              return (  <Box className="CarouselContainerBox" key={`${item.MainHeading + " " + index}`}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                      <img className="SliderImg" src={item.ImagesUrl} />
                      <Box className="TextConatiner">
                        <Box className="CarouselContainer">
                          <Box className="CarouselHeading">
                            <ProjectText width="20%" LineHeight="1.4" className="Heading" variant="h4">
                              {bluedottext}{span}
                            </ProjectText>
                          </Box>
                          <Box className="CaruselDescription">
                            <ProjectText className="Description" variant="subtitle1">
                              {item.description}
                            </ProjectText>
                          </Box>
                          <Box className="ScrollButtonConatiner">
                            <Button
                              variant="text"
                              className="ScrollerButton"
                              endIcon={<DoubleArrowIcon />}
                              href="#brandingcontainer"
                            >
                              SCROLL
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )
            })
            }
          </Slider>
          <>
          <Box className="TextConatiner">
            <Box className="CarouselContainer">
              <Box className="CarouselButtonConatainer">
                <Button
                  variant="outlined"
                  className="CaruselButton"
                  size="large"
                  data-test-id="CaruselButtonOpenViewDemo"
                  onClick={this.OpenViewDemo}
                >
                  EXPLORE DEMO
                </Button>
                <Button
                  variant="outlined"
                  className="CaruselButton contactus"
                  size="large"
                  data-test-id={`ContactUs`}
                  onClick={this.OpenGetInTouchModel}
                >
                  GET IN TOUCH
                </Button>
              </Box>
            </Box>
          </Box>
        </>
        </Box>
        <Grid container alignContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={12} xl={10} lg={10} className="BlackBoxConatainerGrid">
            <Box className="BlackBoxConatainer">
              {this.state.LandingCountData?.map((item: { liners: string, teu_transacted: string, transaction_value: string, id: string }) => {
                function splitNumberAndString(input: string): { numberPart: number, stringPart: string } {
                  const numberPart = parseInt(input);
                  const stringPart = input.replace(/\d+/g, '').trim();
                  return {
                    numberPart: numberPart,
                    stringPart: stringPart
                  };
                }
                const { numberPart, stringPart } = splitNumberAndString(item.transaction_value);
                return (
                  <Grid container>
                    <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
                      <ProjectText className="BlackBoxHeadingTitle">
                        Our Impact, Quantified
                      </ProjectText>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
                      <ProjectText fontweight="900" variant="h3" className="BlackBoxHeading">
                        {item.liners}
                      </ProjectText>
                      <ProjectText variant="h5" className="BlackBoxHeading">
                        Liners & NVOCCs onboard
                      </ProjectText>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
                      <ProjectText fontweight="900" variant="h3" className="BlackBoxHeading">
                        {item.teu_transacted}
                      </ProjectText>
                      <ProjectText variant="h5" className="BlackBoxHeading">
                        TEUs transacted
                      </ProjectText>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
                      <ProjectText variant="h3" fontweight="900" className="BlackBoxHeading">
                        {numberPart}
                        <span className="PersentageClass">{stringPart}</span>
                      </ProjectText>
                      <ProjectText variant="h5" className="BlackBoxHeading">
                        Transaction value
                      </ProjectText>
                    </Grid>
                  </Grid>
                )
              })}
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }

  BrandAndCompaniesContainer = () => {
    return ( <Box className="BrandAndCompaniesContainer" id="brandingcontainer">
        <Carousel autoPlay={false} navButtonsAlwaysInvisible animation="slide"
          indicatorContainerProps={{
            className: 'CarouselBtnsContainer',
          }}
          indicatorIconButtonProps={{
            className: 'CarouselBtns',
          }}
          activeIndicatorIconButtonProps={{
            className: 'ActiveCarouselBtns',
          }}>
          {this.state.OurPartners?.map((item: { MainHeading: string, description: string, ImagesUrl: Array<string>, Id: number }, index: number) => {
            let bluedottext = "";
            let span = <></>;
            if (item.MainHeading.endsWith(".")) { bluedottext = item.MainHeading.slice(0, -1)
              span = <span className="BlueDot">.</span>
            } else {    bluedottext = item.MainHeading
              span = <></>
            }
            return ( <Box key={`${item.MainHeading + index}`}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={12} xl={12} lg={12}>
                    <ProjectText variant="h6" filter={false} className="OurPartnersClass">OUR USERS/</ProjectText>
                  </Grid>
                </Grid>
                <Grid container spacing={10}>
                  <Grid item xs={12} md={6} xl={6} lg={6}>
                    <Box className="MultipleHeading">
                      <ProjectText variant="h4" className="Brandheadings">
                        {bluedottext}{span}
                      </ProjectText>
                      <Box>
                        <ProjectText variant="subtitle2" className="CompnainesDetails">
                          {item.description}
                        </ProjectText>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} xl={6} lg={6}>
                    <Grid container spacing={6}>
                      {item.ImagesUrl?.map((img: string) => {
                        return (
                          <Grid item xs={4} key={`${img + item.Id}`} md={4} xl={4} lg={4}>
                            <img src={img} className="BrandsIcon" />
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )
          })
          }
        </Carousel>
      </Box>
    )
  }

  AboutUsConatiner = () => {
    let bluedottext = "";
    let span = <></>;
    if (this.state.AboutUsData[0]?.heading.endsWith(".")) {
      bluedottext = this.state.AboutUsData[0]?.heading.slice(0, -1)
      span = <span className="BlueDot">.</span>
    } else {
      bluedottext = this.state.AboutUsData[0]?.heading;
      span = <></>;
    }
    return ( <Box className="AboutUsConatiner" id="ABOUTUS">
        <Grid container justifyContent="flex-end" alignItems="center">
          <Grid item xs={12} md={6} lg={6} xl={6} alignItems="center" className="LeftGrid">
            <Box className="AboutUsWhiteBox">
              <Grid container>
                <Grid item xs={12} lg={12} xl={12} md={12}>
                  <Box className="AboutUsWhiteBoxText">
                    <Box className="HedingConatiner">
                      <ProjectText variant="h6" Upercase filter={false} fontweight="700" textcolor="#1653DD">
                        {this.state.AboutUsData[0]?.type}/
                      </ProjectText>
                      <ProjectText variant="h4"
                        fontweight="700"
                        TextSize="54px"
                        className="Aboutheadings"
                      >
                        {bluedottext}{span}
                      </ProjectText>
                    </Box>
                    <Box className="AboutUsDetailsConatiner">
                      {this.state.AboutUsData.map((item: { Subtext: string }) => {
                        return (
                          <Box key={item.Subtext} className="AboutUsDetails">
                            <Box>
                              <Box className="BlueRound" />
                            </Box>
                            <ProjectText TextSize="18px" variant="subtitle2" LineHeight="1.5" fontweight="500" textcolor="#363636">
                              {item.Subtext}
                            </ProjectText>
                          </Box>
                        )
                      })}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <img src={this.state.AboutUsData[0]?.image} className="AboutUsImage" />
          </Grid>
        </Grid>
      </Box>
    )
  }

  keyfeatures = () => {
    return ( <Box className="keyfeatures" id="KEYFEATURES">
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} xl={12} lg={12}>
              <ProjectText variant="h6" className="Heading" filter={false} fontweight="700" textcolor="#1653DD">
                KEY FEATURES/
              </ProjectText>
            </Grid>
          </Grid>
        </Box>
        <Box className="keyfeaturesBigHeading">
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} xl={12} lg={12}>
              <ProjectText variant="h4"
                fontweight="700"
                TextSize="54px"
                className="Keyheadings"
              >
                Unlock the power of SlotXpert<span className="BlueDot">.</span>
              </ProjectText>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={2}>
            {this.state.KeyFeatures?.map((item: {
              image: string,
              heading: string,
              Subtext: string,
              type: string
            }, index: number) => {
              return (
                <Grid item xs={12} md={6} lg={3} xl={3} key={`${index + item.heading}`}>
                  <Box>
                    <Box className="Keyheadings">
                      <ProjectText align="center" TextSize="24px" fontweight="600">
                        {item.heading}
                      </ProjectText>
                    </Box>
                  </Box>
                  <Box>
                    <Box className="KeyImages">
                      <img src={item.image} />
                    </Box>
                  </Box>
                  <Box>
                    <Box className="KeyDescrtiption">
                      <ProjectText align="center" TextSize="18px" fontweight="400">
                        {item.Subtext}
                      </ProjectText>
                    </Box>
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </Box>
    )
  }

  WorksHowItWorks = () => {
    return (  <Box className="WorksHowItWorks" id="HOWITWORKS">
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} xl={12} lg={12}>
              <ProjectText variant="h6" filter={false} fontweight="700" textcolor="#1653DD">
                HOW IT WORKS/
              </ProjectText>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={8} xl={8} lg={8}>
              <ProjectText variant="h4"
                fontweight="700"
                TextSize="54px"
                textcolor="#fff"
              >
                A Step by Step
              </ProjectText>
              <ProjectText variant="h4"
                fontweight="700"
                TextSize="54px"
                textcolor="#fff"
              >
                Breakdown<span className="BlueDot">.</span>
              </ProjectText>
            </Grid>
            <Grid item xs={12} md={4} xl={4} lg={4}>
              <ProjectText variant="subtitle2" textcolor="#fff" TextSize="18px">
                A Comprehensive Guide to Booking Flow. Our streamlined and digitized steps accelerate the entire booking process, ensuring a seamless journey from start to end.
              </ProjectText>
            </Grid>
          </Grid>
          <Box className="ButtonContainer">
            <Grid container justifyContent="center">
              <Grid item xs={12} xl={6} lg={6}>
                <Box className="BlueConatiner">
                  {["Slot Seeker", "Slot Owner"]?.map((item: string, index: number) => {
                    return (  
                    <Button key={`${index + item}`} data-test-id={`slotId${index}`} fullWidth onClick={() => {
                        this.SetActiveButton(index)
                      }} style={{ backgroundColor: index === this.state.ActiveButton ? "white" : "", textTransform: "capitalize" }}>
                        <ProjectText variant="h4" TextSize="32px" className="workBtnText" textcolor={index === this.state.ActiveButton ? "black" : "white"} fontweight="700">
                          {item}
                        </ProjectText>
                      </Button>
                    )
                  })}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} xl={12} lg={12}>
              {this.state.ActiveButton === 0 ? <><img src={manypart} className="ManyPartImage" /></> : <><img src={manypart2} className="ManyPartImage" /></>}
            </Grid>
          </Grid>
        </Box>
      </Box>
    )
  }

  FrequentlyAskedQuestionsConatiner = () => {
    return (  
    <Box className="FrequentlyAskedQuestionsConatiner">
        <Box className="FAQheading">
          <Grid container>
            <Grid item xs={12}>
              <ProjectText variant="h6" id="FAQ" filter={false} TextSize="18px" align="center" fontweight="700" textcolor="#1653DD">
                FAQS/
              </ProjectText>
              <ProjectText variant="h4" className="FaqTitle" align="center" fontweight="700" TextSize="54px">
                Frequently asked questions<span className="BlueDot">.</span>
              </ProjectText>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={2}>
            {this.state.FaqData?.slice(0, 4).map((item: { question: string, response: string }, index: number) => {
              let colorchange = Number(this.state.OpenAccoudin) === Number(index) ? "Active" : "deactive"
              return ( 
              <Grid item xs={12} key={item.question}>
                  <Accordion data-test-id={`Accordion${index}`} expanded={Number(this.state.OpenAccoudin) === Number(index)} onClick={() => {
                    this.AccordionOnChange(index)
                  }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon fontSize="large" className={`IconSize IconColorChange ${colorchange}`} />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <ProjectText
                        className={`FirstHeading ${Number(index) === Number(this.state.OpenAccoudin) ? "IconColorChange" : "IconColorChangeBlack"} `}
                        dangerouslySetInnerHTML={{ __html: item.question }} variant="h6"
                        TextSize="30px"
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <ProjectText variant="h6" className="SubHeading" fontweight="400" dangerouslySetInnerHTML={{ __html: item.response }} />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              )
            })}
            <Grid item xs={12}>
              <Box className="ViewMoreContainer">
                <ContainedButton onClick={this.NavigateToFaq} data-test-id="NavigateToFaq">
                  View More
                </ContainedButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    )
  }

  render() {
    return (
      <LandingPageStyle data-test-id="LandingPage">
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        {this.MainBaner()}
        {this.BrandAndCompaniesContainer()}
        {this.AboutUsConatiner()}
        {this.keyfeatures()}
        {this.WorksHowItWorks()}
        {this.FrequentlyAskedQuestionsConatiner()}
        <Box className="GetInTouchContainer">
          <Grid container>
            <Grid item xs={12} sm={12} md={12} xl={6} lg={6}>
              <Box className="LeftContainer">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ProjectText variant="h6" filter={false} fontweight="700" textcolor="#1653DD">
                      GET IN TOUCH/
                    </ProjectText>
                  </Grid>
                  <Grid item xs={12}>
                    <ProjectText variant="h4" className="GetTouchTitle" fontweight="700" TextSize="54px">
                      Reach out to
                    </ProjectText>
                    <ProjectText variant="h4" className="GetTouchTitle" fontweight="700" TextSize="54px">
                      request a demo<span className="BlueDot">.</span>
                    </ProjectText>
                  </Grid>
                  {this.state.FooterAndContactUsdata?.map((item: { description: string, id: string }) => {
                    return (  <Grid item xs={11} key={item.id}>
                        <ProjectText variant="h6" fontweight="400" LineHeight="1.8">
                          {item.description}
                        </ProjectText>
                      </Grid>
                    )
                  })}

                  {this.state.FooterAndContactUsdata?.map((item: { email: string, phone: string, description: string, address: string, id: string }) => {
                    return ( <Grid item xs={12} key={item.id}>
                        <Box className="ContactUsContainer">
                          <Grid container>
                            <Grid item xs={12}>
                              <Box className="Subonatiner">
                                <Box>
                                  <IconButton aria-label="delete" className="RoundButton">
                                    <img src={MailIcon} className="SubonatinerImg" alt="MailIcon" />
                                  </IconButton>
                                </Box>
                                <Box>
                                  <Box>
                                    <Box className="EmailAndCallTextConatiner">
                                      <Box>
                                        <ProjectText variant="h6" fontweight="400">
                                          Send us an email
                                        </ProjectText>
                                        <Box className="TextContainer">
                                          <ProjectText
                                            variant="h6"
                                            fontweight="700"
                                            className="contactTitle"
                                            TextSize="28px">
                                            {item.email}
                                          </ProjectText>
                                          <ArrowRightAltIcon fontSize="large" className="BlueArror" />
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Box className="Subonatiner">
                                <Box>
                                  <IconButton aria-label="delete" className="RoundButton">
                                    <img src={Phone} className="SubonatinerImg" alt="MailIcon" />
                                  </IconButton>
                                </Box>
                                <Box>
                                  <Box>
                                    <Box>
                                      <ProjectText variant="h6" fontweight="400">
                                        Give us a call
                                      </ProjectText>
                                      <Box className="TextContainer">
                                        <ProjectText variant="h6" className="contactTitle" fontweight="700" TextSize="28px">
                                          {item.phone}
                                        </ProjectText>
                                        <ArrowRightAltIcon fontSize="large" className="BlueArror" />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    )
                  })}
                </Grid>

              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={6} lg={6}>
              <Box className="GetInTouchBlackContainer">
                <Formik
                  validationSchema={this.GetInTouch}
                  initialValues={{
                    Fullname: this.state.Fullname || "",
                    company: this.state.company || "",
                    email: this.state.email || "",
                    ContactNumber: this.state.ContactNumber || "",
                    message: this.state.message || "",
                    subject: this.state.subject || "",
                  }}
                  validateOnChange={true}
                  validateOnBlur={true}
                  onSubmit={(values) => {
                    this.ContactUsApiFuncation(values)
                  }}
                >
                  {({ errors, touched, setFieldValue, handleSubmit, isValid, values }) => {
                    return ( <form onSubmit={handleSubmit} autoComplete="off">
                        <Grid container spacing={6}>
                          <Grid item xs={12} md={6}>
                            <InputLabel htmlFor="Fullname">
                              <ProjectText Upercase variant="subtitle1" textcolor="white">
                                Full name
                              </ProjectText>
                            </InputLabel>
                            <LandingPageTextField value={this.state.Fullname} inputProps={{
                              "data-test-id": "FULL NAME"
                            }}
                              variant="outlined"
                              helperText={errors.Fullname !== undefined && touched.Fullname && errors.Fullname}
                              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                this.TextfieldChnage(e, setFieldValue)
                              }}
                              type='text'
                              id="Fullname"
                              name="Fullname"
                              fullWidth />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <InputLabel htmlFor="company">
                              <ProjectText Upercase variant="subtitle1" textcolor="white">
                                COMPANY
                              </ProjectText>
                            </InputLabel>
                            <LandingPageTextField inputProps={{
                              "data-test-id": "company"
                            }} helperText={errors.company !== undefined && touched.company && errors.company}
                              value={this.state.company}
                              variant="outlined" onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                this.TextfieldChnage(e, setFieldValue)
                              }} type='text' id="company" name="company" fullWidth />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <InputLabel htmlFor="email">
                              <ProjectText Upercase variant="subtitle1" textcolor="white">
                                EMAIL ADDRESS
                              </ProjectText>
                            </InputLabel>
                            <LandingPageTextField
                              inputProps={{
                                "data-test-id": "email"
                              }}
                              value={this.state.email}
                              helperText={errors.email !== undefined && touched.email && errors.email}
                              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                this.TextfieldChnage(e, setFieldValue)
                              }} variant="outlined" type='text' id="EMAIL" name="email" fullWidth />
                          </Grid>
                          <Grid item xs={12} md={6} className="PhoneInput">
                            <InputLabel htmlFor="ContactNumber">
                              <ProjectText Upercase variant="subtitle1" textcolor="white">
                                Contact Number
                              </ProjectText>
                            </InputLabel>
                            <PhoneInput
                              country={"sg"}
                              placeholder={""}
                              value={this.state.ContactNumber}
                              onChange={(number: string, data: { dialCode: string }) => {
                                this.PhoneInputChange(number, data.dialCode, setFieldValue)
                              }}
                              inputProps={{
                                required: true,
                                id: "ContactNumber",
                                name: "ContactNumber",
                                "data-test-id": "PhoneNumber"
                              }}
                              enableSearch={true}
                              disableSearchIcon={true}
                              jumpCursorToEnd={true}
                              countryCodeEditable={false}
                              inputClass={`PhoneInputPackgeClass`}
                              containerStyle={{
                                fontWeight: "bold"
                              }}
                            />
                            {errors.ContactNumber !== undefined && touched.ContactNumber !== undefined &&
                              <ProjectText variant="h6"
                                className="PhoneError"
                                fontweight="400" TextSize="16px" LineHeight="1.8">
                                {errors.ContactNumber}
                              </ProjectText>
                            }
                          </Grid>
                          <Grid item xs={12}>
                            <InputLabel htmlFor="subject">
                              <ProjectText Upercase variant="subtitle1" textcolor="white">
                                Subject
                              </ProjectText>
                            </InputLabel>
                            <LandingPageTextField
                              value={this.state.subject}
                              inputProps={{
                                "data-test-id": "Subject"
                              }} helperText={errors.subject !== undefined && touched.subject && errors.subject} onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                this.TextfieldChnage(e, setFieldValue)
                              }} variant="outlined" type='text' id="subject" name="subject" fullWidth />
                          </Grid>
                          <Grid item xs={12}>
                            <InputLabel htmlFor="message">
                              <ProjectText Upercase variant="subtitle1" textcolor="white">
                                MESSAGE
                              </ProjectText>
                            </InputLabel>
                            <LandingPageTextField
                              value={this.state.message}
                              helperText={errors.message !== undefined && touched.message && errors.message}
                              inputProps={{
                                "data-test-id": "message"
                              }}
                              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                this.TextfieldChnage(e, setFieldValue)
                              }} variant="outlined" type='text' id="message" name="message" fullWidth />
                          </Grid>
                          <Grid item xs={4}>
                            <ContainedButton style={{ whiteSpace: 'nowrap' }} type="submit" data-test-id="SubmitBtn">
                              SEND MESSAGE
                            </ContainedButton>
                          </Grid>
                        </Grid>
                      </form>
                    )
                  }

                  }
                </Formik>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className="ExportConatiner">
          <img src={FooterImg} />
        </Box>
        <Box className="FooterContainer">
          <Grid container spacing={4} justifyContent="space-between" alignItems="stretch">
            <Grid item xs={12} md={6}>
              <Box className="footoerlogo">
                <img src={SlotXpertLogo} alt="logo" className="logo" />
              </Box>
              <Box>
                <ProjectText variant="h6" textcolor="white" TextSize="20px">Never let empty slots on vessels go wasted!</ProjectText>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} lg={3}>
              <Box >
                <Box>
                  <Box>
                    <ProjectText variant="h6" className="leftSideFirstTitle" textcolor="white" fontweight="900" TextSize="20px">Office Address</ProjectText>
                  </Box>
                  {this.state.FooterAndContactUsdata?.map((item: { description: string, id: string, address: string, }) => {
                    return ( 
                    <Box className="SpaceBetween" key={item.id}>
                        <ProjectText variant="h6" className="leftSideSecondTitle" textcolor="white" TextSize="20px">
                          {item.address}
                        </ProjectText>
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider className="Divider" />
            </Grid>
            <Grid item xs={12}>
              <ProjectText variant="h6" textcolor="white" align="center" fontweight="400" TextSize="20px"> All rights reserved.</ProjectText>
            </Grid>
          </Grid>
        </Box>
        <ProjectModel IconButtonBgColor="white" CloseIconDataTestId="OpenViewDemoClose" BtnColor="black" maxWidth="md" OpenModel={this.state.ViewDemoModel} ModelTitleColor="black" TitlePadding="10px" onClose={this.OpenViewDemo} DialogContentPadding={false} fullWidth modalTitle="" aria-labelledby="customized-dialog-title">
          <DialogContent>
            <video className="videocontrolStyle" width="100%" height="auto" controls style={{ height: "100%" }}>
              <source src={`${this.state.ViewDemoData}`} type="video/mp4" />
            </video>
          </DialogContent>
        </ProjectModel>
        <ProjectModel IconButtonBgColor="black" BtnColor="white" maxWidth="lg" ModelTitleColor="white" TitlePadding="10px" onClose={this.OpenGetInTouchModel} DialogContentPadding={false} fullWidth modalTitle="" aria-labelledby="customized-dialog-title" OpenModel={this.state.GetInTouchModel}>
          <DialogContent>
            <ModelStyle>
              {this.GetInTouchComponent()}
            </ModelStyle>
          </DialogContent>
        </ProjectModel>
      </LandingPageStyle>
    );
  }
}

export default LandingPage;

const ModelStyle = styled(Box)({
  "& .PhoneInputPackgeClass": {
    width: "100%",
    height: "56px",
    borderRadius: "5px",
    fontSize: "16px",
    fontWeight: 700,
    boder: "none !important",
    backgroundColor: "white",
    "@media (max-width: 1200px)": {
      fontSize: "12px",
      height: "30px"
    }
  },
  "& .flag-dropdown": {
    boder: "none !important"
  },
  "& .PhoneInput": {
    position: "relative",
    "& .PhoneError": {
      color: "red",
      margin: "6px 14px",
      marginLeft:"0",
      position: "absolute",
      fontWeight:"bold"
    },
    "& .react-tel-input .country-list": {
      fontFamily: "Asap",
      color: '#1b1b1b',
    },
    "& .react-tel-input .country-list .dial-code": {
      fontFamily: "Asap",
      color: '#1b1b1b',
    },
  },
  "& .EmailAndCallTextConatiner": {
    display: "flex",
    alignItems: "center"
  }
})

const LandingPageStyle = styled(Box)({
  "& .MuiFormHelperText-contained":{
    marginLeft:"0",
  fontWeight:"bold",
  },
  "& .MuiOutlinedInput-input": {
    "@media (max-width: 1200px)": {
      padding: '5px 14px'
    },
  },
 
 "& video::-webkit-media-controls-current-time-display":{
    fontWeight:"bold",
  },
  
  "& .MainBanner": {
    position: "relative",
    "& .CarouselContainerBox": {
      position: "relative", height: "100vh",
      "@media (max-width: 1025px)": {
        height: "unset"
      },
    },
    "& .TextConatiner": {
      padding: "0 55px",
      "@media (max-width: 440px)": {
        padding: "0 32px",
      },
    },
    "& .CarouselContainer": {
      position: "absolute",
      top: "160px",
      width: 'calc(100vw - 72px)',
      "@media (max-width: 600px)": {
        top: "115px",
      },
      "& .Heading": {
        fontSize: "45px",
        color: "#fff",
        fontWeight: "700",
        "@media (max-width: 1025px)": {
          width: "unset"
        },
        "@media (max-width: 1473px)": {
          fontSize: "35px",
        },
        "@media (max-width: 600px)":
         {
          fontSize: "25px",
        },
      },
      "& .CarouselButtonConatainer": {
        display: "flex",
        position: "relative",
        top: "260px",
        marginTop: "10px",
        "@media (min-width: 2431px)": {
          top: "205px"
         },
        "@media (max-width: 1545px)": {
          top: "325px"
         },
        "@media (max-width: 1473px)": {
         top: "215px",
        },
        "@media (max-width: 1024px)": {
          top: "17rem",
          display: "grid",
          gridAutoFlow: "column",
          justifyContent: "center"
         },
        "@media (max-height: 640px)": {
          top: "8rem",
          display: "grid",
          gridAutoFlow: "column",
          justifyContent: "center"
         },
        "& .CaruselButton": {
          borderRadius: "30px",
          whiteSpace: "nowrap",
          "& .MuiButton-label": {
            fontFamily: "Asap, sans-serif",
          },
          color: "white",
          fontWeight: "800",
          fontSize: "12px",
          padding: "8px 42px",
          backgroundColor: "#1653DD",
          border: "1px solid transparent",
          "@media (max-width: 1473px)": {
            padding: '6px 32px'
          },
        },
        "&  .contactus": {
          marginLeft: "10px",
          backgroundColor: "transparent",
          border: "1px solid #fff",
          "&:hover": {
            backgroundColor: "#1653DD",
            border: "1px solid transparent",
          },
          "@media (max-width: 1473px)": {
            padding: '6px 32px'
          },
        },
      },
      "& .Description": {
        marginTop: "12px",
        marginBottom: "12px",
        color: "#fff",
        width: '20%',
        fontWeight: "400",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
        "@media (max-width: 1473px)": {
          fontSize: "15px",
          marginTop: "12px",
          marginBottom: "12px",
        },
        "@media (max-width: 600px)": {
          fontSize: "12px",
        },
        "@media (max-width: 1025px)": {
          width: '100%',
        },
      },
      "& .ScrollButtonConatiner": {
        display: "flex",
        justifyContent: "flex-end",
        position: "absolute",
        marginTop: "60px",
        right: 0,
        left: 0,
        "& .ScrollerButton": {
          color: "#fff",
          transform: "rotate(90deg)",
          fontWeight: "700",
          "& .MuiButton-label": {
            filter: "drop-shadow(0px 0px 1px grey)"
          }
        },
      },
    },
    "& .slick-dots": {
      position: "absolute",
      bottom: "230px",
      "@media (max-width: 1025px)": {
        bottom: "20px"
      },
      filter: "drop-shadow(0px 0px 1px grey)"
    },
  },
  "& .BlackBoxConatainerGrid": {
    position: "relative",
    "& .BlackBoxConatainer": {
      backgroundColor: "black",
      padding: "40px 60px",
      position: "absolute",
      width: "100%",
      top: "-12.5rem",
      "@media (max-width: 1025px)": {
        position: "unset",
        width: "unset"
      },
      "@media (max-width: 960px)": {
        textAlign: 'center'
      },
    },
    "& .BlackBoxHeading": {
      color: "white",
      backdropFilter: 'blur(5px)',
    },
    "& .BlackBoxHeadingTitle": {
      fontSize: '45px',
      lineHight: '48px',
      color: "white",
      marginTop: '15px',
      backdropFilter: 'blur(5px)',
      "@media (max-width: 1920px)": {
        fontSize: '38px',
        lineHight: '48px',
        color: "white",
        backdropFilter: 'blur(5px)',
      },
      "@media (max-width: 1440px)": {
        fontSize: '36px',
        lineHight: '48px',
        color: "white",
        backdropFilter: 'blur(5px)',
      },
      "@media (max-width: 1280px)": {
        fontSize: '33px',
        lineHight: '48px',
        color: "white",
        backdropFilter: 'blur(5px)',
      },
      "@media (max-width: 1080px)": {
        fontSize: '30px',
        lineHight: '58px',
        color: "white",
        backdropFilter: 'blur(5px)',
      },
      "@media (max-width: 436px)": {
        fontSize: '26px',
        lineHight: '58px',
        color: "white",
        backdropFilter: 'blur(5px)',
      },

    },
    "& .PersentageClass": {
      color: "#1653DD",
      fontWeight: "900",
      fontSize: "18px",
    },
    "& h5.BlackBoxHeading": {
      marginTop: "15px"
    },
    "& h3.BlackBoxHeading": {
      marginTop: "15px"
    }
  },
  "& .slick-list":{
    display:"grid"
  },
  "& .slick-dots li": {
    width: '10px'
  },
  "& .slick-dots li button::before": {
    fontSize: "12px",
    color: 'rgba(255, 255, 255, 1)',
    opacity: "none",
    zIndex:100
  },
  "& .slick-dots li.slick-active button:before": {
    opacity: 100,
    fontSize: '20px',
    color: 'white',
    zIndex:100
  },
  "& .SliderImg": {
    width: "100vw",
    height: "100vh",
    "@media (max-width: 1026px)": {
      height: "60vh",
    },
  },
  "& .BrandAndCompaniesContainer": {
    padding: "70px 56px",
    "@media (max-width: 420px)": {
      padding: "52px 16px",
    },
    "& .BrandsIcon": {
      height: "auto",
      width: "100%",
      aspectRatio:"13/5",
    },
    "& .MultipleHeading": {
      paddingRight: "20px"
    },
    "& .CompnainesDetails": {
      fontSize: "18px"
    },
    "& .OurPartnersClass": {
      color: "#1653DD",
      fontWeight: "700",
    },
    "& .Brandheadings": {
      fontWeight: "700",
      fontSize: "46px",
      marginBottom: '15px',
      "@media (max-width: 1280px)": {
        fontSize: "40px",
      },
      "@media (max-width: 1080px)": {
        fontSize: "38px",
      },
      "@media (max-width: 778px)": {
        fontSize: "32px",
      },
    },
    "& .CarouselBtnsContainer": {
      marginTop: '70px',
      marginLeft: '400px',
      "@media (max-width: 1025px)": {
        margin: "unset"
      },
    },
    "& .CarouselBtns": {
      marginRight: ".125rem",
      marginLeft: ".125rem",
      color: "grey"
    },
    "& .ActiveCarouselBtns": {
      backgroundColor: 'black',
      color: "black",
      padding: '1px',
    }
  },
  "& .AboutUsConatiner": {
    paddingTop: "30px",
    overflowY: "auto",
    paddingBottom: "30px",
    "& .LeftGrid": {
      display: "flex",
      alignItems: 'center',
      "@media (max-width: 768px)": {
        display: 'grid'
      },
    },
    "& .AboutUsImgContainer": {
      backgroundColor: "white"
    },
    "& .AboutUsWhiteBox": {
      position: "absolute",
      width: 'calc(60% - 60px)',
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px,rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;",
      left: '0px',
      backgroundColor: "white",
      "@media (max-width: 1280px)": {
        height: 'max-content'
      },
      "@media (max-width: 768px)": {
        width: '100%',
        position: "initial",
      },
      "& .AboutUsWhiteBoxText": {
        padding: "50px",
        height: "100%",
        "@media (max-width: 420px)": {
          padding: "32px 16px",
        },
        "& .HedingConatiner": {
          marginTop: "20px",
          marginBottom: "20px",
          "& .Aboutheadings": {
            fontWeight: "700",
            fontSize: "48px",
            "@media (max-width: 1280px)": {
              fontSize: "40px",
            },
            "@media (max-width: 1080px)": {
              fontSize: "38px",
            },
            "@media (max-width: 778px)": {
              fontSize: "32px",
            },
          },
          "@media (max-width: 1280px)": {
            marginTop: "0px",
            marginBottom: "0px",
          },
        },
        "& .AboutUsDetailsConatiner": {
          marginTop: "10px",
          "& .AboutUsDetails": {
            display: "flex",
            alignItems: "flex-start",
            margin: "30px 0",
            "& .BlueRound": {
              padding: "8px",
              backgroundColor: "#1653DD",
              borderRadius: "16px",
              marginRight: "1.5rem",
              marginTop: ".375rem"
            }
          }
        }
      }
    },
    "& .AboutUsImage": {
      width: "100%",
      height: "auto",
      zIndex: -1
    },
    "@media (max-width: 1280px)": {
      paddingTop: "60px",
      paddingBottom: "120px",
    },
    "@media (max-width: 1026px)": {
      paddingTop: "120px",
      paddingBottom: "160px",
    },
    "@media (max-width: 768px)": {
      paddingTop: "20px",
      paddingBottom: "20px",
    },
  },
  "& .keyfeatures": {
    padding: "56px",
    marginBottom: "50px",
    "@media (max-width: 420px)": {
      padding: "32px 16px",
    },
    "& .Heading": {
      "@media (max-width: 1025px)": {
        textAlign: "center"
      },
    },
    "& .keyfeaturesBigHeading": {
      marginBottom: "30px",
      "@media (max-width: 1025px)": {
        textAlign: "center"
      },
      "& .Keyheadings": {
        fontWeight: "700",
        fontSize: "48px",
        "@media (max-width: 1280px)": {
          fontSize: "40px",
        },
        "@media (max-width: 1080px)": {
          fontSize: "38px",
        },
        "@media (max-width: 778px)": {
          fontSize: "32px",
        },
      }
    },
    "& .Keyheadings": {
      minHeight: "70px"
    },
    "& .KeyImages": {
      display: "flex",
      justifyContent: "center",
      margin: "10px 10px",
      "& img": {
        width: "100%",
        aspectRatio:1,
        objectFit:"fill"
      }
    }
  },
  "& .WorksHowItWorks": {
    backgroundColor: "black",
    padding: "56px",
    "@media (max-width: 420px)": {
      padding: "32px 16px",
    },
    "& .ButtonContainer": {
      margin: "50px 0",
      "& .BlueConatiner": {
        backgroundColor: "#1653DD",
        padding: "4px",
        display: "flex",
        "& button": {
          transition: 'all 200ms ease-in',
        },
        "& .workBtnText": {
          "@media (max-width: 1280px)": {
            fontSize: '22px'
          },
          "@media (max-width: 768px)": {
            fontSize: '20px'
          },
          "@media (max-width: 600px)": {
            fontSize: '16px'
          },
        }
      }
    },
    "& .ManyPartImage": {
      objectFit: "cover", width: "100%"
    }
  },
  "& .FrequentlyAskedQuestionsConatiner": {
    padding: "50px 52px",
    "@media (max-width: 420px)": {
      padding: "32px 16px",
    },
    "& .deactive": {
      transform: "rotate(270deg)"
    },
    "& .Active": {
      transform: "rotate(180deg)",
    },
    "& .ViewMoreContainer": {
      display: "flex",
      justifyContent: "center",
      marginTop: "20px"
    },
    "& .FAQheading": {
      padding: "50px 0",
      "& .FaqTitle": {
        fontWeight: "700",
        fontSize: "48px",
        "@media (max-width: 1280px)": {
          fontSize: "40px",
        },
        "@media (max-width: 1080px)": {
          fontSize: "38px",
        },
        "@media (max-width: 778px)": {
          fontSize: "32px",
        },
      },
    },
    "& .MuiAccordion-rounded": {
      backgroundColor: "#F2F2F2",
      padding: "20px 60px",
      "@media (max-width: 768px)": {
        padding: "20px 30px",
      },
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none"
    },
    "& .IconColorChange": {
      color: "#1653DD",
    },
    "& .IconColorChangeBlack": {
      color: "black"
    },
    "& .FirstHeading": {
      "@media (max-width: 1280px)": {
        fontSize: '25px'
      },
      "@media (max-width: 768px)": {
        fontSize: '22px'
      },
      "@media (max-width: 600px)": {
        fontSize: '20px'
      },
      "@media (max-width: 500px)": {
        fontSize: '18px'
      },
      "& p": {
        margin: "0px",
      }
    },
    "& .SubHeading": {
      "& p": {
        margin: "0px",
        fontSize: "20px"
      }
    },
    "& .IconSize": {
      fontSize: "40px"
    }
  },
  "& .GetInTouchContainer": {
    paddingRight: "0px",
    paddingBottom: "0px",
    "@media (max-width: 960px)": {
      padding: "unset"
    },
    "& .GetInTouchBlackContainer": {
      backgroundColor: "black",
      padding: "30px"
    },
    "& .LeftContainer": {
      padding: "0 52px",
      "@media (max-width: 420px)": {
        padding: "32px 16px",
      },
      "& .GetTouchTitle": {
        "@media (max-width: 1280px)": {
          fontSize: "40px",
        },
        "@media (max-width: 1080px)": {
          fontSize: "38px",
        },
        "@media (max-width: 778px)": {
          fontSize: "32px",
        },
      },
      "& .ContactUsContainer": {
        "& .Subonatiner": {
          display: "flex",
          alignItems: "center",
          marginTop: "10px",
          marginBottom: "10px",
          "& .MuiTypography-root": {
            lineHeight: "1"
          },
          "& .TextContainer": {
            display: "flex",
            alignItems: "center",
            "& .contactTitle": {
              "@media (max-width: 770px)": {
                fontSize: '22px'
              },
            }
          }
        },
        "& .RoundButton": {
          color: "white",
          marginRight: "10px",
          "& .SubonatinerImg": {
            "@media (max-width: 420px)": {
              width: '45px'
            },
          }
        },
        "& .BlueArror": {
          color: "#1653DD",
          marginLeft: "1.25rem",

        }
      },
    }
  },
  "& .GetInTouchContainerTop": {
    paddingRight: "0px",
    paddingBottom: "0px",
    "& .GetInTouchBlackContainer": {
      backgroundColor: "black",
      padding: "30px"
    },
    "& .LeftContainer": {
      padding: "0 52px",
      "@media (max-width: 420px)": {
        padding: "32px 16px",
      },
      "& .GetTouchTitle": {
        "@media (max-width: 1280px)": {
          fontSize: "40px",
        },
        "@media (max-width: 1080px)": {
          fontSize: "38px",
        },
        "@media (max-width: 778px)": {
          fontSize: "32px",
        },
      },
      "& .ContactUsContainer": {
        "& .Subonatiner": {
          display: "flex",
          alignItems: "center",
          marginTop: "10px",
          marginBottom: "10px",
          "& .MuiTypography-root": {
            lineHeight: "1"
          },
          "& .TextContainer": {
            display: "flex",
            alignItems: "center",
            "& .contactTitle": {
              "@media (max-width: 770px)": {
                fontSize: '22px'
              },
            }
          }
        },
        "& .RoundButton": {
          color: "white",
          marginRight: "10px",
          "& .SubonatinerImg": {
            "@media (max-width: 420px)": {
              width: '45px'
            },
          }
        },
        "& .BlueArror": {
          color: "#1653DD",
          marginLeft: "1.25rem",

        }
      },
    }
  },
  "& .ExportConatiner": {
    "& img": {
      height: "350px",
      width: "100%",
      objectFit: "cover"
    }
  },
  "& .FooterContainer": {
    backgroundColor: "black",
    padding: "70px 56px",
    "& .LeftSide": {
      display: "flex",
      justifyContent: "flex-end",
      "& .leftSideFirstTitle": {
        "@media (max-width: 420px)": {
          fontSize: '16px',
          marginTop: '55px'
        },
      },
      "& .leftSideSecondTitle": {
        "@media (max-width: 420px)": {
          fontSize: '16px',
        },
      },
    },
    "& .SpaceBetween": {
      marginTop: "20px",
      "@media (max-width: 420px)": {
        marginTop: '0px'
      },
    },
    "& .Divider": {
      backgroundColor: "#4C4C4C"
    }
  },
  "& .PhoneInputPackgeClass": {
    width: "100%",
    height: "56px",
    borderRadius: "5px",
    fontSize: "16px",
    fontWeight: 700,
    boder: "none !important",
    backgroundColor: "white",
    "@media (max-width: 1200px)": {
      fontSize: "12px",
      height: "30px"
    }
  },
  "& .flag-dropdown": {
    boder: "none !important"
  },
  "& .PhoneInput": {
    position: "relative",
    "& .PhoneError": {
      fontSize: "16px",
      color: "red",
      margin: "6px 14px",
      position: "absolute",
      marginLeft:"0",

      fontWeight:"bold"
    },
    "& .react-tel-input .country-list": {
      fontFamily: "Asap",
      color: '#1b1b1b',
    },
    "& .react-tel-input .country-list .dial-code": {
      fontFamily: "Asap",
      color: '#1b1b1b',
    },
  },
  "& .footoerlogo": {
    marginBottom: "1.25rem",
  },
  "& .EmailAndCallTextConatiner": {
    display: "flex",
    alignItems: "center"
  },
  "& .BlueDot": {
    color: "blue"
  }
});

// Customizable Area End 