import React from "react";
import {
    Modal,
    Fade,
    Backdrop,
    CircularProgress
} from "@material-ui/core";
import { styled } from "@material-ui/styles";

interface myProps {
    spinnerModal: boolean;
}

export default function Spinner(props: myProps) {
    return <LoaderStyle
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
        open={props.spinnerModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
        disableAutoFocus={true}
    >
        <Fade in={props.spinnerModal} style={{ border: "none" }}>
            <div style={{
                backgroundColor: "transparent",
                outline: "none",
                borderRadius: 29,
                width: "auto",
                height: "auto",

            }}>
                <CircularProgress value={10} size={60} />
            </div>
        </Fade>
    </LoaderStyle>
}

const LoaderStyle = styled(Modal)({
    "& .MuiBackdrop-root": {
        backgroundColor: "white",
    }
}) 