Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";

exports.LandPageGetMethod = "GET";
exports.LandPageApiEndPoint = "/bx_block_landingpage2/landing_pages";

exports.LandPageCountGetMethod = "GET";
exports.LandPageCountApiEndPoint = "/bx_block_landingpage2/landing_counts";

exports.FaqGetMethod = "GET";
exports.FaqsApiEndPoint = "/bx_block_interactive_faqs/interactive_faqs";

exports.ViewDemoMethod = "GET";
exports.ViewDemoMethodEndPoint = "/bx_block_content_management/videos";

exports.AboutUsAndKeyFetureMethod = "GET";
exports.AboutUsAndKeyFetureApiEndPoint = "/bx_block_landingpage2/sections";

exports.InteractiveFaqsMethod = "GET";
exports.InteractiveFaqsApiEndPoint = "/bx_block_interactive_faqs/interactive_faqs";

exports.ContactUsApiMethod_POST = "POST";
exports.ContactUsApiEndPoint = "/bx_block_contact_us/enquiry_before_log_in";

exports.FooterApiMethod_GET = "GET";
exports.FooterAPiEndPoint = "/bx_block_contact_us/contact_us";
// Customizable Area End