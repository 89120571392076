// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";


export const configJSON = require("./config");

export interface Props {
  id: string;
  navigation: any;
}

interface S {
  ActiveButton: number
  OpenAccoudin: number
  CarouselData: Array<{ MainHeading: string, description: string, ImagesUrl: string, Id: number, SectionType: string }>;
  OurPartners: Array<{ MainHeading: string, description: string, ImagesUrl: Array<string>, Id: number, SectionType: string }>;
  FaqData: Array<{ question: string, response: string }>;
  AboutUsData: Array<{ heading: string, Subtext: string, image: string, type: string }>;
  KeyFeatures: Array<{ heading: string, Subtext: string, image: string, type: string }>;
  ViewDemoData: Array<string | Object | undefined | null>;
  formData: Array<{ key: string, value: string }>;
  loading: boolean;
  ViewDemoModel: boolean;
  GetInTouchModel: boolean;
  FooterAndContactUsdata: Array<{ email: string, phone: string, description: string, address: string, id: string }>,
  LandingCountData: Array<{ liners: string, teu_transacted: string, transaction_value: string, id: string }>,
  ResetForm: boolean,
  Fullname: string,
  company: string,
  email: string,
  ContactNumber: string,
  message: string,
  subject: string,
  ActiveCarouselIndex: number,
  FormReinitialize: boolean,
  DailCode: string
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      ActiveButton: 0,
      OpenAccoudin: -1,
      CarouselData: [],
      AboutUsData: [],
      ViewDemoData: [],
      FaqData: [],
      formData: [],
      OurPartners: [],
      KeyFeatures: [],
      loading: false,
      ViewDemoModel: false,
      GetInTouchModel: false,
      FooterAndContactUsdata: [],
      LandingCountData: [],
      ResetForm: false,
      Fullname: "",
      company: "",
      email: "",
      ContactNumber: "",
      message: "",
      subject: "",
      ActiveCarouselIndex: 0,
      FormReinitialize: false,
      DailCode: ""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.LandingPageFirstApi) {
      let DataAPIresposne = responseJson.data?.map((item: {
        attributes: {
          section_title: string,
          content: string,
          images: Array<{ url: string, id: number }>,
          key_name: string
        }
      }) => {
        return {
          MainHeading: item.attributes.section_title,
          description: item.attributes.content,
          ImagesUrl: item.attributes.images?.map((Imgitem: { url: string }) => Imgitem.url),
          Id: item.attributes.images?.map((Imgitem: { id: number }) => Imgitem.id),
          SectionType: item.attributes.key_name
        }
      })
      let CarouselDataAPIresposne = DataAPIresposne.filter((item: { SectionType: string }) => item.SectionType === "Image-Slider")
      let OurPartnersData = DataAPIresposne.filter((item: { SectionType: string }) => item.SectionType === "our-partners")
      this.setState({
        CarouselData: CarouselDataAPIresposne,
        OurPartners: OurPartnersData,
        loading: false
      })
    }
    if (apiRequestCallId === this.LandingPageSecondApi) {
      let LandingCountResposneData = responseJson.data?.map((item: {
        attributes: {
          liners: string, teu_transacted: string, transaction_value: string,
        }, id: string
      }) => {
        return {
          liners: item.attributes.liners,
          teu_transacted: item.attributes.teu_transacted,
          transaction_value: item.attributes.transaction_value,
          id: item.id
        }
      })
      this.setState({
        LandingCountData: LandingCountResposneData
      })

    }
    if (apiRequestCallId === this.AboutUsAndKeyFetureApiId) {
      let ResponseJsonData = responseJson.data?.map((item: { attributes: { image: string, section_header: string, section_text: string, section_type: string, } }) => {
        return {
          image: item.attributes.image,
          heading: item.attributes.section_header,
          Subtext: item.attributes.section_text,
          type: item.attributes.section_type
        }
      })
      const AboutData = ResponseJsonData.filter((item: { type: string }) => item.type === "About us")
      const KeyFeaturesData = ResponseJsonData.filter((item: { type: string }) => item.type === "key-features")
      this.setState({
        AboutUsData: AboutData,
        KeyFeatures: KeyFeaturesData,
        loading: false
      })
    }
    if (apiRequestCallId === this.ViewDemoApiID) {
      this.setState({
        ViewDemoData: responseJson.data[0].attributes.demo_video,
        loading: false
      })
    }
    if (apiRequestCallId === this.FaqApiCallId) {
      let ResponseJsonData = responseJson.data?.map((item: { attributes: { question: string, response: string } }) => {
        return {
          question: item.attributes.question,
          response: item.attributes.response
        }
      })
      this.setState({
        FaqData: ResponseJsonData,
        loading: false
      })
    }
    if (apiRequestCallId === this.ContactUsApiID) {
      if (responseJson.errors ?? responseJson.error) {
        toast.warning(responseJson.errors[0].contact[0])
      } else {
        this.setState({
          GetInTouchModel: false,
          Fullname: "",
          company: "",
          email: "",
          ContactNumber: this.state.DailCode,
          message: "",
          subject: "",
          FormReinitialize: true,
        })
        toast.success("Query Successfully submitted")
      }
    }
    if (apiRequestCallId === this.FooterApiId) {
      let ResposneData = responseJson.data?.map((item: {
        attributes: {
          address: string, description: string, email: string, phone_number: string
        }, id: string
      }) => {
        return {
          email: item.attributes.email,
          phone: item.attributes.phone_number,
          description: item.attributes.description,
          address: item.attributes.address,
          id: item.id
        }
      })
      this.setState({
        FooterAndContactUsdata: ResposneData
      })

    }
  }

  async componentDidMount() {
    this.LandingPageFirstFuncation();
    this.LandingPageSecondFuncation();
    this.ViewDemoApiFuncation();
    this.AboutUsAndKeyFetureApiFuncation();
    this.FooterApiFuncation();
    this.FaqsApiFuncation();
    this.scrollToAnchor();
  }
  componentDidUpdate() {
    this.scrollToAnchor();
  }

  scrollToAnchor() {
    const { hash } = window.location;
    if (hash) { setTimeout(() => {const element = document.getElementById(hash.substring(1)); if (element) {element.scrollIntoView({ behavior: 'smooth', block: 'start' });}}, 400);}
  }

  OpenViewDemo = () => {
    const newUrl = window.location.href.split('#')[0];
    window.history.replaceState(null, '', newUrl);
    this.setState({
      ViewDemoModel: !this.state.ViewDemoModel
    })
  }

  OpenGetInTouchModel = () => {
    const newUrl = window.location.href.split('#')[0];
          window.history.replaceState(null, '', newUrl);
    this.setState({
      GetInTouchModel: !this.state.GetInTouchModel
    })
  }

  SetActiveButton = (index: number) => {
    const newUrl = window.location.href.split('#')[0];
          window.history.replaceState(null, '', newUrl);
    this.setState({
      ActiveButton: index
    })
  }

  NavigateToFaq = () => {
    this.props.navigation.navigate("Faq")
  }

  AccordionOnChange = (index: number) => {
    const newUrl = window.location.href.split('#')[0];
          window.history.replaceState(null, '', newUrl);
    this.setState({
      OpenAccoudin: Number(index) === Number(this.state.OpenAccoudin) ? -1 : Number(index),
    })
  }

  TextfieldChnage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void) => {
    const existingIndex = this.state.formData.findIndex((item: { key: string }) => item.key === e.target.name);
    let key = e.target.name
    let value = e.target.value
    setFieldValue(`${e.target.name}`, e.target.value)
    if (existingIndex !== -1) {
      const newData = [...this.state.formData];
      newData[existingIndex] = { key, value };
      this.setState({
        ...this.state, formData: newData,
        [key]: value
      })
    } else {
      this.setState({
        ...this.state, formData: [...this.state.formData, { key, value }],
        [key]: value
      })
    }
  }

  PhoneInputChange = (number: number | string, dailcode: string, setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void) => {
    if(number == dailcode){
    setFieldValue(`ContactNumber`, "")
    }else{
      setFieldValue(`ContactNumber`, `${number}`)
    }
    const newUrl = window.location.href.split('#')[0];
    window.history.replaceState(null, '', newUrl);
    const existingIndex = this.state.formData.findIndex((item: { key: string }) => item.key === "ContactNumber");
    let key = "ContactNumber"
    let value = `${number}`
    if (existingIndex !== -1) {
      const newData = [...this.state.formData];
      newData[existingIndex] = { key, value };
      this.setState({
        formData: newData,
        DailCode: dailcode
      })
    } else {
      this.setState({
        ...this.state, formData: [...this.state.formData, { key, value }],
        DailCode: dailcode
      })
    }
  }

  LandingPageFirstApi: string = "";

  LandingPageFirstFuncation = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };
    this.setState({
      loading: true
    })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.LandingPageFirstApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.LandPageApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.LandPageGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  LandingPageSecondApi: string = "";

  LandingPageSecondFuncation = () => {
    const header = {
      "Content-Type": "application/json",
    };
    this.setState({
      loading: true
    })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.LandingPageSecondApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.LandPageCountApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.LandPageCountGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  ViewDemoApiID: string = "";

  ViewDemoApiFuncation = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    this.setState({
      loading: true
    })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ViewDemoApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ViewDemoMethodEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.ViewDemoMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  AboutUsAndKeyFetureApiId: string = "";

  AboutUsAndKeyFetureApiFuncation = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };
    this.setState({
      loading: true
    })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.AboutUsAndKeyFetureApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AboutUsAndKeyFetureApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.AboutUsAndKeyFetureMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  FaqApiCallId: string = "";

  FaqsApiFuncation = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };
    this.setState({
      loading: true
    })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.FaqApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.InteractiveFaqsApiEndPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.InteractiveFaqsMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  ContactUsApiID: string = ""

  ContactUsApiFuncation = async (values: {
    Fullname: string,
    company: string,
    email: string,
    ContactNumber: string,
    message: string,
    subject: string
  }) => {
    const header = {
      "Content-Type": "application/json",
    };
    
    const { Fullname, company, email, ContactNumber, message, subject } = values

    let phone = `+${this.state.DailCode} ${ContactNumber.slice(
      this.state.DailCode.length
    )}`

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ContactUsApiID = requestMessage.messageId;
    let Body = {
      "data": {
        "name": Fullname,
        "email": email,
        "phone_number": `${phone}`,
        "description": message,
        "subject": subject,
        "company": company
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ContactUsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.ContactUsApiMethod_POST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    values.Fullname="",
    values.email=""
    values.company=""
    values.message=""
    values.subject=""
    values.ContactNumber=""
  };

  FooterApiId: string = ""

  FooterApiFuncation = async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.FooterApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FooterAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.FooterApiMethod_GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

}
// Customizable Area End
