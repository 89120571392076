import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "framework/src/Utilities";
import { toast } from "react-toastify";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  buttonArr: string[];
  selectedButton: string;
  regionSelect: any;
  historyButton: string[];
  historySelectedButton: string;
  isBuyerRole: boolean;
  loading: boolean;
  tableData: any;
  searchKey:string,
  filterDashboard: {
    pol: any;
    pod: any;
    containerType: any;
    containerSize: any;
    containerCode: any;
  };
  regionPort: Array<{
    id: number;
    port_code: string;
    port_description: string;
    port_country: string;
    port_region: string;
    enabled: boolean;
  }>;
  regionChart: Array<any>;
  selectedRegion: string;
  historicalChart: Array<{
    outflow: number;
    inflow: number;
    week: number;
    difference: number;
    total_outflow: number;
    total_inflow: number;
    total_difference: number;
    week_range: string;
  }>;
  persantageData: {
    differnce_lying: number;
    current_days_to_go: number;
    expected_dwell_days: number;
  };
  historicalWeek: Array<string>;
  inventoryWeek: Array<string>;
  masterData: Array<{
    inventory: number;
    average_dwell: number;
    week: number;
    week_range: string
  }>;
  inventoryGraph: Array<{
    inventory: number;
    average_dwell: number;
    week: number;
    week_range: string
  }>;
  rangeFilter: Array<any>;
  selectedFilter: {
    pol: any;
    pod: any;
    containerType: any;
    containerSize: any;
    containerCode: any;
  };
  isDownload: boolean;
  csvData: any;
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class InventoryhealthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start

    (async () => {
      let inventoryHealthLoginData = await getStorageData("UserRole");
      if(!inventoryHealthLoginData){
        window.location.href = "/"
      } else if(inventoryHealthLoginData == "seller"){
        window.location.href = "/SellerListing"
      }
    })()
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      buttonArr: ["1M", "6M", "1YR", "YTD"],
      selectedButton: "1M",
      regionSelect: [],
      historyButton: ["1W", "2W", "1M", "3M"],
      historySelectedButton: "1W",
      isBuyerRole: false,
      loading: true,
      tableData: [],
      filterDashboard: {
        pol: [],
        pod: [],
        containerType: [],
        containerSize: [],
        containerCode: [],
      },
      regionPort: [
        {
          id: 0,
          port_code: "",
          port_description: "",
          port_country: "",
          port_region: "",
          enabled: false,
        },
      ],
      regionChart: [],
      selectedRegion: "",
      searchKey:"",
      historicalChart: [
        {
          outflow: 2,
          inflow: 2,
          week: 1,
          difference: 0,
          total_outflow: 2,
          total_inflow: 2,
          total_difference: 0,
          week_range: "",
        },
      ],
      persantageData: {
        differnce_lying: 0,
        current_days_to_go: 0,
        expected_dwell_days: 0,
      },
      historicalWeek: ["Week 1"],
      inventoryWeek: ["Week 1"],
      masterData: [
        {
          inventory: 0,
          average_dwell: 0,
          week: 0,
          week_range: "",
        },
      ],
      inventoryGraph: [
        {
          inventory: 0,
          average_dwell: 0,
          week: 0,
          week_range: "",
        },
      ],
      rangeFilter: ["0-10"],
      selectedFilter: {
        pol: " ",
        pod: " ",
        containerType: " ",
        containerSize: " ",
        containerCode: " ",
      },
      isDownload: false,
      csvData: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    const UserRole = (await getStorageData("UserRole")) === "buyer";
    this.setState({
      isBuyerRole: UserRole,
    });
    this.handleFilterData();
    this.historicaldata(this.state.historySelectedButton);
    this.inventoryDwellDaysGraph(this.state.selectedButton);
    this.regionFilterdata();
    this.regionDwelllFilterdata("");
    this.persantagedataFunc();
  }

  getInventoryHealthdataApiCallID: string = "";
  transhipdataApiCallID: string = "";
  quntityFilterdataApiCallID: string = "";
  regionFilterdataApiCallID: string = "";
  regionDwellfilteFilterdataApiCallID: string = "";
  historicaldataApiCallID: string = "";
  historicalWeekdataApiCallID: string = "";
  inventoryDwellDaysGraphDataApiCallID: string = "";
  persantagedataApiCallID: string = "";
  filterdataApiCallID: string = "";
  relevancedataApiCallID: string = "";
  downloadCSVdataApiCallID: string = "";

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.handleHistoricalAPIResponse(responseJson, apiRequestCallId);

    this.handleInventoryResponse(responseJson, apiRequestCallId);

    this.handleFilterResponse(responseJson, apiRequestCallId);

    if (apiRequestCallId === this.persantagedataApiCallID) {
      this.setState({ persantageData: responseJson[0], loading: false });
    }

    if (apiRequestCallId === this.historicalWeekdataApiCallID) {
      let arr = responseJson.map((item: any) => {
        return `Week ${item.week}`;
      });
      this.setState({
        historicalChart: responseJson,
        loading: false,
      });
    }

    if (apiRequestCallId === this.relevancedataApiCallID) {
      this.setState({ tableData: { ...this.state.tableData, filtered_inventories: responseJson[0].filtered_inventories }, loading: false });
    }

    if (apiRequestCallId === this.quntityFilterdataApiCallID) {
      this.setState({ tableData: { ...this.state.tableData, filtered_inventories: responseJson[0].filtered_inventories }, loading: false });
    }

    if (apiRequestCallId === this.transhipdataApiCallID) {
      this.setState({ tableData: { ...this.state.tableData, filtered_inventories: responseJson[0].filtered_inventories }, loading: false });
    }

    if (apiRequestCallId === this.regionFilterdataApiCallID) {
      this.setState({
        regionSelect: responseJson.region,
        regionPort: responseJson.port,
        loading: false,
      });
    }

    if (apiRequestCallId === this.regionDwellfilteFilterdataApiCallID) {
      this.setState({ regionChart: responseJson, loading: false });
    }

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }
   hasNonEmptyArray = (obj:any) => {
    return Object.values(obj).some(value => Array.isArray(value) && value.length > 0);
  };
  filterCondition = (arr:any)=>{
    const hasanyNonEmtyArray = this.hasNonEmptyArray(this.state.filterDashboard)
        if(!hasanyNonEmtyArray){
          this.setState({ filterDashboard: arr });
        }
  }
  handleFilterResponse = (responseJson: any, apiRequestCallId: string) => {
    if (apiRequestCallId === this.filterdataApiCallID) {
      if (
        !this.state.isDownload &&
        !responseJson.message &&
        responseJson[0].filtered_inventories
      ) {
        const ranges = responseJson[0].filtered_inventories.map((item: any) =>
          Number(item.quantity)
        );
        const val = [];
        let z = Math.max(...ranges);
        for (let i = 1; i <= z; i += 10) {
          const rangeStart = i;
          const rangeEnd = Math.min(i + 10 - 1, z);
          const range = `${rangeStart}-${rangeEnd}`;
          val.push(range);
        }
        this.setState({
          tableData: responseJson[0],
          loading: false,
          rangeFilter: val,
        });
        let temp = {
          pol: responseJson[0].filtered_inventories.map(
            (val: any) => val?.load_port_c
          ),
          pod: responseJson[0].filtered_inventories.map((val: any) => val?.pod),
          containerType: responseJson[0].filtered_inventories.map(
            (val: any) => val?.container_type
          ),
          containerSize: responseJson[0].filtered_inventories.map(
            (val: any) => val?.container_size
          ),
          containerCode: responseJson[0].filtered_inventories.map(
            (val: any) => val?.container_code
          ),
        };        
        let a1 = [...new Set(temp.pol)];
        let a2 = [...new Set(temp.pod)];
        let a3 = [...new Set(temp.containerType)];
        let a4 = [...new Set(temp.containerSize)];
        let a5 = [...new Set(temp.containerCode)];
        let arr = {
          pol: a1,
          pod: a2,
          containerType: a3,
          containerSize: a4,
          containerCode: a5,
        };      
        this.filterCondition(arr)
      }
      if (this.state.isDownload) {
        this.setState({ isDownload: false, loading: false });
        const csvData = responseJson;
        let obj = csvData[0];
        let allValues = "";
        for (const rowData of csvData) {
          allValues += Object.values(rowData).join(",") + "\n";
        }
        let allk = Object.keys(obj);
        const allKeys = allk.join(",");
        const result = allKeys + "\n" + allValues;

        this.exportData(result, "DataFile.csv", "text/csv;charset=utf-8;");
      }
      if (responseJson.message === "Record not found") {
        toast.error("No Record Found Please Change Filter");
        this.setState({ loading: false });
      }
    }
  };

  handleHistoricalAPIResponse = (
    responseJson: any,
    apiRequestCallId: string
  ) => {
    if (apiRequestCallId === this.historicaldataApiCallID) {
      this.setState({ historicalChart: responseJson, loading: false });
      if (this.state.historySelectedButton === "1W") {
        let temp = responseJson.map((item: any) => {
          return `Week ${item.week}`;
        });
        this.setState({ historicalWeek: temp });
      }
      if (this.state.historySelectedButton === "2W") {
        let temp = responseJson.map((item: any) => `Week ${item.week}`);
        this.setState({ historicalWeek: temp });
      }
      if (this.state.historySelectedButton === "1M") {
        let temp = responseJson.map((item: any) => {
          return `Week ${item.week}`;
        });
        this.setState({ historicalWeek: temp });
      }
      if (this.state.historySelectedButton === "3M") {
        let temp = responseJson.map((item: any) => `Week ${item.week}`);
        this.setState({ historicalWeek: temp });
      }
    }
  };

  handleInventoryResponse = (responseJson: any, apiRequestCallId: string) => {
    if (apiRequestCallId === this.inventoryDwellDaysGraphDataApiCallID) {
      this.setState({ inventoryGraph: responseJson, masterData:responseJson, loading: false });
      if (this.state.selectedButton === "1M") {
        let temp = responseJson.map((item: any) => `Week ${item.week}`);
        this.setState({ inventoryWeek: temp });
      }
      if (this.state.selectedButton === "6M") {
        let temp = responseJson.map((item: any) => {return `Week ${item.week}`});
        this.setState({ inventoryWeek: temp });
      }
      if (this.state.selectedButton === "1YR") {
        let temp = responseJson.map((item: any) => `Week ${item.week}`);
        this.setState({ inventoryWeek: temp });
      }
      if (this.state.selectedButton === "YTD") {
        let temp = responseJson.map((item: any) => {return `Week ${item.week}`});
        this.setState({ inventoryWeek: temp });
      }
    }
  };

  getInventoryHealthdata = async () => {
    const token = await getStorageData("authToken");
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInventoryHealthdataApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_inventoryhealth/inventory_healths"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  transhipFilter = async (val: string) => {
    const token = await getStorageData("authToken");
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.transhipdataApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_inventoryhealth/purpose_c_filter?purpose_c=${val}&pod=${this.state.selectedFilter.pod}&pol=${this.state.selectedFilter.pol}&container_code=${this.state.selectedFilter.containerCode}&length_q=${this.state.selectedFilter.containerSize}&container_type=${this.state.selectedFilter.containerType}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  QuntityFilter = async (range: any) => {
    const token = await getStorageData("authToken");
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.quntityFilterdataApiCallID = requestMessage.messageId;
    let RangeData= range.split('-')
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_inventoryhealth/quantity_filter?count_quantity=[${RangeData[0]},${RangeData[1]}]&pol=${this.state.selectedFilter.pol}&pod=${this.state.selectedFilter.pod}&container_code=${this.state.selectedFilter.containerCode}&length_q=${this.state.selectedFilter.containerSize}&container_type=${this.state.selectedFilter.containerType}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  regionFilterdata = async () => {
    const token = await getStorageData("authToken");
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.regionFilterdataApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_inventoryhealth/region_filter"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  regionDwelllFilterdata = async (port: string) => {
    const token = await getStorageData("authToken");
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.regionDwellfilteFilterdataApiCallID = requestMessage.messageId;



    if(this.state.searchKey==''){
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_inventoryhealth/region_dwell_filter?disc_port1_c=${port}&pod=${this.state.selectedFilter.pod}&pol=${this.state.selectedFilter.pol}&container_code=${this.state.selectedFilter.containerCode}&length_q=${this.state.selectedFilter.containerSize}&container_type=${this.state.selectedFilter.containerType}`
      );
       }
 
       if(this.state.searchKey=='pol'){
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_inventoryhealth/region_dwell_filter?disc_port1_c=${port}&pol=${this.state.selectedFilter.pol}`
        );
         }
     
         if(this.state.searchKey=='pod'){
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_inventoryhealth/region_dwell_filter?disc_port1_c=${port}&pod=${this.state.selectedFilter.pod}`
          );
           }
     
           if(this.state.searchKey=='code'){
              requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `bx_block_inventoryhealth/region_dwell_filter?disc_port1_c=${port}&container_code=${this.state.selectedFilter.containerCode}}`
              );
               }
             if(this.state.searchKey=='size'){            
                    requestMessage.addData(
                      getName(MessageEnum.RestAPIResponceEndPointMessage),
                      `bx_block_inventoryhealth/region_dwell_filter?disc_port1_c=${port}&length_q=${this.state.selectedFilter.containerSize}`
                    );
             }
     
             if(this.state.searchKey=='type'){
              requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `bx_block_inventoryhealth/region_dwell_filter?disc_port1_c=${port}&container_type=${this.state.selectedFilter.containerType}`
              );             
           }



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  historicaldata = async (val: string) => {
    const token = await getStorageData("authToken");
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.historicaldataApiCallID = requestMessage.messageId;

    if(this.state.searchKey==''){
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_inventoryhealth/historical_filter?duration=${val}&pol=${this.state.selectedFilter.pol}&pod=${this.state.selectedFilter.pod}&container_code=${this.state.selectedFilter.containerCode}&length_q=${this.state.selectedFilter.containerSize}&container_type=${this.state.selectedFilter.containerType}`
     );
      }

      if(this.state.searchKey=='pol'){
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_inventoryhealth/historical_filter?duration=${val}&pol=${this.state.selectedFilter.pol}`
         );
        }
    
        if(this.state.searchKey=='pod'){
          requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_inventoryhealth/historical_filter?duration=${val}&pod=${this.state.selectedFilter.pod}`
           );
          }
    
          if(this.state.searchKey=='code'){      
          requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
         `bx_block_inventoryhealth/historical_filter?duration=${val}&container_code=${this.state.selectedFilter.containerCode}`
          );
            }
            if(this.state.searchKey=='size'){
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_inventoryhealth/historical_filter?duration=${val}&length_q=${this.state.selectedFilter.containerSize}`
            ); 
            }
    
            if(this.state.searchKey=='type'){
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_inventoryhealth/historical_filter?duration=${val}&container_type=${this.state.selectedFilter.containerType}`
            );
            }



    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleWeekData = async (val: string) => {
    const token = await getStorageData("authToken");
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const newVal = this.state.historySelectedButton == "3M" ?  val : parseInt(val) + 8 + "W";

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.historicalWeekdataApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_inventoryhealth/weekly_filter_api?duration=${newVal}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  inventoryDwellDaysGraph = async (val: string) => {
    const token = await getStorageData("authToken");
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const typeOfGet = "GET";
    
    let endpoint = `bx_block_inventoryhealth/inventory_dwell_days_graph?duration=${val}&pol=${this.state.selectedFilter.pol}&pod=${this.state.selectedFilter.pod}&container_code=${this.state.selectedFilter.containerCode}&length_q=${this.state.selectedFilter.containerSize}&container_type=${this.state.selectedFilter.containerType}`


    if(this.state.searchKey==''){
       endpoint = `bx_block_inventoryhealth/inventory_dwell_days_graph?duration=${val}&duration=${val}&pol=${this.state.selectedFilter.pol}&pod=${this.state.selectedFilter.pod}&container_code=${this.state.selectedFilter.containerCode}&length_q=${this.state.selectedFilter.containerSize}&container_type=${this.state.selectedFilter.containerType}`
        }
  
        if(this.state.searchKey=='pol'){  
          endpoint = `bx_block_inventoryhealth/inventory_dwell_days_graph?duration=${val}&pol=${this.state.selectedFilter.pol}`
          }
      
          if(this.state.searchKey=='pod'){
            endpoint = `bx_block_inventoryhealth/inventory_dwell_days_graph?duration=${val}&pod=${this.state.selectedFilter.pod}`
            }
      
            if(this.state.searchKey=='code'){
              endpoint = `bx_block_inventoryhealth/inventory_dwell_days_graph?duration=${val}&container_code=${this.state.selectedFilter.containerCode}`
              }
              if(this.state.searchKey=='size'){
                endpoint = `bx_block_inventoryhealth/inventory_dwell_days_graph?duration=${val}&length_q=${this.state.selectedFilter.containerSize}`
              }
      
              if(this.state.searchKey=='type'){             
                endpoint = `bx_block_inventoryhealth/inventory_dwell_days_graph?duration=${val}&container_type=${this.state.selectedFilter.containerType}`              
            }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.inventoryDwellDaysGraphDataApiCallID = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      typeOfGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  persantagedataFunc = async () => {
    const token = await getStorageData("authToken");
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.persantagedataApiCallID = requestMessage.messageId;

    if(this.state.searchKey==''){       
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_inventoryhealth/percentage_api?pol=${this.state.selectedFilter.pol}&pod=${this.state.selectedFilter.pod}&container_code=${this.state.selectedFilter.containerCode}&length_q=${this.state.selectedFilter.containerSize}&container_type=${this.state.selectedFilter.containerType}`
          );
        }
  
        if(this.state.searchKey=='pol'){
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_inventoryhealth/percentage_api?pol=${this.state.selectedFilter.pol}`
          );
          }
      
          if(this.state.searchKey=='pod'){
      
            requestMessage.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_inventoryhealth/percentage_api?pod=${this.state.selectedFilter.pod}`
            );
            }
      
            if(this.state.searchKey=='code'){
              requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_inventoryhealth/percentage_api?container_code=${this.state.selectedFilter.containerCode}`
              );
              }
              if(this.state.searchKey=='size'){
              requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_inventoryhealth/percentage_api?length_q=${this.state.selectedFilter.containerSize}`
              );
              }
      
              if(this.state.searchKey=='type'){                   
            requestMessage.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_inventoryhealth/percentage_api?container_type=${this.state.selectedFilter.containerType}`
            );
              
            }


    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header));

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFilterData = async () => {
    const token = await getStorageData("authToken");
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.filterdataApiCallID = requestMessage.messageId;

    if(this.state.searchKey==''){
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_inventoryhealth/apply_inventory_filters?pol=${this.state.selectedFilter.pol}&pod=${this.state.selectedFilter.pod}&container_code=${this.state.selectedFilter.containerCode}&length_q=${this.state.selectedFilter.containerSize}&container_type=${this.state.selectedFilter.containerType}&downloadable_csv=${this.state.isDownload}`);
      }

      if(this.state.searchKey=='pol'){
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_inventoryhealth/apply_inventory_filters?pol=${this.state.selectedFilter.pol}`);
        }
    
        if(this.state.searchKey=='pod'){
          requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_inventoryhealth/apply_inventory_filters?pod=${this.state.selectedFilter.pod}`);
          }
    
          if(this.state.searchKey=='code'){
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
              `bx_block_inventoryhealth/apply_inventory_filters?container_code=${this.state.selectedFilter.containerCode}`);
            }
            if(this.state.searchKey=='size'){
              requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
              `bx_block_inventoryhealth/apply_inventory_filters?length_q=${this.state.selectedFilter.containerSize}`);
            }
    
            if(this.state.searchKey=='type'){
              requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
              `bx_block_inventoryhealth/apply_inventory_filters?container_type=${this.state.selectedFilter.containerType}`);
            }
            setTimeout(() => {
              this.historicaldata(this.state.historySelectedButton);
              this.inventoryDwellDaysGraph(this.state.selectedButton);
              this.regionFilterdata();
              this.regionDwelllFilterdata("");
              this.persantagedataFunc();
            }, 100);
      

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  relevanceFilter = async () => {
    const token = await getStorageData("authToken");
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.relevancedataApiCallID = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_inventoryhealth/relevance_filter?pol=${this.state.selectedFilter.pol}&pod=${this.state.selectedFilter.pod}&container_code=${this.state.selectedFilter.containerCode}&length_q=${this.state.selectedFilter.containerSize}&container_type=${this.state.selectedFilter.containerType}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleDownload = () => {
    this.setState({ isDownload: true });
    this.handleFilterData();
  };

  exportData = (data: any, fileName: string, type: string) => {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  handleNavigation = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "BuyersRequest"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleChangeFilter = (val: any, key: string) => {
    if (key === "pol") {
      this.setState({
        selectedFilter: { ...this.state.selectedFilter, pol: val },searchKey:key
      });
    }
    if (key === "pod") {
      this.setState({
        selectedFilter: { ...this.state.selectedFilter, pod: val },searchKey:key
      });
    }
    if (key === "type") {
      this.setState({
        selectedFilter: { ...this.state.selectedFilter, containerType: val },searchKey:key
      });
    }
    if (key === "size") {
      this.setState({
        selectedFilter: { ...this.state.selectedFilter, containerSize: val },searchKey:key
      });
    }
    if (key === "code") {
      this.setState({
        selectedFilter: { ...this.state.selectedFilter, containerCode: val },searchKey:key
      });
    }
    setTimeout(() => {
      this.handleFilterData();
      this.historicaldata(this.state.historySelectedButton);
      this.inventoryDwellDaysGraph(this.state.selectedButton);
      this.regionFilterdata();
      this.regionDwelllFilterdata("");
      this.persantagedataFunc();
    }, 100);
  };

  handlePurposeFilter = (val: any) => {
    if (val === "Transhipment") {
      this.transhipFilter("T");
    }
    if (val === "Import") {
      this.transhipFilter("I");
    }
    if (val === "Export") {
      this.transhipFilter("X");
    }
  };

  handleRegionFilter = (val: string) => {    
    this.setState({ selectedRegion: val });
    let port = this.state.regionPort?.filter(
      (item: any) => item.port_region === val
    );
    this.regionDwelllFilterdata(port?.[0]?.port_code);
  };

  handleWeekFilter = (val: string) => {
    this.handleWeekData(`${val.split(" ")[1]}W`);
    
  };

  handleWeekChange = (val: string) => {
    const temp = this.state.masterData.filter(item => `Week ${item.week}` === val)
    this.setState({ inventoryGraph: temp })
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleButtonClick = (button: string) => {
    this.inventoryDwellDaysGraph(button);
    this.setState({
      selectedButton: button,
    });
  };
  handleButtonClickHistory = (button: string) => {
    this.historicaldata(button);
    this.setState({
      historySelectedButton: button,
    });
  };

  handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ regionSelect: event.target.value as string });
  };

  getWeekVal = () => this.state.historicalChart.map((item) => {
      if(this.state.historySelectedButton == "3M"){ return `Week ${item.week}\n${moment(item.week_range.slice(0,10)).format("D MMM")}-${moment(item.week_range.slice(13,24)).format("D MMM")}`;}
      return `Week ${item.week >= 9 ? item.week - 8 : item.week}\n${moment(item.week_range.slice(0,10)).format(
        "D MMM"
      )}-${moment(item.week_range.slice(13,24)).format("D MMM")}`;
    });
  

  // Customizable Area End
}
