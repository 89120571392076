// Customizable Area Start
import React from 'react'
import { Button, ButtonProps } from "@material-ui/core";
import { styled } from "@material-ui/styles";

interface ButtonTypes {
    textalign?: string,
    space?: string,
    shape?: string,
    bgcolor?: string,
    variant?: string,
    fontweight?: string,
    HoverColor?: string,
    ButtonDefault?: ButtonProps,
    TextColor?: string,
    BorderColor?: string,
    MarginFalse?: boolean
}

const Outlined = styled(Button)((props: ButtonTypes) => ({
    textTransform: "capitalize",
    ...(typeof props.textalign === "string" && {
        textalign: props.textalign
    }),
    ...(props.space === "both" && {
        marginBottom: "10px",
        marginTop: "10px"
    }),
}))

const PhoneButton = styled(Button)((props: ButtonTypes) => ({
    backgroundColor: "#EDF3F8",
    ...(props.shape !== "squre" && {
        borderRadius: "30px",
        fontSize: "20px",
        height: "60px"
    }),
    "&:hover": {
        backgroundColor: "#EDF3F8",
    },
    ...(props.shape === "squre" && {
        fontWeight: 600,
        fontSize: "14px"
    }),
    fontWeight: 600,
    margin: "4px",
}))

const ContainedButton = styled(Button)((props: ButtonTypes) => ({
    borderRadius: "30px",
    color: props.TextColor ? props.TextColor : "white",
    fontWeight: 700,
    ...(props.ButtonDefault?.size !== undefined ? {
        padding: "5px 25px",
    } : {
        padding: "15px 50px",
    }),
    ...(props.BorderColor && {
        border: `1px solid ${props.BorderColor}`
    }),
    backgroundColor: props.bgcolor === undefined ? "#1653DD" : `${props.bgcolor}`,
    ...(props.MarginFalse === undefined && {
        margin: "auto",
    }),
    "&:hover": {
        backgroundColor: typeof props.HoverColor === "string" ? props.HoverColor : "#1653DD",
    },
    ...(props.ButtonDefault?.disabled && {
        color: "black",
        backgroundColor: "lightgrey !important",
    }),
    "& .MuiButton-label": {
        fontFamily: "Asap, sans-serif",
    },
    fontFamily: "Asap, sans-serif",
}))

export { ContainedButton, Outlined, PhoneButton }
// Customizable Area End