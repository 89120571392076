// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import { toast } from "react-toastify";
import { DateRange } from "materialui-daterange-picker";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start


interface PastTransactRecordCoreDataTypesC {
  id: string;
  type: string | null | undefined;
  attributes: any;
}

type HistoryPastTransactRecordTypesC = PastTransactRecordCoreDataTypesC[];



type NullableString = string | null | unknown;


// Customizable Area End

interface S {
  // Customizable Area Start
  currentTab: number;
  activeButton: number;
  selectedButton: string;
  viewDeatilSmallBox:boolean;
  fullName:string,
  isBuyerRole: boolean;
  isPopupOpen: boolean;
  authToken:string;
  regionFilterDropdown : string[],
  destinationPortDropdown : string[],
  containerTypeDropdown : string[],
  regionFilterLoader : boolean,
  containerTypeFilterLoader : boolean,
  containerSizeDropdown : string[];
  transactionValuesShow : {
    total_transactions: number,
    total_teus: number,
    total_transaction_values : number
  };
  timeSeriesShow:any;
  timeItervalsGraphShow : string[];
  transactedPODSShow : { [key: string]: {values: string; teues: string; vessel_code :string} }[],
  userTransactionsShow : { [key: string]: {values: string; teus: string;} }[],
  pastTransactionsData:  HistoryPastTransactRecordTypesC;
  filterAPIBody: {
    content_type: "transaction_values" | "time_series" | "transacted_pods" | "user_transactions" | "past_transactions";
    global_filters: {
      region?: NullableString;
      destination_port_location?: NullableString,
      container_type?: NullableString,
      container_size?:  NullableString
    },
    content_filters: {
      interval? : string,
      container_size: string,
      container_type: string
    }
  };
  timeIntervalsGraphShowYAxis : string[],
  timeIntervalsDropdownFilter : string | unknown,
  transactedPodsValuesGraphShow : string[],
  transactedPodsTuesGraphShow : string[],
  userTransactionValuesGraphShow : string[],
  userTransactionTeusGraphShow : string[],
  userTransactionUserNamesGraphShow : string[],
  transPodsRegeionDropdownFilter : NullableString,
  transPodsVesselVoyageFilter : string | unknown | null,
  vesselVoyageDropdown: string[],
  userTransactSizeFilter : NullableString,
  userTransactTypeFilter : NullableString,
  timeIntervalDatesShow : string[],
  activeRecordIDForView : string,
  openDatePicker: boolean;
  StartDate: string,
  EndDate: string,
  RenderStart: string;
  RenderEndDate: string;
  Datefilter: {
    startDate: string;
    endDate: string
  };
  selectedViewDetails : any;
  selectedOptionAuto : string;
  weeklyDropdown : string[];
  monthlyDropdown : string[];
  page: number;
  rowsPerPage: number;
  selectedRegion : NullableString;
  selectedPortDestLocation : NullableString;
  selectedContainerType : NullableString;
  selectedContainerSize : NullableString;
  selectedTransactedPodsVesselCode : string[];
  transPodsRegeionDropdownValueCatch : NullableString,
  overViewUpdatedData : string,
  timeIntervalsWeeklyDropdownFilter : string | unknown,
  downloadCSVData: string,
  timeIntervalLoading : boolean,
  transactedPodsLoading : boolean,
  userTransactLoading : boolean,
  pastTransactLoading: boolean,
  anchorEl : HTMLElement | null,
  accordianData: string,
  bookingData: any,
  selectedBooking: any,
  
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class HistoryController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
    // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
  ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    (async () => {
      let historyLoginData = await getStorageData("loginData");
      if(!historyLoginData){
        window.location.href = "/"
      } 
    })();

    this.state = {
      currentTab: 0,
      activeButton: 1,
      selectedButton: "WEEKLY",
      viewDeatilSmallBox:false,
      fullName:'',
      isBuyerRole: false,
      isPopupOpen: false,
      authToken : "",
      regionFilterDropdown : [],
      destinationPortDropdown : [],
      regionFilterLoader : false,
      containerTypeDropdown : [],
      containerTypeFilterLoader : false,
      transactionValuesShow : {
        total_transactions: 0,
        total_teus: 0,
        total_transaction_values : 0
      },
      containerSizeDropdown: [],
      transactedPODSShow : [],
      timeSeriesShow:{},
      timeItervalsGraphShow : [],
      userTransactionsShow : [],
      pastTransactionsData: [],
      filterAPIBody: {
        content_type: "transaction_values",
        global_filters: {
          region: null,
          destination_port_location: null,
          container_type: null,
          container_size:  null
        },
        content_filters: {
          interval : "",
          container_size: "",
          container_type: ""
        }
      },
      timeIntervalsGraphShowYAxis : [],
      timeIntervalsDropdownFilter: null,
      transactedPodsTuesGraphShow : [],
      transactedPodsValuesGraphShow : [],
      userTransactionValuesGraphShow : [],
      userTransactionUserNamesGraphShow : [],
      userTransactionTeusGraphShow : [],
      transPodsRegeionDropdownFilter : null,
      vesselVoyageDropdown : [],
      transPodsVesselVoyageFilter: null,
      userTransactTypeFilter: null,
      userTransactSizeFilter: null,
      timeIntervalDatesShow : [],
      activeRecordIDForView: "",
      StartDate: "",
      openDatePicker: false,
      EndDate: "",
      RenderEndDate: "",
      RenderStart: "",
      Datefilter: {
        startDate: '',
        endDate: ""
      },
      selectedViewDetails : {},
      selectedOptionAuto : "",
      monthlyDropdown : [],
      weeklyDropdown : [],
      page: 0,
      rowsPerPage: 20,
      selectedRegion : null,
      selectedPortDestLocation : null,
      selectedContainerType : null,
      selectedContainerSize : null,
      selectedTransactedPodsVesselCode: [],
      transPodsRegeionDropdownValueCatch :  null,
      overViewUpdatedData : "",
      timeIntervalsWeeklyDropdownFilter: null,
      downloadCSVData : "",
      timeIntervalLoading: false,
      transactedPodsLoading : false,
      userTransactLoading: false,
      pastTransactLoading: false,
      anchorEl : null,
      accordianData: "",
      bookingData: [],
      selectedBooking: [],
    };

        // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const UserRole = (await getStorageData("UserRole")) === "buyer";
    this.setState({
      isBuyerRole: UserRole
    });
    let buyerLoginData = await getStorageData("loginData");
    
    let LocationToken = await getStorageData("authToken");
    let obj = JSON.parse(buyerLoginData)
    this.setState({
      fullName: obj.full_name
    });
    this.OnloadApicall(LocationToken)
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if(JSON.stringify(prevState.filterAPIBody) !== JSON.stringify(this.state.filterAPIBody)){
      let LocationToken = await getStorageData("authToken");
      this.OnloadApicall(LocationToken)
    }
  }

  OnloadApicall = (LocationToken: string) => {
    this.setState({ authToken: LocationToken });
    
    this.getRegionFiltersDropdowns();
    this.getDestinationPortFiltersDropdowns();
    this.getContainerTypeFiltersDropdowns();
    this.getContainerSizeFiltersDropdowns();
    this.getHistoryTransaction();
    this.getHistoryTimeSeries();
    this.getWeeklyFiltersDropdowns()
    // this.getTransactedPODS();
    // this.getUserTransactions();
    this.getPastTransactions();
    this.getVesselVoyageFiltersDropdowns();
    this.getOverviewUpdatedAt();
    this.getSellerBooking();
  };

  getHistoryTransactionApiCallID : string = "";
  getRegionFiltersDropdownsApiCallID : string="";
  getDestinationPortFiltersDropdownsApiCallID : string="";
  getContainerTypeFiltersDropdownsApiCallID : string="";
  getContainerSizeFiltersDropdownsApiCallID : string="";
  getHistoryTimeSeriesApiCallID : string = "";
  getTransactedPODSApiCallID : string = "";
  getUserTransactionsApiCallID : string = '';
  getPastTransactionsApiCallID : string = "";
  getVesselVoyageFiltersDropdownsApiCallID : string = "";
  getViewDetailsBookingDataApiCallID:string="";
  getMonthlyFiltersDropdownsApiCallID : string = "";
  getWeeklyFiltersDropdownsApiCallID : string ="";
  getOverviewUpdatedAtApiCallID : string = "";
  getDownloadCSVApiCallID : string = "";
  getSellerBookingDataApiCallID: string = "";
   

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    
    this.onReceiveContainerTypeFilters(apiRequestCallId, responseJson)
    this.onReceiveRegionFilters(apiRequestCallId, responseJson)
    this.onReceiveDestinationPortFilters(apiRequestCallId, responseJson)
    this.onReceiveVesselVoyageFilters(apiRequestCallId, responseJson)
    this.onReceiveContainerSizeFilters(apiRequestCallId, responseJson)
    this.onReceiveWeeklyFilters(apiRequestCallId, responseJson)
    this.onReceiveMontlyFilters(apiRequestCallId, responseJson)


    this.onReceiveTransactionsData(apiRequestCallId, responseJson)
    this.onReceiveTimeSeriesData(apiRequestCallId, responseJson)
    this.onReceiveUserTransactionsData(apiRequestCallId, responseJson)
    this.onReceiveTransactedPODSData(apiRequestCallId, responseJson)
    this.onReceivePastTransactionsData(apiRequestCallId, responseJson)
    this.onReceiveViewDetailsBokingData(apiRequestCallId, responseJson)
    this.onReceiveOverViewUpdated(apiRequestCallId, responseJson)
    this.onReceiveDownloadCsvData(apiRequestCallId, responseJson)

    if (apiRequestCallId === this.getSellerBookingDataApiCallID) {
      this.setState({ bookingData: responseJson.data});
      
    }

  }

  // API recive functions start
  
  getDropdownFilters = async (requestMessage: Message, filter_type: string) => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.authToken,
    };
      
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_dashboard/transactions/filters" + "?" + new URLSearchParams({filter_type: filter_type})
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSellerBooking = async () => {
    let LocationToken = await getStorageData("authToken");
    const header = {
      token: LocationToken, "Content-Type": "application/json",
    };

    const requestMessage = new Message(  getName(MessageEnum.RestAPIRequestMessage)  );

    this.getSellerBookingDataApiCallID = requestMessage.messageId;

    requestMessage.addData(  getName(MessageEnum.RestAPIResponceEndPointMessage),  "bx_block_listdemandpickflow/bookings"
    );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),  JSON.stringify(header)
    );

    requestMessage.addData(  getName(MessageEnum.RestAPIRequestMethodMessage), "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  isResponseJsonValid = (apiRequestCallId: string, responseJson: {[x: string]: any;}, receivedApiCallId:string, field_type: string) => {
    if(apiRequestCallId === receivedApiCallId){
      if(responseJson?.data?.[field_type]) {
        return true
      }
    }
    return false
  }

  onReceiveRegionFilters = (apiRequestCallId: string, responseJson: {data: {regions: {data: string[]}}}) => {
    if(this.isResponseJsonValid(apiRequestCallId, responseJson, this.getRegionFiltersDropdownsApiCallID, "regions")){
      const data = responseJson.data.regions.data 
      const uniqueSet = new Set(data);
      const uniqueArrayData = Array.from(uniqueSet);
      this.setState({regionFilterDropdown: uniqueArrayData})
      if(this.state.selectedRegion){
        this.setState({transPodsRegeionDropdownFilter : this.state.selectedRegion}, () => this.getTransactedPODS())
      } else {
        this.setState({transPodsRegeionDropdownFilter : uniqueArrayData[0]}, () => this.getTransactedPODS())
      }
    }
  }
  onReceiveDestinationPortFilters = (apiRequestCallId: string, responseJson: {data: {destination_port_locations: {data: string[]}}}) => {
    if(this.isResponseJsonValid(apiRequestCallId, responseJson, this.getDestinationPortFiltersDropdownsApiCallID, "destination_port_locations")){
      const uniqueSet = new Set(responseJson.data.destination_port_locations.data);
      const uniqueArrayData = Array.from(uniqueSet);
      this.setState({destinationPortDropdown: uniqueArrayData})
    }
  }

  onReceiveContainerTypeFilters = (apiRequestCallId: string, responseJson: {data: {container_types: {data: string[]}}}) => {
    if(this.isResponseJsonValid(apiRequestCallId, responseJson, this.getContainerTypeFiltersDropdownsApiCallID, "container_types")){
      const uniqueSet = new Set(responseJson.data.container_types.data);
      const uniqueArrayData = Array.from(uniqueSet);
      this.setState({containerTypeDropdown: uniqueArrayData})
      if(this.state.selectedContainerType){
        this.setState({userTransactTypeFilter : this.state.selectedContainerType}, () => this.getUserTransactions())
      } else {
        this.setState({userTransactTypeFilter : uniqueArrayData[0]}, () => this.getUserTransactions())
      }
    }
  }

  onReceiveContainerSizeFilters = (apiRequestCallId: string, responseJson: {data: {container_sizes: {data: string[]}}}) => {
    if(this.isResponseJsonValid(apiRequestCallId, responseJson, this.getContainerSizeFiltersDropdownsApiCallID, "container_sizes")){
      const uniqueSet = new Set(responseJson.data.container_sizes.data);
      const uniqueArrayData = Array.from(uniqueSet);
      this.setState({containerSizeDropdown: uniqueArrayData})
      if(this.state.selectedContainerSize){
        this.setState({userTransactSizeFilter : this.state.selectedContainerSize}, () => this.getUserTransactions())
      } else {
        this.setState({userTransactSizeFilter : uniqueArrayData[0]}, () => this.getUserTransactions())
      }
    }
  }


  onReceiveVesselVoyageFilters = (apiRequestCallId: string, responseJson: {data: {vessel_voyages: {data: string[]}}}) => {
    if(this.isResponseJsonValid(apiRequestCallId, responseJson, this.getVesselVoyageFiltersDropdownsApiCallID, "vessel_voyages")){
      const uniqueSet = new Set(responseJson.data.vessel_voyages.data);
      const uniqueArrayData = Array.from(uniqueSet);
      this.setState({vesselVoyageDropdown: uniqueArrayData})
      this.setState({transPodsVesselVoyageFilter : uniqueArrayData[0]}, () => this.getTransactedPODS())
    }
  }


  onReceiveMontlyFilters = (apiRequestCallId: string, responseJson: {data: {monthly: {data: string[]}}}) => {
    if(this.isResponseJsonValid(apiRequestCallId, responseJson, this.getMonthlyFiltersDropdownsApiCallID, "monthly")){
      const uniqueSet = new Set(responseJson.data.monthly.data);
      const uniqueArrayData = Array.from(uniqueSet);
      this.setState({monthlyDropdown: uniqueArrayData})
    }
  }

  onReceiveWeeklyFilters = (apiRequestCallId: string, responseJson:{data: {weekly: {data: string[]}}}) => {
    if(this.isResponseJsonValid(apiRequestCallId, responseJson, this.getWeeklyFiltersDropdownsApiCallID, "weekly")){
      const uniqueSet = new Set(responseJson.data.weekly.data);
      const uniqueArrayData = Array.from(uniqueSet);
      this.setState({weeklyDropdown: uniqueArrayData})
    }
  }


  onReceiveTransactionsData = (apiRequestCallId : string, responseJson : {
    errors : {[key: string] : string}[];
    error : string;
    data: {global_filters: { [key: string]: string | null },
    transaction_values: { data :   {
      total_transactions: number,
      total_teus: number,
      total_transaction_values : number
    }}}}) => {
    if(this.isResponseJsonValid(apiRequestCallId, responseJson, this.getHistoryTransactionApiCallID, "transaction_values")){
      this.setState({transactionValuesShow: responseJson.data.transaction_values.data})
    }
  }

  onReceiveTimeSeriesData = (apiRequestCallId : string, responseJson : {[x: string]: any;}) => {
    if(this.isResponseJsonValid(apiRequestCallId, responseJson, this.getHistoryTimeSeriesApiCallID, "time_series")){
      this.setState({timeSeriesShow: responseJson.data.time_series, openDatePicker : false, timeIntervalLoading : false}, () => this.getMonthlyLablesDataForTimeSeries())
    } else {
      this.setState({timeIntervalLoading : false})
      
    }
  }

  onReceivePastTransactionsData = (apiRequestCallId: string, responseJson: {[x: string]: any;}) => {
    if(this.isResponseJsonValid(apiRequestCallId, responseJson, this.getPastTransactionsApiCallID, "past_transactions")){
      this.setState({pastTransactionsData: responseJson.data.past_transactions.data, pastTransactLoading : false})
    } else {
      this.setState({pastTransactLoading : false})
    }
  }

  onReceiveTransactedPODSData = (apiRequestCallId: string, responseJson: {
    errors : {[key: string] : string}[];
    error : string;
    data: {global_filters: { [key: string]: string | null },
    transacted_pods: { data :   { [key: string]: {values: string; teues: string; vessel_code :string} }[] },
    pagination:{ [key: string]: string | number }}}) => {
    if(this.isResponseJsonValid(apiRequestCallId, responseJson, this.getTransactedPODSApiCallID, "transacted_pods")){
      this.setState({transactedPODSShow: responseJson.data.transacted_pods.data,  transactedPodsLoading: false}, () => this.getTransactedPOdsDataForGraphShow())
    } else {
      this.setState({
        transactedPodsLoading: false
      })
     
    }
  }

  onReceiveUserTransactionsData = (apiRequestCallId: string, responseJson: {
    errors : {[key: string] : string}[];
    error : string;
    data: {global_filters: { [key: string]: string | null }
        user_transactions: { data :  { [key: string]: {values: string; teus: string;} }[] } ,pagination:{ [key: string]: string | number }}}) => {
    if(this.isResponseJsonValid(apiRequestCallId, responseJson, this.getUserTransactionsApiCallID, "user_transactions")){
      this.setState({userTransactionsShow: responseJson.data.user_transactions.data, userTransactLoading : false}, () => this.getUserTransactionDataForGraphShow())
    } else {
      this.setState({
        userTransactLoading : false
      })
     
    }
  }

  onReceiveViewDetailsBokingData = (apiRequestCallId: string, responseJson: {[x: string]: any;}) => {
    if(apiRequestCallId === this.getViewDetailsBookingDataApiCallID){
      if(responseJson) {
        if(responseJson.data){
          this.setState({selectedViewDetails: responseJson.data})
              }
      }
    }
  }

  onReceiveOverViewUpdated = (apiRequestCallId: string, responseJson: {data : {global_filters : {[x: string]: string | null;}, lastest_booking_date:string}}) => {
    if(apiRequestCallId === this.getOverviewUpdatedAtApiCallID){
      if(responseJson) {
        if(responseJson.data){
          this.setState({overViewUpdatedData: responseJson.data.lastest_booking_date})
        }
      }
    }
  }


  onReceiveDownloadCsvData = (apiRequestCallId: string, responseJson: {
    errors : {[key: string] : string}[];
        data: {global_filters: { [key: string]: string | null }, data: string}}) => {
    if(apiRequestCallId === this.getDownloadCSVApiCallID){
      if(responseJson) {
        if(responseJson.data){
          if(responseJson.data.data){
            this.getDownloadCSVFILE(responseJson.data.data)
          } else {
            toast.error("Data Unavailable!")
          }
        }
            }
    }
  }





  // API recieve functions end



  getRegionFiltersDropdowns = async() => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getRegionFiltersDropdownsApiCallID = requestMessage.messageId;
    this.getDropdownFilters(requestMessage, "region")
  };

  getDestinationPortFiltersDropdowns = async() => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getDestinationPortFiltersDropdownsApiCallID = requestMessage.messageId;
    this.getDropdownFilters(requestMessage, "destination_port_location")
  };

  getContainerTypeFiltersDropdowns = async() => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getContainerTypeFiltersDropdownsApiCallID = requestMessage.messageId;
    this.getDropdownFilters(requestMessage, "container_type")
  };

  getContainerSizeFiltersDropdowns = async() => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getContainerSizeFiltersDropdownsApiCallID = requestMessage.messageId;
    this.getDropdownFilters(requestMessage, "container_size")
  };


  getVesselVoyageFiltersDropdowns= async() => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getVesselVoyageFiltersDropdownsApiCallID = requestMessage.messageId;
    this.getDropdownFilters(requestMessage, "vessel_voyage")
  };

  getMonthlyFiltersDropdowns= async() => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getMonthlyFiltersDropdownsApiCallID = requestMessage.messageId;
    this.getDropdownFilters(requestMessage, "monthly")
  };
  
  getWeeklyFiltersDropdowns= async() => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getWeeklyFiltersDropdownsApiCallID = requestMessage.messageId;
    this.getDropdownFilters(requestMessage, "weekly")
  };


  getPastTransactions = async () => {
    const header = {"Content-Type": "application/json",token: this.state.authToken,};

    this.setState({
      pastTransactLoading : true
    })

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getPastTransactionsApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_dashboard/transactions/history"
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({...this.state.filterAPIBody, content_filters: {}, content_type: "past_transactions"})
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getHistoryTransaction = async () => {
    
    const header = {
      "Content-Type": "application/json",
      token: this.state.authToken,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getHistoryTransactionApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_dashboard/transactions/history"
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({...this.state.filterAPIBody, content_filters: {}})
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getHistoryTimeSeries =  async() => {

    const header = {
      "Content-Type": "application/json",
      token: this.state.authToken,
    };
    this.setState({
      timeIntervalLoading : true
    })


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getHistoryTimeSeriesApiCallID = requestMessage.messageId;


    let urlToBeSend;


    switch(this.state.selectedButton) {
      case "DAILY":
        if(this.state.StartDate && this.state.EndDate) {
          urlToBeSend = JSON.stringify({
            ...this.state.filterAPIBody,
            content_filters: {
              interval: this.state.selectedButton.toLowerCase(),
              start_date: moment(this.state.StartDate).format("YYYY-MM-DD"),
              end_date: moment(this.state.EndDate).format("YYYY-MM-DD")
            },
            content_type: "time_series"
          });
        } else {
          urlToBeSend = JSON.stringify({
            ...this.state.filterAPIBody,content_filters: {interval: this.state.selectedButton.toLowerCase()},
            content_type: "time_series"
          });
        }
        break;
      case "MONTHLY":
        if(this.state.timeIntervalsDropdownFilter){
          urlToBeSend = JSON.stringify({
            ...this.state.filterAPIBody,
            content_filters: {
              interval: this.state.selectedButton.toLowerCase(),
              year: this.state.timeIntervalsDropdownFilter
            },
            content_type: "time_series"
          });
        } else {
          urlToBeSend = JSON.stringify({
            ...this.state.filterAPIBody,
            content_filters: {
              interval: this.state.selectedButton.toLowerCase(),
            },content_type: "time_series"
          });
        }
        break;
      default:
        if(this.state.timeIntervalsWeeklyDropdownFilter){
          urlToBeSend = JSON.stringify({
            ...this.state.filterAPIBody,
            content_filters: {
              interval: this.state.selectedButton.toLowerCase(),
              month_year: this.state.timeIntervalsWeeklyDropdownFilter
            },
            content_type: "time_series"
          });
        } else {
          urlToBeSend = JSON.stringify({
            ...this.state.filterAPIBody,
            content_filters: {interval: this.state.selectedButton.toLowerCase()}, content_type: "time_series"
          });
        }
    }
    
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_dashboard/transactions/history"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      urlToBeSend
      // JSON.stringify({...this.state.filterAPIBody, content_filters: {interval : this.state.selectedButton.toLowerCase()}, content_type: "time_series"})
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };




  FilterTransactedPODSSelect = () => {
    const { transPodsRegeionDropdownFilter, transPodsVesselVoyageFilter } = this.state
    if (!transPodsRegeionDropdownFilter) {toast.warning("Select Region Filter")} 
    else if (!transPodsVesselVoyageFilter) {toast.warning("Select Vessel Voyage Filter")
    } else {this.getTransactedPODS()
    }
  }
  

  getTransactedPODS = async () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.authToken,
    };

    this.setState({
      transactedPodsLoading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTransactedPODSApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_dashboard/transactions/history"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({...this.state.filterAPIBody, content_filters: {region : this.state.transPodsRegeionDropdownFilter, vessel_voyage : this.state.transPodsVesselVoyageFilter}, content_type: "transacted_pods"})
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };



  FilterUserTransactionsSelect = () => {
    const { userTransactSizeFilter, userTransactTypeFilter } = this.state
    if (!userTransactSizeFilter) {toast.warning("Select Container Size Filter")} 
    else if (!userTransactTypeFilter) {toast.warning("Select Container Type Filter")
    } else {this.getUserTransactions()
    }
  }


  getUserTransactions = async () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.authToken,
    };

    this.setState({
    userTransactLoading : true
    })

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserTransactionsApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_dashboard/transactions/history"
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({...this.state.filterAPIBody, content_filters: {container_size : this.state.userTransactSizeFilter, container_type: this.state.userTransactTypeFilter}, content_type: "user_transactions"})
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getCSVDataForDownload = async () => {
    const header = {"Content-Type": "application/json",token: this.state.authToken,};

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getDownloadCSVApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_dashboard/transactions/download_csv"
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({...this.state.filterAPIBody, content_filters: {}, content_type: null})
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

 
  getViewDetailsBookingData = async (itemID : string) => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getViewDetailsBookingDataApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_listdemandpickflow/bookings/${itemID}`
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };



  getOverviewUpdatedAt = async () => {
    const header = {"Content-Type": "application/json",token: this.state.authToken,};

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getOverviewUpdatedAtApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_dashboard/transactions/history"
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({...this.state.filterAPIBody, content_filters: {}, content_type: "latest_booking_date"})
    )
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleButtonClick = (button: string) => {
    this.setState({
      selectedButton: button,
      timeItervalsGraphShow : [],
      timeIntervalsGraphShowYAxis : [],
      timeIntervalDatesShow: [],
      timeIntervalsWeeklyDropdownFilter : null,
      timeIntervalsDropdownFilter: null,
      StartDate: "", EndDate: ""
    }, () => this.getHistoryTimeSeries())

    if(button === "WEEKLY"){
      this.getWeeklyFiltersDropdowns()
    } else if(button === "MONTHLY") {
      this.getMonthlyFiltersDropdowns()
    }
  }

  handleDotClick = (event : any, itemID: any) => {
    const selectedData = this.state.pastTransactionsData?.filter((item) => item.id == itemID);
    this.setState({selectedBooking: selectedData[0]});
    this.setState({
      anchorEl : event.currentTarget
    }, () => this.getViewDetailsBookingData(itemID))
  }

  handleCloseViewDetails = () => {
    this.setState({
      anchorEl :null
    });
  }


  getMonthlyLablesDataForTimeSeries = () => {
    let lables : string[] = [], values:string[] = [], startEndDate : string[] = []

    this.state.timeSeriesShow?.data?.map((KeyObject : {[key : string] : any}) => {
      const timeSeriesKeys = Object.keys(KeyObject)[0];

      if(this.state.timeSeriesShow.interval === "daily"){
        if(this.state.timeSeriesShow.grouped_by === "weeks"){
          lables.push(timeSeriesKeys);
        } else if (this.state.timeSeriesShow.grouped_by === "months") {
          lables.push(`${moment(timeSeriesKeys, "YYYY-MM").format("MMMM").toUpperCase()} ${moment(timeSeriesKeys, "YYYY-MM").format("YYYY")} `);
        } else {
          // default case show daily dates less than 28 days
          const format = "YYYY-MM-DD";
          let formatedOutput = moment(timeSeriesKeys, format).format("DD MMM YYYY");
          lables.push(formatedOutput);
        }

      } else if(this.state.timeSeriesShow.interval === "monthly") {
        lables.push(`${timeSeriesKeys.toUpperCase()} ${KeyObject[timeSeriesKeys].year}`);
      } else {
        // weekly select condition
        lables.push(timeSeriesKeys.toUpperCase());
      }

      values.push(KeyObject[timeSeriesKeys].values);
      let formatedStartDate = moment(KeyObject[timeSeriesKeys].start_date).format("DD MMM")
      let formatedEndDate = moment(KeyObject[timeSeriesKeys].end_date).format("DD MMM")
      startEndDate.push(`${formatedStartDate} - ${formatedEndDate}`)

    });
    this.setState({
      timeItervalsGraphShow : lables,
      timeIntervalsGraphShowYAxis : values,
      timeIntervalDatesShow: startEndDate,
    })
  }


  getTransactedPOdsDataForGraphShow = () => {
    let transactedValue : string[] = [], teues:string[] = [], vesselCode : string[] = []
    this.state.transactedPODSShow?.map((keyObject : { [key: string]: {values: string; teues: string; vessel_code :string} }) => {
        const transactedKeys = Object.keys(keyObject)[0];
        transactedValue.push(keyObject[transactedKeys].values);
        teues.push(keyObject[transactedKeys].teues);
        vesselCode.push(keyObject[transactedKeys].vessel_code);
    });


    this.setState({
      transactedPodsValuesGraphShow : transactedValue,
      transactedPodsTuesGraphShow : teues,
      selectedTransactedPodsVesselCode : vesselCode,
    })
  }

  getUserTransactionDataForGraphShow = () => {
    let userTransactionValue : string[] = [], teus:string[] = [], usersNames: string[] = [];
    this.state.userTransactionsShow?.map((keyObject : { [key: string]: {values: string; teus: string;} }) => {
      const usersKeys = Object.keys(keyObject)[0];
      userTransactionValue.push(keyObject[usersKeys].values);
      usersNames.push(usersKeys)
      teus.push(keyObject[usersKeys].teus);
    });

    this.setState({
      userTransactionValuesGraphShow : userTransactionValue,
      userTransactionTeusGraphShow : teus,
      userTransactionUserNamesGraphShow : usersNames,
    })
  }


  handleRegionFilter = (regionFilter: NullableString) => {
    this.setState(prevState => ({
      filterAPIBody: {
        ...prevState.filterAPIBody,
        global_filters: {
          ...prevState.filterAPIBody.global_filters,
          region: regionFilter
        }
      }
    }))
  }

  handleDestinationPortLocationFilter = (destinationPortFilter: NullableString) => {
    this.setState(prevState => ({
      filterAPIBody: {
        ...prevState.filterAPIBody,
        global_filters: {
          ...prevState.filterAPIBody.global_filters,
          destination_port_location: destinationPortFilter
        }
      }
    }))
  }

  handleContainerTypeFilter = (containerTypeFilter: NullableString ) => {
    this.setState(prevState => ({
      filterAPIBody: {
        ...prevState.filterAPIBody,
        global_filters: {
          ...prevState.filterAPIBody.global_filters,
          container_type: containerTypeFilter
        }
      }
    }))
  }

  handleContainerSizeFilter = (containerSizeFilter: NullableString) => {
    this.setState(prevState => ({
      filterAPIBody: {
        ...prevState.filterAPIBody,
        global_filters: {
          ...prevState.filterAPIBody.global_filters,
          container_size: containerSizeFilter
        }
      }
    }))
  }

  hadleViewDeatils = (itemID : string) => {
    this.setState({ 
      viewDeatilSmallBox: false, isPopupOpen: true, 
    });
  };
  handleDialogCLose = () => {
    this.setState({ isPopupOpen: false , anchorEl :null});
  }


  TogolFilter = () => {
    this.setState({
      openDatePicker: !this.state.openDatePicker
    });
  }
  SetDateFilterData = (range: DateRange) => {
    this.setState({
      RenderStart: moment(range.startDate).format("DD-MM-YYYY"), RenderEndDate: moment(range.endDate).format("DD-MM-YYYY"),
      StartDate: moment(range.startDate).format(), EndDate: moment(range.endDate).format(),
      Datefilter: { ...this.state.Datefilter, startDate: moment(range.startDate).format("YYYY-MM-DD"), endDate: moment(range.endDate).format("YYYY-MM-DD") },
    })
  }

  FilterDateSelect = () => {
    const { StartDate, EndDate } = this.state
    if (StartDate === "") {toast.warning("Select start date")} 
    else if (EndDate === "") {toast.warning("Select end date")
    } else {this.getHistoryTimeSeries()
    }
  }

  ClearFilterData = () => {
    this.setState({
      Datefilter: { ...this.state.Datefilter, startDate: "", endDate: "" },openDatePicker: false, RenderStart: "", RenderEndDate: "",
      StartDate: "", EndDate: "",}, () => {
      this.getHistoryTimeSeries()
    })
  }


  handleGlobleFiltersClear = () => {
    this.setState({
      selectedRegion : null, selectedPortDestLocation : null,selectedContainerType : null,selectedContainerSize : null,
    })
    this.handleRegionFilter(null);
    this.handleContainerTypeFilter(null);
    this.handleDestinationPortLocationFilter(null);
    this.handleContainerSizeFilter(null)
  }

  handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event: { target: { value: string; }; }) => {
    this.setState({rowsPerPage: parseInt(event.target.value, 10),page: 0,
    });
  };

  handleDownloadCSV = () => {
    this.getCSVDataForDownload()
  };


  convertToCsv = (data: string): string => {
    return data.trim();
  };



  getDownloadCSVFILE = (rawData : string) => {
    const raw_data = rawData
  
    const csvData = this.convertToCsv(raw_data);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'slotexpert.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // Customizable Area End
}
