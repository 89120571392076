// Customizable Area Start
import React, { Component, ReactNode, KeyboardEvent } from "react";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { makeStyles, styled } from "@material-ui/styles";
import { Button, Box, Toolbar, Typography, AppBar, CssBaseline, Grid, IconButton, DialogContent, Hidden, Drawer, List, Dialog, } from "@material-ui/core";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ProjectText from "./ProjectText";
import ProjectModel from "./ProjectModel";
import { ContainedButton } from "./GlobalButton";
import Contactus from "../../blocks/contactus/src/Contactus.web";
import { getStorageData } from "../../framework/src/Utilities";
const logo = require("./logo.png");
const Health = require("./health.png");
const Vessel = require("./vessel.png");
const Transactions = require("./transaction.png");
const History = require("./history.png");
const SmallUserIcon = require("./SmallUserIcon.png")
const notificationIcon = require("./notification.png")
const notificationUnreadIcon = require("./notification_unread.png")
import MenuIcon from "@material-ui/icons/Menu";
import { Message } from "../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { IBlock } from "../../framework/src/IBlock";
import { mark } from "../../blocks/notifications/src/assets";
import { Link } from "react-router-dom"
import Demorequest from "../../blocks/demorequest/src/Demorequest.web";
import { toast } from "react-toastify";


const QuetionMarkImg = require("./QuetionMarkImg.png")

interface NavbarProps extends RouteComponentProps {
  AppBarTransparent?: boolean,
  SellerPendingScreen?: boolean,
  isBuyerScreen?: boolean,
  LandingPageNavBar?: boolean,
  VesselUtilisation?: boolean,
  TabDataCount?: (range: {}) => void
}

interface NavbarState {
  ActiveButton: string,
  mobileOpen: boolean
  anchorEl: null | HTMLElement,
  open: boolean,
  OpenLogotModel: boolean
  contactUs: boolean,
  helpVideo: boolean,
  videoData: {
    video_name: string;
    title: string;id: string,
  type: string,
  attributes: {
      video_name: string,
      description: string,
      video_type: string,
      videos: 
          {
              id: number,
              url: string
          }[]
        }
  }[],
  roleName: {
    "token": string,
    "refresh_token": string,
    "id": number,
    "role_name": string,
    "full_name": string,
    "email": string,
    "full_phone_number": string,
    "organization_id": number
  },
  openNotificationBox: boolean;
  notificationData: any;
  RoleOfUser: string,
  DynamicCount: {
    listing?: string | number,
    requst?: string | number,
    offers?: string | number,
    booking?: string | number
  },
}

class Navbar extends Component<NavbarProps, NavbarState> {
  subScribedMessages: string[];
  constructor(props: NavbarProps) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      ActiveButton: "Transactions",
      mobileOpen: false,
      anchorEl: null,
      open: false,
      OpenLogotModel: false,
      contactUs: false,
      helpVideo: false,
      videoData:[],
      roleName: {
        "token": "",
        "refresh_token": "",
        "id": 0,
        "role_name": "",
        "full_name": "",
        "email": "",
        "full_phone_number": "",
        "organization_id": 0
      },
      openNotificationBox: false,
      notificationData: [],
      RoleOfUser: "",
      DynamicCount: {
        listing: "0",
        requst: "0",
        offers: "0",
        booking: "0"
      }
    };
    runEngine.attachBuildingBlock(this as unknown as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getNotificationsDataCallId) {
      if (responseJson) {
        this.setState({ notificationData: responseJson?.data })
      } else {
      }
    }
    if (apiRequestCallId === this.markAllAsReadCallId) {
      if (responseJson) {
        toast.success(responseJson?.message);
        this.getNotifications();
      }
    }
    if (apiRequestCallId === this.getSellerListingDataApiCallID) {
      if (Array.isArray(responseJson)) {
        this.setState({
          DynamicCount: {
            ...this.state.DynamicCount,
            listing: responseJson.length
          }
        }, () => {
          this.DynamicCountPass()
        })
      }
    }
    if (apiRequestCallId === this.getSellerPendigRequestataApiCallID) {
      if (Array.isArray(responseJson)) {
        this.setState({
          DynamicCount: {
            ...this.state.DynamicCount,
            requst: responseJson.length
          }
        }, () => {
          this.DynamicCountPass()
        })
      }
    }

    if (apiRequestCallId == this.helpvideoApiCallId) {
      if(responseJson.data){
        this.setState({videoData:responseJson.data})
      }else {
        
        this.setState({
          videoData:[]
        })
      }
  }

    if (apiRequestCallId === this.getSellerOfferDataApiCallID) {
      this.setState({
        DynamicCount: {
          ...this.state.DynamicCount,
          offers: `${responseJson.count}`
        }
      }, () => {
        this.DynamicCountPass()
      })
    }
    if (apiRequestCallId === this.getSellerBookingDataApiCallID) {
      this.setState({
        DynamicCount: {
          ...this.state.DynamicCount,
          booking: `${responseJson.count}`
        }
      }, () => {
        this.DynamicCountPass()
      })
    }
    if (apiRequestCallId === this.getBuyerListingDataApiCallID) {
      if (Array.isArray(responseJson)) {
        this.setState({
          DynamicCount: {
            ...this.state.DynamicCount,
            requst: `${responseJson.length}`
          }
        }, () => {
          this.DynamicCountPass()
        })
      }
    }
    if (apiRequestCallId === this.getBuyerOfferApiCallID) {
      if (Array.isArray(responseJson)) {
        this.setState({
          DynamicCount: {
            ...this.state.DynamicCount,
            offers: `${responseJson.length}`
          }
        }, () => {
          this.DynamicCountPass()
        })
      }
    }
    if (apiRequestCallId === this.getBuyerBookingDataApiCallID) {
      this.setState({
        DynamicCount: {
          ...this.state.DynamicCount,
          booking: `${responseJson.count}`
        }
      }, () => {
        this.DynamicCountPass()
      })
    }
    if (apiRequestCallId === this.GetCountDataOfAlltabs) {
      const { listing, request, offer, booking, buyer_offer } = responseJson
      const UserRole = await getStorageData("UserRole");
      this.setState({
        DynamicCount: {
          booking: `${booking}`,
          listing: `${listing}`,
          requst: `${request}`,
          offers: UserRole === "seller" ? `${offer}` : `${buyer_offer}`,
        }
      }, () => {
        this.DynamicCountPass()
      })
    }
  }

  DynamicCountPass = () => {
    if (this.props.TabDataCount) {
      this.props.TabDataCount(this.state.DynamicCount)
    }
  }


  async componentDidMount() {
    let loginData = await getStorageData("loginData");
    let AuthToken = await getStorageData("authToken");
    const UserRole = await getStorageData("UserRole");
    this.GetCountOfData()
    this.setState({
      RoleOfUser: UserRole,
    })
    let obj = JSON.parse(loginData)
    const token=obj.token
     const role_name=obj.role_name
    this.getHelpvideoList(token,role_name)
    if (AuthToken && this.props.history.location.pathname === "/SellerListing" || this.props.history.location.pathname === "/BuyersRequest") {
      this.setState({
        ActiveButton: "Transactions"
      })
    }
    if (AuthToken && this.props.history.location.pathname === "/Notifications") {
      this.setState({
        ActiveButton: "notificationIcon"
      })
    }
    if (this.props.history.location.pathname.toLowerCase() === "/userprofile") {
      this.setState({
        ActiveButton: "SmallUserIcon"
      })
    }
    if (obj) {
      this.setState({
        roleName: obj
      });
    }
    this.getNotifications();
  }

  getSellerListingDataApiCallID: string = "";
  getSellerPendigRequestataApiCallID: string = "";
  getSellerOfferDataApiCallID: string = "";
  getSellerBookingDataApiCallID: string = "";
  // buyer api calls
  getBuyerListingDataApiCallID: string = "";
  getBuyerOfferApiCallID: string = "";
  getBuyerBookingDataApiCallID: string = "";
  helpvideoApiCallId : string = "";
  // count data
  GetCountDataOfAlltabs: string = "";

  SellerAPiCalls = () => {
    this.getSellerListingData()
    this.getSellerPendingRequestData()
    this.getSellerOfferData()
    this.getSellerBooking()
  }

  BuyerApiCall = () => {
    this.getBuyerListingData()
    this.getBuyerOfferData()
    this.getBuyerBookingData()
  }

  getSellerListingData = async () => {
    let LocationToken = await getStorageData("authToken");
    const header = {
      "Content-Type": "application/json",
      token: LocationToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSellerListingDataApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_listdemandpickflow/seller_listings"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  GetCountOfData = async () => {
    let LocationToken = await getStorageData("authToken");
    const header = {
      "Content-Type": "application/json",
      token: LocationToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetCountDataOfAlltabs = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_listdemandpickflow/bookings/record_count"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSellerPendingRequestData = async () => {
    const token = await getStorageData("authToken");
    const header = { "Content-Type": "application/json", token: token, }; const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
    ); this.getSellerPendigRequestataApiCallID = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_listdemandpickflow/pending_requests"); requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)); requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET"); runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSellerOfferData = async () => {
    let LocationToken = await getStorageData("authToken");
    const header = { token: LocationToken, "Content-Type": "application/json" };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSellerOfferDataApiCallID = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_listdemandpickflow/make_an_offers");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSellerBooking = async () => {
    let LocationToken = await getStorageData("authToken");
    const header = {
      token: LocationToken, "Content-Type": "application/json",
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getSellerBookingDataApiCallID = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_listdemandpickflow/bookings"
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  //  buyer Api Calls

  getBuyerListingData = async () => {
    let LocationToken = await getStorageData("authToken");
    const header = {
      "Content-Type": "application/json", token: LocationToken,
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBuyerListingDataApiCallID = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_listdemandpickflow/pending_requests"
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBuyerOfferData = async () => {
    let LocationToken = await getStorageData("authToken");
    const header = {
      "Content-Type": "application/json",
      token: LocationToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBuyerOfferApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_listdemandpickflow/make_an_offers/buyer_offer_list"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBuyerBookingData = async () => {
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBuyerBookingDataApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_listdemandpickflow/bookings"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleClose = (keyname: string) => {
    this.setState({ open: false });
    if (keyname === "Logout") {
      this.OpenLogotModelCloseAndOpen()
    } else if (keyname === "Profile") {
      this.setState({
        ActiveButton: "notificationIcon"
      })
      this.props.history.push({
        pathname: "/UserProfile"
      })
    }
    else if (keyname === "ContactUs") {
      this.handleContactusModal()
    } else if (keyname === "HelpVideo") {
      this.handlehelpvideo()
    }
  };

  getHelpvideoList = (token: string,userType:string) => {
    let key;
    if (userType === "seller") {
      key = "Seller";
    } else if (userType === "buyer") {
      key = "Buyer";
    } 
  
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.helpvideoApiCallId = requestMessage.messageId;
    

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_demorequest/help_videos?video_type="+`${key}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleListKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({ open: false });
    }
  };
  OpenLogotModelCloseAndOpen = () => {
    this.setState({
      OpenLogotModel: !this.state.OpenLogotModel,
    })
  }
  ActiveButtonChange = (ButtonString: string) => {
    this.setState({
      ActiveButton: ButtonString
    })
    if (ButtonString === "Transactions") {
      if (this.state.RoleOfUser === "seller") {
        this.props.history.push({
          pathname: "/SellerListing"
        })
      } else {
        this.props.history.push({
          pathname: "/buyersrequest"
        })
      }
    }
    if (ButtonString === "Vessel Utilization") {
      this.props.history.push({
        pathname: "/VesselUtilisation"
      })
    }
    else if (ButtonString === "Inventory Health") {
      this.props.history.push({
        pathname: "/Inventoryhealth"
      })
    }
    else if (ButtonString === "HISTORY") {
      this.props.history.push({
        pathname: "/History"
      })
    }
  }

  OpenProfileModel = () => {
    this.setState({ open: !this.state.open, openNotificationBox: false });
  }

  handleOpenNotificationBox = () => {
    this.setState({ openNotificationBox: !this.state.openNotificationBox, open: false });
  }

  handleCloseNotificationBox = () => {
    this.setState({ openNotificationBox: false });
  }

  handleContactusModal = () => {
    this.setState({
      contactUs: true
    })
  }
  handleContactcloseModal = () => {
    this.setState({
      contactUs: false
    })
  }

  handlehelpvideo = () => {
    if(this.state.videoData.length > 0){
      this.setState({
        helpVideo: true
      })
    } else {
      toast.error("There are no help videos")
    }
    
  }
  handlehelpvideoclose = () => {
    this.setState({
      helpVideo: false
    })
  }
  NavigateLogin = () => {
    this.props.history.push({
      pathname: "/EmailAccountLogin"
    })
  }


  LogoutUserFuncation = () => {
    Object.keys(localStorage).forEach((item: string) => {
      localStorage.removeItem(item);
    })
    this.setState({
      OpenLogotModel: false
    })
    this.props.history.replace({
      pathname: '/emailaccountlogin'
    })
  }

  handleDrawerToggle = () => {
    this.setState((prevState) => ({
      mobileOpen: !prevState.mobileOpen,
    }));
  };

  getNotificationsDataCallId: string = "";
  getNotifications = async () => {
    let authToken = await getStorageData("authToken");
    const header = {
      "Content-Type": "application/json",
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotificationsDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_notifications/notifications"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  markAllAsReadCallId: string = "";
  markAllasRead = async () => {
    let authToken = await getStorageData("authToken");
    const header = {
      "Content-Type": "application/json",
      token: authToken,
    };

    const requestbody = {
      "account": {
        "is_read": true
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.markAllAsReadCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_notifications/notifications/mark_all_read"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestbody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  riderting = (type: string) => {
    switch (type) {
      case "HOME":
        return "/LandingPage#HOME"
        break;
      case "ABOUT US":
        return "/LandingPage#ABOUTUS"
        break;
      case "KEY FEATURES":
        return "/LandingPage#KEYFEATURES"
        break;
      case "HOW IT WORKS":
        return "/LandingPage#HOWITWORKS"
        break;
      case "FAQ":
        return "/LandingPage#FAQ"
        break;
      default:
        break;
    }
  };


  LogoNavigate = async () => {
    let AuthToken = await getStorageData("authToken");
    if (AuthToken === null) {
      this.props.history.push({
        pathname: "/LandingPage"
      })
    } else {

      if (this.state.RoleOfUser === "seller") {
        this.props.history.push({
          pathname: "/SellerListing"
        })
      } else {
        this.props.history.push({
          pathname: "/buyersrequest"
        })
      }
    }
  }

  timeSince(date: string) {
    var seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

  render() {
    const { mobileOpen } = this.state;
    const MenuListArray = [
      "HOME",
      "ABOUT US",
      "KEY FEATURES",
      "HOW IT WORKS",
      "FAQ",
    ];

    const SellerScren = [
      {
        Title: "Inventory Health",
        url: "/Inventoryhealth",
        Icon: <img src={Health} alt="Health" />
      }, {
        Title: "Vessel Utilization",
        url: "/VesselUtilisation",
        Icon: <img src={Vessel} alt="Vessel" />
      },
      {
        Title: "Transactions",
        url: this.state.RoleOfUser === "buyer" ? "/buyersrequest" : "/SellerListing",
        Icon: <img src={Transactions} alt="Transactions" />
      },
      {
        Title: "HISTORY",
        url: "/History",
        Icon: <img src={History} alt="History" />
      }
    ].filter((data: { Title: string }) => {
      if (this.state.RoleOfUser === "buyer") {
        return data.Title !== "Vessel Utilization"
      }
      if (this.state.RoleOfUser === "seller") {
        return data.Title !== "Inventory Health"
      } else {
        return data
      }
    })
    const AppbarStyle = styled(Box)({
      ...(this.props.AppBarTransparent === false && {
        marginBottom: "5.25rem"
      }),
      position: "relative",
      "& .NavBar": {
        position: "absolute",
      },
      "& .LoginButton": {
        marginLeft: 30
      },
      "& .navbarContainer": {
        "@media (max-width: 600px)": {
          paddingLeft: '22px'
        },
      },
      "& .MuiToolbar-gutters": {
        paddingLeft: "3.4375rem",
        paddingRight: "3.4375rem",
      },
      "& .MuiAppBar-colorPrimary": {
        ...(this.props.AppBarTransparent === true && {
          backgroundColor: "transparent"
        }),
        ...(this.props.isBuyerScreen && {
          background: "linear-gradient(90deg, rgba(255, 92, 0, 0.69) 0%, #FF5C00 100%)"
        }),
        ...(this.props.AppBarTransparent === false && !this.props.isBuyerScreen && {
          background: "linear-gradient(90deg, #3216DD 0.01%, #3CA8D6 100%)"
        }),
        boxShadow: "none",
      },
      "& .Logo": {
        cursor: 'pointer',
        margin: "2rem 0rem",
        "@media (max-width: 600px)": {
          width: '130px'
        },
      },
      "& .ButtonText": {
        color: "#fff",
        fontSize: ".875rem",
        fontWeight: "bold",
        whiteSpace: 'nowrap',
        "& .MuiButton-label": {
          fontFamily: "Asap, sans-serif",
        },
        "&:hover": {
          textDecoration: "underline",
        },
      },
      ...(this.props.AppBarTransparent === false && {
        "& .LoginButton": {
          backgroundColor: "transparent"
        }
      }),
      "& .SellerMenuContainer": {
        display: "flex", alignItems: "center",
        justifyContent: "center",
        "& .MenuIcons": {
          marginLeft: "1.925rem",
          marginRight: "1.925rem",
          "@media (max-width: 1280px)": {
            marginLeft: "1rem",
            marginRight: "1rem",
          },
          "@media (max-width: 1200px)": {
            marginLeft: "0.5rem",
            marginRight: "0.5rem",
          },
          "@media (max-width: 1150px)": {
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
          },
          "@media (max-width: 1080px)": {
            marginLeft: "0px",
            marginRight: "0px",
            fontSize: '12px'
          },
          "@media (max-width: 1000px)": {
            marginLeft: "0px",
            marginRight: "0px",
            fontSize: '10px'
          },
        },
        "& .ButtonsContainer": {
          display: "flex",
          alignItems: "center"
        }
      },
      "& .WhiteShape": {
        display: "flex",
        justifyContent: "center",
        "& .SvgContainer": {
          position: "absolute",
          bottom: "0px",
          "@media (max-width: 960px)": {
            bottom: "-5px",
          }
        }
      },
      "& .MenuContainer": {
        top: "90px !important",
        right: "80px",
        left: "unset !important",
        zIndex: 9,
        position: "absolute !important" as "absolute",
        "& .MuiPaper-rounded": {
          borderRadius: "10px"
        },
        "& .MuiList-padding": {
          padding: "0px"
        },
        "& .LogoutMenuItem": {
          "& .MuiTypography-root": {
            color: "red",
          },
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          "&:hover": {
            "& .MuiTypography-root": {
              transition: "all ease"
            }
          }
        },
        "& .ItemsOfMenu": {
          padding: "7px 15px 5px 25px",
          zIndex: 9,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#8080803b"
          }
        }
      },
      "& .MenuContainerProfile": {
        top: "90px !important",
        right: "22px",
        left: "unset !important",
        zIndex: 9,
        position: "absolute !important" as "absolute",
        "& .MuiPaper-rounded": {
          borderRadius: "10px"
        },
        "& .MuiList-padding": {
          padding: "0px"
        },
        "& .LogoutMenuItem": {
          "& .MuiTypography-root": {
            color: "red",
          },
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          "&:hover": {
            "& .MuiTypography-root": {
              transition: "all ease"
            }
          }
        },
        "& .ItemsOfMenu": {
          padding: "7px 15px 5px 25px",
          zIndex: 9,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#8080803b"
          }
        }
      },
      "& .MenuContainerResponsiveNotification": {
        top: "90px !important",
        right: "80px",
        left: "unset !important",
        zIndex: 9,
        "@media (max-width: 600px)": {
          top: "90px !important",
          left: "20px !important",
          right: "20px !important",
        },
        "& .MuiPaper-rounded": {
          borderRadius: "10px"
        },
        "& .MuiList-padding": {
          padding: "0px"
        },
        "& .LogoutMenuItem": {
          "& .MuiTypography-root": {
            color: "red",
          },
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          "&:hover": {
            "& .MuiTypography-root": {
              transition: "all ease"
            }
          }
        },
        "& .ItemsOfMenu": {
          padding: "7px 15px 5px 25px",
          zIndex: 9,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#8080803b"
          }
        }
      },
      "& .MenuContainerResponsiveProfile": {
        top: "90px !important",
        right: "80px",
        left: "unset !important",
        zIndex: 9,
        "@media (max-width: 600px)": {
          top: "90px !important",
          left: "244px !important",
          right: "40px !important",
        },
        "& .MuiPaper-rounded": {
          borderRadius: "10px"
        },
        "& .MuiList-padding": {
          padding: "0px"
        },
        "& .LogoutMenuItem": {
          "& .MuiTypography-root": {
            color: "red",
          },
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          "&:hover": {
            "& .MuiTypography-root": {
              transition: "all ease"
            }
          }
        },
        "& .ItemsOfMenu": {
          padding: "7px 15px 5px 25px",
          zIndex: 9,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#8080803b"
          }
        }
      },
      "& .SellerMenuSmallIconContainer": {
        display: "flex", justifyContent: "flex-end",
        "& .RoundButton": {
          backgroundColor: "white",
          marginLeft: "0.8rem",
          marginRight: "0.8rem",
          position: "relative",
        },
      },
    });
    
    const unreadMessages = this.state.notificationData?.filter((item: any) => item?.attributes?.is_read == false)
    return (
<>
<DialogContainer
          open={this.state.contactUs}
          onClose={this.handleContactcloseModal}
          maxWidth="lg"
        >
          <Contactus handleClose={this.handleContactcloseModal} RoleOfUser={this.state.RoleOfUser} roleName={this.state.roleName} navigation={undefined} id={"contact-us"} />
        </DialogContainer>
      <AppbarStyle>
       
        <AppBar component="nav" className="NavBar">
          <Toolbar className="navbarContainer">
            <Typography variant="h6" component="div" style={{ flexGrow: 1 }} onClick={this.LogoNavigate}>
              <img src={logo} alt="logo" className="logo" />
            </Typography>
            {this.props.LandingPageNavBar && (
              <Hidden smDown>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {MenuListArray.map((item) => (
                    <Button
                      key={item}
                      style={{ fontWeight: 800 }}
                      className="ButtonText"
                      href={this.riderting(item)}
                    >
                      {item}
                    </Button>
                  ))}
                  {this.props.LandingPageNavBar && (
                    <LoginButton
                      variant="outlined"
                      className="LoginButton"
                      key="loginButton"
                      onClick={this.NavigateLogin}
                    >
                      LOGIN
                    </LoginButton>
                  )}
                </Box>
              </Hidden>
            )}
            {
              this.props.SellerPendingScreen && (
                <Hidden smDown>
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={10} justifyContent="center" alignItems="center">
                      <Box className="SellerMenuContainer">
                        <Box className="ButtonsContainer">
                          {SellerScren.map((item: { Title: string, Icon: ReactNode, url: string }) => {
                            const UrlData = [
                              "/SellerListing",
                              "/SellerPendingRequest",
                              "/SellerPendingOffers",
                              "/SellerBookings",
                              "/BuyersRequest",
                              "/BuyersOffer",
                              "/BuyerMatches"
                            ]
                            const WhitePart = UrlData.includes(window.location.pathname)
                            return (
                              <Box key={item.Title}>
                                <Button startIcon={item.Icon} onClick={() => {
                                  this.ActiveButtonChange(item.Title)
                                }}
                                  className="ButtonText MenuIcons">
                                  {item.Title}
                                </Button>
                                {item.url.toLowerCase() == window.location.pathname.toLowerCase() && !WhitePart &&
                                  <Box className="WhiteShape">
                                    <Box className="SvgContainer">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="63" height="19" viewBox="0 0 63 19" fill="none">
                                        <path d="M63 19C63 19 56.6592 19 50.6471 12.35C44.635 5.7 38.9118 0 32.1176 0C25.3235 0 21 5.7 14.2059 12.35C7.41177 19 0 19 0 19L63 19Z" fill="#EFEFEF" />
                                      </svg>
                                    </Box>
                                  </Box>
                                }
                                {
                                  WhitePart && item.Title === "Transactions" && <Box className="WhiteShape">
                                    <Box className="SvgContainer">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="63" height="19" viewBox="0 0 63 19" fill="none">
                                        <path d="M63 19C63 19 56.6592 19 50.6471 12.35C44.635 5.7 38.9118 0 32.1176 0C25.3235 0 21 5.7 14.2059 12.35C7.41177 19 0 19 0 19L63 19Z" fill="#EFEFEF" />
                                      </svg>
                                    </Box>
                                  </Box>
                                }
                              </Box>
                            )
                          })}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={2} justifyContent="center" alignItems="center">
                      <Box className="SellerMenuSmallIconContainer">
                        <Box>
                          <IconButton className="RoundButton"
                            onClick={() => {
                              this.ActiveButtonChange("notificationIcon");
                              this.handleOpenNotificationBox();
                            }}>
                              {
                                unreadMessages?.length > 0 ?
                                <img src={notificationUnreadIcon} />
                                :
                                <img src={notificationIcon} />
                              }
                          </IconButton>
                          {this.state.ActiveButton === "notificationIcon" &&
                            <Box className="WhiteShape">
                              <Box className="SvgContainer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="63" height="19" viewBox="0 0 63 19" fill="none">
                                  <path d="M63 19C63 19 56.6592 19 50.6471 12.35C44.635 5.7 38.9118 0 32.1176 0C25.3235 0 21 5.7 14.2059 12.35C7.41177 19 0 19 0 19L63 19Z" fill="#EFEFEF" />
                                </svg>
                              </Box>
                            </Box>
                          }
                          {this.state.openNotificationBox && (
                            <Popper className="MenuContainer" open={this.state.openNotificationBox} role={undefined} transition disablePortal>
                              {({ TransitionProps, placement }) => (
                                <Grow
                                  {...TransitionProps}
                                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                >
                                  <Paper>
                                    <ClickAwayListener onClickAway={this.handleCloseNotificationBox}>
                                      <NotificationsList>
                                        <Grid container className="headSection">
                                          <Grid item lg={8}>
                                            <div>
                                              <ProjectText variant="h6" TextSize="14px" fontweight="700" textcolor="#000">NOTIFICATIONS</ProjectText>
                                            </div>
                                          </Grid>
                                          <Grid item lg={4} className="textEnd">
                                            <div className="dFlexJustifyEnd">
                                              <img src={mark} alt="" className="w20" />
                                              <ProjectText variant="h6" TextSize="14px" fontweight="700" textcolor="#1653DD" marginLeft="10px" onClick={this.markAllasRead} style={{ cursor: "pointer" }}>MARK ALL AS READ</ProjectText>
                                            </div>
                                          </Grid>
                                        </Grid>
                                        {Array.isArray(this.state.notificationData) && this.state.notificationData?.length > 0 ? this.state.notificationData?.sort((a: any, b: any) => (a < b) ? 1 : -1)?.slice(0, 3).map((elem: any, i: number) => {
                                          const timeResult = this.timeSince(elem?.attributes?.created_at)
                                          return (
                                            <Grid container className={`${elem?.attributes?.is_read ? "whiteBox" : "blueBox"}`} key={i}>
                                              <Grid item lg={8}>
                                                <div className="dFlexAlignCenter">
                                                  <div>
                                                    <div className="blueDot40"></div>
                                                  </div>
                                                  <ProjectText variant="h6" TextSize="14px" fontweight="700" textcolor="#000" style={{ textTransform: "capitalize" }}>{elem?.attributes?.contents}</ProjectText>
                                                </div>
                                              </Grid>
                                              <Grid item lg={4} className="textEnd">
                                                <ProjectText variant="h6" TextSize="14px" fontweight="700" textcolor="#797979" marginRight="30px" Upercase>{timeResult} AGO</ProjectText>
                                              </Grid>
                                            </Grid>
                                          )
                                        }) : <div className="dFlexJustifyCenter">
                                          <ProjectText variant="h6" TextSize="14px" fontweight="700" textcolor="#000">
                                            NO NOTIFICATIONS
                                          </ProjectText>
                                        </div>}
                                        {Array.isArray(this.state.notificationData) && this.state.notificationData?.length > 0 && <div className="dFlexJustifyCenter">
                                          <ProjectText variant="h6" TextSize="14px" fontweight="700" textcolor="#1653DD">
                                            <Link to="/Notifications">
                                              VIEW ALL NOTIFICATIONS
                                            </Link>
                                          </ProjectText>
                                        </div>}
                                      </NotificationsList>
                                    </ClickAwayListener>
                                  </Paper>
                                </Grow>
                              )}
                            </Popper>
                          )}
                        </Box>
                        <Box>
                          <IconButton className="RoundButton"
                            onClick={() => {
                              this.OpenProfileModel();
                              this.ActiveButtonChange("SmallUserIcon")
                            }}
                          >
                            <img src={SmallUserIcon} />
                          </IconButton>
                          {this.state.open &&
                            <Popper className="MenuContainerProfile" open={this.state.open} role={undefined} transition disablePortal>
                              {({ TransitionProps, placement }) => (
                                <Grow
                                  {...TransitionProps}
                                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                >
                                  <Paper>
                                    <ClickAwayListener onClickAway={() => this.handleClose("")}>
                                      <MenuList autoFocusItem={this.state.open} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                                        <Box className="ItemsOfMenu" onClick={() => this.handleClose("Profile")}>
                                          <ProjectText fontweight="700" Upercase TextSize="14px">
                                            Profile
                                          </ProjectText>
                                        </Box>
                                        <Box className="ItemsOfMenu" onClick={() => this.handleClose("HelpVideo")}>
                                          <ProjectText fontweight="700" Upercase TextSize="14px">
                                            HELP VIDEO
                                          </ProjectText>
                                        </Box>
                                        <Box className="ItemsOfMenu" onClick={() => this.handleClose("ContactUs")}>
                                          <ProjectText fontweight="700" Upercase TextSize="14px">
                                            Contact Us
                                          </ProjectText>
                                        </Box>
                                        <Box className="ItemsOfMenu LogoutMenuItem" onClick={() => this.handleClose("Logout")}>
                                          <ProjectText fontweight="700" Upercase TextSize="14px">
                                            Logout
                                          </ProjectText>
                                        </Box>
                                      </MenuList>
                                    </ClickAwayListener>
                                  </Paper>
                                </Grow>
                              )}
                            </Popper>
                          }
                          {this.state.ActiveButton === "SmallUserIcon" &&
                            <Box className="WhiteShape">
                              <Box className="SvgContainer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="63" height="19" viewBox="0 0 63 19" fill="none">
                                  <path d="M63 19C63 19 56.6592 19 50.6471 12.35C44.635 5.7 38.9118 0 32.1176 0C25.3235 0 21 5.7 14.2059 12.35C7.41177 19 0 19 0 19L63 19Z" fill="#EFEFEF" />
                                </svg>
                              </Box>
                            </Box>
                          }
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Hidden>
              )
            }
            <Hidden mdUp>
              {!this.props.LandingPageNavBar && (
                <Grid container justifyContent="flex-end" alignItems="flex-end">
                  <Box className="SellerMenuSmallIconContainer">
                    <Box>
                      <IconButton className="RoundButton"
                        onClick={() => {
                          this.ActiveButtonChange("notificationIcon");
                          this.handleOpenNotificationBox();
                        }}>
                         {
                                unreadMessages?.length > 0 ?
                                <img src={notificationUnreadIcon} />
                                :
                                <img src={notificationIcon} />
                              }
                      </IconButton>
                      {this.state.ActiveButton === "notificationIcon" &&
                        <Box className="WhiteShape">
                          <Box className="SvgContainer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="63" height="19" viewBox="0 0 63 19" fill="none">
                              <path d="M63 19C63 19 56.6592 19 50.6471 12.35C44.635 5.7 38.9118 0 32.1176 0C25.3235 0 21 5.7 14.2059 12.35C7.41177 19 0 19 0 19L63 19Z" fill="#EFEFEF" />
                            </svg>
                          </Box>
                        </Box>
                      }
                      {this.state.openNotificationBox && (
                        <Popper className="MenuContainerResponsiveNotification" open={this.state.openNotificationBox} role={undefined} transition disablePortal>
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                              <Paper>
                                <ClickAwayListener onClickAway={this.handleCloseNotificationBox}>
                                  <NotificationsList>
                                    <Grid container className="headSection">
                                      <Grid item lg={8}>
                                        <div>
                                          <ProjectText variant="h6" TextSize="14px" fontweight="700" textcolor="#000">NOTIFICATIONS</ProjectText>
                                        </div>
                                      </Grid>
                                      <Grid item lg={4} className="textEnd">
                                        <div className="dFlexJustifyEnd">
                                          <img src={mark} alt="" className="w20" />
                                          <ProjectText variant="h6" TextSize="14px" fontweight="700" textcolor="#1653DD" marginLeft="10px">MARK ALL AS READ</ProjectText>
                                        </div>
                                      </Grid>
                                    </Grid>
                                    {this.state.notificationData.length > 0 ? this.state.notificationData?.slice(0, 3).map((elem: any, i: number) => {
                                      return (
                                        <Grid container className="whiteBox" key={i}>
                                          <Grid item lg={8}>
                                            <div className="dFlexAlignCenter">
                                              <div className="blueDot40"></div>
                                              <ProjectText variant="h6" TextSize="14px" fontweight="700" textcolor="#000">{elem?.attributes?.contents}</ProjectText>
                                            </div>
                                          </Grid>
                                          {/* <Grid item lg={4} className="textEnd">
                                              <ProjectText variant="h6" TextSize="14px" fontweight="700" textcolor="#797979" marginRight="30px" Upercase>1 min ago</ProjectText>
                                            </Grid> */}
                                        </Grid>
                                      )
                                    }) : <div className="dFlexJustifyCenter">
                                      <ProjectText variant="h6" TextSize="14px" fontweight="700" textcolor="#000">
                                        NO NOTIFICATIONS
                                      </ProjectText>
                                    </div>}
                                    {this.state.notificationData.length > 0 && <div className="dFlexJustifyCenter">
                                      <ProjectText variant="h6" TextSize="14px" fontweight="700" textcolor="#1653DD">
                                        <Link to="/Notifications">
                                          VIEW ALL NOTIFICATIONS
                                        </Link>
                                      </ProjectText>
                                    </div>}
                                  </NotificationsList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      )}
                    </Box>
                    <Box>
                      <IconButton className="RoundButton"
                        onClick={() => {
                          this.OpenProfileModel();
                          this.ActiveButtonChange("SmallUserIcon")
                        }}
                      >
                        <img src={SmallUserIcon} />
                      </IconButton>
                      {this.state.open &&
                        <Popper className="MenuContainerResponsiveProfile" open={this.state.open} role={undefined} transition disablePortal>
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                              <Paper>
                                <ClickAwayListener onClickAway={() => this.handleClose("")}>
                                  <MenuList autoFocusItem={this.state.open} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                                    <Box className="ItemsOfMenu" onClick={() => this.handleClose("Profile")}>
                                      <ProjectText fontweight="700" Upercase TextSize="14px">
                                        Profile
                                      </ProjectText>
                                    </Box>
                                    <Box className="ItemsOfMenu" onClick={() => this.handleClose("HelpVideo")}>
                                      <ProjectText fontweight="700" Upercase TextSize="14px">
                                        HELP VIDEO
                                      </ProjectText>
                                    </Box>
                                    <Box className="ItemsOfMenu" onClick={() => this.handleClose("ContactUs")}>
                                      <ProjectText fontweight="700" Upercase TextSize="14px">
                                        Contact Us
                                      </ProjectText>
                                    </Box>
                                    <Box className="ItemsOfMenu LogoutMenuItem" onClick={() => this.handleClose("Logout")}>
                                      <ProjectText fontweight="700" Upercase TextSize="14px">
                                        Logout
                                      </ProjectText>
                                    </Box>
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      }
                      {this.state.ActiveButton === "SmallUserIcon" &&
                        <Box className="WhiteShape">
                          <Box className="SvgContainer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="63" height="19" viewBox="0 0 63 19" fill="none">
                              <path d="M63 19C63 19 56.6592 19 50.6471 12.35C44.635 5.7 38.9118 0 32.1176 0C25.3235 0 21 5.7 14.2059 12.35C7.41177 19 0 19 0 19L63 19Z" fill="#EFEFEF" />
                            </svg>
                          </Box>
                        </Box>
                      }
                    </Box>
                  </Box>
                </Grid>
              )}
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={this.handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          </Toolbar>
        </AppBar>
        <Hidden mdUp smDown implementation="css">
          <Drawer
            variant="temporary"
            anchor="top"
            open={mobileOpen}
            onClose={this.handleDrawerToggle}
          >
            <List style={{ background: this.props.isBuyerScreen ? 'linear-gradient(90deg, rgba(255, 92, 0, 0.69) 0%, #FF5C00 100%)' : this.props.SellerPendingScreen ? 'linear-gradient(90deg, #3216DD 0.01%, #3CA8D6 100%)' : this.props.LandingPageNavBar ? '' : '' }}>
              {this.props.LandingPageNavBar && (
                <Box sx={{ display: "grid", flexDirection: "column" }}>
                  {MenuListArray.map((item) => (
                    <Button
                      key={item}
                      style={{ fontWeight: 800 }}
                      className="ButtonText"
                      href={this.riderting(item)}
                    >
                      {item}
                    </Button>
                  ))}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "12px",
                    }}
                  >
                    <LoginButton
                      variant="outlined"
                      className="LoginButton"
                      key="loginButton"
                      onClick={this.NavigateLogin}
                    >
                      LOGIN
                    </LoginButton>
                  </Box>
                </Box>
              )}
              {
                this.props.SellerPendingScreen && (
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item justifyContent="center" alignItems="center">
                      <Box>
                        <Box>
                          {SellerScren.map((item: { Title: string, Icon: ReactNode, url: string }) => {
                            return (
                              <Box key={item.Title}>
                                <Button startIcon={item.Icon} onClick={() => {
                                  this.ActiveButtonChange(item.Title)
                                }}
                                  style={{ color: '#ffffff', fontFamily: 'Asap, sans-serif' }}>
                                  {item.Title}
                                </Button>
                              </Box>
                            )
                          })}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                )
              }
            </List>
          </Drawer>
        </Hidden>
        <ProjectModel IconButtonBgColor="black" CloseIconSize="1rem" BorderRadius="20px" BtnColor="white" maxWidth="md" ModelTitleColor="white" TitlePadding="5px" onClose={this.OpenLogotModelCloseAndOpen} DialogContentPadding={true} fullWidth modalTitle="" aria-labelledby="customized-dialog-title" OpenModel={this.state.OpenLogotModel}>
          <DialogContent>
            <LogoutModel>
              <Box className="LogoutModelContainer">
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={12}>
                    <Box className="QuetionMarkImgClass">
                      <img src={QuetionMarkImg} alt="QuetionMarkImg" />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <ProjectText TextSize="28px" fontweight="700" align="center">
                      Logout?
                    </ProjectText>
                  </Grid>
                  <Grid item xs={12}>
                    <ProjectText TextSize="1rem" fontweight="400" align="center">
                      Are you sure you want to logout.
                    </ProjectText>
                  </Grid>
                  <Grid item xs={4} container justifyContent="flex-end">
                    <ContainedButton onClick={this.LogoutUserFuncation} HoverColor="#DB2525" MarginFalse fullWidth bgcolor="#DB2525">
                      LOGOUT
                    </ContainedButton>
                  </Grid>
                  <Grid item xs={4}>
                    <ContainedButton HoverColor="white"
                      TextColor="black" onClick={this.OpenLogotModelCloseAndOpen} BorderColor="black" MarginFalse fullWidth bgcolor="white">
                      CANCEL
                    </ContainedButton>
                  </Grid>
                </Grid>
              </Box>
            </LogoutModel>
          </DialogContent>
        </ProjectModel>
        <CssBaseline />
        <DialogContainer
          open={this.state.helpVideo}
          onClose={this.handlehelpvideoclose}
          maxWidth="lg"
        >
                    <Demorequest  videoData={this.state.videoData} handleClose={this.handlehelpvideoclose} roleName={this.state.roleName} 
          navigation={undefined} id={""} />  
        </DialogContainer>
      </AppbarStyle>
      </>
    );
  }
}

export default withRouter(Navbar);

const DialogContainer = styled(Dialog)({
  "& .MuiContainer-maxWidthLg": {
    padding: "0px !important"
  },
  "& .MuiDialog-paper": {
    borderRadius: "15px"
  }
});

const LogoutModel = styled(Box)({
  "& .LogoutModelContainer": {
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "80px",
    paddingTop: "30px"
  },
  "& .QuetionMarkImgClass": {
    display: "flex",
    justifyContent: "center"
  }
})
const LoginButton = styled(Button)({
  width: 170,
  height: 40,
  borderRadius: 45,
  gap: 8,
  color: "#FFF",
  backgroundColor: "#1653DD",
  textTransform: 'none',
  fontWeight: 600,
  "&:hover": {
    backgroundColor: "#1653DD",
  },
});

const NotificationsList = styled(Box)({
  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  minWidth: "650px",
  maxWidth: "650px",
  borderRadius: "8px",
  "@media (max-width: 768px)": {
    minWidth: "0px",
  },
  "& .headSection": {
    padding: "20px",
    justifyContent: 'space-between'
  },
  "& .textEnd": {
    textAlign: "end"
  },
  "& .dFlexJustifyEnd": {
    display: "flex",
    justifyContent: "right"
  },
  "& .w20": {
    height: "20px",
    width: "20px"
  },
  "& .dFlexAlignCenter": {
    display: "flex",
    alignItems: "center",
  },
  "& .blueDot40": {
    height: "18px",
    width: "18px",
    background: "#1653DD",
    borderRadius: "100%",
    marginRight: "15px"
  },
  "& .whiteBox": {
    background: "#fff",
    padding: "15px 20px",
    marginBottom: "4px",
  },
  "& .blueBox": {
    background: "#DCE5FA",
    padding: "15px 20px",
    marginBottom: "4px"
  },
  "& .dFlexJustifyCenter": {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "15px"
  },
  "& a": {
    textDecoration: "none",
    color: "#1653DD"
  }
})

// Customizable Area End
