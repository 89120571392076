import React from "react";
import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  styled,
  MenuItem,
  Select,
  FormControl,
  withStyles,
  InputBase,
  TableContainer,
  TableBody,
  TableRow,
  TableHead,
  Table,
  TableCell,
  MenuProps,
  // Customizable Area End
} from "@material-ui/core";
import {
  OptionForSelect,
  ProjectTextField,
} from "../../../components/src/ProjectTextField";
// Customizable Area Start
import Navbar from "../../../components/src/NavBar.web";
import Spinner from "../../../components/src/ProjectLoader";
import {
  update,
  download,
  dropdown,
  help,
  vector,
  arrowDown,
  arrowUp,
  dropup,
  whitedropdown,
} from "./assets";
import ReactECharts from "echarts-for-react";
import { ProjectText } from "../../../components/src/ProjectText";

// Customizable Area End

import InventoryhealthController, { Props } from "./InventoryhealthController";
import moment from "moment";
import { 
  BarChartBox 
} from "../../listdemandpickflow/src/VesselUtilisation.web";


const menuDropFieldProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null
}

export default class Inventoryhealth extends InventoryhealthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  valueFormatter = (value: number) => {
    return value / 1000 + "K";
  };

  position(point: any[]) {
    return [point[0], "10%"];
  }


  tooltipDesign(params: any[]) {
    let tooltipContent = "";
    params.forEach((param: { seriesName: string; value: any }) => {
      if (param.seriesName === "Inventory") {
        tooltipContent += `<div style="background-color: #1653DD; color: #fff; padding: 5px 10px;margin-bottom: 5px;border-radius:4px;font-weight:500;display:flex; justify-content:center">
        Inventory: ${param.value} Boxes</div>`;
      } else if (param.seriesName === "Average dwells") {
        tooltipContent += `<div style="background-color: #E3A917; color: #fff; padding: 5px 10px;border-radius:4px;font-weight:500">Average Dwell: ${param.value} days</div>`;
      }
    });
    return tooltipContent;
  }

  tooltipDesignSec = (params: any[]) => {
    let tooltipContent = "";
    params.forEach((param: { seriesName: string; value: any }) => {
      let bubbleColor = param.value[0] < 7 ? "#87CC5F" : "#E26A6A"; // Determine bubble color
      let tooltipBackgroundColor = bubbleColor === "#E26A6A" ? "#E26A6A" : "#87CC5F"; // Determine tooltip background color
      
      tooltipContent += `<div style="background-color:transparent">
        <div style="background-color:${tooltipBackgroundColor};color:#FFFFFF;font-family:Asap;padding:0.1rem 1rem 0.1rem 1rem;border-radius:0.4rem; font-weight:600;font-size:12px">
          <p style="margin-top:0px; text-transform:uppercase">${this.state.selectedRegion}</p>
          <p style="margin-top:-10px">Average Expected Dwell Days :${param.value[1]}</p>
          <p style="margin-top:-10px; margin-bottom:0">Days to go : ${param.value[0]}</p>
        </div>
      </div>`;
    });
    return tooltipContent;
}

renderHistoryButton = (button: any) => {
  return(
    <Button
        key={button}
        data-test-id="buttonClick2"
        onClick={() =>
          this.handleButtonClickHistory(button)
        }
        style={{
          ...webStyle.selectButton,
          backgroundColor:
            this.state.historySelectedButton ===
              button
              ? "#1653DD"
              : "#EEF2F5",
          color:
            this.state.historySelectedButton ===
              button
              ? "#FFFFFF"
              : "#797979",
          minHeight: "42px",
        }}
      >
        {button}
      </Button>
  )
}

renderRegionListItem = (value: any) => {
  return(
    <MenuItem
      key={value}
      value={value}
      onClick={() => this.handleRegionFilter(value)}
      data-test-id="handleRegionFilter"
      style={{
        ...webStyle.listItem,
        background:
          this.state.regionSelect === value
            ? "#D5E2FF"
            : "none",
      }}
    >
      {value}
    </MenuItem>
  )
}

renderBarChartButtons = (button: any) => {
  return(
    <Button
      key={button}
      data-test-id="buttonClick"
      onClick={() => this.handleButtonClick(button)}
      style={{
        ...webStyle.selectButton,
        backgroundColor:
          this.state.selectedButton === button
            ? "#1653DD"
            : "#EEF2F5",
        minHeight: "42px",
        color:
          this.state.selectedButton === button
            ? "#FFFFFF"
            : "#797979",
      }}
    >
      {button}
    </Button>
  )
}
clearFilters = () => {
  if (this.state.searchKey !== "") {
    this.setState(
      {
        selectedFilter: {
          pol: "",
          pod: "",
          containerCode: "",
          containerSize: "",
          containerType: "",
        },
      },
      () => {
        this.handleFilterData();
      }
    );
  }
};

  // Customizable Area End

  render() {

    const weeks = this.state.inventoryGraph.map((item) => {
      if(this.state.selectedButton === "1M" || this.state.inventoryGraph?.length === 1){
        return `Week ${item.week}\n${moment(item.week_range.slice(0,10)).format(
          "D MMM"
        )}-${moment(item.week_range.slice(13,24)).subtract(1, "d").format("D MMM")}\n${moment(item.week_range.slice(0,10)).format(
          "YYYY"
        )}`
      } else {
        return `${moment(item.week_range.slice(0,10)).format(
          "D MMM")}\n${moment(item.week_range.slice(0,10)).format(
            "YYYY"
          )}`
      }
    }); 
   
    const optionFirstBar = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        position: this.position,
        backgroundColor: "none",
        border: "none",
        padding: 0,
        formatter: this.tooltipDesign,
      },

      xAxis: {
        type: "category",
        data: weeks,
        axisLabel: {
          textStyle: {
            color: "#1E1E1E",
            fontFamily: "Asap",
            fontSize: 14,
            lineHeight: 14,
          },
        },
      },
      yAxis: [
        {
          type: "value",
          name: "",
          splitLine: { show: true, lineStyle: { type: "dashed" } },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#1653DD",
            },
          },
          axisLabel: {
            formatter: this.valueFormatter,
          },
          axisTick: {
            show: true,
          },
        },
        {
          type: "value",
          name: "",
          position: "right",
          splitLine: { show: true, lineStyle: { type: "dashed" } },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#E3A917",
            },
          },
          axisTick: {
            show: true,
          },
        },
      ],
      series: [
        {
          name: "Inventory",
          type: "bar",
          data: this.state.inventoryGraph?.map((val) => val.inventory),
          itemStyle: {
            color: {
              x: 0,
              y: 0,
              type: "linear",
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0, color: "#4180BE" },
                { offset: 0.5, color: "#4180BE" },
                { offset: 1, color: "#CFDEFF" },
              ],
            },
          },
        },
        {
          name: "Average dwells",
          type: "line",
          yAxisIndex: 1,
          data: this.state.inventoryGraph?.map((val) => val.average_dwell),
          lineStyle: {
            color: "#E3A917",
            width: 1,
            type: "solid",
          },
          symbol: "none",
        },
      ],
    };
    const regionVal = this.state.regionChart.map((item) => {
      return {
        value: [item.days_to_go, item.expected_dwell_days],
        itemStyle: item.days_to_go < 7 ? { color: "#CDF4B666", borderColor: "#87CC5F" } : { color: "#E26A6A", borderColor: "#E26A6A" }
      };
    });
    const option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: this.tooltipDesignSec,
      },
      title: {
        text: "",
      },
      xAxis: {},
      yAxis: {},
      grid: {
        containLabel: true,
      },
      series: [
        {
          type: "scatter",
          data: regionVal,
          symbolSize: 20,
          barWidth: "32px",
        },
      ],
    };

    let chartVal = this.state.historicalChart.map((value) => value.difference);
    let weekval = this.state.historicalChart.map((item) => {
      return `Week ${item.week >= 9 ? item.week - 8 : item.week}\n${moment(item.week_range.slice(0,10)).format(
        "D MMM"
      )}-${moment(item.week_range.slice(13,24)).format("D MMM")}`;
    });
   

    const barOption = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      xAxis: {
        type: "category",
        splitLine: { show: true, lineStyle: { type: "solid" } },
        data: weekval,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
          lineStyle: {
            color: "red",
            width: 1,
            type: "solid",
          },
        },
      },
      yAxis: {
        title: "Inflow",
        splitLine: { show: true, lineStyle: { type: "solid" } },
        type: "value",
        axisLine: {
          show: true,
        },
        axisLabel: {
          formatter: this.valueFormatter,
        },
      },
      grid: {
        containLabel: true,
      },
      series: [
        {
          type: "bar",
          data: chartVal.map((value) => ({
            value,
            itemStyle: {
              color:
                value >= 0
                  ? {
                    type: "linear",
                    x: 0, y: 0,x2: 0,y2: 1,
                    colorStops: [
                      { offset: 0, color: "#DB252547" },{ offset: 1, color: "#DB252547" },
                      { offset: 0, color: "#FF0000" },{ offset: 1, color: "#FF0000" },
                    ],
                  }
                  : {
                    type: "linear",
                    x: 0,y: 0,x2: 0,y2: 1,
                    colorStops: [
                      { offset: 1, color: "#61AE00" },{ offset: 1, color: "#61AE00" },
                      { offset: 1, color: "#296F0047" },{ offset: 0, color: "#296F0047" },
                    ],
                  },
            },
          })),
          barWidth: "32px"
        },
      ],
    };

    let sumOfInflow = 0;
    let sumOfOutflow = 0;
    let sumOfDifference = 0;

    this.state.historicalChart.forEach(entry => {
        sumOfInflow += entry.inflow;
        sumOfOutflow += entry.outflow;
        sumOfDifference += entry.difference;
    });

    

    return (
      // Customizable Area Start
      <div>
        <Navbar
          SellerPendingScreen={true}
          isBuyerScreen={this.state.isBuyerRole}
          AppBarTransparent={false}
        />
        <Spinner spinnerModal={this.state.loading} />
        <div style={webStyle.outerBox}>
          <div
            style={{
              ...webStyle.innerBox,
              padding: "0px",
            }}
          >
            <Box
              style={{
                background: "#FFF",
                padding: "25px 20px",
                margin: "0px",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid container spacing={2}>
              <CustomBox>
                <FormControl style={{ width: "100%", marginRight: "15px" }}>
                  <ProjectText style={webStyle.textFieldHeading}>POL</ProjectText>
                  <ProjectTextField
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                          maxHeight: 200
                      }
                  }
                  }}
                    variant="outlined"
                    id="POL"
                    name="POL"
                    fullWidth
                    BoderRadius="8px"
                    data-test-id="searchFilter"
                    value={this.state.selectedFilter.pol}
                    onChange={(e) =>this.handleChangeFilter(e.target.value, "pol")}
                  >
                    {this.state.filterDashboard.pol.map((value: any) => {
                      return (
                        <OptionForSelect
                          value={value}
                          key={value}
                          data-test-id="pol"
                        >
                          <ProjectText
                            variant="h6"
                            fontweight="500"
                            TextSize="14px"
                          >
                            {value}
                          </ProjectText>
                        </OptionForSelect>
                      );
                    })}
                  </ProjectTextField>
                </FormControl>
                <FormControl style={{ width: "100%", marginRight: "15px" }}>
                  <ProjectText style={webStyle.textFieldHeading}>POD</ProjectText>
                  <ProjectTextField
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                          maxHeight: 200
                      }
                  }
                  }}
                    variant="outlined"
                    id="POD"
                    name="POD"
                    fullWidth
                    BoderRadius="8px"
                    data-test-id="searchFilterPod"
                    value={this.state.selectedFilter.pod}
                    onChange={(e) =>
                      this.handleChangeFilter(e.target.value, "pod")
                    }
                  >
                    {this.state.filterDashboard.pod.map((value: any) => {
                      return (
                        <OptionForSelect
                          value={value}
                          key={value}
                          data-test-id="pod"
                        >
                          <ProjectText
                            variant="h6"
                            fontweight="500"
                            TextSize="14px"
                          >
                            {value}
                          </ProjectText>
                        </OptionForSelect>
                      );
                    })}
                  </ProjectTextField>
                </FormControl>
                <FormControl style={{ width: "100%", marginRight: "15px" }}>
                  <ProjectText style={webStyle.textFieldHeading}>
                    CONTAINER STATUS
                  </ProjectText>
                  <ProjectTextField
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null,
                      PaperProps: {
                        style: {
                            maxHeight: 200
                        }
                    }
                    }}
                    variant="outlined"
                    id="containertype"
                    name="containertype"
                    data-test-id="searchFilterType"
                    fullWidth
                    BoderRadius="8px"
                    value={this.state.selectedFilter.containerType}
                    onChange={(e) =>
                      this.handleChangeFilter(e.target.value, "type")
                    }
                  >
                    {this.state.filterDashboard.containerType.map(
                      (value: any) => {
                        return (
                          <OptionForSelect
                            value={value}
                            key={value}
                            data-test-id="containertype"
                          >
                            <ProjectText
                              variant="h6"
                              fontweight="500"
                              TextSize="14px"
                            >
                              {value}
                            </ProjectText>
                          </OptionForSelect>
                        );
                      }
                    )}
                  </ProjectTextField>
                </FormControl>
                <FormControl style={{ width: "100%", marginRight: "15px" }}>
                  <ProjectText style={webStyle.textFieldHeading}>
                    CONTAINER SIZE
                  </ProjectText>
                  <ProjectTextField
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                          maxHeight: 200
                      }
                  }
                  }}
                    variant="outlined"
                    id="containerSize"
                    name="containerSize"
                    fullWidth
                    BoderRadius="8px"
                    value={this.state.selectedFilter.containerSize}
                    onChange={(e) =>
                      this.handleChangeFilter(e.target.value, "size")
                    }
                  >
                    {this.state.filterDashboard.containerSize.map(
                      (value: any) => {
                        return (
                          <OptionForSelect
                            value={value}
                            key={value}
                            data-test-id="containerSize"
                          >
                            <ProjectText
                              variant="h6"
                              fontweight="500"
                              TextSize="14px"
                            >
                              {value}
                            </ProjectText>
                          </OptionForSelect>
                        );
                      }
                    )}
                  </ProjectTextField>
                </FormControl>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <ProjectText style={webStyle.textFieldHeading}>
                    CONTAINER TYPE
                  </ProjectText>
                  <ProjectTextField
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                          maxHeight: 200
                      }
                  }
                  }}
                    variant="outlined"
                    id="containerCode"
                    name="containerCode"
                    fullWidth
                    BoderRadius="8px"
                    value={this.state.selectedFilter.containerCode}
                    onChange={(e) =>
                      this.handleChangeFilter(e.target.value, "code")
                    }
                  >
                    {this.state.filterDashboard.containerCode.map(
                      (value: any) => {
                        return (
                          <OptionForSelect
                            value={value}
                            key={value}
                            data-test-id="containerCode"
                          >
                            <ProjectText
                              variant="h6"
                              fontweight="500"
                              TextSize="14px"
                            >
                              {value}
                            </ProjectText>
                          </OptionForSelect>
                        );
                      }
                    )}
                  </ProjectTextField>
                </FormControl>
              </CustomBox>
              </Grid>
            </Box>
          </div>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            width="100%"
            style={{ marginBottom: "1rem" }}
          >
            <ProjectText
              data-test-id="SetState"
              onClick={this.clearFilters}
              style={{
                background: "#E2E2E2",
                padding: "5px",
                marginTop: "10px",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              textcolor="#656565"
              TextSize="14px"
              fontweight="700"
            >
              Clear Filters
            </ProjectText>
          </Box>
          <div style={{ ...webStyle.innerBox, padding: "1px 30px 30px 30px" }}>
            <MainHeadingBox>
              <div>
                <div style={{ display: "flex" }}>
                  <ProjectText
                    TextSize="28px"
                    fontweight="700"
                    style={webStyle.monthly}
                  >
                    Inventory Overview
                  </ProjectText>
                  <Button
                    style={{
                      background: "#E2E2E2",
                      color: "#656565",
                      fontFamily: "Asap",
                      fontSize: "14px",
                      fontWeight: 600,
                      marginLeft: "10px",
                    }}
                  >
                    {moment().format("MMM yyyy")}
                  </Button>
                </div>
                <div style={{ display: "flex", marginTop: "5px" }}>
                  <img src={update} />
                  <ProjectText
                    TextSize="12px"
                    fontweight="500"
                    style={webStyle.udatedText}
                  >
                    Updated: {this.state.tableData?.filtered_inventories ? ` ${moment(this.state.tableData?.filtered_inventories[0]?.updated_at).format('DD MMM, HH:mm')} SGT` : ' No Date Found!'}
                  </ProjectText>
                </div>
              </div>
              <div style={webStyle.downloadBox}>
                <ProjectText
                  TextSize="14px"
                  fontweight="700"
                  data-test-id="handleDownload"
                  style={webStyle.download}
                  onClick={() => this.handleDownload()}
                >
                  Download CSV
                </ProjectText>
                <img
                  src={download}
                  style={{
                    width: "10.67px",
                    height: "10.67px",
                    marginTop: "5px",
                  }}
                />
              </div>
            </MainHeadingBox>
            <PercentBox container spacing={2}>
              <Grid item xs={12} sm={12} md={4} style={webStyle.inboundBox}>
                <ProjectText
                  fontweight="400"
                  TextSize="16px"
                  style={webStyle.percentType}
                >
                  Lying Boxes (Yard)
                </ProjectText>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  <ProjectText
                    TextSize="32px"
                    fontweight="700"
                    style={webStyle.percent}
                  >
                    {this.state.tableData?.lying_box}
                  </ProjectText>
                  <div>
                    <div style={webStyle.percentUpDown}>
                      {this.state.persantageData.differnce_lying}%{" "}
                      <img src={dropup} style={{ marginLeft: 5 }} />
                    </div>
                    <ProjectText
                      TextSize="10px"
                      fontweight="600"
                      style={webStyle.smallestHeading}
                    >
                      From last week
                    </ProjectText>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4} style={webStyle.inboundBox}>
                <ProjectText
                  fontweight="400"
                  TextSize="16px"
                  style={webStyle.percentType}
                >
                  Average Expected Dwell Days
                </ProjectText>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  <ProjectText
                    TextSize="32px"
                    fontweight="700"
                    style={webStyle.percent}
                  >
                    {this.state.tableData?.expected_dwell_days}
                  </ProjectText>
                  <div>
                    <div style={webStyle.percentUpDown}>
                      {this.state.persantageData.expected_dwell_days}%{" "}
                      <img src={dropdown} style={{ marginLeft: 5 }} />
                    </div>
                    <ProjectText
                      TextSize="10px"
                      fontweight="600"
                      style={webStyle.smallestHeading}
                    >
                      From last week
                    </ProjectText>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4} style={webStyle.outboundBox}>
                <ProjectText
                  fontweight="400"
                  TextSize="16px"
                  style={webStyle.percentType}
                >
                  Current Days To Go
                </ProjectText>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  <ProjectText
                    TextSize="32px"
                    fontweight="700"
                    style={webStyle.percent}
                  >
                    {this.state.tableData?.current_days_to_go}
                  </ProjectText>
                  <div>
                    <div style={webStyle.percentUpDownRed}>
                      {this.state.persantageData.current_days_to_go}%
                      <img src={whitedropdown} style={{ marginLeft: 5 }} />
                    </div>
                    <ProjectText
                      TextSize="10px"
                      fontweight="600"
                      style={webStyle.smallestHeading}
                    >
                      From last week
                    </ProjectText>
                  </div>
                </div>
              </Grid>
            </PercentBox>
            <div style={webStyle.mainHeadingBox}>
              <div>
                <ProjectText TextSize="28px" fontweight="700">
                  Historical Inventory & Dwell Days
                </ProjectText>
                <ProjectText
                  fontweight="500"
                  TextSize="12px"
                  style={webStyle.subHeading}
                >
                  View total inventory of boxes and number of days stayed in yard
                </ProjectText>
                <div style={{ display: "flex", marginTop: "5px" }}>
                  <img src={update} />
                  <ProjectText
                    TextSize="12px"
                    fontweight="500"
                    style={webStyle.udatedText}
                  >
                    Updated: {this.state.tableData?.filtered_inventories ? ` ${moment(this.state.tableData?.filtered_inventories[0]?.updated_at).format('DD MMM, HH:mm')} SGT` : ' No Date Found!'}
                  </ProjectText>
                </div>
              </div>
            </div>
            <BarChartBox>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  <div style={webStyle.buttonGroup}>
                    {this.state.buttonArr.map((button) => (
                      <>
                      {this.renderBarChartButtons(button)}
                      </>
                    ))}
                  </div>
                </Grid>
                <DateWeekGrid
                  item
                  xs={12}
                  sm={6}
                >
                  <Select
                    variant="outlined"
                    style={{ ...webStyle.dropdown, height: "40px" }}
                    MenuProps={menuDropFieldProps}
                  >
                    {this.state.inventoryWeek.map((val: any) => {
                      return (
                        <MenuItem
                          value={val}
                          style={webStyle.listItem}
                          key={val}
                          data-test-id="handleWeekChange"
                          onClick={() => this.handleWeekChange(val)}
                        >
                          {val}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </DateWeekGrid>
              </Grid>
              <ReactECharts
                option={optionFirstBar}
                style={{ height: "400px", marginTop: "30px" }}
              /> 
            </BarChartBox>
            <div>
              <Grid container spacing={2} style={{ marginTop: "30px" }}>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <div style={{ display: "flex" }}>
                    <HistoricalText>
                      Region
                    </HistoricalText>

                    <img
                      src={help}
                      style={{
                        marginLeft: "5px",
                        marginTop: "-4px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <ProjectText
                    TextSize="12px"
                    fontweight="500"
                    style={webStyle.subHeading}
                  >
                    View the dwell days by POD region
                  </ProjectText>
                  <Box style={webStyle.tradeBox}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <ProjectText
                        fontweight="600"
                        TextSize="14px"
                        style={webStyle.inoutText}
                      >
                        Average Expected Dwell Days
                      </ProjectText>
                      <Select
                        variant="outlined"
                        value={this.state.selectedRegion}
                        style={{
                          width: "153px",
                          ...webStyle.dropdown,
                          padding: 0,
                          background: "#E2E2E2",
                          height: "36px",
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                                maxHeight: 200,
                                maxWidth:100
                            }
                        }
                        }}  
                      >
                        {this.state.regionSelect.map(
                          (value: string, index: number) => (
                            this.renderRegionListItem(value)
                          )
                        )}
                      </Select>
                    </div>
                    <ReactECharts
                      option={option}
                      style={{ height: "400px", width: "100%" }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "-50px",
                        marginBottom: "27px",
                      }}
                    >
                      <div style={webStyle.inoutText}>Days To Go</div>
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={8}>
                  <HistoricalText
                  >
                    Historical inflow/outflow
                  </HistoricalText>
                  <ProjectText
                    TextSize="12px"
                    fontweight="500"
                    style={{ ...webStyle.subHeading, marginTop: "15px" }}
                  >
                    View inflow and outflow of boxes in your vessels
                  </ProjectText>
                  <BarChartBox style={{height:'485px'}}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={10}>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{ display: "flex", flexWrap: "wrap" }}
                          >
                            <div style={webStyle.buttonGroup}>
                              {this.state.historyButton.map((button) => (
                                <>
                                {this.renderHistoryButton(button)}
                                </>
                              ))}
                            </div>
                          </Grid>
                          <DateWeekGrid
                            item
                            xs={12}
                            sm={6}
                          >
                            <Select
                              variant="outlined"
                              MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                              }}
                              defaultValue={this.state.historicalWeek[0]}
                              style={{ ...webStyle.dropdown, height: "40px" }}
                            >
                              {this.state.historicalWeek?.map((val: any) => {
                                return (
                                  <MenuItem
                                    value={val}
                                    style={webStyle.listItem}
                                    data-test-id="handleWeekFilter"
                                    key={val}
                                    onClick={() => this.handleWeekFilter(val)}
                                  >
                                    {val}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </DateWeekGrid>
                        </Grid>

                        <ReactECharts
                          option={barOption}
                          style={{
                            height: "350px",
                            width: "124%",
                            paddingRight: "90px"
                          }}
                        />
                        <div
                          style={{
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "14px",
                              height: "14px",
                              background: "#FF0101",
                              borderRadius: "50%",
                            }}
                          />
                          <ProjectText
                            fontweight="600"
                            TextSize="14px"
                            style={webStyle.colorText}
                          >
                            Inflow
                          </ProjectText>
                          <div
                            style={{
                              width: "14px",
                              height: "14px",
                              background: "#67AD13",
                              borderRadius: "50%",
                            }}
                          />
                          <ProjectText
                            fontweight="600"
                            TextSize="14px"
                            style={webStyle.colorText}
                          >
                            Outflow
                          </ProjectText>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={2}
                      className="HistoricalGrid"
                      >
                        <div>
                          <InflowSmallBox>
                            <div className="centerText">
                              <ProjectText
                                TextSize="14px"
                                fontweight="600"
                                style={webStyle.inOutDiff}
                              >
                                Inflow
                              </ProjectText>
                              <img src={arrowDown} />
                            </div>
                            <ProjectText
                              TextSize="24px"
                              fontweight="600"
                              style={webStyle.inOutDiffNumber}
                            >
                              {
                                sumOfInflow
                              }
                            </ProjectText>
                          </InflowSmallBox>
                          <OutflowSmallBox>
                            <div className="centerText" style={{ display: "flex", marginLeft: "8px" }}>
                              <ProjectText
                                TextSize="14px"
                                fontweight="600"
                                style={webStyle.inOutDiff}
                              >
                                Outflow
                              </ProjectText>
                              <img src={arrowUp} />
                            </div>
                            <ProjectText
                              TextSize="24px"
                              fontweight="600"
                              style={webStyle.inOutDiffNumber}
                            >
                              {
                                sumOfOutflow
                              }
                            </ProjectText>
                          </OutflowSmallBox>
                          <DifferenceBox>
                            <ProjectText
                              TextSize="14px"
                              fontweight="600"
                              style={webStyle.inOutDiff}
                            >
                              Difference
                            </ProjectText>
                            <ProjectText
                              TextSize="24px"
                              fontweight="600"
                              style={webStyle.inOutDiffNumber}
                            >
                              {
                                sumOfDifference
                              }
                            </ProjectText>
                          </DifferenceBox>
                        </div>
                      </Grid>
                    </Grid>
                  </BarChartBox>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginTop: "30px" }}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <PodText>
                    POD Insights
                  </PodText>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <ProjectText style={webStyle.tableRange}>
                    Quantity
                  </ProjectText>
                  <Select variant="outlined" style={webStyle.rangeSelect}
                  MenuProps={menuDropFieldProps}
                  >
                    {this.state.rangeFilter.map((num) => {
                      return (
                        <MenuItem
                          value={num}
                          key={num}
                          onClick={() => this.QuntityFilter(num)}
                          data-test-id="QuntityFilter"
                          style={webStyle.filterRangeOptionsStyle}
                        >
                          {num}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <Select
                    MenuProps={menuDropFieldProps}
                    variant="outlined"
                    data-test-id="handlePurposeFilter"
                    style={webStyle.rangeSelect}
                    onChange={(e) => this.handlePurposeFilter(e.target.value)}
                  >
                    <MenuItem style={webStyle.filterRangeOptionsStyle} value="Import">Import</MenuItem>
                    <MenuItem style={webStyle.filterRangeOptionsStyle} value="Export">Export</MenuItem>
                    <MenuItem style={webStyle.filterRangeOptionsStyle} value="Transhipment">Transhipment</MenuItem>
                  </Select>
                  <ProjectText style={webStyle.tableRange}>
                    Sort by:
                  </ProjectText>
                  <Select MenuProps={menuDropFieldProps} variant="outlined" style={webStyle.rangeSelect}>
                    <MenuItem
                      style={webStyle.filterRangeOptionsStyle}
                      value="Relevance"
                      data-test-id="relevanceFilter"
                      onClick={() => this.relevanceFilter()}
                    >
                      Relevance
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </div>
            <TableContainer style={{ marginTop: "25px" }}>
              <StyledTable aria-label="simple table">
                <TableHead style={webStyle.tableHeadingBox}>
                  <TableRow style={{ marginBottom: "20px" }}>
                    <StyledTableCell
                      style={{
                        ...webStyle.tableHeading,
                        textAlign: "center",
                        borderBottomLeftRadius: "8px",
                      }}
                    >
                      POD
                      <img src={vector} style={{ marginLeft: "5px" }} />
                    </StyledTableCell>
                    <StyledTableCell style={webStyle.tableHeading}>
                      CONTAINER <br /> STATUS
                    </StyledTableCell>
                    <StyledTableCell style={webStyle.tableHeading}>
                      CONTAINER <br /> SIZE
                    </StyledTableCell>
                    <StyledTableCell style={webStyle.tableHeading}>
                      CONTAINER <br /> TYPE
                    </StyledTableCell>
                    <StyledTableCell style={webStyle.tableHeading}>
                      QTY
                    </StyledTableCell>
                    <StyledTableCell style={webStyle.tableHeading}>
                      DAYS IN YARDS
                    </StyledTableCell>
                    <StyledTableCell style={webStyle.tableHeading}>
                      +
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ ...webStyle.tableHeading, textAlign: "center" }}
                    >
                      DAYS TO GO
                    </StyledTableCell>
                    <StyledTableCell style={webStyle.tableHeading}>
                      =
                    </StyledTableCell>
                    <StyledTableCell style={webStyle.tableHeading}>
                      TOTAL EXPECTED
                      <img src={vector} style={{ marginLeft: "5px" }} />
                      <br />
                      DWELL
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ borderBottomRightRadius: "8px" }}
                    />
                  </TableRow>
                </TableHead>
                
                <CustomTableBody>
                    {this.state.tableData?.filtered_inventories?.map(
                    (row: any) => {
                      return (
                        <StyledTableRow
                          style={{ height: "68px", marginTop: "30px" }}
                          key={row.id}
                        >
                          <TableCell
                            style={{
                              ...webStyle.rowText,
                              borderLeft: "1px solid #CDCDCD",
                              borderTop: "1px solid #CDCDCD",
                              borderBottom: "1px solid #CDCDCD",
                              borderTopLeftRadius: "8px",
                              borderBottomLeftRadius: "8px",
                            }}
                          >
                            <p style={{ paddingLeft: "41px" }}>{row.pod}</p>
                          </TableCell>
                          <TableCell
                            style={{
                              ...webStyle.rowText,
                              borderTop: "1px solid #CDCDCD",
                              borderBottom: "1px solid #CDCDCD",
                            }}
                          >
                            <p className="firstchild">{row.container_type}</p>
                          </TableCell>
                          <TableCell
                            style={{
                              ...webStyle.rowText,
                              borderTop: "1px solid #CDCDCD",
                              borderBottom: "1px solid #CDCDCD",
                            }}
                          >
                            <p className="secondchild">{row.container_size}</p>
                          </TableCell>
                          <TableCell
                            style={{
                              ...webStyle.rowText,
                              borderTop: "1px solid #CDCDCD",
                              borderBottom: "1px solid #CDCDCD",
                            }}
                          >
                            <p className="thirdchild">{row?.container_code}</p>
                          </TableCell>
                          <TableCell
                            style={{
                              ...webStyle.rowText,
                              borderTop: "1px solid #CDCDCD",
                              borderBottom: "1px solid #CDCDCD",
                            }}
                          >
                            <p className="lastchild">{row?.quantity||"null"}</p>
                          </TableCell>
                          <TableCell
                            style={{
                              ...webStyle.rowText,
                              marginLeft: "5px",
                              borderTop: "1px solid #CDCDCD",
                              borderBottom: "1px solid #CDCDCD",
                            }}
                          >
                            <p
                              className="paratag text-left second"
                              style={{ marginLeft: "20px" }}
                            >
                              {" "}
                              <span
                                style={{
                                  background: "#98CCFF",
                                  display: "flex",
                                  width: "50px",
                                  height: "36px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontWeight: "bold",
                                }}
                              >
                                {row.days_in_yards}
                              </span>
                            </p>
                          </TableCell>
                          <TableCell
                            style={{
                              ...webStyle.rowTextPad,
                              borderTop: "1px solid #CDCDCD",
                              borderBottom: "1px solid #CDCDCD",
                            }}
                          >
                            <p className="paratag text-left zero-pad" />
                          </TableCell>
                          <TableCell
                            style={{
                              ...webStyle.rowText,
                              borderTop: "1px solid #CDCDCD",
                              borderBottom: "1px solid #CDCDCD",
                            }}
                          >
                            <p
                              className="paratag text-right"
                              style={{
                                color: "#FFFFFF",
                                paddingRight: "20px",
                                marginRight: "56px",
                              }}
                            >
                              {row.days_to_go ? row.days_to_go : 0}
                            </p>
                          </TableCell>
                          <TableCell
                            style={{
                              borderTop: "1px solid #CDCDCD",
                              borderBottom: "1px solid #CDCDCD",
                            }}
                          />
                          <TableCell
                            style={{
                              ...webStyle.rowText,
                              borderTop: "1px solid #CDCDCD",
                              borderBottom: "1px solid #CDCDCD",
                            }}
                          >
                            <p style={{ marginLeft: "20px" }}>
                              {row.total_expected_dwell}
                            </p>
                          </TableCell>
                          <TableCell
                            style={{
                              borderTop: "1px solid #CDCDCD",
                              borderBottom: "1px solid #CDCDCD",
                              borderRight: "1px solid #CDCDCD",
                              borderTopRightRadius: "8px",
                              borderBottomRightRadius: "8x",
                            }}
                          >
                            <Typography
                              data-test-id="handleNavigation"
                              style={webStyle.buybox}
                              onClick={() => this.handleNavigation()}
                            >
                              BUY
                            </Typography>
                          </TableCell>
                        </StyledTableRow>
                      );
                    }
                  )}
                </CustomTableBody>
              </StyledTable>
            </TableContainer>
          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const PercentBox = styled(Grid)({
  flexWrap: "unset",
  gap: "8px",
  marginTop: "20px",
  '@media (max-width: 960px)': {
    display: "grid",
 },
});
const HistoricalText = styled(ProjectText)({
  color: "#1E1E1E",fontFamily: "Asap" as "Asap",
  fontSize: "28px",fontWeight: 700,
  lineHeight: "32.09%",
  '@media (max-width: 450px)': {
    fontSize: "22px",
    marginTop: "10px"
 },
});
const PodText = styled(ProjectText)({
  fontFamily: "Asap" as "Asap",color: "#1E1E1E",
  fontSize: "28px",fontWeight: 700,
  lineHeight: "32.09%",
  '@media (max-width: 600px)': {
    marginBottom: "20px"
 },
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    borderRadius: "10px",color: "#B8D5F9",
    fontFamily: "Asap",fontSize: "14px",
    fontWeight: 400,lineHeight: "16px",
    background: "#fff",
  },
}))(TableRow);
const StyledTable = withStyles((theme) => ({
  root: {
    borderCollapse: "separate",borderSpacing: "0 6px",
    rowGap: "10px",background: "transparent",
  },
}))(Table);

const CustomBox = styled(Box)(({ theme }) => ({
  background: "#FFF",margin: "0rem",
  display: "block",padding: "1.5625rem 1.25rem",
  justifyContent: "space-between",
  [theme.breakpoints.up("lg")]: {
    display: "flex",
  },
  width: '100%',
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "#1E1E1E",fontWeight: 600,
    fontFamily: "Asap",fontSize: "14px",
    fontStyle: "normal",lineHeight: "24px",
    background: "#E1E5EE",borderBottom: "2px solid #8A99BB",
  },
  body: { fontSize: 14, 
    fontFamily: "Asap" },
}))(TableCell);
const CustomTableBody = styled(TableBody)({
  "& .zero-pad": {
    height: 36, 
    background: "#3E4DA6",
  },
  "& td p": {
    paddingBlock: 10, 
    paddingLeft: 16,
  },
  "& td .second": {
    paddingBlock: 0, 
    paddingLeft: 0,
  },
  "& td .firstchild": {
    border: "1px solid #3E4DA6", borderRight: "none",
    borderTopLeftRadius: 5,  background: "#E1F0FF",
    borderBottomLeftRadius: 5,marginLeft: 8,
  },
  "& td .secondchild, td .thirdchild": {
    border: "1px solid #3E4DA6", borderRight: "none",
    background: "#E1F0FF", borderLeft: "none",
  },
  "& td .lastchild": {
    border: "1px solid #3E4DA6", borderLeft: "none",
    borderTopRightRadius: 5, background: "#E1F0FF",
    borderBottomRightRadius: 5,marginRight: 12,
  },
  "& .paratag.text-center": {
    textAlign: "center", paddingLeft: 0,
  },
  "& .paratag.text-left": {
    textAlign: "start", background: "#3E4DA6",
  },
  "& .paratag.text-right": {
    background: "#3E4DA6", textAlign: "end",
  },
  "& .MuiTable-root": {
    borderSpacing: "0 21px", borderCollapse: "separate",
  },
});
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    backgroundColor: theme.palette.common.white, borderRadius: 5,
    border: "1px solid #ced4da",padding: "10px 12px",
    position: "relative",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontSize: "16px",color: "#000000",
    fontWeight: 500,
    "&:focus": {
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      borderColor: "##DCDCD",
    },
  },
}))(InputBase);

const ListItem = styled(MenuItem)({
  color: "#1E1E1E",fontSize: "14px",
  fontFamily: "Asap" as "Asap",flexBasisontWeight: 600,
  "&:hover": {
    backgroundColor: "#D5E2FF",
  },
});
const MainHeadingBox = styled("div")({
  justifyContent: "space-between", display: "flex",
  marginTop: "40px", alignItems: "center",
  "@media (max-width: 960px)": {
    alignItems: "baseline",
    gap: "24px"
  },
});
const InflowSmallBox = styled("div")({
  background: "#FFC8C8", textAlign: "center" as "center",
  borderRadius: "12px", padding: "16px 12px",
  maxWidth: "102px",
  "@media (max-width: 960px)": {
    maxWidth: "initial"
  },
  "& .centerText": {
    display: "flex", marginLeft: "8px",
    "@media (max-width: 960px)": {
      justifyContent: "center"
    },
  },
});
const OutflowSmallBox = styled("div")({
  textAlign: "center" as "center", background: "#DFFFE8",
  padding: "16px 12px", borderRadius: "12px",
  marginTop: "10px", maxWidth: "102px",
  "@media (max-width: 960px)": {
    maxWidth: "initial",
  },
  "& .centerText": {
    display: "flex",  marginLeft: "8px",
    "@media (max-width: 960px)": {
      justifyContent: "center",
    },
  },
});
const DifferenceBox = styled("div")({
  textAlign: "center" as "center", background: "#F5F5F5",
  padding: "16px 12px", borderRadius: "12px",
  marginTop: "10px", maxWidth: "102px",
  "@media (max-width: 960px)": {
    maxWidth: "initial",
  },
  "& .centerText": {
    display: "flex", 
    marginLeft: "8px",
    "@media (max-width: 960px)": {
      justifyContent: "center",
    },
  },
});
const DateWeekGrid = styled(Grid)({
  display: "flex", 
  justifyContent: "flex-end",
  "@media (max-width: 700px)": {
    justifyContent: "flex-start",
    marginTop: "10px"
 },
});
const webStyle = {
  outerBox: {
    padding: "45px 20px", background: "#EFEFEF",
  },
  textFieldHeading: {
    fontFamily: "Asap" as "Asap",lineHeight: "16px",
    fontWeight: 700,marginBottom: "10px",
    letterSpacing: "0em",fontSize: "14px",
    textAlign: "left" as "left",color: "#363636",
  },
  innerBox: {
    background: "#FFFFFF",padding: "1px 30px",
    borderRadius: "8px",
  },
  mainHeadingBox: {
    justifyContent: "space-between",display: "flex",
    marginTop: "40px",alignItems: "center",
  },
  subHeading: {
    marginTop: "5px",color: "#363636",
    lineHeight: "16px",fontWeight: 500,
    fontSize: "12px",textAlign: "left" as "left",
    fontFamily: "Asap" as "Asap",letterSpacing: "0em"
  },
  monthly: {
    color: "#1E1E1E",
    fontSize: "28px",
    fontFamily: "Asap" as "Asap",
    letterSpacing: "0em",
    fontWeight: 700,
    textAlign: "left" as "left",
    lineHeight: "36px"
  },
  udatedText: {
    fontEeight: 500,
    color: "#ACACAC",
    fontSize: "12px",
    fontFamily: "Asap" as "Asap",
    lineHeight: "24px",
    marginLeft: "5px"
  },
  download: {
    color: "#656565",
    lineHeight: "22px",
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "Asap",
    marginRight: "5px"
    },
  downloadBox: {
    display: "flex",
    padding: "10px",
    background: "#E2E2E2",
    borderRadius: "6px"
  },
  inboundBox: {
    paddingBottom: "24px",
    paddingTop: "24px",
    textAlign: "center" as "center",
    borderRadius: "16px",
    background: "#DFFFE8",
    borderLeft: "4px solid #61AE00"
  },
  percent: {
    color: "#000000",
    fontSize: "32px",
    lineHeight: "37px",
    fontFamily: "Asap" as "Asap",
    fontWeight: 700
  },
  percentType: {
    fontSize: "16px",
    lineHeight: "18px",
    fontFamily: "Asap",
    marginTop: "10px",
    color: "#000000",
    fontWeight: 400,
  },
  outboundBox: {
    textAlign: "center" as "center",
    background: "#FFC8C8",
    paddingTop: "24px",
    borderRadius: "16px",
    paddingBottom: "24px",
    borderLeft: "4px solid #DB2525"
  },
  barChartBox: {
    border: "1px solid #CDCDCD",
    padding: "24px 60px 24px 60px",
    borderRadius: "16px",
    marginTop: "30px"
  },
  inoutText: {
    fontFamily: "Asap" as "Asap",
    color: "#1E1E1E",
    fontSize: "14px",
    lineHeight: "23px",
    fontWeight: 600,
    width: "110px",
    marginRight: "25px"
  },
  buttonGroup: {
    borderRadius: "20px",
    background: "#EEF2F5"
  },
  selectButton: {
    fontFamily: "Asap",
    borderRadius: "20px",
    fontSize: "14px",
    fontWeight: 500
  },
  dropdown: {
    color: "#1E1E1E",
    fontFamily: "Asap" as "Asap",
    fontSize: "14px",
    fontWeight: 600,
    border: "1px solid #CDCDCD",
    borderRadius: "4px"
  },
  colorText: {
    fontFamily: "Asap" as "Asap",
    lineHeight: "18px",
    color: "#0F172A",
    fontWeight: 400,
    fontSize: "14px",
    marginRight: "10px",
    marginLeft: "10px"
  },
  monthltTextChart: {
    fontWeight: 600,
    color: "#1E1E1E",
    fontSize: "16px",
    fontFamily: "Asap" as "Asap",
    lineHeight: "18px"
  },
  tradeText: {
    color: "#1E1E1E",
    fontFamily: "Asap" as "Asap",
    fontSize: "28px",
    fontWeight: 700,
    lineHeight: "32.09%"
  },
  tradeBox: {
    padding: "20px 20px",
    borderRadius: "16px",
    border: "1px solid #CDCDCD",
    marginTop: "30px"
  },
  tableHeadingBox: {
    background: "#E1E5EE",
    borderBottom: "3px solid #8A99BB",
    borderRadius: "8px",
    paddingTop: "24px",
    paddingBottom: "24px",
    marginTop: "30px"
  },
  tableRow: {
    border: "1px solid #CDCDCD",
    borderRadius: "8px",
    paddingTop: "24px",
    paddingBottom: "16px",
    marginTop: "10px"
  },
  tableHeading: {
    fontWeight: 700,color: "#1E1E1E",
    fontFamily: "Asap" as "Asap",
    fontSize: "14px",
    lineHeught: "16px"
  },
  rowText: {
    color: "#1E1E1E",
    fontWeight: 500,
    fontFamily: "Asap" as "Asap",
    fontSize: "14px",
    paddingInline: 0,
    lineHeight: "16px"
  },
  rowTextPad: {
    paddingInline: 0,
  },
  sellbox: {
    border: "1px solid #DB2525",
    color: "#1E1E1E",
    background: "#FFE8E8",
    fontWeight: 600,
    padding: "6px 10px",
    fontFamily: "Asap" as "Asap",
    width: "53px",
    fontSize: "14px",
    marginTop: "-6px",
  },
  agsText: {
    color: "#363636",
    fontFamily: "Asap" as " Asap",
    fontWeight: 400,
    fontSize: "14px",
    marginTop: "4px"
  },
  listItem: {
    color: "#1E1E1E",
    fontFamily: "Asap" as "Asap",
    fontSize: "14px",
    fontWeight: 600
  },
  popHeading: {
    color: "#1E1E1E",
    fontSize: "28px",
    fontFamily: "Asap" as "Asap",
    fontWeight: 700,
    lineHeight: "32.09px"
  },
  listTrade: {
    fontFamily: "Asap" as "Asap",
    fontWeight: 700,
    color: "#ACACAC",
    fontSize: "20px",
    lineHeight: "32px"
  },
  listContent: {
    fontWeight: 600,
    color: "#363636",
    fontSize: "20px",
    lineHeight: "24px",
    fontFamily: "ASap" as "Asap",
    marginTop: "20px"
  },
  rightPopHeading: {
    color: "#1E1E1E",
    marginTop: "20px",
    fontSize: "24px",
    fontWeight: 600,
    fontFamily: "Asap" as "Asap",
    lineHeight: "27px"
  },
  rightPopsub: {
    lineHeight: "24px",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Asap" as "Asap",
    color: "#363636",
    marginTop: "20px"
    },
  itemList: {
    display: "flex",
    width: "188px",
    justifyContent: "space-between",
  },
  percentageText: {
    position: "absolute" as "absolute",
    fontWeight: 400,
    top: 5,
    left: 10,
    color: "#FFFFFF",
    fontSize: "14px",
    fontFamily: "Asap" as "Asap",
  },
  smallestHeading: {
    fontFamily: "Asap" as "Asap",
    color: "#000000",
    fontSize: "10px",
    fontWeight: 600,
  },
  percentUpDown: {
    fontFamily: "Asap" as "Asap",
    fontSize: "12px",
    fontWeight: 600,
    background: "#70E08F",
    padding: "4px",
    borderRadius: "4px",
    marginTop: 3,
    marginLeft: 3,
    color: "#1E1E1E"
  },
  percentUpDownRed: {
    color: "#FFFFFF",
    background: "#DB2525",
    fontFamily: "Asap" as "Asap",
    fontSize: "12px",
    fontWeight: 600,
    borderRadius: "4px",
    marginTop: 3,
    padding: "4px",
    marginLeft: 3
  },
  historyInflow: {
    background: "#FFC8C8",padding: "16px 12px",
    borderRadius: "12px"
  },
  tableRange: {
    color: "#363636",fontSize: "12px",
    FontFamily: "Asap" as "Asap",
    alignItems: "center",fontWeight: 500,
    marginRight: "5px",display: "flex",
    marginLeft: "5px"
  },
  rangeSelect: {
    background: "#F0F0F0",color: "#1E1E1E",
    fontSize: "14px",fontWeight: 600,
    fontFamily: "Asap" as "Asap",height: "32px",
    marginLeft: "5px"
  },
  inflowSmallBox: {
    background: "#FFC8C8",textAlign: "center" as "center",
    borderRadius: "12px",padding: "16px 12px",
    maxWidth: "102px"
  },
  outflowSmallBox: {
    textAlign: "center" as "center",
    background: "#DFFFE8",
    padding: "16px 12px",
    borderRadius: "12px",
    marginTop: "10px",
    maxWidth: "102px"
  },
  inOutDiff: {
    fontFamily: "Asap" as "Asap",
    fontSize: "14px",
    color: "#1E1E1E",
    fontWeight: 600
  },
  inOutDiffNumber: {
    color: "#000000",
    fontSize: "24px",
    fontFamily: "Asap" as "ASap",
    fontWeight: 600,
  },
  buybox: {
    color: "#1E1E1E",
    background: "#FFE8E8",
    border: "1px solid #DB2525",
    padding: "6px 10px",
    width: "53px",
    fontWeight: 600,
    fontSize: "14px",
    fontFamily: "Asap" as "Asap",
    marginTop: "-6px",
    borderRadius: "4px",
  },
  filterRangeOptionsStyle : {
    color: "black",
    fontWeight: 600, 
    fontFamily: "Asap"
  }
};
// Customizable Area End