import React from "react";

import {
  Box,
  Typography,
  Grid,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { closeImg } from "./assets"
import { createTheme, ThemeProvider,styled } from "@material-ui/core/styles"

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import DemorequestController, {
  Props,
  configJSON,
} from "./DemorequestController";
import VideoPlayer from "../../../components/src/VideoPlayer.web";

export default class Demorequest extends DemorequestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <VideoStyle>
        <Grid container className="mainContainer">
          <Grid item lg={12} md={12} sm={12} xs={12} className="closeGrid">
          <Typography>
                <img onClick={this.props.handleClose} src={closeImg} alt="close" className="closeImg" />
              </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} className="title">
          <Typography className="titleStyle">
                {this.props.videoData.length > 0 ? configJSON.title : "There are no help videos"}
              </Typography>
          </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container className="videoContainer">
                {this.props.videoData.map((videoData, index) => (
                  <Grid item data-testid="videoPlayer" lg={this.props.videoData.length===1?12:5} md={this.props.videoData.length===1?12:5} sm={12} xs={12} className="videoGrid">
                   {videoData.attributes.videos.map((data: { url: string; })=>{
                    return(
                      <VideoPlayer
                      key={index}
                      videoUrl={data.url}
                      title={videoData.attributes.video_name} posterUrl={""} />
                    )
                   })}
                  </Grid>
                ))}
              </Grid>
            </Grid>
        </Grid>
        </VideoStyle>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const VideoStyle = styled(Box)({
"& .mainContainer":{
  padding:"1%",
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  textAlign:"center",
  width:"100%"
},
"& .closeGrid": {
  textAlign: "end",
  margin: "1%"
},
"& .closeImg":{
cursor:"pointer"
},
"& .titleStyle":{
  color:"#1E1E1E",
  fontSize: "32px",
  fontFamily: "Asap",
  fontWeight: "bold",
  lineHeight: "16px",
},
"& .videoContainer":{
  display: "flex",
  justifyContent: "center",
  padding:" 3% 3%"
},
"& .videoGrid":{
textAlign:"start",
margin:"1%",
background:"#E3E6ED",
borderRadius:"10px",
width:"100%",
height:"100%"
},
})
// Customizable Area End
