// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { FormikProps } from "formik";
import { toast } from "react-toastify";

import { RouteComponentProps } from "react-router";
export const configJSON = require("./config");

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  stepLabels: Array<string>;
  prevActiveStep: number;
  activeStep: number;
  showPrincipalOrgPage: boolean;
  showPassword: boolean;
  add: Array<number>;
  name: string;
  business: string;
  authorisation: string;
  principalOrgData: Array<object>;
  UploadFiles: Array<File>;
  FileName: string;
  BusinessRegistrationDocument: File[];
  BRDFileName: string;
  ErrorPrincipal: Array<{
    name: string;
    business: string;
    authorisation: string;
  }>
  AuthLetterFileName: string;
  OrganizationName: string;
  OrganizationUEN: string;
  BusinessRegistrationDoc: File[];
  BusinessRegistrationDocBlob: string;
  PrincipalRegistrationDoc: string;
  AuthLetter: string;
  AdminName: string;
  AdminNo: string;
  AdminDepartment: string;
  AdminDesignation: string;
  AdminEmail: string;
  AdminPassword: string;
  showPopup: boolean;
  disable: boolean;
  isViewLess: boolean;
  tcChecked: boolean;
  tAndCPdf: string;
  orgName: string;
  adminEmail: string;
  loading: boolean,
  FormWithFiles: Array<{
    NameOfSection: string,
    BusinessName: string,
    BusinessFile: Array<File>,
    AuthorisationFileName: string,
    authorisationFile: Array<File>,
    NameTouch: boolean,
    BusinessTouch: boolean,
    authorisationTouch: boolean,
    BusinessFileBlob: string,
    authorisationFileBlob: string,
    BusinessDrag: boolean,
    AuthorisationDrag: boolean
  }>,
  FormWithFilesHeadings: Array<string>,
  ErrorFiledIndex: Array<number>,
  PhoneNumber: string,
  FormSubmitContainer: boolean,
  FormenableReinitialize: boolean,
  AdminFrom: boolean,
  DragActive: boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OrganizationSignUpController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      stepLabels: [],
      disable: true,
      prevActiveStep: 0,
      activeStep: 0,
      add: [1],
      showPassword: false,
      name: "",
      business: "",
      authorisation: "",
      showPrincipalOrgPage: false,
      principalOrgData: [{ name: "", business: "", authorisation: "" }],
      UploadFiles: [],
      FileName: "",
      BusinessRegistrationDocument: [],
      BRDFileName: "",
      AuthLetterFileName: "",
      ErrorPrincipal: [
        {
          name: "",
          business: "",
          authorisation: "",
        }
      ],
      OrganizationName: "",
      OrganizationUEN: "",
      BusinessRegistrationDoc: [],
      BusinessRegistrationDocBlob: "",
      PrincipalRegistrationDoc: "",
      AuthLetter: "",
      AdminName: "",
      AdminNo: "",
      AdminDepartment: "",
      AdminDesignation: "",
      AdminEmail: "",
      AdminPassword: "",
      showPopup: false,
      isViewLess: false,
      tcChecked: false,
      tAndCPdf: "",
      orgName: "",
      adminEmail: "",
      FormWithFiles: [{
        NameOfSection: "",
        BusinessName: "",
        BusinessFile: [],
        BusinessFileBlob: "",
        AuthorisationFileName: "",
        authorisationFile: [],
        authorisationFileBlob: "",
        NameTouch: false,
        BusinessTouch: false,
        authorisationTouch: false,
        BusinessDrag: false,
        AuthorisationDrag: false
      }],
      FormWithFilesHeadings: [],
      loading: true,
      ErrorFiledIndex: [],
      PhoneNumber: "",
      FormSubmitContainer: false,
      FormenableReinitialize: true,
      AdminFrom: false,
      DragActive: false
      // Customizable Area End
    };
  }
  // Customizable Area Start
  async componentDidMount() {
    this.GetTearmsAndConditon();
    this.getPrefilledData();
    let temp = ["Organisation Details", "Admin Details", "Review & Submit"];
    this.setState({
      stepLabels: temp,
    }, () => {
      setTimeout(() => {
        this.setState({
          FormenableReinitialize: false
        })
      }, 2000);
    });
  }

  signUpApiCallID: string = "";
  getTearmsAndConditionApiCallID: string = "";
  getPrefilledDataApiCallID: string = "";

  handleFilUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    keyName: string
  ) => {
    let data = [...this.state.FormWithFiles]
    if (e.target.files) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(`You're cant upload file bigger than 10 Mb`);
      } else {
        const lastIndex = e.target.files[0].name.lastIndexOf(".");
        let FileType = ""
        if (lastIndex) {
          const AfterSeprator = e.target.files[0].name.substring(lastIndex + 1)
          FileType = AfterSeprator
        }
        if (['pdf', 'doc', 'docx'].includes(FileType.toLowerCase())) {
          this.FileUploads(e, index, keyName)
        } else {
          toast.error(`You can't upload ${FileType} File Here`);
        }
      }
      this.setState({ FormWithFiles: data })
    }
  };

  handleFilUploadDrag = (e: React.DragEvent<HTMLElement>, index: number, keyName: string) => {
    let data = [...this.state.FormWithFiles]
    e.preventDefault(); e.stopPropagation();
    if (e.dataTransfer.files) {
      if (e.dataTransfer.files[0].size > 10 * 1024 * 1024) {
        toast.error(`You're cant upload file bigger than 10 Mb`);
        if (keyName === "BusinessFile") {
          data[index].BusinessDrag = false
        }
        if (keyName === "authorisation") {
          data[index].AuthorisationDrag = false
        }
      } else {
        this.ElseFileDrag(e, index, keyName)
      }
      this.setState({ FormWithFiles: data })
    }
  }

  ElseFileDrag = (e: React.DragEvent<HTMLElement>, index: number, keyName: string) => {
    const lastIndex = e.dataTransfer.files[0].name.lastIndexOf(".");
    let FileType = ""
    if (lastIndex) {
      const AfterSeprator = e.dataTransfer.files[0].name.substring(lastIndex + 1)
      FileType = AfterSeprator
    }
    if (['pdf', 'doc', 'docx'].includes(FileType.toLowerCase())) {
      this.HandeFileDragTwo(e, index, keyName)
    } else {
      toast.error(`You can't upload ${FileType} File Here`);
    }
  }

  HandeFileDragTwo = (e: React.DragEvent<HTMLElement>, index: number, keyName: string) => {
    let data = [...this.state.FormWithFiles]
    if (e.dataTransfer.files) {
      if (keyName === "BusinessFile") {
        data[index].BusinessFile.push(e.dataTransfer.files[0]);
        data[index].BusinessName = `${e.dataTransfer.files[0].name}`;
        data[index].BusinessTouch = true;
        data[index].BusinessFileBlob = URL.createObjectURL(e.dataTransfer.files[0])
      }
      if (keyName === "authorisation") {
        data[index].authorisationFile.push(e.dataTransfer.files[0]);
        data[index].AuthorisationFileName = `${e.dataTransfer.files[0].name}`;
        data[index].authorisationTouch = true;
        data[index].authorisationFileBlob = URL.createObjectURL(e.dataTransfer.files[0])
      }
    }
  }

  FileUploads = (e: React.ChangeEvent<HTMLInputElement>, index: number, keyName: string) => {
    let data = [...this.state.FormWithFiles]
    if (e.target.files) {
      if (keyName === "BusinessFile") {
        data[index].BusinessFile.push(e.target.files[0]);
        data[index].BusinessName = `${e.target.files[0].name}`;
        data[index].BusinessTouch = true;
        data[index].BusinessFileBlob = URL.createObjectURL(e.target.files[0])
      }
      if (keyName === "authorisation") {
        data[index].authorisationFile.push(e.target.files[0]);
        data[index].AuthorisationFileName = `${e.target.files[0].name}`;
        data[index].authorisationTouch = true;
        data[index].authorisationFileBlob = URL.createObjectURL(e.target.files[0])
      }
    }
  }

  handleDragPrincipalFuncation = (e: React.DragEvent<HTMLLabelElement>, index: number, keyName: string) => {
    e.preventDefault();
    e.stopPropagation();
    let data = [...this.state.FormWithFiles]
    if (e.type === "dragenter" || e.type === "dragover") {
      if (keyName === "BusinessFile") {
        data[index].BusinessDrag = true
      }
      if (keyName === "authorisation") {
        data[index].AuthorisationDrag = true
      }
    } else if (e.type === "dragleave") {
      if (keyName === "BusinessFile") {
        data[index].BusinessDrag = false
      }
      if (keyName === "authorisation") {
        data[index].AuthorisationDrag = false
      }
    }
    this.setState({ FormWithFiles: data })
  }

  PrincipalOrganizationNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    let data = [...this.state.FormWithFilesHeadings]
    const inputValue = e.target.value;
    e.target.value = inputValue.trimStart();
    data[index] = e.target.value
    if (data[index].startsWith(' ')) {
      data[index].trimStart()
    }
    this.setState({
      FormWithFilesHeadings: data
    })
  }
  SaveHeading = (index: number) => {
    let arrayData = [...this.state.FormWithFiles]
    arrayData[index].NameOfSection = this.state.FormWithFilesHeadings[index]
    arrayData[index].NameTouch = true
    this.setState({
      FormWithFiles: arrayData
    })
  }

  handleFilRemove = (index: number, keyName: string) => {
    let data = [...this.state.FormWithFiles]

    if (keyName === "BusinessFile") {
      data[index].BusinessFile = [];
      data[index].BusinessName = "";
      data[index].BusinessFileBlob = "";
    }
    if (keyName === "authorisation") {
      data[index].authorisationFile = [];
      data[index].AuthorisationFileName = "";
      data[index].authorisationFileBlob = "";
    }
    this.setState({
      FormWithFiles: data
    })
  };

  handleYes = () => {
    let data = [...this.state.stepLabels];
    data.splice(1, 0, "Principal Organisation Details");
    if (!this.state.stepLabels.includes("Principal Organisation Details")) {
      this.setState({
        stepLabels: data,
      });
    }
  };

  RemoveStep = () => {
    let testData = {
      NameOfSection: "",
      BusinessName: "",
      BusinessFile: [],
      AuthorisationFileName: "",
      authorisationFile: [],
      NameTouch: false,
      BusinessTouch: false,
      authorisationTouch: false,
      BusinessFileBlob: "",
      authorisationFileBlob: "",
      BusinessDrag: false,
      AuthorisationDrag: false
    };
    this.setState({
      stepLabels: this.state.stepLabels.filter((item: string) => {
        return item !== "Principal Organisation Details";
      }),
      FormWithFiles: [testData],
      FormWithFilesHeadings: []
    });
  };

  ChangeCheckBox = () => {
    this.setState({
      tcChecked: !this.state.tcChecked,
    })
  }

  AddnOnChange = () => {
    let testData = {
      NameOfSection: "",
      BusinessName: "",
      BusinessFile: [],
      AuthorisationFileName: "",
      authorisationFile: [],
      NameTouch: false,
      BusinessTouch: false,
      authorisationTouch: false,
      BusinessFileBlob: "",
      authorisationFileBlob: "",
      BusinessDrag: false,
      AuthorisationDrag: false
    };
    let data = [...this.state.FormWithFiles]
    data.push(testData)
    this.setState({ FormWithFiles: data, disable: true, isViewLess: false });
  };

  RemoveOneSection = (index: number) => {
    let data = [...this.state.FormWithFiles];
    let headingdata = [...this.state.FormWithFilesHeadings]

    if (1 < data.length) {
      data.splice(index, 1);
      headingdata.splice(index, 1);
    }
    this.setState({ FormWithFiles: data, FormWithFilesHeadings: headingdata });
  };

  PrincipalOrganisationNextButton = () => {
    let FindError = this.state.FormWithFiles.findIndex((item: {
      NameOfSection: string
      BusinessName: string,
      AuthorisationFileName: string,
    }) => {
      return (
        item.BusinessName === "" || item.AuthorisationFileName === "" || item.NameOfSection === "" || item?.NameOfSection === undefined
      )
    })
    if (FindError !== -1) {
      let data = [...this.state.FormWithFiles]
      if (data[FindError].NameOfSection === undefined) {
        data[FindError].NameOfSection = ""
      }
      data[FindError].NameTouch = data[FindError].NameTouch || true
      data[FindError].BusinessTouch = data[FindError].BusinessTouch || true
      data[FindError].authorisationTouch = data[FindError].authorisationTouch || true
      this.setState({
        FormWithFiles: data
      })
    } else {
      this.handleNext()
    }

  }


  ViewLessOnchange = () => {
    this.setState({
      isViewLess: !this.state.isViewLess,
    });
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.signUpApiCallID) {
      if (
        responseJson.errors === undefined &&
        responseJson.error === undefined
      ) {
        this.setState({ showPopup: true, loading: false });
      } else {
        this.setState({
          loading:false
        })
        this.ErrorHandelResponse(responseJson.errors ?? responseJson.error);
      }
    }
    if (apiRequestCallId === this.getPrefilledDataApiCallID) {
      if (responseJson?.prefilled?.organization.name) {
        this.setState({ loading: false });
        this.setState({
          OrganizationName: responseJson.prefilled.organization.name,
          AdminEmail: responseJson.prefilled.organization.email,
        });
      }
      if (responseJson.prefilled?.admin) {
        this.setState({
          AdminEmail: responseJson.prefilled?.admin.email,
          AdminName: responseJson.prefilled?.admin.full_name,
          activeStep: 1,
          AdminFrom: true,
          loading: false
        });
      }
    }
    if (apiRequestCallId === this.getTearmsAndConditionApiCallID) {
      this.setState({
        tAndCPdf:
          responseJson.data.attributes.organization_terms_and_conditions_pdf,
      });
    }
  }

  ErrorHandelResponse = (data: Array<{ [key: string]: string }>) => {
    if (typeof data === "string") {
      toast.error(data);
    }
    this.ErrorForLoop(data)
  };

  ErrorForLoop = (data: Array<{ [key: string]: string }>) => {
    for (const obj of Object.values(data)) {
      if (typeof data === "object") {
        toast.error(obj);
        if (Array.isArray(obj)) {
          for (const key in obj) {
            toast.error(`${Object.entries(obj[key])[0][0].toString().replace("_", " ").replace(/\b\w/g, char => char.toUpperCase())} ${Object.entries(obj[key])[0][1].toString().replace(",", " ")}.`);
          }
        }
      }
      if (Array.isArray(data)) {
        for (const key in obj) {
          toast.error(obj[key]);
        }
      }
    }
  }

  TearmsAndConditonOpen = () => {
    window.open(this.state.tAndCPdf, "_blank")
  }

  FirstFormSubmit = () => {
    this.handleNext()
  }

  PhoneInputNumber = (dialCode: string, number: string, setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void) => {
    let phone = `+${dialCode} ${number.slice(
      dialCode.length
    )}`;
    let numbers = number
    this.setState({
      AdminNo: number,
      PhoneNumber: phone
    })
    setFieldValue("AdminNo", `${numbers}`);
  }

  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1, FormenableReinitialize: true }, () => {
      setTimeout(() => {
        this.setState({
          FormenableReinitialize: false
        })
      }, 2000);
    });
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1, FormenableReinitialize: true }, () => {
      setTimeout(() => {
        this.setState({
          FormenableReinitialize: false
        })
      }, 2000);
    });
  };
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  TextfieldChnage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void) => {
    let key = e.target.name
    let value = e.target.value
    if (key === "OrganizationUEN") {
      value = e.target.value.toUpperCase()
      value = value.replace(/\s/g, '');
      value = value.replace(/[^a-zA-Z0-9]/g, '')
      setFieldValue(`${e.target.name}`, value.toUpperCase())
    } else if (key === "AdminPassword") {
      value = value.replace(/\s/g, '');
      setFieldValue(`${e.target.name}`, value)
    }
    else {
      setFieldValue(`${e.target.name}`, e.target.value)
    }
    this.setState({
      ...this.state,
      [key]: value,
    })
  }

  UploadFile = async (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void) => {
    const selectedFile = e.target.files && e.target.files[0];
    const { files } = e.target;
    setFieldValue("BusinessRegistrationDoc", selectedFile?.name as string);
    if (files && files.length > 0) {
      if (files[0].size > 10 * 1024 * 1024) {
        toast.error(`you're cant upload file bigger than 10 Mb`);
      } else {
        this.UploadFileElse(e)
      }
    }
  };

  handleDrag = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      this.setState({ DragActive: true });
    } else if (e.type === "dragleave") {
      this.setState({ DragActive: false });
    }
  };

  handleDropFile = (e: React.DragEvent<HTMLElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void) => {
    e.preventDefault(); e.stopPropagation();
    const selectedFile = e.dataTransfer.files && e.dataTransfer.files[0]
    setFieldValue("BusinessRegistrationDoc", selectedFile?.name);
    const { files } = e.dataTransfer;
    if (files && files.length > 0) {
      if (files[0].size > 10 * 1024 * 1024) {
        toast.error(`you're cant upload file bigger than 10 Mb`);
      } else {
        this.DragElse(e)
      }
    }
  }

  DragElse = (e: React.DragEvent<HTMLElement>) => {
    const selectedFile = e.dataTransfer.files && e.dataTransfer.files[0];
    const { files } = e.dataTransfer;
    const lastIndexString = selectedFile?.name.lastIndexOf(".");
    let FileType = ""
    if (files) {
      if (lastIndexString) {
        const AfterSeprator = selectedFile?.name.substring(lastIndexString + 1)
        FileType = AfterSeprator
      }
      if (['pdf',
        'doc',
        'docx'].includes(FileType.toLowerCase())) {
        const filePdf: File = files[0];
        const fileName: string = filePdf.name;
        const fileExtension: string | undefined = fileName.split(".").pop();
        let Urls = URL.createObjectURL(files[0])
        this.setState({
          FileName: fileName
        })
        if (fileExtension) {
          let data = []
          data.push(filePdf)
          this.setState({
            BusinessRegistrationDoc: data,
            BusinessRegistrationDocBlob: Urls,
            DragActive: false
          });
        }
      } else {
        toast.error(`You can't upload ${FileType} File Here`);
      }
    }
  }

  UploadFileElse = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    const { files } = e.target;
    const lastIndex = selectedFile?.name.lastIndexOf(".");
    let FileType = ""
    if (files) {
      if (lastIndex) {
        const AfterSeprator = selectedFile?.name.substring(lastIndex + 1)
        FileType = AfterSeprator as string
      }
      if (['pdf', 'doc', 'docx'].includes(FileType.toLowerCase())) {
        const file: File = files[0];
        const fileName: string = file.name;
        const fileExtension: string | undefined = fileName.split(".").pop();
        let Urls = URL.createObjectURL(files[0])
        this.setState({
          FileName: fileName
        })
        if (fileExtension) {
          let data = []
          data.push(file)
          this.setState({
            BusinessRegistrationDoc: data,
            BusinessRegistrationDocBlob: Urls
          });
        }
      } else {
        toast.error(`You can't upload ${FileType} File Here`);
      }
    }
  }

  DeletePdf = (setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void) => {
    this.setState({
      BusinessRegistrationDoc: [],
      FileName: ""
    });
    setFieldValue("BusinessRegistrationDoc", "");
  };

  editStep = (num: number) => {
    this.setState({ activeStep: num === 2 && !this.state.stepLabels.includes("Principal Organisation Details") ? 1 : num, FormSubmitContainer: false },
      () => {
        setTimeout(() => {
          this.setState({
            FormenableReinitialize: false
          })
        }, 2000);
      }
    );
  };

  AdminFormSubmit = () => {
    if (this.state.AdminFrom) {
      this.signUpOrganization()
    } else {
      this.setState({
        FormSubmitContainer: true,
        activeStep: this.state.stepLabels.includes("Principal Organisation Details") ? 4 : this.state.activeStep + 1,
        FormenableReinitialize: true
      }, () => {
        setTimeout(() => {
          this.setState({
            FormenableReinitialize: false
          })
        }, 2000);
      })
    }
  }

  AdminDetailsBackButton = () => {
    this.setState({
      activeStep: !this.state.stepLabels.includes("Principal Organisation Details") ? 0 : this.state.activeStep - 1,
      FormenableReinitialize: true
    }, () => {
      setTimeout(() => {
        this.setState({
          FormenableReinitialize: false
        })
      }, 2000);
    })
  }

  signUpOrganization = () => {
    let token = this.props.location.search.split("token=");
    this.setState({
      loading: true
    })

    const header = {
      token: token[1],
      contentType: configJSON.OrganizationsUpdate_With_Admin_Api_ContentType,
    };

    const formData = new FormData();

    if (this.state.stepLabels.includes("Principal Organisation Details")) {
      this.state.FormWithFiles.forEach(
        (item: {
          NameOfSection: string,
          BusinessFile: Array<File>,
          authorisationFile: Array<File>,
        }, index: number) => {
          formData.append(`organization[principal_organizations_attributes][${index}][business_registration_pdf]`, item.BusinessFile[0]);
          formData.append(`organization[principal_organizations_attributes][${index}][authorization_letter_pdf]`, item.authorisationFile[0]);
          formData.append(`organization[principal_organizations_attributes][${index}][name]`, item.NameOfSection);
        }
      );
    }
    if (this.state.FormSubmitContainer) {
      formData.append(`organization[business_registration_pdf]`, this.state.BusinessRegistrationDoc[0])
      formData.append(`organization[uen]`, this.state.OrganizationUEN);
    }
    formData.append(`admin[full_name]`, this.state.AdminName);
    formData.append(`admin[password]`, this.state.AdminPassword);
    formData.append(`admin[email]`, this.state.AdminEmail);
    formData.append(`admin[department]`, this.state.AdminDepartment);
    formData.append(`admin[designation]`, this.state.AdminDesignation);
    formData.append(`admin[contact_number]`, this.state.PhoneNumber);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.signUpApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.OrganizationsUpdate_With_Admin_Api_End_Point
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.OrganizationsUpdate_With_Admin_Post_Method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getPrefilledData = () => {
    let token = this.props.location.search.split("token=");
    const header = {
      "Content-Type": "application/json",
      token: token[1],
      prefilled: true,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPrefilledDataApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_profile/organizations/update_with_admin"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  GetTearmsAndConditon = () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTearmsAndConditionApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_terms_and_conditions/terms_and_conditions"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
