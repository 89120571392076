import React from "react";
// Customizable Area Start
import HistoryController, 
{ 
  Props 
} from "./HistoryController.web";
import Navbar from "../../../components/src/NavBar.web";
import ProjectText from "../../../components/src/ProjectText";
import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import {
  Grid,
  styled,
  InputLabel,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Button,
  Dialog,
  withStyles,
  Typography,
  FormControl,
  InputBase,
  TextField,
  TablePagination,
  Popover
} from "@material-ui/core";
import {
  imagebutton_help,
  image_download,
  image_update,
  threedot,
  closeBtn,
  checkbox,
  subCheckbox,
  bueryImage,
  sellerImage,
} from "./assets";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import moment from "moment";
import { 
   DateRangePicker,
   DateRange
  } from "materialui-daterange-picker";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Spinner from "../../../components/src/ProjectLoader";
import { 
  BarChartBox, 
  DateRangeGrid, 
  InnerBox, 
} from "../../listdemandpickflow/src/VesselUtilisation.web";
import SellerVerticalFileWeb from "../../../components/src/SellerVerticalFile.web";
import VerticalFileWeb from "../../../components/src/VerticalFile.web";





const ColorlibConnector = withStyles(
  {
  alternativeLabel: {
    left: 5
  },
  active: {
    "& $line": {
      backgroundColor: "#D58000"
    }
  },
  completed: {
    "& $line": {
      backgroundColor: "#35D261"
    }
  },
  line: {
    width: 2,
    border: 0,
    marginLeft: "8px",
    backgroundColor: "#35D261",
    borderRadius: 1
  }
})(StepConnector);

const BoxModel = styled(Box)(
  {
  "& .stepLabelbox": {
    height: "50px",
    width: "95%",
    padding: "2%",
    border: "1px solid black",
    borderRadius: "25px",
    marginLeft: "2%"
  },
  "& .stepLabelboxone": {
    height: "50px",
    width: "95%",
    padding: "2%",
    border: "1px solid black",
    borderRadius: "25px",
    marginLeft: "2%",
    backgroundColor: "#FDF2E0",
    position: "relative"
  },
  "& .stepLabelspan": {
    position: "absolute",
    top: "0px",
    right: "1px"
  },

  "& .stepLabelButton": {
    height: "48px",
    width: "100%",
    borderRadius: "23px",
    paddingRight: "113px",
    border: "none",
    paddingLeft: " 50px",
    color: "white",
    fontSize: "16px",
    backgroundColor: "#EE8F00",
    position: "relative"
  },
  "& .stepLabelImg": {
    position: "absolute"
  },
  "& .stepLabel": {
    fontSize: "16px",
    fontFamily: "Asap",
    fontWeight: 500,
    color: "#363636"
  },
  "& .stepLabelboxClick": {
    display: "flex",
    justifyContent: "space-between"
  },
  "& .stepLabelclick": {
    color: "white",
    backgroundColor: "#EE8F00"
  }
});


interface StepDate {
  label: string;
  time: string;
  date: string;
}

interface ContainerStep extends StepDate {
  container?: StepDate[];
}

interface StepsType extends ContainerStep {
  keydata?: string;
}


interface PastTransactRecordCoreDataTypes {
  id: string;
  type: string | null | undefined;
  attributes: any;
}

type HistoryPastTransactRecordTypes = PastTransactRecordCoreDataTypes;


// Customizable Area End

export default class History extends HistoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.formatDate = this.formatDate.bind(this);
    this.tooltipFormatter = this.tooltipFormatter.bind(this);

    // Customizable Area End
  }

  // Customizable Area Start
  formatDate(value: string, index: number) {
    const intervals = value;

    const date = this.state.selectedButton === "WEEKLY" ? this.state.timeIntervalDatesShow[index] : ""
    const isWeeklyGrouped = this.state.timeSeriesShow.grouped_by === "weeks" ? this.state.timeIntervalDatesShow[index] : ""
    if(this.state.selectedButton === "MONTHLY"){
      return "{a|" + intervals.split(" ")[0] + "}\n{b|" + intervals.split(" ")[1] + "}"
    } else if(this.state.selectedButton === "DAILY"){
      if(this.state.timeSeriesShow.grouped_by === "weeks"){
        return "{a|" + intervals + "}\n{b|" + isWeeklyGrouped + "}";
      } else if(this.state.timeSeriesShow.grouped_by === "months"){
        return "{a|" + intervals.split(" ")[0] + "}\n{b|" + intervals.split(" ")[1] + "}"
      } else {
        // default case show daily dates less than 28 days
        return "{a|" + moment(intervals).format("DD MMM") + "}\n{b|" + moment(intervals).format("YYYY")+ "}";
      }
    } else {
      // weekly select condition check
      return "{a|" + intervals + "}\n{b|" + date + "}";
    }
  }

  toolTipDynamicLablePass = () => {
    const match = this.state.regionFilterDropdown.find((item: string) => item[1] === this.state.transPodsRegeionDropdownFilter);
    return match ? match[0] : '';
  }

  tooltipFormatter(
    params: {
      name: string;
      value: number;
    }[]
  ) {
    return `<div style="color:#1E1E1E; font-weight:600; font-size:12px">${this.toolTipDynamicLablePass()
      }</div>
   <div style="font-size:12px; font-weight:400; color:#1E1E1E"> Transacted Value (USD): ${params[0].value
      }<br/>Transacted Container: ${params[1].value}</div>`;
  }
  valueFormatter(value: number) {
    return value / 1000 + "k";
  }


  renderTimeIntervalFilter = () => {
    if (
      this.state.selectedButton === "DAILY"
      ) {
      return (
        <DateRangeGrid
          item
          xs={12}
          sm={6}
        >
          <FormControl variant="standard">
            <BootstrapInput
              defaultValue=""
              id="DAILY DATE RANGE"
              data-test-id="dateopne"
              placeholder="dd-mm-yyyy  - dd-mm-yyyy"
              fullWidth
              onClick={this.TogolFilter}
              value={this.state.StartDate !== "" && this.state.EndDate !== "" ? `${this.state.RenderStart} - ${this.state.RenderEndDate}` : ""}
            />
            <Box style={{
              position: "absolute",
              top: "55px",
              right: "0px"
            }}>
              {
                this.state.openDatePicker &&
                <DateContainer style={{ position: "relative" }} data-test-id="DateFilterMainDiv">
                  <DatePickerStyle
                  data-test-id="DateRange"
                    open={this.state.openDatePicker}
                    toggle={this.TogolFilter}
                    wrapperClassName="DateMainClassName"
                    className="DateMainClassName"
                    onChange={(range: DateRange) => {
                      this.SetDateFilterData(range);
                    }}
                  />
                  <Box className="ButtonContaienr">
                    <Button style={{ marginRight: "10px" }} data-test-id="ClearFilter" variant="text" onClick={() => {
                      this.ClearFilterData()
                    }}>
                      <ProjectText variant="h6" TextSize="16px" textcolor="primary">
                        clear
                      </ProjectText>
                    </Button>
                    <Button variant="contained" data-test-id="SelectFilter" onClick={this.FilterDateSelect} color="primary" className="SelectButton">
                      <ProjectText variant="h6" TextSize="16px" textcolor="white">
                        Select
                      </ProjectText>
                    </Button>
                  </Box>
                </DateContainer>
              }
            </Box>
          </FormControl>

        </DateRangeGrid>
      )
    } 
    
    else if (this.state.selectedButton === "MONTHLY"){
      return (
        <DateRangeGrid
          item
          xs={12}
          sm={6}
        >
          <CustomAutoSelete
            id="timeIntervalsMonthlyDropdownFilterTestID"
            options={this.state.monthlyDropdown}
            value={this.state.timeIntervalsDropdownFilter}
            getOptionLabel={(option: unknown) => option as string ?? ""}
            onChange={(event: React.ChangeEvent<{}>, value) => {
              this.setState({
                timeIntervalsDropdownFilter: value ?? ""
              }, () => this.getHistoryTimeSeries())
            }}
            renderInput={(params) => (<TextField placeholder="Search or Select" data-test-id="timeIntervalsMonthlyOptionsFilterTestID" {...params} variant="outlined" />)}
            renderOption={(option: unknown) => (
              <ProjectText
                variant="h6"
                TextSize="14px"
                fontweight="500"
              >
                {option as string ?? ""}
              </ProjectText>
            )}
          />
        </DateRangeGrid>
      )
    }
    else{
      return (
        <DateRangeGrid
          item
          xs={12}
          sm={6}
        >
          <CustomAutoSelete
            id="timeIntervalsWeeklyDropdownFilterTestID"
            value={this.state.timeIntervalsWeeklyDropdownFilter}
            options={this.state.weeklyDropdown}
            getOptionLabel={(option: unknown) => option !=="All" ? moment(option as string, "MM-YYYY").format("MMM YYYY") : "All"}
            onChange={(event: React.ChangeEvent<{}>, value) => {
              this.setState({
                timeIntervalsWeeklyDropdownFilter: value ?? ""
              }, () => this.getHistoryTimeSeries())
            }}
            renderInput={(params) => (<TextField placeholder="Search or Select" data-test-id="timeIntervalsWeeklyOptionsFilterTestID" {...params} variant="outlined" />)}
            renderOption={(option: unknown) => (
              <ProjectText
                variant="h6"
                TextSize="14px"
                fontweight="500"
              >
                {moment(option as string, "MM-YYYY").format("MMM YYYY") ?? ""}
              </ProjectText>
            )}
          />
        </DateRangeGrid>
      )
    }
  }


  helperDateFormatFunc = (date: string, isDate: boolean) => {
    const stepsBEformat = "DD MMM YYYY hh:mm A"
    if (date) {
      if (isDate) {
        return moment(date, stepsBEformat).format("DD MMM YYYY")
      } else {
        return moment(date, stepsBEformat).format("hh:mm A")
      }
    } else {
      return ""
    }
  }

  renderNoDataFound = () => {
    return (
      <div style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: "186px",
        marginBottom: "180px"
      }}>
        <img
          src={this.state.isBuyerRole ? bueryImage : sellerImage}
          alt="resultNotFound"
          style={webStyle.noDataImgShow}
        />
        <p
          style={{ ...webStyle.noDtataContent, fontWeight: "bold" }}
        >
          No Data Found!
        </p>
      </div>
    )
  }



  renderGlobleFiltersBlock = ()  => {
    return(
      <>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <InputLabel htmlFor="REGION">
              <ProjectText style={{marginBottom: "10px"}} fontweight="700" TextSize="14px">REGION</ProjectText>
            </InputLabel>
            <CustomGlobleAutoSearchable
              id="REGION"
              options={Array.isArray(this.state.regionFilterDropdown) ? this.state.regionFilterDropdown : []}
              value={this.state.selectedRegion}
              getOptionLabel={(option: unknown) => typeof option === 'string' ? option : ""}
              onChange={(event:  React.ChangeEvent<{}>, newValue) => {
                this.setState({
                  selectedRegion: newValue ?? "",
                }, () => this.handleRegionFilter(newValue))
              }}
              renderInput={(params) => (<TextField data-test-id="handleRegionFilter" {...params} variant="outlined" />)}
              renderOption={(option: unknown) => (
                <ProjectText
                  variant="h6"
                  fontweight="500"
                  TextSize="14px"
                >
                  {option as string ?? ""}
                </ProjectText>
              )}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <InputLabel htmlFor="DESTINATIONPORTLOCATION">
              <ProjectText style={{marginBottom: "10px"}} fontweight="700" TextSize="14px">DESTINATION PORT LOCATION</ProjectText>
            </InputLabel>
            <CustomGlobleAutoSearchable
              id="DESTINATIONPORTLOCATION"
              options={Array.isArray(this.state.destinationPortDropdown) ? this.state.destinationPortDropdown : []}
              value={this.state.selectedPortDestLocation}
              getOptionLabel={(option: unknown) => typeof option === 'string' ? option : ""}
              onChange={(event:  React.ChangeEvent<{}>, newValue) => {
                this.setState({
                  selectedPortDestLocation: newValue ?? "",
                }, () => this.handleDestinationPortLocationFilter(newValue))
              }}
              renderInput={(params) => (<TextField data-test-id="handleDestinationPortLocationFilter" {...params} variant="outlined" />)}
              renderOption={(option: unknown) => (
                <ProjectText
                  variant="h6"
                  fontweight="500"
                  TextSize="14px"
                >
                  {option as string ?? ""}
                </ProjectText>
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputLabel htmlFor="CONTAINERTYPE">
              <ProjectText style={{marginBottom: "10px"}} fontweight="700" TextSize="14px">CONTAINER STATUS</ProjectText>
            </InputLabel>
            <CustomGlobleAutoSearchable
              id="CONTAINERTYPE"
              options={Array.isArray(this.state.containerTypeDropdown) ? this.state.containerTypeDropdown : []}
              value={this.state.selectedContainerType}
              getOptionLabel={(option: unknown) => typeof option === 'string' ? option : ""}
              onChange={(event:  React.ChangeEvent<{}>, newValue) => {
                this.setState({
                  selectedContainerType: newValue ?? "",
                }, () => this.handleContainerTypeFilter(newValue))
              }}
              renderInput={(params) => (<TextField data-test-id="handleContainerTypeFilter" {...params} variant="outlined" />)}
              renderOption={(option: unknown) => (
                <ProjectText
                fontweight="500"
                  variant="h6"
                  TextSize="14px"
                >
                  {option as string ?? ""}
                </ProjectText>
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputLabel htmlFor="CONTAINERSIZE">
              <ProjectText style={{marginBottom: "10px"}} fontweight="700" TextSize="14px">CONTAINER SIZE </ProjectText>
            </InputLabel>
            <CustomGlobleAutoSearchable
              id="CONTAINERSIZE"
              options={Array.isArray(this.state.containerSizeDropdown) ? this.state.containerSizeDropdown : []}
              value={this.state.selectedContainerSize}
              getOptionLabel={(option: unknown) => typeof option === 'string' ? option : ""}
              onChange={(event:  React.ChangeEvent<{}>, newValue) => {
                this.setState({
                  selectedContainerSize: newValue ?? "",
                }, () => this.handleContainerSizeFilter(newValue))
              }}
              renderInput={(params) => (<TextField data-test-id="handleContainerSizeFilter" {...params} variant="outlined" />)}
              renderOption={(options: unknown) => (
                <ProjectText
                  variant="h6"
                  TextSize="14px"
                  fontweight="500"
                >
                  {options as string ?? ""}
                </ProjectText>
              )}
            />
          </Grid>
        </Grid>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          width="100%"
        >
          <ProjectText data-test-id="handleGlobleFiltersClear" onClick={this.handleGlobleFiltersClear} style={{ background: "#E2E2E2", padding: "5px", marginTop: "10px", borderRadius: "8px", cursor: "pointer" }} textcolor="#656565" TextSize="14px" fontweight="700">
            Clear Filters
          </ProjectText>
        </Box>
      
      </>
    )
  }


  renderViewUpdateCSVAndPercentTransactBlock = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          marginTop="30px"
        >
          <Box>
            <ProjectText className="overview">Overview</ProjectText>
          </Box>
          <Box className="download">
            <ProjectText data-test-id="handleDownloadCSV" onClick={this.handleDownloadCSV} style={{cursor: "pointer"}} textcolor="#656565" TextSize="14px" fontweight="700">
              Download CSV
              <img
                src={image_download}
                alt="off"
                style={{
                  width: "10.67px",
                  height: "10.67px",
                  marginTop: "5px",
                  marginLeft: "5px"
                }}
              />
            </ProjectText>
          </Box>
        </Box>
        <Box>
          <ProjectText textcolor="#363636" TextSize="12px" fontweight="500">
            View monthly utilization on your vessels
          </ProjectText>
        </Box>
        <Box marginBottom="5px">
          <ProjectText className="update" fontweight="500" TextSize="12px">
            <img src={image_update} alt="off" />
            Updated: {this.state.overViewUpdatedData ? `${moment(this.state.overViewUpdatedData).format('DD MMM, HH:mm')} SGT` : 'No Date Found!'}
          </ProjectText>
        </Box>
        <PercentBox container spacing={2}>
          <Grid item xs={12} sm={12} md={4} style={webStyle.inboundBox}>
          <ProjectText style={webStyle.percent}>
            {this.state.transactionValuesShow.total_transactions > 1000
              ? `${this.state.transactionValuesShow.total_transactions / 1000}K`
              : this.state.transactionValuesShow.total_transactions}
          </ProjectText>
            <ProjectText style={webStyle.percentType}>Total Number of Transactions</ProjectText>
          </Grid>

          <Grid item xs={12} sm={12} md={4} style={webStyle.outboundBox}>
          <ProjectText style={webStyle.percent}>
            {
              this.state.transactionValuesShow.total_teus > 1000
                ? `${this.state.transactionValuesShow.total_teus / 1000}K`
                : this.state.transactionValuesShow.total_teus
            }
          </ProjectText>
            <ProjectText style={webStyle.percentType}>Total Number of TEUs</ProjectText>
          </Grid>
          <Grid item xs={12} sm={12} md={4} style={webStyle.outboundBox}>
          <ProjectText style={webStyle.percent}>{
            this.state.transactionValuesShow.total_transaction_values > 1000
              ? `${this.state.transactionValuesShow.total_transaction_values / 1000}K`
              : this.state.transactionValuesShow.total_transaction_values
          }</ProjectText>
            <ProjectText style={webStyle.percentType}>Total Transaction Values (USD)</ProjectText>
          </Grid>
        </PercentBox>
      </>
    )
  }
  getBorderColor = (
    isBuyerRole: boolean,
    requestColorName: string,
    principalColorCode: string
  ): string => {
    return isBuyerRole ? requestColorName : principalColorCode;
  };
  getOrganizationCode = (
    isBuyerRole: boolean,
    requestPrincipalOrgCode: string,
    requestOrgCode: string,
    principalOrgCode: string,
    orgCode:string
  ): string => {
    if (isBuyerRole) {
      return requestPrincipalOrgCode || requestOrgCode;
    } else {
      return principalOrgCode || orgCode;
    }
  };
  renderTerms = (items:PastTransactRecordCoreDataTypes) => {
    if(this.state.isBuyerRole) {
      if(items.attributes.offer_terms_buyers && items.attributes.offer_sec_terms_buyers) return ' | '
    } else {
      if(items.attributes.offer_sec_terms_sellers && items.attributes.offer_terms_sellers) return ' | '
    }
    return ''

  }
  renderPastTransactTableBody = () => {
    
    return (
        <CustomTableBody>
          {this.state.pastTransactionsData
            ?.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
            .map((items: HistoryPastTransactRecordTypes ) => {
              const borderColor: string = this.getBorderColor(
                this.state.isBuyerRole,
                items.attributes.request_color_name,
                items.attributes.principal_color_code
              );
              const organizationCode: string = this.getOrganizationCode(
                this.state.isBuyerRole,
                items.attributes.request_principal_org_code,
                items.attributes.request_org_code,
                items.attributes.seller_principal_org_code,
                items.attributes.seller_org_code
              );
              
              return (
              <TableRow
              >
                
                <TableCell style={{
                  borderLeft: `4px solid ${borderColor}`,
                  borderRadius: "8px"
                }}>
                  <ProjectText
                    fontweight="400"
                    TextSize="14px"
                    style={{
                      background: borderColor ,
                      textAlign: "center",
                      padding: "4px 10px", borderRadius: "4px",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {organizationCode}
                  </ProjectText>
                </TableCell>
                <TableCell style={webStyle.bodyHistoryAlignment}>
                  <ProjectText fontweight="400" TextSize="14px">
                    {this.state.fullName}
                  </ProjectText>
                </TableCell>
                <TableCell style={webStyle.bodyHistoryAlignment}>
                  <ProjectText fontweight="400" TextSize="14px">
                    {items.attributes.booking_uid}
                  </ProjectText>
                </TableCell>
                <TableCell>
                  <ProjectText fontweight="400" TextSize="14px">
                    {moment.parseZone(items.attributes.etb).parseZone().format("DD-MMM-YYYY")}
                    <br />
                    {moment.parseZone(items.attributes.etb).parseZone().format("h:mm A")}
                  </ProjectText>
                </TableCell>
                <TableCell>
                  <ProjectText fontweight="400" TextSize="14px">
                    {items.attributes.vessel_voyage && (
                      <>
                        {items.attributes.vessel_voyage}{" "}
                        <br />
                        {items.attributes.in_voyage_code && (
                          <>
                            {items.attributes.in_voyage_code}/
                          </>
                        )}
                      </>
                    )}
                    {items.attributes.out_voyage_code}
                  </ProjectText>
                </TableCell>
                <TableCell>
                  <ProjectText fontweight="400" TextSize="14px">
                    {items.attributes.pol}/
                    <br />
                    {items.attributes.pol_terminal}
                  </ProjectText>
                </TableCell>
                <TableCell>
                  <ProjectText fontweight="400" TextSize="14px">
                    {items.attributes.pod}/
                    <br />
                    {items.attributes.pod_terminal}
                  </ProjectText>
                </TableCell>
                <TableCell>
                  <ProjectText TextSize="14px">
                    {moment(items.attributes.eta).parseZone().format("DD-MMM-YYYY")}
                    <br />
                  </ProjectText>
                </TableCell>
                <TableCell style={{ padding: 0, ...webStyle.rowText }}>
                  <p className="firstchild" style={{background:borderColor,border:` 1px solid ${borderColor}`}}>{items.attributes.container_type}</p>
                </TableCell>
                <TableCell style={{ padding: 0, ...webStyle.rowText }}>
                  <p className="secondchild" style={{background:borderColor,border:` 1px solid ${borderColor}`}}>
                    {items.attributes.container_code?.map((codes: string, index : number, array : string[]) => {
                      const isNotLast = index < array.length - 1;
                      return (
                        <span key={codes}>{codes}{isNotLast ? ', ' : ''}</span>
                      );
                    })}
                  </p>
                </TableCell>
                <TableCell style={{ padding: 0, ...webStyle.rowText}}>
                 <div className="rowWhiteSpace">
                  <p className={items.attributes.offer_sec_rate_by_seller ? "thirdchild" : "thirdchild_2ndCase"} style={{background:borderColor,border:` 1px solid ${borderColor}`}}>
                  <span style={{fontWeight:"bold"}}>  ${items.attributes.offer_seller_rate}</span>
                    {items.attributes.container_size && `/${items.attributes.container_size}`}
                  </p>
                  {
                    items.attributes.offer_sec_rate_by_seller &&
                    <p className="lastchild" style={{background:borderColor}}><span style={{paddingRight:"2px"}}>|</span>
                     <span style={{fontWeight:"bold"}}> {`$${items.attributes.offer_sec_rate_by_seller}`} </span>
                      {items.attributes.sec_container_sizes ? `/${items.attributes.sec_container_sizes}` : ''}
                    </p>
                  }
                  </div>
                </TableCell>
                <TableCell style={{padding:0,...webStyle.rowText}}>
                  <ProjectText fontweight="400" TextSize="14px">
                    <span className="rowWhiteSpace">
                    {this.state.isBuyerRole ? items.attributes.offer_terms_buyers : items.attributes.offer_terms_sellers}
                    {this.renderTerms(items)}
                    {items.attributes.offer_sec_terms_sellers && items.attributes.offer_sec_terms_buyers && (
                      <> {this.state.isBuyerRole ? items.attributes.offer_sec_terms_buyers : items.attributes.offer_sec_terms_sellers}</>
                    )}
                    </span>
                  </ProjectText>
                </TableCell>
                <TableCell style={webStyle.bodyHistoryAlignment}>
                  <ProjectText fontweight="400" TextSize="14px" style={{whiteSpace:'nowrap'}}>
                    {items.attributes.offer_quantity}x<span style={{ fontWeight: 600, fontFamily: "Asap", fontSize: "14px" }}>{items.attributes.container_size}</span>
                    {items.attributes.offer_sec_quantity && (
                      <> | {items.attributes.offer_sec_quantity}x<span style={{ fontWeight: 600, fontFamily: "Asap", fontSize: "14px" }}>{items.attributes.sec_container_sizes}</span></>
                    )}                        </ProjectText>
                </TableCell>
                <TableCell style={{ position: "relative" }}>
                  <img
                    src={threedot}
                    data-test-id="handleDotClick"
                    onClick={(e) => this.handleDotClick(e, items.id)}
                    style={{ cursor: "pointer" }}
                  />
                    <Popover
                      data-test-id="viewButton"
                      id={items.id}
                      open={Boolean(this.state.anchorEl)}
                      anchorEl={this.state.anchorEl}
                      onClose={this.handleCloseViewDetails}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                      PaperProps={{
                        style: {
                          boxShadow: '0px 1px 3px rgba(0,0,0,0.12), 0px 1px 2px rgba(0,0,0,0.24)', // Adjust these values as needed
                        },
                      }}
                    >
                      <Typography data-test-id="handleViewDeatils" onClick={() => this.hadleViewDeatils(items.id)} style={webStyle.infoBox as React.CSSProperties} >VIEW DETAILS</Typography>
                    </Popover>
                </TableCell>
              </TableRow>
            )})}
        </CustomTableBody>
    )
  }


  renderPastTransactionTableRecords = () => {
    return (
      <Box width="100%" marginTop="50px">
      <Spinner spinnerModal={this.state.pastTransactLoading} />
      <ProjectText TextSize="28px" fontweight="700">
        Past Transactions
      </ProjectText>
      <ProjectText TextSize="12px" fontweight="500px">
        View previous completed transactions
      </ProjectText>

      <TableContainer style={{ marginTop: "30px" }}>
        {
          this.state.pastTransactionsData.length !== 0 ? 
        <HistoryStyledTable aria-label="simple table">
          <TableHead style={webStyle.tableHeadingBox}>

            <TableRow style={{ marginBottom: "20px" }}>
              <StyledTableCell style={webStyle.tableOrgHeading}
              >
                ORG
              </StyledTableCell>
              <StyledTableCell style={webStyle.tableHeading}>USER</StyledTableCell>
              <StyledTableCell style={webStyle.tableHeading}>
                BOOKING ID
              </StyledTableCell>
              <StyledTableCell style={webStyle.tableHeading}>ETB</StyledTableCell>
              <StyledTableCell style={webStyle.tableHeading}>VES/VOY</StyledTableCell>
              <StyledTableCell style={webStyle.tableHeading}>POL</StyledTableCell>
              <StyledTableCell style={webStyle.tableHeading}>POD</StyledTableCell>
              <StyledTableCell style={webStyle.tableHeading}>ETA</StyledTableCell>
              <StyledTableCell style={webStyle.tableHeading}>STATUS</StyledTableCell>
              <StyledTableCell style={webStyle.tableHeading}>TYPES</StyledTableCell>
              <StyledTableCell style={webStyle.tableHeadingRate}>SELLER PRICE(USD)</StyledTableCell>
              <StyledTableCell style={webStyle.tableHeadingRate}>TERMS</StyledTableCell>
              <StyledTableCell style={webStyle.tableHeading}>
                QUANTITY (TEU)
              </StyledTableCell >
              <StyledTableCell style={{ padding: '25px 16px', borderBottomRightRadius: '8px' }} />
            </TableRow>
          </TableHead>

          {/* past transactions dynamic table body records code */}
          {
            this.renderPastTransactTableBody()
          }
        </HistoryStyledTable>
        : 
          this.renderNoDataFound()
        }
        {this.state.pastTransactionsData?.length > 20
          &&
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={this.state.pastTransactionsData?.length || 0}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        }


      </TableContainer>
      </Box>
    )
  }

  TextWithEllipsis = ({ text, maxLength }: any) => {
    if (!text || text.length <= maxLength) {
      return <span>{text}</span>;
    }
    const truncatedText = text.slice(0, maxLength) + '...';
    return (
      <span title={text}>
        {truncatedText}
      </span>
    );
  };

  renderViewDetailsBookingSteperDialogBlock = (steps : StepsType[]) => {
    const bookingAttributes = this.state.selectedViewDetails?.attributes;
    return (
      <DiologStyle
          open={this.state.isPopupOpen}
          onClose={this.handleDialogCLose}
        >
          <div style={{ background: "#DCECFF", paddingBottom: "30px", paddingRight: "17px" }}>
            <div
              style={{ textAlign: "end", padding: "10px", cursor: "pointer" }}
            >
              <img
                src={closeBtn}
                id="handleDialogCLose"
                onClick={() => this.handleDialogCLose()}
              />
            </div>
            <div>
              <ProjectText
                fontweight="700"
                TextSize="28px"
                style={webStyle.bookingDetail}
              >
                Booking Details
              </ProjectText>
              <Grid
                container
                spacing={0}
                style={{ paddingLeft: "50px", marginTop: "20px" }}
              >
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <ProjectText fontweight="700" TextSize="14px">
                    SLOTXPERT #
                  </ProjectText>
                  <ProjectText fontweight="500" TextSize="14px">
                    {this.state.selectedViewDetails?.attributes?.booking_uid}
                  </ProjectText>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12} >
                  <ProjectText fontweight="700" TextSize="14px">
                    VESSEL/VOYAGE
                  </ProjectText>
                  <ProjectText fontweight="500" TextSize="14px">
                    {this.TextWithEllipsis({ text: bookingAttributes?.vessel_voyage && `${bookingAttributes.vessel_voyage} /`, maxLength: 25 })}
                    <br />{this.TextWithEllipsis({ text: bookingAttributes?.in_voyage && `IN- ${bookingAttributes.in_voyage} OUT- ${bookingAttributes.out_voyage}`, maxLength: 25 })}
                  </ProjectText>
                </Grid>
                <Grid item lg={2} md={2} sm={6} xs={12}>
                  <ProjectText fontweight="700" TextSize="14px">
                    POL
                  </ProjectText>
                  <ProjectText fontweight="500" TextSize="14px">
                    {this.state.selectedViewDetails?.attributes?.pol}
                  </ProjectText>
                </Grid>
                <Grid item lg={2} md={2} sm={6} xs={12} >
                  <ProjectText fontweight="700" TextSize="14px">
                    POD
                  </ProjectText>
                  <ProjectText fontweight="500" TextSize="14px">
                    {this.state.selectedViewDetails?.attributes?.pod}
                  </ProjectText>
                </Grid>
                <Grid item lg={2} md={2} sm={6} xs={12}>
                  <ProjectText fontweight="700" TextSize="14px">
                    SELLER BOOKING #
                  </ProjectText>
                  <ProjectText
                    fontweight="700"
                    TextSize="14px"
                    textcolor="#EE8F00"
                  >
                    {this.state.selectedViewDetails?.attributes?.booking_reference_number || this.state.selectedViewDetails?.attributes?.booking_status?.toUpperCase()}
                  </ProjectText>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <ProjectText fontweight="700" TextSize="14px">
                    CONTAINERS
                  </ProjectText>
                  <ProjectText fontweight="500" TextSize="14px">
                    {bookingAttributes?.offer_quantity && (
                      <>
                      {`${bookingAttributes.offer_quantity} X `}
                        {this.state.selectedViewDetails?.attributes?.container_size}
                        {this.state.selectedViewDetails?.attributes?.container_size && this.state.selectedViewDetails?.attributes?.container_type && " "}
                        {this.state.selectedViewDetails?.attributes?.container_type}
                      </>
                    )}
                    <br />
                    {bookingAttributes?.offer_sec_quantity && (
                      <>
                        {this.state.selectedViewDetails.attributes.offer_sec_quantity} X
                        {this.state.selectedViewDetails.attributes.sec_container_sizes}{" "}
                        {this.state.selectedViewDetails.attributes.container_type}
                      </>
                    )}
                  </ProjectText>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12} >
                  <ProjectText fontweight="700" TextSize="14px">
                    RATES
                  </ProjectText>
                  <div>
                    <ProjectText fontweight="700" TextSize="14px">
                      {this.state.selectedViewDetails?.attributes?.seller_offer_rate && (
                        <>${this.state.selectedViewDetails.attributes.seller_offer_rate}</>
                      )}
                      {this.state.selectedViewDetails?.attributes?.container_size && (
                        <>/<span style={{ fontWeight: 500 }}>{this.state.selectedViewDetails.attributes.container_size}</span></>
                      )}
                    </ProjectText>
                    <ProjectText fontweight="700" TextSize="14px">
                      {bookingAttributes?.offer_sec_quantity && (
                        <>
                          ${this.state.selectedViewDetails?.attributes?.seller_sec_offer_rate}/<span style={{ fontWeight: 500 }}>
                            {this.state.selectedViewDetails.attributes.sec_container_sizes}
                          </span>
                        </>
                      )}
                    </ProjectText>
                  </div>
                </Grid>
                <Grid item lg={2} md={2} sm={6} xs={12} >
                  <ProjectText fontweight="700" TextSize="14px">
                    ETB
                  </ProjectText>
                  <ProjectText fontweight="500" TextSize="14px">
                    {this.state.selectedViewDetails?.attributes?.etb && (
                      <>
                        {moment(this.state.selectedViewDetails.attributes.etb).parseZone().format("D MMM yyyy")} <br />
                        {moment(this.state.selectedViewDetails.attributes.etb).parseZone().format("hh:mm A")}
                      </>
                    )}
                  </ProjectText>
                </Grid>
                <Grid item lg={2} md={2} sm={6} xs={12} >
                  <ProjectText fontweight="700" TextSize="14px">
                    ETA
                  </ProjectText>
                  <ProjectText fontweight="500" TextSize="14px">
                    {moment(bookingAttributes?.eta).parseZone().format(
                      "D MMM yyyy"
                    )}{" "}
                    <br />
                    {moment(bookingAttributes?.eta).parseZone().format(
                      "hh:mm A"
                    )}
                  </ProjectText>
                </Grid>
              </Grid>
            </div>
          </div>
          <div style={{
            paddingTop: "40px",
            paddingBottom: "40px",
            paddingLeft: "50px",
            paddingRight: "50px",
          }}>

            
            {
              this.state.isBuyerRole ? 
              <VerticalFileWeb
                data-testid="nominateModal"
                newState={6}
                booking={this.state.selectedBooking}
                selectValue={""}
                bookingStatus = {true}
              />
              :

              <SellerVerticalFileWeb 
                data-test-id="SellerVerticalTest" 
                newState={7}
                selectValue={this.state.accordianData} 
                booking={this.state.selectedBooking} />
            }
          </div>
        </DiologStyle>
    )
  }


  renderTimeTravelsYellowGraph = (buttons : string[], FirstGraphOptions : {[key : string] : [] | {}}) => {
    return(
      <BarChartBox style={{  borderRadius: "16px",
      width: "100%",
      border: "1px solid #CDCDCD",
      marginTop: "30px" }}>
          <Spinner spinnerModal={this.state.timeIntervalLoading} />

          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              className="BarChartBoxTop"
            >
              <ProjectText style={webStyle.inoutText}>
                Transaction
                Value (USD)
              </ProjectText>
              <div style={webStyle.buttonGroup}>
                {buttons.map((button) => (
                  <Button
                    id="buttonClick" data-test-id="buttonClick" key={button}
                    onClick={() => this.handleButtonClick(button)}
                    style={{
                      ...webStyle.selectButton, backgroundColor: this.state.selectedButton === button ? "#1653DD" : "#EEF2F5",
                      color: this.state.selectedButton === button ? "#FFFFFF" : "#797979",
                      minHeight: "42px"
                    }}
                  >{button}
                  </Button>
                ))}
              </div>
            </Grid>
            {this.renderTimeIntervalFilter()}
          </Grid>

          {/* TIME SERIES YELLOW GRAPH */}

          {this.state.timeSeriesShow?.data?.length !== 0 ?
            <div style={{ width: '100%', overflowX: 'auto', boxSizing: 'border-box' }}>
              <div style={{ minWidth: '1440px' }}>
                <ReactECharts
                  option={FirstGraphOptions}
                  style={{ height: "500px", marginTop: -20, left: "-6%" }}
                />
              </div>
            </div>
            :
            this.renderNoDataFound()
          }


          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "-3px",
              paddingBottom: "30px"
            }}
          >
            <ProjectText fontweight="600" TextSize="16px">
              {this.state.selectedButton}
            </ProjectText>
          </div>
        </BarChartBox>
    )
  }


  renderUserTransactionGraph = (UsersTransactionsGraph :  {[key : string] : [] | {}}) => {
    return (
      <Grid item xs={12} sm={12} md={6}>
            <Spinner spinnerModal={this.state.userTransactLoading} />
            <Box>
              <ProjectText TextSize="28px" LineHeight="36px" fontweight="900">
                Users Transactions
              </ProjectText>
              <ProjectText
                fontweight="500"
                LineHeight="16px"
                TextSize="12px"
                className="Colors"
              >
                View individual user's transactions values and container's
                quantity
              </ProjectText>
            </Box>
            <Box style={{ ...webStyle.barChartBox, height:"625px", overflowY:"auto" }}>
              <TransactedPODsBox>
                <CustomAutoSelete
                  id="userTransSizeFilterTestID"
                  style={{ marginRight: "20px" }}
                  value={this.state.userTransactSizeFilter}
                  options={this.state.containerSizeDropdown}
                  getOptionLabel={(sizeOption: unknown) => sizeOption as string ?? ""}
                  onChange={(event: React.ChangeEvent<{}>, newValue) => {
                    this.setState({
                      userTransactSizeFilter: newValue ?? ""
                    }, () => this.FilterUserTransactionsSelect())
                  }}
                  renderInput={(params) => (<TextField data-test-id="userTransSizeOptionFilterTestID" {...params} variant="outlined" />)}
                  renderOption={(options:unknown) => (
                    <ProjectText
                      variant="h6"
                      TextSize="14px"
                      fontweight="500"
                    >
                      {options as string ?? ""}
                    </ProjectText>
                  )}
                />
                <CustomAutoSelete
                  id="userTransTypeFilterTestID"
                  options={this.state.containerTypeDropdown}
                  value={this.state.userTransactTypeFilter}
                  getOptionLabel={(typeOption: unknown) => typeOption as string ?? "No Data"}
                  onChange={(event: React.ChangeEvent<{}>, newValue) => {
                    this.setState({
                      userTransactTypeFilter: newValue ?? ""
                    }, () => this.FilterUserTransactionsSelect())
                  }}
                  renderInput={(params) => (<TextField data-test-id="userTransTypeOptionFilterTestID" {...params} variant="outlined" />)}
                  renderOption={(options: unknown) => (
                    <ProjectText
                      TextSize="14px"
                      variant="h6"
                      fontweight="500"
                    >
                      {options as string ?? "No Data"}
                    </ProjectText>
                  )}
                />
              </TransactedPODsBox>


              {this.state.userTransactionsShow.length !== 0 ?
                <>
                  <div style={{ width: '100%', overflowX: 'auto', boxSizing: 'border-box' }}>
                  <UserTransactionBoxTitle>
                    <ProjectText TextSize="14px" fontweight="600">
                      Total Transacted
                      <br />
                      Value (USD)
                    </ProjectText>
                    <ProjectText
                      TextSize="14px"
                      fontweight="600"
                      style={{ textAlign: "end" }}
                    >
                      Total Number
                      <br />
                      of TEUs
                    </ProjectText>
                  </UserTransactionBoxTitle>
                    <div style={{ minWidth: '800px' }}>
                      <ReactECharts
                        option={UsersTransactionsGraph}
                        style={{ height: "400px" }}
                      />
                    </div>
                  </div>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "-11px",
                      paddingBottom: "40px"
                    }}
                  >
                    <ProjectText TextSize="14px" fontweight="600">
                      User
                    </ProjectText>
                  </Box>
                </> :
                this.renderNoDataFound()
              }

            </Box>
          </Grid>
    )
  }
 

  renderTransactPODSUserTransactGraphs = (TransactedGraphOptions : {[key : string] : [] | {}}, UsersTransactionsGraph :  {[key : string] : [] | {}}) => {
    return (
      <Grid container spacing={2} style={{ marginTop: "40px" }}>
          <Grid item xs={12} sm={12} md={6}>
            <Spinner spinnerModal={this.state.transactedPodsLoading} />
            <Box>
              <ProjectText TextSize="28px" LineHeight="36px" fontweight="900">
                Transacted PODs <img src={imagebutton_help} />
              </ProjectText>
              <ProjectText
                fontweight="500"
                LineHeight="16px"
                TextSize="12px"
                className="Colors"
              >
                View POD and/or region transactions
              </ProjectText>
            </Box>
            <Box style={{ ...webStyle.barChartBox, height:"625px", overflowY:"auto" }}>
              <TransactedPODsBox>
                <CustomAutoSelete
                  style={{ marginRight: "20px" }}
                  id="transPodsRegeionFilterTestID"
                  options={Array.isArray(this.state.regionFilterDropdown) ? this.state.regionFilterDropdown : []}
                  value={this.state.transPodsRegeionDropdownFilter}
                  getOptionLabel={(option: unknown) => typeof option === 'string' ? option : ""}
                  onChange={(event:  React.ChangeEvent<{}>, newValue) => {
                    this.setState({
                      transPodsRegeionDropdownFilter: newValue ?? "",
                    }, () => this.FilterTransactedPODSSelect())
                  }}
                  renderInput={(params) => (<TextField data-test-id="transPodsRegeionFilterOptionsTestID" {...params} variant="outlined" />)}
                  renderOption={(option: unknown) => (
                    <ProjectText
                      variant="h6"
                      fontweight="500"
                      TextSize="14px"
                    >
                      {option as string ?? ""}
                    </ProjectText>
                  )}
                />

                <CustomAutoSelete
                  id="transPodsVesselFilterTestID"
                  options={this.state.vesselVoyageDropdown}
                  value={this.state.transPodsVesselVoyageFilter}
                  getOptionLabel={(option: unknown) => option ? option as string : ""}
                  onChange={(event: React.ChangeEvent<{}>, newValue) => {
                    this.setState({
                      transPodsVesselVoyageFilter: newValue ?? ""
                    }, () => this.FilterTransactedPODSSelect())
                  }}
                  renderInput={(params) => (<TextField data-test-id="transPodsVesselFilterOptionsTestID" {...params} variant="outlined" />)}
                  renderOption={(option: unknown) => (
                    <ProjectText
                      variant="h6"
                      fontweight="500"
                      TextSize="14px"
                    >
                      {option as string ?? ""}
                    </ProjectText>
                  )}
                />
              </TransactedPODsBox>

              {/* TRANSACTED PODS BLUE GRAPH */}

              {this.state.transactedPODSShow.length !== 0 ?
                 <>
                 <div>
                   <ProjectText
                     fontweight="600"
                     TextSize="14px"
                     style={{ paddingLeft: "30px" }}
                   >
                     DESTINATION <br />
                     PORT LOCATION
                   </ProjectText>
                 </div>
                 <div style={{height:"400px",overflowY:"auto"}}>
                 <ReactECharts
                   option={TransactedGraphOptions}
                   style={{ height: "100%", width: "100%" }}
                 />
                 </div>
                 <div
                   style={{
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     paddingBottom: "30px",
                     paddingTop:"40px"
                   }}
                 >
                   <div
                     style={{
                       width: "16px",
                       height: "16px",
                       background: "#1653DD",
                       borderRadius: "4px"
                     }}
                   />
                   <ProjectText
                     TextSize="14px"
                     fontweight="500"
                     style={{ marginLeft: "15px", marginRight: "15px" }}
                   >
                     Transacted Value (USD)
                   </ProjectText>
                   <div
                     style={{
                       width: "16px",
                       height: "16px",
                       background: "#E8B32D",
                       borderRadius: "4px"
                     }}
                   />
                   <ProjectText
                     TextSize="14px"
                     fontweight="500"
                     style={{ marginLeft: "15px", marginRight: "15px" }}
                   >
                     Transacted Container (TEUs)
                   </ProjectText>
                 </div>
               </>
                :
                this.renderNoDataFound()
              }



            </Box>
          </Grid>
          {
            this.renderUserTransactionGraph(UsersTransactionsGraph)
          }
        </Grid>
    )
  }

  MainBaner = () => {

    const steps = [
      {
        label: "Issue Booking Reference Number",
        time: this.helperDateFormatFunc(this.state.selectedViewDetails?.attributes?.booking_reference_number_at, false),
        date: this.helperDateFormatFunc(this.state.selectedViewDetails?.attributes?.booking_reference_number_at, true),
      },
      {
        label: "Authorize Container Operator",
        keydata: "auth_cont",
        container: [
          { label: "Container Nomination by Buyer", time: this.helperDateFormatFunc(this.state.selectedViewDetails?.attributes?.nominated_at, false), date: this.helperDateFormatFunc(this.state.selectedViewDetails?.attributes?.nominated_at, true) },
          { label: "Shipping Instructions from Buyer", time: this.helperDateFormatFunc(this.state.selectedViewDetails?.attributes?.submitted_si_at, false), date: this.helperDateFormatFunc(this.state.selectedViewDetails?.attributes?.submitted_si_at, true) }
        ],
        time: this.helperDateFormatFunc(this.state.selectedViewDetails?.attributes?.co_authorizer_at, false),
        date: this.helperDateFormatFunc(this.state.selectedViewDetails?.attributes?.co_authorizer_at, true),
      },
      {
        label: "Shared Draft B/L",
        container: [{ label: "Draft B/L Verified by Buyer", time: this.helperDateFormatFunc(this.state.selectedViewDetails?.attributes?.verified_draft_bl_at, false), date: this.helperDateFormatFunc(this.state.selectedViewDetails?.attributes?.verified_draft_bl_at, true) }],
        time: this.helperDateFormatFunc(this.state.selectedViewDetails?.attributes?.upload_draft_bl_at, false),
        date: this.helperDateFormatFunc(this.state.selectedViewDetails?.attributes?.upload_draft_bl_at, true),
      },
      {
        label: "Shared Invoice",
        container: [{ label: "Payment Confirmation Uploaded by Buyer", time: this.helperDateFormatFunc(this.state.selectedViewDetails?.attributes?.payment_confirmed_at, false), date: this.helperDateFormatFunc(this.state.selectedViewDetails?.attributes?.payment_confirmed_at, true) }],
        time: this.helperDateFormatFunc(this.state.selectedViewDetails?.attributes?.upload_invoice_at, false),
        date: this.helperDateFormatFunc(this.state.selectedViewDetails?.attributes?.upload_invoice_at, true),
      },
      {
        label: "Container Loaded onto Vessel",
        time: this.helperDateFormatFunc(this.state.selectedViewDetails?.attributes?.co_authorizer_at, false),
        date: this.helperDateFormatFunc(this.state.selectedViewDetails?.attributes?.co_authorizer_at, true),
      },
      {
        label: "Final B/L sent",
        time: this.helperDateFormatFunc(this.state.selectedViewDetails?.attributes?.upload_final_bl_at, false),
        date: this.helperDateFormatFunc(this.state.selectedViewDetails?.attributes?.upload_final_bl_at, true),
      }
    ];


    const FirstGraphOptions = {
      tooltip: {
        show: true
      },
      xAxis: {
        type: 'category',
        data: this.state.timeItervalsGraphShow,
        axisTick: {
          show: false,
        },
        axisLabel: {
          interval: 0,
          formatter: this.formatDate,
          textStyle: {
            rich: {
              a: {
                color: "#1E1E1E",
                fontSize: 14,
                fontWeight: 800,
                fontStyle: "Asap"
              },
              b: {
                color: "#ACACAC",
                fontSize: 14,
                fontWeight: 500,
                fontStyle: "Asap"
              }
            },
            lineHeight: 20
          }
        }
      },
      yAxis: {
        type: "value",
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed"
          }
        },
        axisLabel: {
          interval: 0,
          textStyle: {
            color: "#1E1E1E",
            fontSize: 14,
            fontWeight: 400
          }
        },
        axisTick: {
          show: false
        }
      },
      series: [
        {
          data: this.state.timeIntervalsGraphShowYAxis,
          type: "bar",
          itemStyle: {
            color: "#E8B32D",
            barBorderRadius: [8, 8, 0, 0]
          },
          barWidth: "50px"
        }
      ]
    };

    const TransactedGraphOptions = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow"
        },
        formatter: this.tooltipFormatter
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true
      },
      xAxis: {
        type: "value",
        splitLine: {
          show: false
        },
        axisLabel: {
          show: false
        }
      },
      yAxis: {
        type: "category",
        data: this.state.selectedTransactedPodsVesselCode,
        axisTick: {
          show: false
        },
        axisLabel: {
          interval: 0,
          textStyle: {
            color: "#1653DD",
            fontSize: 14,
            fontWeight: 400
          }
        }
      },
      series: [
        {
          // blue transaction
          name: "Value 1",
          type: "bar",
          data: this.state.transactedPodsValuesGraphShow,
          itemStyle: {
            color: "#1653DD",
            barBorderRadius: [0, 8, 8, 0]
          },
          barWidth: "40px"
        },
        {
          // yellow transaction
          name: "Value 2",
          type: "bar",
          data: this.state.transactedPodsTuesGraphShow,
          itemStyle: {
            color: "#E3A917",
            barBorderRadius: [0, 8, 8, 0]
          },
          barWidth: "40px"
        }
      ]
    };


    const UsersTransactionsGraph = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow"
        }
      },
      xAxis: {
        type: "category",
        data: this.state.userTransactionUserNamesGraphShow,
        axisLine: {
          show: false,
          lineStyle: {
            color: "#808080"
          }
        },
        axisLabel: {
          interval: 0,
          textStyle: {
            color: "#000000",
            fontSize: 14,
            fontWeight: 400
          },
        },
        axisTick: {
          show: false
        }
      },
      yAxis: [
        {
          type: "value",
          name: "",
          splitLine: { show: true },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#1653DD"
            }
          },
          axisLabel: {
            formatter: this.valueFormatter,
            textStyle: {
              color: "#000000",
              fontSize: 14,
              fontWeight: 400
            }
          },
          axisTick: {
            show: true
          }
        },
        {
          type: "value",
          name: "",
          position: "right",
          splitLine: { show: false },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#FF3A9C"
            }
          },
          axisTick: {
            show: true
          }
        }
      ],
      series: [
        {
          name: "Value(USD)",
          type: "bar", 
          data: this.state.userTransactionValuesGraphShow,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "#1653DD" },
              { offset: 0.5, color: "#1653DD" },
              { offset: 1, color: "#C3DEFF" }
            ]),
            barBorderRadius: [8, 8, 0, 0]
          },
          barWidth: "48px"
        },
        {
          name: "TEUs",
          type: "line",
          yAxisIndex: 1,
          data: this.state.userTransactionTeusGraphShow,
          lineStyle: {
            color: "#FF3A9C",
            width: 1,
            type: "solid"
          },
          symbol: "none"
        }
      ]
    };

    const buttons = ["DAILY", "WEEKLY", "MONTHLY"];


    return (
      <Grid className="firstContent">
        {/* globle filters code */}
        {
          this.renderGlobleFiltersBlock()
        }

        {/* View Updates , Download csv, clear filters & transacted values code */}
        {
          this.renderViewUpdateCSVAndPercentTransactBlock()
        }

        {/* Time series yello graph daily, weekly, monthly code */}
        {
          this.renderTimeTravelsYellowGraph(buttons, FirstGraphOptions)
        }

        {/* Transacted PODS & User transaction blue pink rib graph code */}
        {
          this.renderTransactPODSUserTransactGraphs(TransactedGraphOptions, UsersTransactionsGraph)
        }

        {/* Past Transaction table code */}
        {
          this.renderPastTransactionTableRecords()
        }
       

       {/* view details clicked booking details screen dialog code */}

       {
        this.renderViewDetailsBookingSteperDialogBlock(steps)
       }

        
      </Grid>
    );
  };


  

  




  // Customizable Area End

  render() {
    // Customizable Area Start

    // Customizable Area End
    return (
      // Customizable Area Start
      <Box maxWidth="sm">
        <Navbar
          SellerPendingScreen={true}
          isBuyerScreen={this.state.isBuyerRole}
          AppBarTransparent={false}
        />
        <div style={webStyle.outerBox}>
          <InnerBox>
            <LandingPageStyle>{this.MainBaner()}</LandingPageStyle>
          </InnerBox>
        </div>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const PercentBox = styled(Grid)(
  {
  flexWrap: "unset",
  gap: "8px",
  marginTop: "20px",
  "@media (max-width:960px)": {
      display:"grid"
  }
});
const DiologStyle = styled(Dialog)(
  {
  "& .MuiDialog-paperWidthSm": {
    minWidth: "826px",
    borderRadius: "24px"
  },
  "@media (max-width:1000px)": {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "500px"
    }
  }
});

const HistoryStyledTable = withStyles((theme) => (
  {
  root: { borderCollapse: "separate", borderSpacing: "0 6px", rowGap: "10px", background: "transparent", },
}))(Table)

const StyledTableCell = withStyles((theme) => (
  {
  head: { color: "#1E1E1E", fontWeight: 600, fontFamily: "Asap", fontSize: "14px", fontStyle: "normal", lineHeight: "24px", background: "#E1E5EE", borderBottom: "2px solid #8A99BB" },
  body: { fontSize: 14, fontFamily: 'Asap' },
}))(TableCell)

const CustomTableBody = styled(TableBody)(
  {
  "& td p": {
    paddingBlock: 5
  },
  "& td .firstchild": {
    border: "1px solid #D0B655",
    borderRight: "none",
    borderTopLeftRadius: 5,
    background: "#FFECA5",
    borderBottomLeftRadius: 5,
    paddingLeft: 5,
    marginLeft: 10
  },
  "& td .secondchild":{
    background: "#FFECA5",
    paddingRight: "24px",
    borderLeft: "none",
    display: "flex",
    justifyContent: "center",
    border: "1px solid #D0B655",
    borderRight: "none",
  },
  "& td .thirdchild": {
    border: "1px solid #D0B655",
    borderRight: "none",
    background: "#FFECA5",
    borderLeft: "none",
    display: "flex",
    justifyContent: "end",
  },
  "& td .rowWhiteSpace": {
    display: "flex",
    whiteSpace: "nowrap"
  },
  "& td .thirdchild_2ndCase": {
    background: "#FFECA5",
    border: "1px solid #D0B655",
    display: "flex",
    justifyContent: "center",
    borderLeft: "none",
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    marginRight: "22px"
  },
  "& td .lastchild": {
    borderTopRightRadius: 5,
    background: "#FFECA5",
    paddingLeft: 8,
    paddingRight:4,
    display: "flex",
    justifyContent: "center",
    borderBottomRightRadius: 5,
    whiteSpace:'nowrap'
  },
  "& .MuiTableRow-root th:first-child": {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px"
  },
  "& .MuiTableRow-root th:last-child": {
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px"
  }
});

const LandingPageStyle = styled(Box)(
  {
  backgroundColor: "#FFF",

  "& .firstContent": {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start"
  },

  "& .input": {
    color: "#363636",
    fontFamily: "Asap",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    textTransform: "uppercase"
  },
  "& .overview": {
    color: "#1E1E1E",
    fontFamily: "Asap",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "36px"
  },
  "& .download": {
    display: "flex",
    padding: " 6px 12px",
    alignItems: "center",
    gap: "6px",
    borderRadius: "6px",
    background: "#E2E2E2"
  },
  "& .update": {
    display: "flex",
    color: "#ACACAC",
    fontFamily: "Asap",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "24px"
  },
  "& .mainDiv": {
    justifyContent: "space-between"
  },
  "& .divBox": {
    textAlign: "center",
    borderRadius: "16px",
    borderLeft: "4px solid #09F",
    background: "#97CDFF",
    padding: "50px",
    width: "32%"
  },
  "& .divBox2": {
    textAlign: "center",
    borderRadius: "16px",
    borderLeft: "4px solid #E3A917",
    background: "#F3CC67",
    padding: "50px",
    width: "32%"
  },
  "& .divBox3": {
    textAlign: "center",
    borderRadius: "16px",
    borderLeft: "4px solid #E3A917",
    background: "#F3CC67",
    padding: "50px",
    width: "32%"
  },
  "& .chart": {
    borderRadius: "16px",
    border: "1px solid #CDCDCD",
    background: "#FFF",
    textAlign: "center",
    marginTop: "40px"
  },
  "& .Display": {
    display: "flex"
  },
  "& .Gap": {
    gap: "15px"
  },
  "& .bgColor": {
    display: "flex",
    alignItems: "center",
    borderRadius: "50px",
    background: "#EEF2F5",
    padding: "4px 40px"
  },
  "& .rounded-button": {
    border: "none",
    outline: "none",
    borderRadius: "50px",
    cursor: "pointer",
    fontSize: "16px",
    color: "#797979",
    fontWeight: "900"
  },
  "& .rounded-button:hover": {
    backgroundColor: "#1653DD",
    color: "#FFFFFF",
    fontWeight: "900"
  },
  "& .rounded-button.active": {
    backgroundColor: "#1653DD",
    color: "#FFFFFF",
    fontWeight: "900"
  },
  "& .Colors": {
    color: "#363636"
  }
});

const webStyle = {
  btnDiv: {
    alignItems: "center",
    display: "flex"
  },
  org: {
    fontSize: "15px",
    background: "#B8D5F9",
    fontWeight: 500,
    fontFamily: "Asap",
    color: "#1E1E1E",
    padding: "6px 12px",
    borderRadius: "4px"
  },
  bodyHistoryAlignment: {
    textAlign: "center" as "center"
  },
  tableContent: {
    color: "#363636",
    fontFamily: "Asap",
    fontSize: "15px",
    fontWeight: 500
  },
  typeDiv: {
    display: "flex",
    justifyContent: "space-between",
    background: "#B8D5F9",
    fontSize: "15px",
    color: "#1E1E1E",
    fontWeight: 500,
    fontFamily: "Asap",
    padding: "6px 12px",
    border: "1px solid #6495D2",
    borderRadius: "4px",
    marginBottom: "3px"
  },
  total: {
    color: "#1E1E1E",
    fontSize: "15px",
    fontWeight: 500,
    fontFamily: "Asap",
    background: "#EAEAEA",
    padding: "5px 10px",
    borderRadius: "4px"
  },
  tableLeftBorder: {
    borderLeft: "3px solid #B8D5F9",
    borderRadius: "6px",
    textAlign: "center" as "center"
  },
  dFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    listStyle: "none"
  },
  textCenter: {
    textAlign: "center" as "center"
  },
  ellipsis: {
    cursor: "pointer"
  },
  inputText: {
    border: "1px solid #CBD5E1",
    height: "44px",
    width: "100%",
    borderRadius: "5px",
    padding: "0 8px"
  },
  expiryDate: {
    color: "#1E1E1E",
    fontSize: "14px",
    fontFamily: "Asap, sans-serif"
  },
  expiryDateDiv: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "40px",
    paddingLeft: "10px"
  },
  usd: {
    margin: "0 10px"
  },
  outerBox: {
    padding: "45px 20px",
    background: "#EFEFEF"
  },
  innerBox: {
    padding: "50px",
    background: "#FFFFFF"
  },
  inboundBox: {
    textAlign: "center" as "center",
    paddingBottom: "24px",
    borderLeft: "4px solid #0099FF",
    paddingTop: "24px",
    background: "#97CDFF",
    borderRadius: "16px"
  },
  percent: {
    fontFamily: "Asap" as "Asap",
    fontWeight: 700,
    color: "#000000",
    fontSize: "32px",
    lineHeight: "37px"
  },
  percentType: {
    fontWeight: 400,
    color: "#000000",
    fontFamily: "Asap",
    marginTop: "10px",
    fontSize: "16px",
    lineHeight: "18px"
  },
  outboundBox: {
    background: "#F3CC67",
    borderRadius: "16px",
    paddingBottom: "24px",
    textAlign: "center" as "center",
    paddingTop: "24px",
    borderLeft: "4px solid #E3A917"
  },
  barChartBox: {
    borderRadius: "16px",
    width: "100%",
    border: "1px solid #CDCDCD",
    marginTop: "30px"
  },
  inoutText: {
    fontWeight: 600,
    fontFamily: "Asap" as "Asap",
    fontSize: "14px",
    lineHeight: "18px",
    width: "95px",
    marginRight: "25px",
    color: "#1E1E1E"
  },
  buttonGroup: {
    background: "#EEF2F5",
    borderRadius: "20px"
  },
  selectButton: {
    fontFamily: "Asap",
    fontSize: "14px",
    borderRadius: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    fontWeight: 500
  },
  dropdown: {
    borderRadius: "4px",
    color: "#1E1E1E",
    fontFamily: "Asap" as "Asap",
    fontSize: "14px",
    fontWeight: 600,
    border: "1px solid #CDCDCD"
  },
  tableHeadingBox: {
    borderBottom: "3px solid #8A99BB",
    borderRadius: "8px",
    paddingTop: "24px",
    paddingBottom: "24px",
    marginTop: "30px",
    background: "#E1E5EE"
  },
  tableRow: {
    border: "1px solid #CDCDCD",
    paddingBottom: "16px",
    borderRadius: "8px",
    paddingTop: "24px",
    marginTop: "10px"
  },
  tableHeading: {
    textAlign: "center" as "center",
    fontWeight: 700,
    color: "#1E1E1E",
    fontFamily: "Asap" as "Asap",
    fontSize: "14px",
    lineHeught: "16px"
  },
  tableHeadingRate: {
    textAlign: "start" as "start",
    fontWeight: 700,
    color: "#1E1E1E",
    fontFamily: "Asap" as "Asap",
    fontSize: "14px",
    paddingLeft:"0"
 
  },
  tableOrgHeading: {
    padding: '25px 16px', 
    borderBottomLeftRadius: '8px',
    textAlign: "center" as "center",
  },
  rowText: {
    lineHeight: "16px",
    color: "#1E1E1E",
    fontWeight: 500,
    fontFamily: "Asap" as "Asap",
    fontSize: "14px",
    paddingInline: 0
  },
  rowTexts: {
    lineHeight: "18px",
    color: "#1E1E1E",
    fontWeight: 500,
    fontFamily: "Asap" as "Asap",
    fontSize: "14px",
    paddingInline: 0
  },
  infoBox: {
    background: "#fff",
    padding: "12px 12px",
    zIndex: 1000,
    borderRadius: "8px",
    // width: "125px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 4px 24px 0px #00000040",
    cursor: "pointer",
    color: "#1E1E1E",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Asap" as "Asap",
  },
  listItem: {
    color: "#1E1E1E",
    fontFamily: "Asap" as "Asap",
    fontSize: "14px",
    fontWeight: 600
  },
  bookingDetail: {
    display: "flex",
    justifyContent: "center"
  },
  noDataShow: {
    fontFamily: "Asap",
    paddingTop: "6rem",
  },
  noDataImgShow: {
    height: "4rem",
  },
  noDtataContent: {
    fontFamily: "Asap",
    fontSize: "1rem",
    margin: "0.5rem",
  },
};


const BootstrapInput = withStyles((theme) => (
  {
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    width: "auto",
    cursor: "pointer",
    borderRadius: 5,
    position: "relative", backgroundColor: theme.palette.common.white, border: ".0625rem solid #ced4da",
    fontSize: "1rem",
    padding: ".625rem .75rem",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: "Asap",
    color: "#363636",
    "&:focus": {
      borderColor: "##DCDCD",
      boxShadow: "rgba(149, 157, 165, 0.2) 0rem .5rem 1.5rem",
    },
  },
}))(InputBase);

const DatePickerStyle = styled(DateRangePicker)(
  {
  "& .MuiInputLabel-animated": {
    transition: "unset"
  },
  "& .DateMainClassName": {
    "& .MuiPaper-root": {
      paddingBottom: "60px !important",
      backgroundColor: "grey",
    }
  }
})

const DateContainer = styled(Box)(
  {
  "& .ButtonContaienr": {
    position: "absolute",
    display: "flex",
    justifyContent: "flex-end",
    bottom: "0px",
    right: "0px",
    zIndex: 9,
    padding: ".625rem .375rem"
  },
  "& .DateMainClassName": {
    "& .MuiPaper-root": {
      paddingBottom: "20px",
      "& .MuiGrid-wrap-xs-nowrap": {
        "&.MuiGrid-root": {
          "& .materialui-daterange-picker-MuiList-root-59": {
            display: "none"
          }
        }
      }
    },
    "& .MuiTypography-root": {
      fontWeight: "900 !important"
    },
    "& .materialui-daterange-picker-makeStyles-highlighted-57": {
      color: "red !important",
    }
  },
})

const CustomAutoSelete = styled(Autocomplete)(
  {
  "& .MuiOutlinedInput-root": {
    height: "44px",
    padding: "3px",
    color: "#1E1E1E",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Asap",
    paddingRight: "41px",
    width: "153px",
  },
  "& .MuiAutocomplete-clearIndicatorDirty": {
    display: "none"
  },
  "& .MuiAutocomplete-inputRoot": {
    paddingRight: "10px !important"
  }
})

const UserTransactionBoxTitle = styled(Box)(
  {
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: "15px",
  paddingRight: "15px",
  marginTop: "40px",
  "@media (max-width:1800px)": {
    width: "790px"
}
})

const TransactedPODsBox = styled(Box)(
  {
  display: "flex",
  justifyContent: "flex-end",
  padding: "24px 60px 24px 60px",
  "@media (max-width:500px)": {
    justifyContent: "center"
}
})

const CustomGlobleAutoSearchable = styled(Autocomplete)(
  {
  "& .MuiOutlinedInput-root": {
    height: "56px",
    color: "#1E1E1E",
    padding: "3px",
    fontSize: "14px",
    borderRadius: "4px",
    fontWeight: 600,
    fontFamily: "Asap",
    width: "100%",
  },
  "& .MuiAutocomplete-clearIndicatorDirty": {display: "none"},
  "& .MuiAutocomplete-inputRoot": {paddingRight: "10px !important"}
})


// Customizable Area End