// Customizable Area Start
import { 
  BlockComponent
 } from "../../../framework/src/BlockComponent";
import { 
  runEngine 
} from "../../../framework/src/RunEngine";
import MessageEnum, 
{
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { 
  Message
 } from "../../../framework/src/Message";
import {
  getStorageData, 
  setStorageData 
} from "../../../framework/src/Utilities";
import moment from "moment";
import {
   DateRange
   } from "materialui-daterange-picker";
import { 
  toast
 } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}

export interface S {
  currentTab: number;
  anchorEl: any;
  page: number;
  rowsPerPage: number;
  popup: boolean;
  profilePop: boolean;
  rows: any;
   switch: boolean;
   show:boolean;
   buyerListings: any;
   loading: boolean;
   buyerLoginData : any;
   addBuyerListing: boolean;
   buyerContainerData: Array<{
    buyerContainer_type: string;
    buyerBox_sizes: Array<{size: string}>;
    buyerCodes: Array<string>;
  }>;
  selectedContainer: any;
  buyerPrinicpalOrgList: any;
  buyerEtbDate: string;
  buyerEtaDate: string;
  buyerAddedListingID:string;
  buyerAvarageTons:string;
  buyerListingAdded : boolean;
  authToken : string;
  menuIsOpen: boolean,
  anchorElDropdown: any,
  listDeleted : boolean,
  catchDeleteIdKey : string,
  selectedBuyerContainerOneSize:  any,
  reinitialisedForm : boolean,
  buyerPolData : any,
  catchEditedData:any,
  triggerEditForm : boolean,
  buyerUpdatedListingID : string,
  updateBuyerListing : boolean,

  buyerRequestSnackbarOpen: boolean,
  isAddContainerClicked : boolean,
  buyersPriceAndTermData: Array<{
    id: string,
    BoxSize: string
    Container: string
    PortOfDestination: string
    PortOfLocation: string
    Rate: string
    TermsOne: string,
    TermsTwo: string,
  }>
  isEditContainerClicked : boolean,
  buyerExpanded : boolean,
  buyerExpandedPol : boolean,
  buyerExpandedPod : boolean,
  buyerExpandedType : boolean,
  buyerFilter: {
    listingID: Array<{ 
      label: string; 
      isChecked: boolean 
    }>;
    pol: Array<{ 
      label: string; 
      isChecked: boolean 
    }>;
    pod: Array<{ 
      label: string; 
      isChecked: boolean }>;
    type: Array<{ 
      label: string; 
      isChecked: boolean }>;
    startDate: string;
    endDate: string
  };
  isMatchesFound : boolean;
  reinitialisedEditedForm : boolean,
  isSlotFilterOpen: boolean,
  activeSlotFilter: any,
  open: boolean,
  StartDate: string,
EndDate: string,
RenderStart: string,
RenderEndDate: string,
DynamicCount: {
  listing?: string | number,
  requst?: string | number,
  offers?: string | number,
  booking?: string | number
},
DateRangeStartDate: Date | undefined,
  DateRangeEndDate: Date | undefined,
  requestPOLTerminalValue: any,
  requestPODTerminalValue: any,
  newPOLTerminalValue: any,
  newPODTerminalValue: any,
}

export interface SS {
    id: any;
}

export default class BuyersRequestController extends BlockComponent<
    Props,
    S,
    SS
> {

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        (async () => {
          let buyerLoginData = await getStorageData("UserRole");
          console.log("parsedLogindata", buyerLoginData)
          if(!buyerLoginData){
            window.location.href = "/"
          } else if(buyerLoginData == "seller"){
            window.location.href = "/SellerListing"
          }
        })()

        this.state = {
          StartDate: "",
          EndDate: "",
          RenderStart: "",
          RenderEndDate: "",
          open: false,
            currentTab: 0,
            anchorEl: null,
            rowsPerPage: 10,
            page: 0,
            popup: false,
            profilePop: false,
            switch:true,
            rows:[{
                org_code: "HMM",
                request: 30711,
                date: "1 JUN 23 12:00 PM",
                pol_terminal: "SGSIN/TCN",
                pod_terminal: "INMUN/BCN",
                loading_date_range: "2 JUN - 2 SEP",
                container_type: "EMPTY",
                container_codes: "MT, MH, RH",
                quantity: 100,
                requested_price: "$120/40FT",
                total: "$50000",
                terms: "CY/CY",
              }],
            show:false,
            buyerListings : [],
            buyerLoginData : {},
            loading: true,
            addBuyerListing: false,

            selectedContainer: [],
            buyerContainerData: [],
            buyerPrinicpalOrgList: [],
            buyerEtbDate: "",
            buyerEtaDate: "",
            buyerAddedListingID: "",
            buyerAvarageTons: "",
            buyerListingAdded : false,
            authToken : "",
            menuIsOpen: false,
            anchorElDropdown: null,
            listDeleted : false,
            catchDeleteIdKey : "",
            selectedBuyerContainerOneSize : "",
            buyerPolData : [],
            reinitialisedForm : false,
            catchEditedData:[],
            triggerEditForm : false,
            buyerUpdatedListingID : "",
            isAddContainerClicked : false,
            updateBuyerListing : false,
            buyerRequestSnackbarOpen: false,
            isEditContainerClicked : false,
            buyersPriceAndTermData : [],
            buyerExpandedPol : false,
            buyerExpanded : false,
            buyerExpandedType : false,
            buyerExpandedPod : false,
            buyerFilter: {
              listingID: [],
              pol: [],
              pod: [],
              type: [],
              startDate: '',
              endDate: ""
            },
            reinitialisedEditedForm : false,
            isMatchesFound : false,
            isSlotFilterOpen: false,
            activeSlotFilter: [],
            DynamicCount: {
              listing: "0",
              requst: "0",
              offers: "0",
              booking: "0"
            },
            DateRangeStartDate: new Date(),
            DateRangeEndDate: new Date(),
            requestPOLTerminalValue: null,
            requestPODTerminalValue: null,
            newPOLTerminalValue: null,
            newPODTerminalValue: null,

        };
    }
    
    async componentDidMount() {
        let buyerLoginData = await getStorageData("loginData");
        let LocationToken = await getStorageData("authToken");
        let obj = JSON.parse(buyerLoginData)
        this.setState({
          buyerLoginData: obj
        })
        this.OnloadApicall(LocationToken)
    }

    OnloadApicall = (LocationToken: string) => {
      this.setState({ authToken: LocationToken });
      this.getBuyerPrincipalOrganisation(LocationToken);
        this.getBuyerListingData(LocationToken);
        this.getBuyersPriceAndTerms()
        this.getBuyerDateData(LocationToken);
        this.getBuyersPolDataFunc(LocationToken)
        this.getBuyerContainerList(LocationToken);
    };

    getBuyerPrincipalOrganisationDataApiCallID : string = "";
    getBuyerListingDataApiCallID: string = "";
    getBuyerDateDataApiCallID : string = "";
    getBuyerContainerListApiCallID : string = "";
    getBuyersPolData : string = "";
    deleteBuyerListingApiCallID : string = "";
    updateBuyerListingApiCallID : string = "";
    getBuyersPriceAndTearmsDataApiCallID : string = "";
    
    applyBuyerFilterApiCallID : string = "";
    onRoleSwitchAPICallID : string = "";
    

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        switch (apiRequestCallId) {
          case this.getBuyerListingDataApiCallID:
            this.setState({ buyerListings: responseJson, loading: false });
            this.onFilteredGetDataForListing(responseJson)
            break;
        
          case this.getBuyerPrincipalOrganisationDataApiCallID:
            this.setState({ buyerPrinicpalOrgList: responseJson?.data });
            break;
        
          case this.getBuyerContainerListApiCallID:
            const Data = responseJson.data?.map((item: {
              attributes: {
                container_type: string;
                request_box_sizes: Array<string>;
                codes: Array<string>;
              };
            }) => {
              return {
                buyerContainer_type: item.attributes.container_type,
                buyerBox_sizes: item.attributes.request_box_sizes,
                buyerCodes: item.attributes.codes,
              };
            });
            this.setState({ buyerContainerData: Data });
            break;
        
          case this.getBuyerDateDataApiCallID:
            this.setState({
              buyerEtbDate: responseJson.container_loading_range_end,
              buyerEtaDate: responseJson.container_loading_range_start,
            });
            break;
        
          case this.deleteBuyerListingApiCallID:
            if (responseJson.message === "Request deleted successfully") {
              toast.success("Buyer request deleted successfully")
              this.setState({ listDeleted: false, catchDeleteIdKey: "" });
              this.getBuyerListingData(this.state.buyerLoginData.token);

            }
            break;
        
          case this.getBuyersPolData:
            if(responseJson?.data){
              const uniquePorts = responseJson?.data?.filter((port : any, index : number, self : any) => {
              return index === self.findIndex((p: any) => p.attributes.port_code === port.attributes.port_code);
              }).map((port : any) => {
              const uniqueCountries = [...new Set(port.attributes.port_country)];return { ...port, attributes: { ...port.attributes, port_country: uniqueCountries } };});
              this.setState({ buyerPolData: uniquePorts });
          } else {this.setState({ buyerPolData: [] });}
            break;
        
          case this.updateBuyerListingApiCallID:
            if (responseJson.message) {
              this.setState({ buyerUpdatedListingID: responseJson.request.request_uid, updateBuyerListing: false, buyerListingAdded: true , selectedContainer : {}, isEditContainerClicked : false, loading: false});
              this.getBuyerListingData(this.state.buyerLoginData.token);
            }
            break;
          
          case this.getBuyersPriceAndTearmsDataApiCallID:
            let catachPriceandtermsdata = responseJson?.data.map((item: {
              id: string, attributes: {
                box_size: string,
                container_type: string, id: number
                port_of_destination: string,
                port_of_location: string
                price_and_currency: string,
                terms: string
              }
            }) => {
              return {
                id: item.id,
                BoxSize: item.attributes.box_size,
                Container: item.attributes.container_type,
                PortOfDestination: item.attributes.port_of_destination,
                PortOfLocation: item.attributes.port_of_location,
                Rate: item.attributes.price_and_currency,
                TermsOne: item.attributes.terms.split('/')[0],
                TermsTwo: item.attributes.terms.split('/')[1],
              }
            })

            this.setState({ buyersPriceAndTermData: catachPriceandtermsdata});
            break;
        
          default:
            break;
        }

        this.onReceiveRoleData(apiRequestCallId, responseJson)

        this.onReceiveFilteredListingData(apiRequestCallId, responseJson)
    }
    // API FUNC STARTED
    SetDateFilterData = (range: DateRange) => {
      this.setState({
        RenderStart: 
        moment(range.startDate)
        .format("DD-MMM-YYYY"), RenderEndDate: 
        moment(range.endDate).format("DD-MMM-YYYY"),
        StartDate: moment(range.startDate).format(), 
        EndDate: moment(range.endDate).format(),DateRangeStartDate:range.startDate,DateRangeEndDate:range.endDate,
        buyerFilter: 
        { ...this.state.buyerFilter, 
          startDate: moment(range.startDate).format("YYYY-MM-DD"), endDate: moment(range.endDate).format("YYYY-MM-DD")
         },
      })
    }
  
    FilterDateSelect = () => {
      const { StartDate,
         EndDate } = this.state
      if (StartDate === "") 
      {
        toast.warning("Select start date");
      } else if (EndDate === "") 
      {
        toast.warning("Select end date");
      } else 
      {
        this.applyBuyerFilter();
      }
    }

    ClearFilterData = () => {
      this.setState({
        buyerFilter: { ...this.state.buyerFilter, startDate: "", endDate: "" },
        open: false,
        RenderStart: "", RenderEndDate: "",DateRangeStartDate:new Date(),
        DateRangeEndDate:new Date(),
        StartDate: "", EndDate: "",
      }, () => {
        this.applyBuyerFilter()
      })
    }
    TogolFilter = () => {
      this.setState({ open: !this.state.open
      });
    }

    handleSlotFilter = (principalOrg: any, isChecked: boolean) => {
      const hasActiveFilter = 
      this.state.activeSlotFilter.find((slot: any) => slot.attributes.code === principalOrg.attributes.code 
      && slot.attributes.name === principalOrg.attributes.name)
      if (isChecked && !hasActiveFilter) {
        this.setState(prevState => ({ activeSlotFilter: [...prevState.activeSlotFilter, principalOrg] }))
        setTimeout(()=> {  this.applyBuyerFilter()  },1000)
        return
      }
      this.setState(prevState => ({ activeSlotFilter: 
        prevState.activeSlotFilter.filter((item: any) => item.attributes.code !== principalOrg.attributes.code &&
         item.attributes.name !== principalOrg.attributes.name) }))
      setTimeout(()=> { this.applyBuyerFilter()},1000)
    }

    onFilteredGetDataForListing = (responseJson : any) => {
      let temp = {
        listingID: responseJson?.map(
          (val: any) => val.request.data.attributes.request_uid
        ),
        pol: responseJson?.map((val: any) => val.request.data.attributes.pol),
        pod: responseJson?.map((val: any) => val.request.data.attributes.pod),
        type: responseJson?.map(
          (val: any) =>  val?.container?.container_type
        ),
        startDate: responseJson?.map(
          (val: any) =>  val.request.data.attributes.container_loading_range_start
        ),
        endDate: responseJson?.map(
          (val: any) =>  val.request.data.attributes.container_loading_range_end
        ),
        
      };
      let d1 = [...new Set(temp.listingID)]
      let d2 = [...new Set(temp.pol)]
      let d3 = [...new Set(temp.pod)]
      let d4 = [...new Set(temp.type)]
      let arrayData = {
        listingID: d1?.map((str: any) => ({
          label: str,
          isChecked: false,
        })),
        pol: d2?.map((str: any) => ({ label: str, isChecked: false })),
        pod: d3?.map((str: any) => ({ label: str, isChecked: false })),
        type: d4?.map((str: any) => ({
          label: str,
          isChecked: false,
        })),
        BuyerprinicipalOrgList: [],
        startDate: "",
        endDate: "",
      };
     
      this.setState({
        buyerFilter: arrayData,
      });
    }

    onReceiveRoleData = (apiRequestCallId: any, responseJson: any) => {
      if (apiRequestCallId === this.onRoleSwitchAPICallID) {
        if(!responseJson.message) {
          this.setState({buyerRequestSnackbarOpen: true});
        } 
        else{
          setStorageData("UserRole", "seller")
          this.props.navigation.navigate("SellerListing");
        }}
      }

    onReceiveFilteredListingData = (apiRequestCallId: any, responseJson: any) => {
      if (apiRequestCallId === this.applyBuyerFilterApiCallID) {
        if(responseJson){
          if(!responseJson.errors && !responseJson.error) {
            this.setState({buyerListings: responseJson,open:false,DynamicCount:{...this.state.DynamicCount,requst:responseJson.length}});
          }
        }
      }
    }


    getBuyerListingData = (token: string) => {
        const header = {
          "Content-Type": "application/json",token: token,
        };
    

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getBuyerListingDataApiCallID = requestMessage.messageId;
    

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),"bx_block_listdemandpickflow/pending_requests"
        );
    

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),"GET"
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getBuyerDateData = (token: string) => {
      const header = {
        "Content-Type": "application/json",
        token: token,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getBuyerDateDataApiCallID = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_listdemandpickflow/pending_requests/set_date"
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };

      onBuyerRequestRoleSwitch = async() => {
        let authToken = await getStorageData("authToken");
        const header = {
          "Content-Type": "application/json",
          token: authToken,
        }; 
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.onRoleSwitchAPICallID = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `/account_block/accounts/switch_role`
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "PUT"
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }


    getBuyerPrincipalOrganisation = (token: string) => {
      const header = {  "Content-Type": "application/json",
        token: token };  
      const requestMessage = 
      new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.getBuyerPrincipalOrganisationDataApiCallID =
       requestMessage.messageId;  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_profile/principal_organizations"
      );  
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage)
      ,JSON.stringify(header)); 

      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET");
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getBuyerContainerList = (token: string) => {
      const header = {"Content-Type": "application/json",token: token,};
  
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getBuyerContainerListApiCallID = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),"bx_block_content_management/container_managements");
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
      );
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"GET");
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    updateBuyerListing = (values: {
      EDITEDBUYERPRINCIPALORGANISATION: string;
      EDITEDBUYERPOD: string;
      EDITEDPODTERMINAL: string;
      EDITEDPOL: string;
      EDITEDPOLTERMINAL: string;
      EDITEDETBATPOL: string;
      EDITEDETAATPOD: string;
      EDITEDTOTALQUANTITY : string;
      EDITEDCONTAINERTYPE: string;
      EDITEDCONTAINERCODES: string[];
      EDITEDCONTAINERSIZE: string;
      EDITEDAVERAGETONSBOX: number;
      EDITEDRATE: string;
      EDITEDTERMS: string;
      EDITEDTERMSTWO: string;

      EDITEDCONTAINERSIZETWO: string;
      EDITEDTOTALQUANTITYTWO : string;
      EDITEDAVERAGETONSBOXTWO: string;
      EDITEDRATETWO: string;
      EDITEDTERMSTHREE: string;
      EDITEDTERMSFOUR: string;
    }, update_id : string, container_id : string, container_details_id_one : string, container_details_id_two :string) => {
      const header = {
        "Content-Type": "application/json",
        token: this.state.authToken,
      };

      this.setState({
        loading: true,
        newPOLTerminalValue: null,
        newPODTerminalValue: null
      })
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      // checking 2nd container should remove or not

      let containerFileds = values.EDITEDCONTAINERSIZETWO && values.EDITEDTOTALQUANTITYTWO && values.EDITEDRATETWO && values.EDITEDTERMSTHREE && values.EDITEDTERMSFOUR


      interface EditedContainerDetail {
        id : string,
        container_size: string;
        quantity: string;
        desired_rates: string;
        terms: string;
        container_id : string;
        tons_per_teu_extra:number
        
      }

      const updateContainerDetail = (
        getId: string,
        size: string,
        quantity: string,
        rate: string,
        bothTerms: string, 
        containerId: string,
        TonsTeu:number
      ): EditedContainerDetail => ({
        id : getId,
        container_size: size,
        quantity: quantity,
        desired_rates: rate,
        terms: bothTerms,
        container_id : containerId,
        tons_per_teu_extra:TonsTeu
      });

      const httpBody = {
        request : {
          principal_organization_name : values.EDITEDBUYERPRINCIPALORGANISATION,
          pol : values.EDITEDPOL,
          pol_terminal : values.EDITEDPOLTERMINAL,
          pod : values.EDITEDBUYERPOD,
          pod_terminal : values.EDITEDPODTERMINAL,
          container_loading_range_start : values.EDITEDETBATPOL,
          container_loading_range_end : values.EDITEDETAATPOD,
          organization_id : this.state.buyerLoginData?.organization_id
        },
        container : {
          id : container_id,
          container_type : values.EDITEDCONTAINERTYPE,
          codes : values.EDITEDCONTAINERCODES,
        },
        container_details : [
          updateContainerDetail(
            container_details_id_one,
            values.EDITEDCONTAINERSIZE,
            values.EDITEDTOTALQUANTITY,
            values.EDITEDRATE,
            `${values.EDITEDTERMS} / ${values.EDITEDTERMSTWO}`,
            container_id,
            Number(values.EDITEDAVERAGETONSBOX)
          )
        ]
      }

      if (containerFileds) {
        httpBody.container_details.push(
          updateContainerDetail(
              container_details_id_two,
              values.EDITEDCONTAINERSIZETWO,
              values.EDITEDTOTALQUANTITYTWO,
              values.EDITEDRATETWO,
              `${values.EDITEDTERMSTHREE} / ${values.EDITEDTERMSFOUR}`,
              container_id,
              values.EDITEDAVERAGETONSBOX
            )
        );
      } else if (container_details_id_two && !values.EDITEDCONTAINERSIZETWO && !values.EDITEDTOTALQUANTITYTWO && !values.EDITEDRATETWO && !values.EDITEDTERMSTHREE && !values.EDITEDTERMSFOUR){
        httpBody.container_details.push(
          updateContainerDetail(
              container_details_id_two,
              "",
              "",
              "",
              "",
              "",
              0
            )
        );
      }
      
      this.updateBuyerListingApiCallID = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_listdemandpickflow/pending_requests/${update_id}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    deleteBuyerListing = (delete_id : string) => {
      const header = {
        "Content-Type": "application/json",
        token: this.state.authToken,
      };
  
      this.setState({
        loading: true
      })
      
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deleteBuyerListingApiCallID = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_listdemandpickflow/pending_requests/${delete_id}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "DELETE"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };


    getBuyersPolDataFunc = (token: string) => {
      const header = {
        "Content-Type": "application/json",
        token: token,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getBuyersPolData = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_content_management/ports"
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  getBuyersPriceAndTerms = async () => {
    let token = await getStorageData("authToken");
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBuyersPriceAndTearmsDataApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/price_managements`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  applyBuyerFilter = async () => {
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const reqUidData = this.state.buyerFilter.listingID
    ?.reduce((acc: string[], item: { label: string; isChecked: boolean }) => {
      if (item.isChecked) acc.push(item.label);
      return acc;
    }, []);

    const polsData = this.state.buyerFilter.pol
    ?.reduce((acc: string[], item: { label: string; isChecked: boolean }) => {
      if (item.isChecked) acc.push(item.label);
      return acc;
    }, []);

    const podsData = this.state.buyerFilter.pod
      ?.reduce((acc: string[], item: { label: string; isChecked: boolean }) => {
        if (item.isChecked) acc.push(item.label);
        return acc;
      }, []);

    const typesData = this.state.buyerFilter.type
      ?.reduce((acc: string[], item: { label: string; isChecked: boolean }) => {
        if (item.isChecked) acc.push(item.label);
        return acc;
      }, []);
      const principalOrganisation = this.state.activeSlotFilter?.map((elem:any)=> elem?.attributes?.name)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.applyBuyerFilterApiCallID = requestMessage.messageId;

    const bookingIdsParam = `request_ids=${JSON.stringify(reqUidData)}`;
    const polsParam = `pols=${JSON.stringify(polsData)}`;
    const podsParam = `pods=${JSON.stringify(podsData)}`;
    const containerTypesParam = `container_types=${JSON.stringify(typesData)}`;
    const principalOrganizationParam = principalOrganisation.length > 0 ? `principal_organization_names=${JSON.stringify(principalOrganisation)}` : '';
    const etaPodParam = `container_loading_range_end=${this.state.buyerFilter.endDate}`;
    const etbPolParam = `container_loading_range_start=${this.state.buyerFilter.startDate}`;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_listdemandpickflow/pending_requests/apply_filters?${bookingIdsParam}&${polsParam}
      &${podsParam}&${containerTypesParam}
      &${etaPodParam}
      &${etbPolParam}
      &${principalOrganizationParam}` 
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


    SwitchChnages = () => { 
      this.onBuyerRequestRoleSwitch();
     }
      handleEditedBuyerClose = () => {
        this.setState({
          triggerEditForm:false, 
          buyerListingAdded : false , 
          anchorElDropdown : null, 
          selectedContainer: {}, 
          isEditContainerClicked : false})
      }

      handleTabChange = (event: any, newValue: any) => {
        this.setState({ currentTab: newValue });
      };

    handleChangePage = (event: any, newPage: any) => {
        this.setState({ page: newPage });
    };

    getAddFilterData = (containerType : string, pod: string, pol :string, BoxSize : string, setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean | undefined
    ) => void) => {
      let filterData = this.state.buyersPriceAndTermData.filter((ItemData: { Container: string, PortOfDestination: string, PortOfLocation: string, BoxSize: string }) => {
        if (ItemData.Container.includes(containerType) && ItemData.PortOfDestination.includes(pod) && ItemData.PortOfLocation.includes(pol) && ItemData.BoxSize.includes(BoxSize)) {
          return ItemData
        }
      })

      setFieldValue("RATE", filterData?.[0]?.Rate || "")
      setFieldValue('TERMS', filterData?.[0]?.TermsOne || "");
      setFieldValue('TERMSTWO', filterData?.[0]?.TermsTwo || "");
    }


    renderCommanFieldState = (setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean | undefined
    ) => void,) => {
      setFieldValue("RATE", "")
      setFieldValue('TERMS', "");
      setFieldValue('TERMSTWO', "");

      setFieldValue("RATETWO", "")
      setFieldValue('TERMSTHREE', "");
      setFieldValue('TERMSFOUR', "");
    }

    getEditedFiltersData = (editedContainerType : string, editedPod: string, editedPol :string, editedBoxSize : string, setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean | undefined
    ) => void) => {

      let editedFilterData = this.state.buyersPriceAndTermData.filter((ItemData: { Container: string, PortOfDestination: string, PortOfLocation: string, BoxSize: string }) => {
        if (ItemData.Container.includes(editedContainerType) && ItemData.PortOfDestination.includes(editedPod) && ItemData.PortOfLocation.includes(editedPol) && ItemData.BoxSize.includes(editedBoxSize)) {
          return ItemData
        }
      })

      setFieldValue("EDITEDRATE", editedFilterData?.[0]?.Rate || "")
      setFieldValue('EDITEDTERMS', editedFilterData?.[0]?.TermsOne || "");
      setFieldValue('EDITEDTERMSTWO', editedFilterData?.[0]?.TermsTwo || "");
    }

    renderEditedCommanFieldState = (setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean | undefined
    ) => void,) => {
      setFieldValue("EDITEDRATE", "")
      setFieldValue('EDITEDTERMS', "");
      setFieldValue('EDITEDTERMSTWO', "");

      setFieldValue("EDITEDRATETWO", "")
      setFieldValue('EDITEDTERMSTHREE', "");
      setFieldValue('EDITEDTERMSFOUR', "");
    }


    EditedBuyerListingFormTextFieldChange = (
      e:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | React.ChangeEvent<{
            value: any;
          }>,
      setFieldValue: (
        field: string,
        value: string,
        shouldValidate?: boolean | undefined
      ) => void,
      keyname: string,
      value : any
    ) => {

      if(keyname === "EDITEDCONTAINERTYPE"){
        this.onSelectBuyerContainer(e);
        setFieldValue("EDITEDCONTAINERCODES", [] as never)
        setFieldValue("EDITEDCONTAINERSIZE", "")
        setFieldValue("EDITEDCONTAINERSIZETWO", "")


        this.renderEditedCommanFieldState(setFieldValue)

      }
      if(keyname === "EDITEDCONTAINERCODES"){
        let event = e.target.value
        setFieldValue("EDITEDCONTAINERCODES", event);
      }
      if(keyname === "EDITEDCONTAINERSIZE"){
        this.renderEditedCommanFieldState(setFieldValue)

        let editedContainerType = value.EDITEDCONTAINERTYPE
        let editedPod = value.EDITEDBUYERPOD
        let editedPol = value.EDITEDPOL
        let editedBoxSize = e.target.value

        this.getEditedFiltersData(editedContainerType, editedPod, editedPol, editedBoxSize, setFieldValue)
      }
      
      if(keyname === "EDITEDCONTAINERSIZETWO"){
        setFieldValue('EDITEDCONTAINERSIZETWO', e.target.value);

        let editedContainerTypeTwo = value.EDITEDCONTAINERTYPETWO
        let editedPodTwo = value.EDITEDBUYERPODTWO
        let editedPolTwo = value.EDITEDPOLTWO
        let editedBoxSizeTwo = e.target.value

        let editedFilterDataTwo = this.state.buyersPriceAndTermData.filter((ItemData: { Container: string, PortOfDestination: string, PortOfLocation: string, BoxSize: string }) => {
          if (ItemData.Container.includes(editedContainerTypeTwo) && ItemData.PortOfDestination.includes(editedPodTwo) && ItemData.PortOfLocation.includes(editedPolTwo) && ItemData.BoxSize.includes(editedBoxSizeTwo)) {
            return ItemData
          }
        })
        setFieldValue("EDITEDRATETWO", editedFilterDataTwo?.[0]?.Rate || "")
        setFieldValue('EDITEDTERMSTHREE', editedFilterDataTwo?.[0]?.TermsOne || "");
        setFieldValue('EDITEDTERMSFOUR', editedFilterDataTwo?.[0]?.TermsTwo || "");
      }
      else {
        if(keyname !== "EDITEDCONTAINERCODES"){
          setFieldValue(`${keyname}`, `${e.target.value}`);
        }
      }
    };


    onSelectBuyerContainer = (e: any) => {
      let temp = this.state.buyerContainerData.filter(
        (data: any) => data.buyerContainer_type === e.target.value
      );

      this.setState({ selectedContainer: temp[0] });
    };

    handleClick = (event:any, deleteKey : string, list: any) => {
      let temp = this.state.buyerContainerData.filter(
        (data: any) => data.buyerContainer_type === list?.container?.container_type
      );
      this.setState({ anchorElDropdown: event.currentTarget, catchDeleteIdKey : deleteKey, catchEditedData : list,
        selectedBuyerContainerOneSize: temp?.[0]?.buyerBox_sizes?.[0]?.size,
      });
      
      this.onSelectBuyerContainer({target : {value: temp?.[0]?.buyerContainer_type}});
    };
  
    handleCloseDropdown = () => {this.setState({ 
      anchorElDropdown: null 
    });};

    handleDeleteListClick = () => {this.setState({
      listDeleted : true,  anchorElDropdown: null
    })}

    handleDeleteCancelClick = () => {
      this.setState({
        listDeleted : false, 
        catchDeleteIdKey : ""})
    }

    handleEditClick=()=>{
      this.setState(
        {
          triggerEditForm:true,
          updateBuyerListing:true,
      }
    );
      if(this.state.catchEditedData?.container_details?.length === 2 && this.state.catchEditedData?.container_details[1].quantity != null){
        this.setState({
          isEditContainerClicked : true
        })
      }
    }
    
    handleSnackbarClose = () => { this.setState({buyerRequestSnackbarOpen: false}) }

    handleCloseAddEditSuccessModal = () => {
      this.setState({show : false, buyerListingAdded: false, triggerEditForm : false, anchorElDropdown : null, buyerAddedListingID : "", isAddContainerClicked : false, isEditContainerClicked : false, isMatchesFound : false})
    }

    handleEditedRemoveAction = (setFieldValue : (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void) => {
      this.setState({
        isEditContainerClicked : false,
      })

      this.setState({
        reinitialisedEditedForm : true
      }, ()=>{
        setTimeout(() => {
          this.setState({
            reinitialisedEditedForm : false
          })
        }, 1000);
      })

      setFieldValue("EDITEDCONTAINERSIZETWO", "")
      setFieldValue("EDITEDTOTALQUANTITYTWO", "")
      setFieldValue("EDITEDAVERAGETONSBOXTWO", "")
      setFieldValue("EDITEDRATETWO", "")
      setFieldValue("EDITEDTERMSTHREE", "")
      setFieldValue("EDITEDTERMSFOUR", "")
      
    }

    handleEditContainerClick = () => {
      this.setState({
        isEditContainerClicked : true,
      })
    }

    handleBuyerOfferExpanded = (val:string) => {
      if(val === "listingID"){
        this.setState({ 
          buyerExpanded: this.state.buyerFilter.listingID?.length > 0  
        });
      }
      if(val === "pol"){
        this.setState({ 
          buyerExpandedPol: this.state.buyerFilter.pol?.length > 0 
        });
      }
      if(val === "pod"){
        this.setState({ 
          buyerExpandedPod: this.state.buyerFilter.pod?.length > 0  
        });
      }
      if(val === "type"){
        this.setState({ 
          buyerExpandedType: this.state.buyerFilter.type?.length > 0  
        });
      }
    };
  
    handleBuyerOfferChange = (
      event: any,
      values: Array<{ label: string; isChecked: boolean }>,
      arrName: string
    ) => {
      const { name, checked } = event.target;
      
      const updatedSelectOptions = values?.map(
        (item: { label: string; isChecked: boolean }) => {
          return item.label === name ? { ...item, isChecked: checked } : item;
        }
      );
      if (arrName === "listingID") {
        let temp = { ...this.state.buyerFilter, listingID: updatedSelectOptions };
          this.setState({ buyerFilter: temp }, ()=> {
       this.applyBuyerFilter();
     });
      }
      if (arrName === "pol") {
        let temp = { ...this.state.buyerFilter, pol: updatedSelectOptions };
        this.setState({ buyerFilter: temp }, ()=> {
          this.applyBuyerFilter();
        });
      }
      if (arrName === "pod") {
        let temp = { ...this.state.buyerFilter, pod: updatedSelectOptions };
        this.setState({ buyerFilter: temp }, ()=> {
          this.applyBuyerFilter();
        });
      }
      if (arrName === "type") {
        let temp = { ...this.state.buyerFilter, type: updatedSelectOptions };
        this.setState({ buyerFilter: temp }, ()=> {
          this.applyBuyerFilter();
        });
      }
    };

    goToBuyerRequestPage = async (route: string) => {
      const messageBuyerReq: Message = new Message(
        getName(MessageEnum.NavigationMessage),
      );
      messageBuyerReq.addData(
          getName(MessageEnum.NavigationTargetMessage),
          route
      );
      messageBuyerReq.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      const raiseMessage: Message = new Message(
          getName(MessageEnum.NavigationPayLoadMessage),
      );
      messageBuyerReq.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(messageBuyerReq);
    };
  
    handleBuyerOfferDelete = (
      option: any,
      values: Array<{ label: string; isChecked: boolean }>, arrName: string
    ) => {
      const updatedSelectOptionsAfterDelet = values?.map((item: any) => {
        return item.label === option.label ? { ...item, isChecked: false } : item;
      });

      if (arrName === "listingID") {
        let temp = { ...this.state.buyerFilter, listingID: updatedSelectOptionsAfterDelet };
        this.setState({ buyerFilter: temp }, ()=> {
          this.applyBuyerFilter();
        });
      }
      if (arrName === "pol") {
        let temp = { ...this.state.buyerFilter, pol: updatedSelectOptionsAfterDelet };
        this.setState({ buyerFilter: temp }, ()=> {
          this.applyBuyerFilter();
        });
      }
      if (arrName === "pod") {
        let temp = { ...this.state.buyerFilter, pod: updatedSelectOptionsAfterDelet };
        this.setState({ buyerFilter: temp }, ()=> {
          this.applyBuyerFilter();
        });
      }
      if (arrName === "type") {
        let temp = { ...this.state.buyerFilter, type: updatedSelectOptionsAfterDelet };
        this.setState({ buyerFilter: temp }, ()=> {
          this.applyBuyerFilter();
        });
      }
     
    };

    DynamicDataSet = (e: { listing?: string, requst?: string, offers?: string, booking?: string }) => { this.setState({ DynamicCount: e }) }


}
// Customizable Area End