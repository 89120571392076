// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  faq: Array<Item>;
  FilterArray: Array<Item>;
  selectedItem: string;
  OpenClose: number;
  headskeys: Array<string>;
  orientation: string 
}

interface Item {
  question: string;
  response: string;
  key: string;
}

interface SS {
  id: string;
}

export default class FaqController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      faq: [],
      selectedItem: "",
      OpenClose: -1,
      headskeys: [],
      FilterArray: [],
      orientation: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.FaqApiCalled) {
      let ResponseJsonData = responseJson.data.map(
        (item: {
          attributes: { question: string; response: string; faq_key: string };
        }) => {
          return {
            question: item.attributes.question,
            response: item.attributes.response,
            key: item.attributes.faq_key
          };
        }
      );
      const defaultFilterValue =
        ResponseJsonData.length > 0 ? ResponseJsonData : [];
      const arrayWithDuplicates = ResponseJsonData.map(
        (item: { key: string }) => `${item.key}`
      );
      const uniqueArray = [...new Set(arrayWithDuplicates)];
      this.setState({
        faq: ResponseJsonData,
        headskeys: uniqueArray as Array<string>,
        selectedItem: defaultFilterValue[0].key,
        FilterArray: defaultFilterValue.filter((data:{key:string})=> data.key === uniqueArray[0])
      });
    }
  }

   setOrientation = () => {
    const orientation =  window.innerWidth > 960 ? 'vertical' : 'horizontal';
    this.setState({ orientation });
  };

  async componentDidMount() {
    this.setOrientation();
    window.addEventListener('resize',  this.setOrientation);
    this.FaqFirstFuncation();
   

  }
 
    async componentWillUnmount() {
      window.removeEventListener('resize', this.setOrientation);
    }

  OpenCloseOnChange = (index: number) => {
    this.setState({
      OpenClose: index === this.state.OpenClose ? -1 : index
    });
  };

  handleClick = (item: any) => {
    this.setState({
      selectedItem: item,
      FilterArray: this.state.faq.filter(filteritem =>
        filteritem.key.includes(item)
      )
    });
  };

  FaqApiCalled: string = "";

  FaqFirstFuncation = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.FaqApiCalled = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FaqsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.FaqGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
// Customizable Area End
