import React, { Component } from "react";
// Customizable Area Start
import {
  withStyles,
} from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import StepConnector from "@material-ui/core/StepConnector";
import { StepIconProps } from "@material-ui/core/StepIcon";
import DescriptionIcon from '@material-ui/icons/Description';
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import { getStorageData, setStorageData } from "../../framework/src/Utilities";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import DirectionsBoatIcon from "@material-ui/icons/DirectionsBoat";
// Customizable Area End
// Customizable Area Start
interface VerticalFileProps {
  newStepperState: number
}

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
    fontFamily: 'Asap'
  },
  active: {
    "& $line": {
      backgroundColor: "#D58000",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#35D261",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);


const ColorlibConnector2 = withStyles({
  alternativeLabel: {
    top: 22,
    fontFamily: 'Asap'
  },
  active: {
    "& $line": {
      backgroundColor: "",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#35D261",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const styles =
{
  root: {
    width: "100%",
  },

}
class ColorlibStepIcon extends Component<StepIconProps & {activeStepData:number,stepIndex:number,userRole:string}> {
  render() {
    // const classes = useColorlibStepIconStyles();
    const { active, completed } = this.props;

    const icons: { [index: string]: React.ReactElement } = {
      "1": <InsertDriveFileIcon />,
      "2": <FolderOpenIcon />,
      "3": <MonetizationOnIcon />,
      "4": <DirectionsBoatIcon />,
      "5": <DescriptionIcon />,
    };
    return (
      <div
        data-testid="stepper"
        style={{
          zIndex: 1,
          width: 50,
          height: 50,
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: (this.props.activeStepData===4&&this.props.stepIndex===4&&this.props.userRole==='seller') ? '#ECECEC':active ? '#EE8F00' : completed ? '#35D261' : '#ECECEC',
          color: active || completed ? '#fff' : '#FFF',
          marginRight: -10
        }}
      >
        {icons[String(this.props.icon)]}
      </div>
    )
  }
}

function getSteps() {
  return ["AUTHORIZE CO", "PREPARE B/L", "SHARE INVOICE", "SAILING", "FINAL B/L"];
}





// Customizable Area End

class CustomizedSteppers extends Component<VerticalFileProps> {
  // Customizable Area Start
  // Customizable Area End


  state = {
    getRole:''
};


  async componentDidMount() {
    let UserRole = await getStorageData("UserRole");
    this.setState({getRole:UserRole})
  }
  
   render() {
    // Customizable Area Start
    const { newStepperState: activeStep } = this.props;
    const steps = getSteps();

    
    // Customizable Area End
    return (
      // Customizable Area Start
      <div style={{ width: '100%' }}>

        <Stepper
          activeStep={activeStep}
          alternativeLabel
         connector={(activeStep===4&&this.state.getRole==='seller')?<ColorlibConnector2 /> :<ColorlibConnector/>}
        >
          {steps.map((label, index) => (
            <Step key={label} >
              <StepLabel style={{ color:  activeStep === index ? '#EE8F00' : 'black', fontFamily: 'Asap' }} StepIconComponent={props =><ColorlibStepIcon {...props} activeStepData={activeStep}  userRole={this.state.getRole} stepIndex={index} />} className='icont_font_size'>
                <text style={{fontFamily: 'Asap', fontWeight: 'bold'}}>{label}</text>
                </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export default withStyles(styles)(CustomizedSteppers);
// Customizable Area End
