// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { RouteComponentProps } from "react-router";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import {toast } from "react-toastify";
import moment from "moment";
import { DateRange } from "materialui-daterange-picker";
import { ChangeEvent } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

interface S {
  // Customizable Area Start
  hoveredBar: number | null;
  selectedButton: string | null;
  bluePercentage: number;
  tradeRouteSelect: string;
  inboundSelect: string[];
  outboundSelect: string[];
  openDialog: boolean;
  isBuyerRole: boolean;
  tradeSelect: string,
  serviceSelect: string,
  PortlocationSelect: string,
  DestinationlocationSelect: string,
  inboundPercentdata: { [key: string]: string | number }
  outboundPercentdata: { [key: string]: string | number }
  outboundWeightPercentdata: { [key: string]: string | number }
  ServicetableData: {
    service_code: string,
    direction: string,
    inbound_weight: number,
    outbound_weight: number,
    outbound_teu: number
   }[]
  ServicedataMessage: { [key: string]: string };
  ServicedataMessagestate: boolean
  MonthlyMessage: { [key: string]: string },
  MonthlyMessagestate: boolean,
  FeederMessagestate: boolean,
  FedderMessage: { [key: string]: string },
  InboundSGstate: boolean,
  InboundSGMessage: { [key: string]: string },
  OutboundSGstate: boolean,
  OutboundSGMessage: { [key: string]: string },
  ListofdirectionsData: { [key: string]: string }[]
  loginToken: string
  UpdatedtimeData: string;
  loading:boolean;
  InServicesorting:boolean,
  outServicesorting:boolean,
  serviceSorting:boolean
  directionSorting:boolean
  inboundSorting:boolean
  outboundSorting:boolean
  outboundWeightsorting:boolean
  InboundservicesData: {
    s_no: number,
    service: string,
    inbound: number,
    service_direction : string,
  }[]
  OutboundservicesData: {
    [x: string]: any;
    s_no: number,
    service: string,
    outbound: number,
    service_direction : string,
  }[];
  FeederlistData:
  {
    s_no: number,
    feeder: string
  }[]
  FeedergraphData: number[][]
  FeedergraphDetails: {
    s_no: number,
    feeder: string,
    inbound: number,
    outbound: number
  }[];
  UserSelect: string
  ServicedatareponseMessage: { [key: string]: string | [] }
  TraderouteData: { [key: string]: string }[]
  ServiceData: { [key: string]: string }[]
  selectedTradeIndex: number
  PortlocationData: { [key: string]: string }[]
  DestinationportData: { [key: string]: string }[]
  TooltipData: {id:string,
       attributes: { trade_route_classification: "string", service: "string", description: "string" } 
}[]
  inboundAverageState: { [key: string]: number | string }[],
  outboundAverageState: { [key: string]: number | string }[],
  monthAverageState: { [key: string]: string | number }[],
  yearAverageState: { [key: string]: string }[],
  selectedValues: { [key: string]: string }[]
  open: boolean,
  StartDate: string,
  EndDate: string,
  InboundURLendpoint: string
  OutboundURLendpoint: string,
  Insorting:boolean,
  Outsorting:boolean,
  OutboundWeightURLendpoint: string,
  MonthlyUtilizationURLendpoint: string,
  FeddergraphURLendpoint: string,
  InboundSGURLendpoint: string,
  OutboundSGURLendpoint: string,
  ServiceoverviewURLendpoint: string,
  DownloadURLendpoint:string,
  selectedItem: string | null;
  count: number;
  RenderStart: string,
  MontlyUtilizationValue:string
  RenderEndDate: string,
  filter: {
    startDate: string;
    endDate: string
  };

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class VesselUtilisationController extends BlockComponent<
  Props,
  S,
  SS
>{
  // Customizable Area Start
  InboundUtilisationPercentageApiId: string = "";
  OutboundUtilisationPercentageApiId: string = "";
  OutboundUtilisationWeightAPiId: string = "";
  ServiceoverviewTableAPIEndpointAPiId: string = "";
  ListofdirectionAPIEndpointAPiId: string = "";
  InboundservicesAPiId: string = "";
  OutboundservicesAPiId: string = "";
  FeederlistDataAPiId: string = "";
  FeedergraphDataAPiId: string = "";
  UpdatetimeAPiId: string = "";
  MonthlyUtilizationAPiId: string = "";
  TraderouteAPiId: string = ""
  ServiceAPiId: string = ""
  PortlocationAPiId: string = ""
  DestinationportAPiId: string = ""
  tradeRoutetooltipAPiId: string = ""
  DownloadfileAPiId:string=""



  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.NavigationMessage),
    ];

    (async () => {
      let vesselUtilisationLoginData = await getStorageData("UserRole");
      if(!vesselUtilisationLoginData){
        window.location.href = "/"
      } else if(vesselUtilisationLoginData == "buyer"){
        window.location.href = "/BuyersRequest"
      }
    })();

    this.state = {
      hoveredBar: null,
      selectedButton: "",
      bluePercentage: 50,
      tradeRouteSelect: "ALL",
      inboundSelect: ['ALL', 'E', 'W', 'S', 'N'],
      outboundSelect: ['ALL', 'E', 'W', 'S', 'N'],
      openDialog: false,
      isBuyerRole: false,
      inboundPercentdata: {},
      outboundPercentdata: {},
      outboundWeightPercentdata: {},
      ListofdirectionsData: [],
      ServicedataMessage: {},
      ServicedataMessagestate: false,
      MonthlyMessage: {},
      MonthlyMessagestate: false,
      FeederMessagestate: false,
      MontlyUtilizationValue:`${"range1"}=${new Date(new Date().setDate(new Date().getDate() - 27)).toISOString().slice(0, 10)}&${"range2"}=${new Date().toISOString().slice(0, 10)}`,
      FedderMessage: {},
      InboundSGstate: false,
      InboundSGMessage: {},
      OutboundSGstate: false,
      OutboundSGMessage: {},
      ServicetableData: [],
      ServicedatareponseMessage: {},
      InboundservicesData: [],
      UpdatedtimeData: "",
      OutboundservicesData: [],
      FeederlistData: [],
      FeedergraphData: [],
      FeedergraphDetails: [],
      loginToken: "",
      TraderouteData: [],
      ServiceData: [],
      PortlocationData: [],
      selectedValues: [],
      DestinationportData: [],
      TooltipData: [],
      tradeSelect: "",
      loading:false,
      serviceSelect: "",
      Insorting:false,
      Outsorting:false,
      PortlocationSelect: "",
      DestinationlocationSelect: "",
      inboundAverageState: [],
      outboundAverageState: [],
      monthAverageState: [],
      yearAverageState: [],
      selectedTradeIndex: 0,
      selectedItem: null,
      open: false,
      InServicesorting: false,
      outServicesorting: false,
      serviceSorting: false,
      directionSorting: false,
      inboundSorting: false,
      outboundSorting: false,
      outboundWeightsorting: false,
      count: 0,
      StartDate: "",
      EndDate: "",
      RenderStart: "",
      RenderEndDate: "",
      filter: {
        startDate: '',
        endDate: ""
      },
      InboundURLendpoint: configJSON.InboundPercentageAPIEndpoint,
      OutboundURLendpoint: configJSON.OutboundPercentageAPIEndpoint,
      OutboundWeightURLendpoint: configJSON.OutboundWeightPercentageAPIEndpoint,
      MonthlyUtilizationURLendpoint: configJSON.MonthlyutilizationAPIEndpoint + `${"range1"}=${new Date(new Date().setDate(new Date().getDate() - 27)).toISOString().slice(0, 10)}&${"range2"}=${new Date().toISOString().slice(0, 10)}`,
      FeddergraphURLendpoint: configJSON.FeddergraphAPIEndpoint + `${"ALL"}`,
      InboundSGURLendpoint: configJSON.InboundservicesAPIEndpoint + `${JSON.stringify(["ALL"])}`,
      OutboundSGURLendpoint: configJSON.OutboundservicesAPIEndpoint + `${JSON.stringify(["ALL"])}`,
      ServiceoverviewURLendpoint: configJSON.ServiceoverviewTableAPIEndpoint,
      DownloadURLendpoint:configJSON.DownloadfileAPIEndpoint,
      UserSelect: "",
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);

      if (apiRequestCallId === this.InboundUtilisationPercentageApiId) {
        this.InboundResponse(responseJson)
      }
      if (apiRequestCallId === this.OutboundUtilisationPercentageApiId) {
        this.OutboundResponse(responseJson)
      }
      if (apiRequestCallId === this.OutboundUtilisationWeightAPiId) {
        this.OutboundweightResponse(responseJson)
      }
      this.ServiceoverviewTableResponse(apiRequestCallId, responseJson)
      this.ListofdirectionsResponse(apiRequestCallId, responseJson)
      this.InboundservicesResponse(apiRequestCallId, responseJson)
      this.OutboundservicesResponse(apiRequestCallId, responseJson)
      this.FedderlistResponse(apiRequestCallId, responseJson)
      this.FeedergraphResponse(apiRequestCallId, responseJson)
      this.UpdatedtimeResponse(apiRequestCallId, responseJson)
      this.MonthlyutilizationResponse(apiRequestCallId, responseJson)
      this.TraderouteResponse(apiRequestCallId, responseJson)
      this.ServiceResponse(apiRequestCallId, responseJson)
      this.PortlocationResponse(apiRequestCallId, responseJson)
      this.DestinationportResponse(apiRequestCallId, responseJson)
      this.TraderouteTooltipResponse(apiRequestCallId, responseJson)
      this.handleDownloadfileResponse(apiRequestCallId, responseJson)
    }
  }


  async componentDidMount() {
    const UserRole = await getStorageData("UserRole") === "buyer"
    this.setState({
      isBuyerRole: UserRole
    })
    let loginData = await getStorageData("loginData");
    let obj = JSON.parse(loginData)
    this.setState({
      loginToken: obj.token
    })
    const token =  obj.token
    const userSelected = ""
    this.handleListofdirections(token)
    this.handleFedderlist(token)
    this.handleListtraderoutes(token)
    this.handleListofservices(token)
    this.handleListofPortlocation(token)
    this.handleListofDestination(token)
    this.handleTraderouteTooltip(token)
    this.handleAPIfiltercall(token, userSelected)
  }
  handleItemClick = (item: string) => {
    this.setState({ selectedItem: item }, () => {
        const element = document.getElementById(item);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    });
};

  InboundResponse = (responseJson: {
    [x: string]: string | number;
    title: string,
    percentage: number
  }) => {
    if (responseJson) {
      this.setState({
        inboundPercentdata: responseJson,
        loading:false
      })
    } if (responseJson.status === 500) {
      toast.error("Something went wrong!");
    } else if (responseJson.errors) {
     
      toast.error("Token has Expired");
    }

  }
  OutboundResponse = (responseJson: {
    [x: string]: string | number;
    title: string,
    percentage: number
  }) => {

    if (responseJson) {
      this.setState({
        outboundPercentdata: responseJson,
        loading:false
      })
    } if (responseJson.status === 500) {
      toast.error("Something went wrong!");
    } else if (responseJson.errors) {
      toast.error("Token has Expired");
    }

  }
  OutboundweightResponse = (responseJson: {
    [x: string]: string | number;
    title: string,
    percentage: number
  }) => {
    if (responseJson) {
      this.setState({
        outboundWeightPercentdata: responseJson,
        loading:false
      })
    } if (responseJson.status === 500) {
      toast.error("Something went wrong!");
    } else if (responseJson.errors) {
      toast.error("Token has Expired");
    }

  }
  ServiceoverviewTableResponse = (apiRequestCallId: string, responseJson: {
    [x: string]: any; message: string
  }) => {

    if (apiRequestCallId === this.ServiceoverviewTableAPIEndpointAPiId) {
      if (responseJson.message) {
        this.setState({
          ServicedataMessage: responseJson,
          ServicedataMessagestate: true,
          loading:false
        })
      } if (responseJson.data) {
        const servieTabData = responseJson as unknown as { data: {
          service_code: string,
          direction: string,
          inbound_weight: number,
          outbound_weight: number,
          outbound_teu: number
         }[] }
        this.setState({
          ServicetableData: servieTabData.data,
          ServicedatareponseMessage: responseJson,
          ServicedataMessagestate: false,
          loading:false
        })
      } if (responseJson.status === 500) {
        toast.error("Something went wrong!");
      } else if (responseJson.errors) {
        toast.error("Token has Expired");
      }
    }

  }
  ListofdirectionsResponse = (apiRequestCallId: string, responseJson: { [key: string]: string }[]) => {
    if (apiRequestCallId === this.ListofdirectionAPIEndpointAPiId) {

      if (responseJson) {
        this.setState({
          ListofdirectionsData: responseJson
        })
      } else {
        toast.error("Something went wrong!");
      }

    }
  }
  InboundservicesResponse = (apiRequestCallId: string,
    responseJson: any
  ) => {
    if (apiRequestCallId === this.InboundservicesAPiId) {
      if (responseJson.message) {
        this.setState({
          InboundSGstate: true,
          InboundSGMessage: responseJson,
          loading: false
        })
      }
      if (responseJson) {
        this.setState({
          InboundservicesData: responseJson,
          InboundSGstate: false,
          loading: false
        })
      } if (responseJson.status === 500) {
        toast.error("Something went wrong!");
      } else if (responseJson.errors) {
        toast.error("Token has Expired");
      }

    }
  }
  UpdatedtimeResponse = (apiRequestCallId: string, responseJson: {
    [x: string]: any; Updated: string;
  }) => {

    if (apiRequestCallId === this.UpdatetimeAPiId) {
      if (responseJson) {
        this.setState({
          UpdatedtimeData: responseJson.Updated
        })
      } if (responseJson.status === 500) {
        toast.error("Something went wrong!");
      } else if (responseJson.errors) {
        toast.error("Token has Expired");
      }

    }
  }
  OutboundservicesResponse = (apiRequestCallId: string,
    responseJson: any
  ) => {
    if (apiRequestCallId === this.OutboundservicesAPiId) {
      if (responseJson.message) {
        this.setState({
          OutboundSGstate: true,
          OutboundSGMessage: responseJson,
          loading: false
        })
      }
      if (responseJson) {
        this.setState({
          OutboundservicesData: responseJson,
          OutboundSGstate: false,
          loading: false
        })
      } if (responseJson.status === 500) {
        toast.error("Something went wrong!");
      } else if (responseJson.errors) {
        toast.error("Token has Expired");
      }

    }
  }
  FedderlistResponse = (apiRequestCallId: string, responseJson: {
    s_no: number,
    feeder: string
  }[]) => {
    if (apiRequestCallId === this.FeederlistDataAPiId) {
      this.setState({
        FeederlistData: responseJson
      })
    }
  }
  FeedergraphResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.FeedergraphDataAPiId) {

      if (responseJson.message) {
        this.setState({
          FeederMessagestate: true,
          FedderMessage: responseJson,
          loading: false
        })
      }
      if (responseJson) {

        const seriesData = responseJson?.map((item: { inbound: number; outbound: number; }) => [item.outbound, item.inbound]);
        this.setState({
          FeedergraphDetails: responseJson,
          FeedergraphData: seriesData,
          FeederMessagestate: false,

          loading: false
        })
      } if (responseJson.status === 500) {
        toast.error("Something went wrong!");
      } else if (responseJson.errors) {
        toast.error("Token has Expired");
      }
    }
  }
  MonthlyutilizationResponse = (apiRequestCallId: string, responseJson: any) => {


    if (apiRequestCallId === this.MonthlyUtilizationAPiId) {

      if (responseJson.message) {
        this.setState({
          MonthlyMessagestate: true,
          MonthlyMessage: responseJson,
          loading: false
        })
      }
      if (responseJson) {
        const inboundData = responseJson?.map((item: { inbound_average: number; }) => item.inbound_average);
        const outboundData = responseJson?.map((item: { outbound_average: number; }) => item.outbound_average);
        this.setState({
          inboundAverageState: inboundData,
          outboundAverageState: outboundData,
          monthAverageState: responseJson,
          MonthlyMessagestate: false,
          loading: false
        })
      }
      if (responseJson.status === 500) {
        toast.error("Something went wrong!");
      } else if (responseJson.errors) {
        toast.error("Token has Expired");
      }
    }

  }
  handleClearfilters = () => {
    this.setState({
      tradeSelect: "",
      serviceSelect: "",
      PortlocationSelect: "",
      DestinationlocationSelect: "",
      UserSelect: "",
      count: 0
    }, () => {
      this.handleAPIfiltercall(this.state.loginToken, this.state.UserSelect)
    })
  }

  handleAPIfiltercall = (token: string, userSelected: string) => {
    const value = this.state.tradeRouteSelect
    const key = this.state.inboundSelect
    let button = "1M"
    this.handleInboundUtilisationPercentage(token, userSelected)
    this.handleOutboundUtilisationPercentage(token, userSelected)
    this.handleOutboundUtilisationWeightPercentage(token, userSelected)
    this.handleServiceoverviewData(token, userSelected)
    this.handleInboundservices(token, key, key, userSelected)
    this.handleOutboundservices(token, key, key, userSelected)
    this.handlefedderGraphlist(token, value, value, userSelected)
    this.handleUpdatetime(token, userSelected)
    this.handleButtonClick(button)
  }

  TraderouteResponse = (apiRequestCallId: string, responseJson: { [key: string]: string }[]) => {
    if (apiRequestCallId === this.TraderouteAPiId) {
      if (responseJson) {
        this.setState({
          TraderouteData: responseJson
        })
      } else {
        toast.error("Something went wrong!");
      }

    }
  }
  ServiceResponse = (apiRequestCallId: string, responseJson: { [key: string]: string }[]) => {
    if (apiRequestCallId === this.ServiceAPiId) {
      if (responseJson) {
        this.setState({
          ServiceData: responseJson
        })
      } else {
        toast.error("Something went wrong!");
      }


    }
  }
  handleNavigation = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'SellerListing'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }
  PortlocationResponse = (apiRequestCallId: string, responseJson: { [key: string]: string }[]) => {
    if (apiRequestCallId === this.PortlocationAPiId) {
      if (responseJson) {
        this.setState({
          PortlocationData: responseJson
        })
      } else {
        toast.error("Something went wrong!");
      }

    }
  }
  DestinationportResponse = (apiRequestCallId: string, responseJson: { [key: string]: string }[]) => {
    if (apiRequestCallId === this.DestinationportAPiId) {
      if (responseJson) {
        this.setState({
          DestinationportData: responseJson
        })
      } else {
        toast.error("Something went wrong!");
      }

    }
  }
  TraderouteTooltipResponse = (apiRequestCallId: string, responseJson: { data: { id: string, attributes: { trade_route_classification: "string", service: "string", description: "string" } }[] }) => {
    if (apiRequestCallId === this.tradeRoutetooltipAPiId) {
      if (responseJson) {
        this.setState({
          TooltipData: responseJson.data
        })
      } else {
        toast.error("Something went wrong!");
      }

    }
  }
  handleDownloadfileResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.DownloadfileAPiId) {
      if (responseJson) {
        const csvData = responseJson
        let obj = csvData[0]
        let allValues = ''
        for (const rowData of csvData) {
          allValues += Object.values(rowData).join(",") + "\n";
        }
        let allk = Object.keys(obj)
        const allKeys = allk.join(",")
        const result = allKeys + '\n' + allValues

        this.exportData(result, 'TestingFile.csv', 'text/csv;charset=utf-8;');
      }
      if (responseJson.message) {
        toast.error(responseJson.message)
      } 
    }

  }
  exportData = (data: any, fileName: string, type: string) => {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  handleTradeClick = (index: number) => {
    this.setState({ selectedTradeIndex: index });
  };

  handleButtonClick = (button: string) => {
    let selectedFunction;
    switch (button) {
      case '1M':
        selectedFunction = this.oneMonth
        break;
      case '6M':
        selectedFunction = this.sixMonths
        break;
      case '1YR':
        selectedFunction = this.oneYear
        break;
      case 'YTD':
        selectedFunction = this.YTD
        break;
      default:
        selectedFunction = this.oneMonth;
    }
    this.setState({
      selectedButton: button,
      StartDate: "", EndDate: ""
    });

    if (selectedFunction) {
      selectedFunction();
    }
  };

  oneMonth = () => {
    const userSelected = this.state.UserSelect
    const currentDate = new Date();
    const currentDateString = currentDate.toISOString().slice(0, 10);
    let oneMonthago = new Date(currentDate);
    oneMonthago.setDate(currentDate.getDate() - 27);
    const oneMonthagoString = oneMonthago.toISOString().slice(0, 10);
    const previousValue = this.state.MontlyUtilizationValue
    this.setState({
      MontlyUtilizationValue: `${"range1"}=${oneMonthagoString}&${"range2"}=${currentDateString}`,
    }, () => {
      this.handleMontlyutilization(this.state.loginToken, previousValue, this.state.MontlyUtilizationValue, userSelected)
    })
  }
  sixMonths = () => {
    const userSelected = this.state.UserSelect
    const currentDate = new Date();
    const currentDateString = currentDate.toISOString().slice(0, 10);
    const sixMonthsAgo = new Date(currentDate);
    sixMonthsAgo.setMonth(currentDate.getMonth() - 5);
    const sixMonthsAgoString = sixMonthsAgo.toISOString().slice(0, 10);
    const previousValue = this.state.MontlyUtilizationValue
    this.setState({
      MontlyUtilizationValue: `${"range1"}=${sixMonthsAgoString}&${"range2"}=${currentDateString}`,
    }, () => {
      this.handleMontlyutilization(this.state.loginToken, previousValue, this.state.MontlyUtilizationValue, userSelected)

    })
  }
  oneYear = () => {
    const userSelected = this.state.UserSelect
    const currentDate = new Date();
    const currentDateString = currentDate.toISOString().slice(0, 10);
    const oneYear = new Date(currentDate);
    oneYear.setMonth(currentDate.getMonth() - 12);
    const oneYearString = oneYear.toISOString().slice(0, 10);
    const previousValue = this.state.MontlyUtilizationValue
    this.setState({
      MontlyUtilizationValue: `${"range1"}=${oneYearString}&${"range2"}=${currentDateString}`,
    }, () => {
      this.handleMontlyutilization(this.state.loginToken, previousValue, this.state.MontlyUtilizationValue, userSelected)
    })
  }
  YTD = () => {
    const userSelected = this.state.UserSelect
    const currentDate = new Date();
    const currentDateString = currentDate.toISOString().slice(0, 10);
    const YTD = new Date(currentDate.getFullYear(), 0, 1);
    const YTDString = YTD.toLocaleDateString()
    const previousValue = this.state.MontlyUtilizationValue
    this.setState({
      MontlyUtilizationValue: `${"range1"}=${YTDString}&${"range2"}=${currentDateString}`,
    }, () => {
      this.handleMontlyutilization(this.state.loginToken, previousValue, this.state.MontlyUtilizationValue, userSelected)
    })
  }
  handleChange = (event: ChangeEvent<{}>, newValue: unknown) => {
    const userSelected = this.state.UserSelect
    const updatedValue: string | null = typeof newValue === 'string' ? newValue : null;

    let previousValue = this.state.tradeRouteSelect
    this.setState({
      tradeRouteSelect: updatedValue || "",
    }, () => {
      this.handlefedderGraphlists(this.state.loginToken, this.state.tradeRouteSelect, "ALL", 'trade_route')
    });
  }
  handleChangetradeRoute = (event: ChangeEvent<{}>, newValue: unknown) => {
    const updatedValue: string | null = typeof newValue === 'string' ? newValue : null;

    this.setState({
        tradeSelect: updatedValue || "",
        count: updatedValue ? 1 : this.state.count + 1,
        UserSelect: 'trade_route'
    }, () => {
        this.handleAPIfiltercall(this.state.loginToken, 'trade_route');
    });
}

  handleChangeService = (event: ChangeEvent<{}>, newValue: unknown) => {
    const updatedValue: string | null = typeof newValue === 'string' ? newValue : null;
    this.setState({
          serviceSelect: updatedValue || "",
          count: this.state.serviceSelect.length > 0 ? 1 : this.state.count + 1,
          UserSelect: "service_c"
        }, () => {
          this.handleAPIfiltercall(this.state.loginToken, "service_c")
        });
  }

 
  handleChangePortlocation = (event: ChangeEvent<{}>, newValue: unknown) => {
    const updatedValue: string | null = typeof newValue === 'string' ? newValue : null;

    this.setState({
      PortlocationSelect: updatedValue || "",
      count: this.state.PortlocationSelect.length > 0 ? 1 : this.state.count + 1,
      UserSelect: "last_port"

    }, () => {
      this.handleAPIfiltercall(this.state.loginToken, "last_port")
    });
  }
  handleChangedestinationport = (event: ChangeEvent<{}>, newValue: unknown) => {
    const updatedValue: string | null = typeof newValue === 'string' ? newValue : null;

    this.setState({
      DestinationlocationSelect: updatedValue ||"",
      count: this.state.DestinationlocationSelect.length > 0 ? 1 : this.state.count + 1,
      UserSelect: "next_port"

    }, () => {
      this.handleAPIfiltercall(this.state.loginToken, "next_port")
    });

  }
    handleChangeIn = (event: React.ChangeEvent<{ name: string | undefined; value: string[] }>) => {
    const userSelected = this.state.UserSelect
    let previousValue = this.state.inboundSelect

    const allData = this.state.ListofdirectionsData.map((opt) => opt.direction)

    if(event.target.value.includes("ALL")){
      if(event.target.value.length === this.state.inboundSelect.length){
        this.setState({ inboundSelect:  allData}, () => {
          this.handleInboundservices(this.state.loginToken, previousValue, this.state.inboundSelect, userSelected)
        });
      } else {
        const lastValue = event.target.value[event.target.value.length - 1]
        if(lastValue === "ALL"){
          this.setState({ inboundSelect: allData }, () => {
            this.handleInboundservices(this.state.loginToken, previousValue, this.state.inboundSelect, userSelected)
          });
        } else {
          const data = event.target.value.filter((option : string) => option !== "ALL").map((opt : string) => opt)
          this.setState({ inboundSelect: data }, () => {
            this.handleInboundservices(this.state.loginToken, previousValue, this.state.inboundSelect, userSelected)
          });
        }
      }
    } else {
      if(event.target.value.length === (this.state.ListofdirectionsData.length -1)){
        this.setState({ inboundSelect: allData }, () => {
          this.handleInboundservices(this.state.loginToken, previousValue, this.state.inboundSelect, userSelected)
        });
      } else {
        this.setState({ inboundSelect: event.target.value }, () => {
          this.handleInboundservices(this.state.loginToken, previousValue, event.target.value, userSelected)
        });
      }
    }
    }
    handleChangeOut = (event: React.ChangeEvent<{ name: string | undefined; value: string[] }>) => {
    const userSelected = this.state.UserSelect
    let previousValue = this.state.outboundSelect

    const allOBData = this.state.ListofdirectionsData.map((opt) => opt.direction)

    if(event.target.value.includes("ALL")){
      if(event.target.value.length === this.state.outboundSelect.length){
        this.setState({ outboundSelect:  allOBData}, () => {
          this.handleOutboundservices(this.state.loginToken, previousValue, this.state.outboundSelect, userSelected)
        });
      } else {
        const lastValue = event.target.value[event.target.value.length - 1]
        if(lastValue === "ALL"){
          this.setState({ outboundSelect: allOBData }, () => {
            this.handleOutboundservices(this.state.loginToken, previousValue, this.state.outboundSelect, userSelected)
          });
        } else {
          const obData = event.target.value.filter((option : string) => option !== "ALL").map((opt : string) => opt)
          this.setState({ outboundSelect: obData }, () => {
            this.handleOutboundservices(this.state.loginToken, previousValue, this.state.outboundSelect, userSelected)
          });
        }
      }
    } else {
      if(event.target.value.length === (this.state.ListofdirectionsData.length -1)){
        this.setState({ outboundSelect: allOBData }, () => {
          this.handleOutboundservices(this.state.loginToken, previousValue, this.state.outboundSelect, userSelected)
        });
      } else {
        this.setState({ outboundSelect: event.target.value }, () => {
          this.handleOutboundservices(this.state.loginToken, previousValue, event.target.value, userSelected)
        });
      }
    }
  }
  handleDialogOpen = () =>
    this.setState({ openDialog: true })
  handleDialogCLose = () =>
    this.setState({ openDialog: false })

  getDisplayName = (feeder: string) => {
    switch (feeder) {
      case "E":
        return "East";
      case "W":
        return "West";
      case "S":
        return "South";
      case "N":
        return "North";
      default:
        return "ALL";
    }
  };

  TogolFilter = () => {
    this.setState({
      open: !this.state.open
    });
  }

  SetDateFilterData = (range: DateRange) => {
    const userSelected = this.state.UserSelect
    const previousValue = this.state.MontlyUtilizationValue
    this.setState({
      RenderStart: moment(range.startDate).format("MMM-YYYY").toUpperCase(), RenderEndDate: moment(range.endDate).format("MMM-YYYY").toUpperCase(),
      StartDate: moment(range.startDate).format(), EndDate: moment(range.endDate).format(),
      filter: { ...this.state.filter, startDate: moment(range.startDate).format("YYYY-MM-DD"), endDate: moment(range.endDate).format("YYYY-MM-DD") },
      MontlyUtilizationValue: `${"range1"}=${moment(range.startDate).format("YYYY-MM-DD")}&${"range2"}=${moment(range.endDate).format("YYYY-MM-DD")}`,
      open: !this.state.open,
      selectedButton:""
    }, () => { this.handleMontlyutilization(this.state.loginToken, previousValue, this.state.MontlyUtilizationValue, userSelected) })
  }

  FilterDateSelect = () => {
    const { StartDate, EndDate } = this.state
    if (StartDate === "") {
      toast.warning("Select start date")
    } else if (EndDate === "") {
      toast.warning("Select end date")
    }
  }

  ClearFilterData = () => {
    this.setState({
      filter: { ...this.state.filter, startDate: "", endDate: "" },
      open: false,
      RenderStart: "", RenderEndDate: "",
      StartDate: "", EndDate: "",
    })
  }

  updateInboundQueryParameter = (InboundPercentendpoint: string, key: string, value: string) => {
    const parameterKey = `${key}=`;
    const parameterAlreadyExists = InboundPercentendpoint.includes(parameterKey);

    if (!parameterAlreadyExists) {
      InboundPercentendpoint += `${key}=${value}`;
    } else {
      InboundPercentendpoint = InboundPercentendpoint.replace(new RegExp(`${key}=[^&]*`), `${key}=${value}`);
    }

    return InboundPercentendpoint;
  }


  handleInboundUtilisationPercentage = (token: string, userSelected: string) => {
    let InboundPercentendpoint = this.state.InboundURLendpoint

    if (this.state.count === 0) {
      InboundPercentendpoint = configJSON.InboundPercentageAPIEndpoint
    }
    else if (this.state.count === 1) {
      InboundPercentendpoint += InboundPercentendpoint.includes(userSelected) ? '' : '?';
    } else if (this.state.count > 1) {
      InboundPercentendpoint += `&`
    }
    else {
      InboundPercentendpoint = configJSON.InboundPercentageAPIEndpoint
    }

    switch (userSelected) {
      case 'trade_route':
        InboundPercentendpoint = this.updateInboundQueryParameter(InboundPercentendpoint, 'trade_route', this.state.tradeSelect);
        break;
      case 'service_c':
        InboundPercentendpoint = this.updateInboundQueryParameter(InboundPercentendpoint, 'service_c', this.state.serviceSelect);
        break;
      case 'last_port':
        InboundPercentendpoint = this.updateInboundQueryParameter(InboundPercentendpoint, 'last_port', this.state.PortlocationSelect);
        break;
      case 'next_port':
        InboundPercentendpoint = this.updateInboundQueryParameter(InboundPercentendpoint, 'next_port', this.state.DestinationlocationSelect);
        break;
      default:
        break;
    }

    this.setState({
      InboundURLendpoint: InboundPercentendpoint,
      loading: true
    })


    const header = {
      "Content-Type": "application/json",
      "Token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.InboundUtilisationPercentageApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      InboundPercentendpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateOutboundQueryParameter = (outboundPercentendpoint: string, key: string, value: string) => {
    const parameterKey = `${key}=`;
    const parameterAlreadyExists = outboundPercentendpoint.includes(parameterKey);

    if (!parameterAlreadyExists) {
      outboundPercentendpoint += `${key}=${value}`;
    } else {
      outboundPercentendpoint = outboundPercentendpoint.replace(new RegExp(`${key}=[^&]*`), `${key}=${value}`);
    }

    return outboundPercentendpoint;
  }


  handleOutboundUtilisationPercentage = (token: string, userSelected: string) => {
    let outboundPercentendpoint = this.state.OutboundURLendpoint

    if (this.state.count === 0) {
      outboundPercentendpoint = configJSON.OutboundPercentageAPIEndpoint
    }
    else if (this.state.count === 1) {
      outboundPercentendpoint += outboundPercentendpoint.includes(userSelected) ? '' : '?';
    } else if (this.state.count > 1) {
      outboundPercentendpoint += `&`
    }

    else {
      outboundPercentendpoint = configJSON.OutboundPercentageAPIEndpoint
    }

    switch (userSelected) {
      case 'trade_route':
        outboundPercentendpoint = this.updateOutboundQueryParameter(outboundPercentendpoint, 'trade_route', this.state.tradeSelect);
        break;
      case 'service_c':
        outboundPercentendpoint = this.updateOutboundQueryParameter(outboundPercentendpoint, 'service_c', this.state.serviceSelect);
        break;
      case 'last_port':
        outboundPercentendpoint = this.updateOutboundQueryParameter(outboundPercentendpoint, 'last_port', this.state.PortlocationSelect);
        break;
      case 'next_port':
        outboundPercentendpoint = this.updateOutboundQueryParameter(outboundPercentendpoint, 'next_port', this.state.DestinationlocationSelect);
        break;
      default:
        break;
    }

    this.setState({
      OutboundURLendpoint: outboundPercentendpoint,
      loading: true
    })

    const header = {
      "Content-Type": "application/json",
      "Token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.OutboundUtilisationPercentageApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      outboundPercentendpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateOutboundweightQueryParameter = (outboundWeightendpoint: string, key: string, value: string) => {
    const parameterKey = `${key}=`;
    const parameterAlreadyExists = outboundWeightendpoint.includes(parameterKey);

    if (!parameterAlreadyExists) {
      outboundWeightendpoint += `${key}=${value}`;
    } else {
      outboundWeightendpoint = outboundWeightendpoint.replace(new RegExp(`${key}=[^&]*`), `${key}=${value}`);
    }

    return outboundWeightendpoint;
  }

  handleOutboundUtilisationWeightPercentage = (token: string, userSelected: string) => {
    let outboundWeightendpoint = this.state.OutboundWeightURLendpoint

    if (this.state.count === 0) {
      outboundWeightendpoint = configJSON.OutboundWeightPercentageAPIEndpoint
    }
    else if (this.state.count === 1) {
      outboundWeightendpoint += outboundWeightendpoint.includes(userSelected) ? '' : '?';
    } else if (this.state.count > 1) {
      outboundWeightendpoint += `&`
    }

    else {
      outboundWeightendpoint = configJSON.OutboundWeightPercentageAPIEndpoint
    }

    switch (userSelected) {
      case 'trade_route':
        outboundWeightendpoint = this.updateOutboundweightQueryParameter(outboundWeightendpoint, 'trade_route', this.state.tradeSelect);
        break;
      case 'service_c':
        outboundWeightendpoint = this.updateOutboundweightQueryParameter(outboundWeightendpoint, 'service_c', this.state.serviceSelect);
        break;
      case 'last_port':
        outboundWeightendpoint = this.updateOutboundweightQueryParameter(outboundWeightendpoint, 'last_port', this.state.PortlocationSelect);
        break;
      case 'next_port':
        outboundWeightendpoint = this.updateOutboundweightQueryParameter(outboundWeightendpoint, 'next_port', this.state.DestinationlocationSelect);
        break;
      default:
        break;
    }

    this.setState({
      OutboundWeightURLendpoint: outboundWeightendpoint,
      loading: true
    })
    const header = {
      "Content-Type": "application/json",
      "Token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.OutboundUtilisationWeightAPiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      outboundWeightendpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateServiceQueryParameter = (serviceEndpoint: string, key: string, value: string) => {
    const parameterKey = `${key}=`;
    const parameterAlreadyExists = serviceEndpoint.includes(parameterKey);

    if (!parameterAlreadyExists) {
      serviceEndpoint += `${key}=${value}`;
    } else {
      serviceEndpoint = serviceEndpoint.replace(new RegExp(`${key}=[^&]*`), `${key}=${value}`);
    }

    return serviceEndpoint;
  }

  handleServiceoverviewData = (token: string, userSelected: string) => {
    let serviceEndpoint = this.state.ServiceoverviewURLendpoint

    if (this.state.count === 0) {
      serviceEndpoint = configJSON.ServiceoverviewTableAPIEndpoint
    }
    else if (this.state.count === 1) {
      serviceEndpoint += serviceEndpoint.includes(userSelected) ? '' : '?';
    } else if (this.state.count > 1) {
      serviceEndpoint += `&`
    }

    else {
      serviceEndpoint = configJSON.ServiceoverviewTableAPIEndpoint
    }

    switch (userSelected) {
      case 'trade_route':
        serviceEndpoint = this.updateServiceQueryParameter(serviceEndpoint, 'trade_route', this.state.tradeSelect);
        break;
      case 'service_c':
        serviceEndpoint = this.updateServiceQueryParameter(serviceEndpoint, 'service_c', this.state.serviceSelect);
        break;
      case 'last_port':
        serviceEndpoint = this.updateServiceQueryParameter(serviceEndpoint, 'last_port', this.state.PortlocationSelect);
        break;
      case 'next_port':
        serviceEndpoint = this.updateServiceQueryParameter(serviceEndpoint, 'next_port', this.state.DestinationlocationSelect);
        break;
      default:
        break;
    }

    this.setState({
      ServiceoverviewURLendpoint: serviceEndpoint,
      loading: true
    })
    const header = {
      "Content-Type": "application/json",
      "Token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ServiceoverviewTableAPIEndpointAPiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      serviceEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleListofdirections = (token: string) => {
    const header = {
      "Content-Type": "application/json",
      "Token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ListofdirectionAPIEndpointAPiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ListofdirectionsAPIEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateInboundSGEndpointParameter = (inboundEndpoint: string, key: any, value: any) => {
    const parameterKey = `${key}=`;
    const parameterAlreadyExists = inboundEndpoint.includes(parameterKey);

    if (!parameterAlreadyExists) {
      inboundEndpoint += `&${key}=${value}`;
    } else {
      inboundEndpoint = inboundEndpoint.replace(new RegExp(`${key}=[^&]*`), `${key}=${value}`);
    }
    return inboundEndpoint;
  };

  handleInboundservices = (token: string, previousValue: string[], key: string[], userSelected: string) => {

    const Pervkey = JSON.stringify(previousValue)
    const keyOne = JSON.stringify(key)
    let inboundEndpoint = this.state.InboundSGURLendpoint
    if (this.state.count === 0) {
      if(keyOne != '[]'){
        inboundEndpoint = configJSON.InboundservicesAPIEndpoint + `${keyOne}`
      } else {
        inboundEndpoint = "/bx_block_datamigration2/services_inbound_percentage"
      }
    }
    if (keyOne) {
      inboundEndpoint = inboundEndpoint.replace(Pervkey, keyOne);
    }

    switch (userSelected) {
      case "trade_route":
        inboundEndpoint = this.updateInboundSGEndpointParameter(inboundEndpoint, "trade_route", this.state.tradeSelect);
        break;
      case "service_c":
        inboundEndpoint = this.updateInboundSGEndpointParameter(inboundEndpoint, "service_c", this.state.serviceSelect);
        break;
      case "last_port":
        inboundEndpoint = this.updateInboundSGEndpointParameter(inboundEndpoint, "last_port", this.state.PortlocationSelect);
        break;
      case "next_port":
        inboundEndpoint = this.updateInboundSGEndpointParameter(inboundEndpoint, "next_port", this.state.DestinationlocationSelect);
        break;
      default:
        break;
    }



    this.setState({
      InboundSGURLendpoint: inboundEndpoint,
      loading: true
    })


    const header = {
      "Content-Type": "application/json",
      "Token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.InboundservicesAPiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      inboundEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateOutboundSGEndpointParameter = (outboundEndpoint: string, key: any, value: any) => {
    const parameterKey = `${key}=`;
    const parameterAlreadyExists = outboundEndpoint.includes(parameterKey);

    if (!parameterAlreadyExists) {
      outboundEndpoint += `&${key}=${value}`;
    } else {
      outboundEndpoint = outboundEndpoint.replace(new RegExp(`${key}=[^&]*`), `${key}=${value}`);
    }
    return outboundEndpoint;
  };

  handleOutboundservices = (token: string, previousValue: string[], key: string[], userSelected: string) => {
    const Pervkey = JSON.stringify(previousValue)
    const keyOne = JSON.stringify(key)
    let outboundEndpoint = this.state.OutboundSGURLendpoint
    if (keyOne) {
      outboundEndpoint = outboundEndpoint.replace(Pervkey, keyOne);
    }
    if (this.state.count === 0) {
      if(keyOne != "[]"){
        outboundEndpoint = configJSON.OutboundservicesAPIEndpoint + `${keyOne}`
      } else {
        outboundEndpoint = "/bx_block_datamigration2/services_outound_percentage"
      }
    }

    switch (userSelected) {
      case "trade_route":
        outboundEndpoint = this.updateOutboundSGEndpointParameter(outboundEndpoint, "trade_route", this.state.tradeSelect);
        break;
      case "service_c":
        outboundEndpoint = this.updateOutboundSGEndpointParameter(outboundEndpoint, "service_c", this.state.serviceSelect);
        break;
      case "last_port":
        outboundEndpoint = this.updateOutboundSGEndpointParameter(outboundEndpoint, "last_port", this.state.PortlocationSelect);
        break;
      case "next_port":
        outboundEndpoint = this.updateOutboundSGEndpointParameter(outboundEndpoint, "next_port", this.state.DestinationlocationSelect);
        break;
      default:
        break;
    }

    this.setState({
      OutboundSGURLendpoint: outboundEndpoint,
      loading: true
    })

    const header = {
      "Content-Type": "application/json",
      "Token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.OutboundservicesAPiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      outboundEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleFedderlist = (token: string) => {
    const header = {
      "Content-Type": "application/json",
      "Token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.FeederlistDataAPiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FedderlistAPIEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updatefeederEndpointParameter = (feederEndpoint: string, key: any, value: any) => {
    const parameterKey = `${key}=`;
    const parameterAlreadyExists = feederEndpoint.includes(parameterKey);

    if (!parameterAlreadyExists) {
      feederEndpoint += `&${key}=${value}`;
    } else {
      feederEndpoint = feederEndpoint.replace(new RegExp(`${key}=[^&]*`), `${key}=${value}`);
    }
    return feederEndpoint;
  };
  handlefedderGraphlist = (token: string, value: string, pervState: string, userSelected: string) => {

    let feederEndpoint = this.state.FeddergraphURLendpoint
    if (value) {
      feederEndpoint = feederEndpoint.replace(pervState, value);
    }
    if (this.state.count === 0) {
      feederEndpoint = configJSON.FeddergraphAPIEndpoint + `${value}`
    }

    switch (userSelected) {
      case "trade_route":
        feederEndpoint = this.updatefeederEndpointParameter(feederEndpoint, "trade_route", this.state.tradeSelect);
        break;
      case "service_c":
        feederEndpoint = this.updatefeederEndpointParameter(feederEndpoint, "service_c", this.state.serviceSelect);
        break;
      case "last_port":
        feederEndpoint = this.updatefeederEndpointParameter(feederEndpoint, "last_port", this.state.PortlocationSelect);
        break;
      case "next_port":
        feederEndpoint = this.updatefeederEndpointParameter(feederEndpoint, "next_port", this.state.DestinationlocationSelect);
        break;
      default:
        break;
    }

    this.setState({
      FeddergraphURLendpoint: feederEndpoint,
      loading: true
    })


    const header = {
      "Content-Type": "application/json",
      "Token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.FeedergraphDataAPiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      feederEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlefedderGraphlists = (token: string, value: string, pervState: string, userSelected: string) => {
    let feederEndpoint = this.state.FeddergraphURLendpoint
    switch (userSelected) {
      case "trade_route":
        feederEndpoint = this.updatefeederEndpointParameter(feederEndpoint, 
          "trade_route", this.state.tradeRouteSelect);
        break;
        case "service_c":
          feederEndpoint = 
          this.updatefeederEndpointParameter(feederEndpoint, "service_c", this.state.serviceSelect);
          break;
        case "last_port":
          feederEndpoint = 
          this.updatefeederEndpointParameter(feederEndpoint, "last_port", this.state.PortlocationSelect)
          break;
      default:
        break;
    }
    this.setState({
      FeddergraphURLendpoint: feederEndpoint, loading: true
    })


    const header = {
      "Content-Type": "application/json", "Token": token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage) );
    this.FeedergraphDataAPiId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      feederEndpoint  );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
    );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType
    );
       runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  handleUpdatetime = (token: string, userSelected: string) => {
    const header = {
      "Content-Type": "application/json",
      "Token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.UpdatetimeAPiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.UpdatedtimeAPIEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updatemonthlyEndpointParameter = (monthlyEndpoint: string, key: any, value: any) => {
    const parameterKey = `${key}=`;
    const parameterAlreadyExists = monthlyEndpoint.includes(parameterKey);

    if (!parameterAlreadyExists) {
      monthlyEndpoint += `&${key}=${value}`;
    } else {
      monthlyEndpoint = monthlyEndpoint.replace(new RegExp(`${key}=[^&]*`), `${key}=${value}`);
    }
    return monthlyEndpoint;
  };

  handleMontlyutilization = (token: string, currentdate: string, selecteddate: string, userSelected: string) => {
    let monthlyEndpoint = this.state.MonthlyUtilizationURLendpoint
    if (selecteddate) {
      monthlyEndpoint = monthlyEndpoint.replace(currentdate, selecteddate);
    }
    if (this.state.count === 0) {
      monthlyEndpoint = configJSON.MonthlyutilizationAPIEndpoint + `${selecteddate}`
    }


    switch (userSelected) {
      case "trade_route":
        monthlyEndpoint = this.updatemonthlyEndpointParameter(monthlyEndpoint, "trade_route", this.state.tradeSelect);
        break;
      case "service_c":
        monthlyEndpoint = this.updatemonthlyEndpointParameter(monthlyEndpoint, "service_c", this.state.serviceSelect);
        break;
      case "last_port":
        monthlyEndpoint = this.updatemonthlyEndpointParameter(monthlyEndpoint, "last_port", this.state.PortlocationSelect);
        break;
      case "next_port":
        monthlyEndpoint = this.updatemonthlyEndpointParameter(monthlyEndpoint, "next_port", this.state.DestinationlocationSelect);
        break;
      default:
        break;
    }

    this.setState({
      MonthlyUtilizationURLendpoint: monthlyEndpoint,
      loading: true
    })

    const header = {
      "Content-Type": "application/json",
      "Token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.MonthlyUtilizationAPiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      monthlyEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleListtraderoutes = (token: string) => {
    const header = {
      "Content-Type": "application/json",
      "Token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.TraderouteAPiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.TraderouteAPIEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleListofservices = (token: string) => {
    const header = {
      "Content-Type": "application/json",
      "Token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ServiceAPiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ServiceAPIEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleListofPortlocation = (token: string) => {
    const header = {
      "Content-Type": "application/json",
      "Token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PortlocationAPiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PortlocationAPIEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleListofDestination = (token: string) => {
    const header = {
      "Content-Type": "application/json",
      "Token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.DestinationportAPiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.DestinationportAPIEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleTraderouteTooltip = (token: string) => {
    const header = {
      "Content-Type": "application/json",
      "Token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.tradeRoutetooltipAPiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.TraderoutetooltipAPIEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateDownloadQueryParameter = (downlodEndpoint: string, key: string, value: string) => {
    const parameterKey = `${key}=`;
    const parameterAlreadyExists = downlodEndpoint.includes(parameterKey);

    if (!parameterAlreadyExists) {
      downlodEndpoint += `${key}=${value}`;
    } else {
      downlodEndpoint = downlodEndpoint.replace(new RegExp(`${key}=[^&]*`), `${key}=${value}`);
    }

    return downlodEndpoint;
  }

  handleDownloadfile = (userSelected: string) => {
    let downlodEndpoint = this.state.DownloadURLendpoint

    if (this.state.count === 0) {
      downlodEndpoint = configJSON.DownloadfileAPIEndpoint
    }
    else if (this.state.count === 1) {
      downlodEndpoint += downlodEndpoint.includes(userSelected) ? '' : '?';
    } else if (this.state.count > 1) {
      downlodEndpoint += `&`
    }

    else {
      downlodEndpoint = configJSON.DownloadfileAPIEndpoint
    }

    switch (userSelected) {
      case 'trade_route':
        downlodEndpoint = this.updateDownloadQueryParameter(downlodEndpoint, 'trade_route', this.state.tradeSelect);
        break;
      case 'service_c':
        downlodEndpoint = this.updateDownloadQueryParameter(downlodEndpoint, 'service_c', this.state.serviceSelect);
        break;
      case 'last_port':
        downlodEndpoint = this.updateDownloadQueryParameter(downlodEndpoint, 'last_port', this.state.PortlocationSelect);
        break;
      case 'next_port':
        downlodEndpoint = this.updateDownloadQueryParameter(downlodEndpoint, 'next_port', this.state.DestinationlocationSelect);
        break;
      default:
        break;
    }

    this.setState({
      DownloadURLendpoint: downlodEndpoint
    })
    const header = {
      "Token": this.state.loginToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.DownloadfileAPiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      downlodEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSortinservice = () => {
    const newToggle = !this.state.InServicesorting;
    this.setState({ InServicesorting: newToggle });
    this.setState(prevState => ({
      InboundservicesData: prevState.InboundservicesData.slice().sort((a, b) => {
        return newToggle
          ? a.service.localeCompare(b.service)
          : b.service.localeCompare(a.service);
      })
    }));
  }
  handleSortinbound = () => {
    const newToggle = !this.state.Insorting;
    this.setState({ Insorting: newToggle });
    this.setState(prevState => ({
      InboundservicesData: prevState.InboundservicesData.slice().sort((a, b) => {
        return newToggle
          ? a.inbound - b.inbound
          : b.inbound - a.inbound;
      })
    }));
  }
  handleSortoutservice = () => {
    const newToggle = !this.state.outServicesorting;
    this.setState({ outServicesorting: newToggle });
    this.setState(prevState => ({
      OutboundservicesData: prevState.OutboundservicesData.slice().sort((a, b) => {
        return newToggle
          ? a.service.localeCompare(b.service)
          : b.service.localeCompare(a.service);
      })
    }));
  }
  handleSortoutbound = () => {
    const newToggle = !this.state.Outsorting;
    this.setState({ Outsorting: newToggle });
    this.setState(prevState => ({
      OutboundservicesData: prevState.OutboundservicesData.slice().sort((a, b) => {
        return newToggle
          ? a.outbound - b.outbound
          : b.outbound - a.outbound;
      })
    }));

  }
  handleServicecode = () => {
    const newToggle = !this.state.serviceSorting;
    this.setState({ serviceSorting: newToggle });
    this.setState(prevState => {
      const sortedData = prevState.ServicetableData ? prevState.ServicetableData.slice().sort((a, b) => {
        return newToggle
          ? a.service_code.localeCompare(b.service_code)
          : b.service_code.localeCompare(a.service_code);
      }) : [];

      return { ServicetableData: sortedData };
    });
  }
  handleDirection = () => {
    const newToggle = !this.state.directionSorting;
    this.setState({ directionSorting: newToggle });
    this.setState(prevState => ({
      ServicetableData: prevState.ServicetableData.slice().sort((a, b) => {
        return newToggle
          ? a.direction.localeCompare(b.direction)
          : b.direction.localeCompare(a.direction)
      })
    }));

  }
  handleInbound = () => {
    const newToggle = !this.state.inboundSorting;
    this.setState({ inboundSorting: newToggle });
    this.setState(prevState => ({
      ServicetableData: prevState.ServicetableData.slice().sort((a, b) => {
        return newToggle
          ? a.inbound_weight - b.inbound_weight
          : b.inbound_weight - a.inbound_weight;
      })
    }));

  }
  handleoutbound = () => {
    const newToggle = !this.state.outboundSorting;
    this.setState({ outboundSorting: newToggle });
    this.setState(prevState => ({
      ServicetableData: prevState.ServicetableData.slice().sort((a, b) => {
        return newToggle
          ? a.outbound_weight - b.outbound_weight
          : b.outbound_weight - a.outbound_weight;
      })
    }));

  }
  handleoutboundweight = () => {
    const newToggle = !this.state.outboundWeightsorting;
    this.setState({ outboundWeightsorting: newToggle });
    this.setState(prevState => ({
      ServicetableData: prevState.ServicetableData.slice().sort((a, b) => {
        return newToggle
          ? a.outbound_teu - b.outbound_teu
          : b.outbound_teu - a.outbound_teu;
      })
    }));

  }
  // Customizable Area End
}
