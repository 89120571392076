import React, { Component } from "react";
import { styled, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import { StepIconProps } from "@material-ui/core/StepIcon";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, Grid, Typography, Tooltip } from "@material-ui/core";
import DirectionsBoatIcon from "@material-ui/icons/DirectionsBoat";
import moment from 'moment';
import { Message } from "../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { IBlock } from "../../framework/src/IBlock";
import { getStorageData} from "../../framework/src/Utilities";
import PhoneInput from "react-phone-input-2";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const CheckImage = require("./image_tick_white.png");
const BoxIcon = require("./Question_mark.png");
const Dropdown = require("./dropDownImage.png");
const pdf = require("./image_pdf.png");
const actionImage = require("./actionControl.png");
const actionLeft = require("./arrowLeft.png");
const arrowUp1 = require("./arrowUp1.png");

interface VerticalFileProps {
  handleNominationModal?: () => void;
  newState: number
  selectValue: string
  booking: any
  bookingStatus: boolean
}

interface VerticalFileState {
  activeStep: number;
  selectValue: any;
  subcatgValue: any;
  catgValue:any;
  invoiceSubcatgValue: any;
  invoiceCatgValue:any;
  SIFormData: any;
  SIFormVisible: boolean;
  shipperAccordion: boolean;
  consigneeAccordion: boolean;
  shipper_container_details: any;
  shippingDataAvailable: boolean;
  activeBookingReference:boolean;
  activeInvoice:boolean;
  activePaymentConf:boolean;
  paymentDetailsShowStatus: boolean;
  finalBLShowStatus: boolean;
  invoiceSubCategoryActive: boolean;
}


// const steps = [

//   {
//     label: "Nominate Containers",
//   },


//   {
//     label: "Submit Shipping Instructions",
//     container: ["Draft B/L Uploaded by Seller"]
//   },

//   {
//     label: "Confirm Draft B/L",
//     container: ["Invoice Uploaded by Seller"]
//   },
//   {
//     label: "Submit Payment Details",
//     container: ["Awaiting For Final B/L"]

//   },
//   {
//     label: "Estimated Date of Container Loading onto Vessel",
//   },
//   {
//     label: "Download Final B/L",
//   },
// ];


const steps = [

  {
    label: "Nominate Containers",
  },

  {
    label: "Submit Shipping Instructions",
    container: ["Awaiting For Draft B/L"]
  },

  {
    label: "Confirm Draft B/L",
    container: ["Awaiting For Invoice"]
  },
  {
    label: "Submit Payment Details",
   // container: ["Awaiting For Final B/L"]
  },
  {
    label: "Estimated Date of Container Loading onto Vessel",
  },
  {
    label: "Awaiting For Final B/L",
  }
];


const LightTooltip = withStyles((theme) => ({
  tooltip: { boxShadow: "0rem .375rem .9375rem -0.1875rem rgba(0, 0, 0, 0.25)", backgroundColor: theme.palette.common.white, color: "black", fontSize: "12px", display: " inline-flex", justifyContent: "center", borderRadius: ".5rem", alignItems: "center",fontFamily: "Asap, sans-serif",},
  arrow: {color: "white"}
}))(Tooltip);

const ColorlibConnector = withStyles({
  alternativeLabel: {
    left: 5,
  },
  active: {
    "& $line": {
      backgroundImage: "linear-gradient(#ff890f,#ff890f)",
      marginTop: -48,
      marginLeft: 20
    },
  },
  completed: {
    "& $line": {
      backgroundImage: "linear-gradient(#35D261,#35D261)",
      marginTop: -48,
      marginLeft: 20
    },
  },
  line: {
    width: 2,
    border: 0,
    marginTop: -5,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
    marginLeft: '20px'
  },
  lineVertical: {
    minHeight: '70px',
  },
})(StepConnector);

const ColorlibConnector1 = withStyles({
  alternativeLabel: {
    left: 5,
  },
  active: {
    "& $line": {
      backgroundImage: "linear-gradient(#35D261,#35D261)",
      marginTop: -48,
      marginLeft: 20
    },
  },
  completed: {
    "& $line": {
      backgroundImage: "linear-gradient(#35D261,#35D261)",
      marginTop: -48,
      marginLeft: 20
    },
  },
  line: {
    width: 2,
    border: 0,
    marginTop: -5,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
    marginLeft: '20px'
  },
  lineVertical: {
    minHeight: '70px',
  },
})(StepConnector);

const styles = {
  root: {
    backgroundColor: "#e7e7e7",
    zIndex: 1,
    color: "#707070",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    color: "#fff",
    backgroundColor: "#ff890f",
  },

  completed: {
    backgroundColor: "#e7e7e7",
  },

};

const StyledDialogModal = styled(Dialog)({
  "& .MuiDialog-paper": {
    width: "100%",
    maxWidth: "800px",
    borderRadius: "24px",
    padding: "5% 2%",
    paddingTop: "2%",
  },
  "& .stepsContainer": {
    display: 'flex',
    alignItems: 'flex-start',
  },
  "& .MuiFormHelperText-contained":{
    marginLeft:"0px"
      },
  "& .buyerTexterrorMsg": {
    color: "red",
    fontSize: "16px",
  },
  "& .nominateCheckbox": {
    color: "#1E1E1E",
    "& .MuiSvgIcon-root": { fontSize: 28 },
  },
  "& .nominateChecked": {
    color: "#FF5C00",
    "& .MuiSvgIcon-root": { fontSize: 28 },
  },
  "& .nominationClose": {
    textAlign: "right",
  },
  "& .nominationMsggrid": {
    textAlign: "center",
  },
  "& .nominationCloseimg": {
    height: "24px",
  },
  "& .nominationErrorimg": {
    height: "100px",
  },
  "& .nominationHeading": {
    fontWeight: 700,
    fontSize: "28px",
    color: "#1E1E1E",
    fontFamily: "Asap",
  },
  "& .revisionText": {
    fontWeight: 400,
    fontSize: "18px",
    color: "#1E1E1E",
    fontFamily: "Asap",
  },
  "& .reasonBox": {
    margin: "5%",
    textAlign: "start",
  },
  "& .reasonTextfield": {
    fontWeight: 400,
    fontSize: "18px",
    color: "#1E1E1E",
    fontFamily: "Asap",
    width: "100%",
  },

  "& .nominationLink": {
    fontWeight: 800,
    fontSize: "14px",
    color: "#1653DD",
    fontFamily: "Asap",
  },
  "& .nominationLinktext": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#1E1E1E",
    fontFamily: "Asap",
  },
  "& .nominationButton": {
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Asap",
    height: "fit-content",
    width: "fit-content",
    padding: "10px 70px",
    backgroundColor: "#FF5C00",
    color: "#fff",
    borderRadius: "25px",
    marginTop: "2%",
    textTransform: "capitalize",
  },
  "& .disbledBtn": {
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Asap",
    height: "fit-content",
    width: "250px",
    backgroundColor: "gray",
    color: "#fff",
    borderRadius: "25px",
    marginTop: "2%",
  },
  "& .nominationButtonOutlined": {
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Asap",
    height: "fit-content",
    width: "fit-content",
    padding: "8px 70px",
    backgroundColor: "#fff",
    color: "#FF5C00",
    borderRadius: "25px",
    marginTop: "2%",
    textTransform: "capitalize",
    border: '2px solid #FF5C00',
    marginLeft: '10px'
  },
  "& .addContainerbutton": {
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Asap",
    height: "fit-content",
    width: "fit-content",
    padding: "10px 50px",
    backgroundColor: "#FF5C00",
    color: "#fff",
    borderRadius: "25px",
    margin: "2% 0%",
    textTransform: "capitalize",
  },
  "& .AddContainerButtonDisable":{
    "& .Mui-disabled":{
      backgroundColor: "lightgrey"
    }
  },
  "&. addContainerimg": {
    height: "20px",
  },
  "& .nominationButtondisable": {
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Asap",
    height: "fit-content",
    width: "fit-content",
    padding: "10px 70px",
    backgroundColor: "#FF5C00",
    color: "#fff",
    borderRadius: "25px",
    marginTop: "2%",
    opacity: "0.3",
    textTransform: "capitalize",
  },
  "& .uploadButton": {
    width: "fit-content",
    padding: "10px 22px",
    textAlign: "start",
    textTransform: "capitalize",
  },
  "& .uploadButtonshow": {
    width: "fit-content",
    padding: "10px 100px",
    textAlign: "start",
    paddingLeft: "5px",
    position: "relative",
    margin: "1%",
    textTransform: "capitalize",
  },
  "& .verifyTextBox": {
    marginTop: "2%",
    padding: "1% 32%",
    textAlign: "start",
  },
  "& .paymentTextBox": {
    marginTop: "2%",
    padding: "1% 25%",
    textAlign: "start",
  },
  "& .dragging":{
    "& .uploadButton":{
      backgroundColor: "lightgrey"
    }
  },
  "& .recentButton": {
    width: "fit-content",
    padding: "10px 200px",
    textAlign: "start",
    paddingLeft:"5px",
    position:"relative",
    margin:"1%",
    textTransform:"uppercase",
    "& .MuiButton-label":{
      justifyContent:"flex-start"
    },
    "& .MuiButton-startIcon":{
      marginLeft: "0px"
    }
  },
  "& .uploadDeletebutton": {
    position:"absolute",
        right:"1%",
        top:"30%",
        height:"20px"
  },
  "& .pdfImg":{
   height:"20px"
  },
  "& .verificationText": {
    fontWeight: 700,
    fontSize: "16px",
    color: "#363636",
    fontFamily: "Asap",
  },
  "& .submittingAccordiongrid": {
    backgroundColor: "#E3E6ED",
  },
  "& .submittingSecondgrid": {
    marginTop: "2%",
    backgroundColor: "#E3E6ED",
    "& .MuiPaper-elevation1":{
      boxShadow: "none"
    }
  },
  "& .submittinggrid": {
    marginTop: "2%",
    backgroundColor: "#E3E6ED",
    padding: "1% 2%",
  },
  "& .submittingFirstgrid": {
    textAlign: "center",
    fontWeight: 700,
    fontSize: "24px",
    color: "#1E1E1E",
    fontFamily: "Asap",
  },
  "& .vesselTextfieldgrid": {
    padding: "1% 1%",
  },
  "& .vesselInformationtext": {
    textAlign: "center",
    fontWeight: 600,
    fontSize: "20px",
    color: "#1E1E1E",
    fontFamily: "Asap",
    position: "relative",
  },
  "& .HeadingTextHidden":{
    visibility:"hidden"
  },
  "& .vesselsubtext": {
    textAlign: "center",
    fontWeight: 600,
    fontSize: "18px",
    color: "#1E1E1E",
    fontFamily: "Asap",
    position: "relative",
  },
  "& .accordionDetails": {

    "& .MuiAccordionDetails-root": {
      padding: "0px 16px 16px !important",
    },
    
  },
  "& .vesselViewlesstext": {
    position: "absolute",
    right: "1%",
    fontSize: "12px",
  },
  "& .vesselCloseimg": {
    height: "18px",
    position: "absolute",
    right: "100%",
  },
  "& .vesselRemovetext": {
    position: "absolute",
    right: "1%",
    fontSize: "12px",
    top: "90%",
    color: "red",
  },
  "& .vesselTextfieldtext": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#1E1E1E",
    fontFamily: "Asap",
    textTransform: 'uppercase',
    alignItem: 'center',
    justifyContent: 'flex-start',
    display:'flex',
  },
  "& .vesselTextfieldtext1": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#1E1E1E",
    fontFamily: "Asap",
    alignItem: 'center',
    justifyContent: 'flex-start',
    display:'flex',
  },
  "& .PhoneInputPackgeClass": {
    height: "40px",
    borderRadius: "10px",
    fontSize: "15px",
    fontFamily: "Asap, sans-serif",
    width: "100%",
    fontWeight: 800,
    backgroundColor: "white",
    "@media (max-width: 1200px)": {
      fontSize: "12px",
      height: "30px",
    },
  },
  "& .textfieldStyle": {
    color: "black",
  },
});
const BoxModel = styled(Box)({
  "& .stepLabelbox": {
    height: "50px",
    width: "95%",
    border: "1px solid black",
    borderRadius: "25px",
    marginLeft: "2%",
    alignItems: 'center',
    paddingLeft:"2%"
  },
  "& .MuiStepLabel-root": {
    display: "flex",
    alignItems: "flex-start"
  },
  "& .stepLabelboxone": {
    height: "50px",
    width: "95%",
    border: "1px solid black",
    borderRadius: "25px",
    marginLeft: "2%",
    backgroundColor: "#FDF2E0",
    position: "relative",
    alignItems: 'center',
    paddingLeft:"2%"
  },
  "& .stepLabelboxoneChild": {
    height: "50px",
    width: "89%",
    border: "1px solid black",
    borderRadius: "25px",
    marginLeft: "8%",
    backgroundColor: "#FDF2E0",
    position: "relative",
    alignItems: 'center',
    paddingLeft:"2%"
  },
  "& .nominationLink": {
    fontWeight: 800,
    fontSize: "14px",
    color: "#1653DD",
    fontFamily: "Asap",
  },
  "& .stepLabelspan": {
    position: "absolute",
    top: "0px",
    right: "1px"
  },
  "& .invoiceStatus":{
    fontWeight: 400,
    fontSize: "14px",
    color: "#1653DD",
    fontFamily: "Asap",
    backgroundColor: '#F3FcF6',
    width: '85%',
    padding: '5px',
    marginLeft: '10%'
  },
  "& .invoiceTerms":{
    fontWeight: 400,
    fontSize: "14px",
    color: "#000000",
    fontFamily: "Asap",
    backgroundColor: '#F3FcF6',
    width: '85%',
    padding: '5px',
    marginLeft: '10%'
  },
  "& .termsEmptyContainer": {
    width: "100%",
    height: "70px",
    marginLeft: -32,
    borderLeft: "2px solid #35D261",
    marginTop: -80,
  },
  "& .DropDownData": {
    width: '30%',
    textAlign: 'right',
    marginRight: '15px'
  },
  "& .DropDownDataChild": {
    width: '30%',
    textAlign: 'right',
    marginRight: '15px'
  },
  "& .accordianData ": {
    backgroundColor: '#F3FcF6',
    width: '90%',
    padding: '5px',
    marginLeft: '5%',
    cursor:'pointer'
  },
  "& .accordianDataSub": {
    backgroundColor: '#F3FcF6',
    width: '81%',
    padding: '10px',
    marginLeft: '14%'
  },
  "& .draftRevision": {
    width:'95%',
    marginLeft:'2%',
    fontWeight:500,
    fontSize:'12px',
    lineHeight:'16px',
    backgroundColor:'#FCECED',
    color:'#DC4923',
    padding:'10px',
    fontFamily:'asap',
    marginTop:'-20px',
    borderBottomLeftRadius: '7px',
    borderBottomRightRadius: '7px'
  },
  "& .bookingRefence": {
    color: '#000',
    fontWeight: '800',
    fontFamily: 'Asap',
    fontSize: "12px"
  },
  "& .SmallIcon": {
    height: '20px'
  },
  "& .nominationLinktext": {
    fontWeight: 400,
    fontSize: "14px",
    color: "#1653DD",
    fontFamily: "Asap",
  },
  "& .stepLabelButton": {
    height: "48px",
    width: "100%",
    borderRadius: "23px",
    paddingRight: "113px",
    border: "none",
    paddingLeft: " 50px",
    color: "white",
    fontSize: "16px",
    backgroundColor: "#D58000",
    "@media (max-width: 768px)": {
      fontSize: "12px",
      padding: "15px !important"
    },
  },
  "& .stepLabelDisableButton": {
    height: "48px",
    width: "100%",
    borderRadius: "23px",
    paddingRight: "113px",
    border: "none",
    paddingLeft: " 50px",
    color: "white",
    fontSize: "16px",
    backgroundColor: 'grey',
    "@media (max-width: 500px)": {
      fontSize: "13px",
      padding: "15px 6px 8px 21px"
    },
  },


  "& .stepLabel": {
    fontSize: "16px",
    fontFamily: "Asap",
    fontWeight: 800,
    marginLeft: '5px',
    "@media (max-width: 768px)": {
      fontSize: "12px",
    },
  },
  "& .stepLabelTable": {
    fontSize: "16px",
    fontFamily: "Asap",
    fontWeight: 800,
    marginLeft: '15px',
    width: '80%',
    "@media (max-width: 768px)": {
      fontSize: "12px",
    },
  },
  "& .dropDowmimg":{
   position: "absolute",
   top:"35%",
   right:"3%"

  },
  "&  .mainBox": {
    marginLeft: '35px'
  },
  "& .stepLabelboxClick": {
    display: "flex",
    justifyContent: "space-between",

  },
  "& .stepLabelclick": {
    color: "white",

    backgroundColor: "#D58000"

  }
})

class ColorlibStepIcon extends Component<StepIconProps & { activeStepData: number, stepIndex: number, Booking: any }> {

  TimeFormate = (dateTimeString: any) => {

    return moment(dateTimeString, "DD MMM YYYY hh:mm A").format("hh:mm A")
  }

  render() {
    const { active, completed } = this.props;
   

    const icons: { [index: string]: React.ReactElement } = {
      "0": <Box style={webstyle.stepBox}>0</Box>,
      "1": <Box style={webstyle.stepBox}>1</Box>,
      "2": <Box style={webstyle.stepBox}>2</Box>,
      "3": <Box style={webstyle.stepBox}>3</Box>,
      "4": <Box style={webstyle.stepBox}>4</Box>,
      "5": <Box style={webstyle.stepBox}><DirectionsBoatIcon /></Box>,
      "6": <Box style={webstyle.stepBox}>5</Box>,
      "7": <Box style={webstyle.stepBox}>4</Box>,
      "8": <Box style={webstyle.stepBox}></Box>,
      "9": <Box style={webstyle.stepBox}></Box>,
      "10": <Box style={webstyle.stepBox}>5</Box>,
    };
    if ((this.props.active && this.props.icon === 3)) {
      return (<div style={{ display: 'flex' }}> <span style={{ marginLeft: -65, fontFamily: 'asap',width:75,fontSize:13 }}>{this.props?.Booking?.draft_bl_status? this.props.Booking?.verified_draft_bl_at:""}</span>
        <div
          style={{
            zIndex: 1,
            width: 50,
            height: 50,
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: this.props?.Booking?.draft_bl_status == false ? "#DC4923" : active ? '#EE8F00' : completed ? '#35D261' : '#ECECEC',
            color: '#fff',
            fontFamily: 'asap',
            fontWeight: 600,
            //marginLeft: '12px',
          }}
        >
          <img src={actionImage} />
        </div>
      </div>
      );
    }

    return (<>
      {this.props.stepIndex == 0 && <div style={{ display: 'flex' }}> <span style={{ marginLeft: -65, fontFamily: 'asap', width: 75, fontSize: 13 }}>{this.props.Booking?.nominated_at}</span>
        <div
          data-testid="stepper"
          style={{
            ...webstyle.CircleMainIcon,
            background: active ? '#D58000' : completed ? '#35D261' : '#ECECEC',
            color: active || completed ? '#fff' : '#B4CDEA',
          }}
        >
          {active ? "Now" : completed ? <><img src={CheckImage} alt="downIMg" />  </> : icons[String(this.props.icon)]}
        </div>
      </div>}

      {this.props.stepIndex == 1 && <div style={{ display: 'flex' }}> <span style={{ marginLeft: -65, fontFamily: 'asap', width: 75, fontSize: 13 }}>{this.props.Booking?.submitted_si_at}</span>
        <div
          data-testid="stepper"
          style={{
            ...webstyle.CircleMainIcon,
            background: this.props?.Booking?.submit_si_status ? '#35D261' : this.props?.Booking?.is_nominated ? '#D58000' : '#ECECEC',
            color: active || completed ? '#fff' : '#B4CDEA',
          }}
        >
          {active ? <img src={BoxIcon} alt="downIMg" /> : completed ? <><img src={CheckImage} alt="downIMg" />  </> : icons[String(this.props.icon)]}
        </div>
      </div>}


      {this.props.stepIndex == 2 && <div style={{ display: 'flex' }}> <span style={{ marginLeft: -65, fontFamily: 'asap', width: 75, fontSize: 13 }}>{this.props?.Booking?.draft_bl_status? this.props.Booking?.verified_draft_bl_at:""}</span>
        <div
          data-testid="stepper"
          style={{
            ...webstyle.CircleMainIcon,
            background: this.props?.Booking?.draft_bl_status == false ? "#DC4923" : active ? '#D58000' : completed ? '#35D261' : '#ECECEC',
            color: active || completed ? '#fff' : '#B4CDEA',
          }}
        >
          {this.props?.Booking?.draft_bl_status == false ? <><img src={actionImage} alt="downIMg" />  </> : active ? <img src={BoxIcon} alt="downIMg" /> : completed ? <><img src={CheckImage} alt="downIMg" />  </> : icons[String(this.props.icon)]}
        </div>
      </div>}

      {this.props.stepIndex == 3 && <div style={{ display: 'flex' }}> <span style={{ marginLeft: -65, fontFamily: 'asap', width: 75, fontSize: 13 }}>{this.props.Booking.payment_confirmed_at}</span>
        <div
          data-testid="stepper"
          style={{
            ...webstyle.CircleMainIcon,
            background: active ? '#D58000' : completed ? '#35D261' : '#ECECEC',
            color: active || completed ? '#fff' : '#B4CDEA',
          }}
        >
          {active ? <img src={BoxIcon} alt="downIMg" /> : completed ? <><img src={CheckImage} alt="downIMg" />  </> : icons[String(this.props.icon)]}
        </div>
      </div>}


      {this.props.stepIndex == 4 && <div style={{ display: 'flex' }}> <span style={{ marginLeft: -65, fontFamily: 'asap', width: 75, fontSize: 13 }}>{this.props?.Booking?.load_time && moment(this.props?.Booking?.load_time).parseZone().format("DD MMM YYYY hh:mm A")} </span>
        <div
          data-testid="stepper"
          style={{
            ...webstyle.CircleMainIcon,
            background: active ? '#D58000' : completed ? '#35D261' : '#ECECEC',
            color: active || completed ? '#fff' : '#B4CDEA',
          }}
        >
          {active ? <img src={BoxIcon} alt="downIMg" /> : completed ? <><img src={CheckImage} alt="downIMg" />  </> : icons[String(this.props.icon)]}
        </div>
      </div>}


      {this.props.stepIndex == 5 && <div style={{ display: 'flex' }}> {this.props?.Booking?.upload_final_bl_at ? (
      <span style={{ marginLeft: -65, fontFamily: 'asap', width: 75, fontSize: 13 }}>
        {moment(this.props.Booking.upload_final_bl_at).parseZone().format("DD MMM YYYY hh:mm A")}
      </span>
    ) : (
      <span style={{ marginLeft: -65, fontFamily: 'asap', width: 75, fontSize: 13 }}></span>
    )}
        <div
          data-testid="stepper"
          style={{
            ...webstyle.CircleMainIcon,
            background: active ? '#D58000' : completed ? '#35D261' : '#ECECEC',
            color: active || completed ? '#fff' : '#B4CDEA',
          }}
        >
          {active ? <img src={BoxIcon} alt="downIMg" /> : completed ? <><img src={CheckImage} alt="downIMg" />  </> : icons[String(this.props.icon)]}
        </div>
      </div>}

    </>
    );
  }
}



class ColorlibStepIcon2 extends Component<StepIconProps & { activeStepData: number, stepIndex: number, booking: any, label: any }> {



  TimeShowData = (dateTimeString: any) => {

    if (dateTimeString) {
      return moment(dateTimeString, "DD MMM YYYY hh:mm A").format("hh:mm A");
    } else {
      return "";
    }

  }

  render() {
    const { active, completed } = this.props;


    const icons: { [index: string]: React.ReactElement } = {
      "0": <Box style={webstyle.stepBox}><img src={BoxIcon} alt="downIMg" /></Box>,
      "1": <Box style={webstyle.stepBox}><img src={BoxIcon} alt="downIMg" /></Box>,
    };

    if ((this.props.icon === 0 && this.props.active) || (this.props.icon === 1 && this.props.active) || (this.props.active && this.props.icon === 2)) {
      return (
        <div
          style={{
            zIndex: 1,
            width: 50,
            height: 50,
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: '#D58000',
            color: '#fff',
            marginLeft: '12px',
            fontFamily: 'asap',
            fontWeight: 600
          }}
        >
          Now
        </div>
      );
    }

    return (<>

      {(this.props.label === 'Nominate Containers') &&
        <>
          {this.props.stepIndex == 0 &&

            <div style={{ display: 'flex' }}> <span style={{ marginLeft: -85, fontFamily: 'asap', width: 75, fontSize: 13 }}>{this.props?.booking?.attributes?.is_nominated}</span>
              <div
                data-testid="stepper"
                style={{
                  ...webstyle.CircleIcon,
                  background: this.props?.booking?.attributes?.is_nominated ? '#35D261' : '#D58000',
                  color: this.props?.booking?.attributes?.is_nominated ? '#fff' : '#B4CDEA'
                }}
              >
                {this.props?.booking?.attributes?.is_nominated ? <img src={CheckImage} alt="downIMg" /> : <img src={BoxIcon} alt="downIMg" />}
              </div></div>}
        </>
      }

      {(this.props.label === 'Submit Shipping Instructions') && <>
        {this.props.stepIndex == 0 &&
          <div style={{ display: 'flex' }}> <span style={{ marginLeft: -75, fontFamily: 'asap', width: 75, fontSize: 13 }}>{this.props?.booking?.attributes?.draft_file_status?this.props?.booking?.attributes?.upload_draft_bl_at:""}</span>
            <div
              data-testid="stepper"
              style={{
                ...webstyle.CircleIcon,
                background: this.props?.booking?.attributes?.draft_file_status ? '#35D261' : '#D58000',
                color: this.props?.booking?.attributes?.draft_file_status ? '#fff' : '#B4CDEA'
              }}
            >
              {this.props?.booking?.attributes?.draft_bl_status ? <img src={CheckImage} alt="downIMg" /> : <img src={BoxIcon} alt="downIMg" />}
            </div> </div>}
      </>
      }

      {(this.props.label === 'Confirm Draft B/L'&&this.props?.booking?.attributes?.draft_bl_status!==false) && <div style={{ display: 'flex' }}> <span style={{ marginLeft: -75, fontFamily: 'asap', width: 75, fontSize: 13 }}>{this.props?.booking?.attributes?.upload_invoice_at}</span>
        {this.props.stepIndex == 0 && <div
          data-testid="stepper"
          style={{
            ...webstyle.CircleIcon,
            background: this.props?.booking?.attributes?.invoice_status ? '#35D261' : '#D58000',
            color: this.props?.booking?.attributes?.invoice_status ? '#fff' : '#B4CDEA'
          }}
        >
          {this.props?.booking?.attributes?.invoice_status ? <img src={CheckImage} alt="downIMg" /> : <img src={BoxIcon} alt="downIMg" />}
        </div>}
      </div>
      }
    </>
    );
  }
}




class VeticalFile extends Component<VerticalFileProps,VerticalFileState> {
  

  subScribedMessages: string[];

  constructor(props: any) {
      super(props);
      this.receive = this.receive.bind(this);
      this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.SessionResponseMessage),
          getName(MessageEnum.PostDetailDataMessage),
      ];

      this.state = {
        activeStep: 0,
        selectValue: '',
        subcatgValue: '',
        catgValue:'',
        invoiceSubcatgValue: '',
        invoiceCatgValue:'',
        SIFormData: {},
        SIFormVisible: false,
        shipperAccordion: true,
        consigneeAccordion: true,
        shipper_container_details: [],
        shippingDataAvailable: false,
        activeBookingReference:false,
        activeInvoice:false,
        activePaymentConf:false,
        paymentDetailsShowStatus: false,
        finalBLShowStatus: false,
        invoiceSubCategoryActive: false,
      };
      runEngine.attachBuildingBlock(this as unknown as IBlock, this.subScribedMessages);
    }
  DropDownValue = (index:any) =>{

    if(this.state.selectValue==index){
      this.setState({selectValue:''})
    }

    if(this.state.selectValue!==index||this.state.selectValue==''){
      this.setState({selectValue:index})
    }
  }

  BookingRefrenceShow = ()=>{
    if(this.state.activeBookingReference){
      this.setState({activeBookingReference:false})
    }else{
      this.setState({activeBookingReference:true})
    }
    }

  PaymentDetailsShow = ()=>{
    if(this.state.paymentDetailsShowStatus){
      this.setState({paymentDetailsShowStatus:false})
    }else{
      this.setState({paymentDetailsShowStatus:true})
    }
    }

  FinalBLShow = ()=>{
    if(this.state.finalBLShowStatus){
      this.setState({finalBLShowStatus:false})
    }else{
      this.setState({finalBLShowStatus:true})
    }
    }

  SubCatgDropDown = (index2: any,index:any) => {
   
    if(this.state.catgValue==index&&this.state.subcatgValue==index2){
     
              this.setState({subcatgValue:'',catgValue:''})
            }
      
            if((this.state.catgValue!==index||this.state.subcatgValue!==index2)){
              
              this.setState({subcatgValue:index2,catgValue:index})
            }
  }
  invoiceSubCatgDropDown = (index2: any,index:any) => {
    this.setState({invoiceSubCategoryActive: !this.state.invoiceSubCategoryActive})
   
    if(this.state.invoiceCatgValue==index&&this.state.invoiceSubcatgValue==index2){
     
              this.setState({invoiceSubcatgValue:'',invoiceCatgValue:''})
            }
      
            if((this.state.invoiceCatgValue!==index||this.state.invoiceSubcatgValue!==index2)){
              
              this.setState({invoiceSubcatgValue:index2,invoiceCatgValue:index})
            }
  }

  Toggle=(index:any) =>{
   return this.state.selectValue==index? arrowUp1:Dropdown
  }

  Toggle2=(index:any,index2:any) =>{

    return (this.state.catgValue==index&&this.state.subcatgValue==index2)? arrowUp1:Dropdown
   }
  Toggle3=(index:any,index2:any) =>{

    return (this.state.invoiceCatgValue==index&&this.state.invoiceSubcatgValue==index2)? arrowUp1:Dropdown
   }


   async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );


    if (apiRequestCallId === this.GetSIFormDataApiCallId) {
        if (responseJson.shipper ) {

            this.setState({SIFormData: responseJson.shipper?.data, shippingDataAvailable: true})
        }
        if (responseJson.shipper_container_details) {
            this.setState({shipper_container_details: responseJson.shipper_container_details, shippingDataAvailable: true})
        }
    }
}

async componentDidMount() {
    this.getPrincipalOrganisation();

}

GetSIFormDataApiCallId: string = "";

getPrincipalOrganisation = async () => {
  this.setState({shippingDataAvailable: false})
    let authToken = await getStorageData("authToken");
    const header = {
        "Content-Type": "application/json",
        token: authToken,
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetSIFormDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_listdemandpickflow/bookings/get_shippment_instraction?id=${this.props.booking?.id}`
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
};

  render() {
    const { newState, booking } = this.props;

    let activeStep = this.props.newState;

    let length = 6;
    let ButtonTitle = 'Nominate'

    if(this.props.booking.attributes?.is_nominated){
         activeStep=1;
    }

    if(this.props.booking.attributes?.draft_file_status){
      activeStep = this.props.newState
    }


    const CheckValidation: any = () => {
      let active_button = false;
      if (activeStep == 0 && !booking.attributes?.co_authorizer) {
        active_button = true;
      }

      return active_button;
    }

    if(booking.attributes.draft_bl_status==false){
      length=3
     // ButtonTitle='Revise B/L'
  }

    const openSIViewForm = () => {
      this.setState({SIFormVisible: true})
     }
     const closeSIViewForm = () => {
      this.setState({SIFormVisible: false})
     }

    const handleVesselinformation = () => {
      return (
        <Grid container spacing={3}>
          <Grid lg={12} md={12} sm={12} xs={12} className="vesselInformationtext">
          Shipment Details
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className="vesselTextfieldgrid"
          >
            <Typography className="vesselTextfieldtext">
            POL
            </Typography>
            <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}><text style = {{fontFamily: 'Asap', color: '#000'}}>
            {this.state.SIFormData?.attributes?.pol}</text></div>
          </Grid>
          
          <Grid item lg={3} md={3} sm={12} xs={12} className="vesselTextfieldgrid" > <Typography className="vesselTextfieldtext">
              POD
            </Typography>
                <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}><text style = {{fontFamily: 'Asap', color: '#000'}}>
                {this.state.SIFormData?.attributes?.pod}
                  </text></div>
              </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12} className="vesselTextfieldgrid" >
            <Typography className="vesselTextfieldtext"> POD Terminal </Typography> 
            <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}><text style = {{fontFamily: 'Asap', color: '#000'}}>
            {this.state.SIFormData?.attributes?.pod_terminal}
              </text></div>
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className="vesselTextfieldgrid"
          >
            <Typography className="vesselTextfieldtext1">
              TEUs BOOKED </Typography>
              <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}><text style = {{fontFamily: 'Asap', color: '#000'}}>
              {this.props.booking?.attributes?.offer_quantity + this.props.booking?.attributes?.offer_sec_quantity * 2 }
                  </text></div>
            </Grid>
        </Grid>
      );
    }

    const handleShipperaccordion = () => {
      this.setState({
        shipperAccordion: !this.state.shipperAccordion,
      });
    };
    const handleConsigneeaccordion = () => {
      this.setState({
        consigneeAccordion: !this.state.consigneeAccordion,
      });
    };

    const  handleShippersummary = () =>  {
      return (
        <Grid container>
          <Grid lg={12} md={12} sm={12} xs={12} className="vesselInformationtext">
            Shipper Details
            {this.state.shipperAccordion ? (
              <span
                onClick={handleShipperaccordion}
                className="vesselViewlesstext"
              >
                <img className="vesselCloseimg" src={require('../../blocks/listdemandpickflow/assets/image_up.png')} alt="" />
                View Less
              </span>
            ) : (
              <span
                onClick={handleShipperaccordion}
                className="vesselViewlesstext"
              >
                <img className="vesselCloseimg" src={require('../../blocks/listdemandpickflow/assets/image_down.png')} alt="" />
                View More
              </span>
            )}
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="vesselTextfieldgrid"
          >
            <Typography className="vesselTextfieldtext">
            Shipper Company Name
            </Typography>
            <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}><text style = {{fontFamily: 'Asap', color: '#000'}}>
              {this.state.SIFormData?.attributes?.shipper_company_name}
              </text></div>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="vesselTextfieldgrid"
          >
            <Typography className="vesselTextfieldtext">
            Shipper Person-In-Charge
            </Typography>
            <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}><text style = {{fontFamily: 'Asap', color: '#000'}}>
            {this.state.SIFormData?.attributes?.shipper_in_charge}
              </text></div>
          </Grid>
        </Grid>
      );
    }

    const handleShipperData = () => {
      return (
        <Grid container>
          <Grid
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ textAlign: "start" }}
            className="vesselsubtext"
          >
            Shipper Address
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="vesselTextfieldgrid"
          >
            <Typography className="vesselTextfieldtext">
            Street Address
            </Typography>
  
            <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}><text style = {{fontFamily: 'Asap', color: '#000'}}>
            {this.state.SIFormData?.attributes?.shipper_address}
              </text></div>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="vesselTextfieldgrid"
          >
            <Typography className="vesselTextfieldtext">
            Street Address Line 2
            </Typography>
  
            <div style={{
              padding: '10px', 
              minHeight: '40px', 
              borderRadius: '10px', 
              backgroundColor: '#EEEEEE', 
              border: '1px solid #CBD5E1'
              }}>
                  <text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {this.state.SIFormData?.attributes?.shipper_street_address}
                  </text>
              </div>
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className="vesselTextfieldgrid"
          >
            <Typography className="vesselTextfieldtext">
            City
            </Typography>
  
            <div style={{
              padding: '10px', 
              minHeight: '40px', 
              borderRadius: '10px', 
              backgroundColor: '#EEEEEE', 
              border: '1px solid #CBD5E1'
              }}>
                  <text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {this.state.SIFormData?.attributes?.shipper_city}
                  </text>
              </div>
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className="vesselTextfieldgrid"
          >
            <Typography className="vesselTextfieldtext">
            State/Province 
            </Typography>
  
            <div style={{
              padding: '10px', 
              minHeight: '40px', 
              borderRadius: '10px', 
              backgroundColor: '#EEEEEE', 
              border: '1px solid #CBD5E1'
              }}>
                  <text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {this.state.SIFormData?.attributes?.shipper_state}
                  </text>
              </div>
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className="vesselTextfieldgrid"
          >
            <Typography className="vesselTextfieldtext">
            Postal/Zip Code
            </Typography>
  
            <div style={{
              padding: '10px', 
              minHeight: '40px', 
              borderRadius: '10px', 
              backgroundColor: '#EEEEEE', 
              border: '1px solid #CBD5E1'
              }}>
                  <text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {this.state.SIFormData?.attributes?.shipper_postal_code}
                  </text>
              </div>
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className="vesselTextfieldgrid"
          >
            <Typography className="vesselTextfieldtext">
            Country
            </Typography>
  
            <div style={{
              padding: '10px', 
              minHeight: '40px', 
              borderRadius: '10px', 
              backgroundColor: '#EEEEEE', 
              border: '1px solid #CBD5E1'
              }}>
                  <text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {this.state.SIFormData?.attributes?.shipper_country}
                  </text>
              </div>
          </Grid>
  
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="vesselTextfieldgrid"
          >
            <Typography className="vesselTextfieldtext">
            Shipper Email address
            </Typography>
  
            <div style={{
              padding: '10px', 
              minHeight: '40px', 
              borderRadius: '10px', 
              backgroundColor: '#EEEEEE', 
              border: '1px solid #CBD5E1'
              }}>
                  <text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {this.state.SIFormData?.attributes?.shipper_email}
                  </text>
              </div>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="vesselTextfieldgrid"
          >
            <Typography className="vesselTextfieldtext">
            Shipper Contact Number
            </Typography>
            <PhoneInput
              country={"sg"}
              placeholder={""}
              value={`${this.state.SIFormData?.attributes?.shipper_phone_number}`}
              disabled
              searchPlaceholder="Search for countries"
              data-test-id="ShipperContact"
              inputProps={{
                required: true,
                id: "ShipperContact",
                name: "ShipperContact",
              }}
              countryCodeEditable={false}
              enableSearch={true}
              jumpCursorToEnd={true}
              inputClass={`PhoneInputPackgeClass`}
            />
          </Grid>
        </Grid>
      );
    }

    const  ConsigneeAccordionRender = () => {
      return(
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className="submittingSecondgrid"
        >
          <Accordion
            className="submittingAccordiongrid"
            expanded={this.state.consigneeAccordion}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid container>
                <Grid
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="vesselInformationtext"
                >
                  Consignee Details
                  {this.state.consigneeAccordion ? (
                    <span
                      onClick={handleConsigneeaccordion}
                      className="vesselViewlesstext"
                    >
                      <img
                        className="vesselCloseimg"
                        src={require('../../blocks/listdemandpickflow/assets/image_up.png')}
                        alt=""
                      />
                      View Less
                    </span>
                  ) : (
                    <span
                      onClick={handleConsigneeaccordion}
                      className="vesselViewlesstext"
                    >
                      <img
                        className="vesselCloseimg"
                        src={require('../../blocks/listdemandpickflow/assets/image_down.png')}
                        alt=""
                      />
                      View More
                    </span>
                  )}
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="vesselTextfieldgrid"
                >
                  <Typography className="vesselTextfieldtext">
                  Consignee Name
                  </Typography>
                  <div style={{
              padding: '10px', 
              minHeight: '40px', 
              borderRadius: '10px', 
              backgroundColor: '#EEEEEE', 
              border: '1px solid #CBD5E1'
              }}>
                  <text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {this.state.SIFormData?.attributes?.cosnginee_name}
                  </text>
              </div>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="vesselTextfieldgrid"
                >
                  <Typography className="vesselTextfieldtext">
                  Consignee Person-In-Charge
                  </Typography>
                  <div style={{
              padding: '10px', 
              minHeight: '40px', 
              borderRadius: '10px', 
              backgroundColor: '#EEEEEE', 
              border: '1px solid #CBD5E1'
              }}>
                  <text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {this.state.SIFormData?.attributes?.consignee_in_charge}
                  </text>
              </div>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails className="accordionDetails">
              <Grid container>
                <Grid
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ textAlign: "start", marginLeft: "1%" }}
                  className="vesselsubtext"
                >
                  Consignee Address
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="vesselTextfieldgrid"
                >
                  <Typography className="vesselTextfieldtext">
                  Street Address
                  </Typography>
  
                  <div style={{
              padding: '10px', 
              minHeight: '40px', 
              borderRadius: '10px', 
              backgroundColor: '#EEEEEE', 
              border: '1px solid #CBD5E1'
              }}>
                  <text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {this.state.SIFormData?.attributes?.cosnginee_address}
                  </text>
              </div>
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="vesselTextfieldgrid"
                >
                  <Typography className="vesselTextfieldtext">
                  Street Address Line 2
                  </Typography>
                  <div style={{
              padding: '10px', 
              minHeight: '40px', 
              borderRadius: '10px', 
              backgroundColor: '#EEEEEE', 
              border: '1px solid #CBD5E1'
              }}>
                  <text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {this.state.SIFormData?.attributes?.cosnginee_street_address}
                  </text>
              </div>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={12}
                  xs={12}
                  className="vesselTextfieldgrid"
                >
                  <Typography className="vesselTextfieldtext">
                  City
                  </Typography>
                  <div style={{
              padding: '10px', 
              minHeight: '40px', 
              borderRadius: '10px', 
              backgroundColor: '#EEEEEE', 
              border: '1px solid #CBD5E1'
              }}>
                  <text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {this.state.SIFormData?.attributes?.cosnginee_city}
                  </text>
              </div>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={12}
                  xs={12}
                  className="vesselTextfieldgrid"
                >
                  <Typography className="vesselTextfieldtext">
                  State/Province{" "}
                  </Typography>
                  <div style={{
                  padding: '10px', 
                  minHeight: '40px', 
                  borderRadius: '10px', 
                  backgroundColor: '#EEEEEE', 
                  border: '1px solid #CBD5E1'
                  }}>
                      <text style = {{fontFamily: 'Asap', color: '#000'}}>
                      {this.state.SIFormData?.attributes?.cosnginee_state}
                      </text>
                  </div>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={12}
                  xs={12}
                  className="vesselTextfieldgrid"
                >
                  <Typography className="vesselTextfieldtext">
                  Postal/Zip Code
                  </Typography>
                  <div style={{
              padding: '10px', 
              minHeight: '40px', 
              borderRadius: '10px', 
              backgroundColor: '#EEEEEE', 
              border: '1px solid #CBD5E1'
              }}>
                  <text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {this.state.SIFormData?.attributes?.cosnginee_postal_code}
                  </text>
              </div>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={12}
                  xs={12}
                  className="vesselTextfieldgrid"
                >
                  <Typography className="vesselTextfieldtext">
                  Country
                  </Typography>
                  <div style={{
              padding: '10px', 
              minHeight: '40px', 
              borderRadius: '10px', 
              backgroundColor: '#EEEEEE', 
              border: '1px solid #CBD5E1'
              }}>
                  <text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {this.state.SIFormData?.attributes?.cosnginee_country}
                  </text>
              </div>
                </Grid>
  
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="vesselTextfieldgrid"
                >
                  <Typography className="vesselTextfieldtext">
                  Consignee Email address
                  </Typography>
                  <div style={{
              padding: '10px', 
              minHeight: '40px', 
              borderRadius: '10px', 
              backgroundColor: '#EEEEEE', 
              border: '1px solid #CBD5E1'
              }}>
                  <text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {this.state.SIFormData?.attributes?.cosnginee_email}
                  </text>
              </div>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="vesselTextfieldgrid"
                >
                  <Typography className="vesselTextfieldtext">
                  Consignee Contact Number
                  </Typography>
                  <PhoneInput
                    country={"sg"}
                    placeholder={""}
                    value={`${this.state.SIFormData?.attributes?.cosnginee_phone_number}`}
                    searchPlaceholder="Search for countries"
                    data-testid="ConsigneeContact"
                    disabled
                    inputProps={{
                      required: true,
                      id: "ConsigneeContact",
                      name: "ConsigneeContact",
                    }}
                    countryCodeEditable={false}
                    enableSearch={true}
                    jumpCursorToEnd={true}
                    inputClass={`PhoneInputPackgeClass`}
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="vesselTextfieldgrid"
                >
                  <Typography className="vesselTextfieldtext">
                    TAX ID {" "} <span style={{fontWeight: 200, fontSize: '14px', textTransform: 'capitalize', marginLeft: '5px'}}>(optional)</span>
                  <LightTooltip
                      title="Full legal name of your organisation as registered with the local authority."
                      placement="right"
                      className="tooltip"
                    >
                      <InfoOutlinedIcon className="infoIcon" style={{fontSize:"20px",paddingLeft:"5px"}} />
                    </LightTooltip>
                  </Typography>
                  <div style={{
              padding: '10px', 
              minHeight: '40px', 
              borderRadius: '10px', 
              backgroundColor: '#EEEEEE', 
              border: '1px solid #CBD5E1'
              }}>
                  <text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {this.state.SIFormData?.attributes?.cosnginee_tax_id}
                  </text>
              </div>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )
    }

      const handleContainerData = (
          index:number,
          DynamicValues:{
              container_number: string,
              shipper_container_type: string,
              shipper_container_size: string,
              shipper_container_codes: any,
              seal_number: string,
              hs_code: string,
              packing_type: string,
              package_count: string,
              gwt: string,
              cargo_description: string,
          }
        ) => {
          return (
            <Grid container>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="vesselTextfieldgrid"
              >
                <Typography className="vesselTextfieldtext">
                Container Number
                </Typography>
                <div style={{
              padding: '10px', 
              minHeight: '40px', 
              borderRadius: '10px', 
              backgroundColor: '#EEEEEE', 
              border: '1px solid #CBD5E1'
              }}>
                  <text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {DynamicValues?.container_number}
                  </text>
              </div>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="vesselTextfieldgrid"
              >
                <Typography className="vesselTextfieldtext">
                Container Status
                </Typography>
                <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}><text style = {{fontFamily: 'Asap', color: '#000'}}>
                {DynamicValues?.shipper_container_type}</text></div>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="vesselTextfieldgrid"
              >
                <Typography className="vesselTextfieldtext">
                Container Size 
                </Typography>
                <div style={{
              padding: '10px', 
              minHeight: '40px', 
              borderRadius: '10px', 
              backgroundColor: '#EEEEEE', 
              border: '1px solid #CBD5E1'
              }}>
                  <text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {DynamicValues?.shipper_container_size}
                  </text>
              </div>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="vesselTextfieldgrid"
              >
                <Typography className="vesselTextfieldtext">
                Container Type
                </Typography>
                <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}>
                  {DynamicValues?.shipper_container_codes?.map((item: any) => {
                      return(
                        <text style = {{fontFamily: 'Asap', color: '#000', padding: '7px 15px', backgroundColor: '#CDCDCD', borderRadius: '15px', marginRight: '10px'}}>{item}</text>
                      )})}</div>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="vesselTextfieldgrid"
              >
                <Typography className="vesselTextfieldtext">
                Seal Number
                </Typography>
                <div style={{
              padding: '10px', 
              minHeight: '40px', 
              borderRadius: '10px', 
              backgroundColor: '#EEEEEE', 
              border: '1px solid #CBD5E1'
              }}>
                  <text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {DynamicValues?.seal_number}
                  </text>
              </div>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="vesselTextfieldgrid"
              >
                <Typography className="vesselTextfieldtext">
                HS Code
                </Typography>
                <div style={{
              padding: '10px', 
              minHeight: '40px', 
              borderRadius: '10px', 
              backgroundColor: '#EEEEEE', 
              border: '1px solid #CBD5E1'
              }}>
                  <text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {DynamicValues?.hs_code}
                  </text>
              </div>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="vesselTextfieldgrid"
              >
                <Typography className="vesselTextfieldtext">
                Packing Type
                </Typography>
                <div style={{
              padding: '10px', 
              minHeight: '40px', 
              borderRadius: '10px', 
              backgroundColor: '#EEEEEE', 
              border: '1px solid #CBD5E1'
              }}>
                  <text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {DynamicValues?.packing_type}
                  </text>
              </div>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="vesselTextfieldgrid"
              >
                <Typography className="vesselTextfieldtext">
                Package Count
                </Typography>
                <div style={{
              padding: '10px', 
              minHeight: '40px', 
              borderRadius: '10px', 
              backgroundColor: '#EEEEEE', 
              border: '1px solid #CBD5E1'
              }}>
                  <text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {DynamicValues?.package_count}
                  </text>
              </div>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="vesselTextfieldgrid"
              >
                <Typography className="vesselTextfieldtext">
                GWT (kgs)
                </Typography>
                <div style={{
              padding: '10px', 
              minHeight: '40px', 
              borderRadius: '10px', 
              backgroundColor: '#EEEEEE', 
              border: '1px solid #CBD5E1'
              }}>
                  <text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {DynamicValues?.gwt}
                  </text>
              </div>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="vesselTextfieldgrid"
              >
                <Typography className="vesselTextfieldtext">
                Cargo Description
                </Typography>
                <div style={{
              padding: '10px', 
              minHeight: '40px', 
              borderRadius: '10px', 
              backgroundColor: '#EEEEEE', 
              border: '1px solid #CBD5E1'
              }}>
                  <text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {DynamicValues?.cargo_description}
                  </text>
              </div>
              </Grid>
            </Grid>
          );
        }


    const renderDynamicForm = () => {
      return(
        <>
          {this.state.shipper_container_details?.map((item: {
                      container_number: string,
                      shipper_container_type: string,
                      shipper_container_size: string,
                      shipper_container_codes: any,
                      seal_number: string,
                      hs_code: string,
                      packing_type: string,
                      package_count: string,
                      gwt: string,
                      cargo_description: string,
                    }, index: number) => {
                      return(
                          <Accordion
                            key={`${String.fromCharCode(94 + index)} this.state.containers`}
                            className="submittingAccordiongrid"
                            expanded={true}
                          >
                            <AccordionSummary
                              aria-controls={`panel${index}-content`}
                              id={`panel${index}-header`}
                            >
                                  <Grid container>
                                    <Grid
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      className="vesselInformationtext"
                                    >
                                      <Typography className="vesselInformationtext">
                                      Container Details
                                      </Typography>
                                    </Grid>
                                  </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                              {handleContainerData(index,item)}
                            </AccordionDetails>
                          </Accordion>
                        )
                    })}
        </>
      )
    }


     const renderViewSIForm = () => {
      return(
          <>
              <Grid item lg={12} md={12} sm={12} xs={12} className="submittinggrid" >
              {handleVesselinformation()}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className="submittingSecondgrid">
              <Accordion
                  expanded={this.state.shipperAccordion}
                  className="submittingAccordiongrid"
              >
                  <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  >
                  {handleShippersummary()}
                  </AccordionSummary>
                  <AccordionDetails className="accordionDetails">
                  {handleShipperData()}
                  </AccordionDetails>
              </Accordion>
              </Grid>
              {ConsigneeAccordionRender()}
              <Grid item lg={12} md={12} sm={12} xs={12} className="submittingSecondgrid">
              {renderDynamicForm()}
              </Grid>
              </>
      )
     }

    return (
      <>
      <Box style={{ marginLeft: '35px', position: "relative" }} >
        {
          this.props.bookingStatus && <>
          {(this.props.booking.attributes.co_authorizer === false && this.props.newState === 0) ?
            (<div style={{ display: 'flex' }}> <span style={{ marginLeft: -63, fontFamily: 'asap', width: 75, fontSize: 13 }}>{this.props?.booking?.attributes?.co_authorizer_at}</span>
              <div
                data-testid="stepper"
                style={{
                  ...webstyle.CircleIconfirst,
                  background: this.props?.booking?.attributes?.co_authorizer ? '#35D261' : '#D58000',
                  color: this.props?.booking?.attributes?.co_authorizer ? '#fff' : '#B4CDEA',
                  marginLeft:'25px'
                }}
              >
                {this.props?.booking?.attributes?.co_authorizer ? <img src={CheckImage} alt="downIMg" /> : <img src={BoxIcon} alt="downIMg" />}
              </div>
              <div style={{ borderRight: '2px solid #d58000', height: "36px", position: "absolute", top: "8%", left: " 9%" }}></div>
  
              <Box style={{ backgroundColor: booking.attributes.co_authorizer ? "#F3FCF6" : "#FDF2E0", color: booking.attributes.co_authorizer ? '#EE8F00' : '#D58000', border: booking.attributes.co_authorizer ? '1px solid #35D261' : "1px solid #FDF2E0", display: 'flex', width: "77%", marginLeft: "4%", borderRadius: "25px",paddingLeft:"2%" }} className={this.props.newState == 0 ? "stepLabelboxoneChild" : "stepLabelbox"} >
                <Typography className="stepLabel" style={{  padding: '12px', width: '60%',fontWeight: 800,fontFamily:'asap' }}>Awaiting For C.O Authorization</Typography>
              </Box>
  
            </div>
            )
            :
            <div style={{ display: 'flex' }}> <span style={{ marginLeft: -37, fontFamily: 'asap', width: 75, fontSize: 13 }}>{this.props?.booking?.attributes?.co_authorizer_at}</span>
              <div
                data-testid="stepper"
                style={{
                  ...webstyle.CircleIconfirst,
                  background: this.props?.booking?.attributes?.co_authorizer && this.props.bookingStatus ? '#35D261' : !this.props.bookingStatus ? "#ECECEC" : '#D58000',
                  color: this.props?.booking?.attributes?.co_authorizer && this.props.bookingStatus ? '#fff' : !this.props.bookingStatus ? "gray" : '#B4CDEA'
                }}
              >
                {this.props?.booking?.attributes?.co_authorizer && this.props.bookingStatus ? <img src={CheckImage} alt="downIMg" /> : !this.props.bookingStatus ? <text>0</text> : <img src={BoxIcon} alt="downIMg" />}
              </div>
              <div style={{ 
                borderRight: "2px solid #18B80A", 
                height: "60px", 
                position: "absolute", 
                top: "5%", 
                left: "56px" 
                }}/>
              <Box
    style={{
      backgroundColor: booking.attributes.co_authorizer || !this.props.bookingStatus ? "#F3FCF6" : "#FDF2E0",
      color: booking.attributes.co_authorizer && this.props.bookingStatus ? '#18B80A': !this.props.bookingStatus? '#1b1b1b' : '#D58000',
      border: booking.attributes.co_authorizer && this.props.bookingStatus ? '1px solid #35D261' : !this.props.bookingStatus? "1px solid #1b1b1b" : "1px solid #FDF2E0",
      display: 'flex',
      width: "73%",
      marginLeft: "8%",
      borderRadius: "25px",
      paddingLeft: "2%"
    }}
    className={this.props.newState == 0 ? "stepLabelboxoneChild" : "stepLabelbox"}
  >
    <Typography
      className="stepLabel"
      style={{
        padding: '12px',
        width: '100%',
        fontWeight: 800,
        fontFamily: 'asap'
      }}
    >
      Container Operator Authorized
    </Typography>
  </Box>
  
            </div>
  
          }
          
          </>
        }
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          connector={ activeStep == 1 && booking.attributes.submit_si_status ? <ColorlibConnector1 style={{ padding: "0px", height: 15 }} /> :  <ColorlibConnector style={{ padding: "0px", height: 15 }} />}
        >


          {steps.slice(0, length).map((step, index) => (
            <Step key={step.label}>

              <StepLabel StepIconComponent={props => <ColorlibStepIcon {...props} activeStepData={activeStep} stepIndex={index} Booking={booking.attributes} />} style={{ alignItems: 'normal' }} icon={index + 1}>

                <BoxModel >
                {index==0&&<Box style={{ backgroundColor: activeStep === index ? '#FDF2E0' : activeStep > index ? '#35D261' : '#ECECEC', color: activeStep === index ? '#D58000' : 'black', border: activeStep === index ? '1px solid #FDF2E0' : activeStep > index ? "1px solid #18B80A" : "1px solid #000", display: 'flex' }} className={index == 0 ? "stepLabelboxone" : "stepLabelbox"} >
                    <Typography className="stepLabelTable" >{step.label}</Typography>
                    {(activeStep === 0 && index === 0) ? (<div><button className={CheckValidation() ? "stepLabelDisableButton" : "stepLabelButton"} disabled={CheckValidation()} style={{ padding: '15px 12px 8px 34px', display: 'flex', backgroundColor: length === 3 ? '#DC4923' : CheckValidation() ? '#ffe0b3' : '#D58000' }} onClick={this.props.handleNominationModal}><span style={{textTransform:'uppercase'}}>{ButtonTitle} </span> <span><img src={actionLeft} /></span></button> </div>) : (activeStep > index) && (index > 0 && index < 4) ? (<div className="DropDownData" ><img onClick={() => this.DropDownValue(index)} src={this.Toggle(index)} /></div>) : null}
                  </Box>}
                  {index === 1 && (
                    <>
                      <Box
                        style={{
                          backgroundColor: (activeStep === index && !booking.attributes.submit_si_status)
                            ? '#FDF2E0'
                            : (activeStep === index && booking.attributes.submit_si_status)
                            ? '#35D261'
                            : activeStep > index
                            ? '#35D261'
                            : '#ECECEC',
                          color: (activeStep === index && !booking.attributes.submit_si_status)
                            ? '#EE8F00'
                            : 'black',
                          border: activeStep === index
                            ? '1px solid #FDF2E0'
                            : activeStep > index
                            ? '1px solid #18B80A'
                            : '1px solid #000',
                          display: 'flex'
                        }}
                        className="stepLabelbox"
                      >
                        <Typography className="stepLabelTable">{step.label}</Typography>
                        {(activeStep === 1 && index === 1 && !booking.attributes.submit_si_status) ? (
                          <div>
                          </div>
                        ) : ( booking.attributes.submit_si_status &&
                          <div className="DropDownData">
                            <img onClick={() => this.BookingRefrenceShow()} src={this.state.activeBookingReference ? arrowUp1 : Dropdown} />
                          </div>
                         )}
                      </Box>

                      {this.state.activeBookingReference && (
                        <div className="accordianData" onClick={() => openSIViewForm()}>
                          <span className="nominationLinktext">View Shipping Instruction Form</span>
                        </div>
                      )}
                    </>
                  )}


                  {index==2&&<Box style={{position: 'relative', backgroundColor: (length === 3 && index == 2) ? '#fcd7d7' : activeStep === index ? '#FDF2E0' : activeStep > index ? '#35D261' : '#ECECEC', color: (length === 3 && index == 2) ? '#DC4923' : activeStep === index ? '#D58000' : 'black', border: (length === 3 && index == 2) ? '1px solid #FCECED' : activeStep === index ? '1px solid #FDF2E0' : activeStep > index ? "1px solid #18B80A" : "1px solid #000", display: 'flex' }} className={"stepLabelbox"} >
                    <Typography className="stepLabelTable" >{step.label}</Typography>
                  </Box>}


                  {index==3&&
                  (<>
                  <Box style={{ backgroundColor: activeStep === index ? '#FDF2E0' : activeStep > index ? '#35D261' : '#ECECEC', color:  activeStep === index ? '#D58000' : 'black', border:  activeStep === index ? '1px solid #FDF2E0' : activeStep > index ? "1px solid #18B80A" : "1px solid #000", display: 'flex' }} className={"stepLabelbox"} >
                    <Typography className="stepLabelTable" >{step.label}</Typography>
                    {(activeStep === 3 && index === 3 && !booking.attributes.payment_status) ? (
                          <div>
                          </div>
                        ) : (activeStep > index) && (index > 0 && index < 4) ? (
                          <div className="DropDownData">
                            <img onClick={() => this.PaymentDetailsShow()} src={this.state.paymentDetailsShowStatus ? arrowUp1 : Dropdown} />
                          </div>
                        ) : null}
                  </Box>
                  {this.state.paymentDetailsShowStatus && (
                    <div className="accordianData" onClick={() => window.open(booking?.attributes?.payment_confirmation_file, '_blank')}>
                      <img src={pdf} alt="upload" className="SmallIcon" /> 
                      <span className="nominationLinktext">{booking?.attributes?.payment_file}</span>
                    </div>
                  )}
                  </>)
                  }

                  {index==4&&<Box style={{ backgroundColor:  activeStep === index ? '#FDF2E0' : activeStep > index ? '#35D261' : '#ECECEC', color:  activeStep === index ? '#D58000' : 'black', border:  activeStep === index ? '1px solid #FDF2E0' : activeStep > index ? "1px solid #18B80A" : "1px solid #000", display: 'flex' }} className={"stepLabelbox"} >
                    <Typography className="stepLabelTable" >{step.label}</Typography>
                  </Box>}

                  {index==5&&
                  <>
                  <Box style={{ backgroundColor:  activeStep === index ? '#FDF2E0' : activeStep > index ? '#35D261' : '#ECECEC', color:  activeStep === index ? '#D58000' : 'black', border: activeStep === index ? '1px solid #FDF2E0' : activeStep > index ? "1px solid #18B80A" : "1px solid #000", display: 'flex' }} className={"stepLabelbox"} >
                    <Typography className="stepLabelTable" >{booking?.attributes?.final_bl_status ? "Download Final B/L" : step.label}</Typography>
                    {(activeStep === 5 && index === 5 && !booking.attributes.final_bl_status) ? (
                          <div>
                          </div>
                        ) : booking.attributes.final_bl_status ? (
                          <div className="DropDownData">
                            <img onClick={() => this.FinalBLShow()} src={this.state.finalBLShowStatus ? arrowUp1 : Dropdown} />
                          </div>
                        ) : null}
                  </Box>
                  {this.state.finalBLShowStatus && (
                    <div className="accordianData" onClick={() => window.open(booking?.attributes?.final_bl, '_blank')}>
                      <img src={pdf} alt="upload" className="SmallIcon" /> 
                      <span className="nominationLinktext">{booking?.attributes?.final_bl_file_name}</span>
                    </div>
                  )}
                  </>
                  }

                  
 
                  {(parseInt(this.state.selectValue) == index) && (index == 1) && (<div className="accordianData"  onClick={()=> openSIViewForm()} >
                  <span className="nominationLinktext" >View Shipping Instruction Form</span>
                  </div>)}

                  {(parseInt(this.state.selectValue) == index) && (index == 2) && (<div className="accordianData" onClick={() => window.open(booking?.attributes?.draft_bl[booking?.attributes?.draft_bl?.length - 1], '_blank')}>
                    <img src={pdf} alt="upload" className="SmallIcon" /> <span className="nominationLinktext">{booking?.attributes?.draft_bl_file_name[booking?.attributes?.draft_bl_file_name?.length - 1]}</span>
                  </div>)}

                  {(parseInt(this.state.selectValue) === index) && (index === 3) && (
                    <div className="accordianData" onClick={() => window.open(booking?.attributes?.payment_confirmation_file, '_blank')}>
                      <img src={pdf} alt="upload" className="SmallIcon" /> 
                      <span className="nominationLinktext">{booking?.attributes?.payment_file}</span>
                    </div>
                  )}

                  {(index === 2 && booking?.attributes?.draft_bl_status === false) && (
                    <Typography className="draftRevision">
                      <br />
                      Request Revision ({moment(booking?.attributes?.verified_draft_bl_at).format('D MMM YY').toUpperCase()}) 
                      <br />
                      {booking?.attributes?.request_revision}.
                    </Typography>
                  )}
                  
                  {(activeStep >= index) && (<div>
                    {step?.container && step?.container.map((item, index2) => (
                      <div >
                        <div style={{ 
                          height: (index == 2 && booking.attributes?.draft_bl_status === false) 
                                  ? "0px" 
                                  : booking.attributes.booking_references_status 
                                  ? "55px" 
                                  : "30px", 
                          marginLeft: -36, 
                          marginTop: booking.attributes.booking_references_status ? -30: -5, 
                          borderLeft: activeStep === index 
                                  ? '2px solid #D58000' 
                                  : activeStep > index 
                                  ? '2px solid #35D261' 
                                  : '2px solid #ECECEC' 
                                  }} />
                        <Step key={item} style={{ marginLeft: -60, marginTop: -5 }} className="stepsContainer">
                          <StepLabel StepIconComponent={props => <ColorlibStepIcon2 {...props} activeStepData={activeStep} stepIndex={index2} booking={booking} label={step.label} style={{ minHeight: 80 }} />} icon={index + 1} >
                            {step.label == 'Nominate Containers' && (
                              <>
                                {index2 == 0 && (
                                  <Box style={{ backgroundColor: booking.attributes.booking_references_status ? "#F3FCF6" : "#FDF2E0", color: booking.attributes.booking_references_status ? '#18B80A' : '#D58000', border: booking.attributes.booking_references_status ? '1px solid #35D261' : "1px solid #FDF2E0", display: 'flex' }} className={"stepLabelboxoneChild"} >
                                    <Typography className="stepLabel" style={{ color: booking.attributes.booking_references_status ? '#35D261' : '#D58000', padding: '12px', width: '60%' }}>{item}</Typography>
                                  </Box>)}

                              </>)}
 
                            {(step.label == 'Submit Shipping Instructions') &&
                            <>
                              <Box style={{ 
                                backgroundColor: booking.attributes.draft_file_status ? "#F3FCF6" : "#FDF2E0", 
                                color: booking.attributes.draft_file_status ? '#18B80A' : '#D58000', 
                                border: booking.attributes.draft_file_status ? '1px solid #35D261' : "1px solid #FDF2E0", 
                                display: 'flex' }} 
                                className={"stepLabelboxoneChild"} >
                                <Typography className="stepLabel" style={{ padding: '4px', width: '60%',fontWeight:800 }}>
                                  {booking.attributes.draft_file_status ? "Draft B/L Uploaded by Seller":item}
                                  <span>{booking.attributes.draft_file_status ?<img className="dropDowmimg" onClick={() => this.SubCatgDropDown(index2,index)}   src={this.Toggle2(index,index2)}/>:""}</span>
                                </Typography>
                                {(activeStep > index) && (index2 == 1) ? (<div className="DropDownDataChild" style={{ width: '40%' }} ><img  src={Dropdown} /></div>) : null}
                              </Box>
                              {(parseInt(this.state.subcatgValue)==index2&&index==parseInt(this.state.catgValue))&&     
                                <>
                                <Typography className="invoiceStatus" onClick={()=>window.open(booking?.attributes?.draft_bl[booking?.attributes?.draft_bl?.length - 1],'_blank')}> <img src={pdf} alt="upload" className="SmallIcon" /> <span className="nominationLinktext">{booking.attributes?.draft_bl_file_name[booking.attributes?.draft_bl_file_name?.length - 1]}</span></Typography>
                                </>}
                            </>
                            }
                            {(step.label == 'Confirm Draft B/L'&&this.props?.booking?.attributes?.draft_bl_status!==false) &&
                            <>
                              <Box style={{
                                backgroundColor: booking.attributes.invoice_status ? "#F3FCF6" : "#FDF2E0", 
                                color: booking.attributes.invoice_status ? '#18B80A' : '#D58000', 
                                border: booking.attributes.invoice_status ? '1px solid #35D261' : "1px solid #FDF2E0", 
                                display: 'flex' }} 
                                className={"stepLabelboxoneChild"} >
                                <Typography className="stepLabel" style={{  padding: '12px', width: '60%',fontWeight:800 }}>
                                  {booking.attributes.invoice_status ? "Invoice Uploaded by Seller" : item}
                                  <span>{booking.attributes.invoice_status ?<img className="dropDowmimg" onClick={() => this.invoiceSubCatgDropDown(index2,index)}   src={this.Toggle3(index,index2)}/>:""}</span></Typography>
                                </Box>
                                {(parseInt(this.state.invoiceSubcatgValue)==index2&&index==parseInt(this.state.invoiceCatgValue))&&     
                                <>
                                <Typography className="invoiceStatus" onClick={()=>window.open(booking?.attributes?.upload_invoice,'_blank')}> <img src={pdf} alt="upload" className="SmallIcon" /> <span className="nominationLinktext">{booking.attributes?.upload_invoice_file_name}</span></Typography>
                                <Typography className="invoiceTerms">{booking?.attributes?.term}</Typography>
                                <div className="termsEmptyContainer" style={booking?.attributes?.payment_status ? {borderLeft: "2px solid #35D261"} : {borderLeft: "2px solid #D58000"}} />
                                </>}
                              </>
                            }

                            {step.label == 'Submit Payment Details' &&
                              <Box style={{ backgroundColor: booking.attributes.payment_status ? "#F3FCF6" : "#FDF2E0", color: booking.attributes.payment_status ? '#18B80A' : '#D58000', border: booking.attributes.payment_status ? '1px solid #35D261' : "1px solid #FDF2E0", display: 'flex' }} className={"stepLabelboxoneChild"} >
                                <Typography className="stepLabel" style={{ color: booking.attributes.payment_status ? '#35D261' : '#D58000', padding: '12px' }}>{item}</Typography>
                              </Box>}
                          </StepLabel></Step>
                      </div>))}
                  </div>)}
                </BoxModel>

              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box >
        </Box>
      </Box>

{this.state.SIFormVisible &&
  <StyledDialogModal open={this.state.SIFormVisible}>
      <Grid
          item
          className="nominationClose"
          lg={12}
          md={12}
          sm={12}
          xs={12}
      >
          <img
          className="nominationCloseimg"
          src={require('../../blocks/listdemandpickflow/assets/AddcloseImg.png')}
          alt=""
          data-testid="closeSubmittingmodal"
          onClick={() => {
              closeSIViewForm()
          }}
          />
      </Grid>
      <Grid container>
          <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className="submittingFirstgrid"
          >
          View Shipping Instructions
          </Grid>
          {renderViewSIForm()}
      </Grid>
      
</StyledDialogModal>
}

</>
    );



  }

  
}

const webstyle = {
  stepBox: {
    backgroundColor: "#ECECEC",
    zIndex: 1,
    width: 50,
    color: "#707070",
    height: 50,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: 'asap',
    fontWeight: 600
  },
  CircleIcon: {
    zIndex: 1,
    width: 40,
    height: 40,
    fontFamily: 'asap',
    fontWeight: 600,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 8,
  },
  CircleIconfirst: {
    zIndex: 1,
    width: 40,
    height: 40,
    fontFamily: 'asap',
    fontWeight: 600,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  //  marginLeft: "7%",
  },
  CircleMainIcon: {
    zIndex: 1,
    width: 50,
    height: 50,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: 'asap',
    fontWeight: 600,
  //  marginLeft: '12px',
  }
}


export default withStyles(styles)(VeticalFile);