// Customizable Area Start
import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { TextField, TextFieldProps, Select, MenuItem, MenuItemProps } from "@material-ui/core";


interface PropsTyps {
    type?: string,
    textcolor?: string,
    fontweight?: string
    space?: string,
    variant?: string,
    TextSize?: string,
    filter?: boolean,
    LineHeight?: string,
    Upercase?: boolean,
    width?: string,
    fontStyle?:string,
    border?:string,
    padding?:boolean,
    borderRadius?:boolean,
    marginLeft?:string,
    marginRight?:string,
    marginBottom?:string
}

const ProjectText = styled(Typography)((props: PropsTyps) => (
    {
        ...(props.type === "error" && {
            color: "red",
        }),
        ...(typeof props.fontweight === "string" && {
            fontWeight: Number(props.fontweight)
        }),
        ...(typeof props.fontStyle === "string" && {
            fontStyle: props.fontStyle
        }),
        ...(typeof props.TextSize === "string" && {
            fontSize: `${props.TextSize}`
        }),
        ...(typeof props.textcolor === "string" ? {
            color: props.textcolor,
        } : {
            ...(props.type === "error" ? {
                color: "red",
            } : {
                color: "black"
            }),
        }),
        ...(typeof props.LineHeight === "string" && {
            lineHeight: props.LineHeight,
        }),
        ...(props.space === "both" && {
            marginBottom: "10px",
            marginTop: "10px"
        }),
        ...(props.filter && {
            filter: "drop-shadow(0px 0px 1px grey)"
        }),
        ...(props.Upercase && {
            textTransform: "uppercase"
        }),
        ...(props.width && {
            width: props.width
        }),
        fontFamily: "Asap, sans-serif",
        ...(typeof props.border === "string" && {
            border: `${props.border}`
        }),
        ...(props.padding && {
            paddingTop: "1px",
            paddingBottom: "1px",
            paddingLeft: "15px",
            paddingRight: "15px",
        }),
        ...(props.borderRadius && {
            borderRadius: "4px",
        }),
        ...(typeof props.marginLeft === "string" && {
            marginLeft: `${props.marginLeft}`
        }),
        ...(typeof props.marginRight === "string" && {
            marginRight: `${props.marginRight}`
        }),
        ...(typeof props.marginBottom === "string" && {
            marginBottom: `${props.marginBottom}`
        })
    }))
    interface ProjectTextFieldProps {
        BgColor?: string
    }
    
    const ProjectTextField = styled(Select)((props: ProjectTextFieldProps) => ({
        ...(typeof props.BgColor === "string" && {
            backgroundColor: props.BgColor
        }),
        ...(typeof props.BgColor === "undefined" && {
            backgroundColor: "#fff"
        }),
        "& option": {
            padding: "10px"
        },
        position: "relative",
        "& .MuiOutlinedInput-input": {
            padding: "12px"
        },
        "& .MuiSelect-root": {
            fontSize: "26px"
        },
        "& .MuiSelect-selectMenu": {
            minHeight: "unset"
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CBD5E1"
        }
    }))
    
    interface CustomMenuItemProps extends MenuItemProps {
        borderColor?: string
    }
    
    const OptionForSelect = styled(MenuItem)((props: CustomMenuItemProps) => ({
        fontSize: "22px",
        borderLeft: `4px solid ${props.borderColor}`,
        marginTop: "5px",
        marginBottom: "5px",
        '& .Mui-focused': {
            outline: "none"
        },
    }))



export default ProjectText
export { ProjectText, ProjectTextField, OptionForSelect}

// Customizable Area End