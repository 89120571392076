// Customizable Area Start
import React, { Component } from "react";
const AlphaLogo = require("./logo.png");
import { withRouter } from "react-router-dom";
const backgroundImg = require("./image_bg.jpg");
import { Box } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { RouteComponentProps } from "react-router";
import { getStorageData } from "framework/src/Utilities";

interface PermanentDrawerLeftProps extends RouteComponentProps {
  children?: React.ReactNode;
}

class AppBackground extends Component<PermanentDrawerLeftProps> {
  constructor(props: PermanentDrawerLeftProps) {
    super(props);
  }
  render() {
    const LogoNavigate = async () => {
      this.props.history.push({
        pathname: "/LandingPage"
      })
    }
    return (
      <BackgroundStyle>
        <Background>
          <img src={AlphaLogo} style={{cursor: 'pointer'}} onClick={LogoNavigate} alt="logo" className="logo" />
          {this.props.children}
        </Background>
      </BackgroundStyle>
    );
  }
}
export default withRouter(AppBackground);
const Background = styled(Box)({
  display: "flex",
  alignContent: "center",
  justifyContent: "center",
  height: "100vh !important",
  backgroundImage: `url(${backgroundImg})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center", // Center the background image
  width: "100%",
  objectFit: "cover",
  position: "relative",
  "& .GridContainerStyle": {
    height: "100%",
  }
});

const BackgroundStyle = styled("div")({
  "& .logo": {
    position: "absolute",
    top: "30px",
    left: "30px",
  },
});

// Customizable Area End