// Customizable Area Start
import React from "react";
import { styled } from "@material-ui/styles";
import { Box, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import ProjectText from "../../../components/src/ProjectText";
import Accordion from "@material-ui/core/Accordion";
import Divider from "@material-ui/core/Divider";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Navbar from "../../../components/src/NavBar.web";

import FaqController from "./FaqController.web";
const manypart = require("../assets/img.png");
const SlotXpertLogo = require("../../../components/src/logo.png");

export default class Faq extends FaqController {
  render() {
    return (
      <FaqStyle data-test-id="Faq">
        <Navbar AppBarTransparent={false} LandingPageNavBar={true} />
        <Grid container>
          <Grid item xs={12} className="MainBanner">
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography className="First">
                  Frequently asked questions.
                </Typography>
              </Grid>
              <Grid item>
                <img className="FaqImage" src={manypart} alt="manypart" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="SecondBanner" container>
            <Grid item xs={12} md={3} className="Sidebar">
              <Typography className="F1">TABLE OF CONTENTS</Typography>
              <Tabs
                orientation={this.state.orientation as any}
                variant="scrollable"
                aria-label="Vertical tabs example"
                value={this.state.selectedItem}
                className="tabs"
                onChange={(event, newValue) => this.handleClick(newValue)}
                indicatorColor={
                  this.state.orientation === "vertical" ? undefined : "primary"
                }
                TabIndicatorProps={{
                  style:
                    this.state.orientation === "vertical"
                      ? tabIndicator
                      : undefined
                }}
              >
                {this.state.headskeys.map((item: string, index: number) => (
                  <Tab
                    className="List"
                    value={item}
                    tabIndex={index}
                    key={index}
                    label={item.replace(/_/g, " ")}
                  />
                ))}
              </Tabs>
            </Grid>
            <Grid
              item
              xs={12}
              md={9}
              className="FrequentlyAskedQuestionsConatiner"
            >
              <Grid container className="FAQheading">
                <Grid item xs={12}>
                  <ProjectText
                    variant="h4"
                    fontweight="700"
                    TextSize="56px"
                    className="About"
                  >
                    {this.state.selectedItem.replace(/_/g, " ")}
                  </ProjectText>
                </Grid>
              </Grid>
              <Grid container className="AboutContainer" spacing={2}>
                {this.state.FilterArray.map(
                  (
                    item: { question: string; response: string },
                    index: number
                  ) => (
                    <Grid item xs={12} key={index}>
                      <Accordion
                        className="custom-accordion"
                        expanded={this.state.OpenClose === index}
                        onClick={() => {
                          this.OpenCloseOnChange(index);
                        }}
                      >
                        <AccordionSummary
                          className="custom-accordion-summary"
                          expandIcon={
                            <ExpandMoreIcon
                              fontSize="large"
                              style={{ color: "black" }}
                            />
                          }
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <ProjectText
                            variant="h6"
                            fontweight="600"
                            TextSize="20px"
                            className="FaqQustion"
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.question
                              }}
                            />
                          </ProjectText>
                        </AccordionSummary>
                        <ProjectText
                          variant="h6"
                          fontweight="400"
                          TextSize="20px"
                          className="FaqAnswer"
                        >
                          <div
                            dangerouslySetInnerHTML={{ __html: item.response }}
                          />
                        </ProjectText>
                      </Accordion>
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box className="FooterContainer">
          <Grid
            container
            spacing={4}
            justifyContent="space-between"
            alignItems="stretch"
          >
            <Grid item xs={6}>
              <img src={SlotXpertLogo} alt="logo" className="logo" />
              <Box>
                <ProjectText
                  variant="h6"
                  textcolor="white"
                  TextSize="24px"
                  fontweight="600"
                  className="Rightside"
                >
                  The Perfect Ocean Freight Matchmaker.
                </ProjectText>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="LeftSide">
                <Box>
                  <Box>
                    <ProjectText variant="h6" textcolor="white" TextSize="20px">
                      Office Address
                    </ProjectText>
                  </Box>
                  <Box className="SpaceBetween">
                    <ProjectText variant="h6" textcolor="white" TextSize="20px">
                      PSA Corporation Ltd.
                    </ProjectText>
                    <ProjectText variant="h6" textcolor="white" TextSize="20px">
                      3 Harbour Drive, Singapore (117404)
                    </ProjectText>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider className="Divider" />
            </Grid>
            <Grid className="Bottom">
              <ProjectText
                variant="h6"
                textcolor="white"
                fontweight="400"
                TextSize="20px"
                className="footer"
              >
                {" "}
                All rights reserved.
              </ProjectText>
            </Grid>
          </Grid>
        </Box>
      </FaqStyle>
    );
  }
}

const FaqStyle = styled(Box)({
  "& .MuiAccordionSummary-root": {
    padding: "0px",
    backgroundColor: "#f9f9f9 !important"
  },
  "& .MainBanner": {
    marginTop: "-8px",
    display: "flex",
    width: "100%",
    alignItems: "center",
    padding: "12px 70px",
    background: "#002987",
    color: "#FFFFFF",
    "& .First": {
      color: "#FFF",
      fontFamily: "Asap",
      fontSize: "46px",
      fontStyle: "normal",
      fontWeight: "bold",
      lineHeight: "64px",
      "@media (max-width: 960px)": {
        fontSize: "34px"
      },
      "@media (max-width: 705px)": {
        fontSize: "28px"
      },
      "@media (max-width: 600px)": {
        fontSize: "22px"
      },
      "@media (max-width: 500px)": {
        fontSize: "15px"
      }
    },
    "& .FaqImage": {
      "@media (max-width: 960px)": {
        width: "125px",
        padding: "15px"
      },
      "@media (max-width: 705px)": {
        width: "80px",
        padding: "15px"
      },
      "@media (max-width: 600px)": {
        width: "50px",
        padding: "10px"
      }
    },
    "& .Second": {
      display: "flex",
      width: "200px",
      height: "200px",
      justifyContent: "center",
      alignItems: "center",
      flexShrink: 0,
      marginTop: "10px"
    }
  },
  "& .MuiTab-wrapper": {
    alignItems: "start",
    justifyContent: "start"
  },
  "& .SecondBanner": {
    padding: "70px 70px",
    width: "100%",
    display: "flex",
    "@media (max-width: 960px)": {
      padding: "70px 40px"
    },
    "& .F1": {
      fontFamily: "Asap",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "32px",
      color: "#ACACAC",
      marginBottom: "10px",
      "@media (max-width: 960px)": {
        fontSize: "18px",
        display: "grid",
        justifyContent: "center"
      },
      "@media (max-width: 600px)": {
        fontSize: "16px"
      },
      "@media (max-width: 500px)": {
        fontSize: "14px",
        display: "grid",
        justifyContent: "center"
      }
    },
    "& .List": {
      color: "#363636",
      fontFamily: "Asap",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: "bold",
      lineHeight: "24px",
      whiteSpace: "nowrap",
      textTransform: "capitalize",
      marginLeft: "12px",
      "@media (max-width: 1080px)": {
        fontSize: "17px"
      },
      "@media (max-width: 768px)": {
        fontSize: "15px"
      },
      "@media (max-width: 500px)": {
        fontSize: "13px"
      }
    },
    "& .Child2": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "4px",
      justifyContent: "space-around"
    }
  },
  "& .ExportConatiner": {
    "& img": {
      height: "350px",
      width: "100%"
    }
  },
  "& .FooterContainer": {
    backgroundColor: "black",
    padding: "70px 56px",
    "@media (max-width: 960px)": {
      padding: "70px 30px"
    },
    "& .LeftSide": {
      display: "flex",
      justifyContent: "flex-end"
    },
    "& .Rightside": {
      marginTop: "50px"
    },
    "& .SpaceBetween": {
      marginTop: "20px"
    },
    "& .Divider": {
      backgroundColor: "#4C4C4C"
    }
  },
  "& .FrequentlyAskedQuestionsConatiner": {
    borderLeft: "1px solid #dadada",
    paddingLeft: "55px",
    "@media (max-width: 960px)": {
      borderLeft: "none",
      paddingLeft: "0"
    },
    "& .ViewMoreContainer": {
      display: "flex",
      justifyContent: "center"
    },
    "& .About": {
      color: "#1E1E1E",
      fontFamily: "Asap",
      fontStyle: "normal",
      fontSize: "45px",
      lineHeight: "64px",
      "@media (max-width: 1080px)": {
        fontSize: "32px"
      },
      "@media (max-width: 960px)": {
        fontSize: "30px",
        marginTop: "20px"
      },
      "@media (max-width: 600px)": {
        fontSize: "25px"
      }
    },
    "& .FaqQustion": {
      "@media (max-width: 1080px)": {
        fontSize: "18px"
      },
      "@media (max-width: 600px)": {
        fontSize: "16px"
      },
      "@media (max-width: 500px)": {
        fontSize: "14px"
      }
    },
    "& .FaqAnswer": {
      color: " #363636",
      fontFamily: "Asap",
      fontStyle: "normal",
      lineHeight: "32px",
      padding: "0px 0px 10px",
      "@media (max-width: 1080px)": {
        fontSize: "17px"
      },
      "@media (max-width: 600px)": {
        fontSize: "17px"
      },
      "@media (max-width: 500px)": {
        fontSize: "15px"
      }
    }
  },
  "& .Bottom": {
    width: " 100%",
    textAlign: "center"
  },
  "& .AboutContainer": {
    marginTop: "5px",
    "@media (max-width: 960px)": {
      marginTop: "0px"
    }
  },
  "& .custom-accordion": {
    border: "none",
    borderBottom: "2px solid #e0e0e0",
    boxShadow: "none",
    borderRadius: "0"
  },
  "& .custom-accordion:last-child": {
    borderBottom: "2px solid #e0e0e0"
  },

  "& .custom-accordion-summary": {
    backgroundColor: "transparent"
  },
  "& .tabs": {
    boxShadow: "inset 4px 0px 0px 0 #dadada",
    marginTop: "20px",
    "@media (max-width: 960px)": {
      boxShadow: "inset 0px -2px 0 0 #dadada"
    }
  }
});
const tabIndicator = {
  background: "#1653dd",
  height: "47px",
  width: "4px",
  left: "0px",
  borderRadius: "35px"
};
