Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.helpvideosAPiEndPoint="bx_block_demorequest/help_videos?video_type="
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "demorequest";
exports.labelBodyText = "demorequest Body";

exports.btnExampleTitle = "CLICK ME";
exports.title="User Guide Videos"
// Customizable Area End