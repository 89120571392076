Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "contact_us/contacts";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid"

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";
exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";
exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";
exports.ContactUsApiMethod_POST = "POST";
exports.ContactUsApiEndPoint = "/bx_block_contact_us/enquiry_before_log_in";

exports.FooterApiMethod_GET = "GET";
exports.FooterAPiEndPoint = "/bx_block_contact_us/contact_us";
exports.ContactAPiEndPoint = "bx_block_contact_us/contacts";

exports.Title = "GET IN TOUCH/";
exports.Heading = "Contact Us";
exports.SubHeading = "request a demo";
exports.Sendemail = "Send us an email";
exports.Givecall = " Give us a call";
exports.Fullname = "Full name";
exports.Company = "COMPANY";
exports.emailAddress = "EMAIL ADDRESS";
exports. ContactNumber = "Contact Number";
exports.Subject = "Subject";
exports.Message = "MESSAGE";
exports.Sendmessage = "SEND MESSAGE";



// Customizable Area End