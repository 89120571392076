import React, { ReactNode } from 'react';
import { Box, DialogProps } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { styled } from "@material-ui/styles";

interface CustomizedDialogsProps {
    modalTitle?: string;
    dialogContent?: ReactNode;
    onClose?: () => void;
    OpenModel: boolean;
    fullWidth?: boolean;
    maxWidth: false | "xs" | "sm" | "md" | "lg" | "xl" | undefined;
    children: React.ReactNode;
    ModelTitleColor?: string;
    DialogContentPadding?: boolean;
    TitlePadding?: string;
    CloseBtnPostionAbsolute?: boolean;
    BtnColor?: string;
    IconButtonBgColor?: string,
    BorderRadius?: string,
    CloseIconSize?: string,
    CloseIconDataTestId?: string,
    OutsideCloseFalse?:boolean
}

class ProjectModel extends React.Component<CustomizedDialogsProps> {

    ModelStyle = styled(Dialog)((StyleProps: DialogProps) => ({
        "& .MuiDialogTitle-root": {
            backgroundColor: `${this.props.ModelTitleColor}`,
            ...(typeof this.props.TitlePadding === "string" && {
                padding: this.props.TitlePadding
            }),
            ...(this.props.CloseBtnPostionAbsolute && {
                position: "absolute",
                right: "0px"
            })
        },
        "& .MuiDialog-paper": {
            ...(typeof this.props.BorderRadius === "string" && {
                borderRadius: this.props.BorderRadius
            })
        },
        "& .MuiDialogContent-root": {
            ...(this.props.DialogContentPadding === false && {
                padding: "0px"
            }),
            overflowY: "auto"
        },
        "& .IconButtonBgColor": {
            backgroundColor: `${this.props.IconButtonBgColor}`,
            margin:"5px"
        },
        "& .BtnColor": {
            color: `${this.props.BtnColor}`,
            ...(typeof this.props.CloseIconSize === "string" && {
                fontSize: this.props.CloseIconSize
            })
        }
    }))

    render() {
        const { children, onClose, fullWidth, OpenModel, maxWidth, ModelTitleColor,OutsideCloseFalse } = this.props;
        return (
            <this.ModelStyle fullWidth={fullWidth}
                maxWidth={maxWidth} onClose={OutsideCloseFalse == undefined ? onClose : ()=>{}}  open={OpenModel}>
                <MuiDialogTitle>
                    <Box style={{ backgroundColor: ModelTitleColor, display: "flex", justifyContent: "flex-end" }}>
                        <IconButton size='small' className='IconButtonBgColor' data-test-id={this.props.CloseIconDataTestId} onClick={onClose}>
                            <CloseIcon className='BtnColor' />
                        </IconButton>
                    </Box>
                </MuiDialogTitle>
                {children}
            </this.ModelStyle>
        );
    }
}


export default ProjectModel;
