import React, { Component } from "react";
import { styled, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import { StepIconProps } from "@material-ui/core/StepIcon";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, Grid, Typography, Tooltip } from "@material-ui/core";
import DirectionsBoatIcon from "@material-ui/icons/DirectionsBoat";
import moment from 'moment';
import { Message } from "../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { IBlock } from "../../framework/src/IBlock";
import { getStorageData } from "../../framework/src/Utilities";
import PhoneInput from "react-phone-input-2";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";


const CheckImage = require("./image_tick_white.png");
const BoxIcon = require("./Question_mark.png");
const Dropdown = require("./dropDownImage.png");
const pdf = require("./image_pdf.png");
const actionImage = require("./actionControl.png");
const actionLeft = require("./arrowLeft.png");
const arrowUp1 = require("./arrowUp1.png");

interface VerticalFileProps {
  handleApprovalModal?: () => void;
  handleNominationModal?: () => void;
  newState: number
  selectValue: string
  booking: any
}
interface VerticalFileState {
    activeStep: number;
    selectValue: any;
    subcatgValue: any;
    catgValue:any;
    SIFormData: any;
    SIFormVisible: boolean;
    shipperAccordion: boolean;
    consigneeAccordion: boolean;
    shipper_container_details: any;
    shippingDataAvailable: boolean;
    activeBookingReference:boolean;
    activeDraftBl:boolean;
    activeInvoice:boolean;
    activeSI:boolean;
    activePaymentConf:boolean;
    downloadedFilePath: any;
    activeFinalDraft: boolean;
}

const steps = [
    
    {
        label: "Authorize Container Operator",
        keydata: "auth_cont",
        container: ["Container Nomination by Buyer"]
    },

    {
        label: "Issue Booking Reference Number",
        keydata: "book_ref",
        container:["Shipping Instructions from Buyer"]
    },
    
    {
        label: "Prepare Draft B/L",
        container: ["Draft B/L Verified by Buyer"]
    },
    {
        label: "Share Invoice",
        container: ["Payment Confirmation Uploaded by Buyer"]
    },
    {
        label: "Estimated Date Of Container Loading Onto Vessel",
    },
    {
        label: "Share Final B/L",
    },
];

const LightTooltip = withStyles((theme) => ({
    tooltip: { boxShadow: "0rem .375rem .9375rem -0.1875rem rgba(0, 0, 0, 0.25)", backgroundColor: theme.palette.common.white, color: "black", fontSize: "12px", display: " inline-flex", justifyContent: "center", borderRadius: ".5rem", alignItems: "center",fontFamily: "Asap, sans-serif",},
    arrow: {color: "white"}
  }))(Tooltip);

const StyledDialogModal = styled(Dialog)({
    "& .MuiDialog-paper": {
      width: "100%",
      maxWidth: "800px",
      borderRadius: "24px",
      padding: "5% 2%",
      paddingTop: "2%",
    },
    "& .MuiFormHelperText-contained":{
      marginLeft:"0px"
        },
    "& .buyerTexterrorMsg": {
      color: "red",
      fontSize: "16px",
    },
    "& .nominateCheckbox": {
      color: "#1E1E1E",
      "& .MuiSvgIcon-root": { fontSize: 28 },
    },
    "& .nominateChecked": {
      color: "#FF5C00",
      "& .MuiSvgIcon-root": { fontSize: 28 },
    },
    "& .nominationClose": {
      textAlign: "right",
    },
    "& .nominationMsggrid": {
      textAlign: "center",
    },
    "& .nominationCloseimg": {
      height: "24px",
    },
    "& .nominationErrorimg": {
      height: "100px",
    },
    "& .nominationHeading": {
      fontWeight: 700,
      fontSize: "28px",
      color: "#1E1E1E",
      fontFamily: "Asap",
    },
    "& .revisionText": {
      fontWeight: 400,
      fontSize: "18px",
      color: "#1E1E1E",
      fontFamily: "Asap",
    },
    "& .reasonBox": {
      margin: "5%",
      textAlign: "start",
    },
    "& .reasonTextfield": {
      fontWeight: 400,
      fontSize: "18px",
      color: "#1E1E1E",
      fontFamily: "Asap",
      width: "100%",
    },
  
    "& .nominationLink": {
      fontWeight: 500,
      fontSize: "14px",
      color: "#1653DD",
      fontFamily: "Asap",
    },
    "& .nominationLinktext": {
      fontWeight: 400,
      fontSize: "16px",
      color: "#1E1E1E",
      fontFamily: "Asap",
    },
    "& .nominationButton": {
      fontWeight: 700,
      fontSize: "18px",
      fontFamily: "Asap",
      height: "fit-content",
      width: "fit-content",
      padding: "10px 70px",
      backgroundColor: "#FF5C00",
      color: "#fff",
      borderRadius: "25px",
      marginTop: "2%",
      textTransform: "capitalize",
    },
    "& .disbledBtn": {
      fontWeight: 700,
      fontSize: "18px",
      fontFamily: "Asap",
      height: "fit-content",
      width: "250px",
      backgroundColor: "gray",
      color: "#fff",
      borderRadius: "25px",
      marginTop: "2%",
    },
    "& .nominationButtonOutlined": {
      fontWeight: 700,
      fontSize: "18px",
      fontFamily: "Asap",
      height: "fit-content",
      width: "fit-content",
      padding: "8px 70px",
      backgroundColor: "#fff",
      color: "#FF5C00",
      borderRadius: "25px",
      marginTop: "2%",
      textTransform: "capitalize",
      border: '2px solid #FF5C00',
      marginLeft: '10px'
    },
    "& .addContainerbutton": {
      fontWeight: 400,
      fontSize: "16px",
      fontFamily: "Asap",
      height: "fit-content",
      width: "fit-content",
      padding: "10px 50px",
      backgroundColor: "#FF5C00",
      color: "#fff",
      borderRadius: "25px",
      margin: "2% 0%",
      textTransform: "capitalize",
    },
    "& .AddContainerButtonDisable":{
      "& .Mui-disabled":{
        backgroundColor: "lightgrey"
      }
    },
    "&. addContainerimg": {
      height: "20px",
    },
    "& .nominationButtondisable": {
      fontWeight: 700,
      fontSize: "18px",
      fontFamily: "Asap",
      height: "fit-content",
      width: "fit-content",
      padding: "10px 70px",
      backgroundColor: "#FF5C00",
      color: "#fff",
      borderRadius: "25px",
      marginTop: "2%",
      opacity: "0.3",
      textTransform: "capitalize",
    },
    "& .uploadButton": {
      width: "fit-content",
      padding: "10px 22px",
      textAlign: "start",
      textTransform: "capitalize",
    },
    "& .uploadButtonshow": {
      width: "fit-content",
      padding: "10px 100px",
      textAlign: "start",
      paddingLeft: "5px",
      position: "relative",
      margin: "1%",
      textTransform: "capitalize",
    },
    "& .verifyTextBox": {
      marginTop: "2%",
      padding: "1% 32%",
      textAlign: "start",
    },
    "& .paymentTextBox": {
      marginTop: "2%",
      padding: "1% 25%",
      textAlign: "start",
    },
    "& .dragging":{
      "& .uploadButton":{
        backgroundColor: "lightgrey"
      }
    },
    "& .recentButton": {
      width: "fit-content",
      padding: "10px 200px",
      textAlign: "start",
      paddingLeft:"5px",
      position:"relative",
      margin:"1%",
      textTransform:"uppercase",
      "& .MuiButton-label":{
        justifyContent:"flex-start"
      },
      "& .MuiButton-startIcon":{
        marginLeft: "0px"
      }
    },
    "& .uploadDeletebutton": {
      position:"absolute",
          right:"1%",
          top:"30%",
          height:"20px"
    },
    "& .pdfImg":{
     height:"20px"
    },
    "& .verificationText": {
      fontWeight: 700,
      fontSize: "16px",
      color: "#363636",
      fontFamily: "Asap",
    },
    "& .submittingAccordiongrid": {
      backgroundColor: "#E3E6ED",
    },
    "& .submittingSecondgrid": {
      marginTop: "2%",
      backgroundColor: "#E3E6ED",
      "& .MuiPaper-elevation1":{
        boxShadow: "none"
      }
    },
    "& .submittinggrid": {
      marginTop: "2%",
      backgroundColor: "#E3E6ED",
      padding: "1% 2%",
    },
    "& .submittingFirstgrid": {
      textAlign: "center",
      fontWeight: 700,
      fontSize: "24px",
      color: "#1E1E1E",
      fontFamily: "Asap",
    },
    "& .vesselTextfieldgrid": {
      padding: "1% 1%",
    },
    "& .vesselInformationtext": {
      textAlign: "center",
      fontWeight: 600,
      fontSize: "20px",
      color: "#1E1E1E",
      fontFamily: "Asap",
      position: "relative",
    },
    "& .HeadingTextHidden":{
      visibility:"hidden"
    },
    "& .vesselsubtext": {
      textAlign: "center",
      fontWeight: 600,
      fontSize: "18px",
      color: "#1E1E1E",
      fontFamily: "Asap",
      position: "relative",
    },
    "& .accordionDetails": {
  
      "& .MuiAccordionDetails-root": {
        padding: "0px 16px 16px !important",
      },
      
    },
    "& .vesselViewlesstext": {
      position: "absolute",
      right: "1%",
      fontSize: "12px",
    },
    "& .vesselCloseimg": {
      height: "18px",
      position: "absolute",
      right: "100%",
    },
    "& .vesselRemovetext": {
      position: "absolute",
      right: "1%",
      fontSize: "12px",
      top: "90%",
      color: "red",
    },
    "& .vesselTextfieldtext": {
      fontWeight: 400,
      fontSize: "16px",
      color: "#1E1E1E",
      fontFamily: "Asap",
      textTransform: 'uppercase',
      alignItem: 'center',
      justifyContent: 'flex-start',
      display:'flex',
    },
    "& .vesselTextfieldtext1": {
      fontWeight: 400,
      fontSize: "16px",
      color: "#1E1E1E",
      fontFamily: "Asap",
      alignItem: 'center',
      justifyContent: 'flex-start',
      display:'flex',
    },
    "& .PhoneInputPackgeClass": {
      height: "40px",
      borderRadius: "10px",
      fontSize: "15px",
      fontFamily: "Asap, sans-serif",
      width: "100%",
      fontWeight: 500,
      backgroundColor: "white",
      "@media (max-width: 1200px)": {
        fontSize: "12px",
        height: "30px",
      },
    },
    "& .textfieldStyle": {
      color: "black",
    },
  });

const ColorlibConnector = withStyles({
    alternativeLabel: {
        left: 5,
    },
    active: {
        "& $line": {
            backgroundImage: "linear-gradient(#ff890f,#ff890f)",
            marginTop:-45,
            marginLeft:37
        },
    },
    completed: {
        "& $line": {
            backgroundImage: "linear-gradient(#35D261,#35D261)",
            marginTop:-45,
            marginLeft:37
        },
    },
    line: {
        width: 2,
        border: 0,
        marginTop:-5,
        backgroundColor: "#eaeaf0",
        borderRadius: 1,
        marginLeft:'37px'
    },
    lineVertical: {
        minHeight: '70px', // Set your desired height here
      },
})(StepConnector);
const ColorlibConnector1 = withStyles({
    alternativeLabel: {
        left: 5,
    },
    active: {
        "& $line": {
            backgroundImage: "linear-gradient(#35D261,#35D261)",
            marginTop:-45,
            marginLeft:37
        },
    },
    completed: {
        "& $line": {
            backgroundImage: "linear-gradient(#35D261,#35D261)",
            marginTop:-45,
            marginLeft:37
        },
    },
    line: {
        width: 2,
        border: 0,
        marginTop:-5,
        backgroundColor: "#eaeaf0",
        borderRadius: 1,
        marginLeft:'37px'
    },
    lineVertical: {
        minHeight: '70px', // Set your desired height here
      },
})(StepConnector);

const styles = {
    root: {
        backgroundColor: "#e7e7e7",
        zIndex: 1,
        color: "#707070",
        width: 40,
        height: 40,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
    },
    active: {
        color: "#fff",
        backgroundColor: "#ff890f",
    },

    completed: {
        backgroundColor: "#e7e7e7",
    },
   
};
const BoxModel = styled(Box)({
    "& .stepLabelbox": {
        height: "50px",
        width: "95%",
        border: "1px solid black",
        borderRadius: "25px",
        marginLeft: "2%",
        alignItems:'center'
    },
    "& .stepLabelboxs": {
        height: "50px",
        width: "95%",
        border: "1px solid black",
        borderRadius: "25px",
        marginLeft: "2%",
        alignItems:'center',
    },
    "& .stepLabelboxone": {
        height: "50px",
        width: "95%",
        border: "1px solid black",
        borderRadius: "25px",
        marginLeft: "2%",
        backgroundColor: "#FDF2E0",
        position: "relative",
        alignItems:'center'
    },
    "& .stepLabelboxoneChild": {
        height: "50px",
        width: "88%",
        border: "1px solid black",
        borderRadius: "25px",
        marginLeft: "10%",
        backgroundColor: "#FDF2E0",
        position: "relative",
        alignItems:'center'
    },
    "& .stepLabelboxone1": {
        height: "50px",
        width: "95%",
        border: "1px solid black",
        borderRadius: "25px",
        marginLeft: "2%",
        backgroundColor: "#f00",
        position: "relative",
        alignItems:'center',
    },
    "& .stepLabelboxoneChild1": {
        height: "50px",
        width: "88%",
        border: "1px solid black",
        borderRadius: "25px",
        marginLeft: "10%",
        backgroundColor: "#00f",
        position: "relative",
        alignItems:'center',
    },
    "& .nominationLink": {
        fontWeight: 500,
        fontSize: "14px",
        color: "#1653DD",
        fontFamily: "Asap",
    },
    "& .stepLabelspan": {
        position: "absolute",
        top: "0px",
        right: "1px"
    },
     "& .DropDownData":{
        width:'30%',
        textAlign:'right',
        marginRight:'15px'
     } ,    
     "& .DropDownDataChild":{
        width:'30%',
        textAlign:'right',
        marginRight:'15px'
     },
     "& .accordianData ":{
        backgroundColor:'#F3FcF6',
        width:'90%',
        padding:'5px',
        marginLeft:'5%'
     },
     "& .accordianDataSub":{
        backgroundColor:'#F3FcF6',
        width:'85%',
        padding:'10px',
        marginLeft:'10%'
      },
     "& .draftRevision":{
        width:'95%',
        marginLeft:'2%',
        fontWeight:500,
        fontSize:'12px',
        lineHeight:'16px',
        backgroundColor:'#FCECED',
        color:'#DC4923',
        padding:'10px',
        fontFamily:'asap',
        marginTop:'-20px',
        borderBottomLeftRadius: '7px',
        borderBottomRightRadius: '7px'
     },
     "& .bookingRefence":{
        color:'#000',
        fontWeight:'800',
        fontFamily:'Asap',
        fontSize:"12px"
     },
     "& .SmallIcon":{
       height:'20px'
     },
     "& .nominationLinktext": {
        fontWeight: 400,
        fontSize: "14px",
        color: "#1653DD",
        fontFamily: "Asap",
        cursor: 'pointer'
      },
    "& .stepLabelButton": {
        height: "48px",
        width: "100%",
        borderRadius: "23px",
        paddingRight: "113px",
        border: "none",
        paddingLeft: " 50px",
        color: "white",
        fontSize: "16px",
        textAlign: "center",
        backgroundColor: "#EE8F00",
        "@media (max-width: 768px)": {
            fontSize: "12px",
            padding: "15px !important"
          },
    },
    "& .stepLabelDisableButton": {
        height: "48px",
        width: "100%",
        borderRadius: "23px",
        paddingRight: "113px",
        border: "none",
        paddingLeft: " 50px",
        color: "white",
        fontSize: "16px",
        backgroundColor: 'grey',
        "@media (max-width: 500px)": {
          fontSize: "13px",
          padding: "15px 6px 8px 21px"
        },
    },


    "& .stepLabel": {
        fontSize: "16px",
        fontFamily: "Asap",
        fontWeight: 500,
        padding:'12px',
        width:'100%',
        marginLeft:'5px',
        "@media (max-width: 768px)": {
            fontSize: "12px",
          },
    },
    "& .stepLabelTable": {
        fontSize: "16px",
        fontFamily: "Asap",
        fontWeight: 800,
        marginLeft:'15px',
        padding:'12px',
        width: '100%',
        "@media (max-width: 768px)": {
            fontSize: "12px",
          },
    },
    "& .invoiceTerms":{
      fontWeight: 400,
      fontSize: "14px",
      color: "#000000",
      fontFamily: "Asap",
      backgroundColor: '#F3FcF6',
      width: '90%',
      paddingLeft: '5px',
      marginLeft: '5%',
      marginTop: "-5px",
    },
    "& .termsText": {
      lineHeight: "18px",
      display: "block",
      textOverflow: "ellipsis",
      wordWrap: "break-word",
      overflow: "hidden",
      maxHeight: "18px",
    },
    "& .stepLabelTable1": {
        fontSize: "16px",
        fontFamily: "Asap",
        fontWeight: 800,
        padding:'12px',
        width: '100%',
        paddingLeft:'27px',
        "@media (max-width: 768px)": {
            fontSize: "12px",
          },
    },
    "&  .mainBox":{
      marginLeft:'35px'
    },
    "& .stepLabelboxClick": {
        display: "flex",
        justifyContent: "space-between",

    },
    "& .stepLabelclick": {
        color: "white",

        backgroundColor: "#EE8F00"

    }
})

class ColorlibStepIcon extends Component<StepIconProps & {activeStepData:number,stepIndex:number,Booking:any}> {
    
     TimeFormate =(dateTimeString:any)=>{
      
        return moment(dateTimeString, "DD MMM YYYY hh:mm A").format("hh:mm A")
    }


    
    render() {
        const { active, completed } = this.props;
        const icons: { [index: string]: React.ReactElement } = {
            "0": <Box style={webstyle.stepBox}>Now </Box>,
            "1": <Box style={webstyle.stepBox}>1</Box>,
            "2": <Box style={webstyle.stepBox}>2</Box>,
            "3": <Box style={webstyle.stepBox}>3</Box>,
            "4": <Box style={webstyle.stepBox}>4</Box>,
            "5": <Box style={webstyle.stepBox}><DirectionsBoatIcon/></Box>,
            "6": <Box style={webstyle.stepBox}>5</Box>,
            "7": <Box style={webstyle.stepBox}>4</Box>,
            "8": <Box style={webstyle.stepBox}></Box>,
            "9": <Box style={webstyle.stepBox}></Box>,
            "10": <Box style={webstyle.stepBox}>5</Box>,
        };
        if ((this.props.active && this.props.icon === 3)) {
            return (<div style={{display:'flex'}}> <span style={{marginLeft:-60,fontFamily:'asap',width:60,fontSize:13}}>{this.props.Booking?.upload_draft_bl_at}</span>
                <div
                    style={{
                        zIndex: 1,
                        width: 50,
                        height: 50,
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: (this.props?.Booking?.draft_file_status) ? '#35D261' : (this.props?.Booking?.draft_bl_status==false)? "#DC4923" : (active&&this.props?.Booking?.booking_references_status) ? '#EE8F00' : '#ECECEC',
                        color: '#fff',
                        fontFamily:'asap',
                        fontWeight:600,
                        marginLeft:'26px',
                    }}
                >
                 <img src={actionImage}/>
                </div>
                </div>
            );
        }

        return (<>
        {this.props.stepIndex==0&&<div style={{display:'flex'}}> <span style={{marginLeft:-60,fontFamily:'asap',width:75,fontSize:13}}>{this.props.Booking?.co_authorizer_at}</span>
            <div
                data-testid="stepper"
                style={{                 
                    ...webstyle.CircleMainIcon,
                    background:  (active&&!this.props?.Booking?.co_authorizer) ? '#EE8F00' : ((active&&this.props?.Booking?.co_authorizer)||this.props?.Booking?.co_authorizer) ? '#35D261' : '#ECECEC',
                    color: active || completed ? '#fff' : '#B4CDEA',          
                }}
            >
                {(active&&!this.props?.Booking?.co_authorizer) ? "Now" : ((active&&this.props?.Booking?.co_authorizer)||this.props?.Booking?.co_authorizer) ? <><img src={CheckImage} alt="downIMg" />  </>: icons[String(this.props.icon)]}
            </div>
            </div>}
            
            
            {this.props.stepIndex==1&&<div style={{display:'flex'}}> <span style={{marginLeft:-60,fontFamily:'asap',width:75,fontSize:13}}>{this.props.Booking?.booking_reference_number_at}</span>
            <div
                data-testid="stepper"
                style={{                 
                    ...webstyle.CircleMainIcon,
                    background: (active&&!this.props?.Booking?.booking_references_status) ? '#EE8F00' : ((active&&this.props?.Booking?.booking_references_status)||this.props?.Booking?.booking_references_status) ? '#35D261' : '#ECECEC',
                    color: active || completed ? '#fff' : '#B4CDEA',          
                }}
            >
                {(active&&!this.props?.Booking?.booking_references_status) ? "Now" : ((active&&this.props?.Booking?.booking_references_status)||this.props?.Booking?.booking_references_status) ? <><img src={CheckImage} alt="downIMg" />  </>: icons[String(this.props.icon)]}
            </div>
            </div>}

            {this.props.stepIndex==2&&<div style={{display:'flex'}}> <span style={{marginLeft:-60,fontFamily:'asap',width:75,fontSize:13}}>{this.props.Booking?.upload_draft_bl_at}</span>
            <div
                data-testid="stepper"
                style={{                 
                    ...webstyle.CircleMainIcon,
                    background: this.props?.Booking?.draft_bl_status==false? "#DC4923" : (active&&this.props?.Booking?.booking_references_status) ? '#EE8F00' : ((active&&this.props?.Booking?.draft_file_status)||this.props?.Booking?.draft_file_status) ? '#35D261': '#ECECEC',
                    color: active || completed ? '#fff' : '#B4CDEA',          
                }}
            >
                {this.props?.Booking?.draft_bl_status==false ? <><img src={actionImage} alt="downIMg" />  </> :(active&&!this.props?.Booking?.draft_bl_status) ? "Now" : ((active&&this.props?.Booking?.draft_file_status)||this.props?.Booking?.draft_file_status) ? <><img src={CheckImage} alt="downIMg" />  </>: icons[String(this.props.icon)]}
            </div>
            </div>}
            
            {this.props.stepIndex==3&&<div style={{display:'flex'}}> <span style={{marginLeft:-60,fontFamily:'asap',width:75,fontSize:13}}>{this.props.Booking.upload_invoice_at}</span>
            <div
                data-testid="stepper"
                style={{                 
                    ...webstyle.CircleMainIcon,
                    background: (active&&!this.props?.Booking?.invoice_status) ? '#EE8F00' : ((active&&this.props?.Booking?.invoice_status)||this.props?.Booking?.invoice_status) ? '#35D261'  : '#ECECEC',
                    color: active || completed ? '#fff' : '#B4CDEA',          
                }}
            >
                {(active&&!this.props?.Booking?.invoice_status) ? "Now" : ((active&&this.props?.Booking?.invoice_status)||this.props?.Booking?.invoice_status)  ? <><img src={CheckImage} alt="downIMg" />  </>: icons[String(this.props.icon)]}
            </div>
            </div>}


            {this.props.stepIndex==4&&<div style={{display:'flex'}}> <span style={{marginLeft:-60,fontFamily:'asap',width:75,fontSize:13}}>{this.props?.Booking?.load_time && moment(this.props?.Booking?.load_time).parseZone().format("DD MMM YYYY hh:mm A")} </span>
            <div
                data-testid="stepper"
                style={{                 
                    ...webstyle.CircleMainIcon,
                    background: active ? '#EE8F00' : completed ? '#35D261' : '#ECECEC',
                    color: active || completed ? '#fff' : '#B4CDEA',          
                }}
            >
                {active ? "Now" : completed ? <><img src={CheckImage} alt="downIMg" />  </>: icons[String(this.props.icon)]}
            </div>
            </div>}


            {this.props.stepIndex==5&&<div style={{display:'flex'}}> <span style={{marginLeft:-60,fontFamily:'asap',width:75,fontSize:13}}>{this.props.Booking.upload_final_bl_at}</span>
            <div
                data-testid="stepper"
                style={{                 
                    ...webstyle.CircleMainIcon,
                    background: active ? '#EE8F00' : completed ? '#35D261' : '#ECECEC',
                    color: active || completed ? '#fff' : '#B4CDEA',          
                }}
            >
                {active ? "Now" : completed ? <><img src={CheckImage} alt="downIMg" />  </>: icons[String(this.props.icon)]}
            </div>
            </div>}
            
            </>
        );
    }
}



class ColorlibStepIcon2 extends Component<StepIconProps & {activeStepData:number,stepIndex:number,booking:any,label:any,activeSI:any,activePaymentConf:any}> {
    
  
    
    TimeShowData =(dateTimeString:any)=>{
      
        if (dateTimeString) {
            return moment(dateTimeString, "DD MMM YYYY hh:mm A").format("hh:mm A");
        } else {
            return "";
        }    

    }
    
    render() {
        const { active, completed} = this.props;

      
        
        const icons: { [index: string]: React.ReactElement } = {
            "0": <Box style={webstyle.stepBox}><img src={BoxIcon} alt="downIMg" /></Box>,
            "1": <Box style={webstyle.stepBox}><img src={BoxIcon} alt="downIMg" /></Box>,
        };

        if ((this.props.icon === 0 && this.props.active) || (this.props.icon === 1 && this.props.active) || (this.props.active && this.props.icon === 2)) {
            return (
                <div
                    style={{
                        zIndex: 1,
                        width: 50,
                        height: 50,
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background:  this.props.booking.attributes.nominated_at   ? '#35D261' : this.props?.booking?.attributes?.co_authorizer ? '#EE8F00' : '#ECECEC',
                        color: '#fff',
                        marginLeft:'12px',
                        fontFamily:'asap',
                        fontWeight:600
                    }}
                >
                    Now
                </div>
            );
        }

        return (<>
            
            {this.props.label==='Authorize Container Operator'&&<>
            {this.props.stepIndex==0&&
            <div style={{display:'flex'}}> <span style={{marginLeft:-85,fontFamily:'asap',width:75,fontSize:13}}>{this.props?.booking?.attributes?.nominated_at}</span>
            <div
                data-testid="stepper"
                style={{...webstyle.CircleIcon,
                     background:  this.props.booking.attributes.nominated_at   ? '#35D261' : '#EE8F00',
                    color: this.props?.booking?.attributes?.is_nominated ? '#fff' : '#B4CDEA'}}
            >
               {this.props?.booking?.attributes?.is_nominated ? <img src={CheckImage} alt="downIMg" /> : <img src={BoxIcon} alt="downIMg" />}
            </div></div>}
            </>
            }

            {(this.props.label==='Issue Booking Reference Number')&&<> 
            {this.props.stepIndex==0&&
            <div style={{display:'flex'}}> <span style={{marginLeft:-85,fontFamily:'asap',width:75,fontSize:13,marginTop:this.props.activeSI ? -40:0}}>{this.props?.booking?.attributes?.submitted_si_at}</span>
            <div
                data-testid="stepper"
                style={{...webstyle.CircleIcon,
                     background: this.props.booking.attributes. submit_si_status  ? '#35D261' : this.props?.booking?.attributes?.is_nominated ? '#EE8F00' : '#ECECEC',
                    color: this.props?.booking?.attributes?.submit_si_status ? '#fff' : '#B4CDEA',marginTop:this.props.activeSI ? -40:0}}
            >
             {this.props?.booking?.attributes?.submit_si_status ? <img src={CheckImage} alt="downIMg" /> : <img src={BoxIcon} alt="downIMg" />}
            </div> </div>}
            </>
            }

          {(this.props.label==='Prepare Draft B/L'&&(this.props?.booking?.attributes?.draft_bl_status!==false || this.props?.booking?.attributes?.draft_file_status!==false))&&<div style={{display:'flex'}}> <span style={{marginLeft:-85,fontFamily:'asap',width:75,fontSize:13}}>{this.props?.booking?.attributes?.verified_draft_bl_at}</span>
            {this.props.stepIndex==0&&<div
                data-testid="stepper"
                style={{...webstyle.CircleIcon,
                     background:  this.props?.booking?.attributes?.draft_bl_status ? '#35D261' : this.props?.booking?.attributes?.submit_si_status ? '#EE8F00' : '#ECECEC',
                    color: this.props?.booking?.attributes?.draft_bl_status ? '#fff' : '#B4CDEA'}}
            >
                {this.props?.booking?.attributes?.draft_bl_status ? <img src={CheckImage} alt="downIMg" /> : <img src={BoxIcon} alt="downIMg" />}
            </div>}
            </div>
            }


            {this.props.label==='Share Invoice'&&<div style={{display:'flex'}}> <span style={{marginLeft:-85,fontFamily:'asap',width:75,fontSize:13,marginTop:this.props.activePaymentConf ?-35:0}}>{this.props?.booking?.attributes?.upload_invoice_at}</span>
            {this.props.stepIndex==0&&<div
                data-testid="stepper"
                style={{...webstyle.CircleIcon,
                     background:  this.props?.booking?.attributes?.payment_status ? '#35D261' : this.props?.booking.attributes.draft_bl_status ? '#EE8F00':'#ECECEC',
                    color: this.props?.booking?.attributes?.payment_status ? '#fff' : this.props?.booking.attributes.draft_bl_status ?'#B4CDEA':'#000',marginTop:this.props.activePaymentConf ?-32:0}}
            >
                {this.props?.booking?.attributes?.payment_status ? <img src={CheckImage} alt="downIMg" /> : <img src={BoxIcon} alt="downIMg" />}
            </div>}
            </div>
            }
            </>
        );
    }
}




class SellerVeticalFile extends Component<VerticalFileProps, VerticalFileState> {
    subScribedMessages: string[];

    constructor(props: any) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.PostDetailDataMessage),
        ];
        this.state = {
            activeStep: 0,
            selectValue:'',
            subcatgValue:'',
            catgValue:'',
            SIFormData: {},
            SIFormVisible: false,
            shipperAccordion: true,
            consigneeAccordion: true,
            shipper_container_details: [],
            shippingDataAvailable: false,
            activeBookingReference:false,
            activeDraftBl:false,
            activeInvoice:false,
            activeSI:false,
            activePaymentConf:false,
            downloadedFilePath: null,
            activeFinalDraft: false,
        };
        runEngine.attachBuildingBlock(this as unknown as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );


        if (apiRequestCallId === this.GetSIFormDataApiCallId) {
            if (responseJson.shipper ) {

                this.setState({SIFormData: responseJson.shipper?.data, shippingDataAvailable: true})
            }
            if (responseJson.shipper_container_details) {
                this.setState({shipper_container_details: responseJson.shipper_container_details, shippingDataAvailable: true})
            }
        }
    }

    async componentDidMount() {
        this.getPrincipalOrganisation();

    }

    GetSIFormDataApiCallId: string = "";

    getPrincipalOrganisation = async () => {
      this.setState({shippingDataAvailable: false})
        let authToken = await getStorageData("authToken");
        const header = {
            "Content-Type": "application/json",
            token: authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.GetSIFormDataApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_listdemandpickflow/bookings/get_shippment_instraction?id=${this.props.booking?.id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };


    DropDownValue = (index:number) =>{

      if(this.state.selectValue==index){
        this.setState({selectValue:''})
      }

      if(this.state.selectValue!==index||this.state.selectValue==''){
        this.setState({selectValue:index})
      }
    }

    BookingRefrenceShow = ()=>{
    if(this.state.activeBookingReference){
      this.setState({activeBookingReference:false})
    }else{
      this.setState({activeBookingReference:true})
    }
    }
 
    ActiveSI =()=>{
      if(this.state.activeSI){
        this.setState({activeSI:false})
      }else{
        this.setState({activeSI:true})
      } 
    }

    ShowInvoice = ()=>{
      if(this.state.activeInvoice){
        this.setState({activeInvoice:false})
      }else{
        this.setState({activeInvoice:true})
      } 
    }

    showFinalBL = () => {
      if(this.state.activeFinalDraft){
        this.setState({activeFinalDraft:false})
      }else{
        this.setState({activeFinalDraft:true})
      } 
    }

    SubCatgDropDown =(index2:number,index:number)=>{

      if(this.state.catgValue==index&&this.state.subcatgValue==index2){

        this.setState({subcatgValue:'',catgValue:'',activePaymentConf:false})
      }

      if((this.state.catgValue!==index||this.state.subcatgValue!==index2)){

        this.setState({subcatgValue:index2,catgValue:index,activePaymentConf:true})
      }

    }

    downloadFileFromMinio = async (minioUrl: any, manualName: any) => {
      try {
          const response = await fetch(minioUrl);
          if (!response.ok) {
              throw new Error('Failed to download file');
          }
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
  
          // Create a link element
          const a = document.createElement('a');
          a.href = url;
          a.download = manualName; // Set the file name
          a.style.display = 'none';
          document.body.appendChild(a);
  
          // Simulate click on the link to trigger download
          a.click();
  
          // Remove the link from the DOM
          document.body.removeChild(a);
  
          // Revoke the object URL to free up resources
          window.URL.revokeObjectURL(url);
  
          return manualName;
      } catch (error) {
          console.error('Error downloading file:', error);
          throw error;
      }
  };

  // Method to handle file download
  handleDownload = async (file: any, filename: any) => {
      const minioUrl = file; // Replace with actual Minio URL
      const manualName = filename; // Replace with desired file name
      try {
          const downloadedFilePath = await this.downloadFileFromMinio(minioUrl, manualName);
          this.setState({ downloadedFilePath });
      } catch (error) {
          console.error('Error downloading file:', error);
      }
  };

    

    render() {
        const { newState , booking } = this.props;
        let activeStep = -1;
        let length=6;
        let ButtonTitle='Authorize';
        let activeConnector: boolean= false;

         if(booking.attributes.booking_confirmation_status !== "Pending Confirmation" && booking.attributes.booking_confirmation_status !== "Booking Cancelled"){
             activeStep=0
         }
         if(booking.attributes.co_authorizer&&booking.attributes.is_nominated){
             activeStep=1
         }

         if(booking.attributes.booking_references_status&&booking.attributes.submit_si_status){
            activeStep=2
        }

        if(booking.attributes.draft_file_status&&booking.attributes.draft_bl_status){
            activeStep=3
        }

        if(booking.attributes.invoice_status&&booking.attributes.payment_status){
            activeStep=4
        }

        if(booking.attributes.load_time){
            activeStep=5
        }

        if(booking.attributes.final_bl_status){
            activeStep=6
        }

        if (
          booking &&
          booking.attributes &&
          (
            (booking.attributes.booking_references_status && !booking.attributes.submit_si_status) ||
            (booking.attributes.draft_file_status) ||
            (booking.attributes.invoice_status && !booking.attributes.payment_status)
          )
        ) {
          activeConnector = true
        }
        if(booking.attributes.draft_bl_status==false && booking.attributes.draft_file_status==false){
            length=3
            ButtonTitle='Revise B/L'
        }


        if(activeStep==1){
            ButtonTitle='Issue'        
        }
        if(activeStep==2&&!booking.attributes?.draft_bl){
            ButtonTitle='Upload'
        }


        

        const CheckValidation =()=>{

                let  active_button=false;
               if(activeStep==2&&!booking.attributes?.submit_si_status){
                active_button=true;
                 } 
                
               if(activeStep==1&&!booking.attributes?.is_nominated){
                     active_button=true;
                }

                if(booking.attributes?.booking_status=='rejected'){
                    active_button=true;
                 }

                 if(activeStep==3&&booking.attributes.draft_bl_status==false&&booking.attributes?.draft_bl){
                  active_button=true;
                 }


                return active_button;
           }

           const openSIViewForm = () => {
            this.setState({SIFormVisible: true})
           }
           const closeSIViewForm = () => {
            this.setState({SIFormVisible: false})
           }

           const handleVesselinformation = () => {
            return (
              <Grid container spacing={3}>
                <Grid lg={12} md={12} sm={12} xs={12} className="vesselInformationtext">
                Shipment Details
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={12}
                  xs={12}
                  className="vesselTextfieldgrid"
                >
                  <Typography className="vesselTextfieldtext">
                  POL
                  </Typography>
                  <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}><text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {this.state.SIFormData?.attributes?.pol}</text></div>
                </Grid>
                
                <Grid item lg={3} md={3} sm={12} xs={12} className="vesselTextfieldgrid" > <Typography className="vesselTextfieldtext">
                    POD
                  </Typography>
                      <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}><text style = {{fontFamily: 'Asap', color: '#000'}}>
                      {this.state.SIFormData?.attributes?.pod}
                        </text></div>
                    </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12} className="vesselTextfieldgrid" >
                  <Typography className="vesselTextfieldtext"> POD Terminal </Typography> 
                  <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}><text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {this.state.SIFormData?.attributes?.pod_terminal}
                    </text></div>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={12}
                  xs={12}
                  className="vesselTextfieldgrid"
                >
                  <Typography className="vesselTextfieldtext1">
                    TEUs BOOKED </Typography>
                    <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}><text style = {{fontFamily: 'Asap', color: '#000'}}>
                    {this.props.booking?.attributes?.offer_quantity + this.props.booking?.attributes?.offer_sec_quantity * 2 }
                        </text></div>
                  </Grid>
              </Grid>
            );
          }

          const handleShipperaccordion = () => {
            this.setState({
              shipperAccordion: !this.state.shipperAccordion,
            });
          };
          const handleConsigneeaccordion = () => {
            this.setState({
              consigneeAccordion: !this.state.consigneeAccordion,
            });
          };

          const  handleShippersummary = () =>  {
            return (
              <Grid container>
                <Grid lg={12} md={12} sm={12} xs={12} className="vesselInformationtext">
                  Shipper Details
                  {this.state.shipperAccordion ? (
                    <span
                      onClick={handleShipperaccordion}
                      className="vesselViewlesstext"
                    >
                      <img className="vesselCloseimg" src={require('../../blocks/listdemandpickflow/assets/image_up.png')} alt="" />
                      View Less
                    </span>
                  ) : (
                    <span
                      onClick={handleShipperaccordion}
                      className="vesselViewlesstext"
                    >
                      <img className="vesselCloseimg" src={require('../../blocks/listdemandpickflow/assets/image_down.png')} alt="" />
                      View More
                    </span>
                  )}
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="vesselTextfieldgrid"
                >
                  <Typography className="vesselTextfieldtext">
                  Shipper Company Name
                  </Typography>
                  <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}><text style = {{fontFamily: 'Asap', color: '#000'}}>
                    {this.state.SIFormData?.attributes?.shipper_company_name}
                    </text></div>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="vesselTextfieldgrid"
                >
                  <Typography className="vesselTextfieldtext">
                  Shipper Person-In-Charge
                  </Typography>
                  <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}><text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {this.state.SIFormData?.attributes?.shipper_in_charge}
                    </text></div>
                </Grid>
              </Grid>
            );
          }

          const handleShipperData = () => {
            return (
              <Grid container>
                <Grid
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ textAlign: "start" }}
                  className="vesselsubtext"
                >
                  Shipper Address
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="vesselTextfieldgrid"
                >
                  <Typography className="vesselTextfieldtext">
                  Street Address
                  </Typography>
        
                  <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}><text style = {{fontFamily: 'Asap', color: '#000'}}>
                  {this.state.SIFormData?.attributes?.shipper_address}
                    </text></div>
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="vesselTextfieldgrid"
                >
                  <Typography className="vesselTextfieldtext">
                  Street Address Line 2
                  </Typography>
        
                  <div style={{
                    padding: '10px', 
                    minHeight: '40px', 
                    borderRadius: '10px', 
                    backgroundColor: '#EEEEEE', 
                    border: '1px solid #CBD5E1'
                    }}>
                        <text style = {{fontFamily: 'Asap', color: '#000'}}>
                        {this.state.SIFormData?.attributes?.shipper_street_address}
                        </text>
                    </div>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={12}
                  xs={12}
                  className="vesselTextfieldgrid"
                >
                  <Typography className="vesselTextfieldtext">
                  City
                  </Typography>
        
                  <div style={{
                    padding: '10px', 
                    minHeight: '40px', 
                    borderRadius: '10px', 
                    backgroundColor: '#EEEEEE', 
                    border: '1px solid #CBD5E1'
                    }}>
                        <text style = {{fontFamily: 'Asap', color: '#000'}}>
                        {this.state.SIFormData?.attributes?.shipper_city}
                        </text>
                    </div>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={12}
                  xs={12}
                  className="vesselTextfieldgrid"
                >
                  <Typography className="vesselTextfieldtext">
                  State/Province 
                  </Typography>
        
                  <div style={{
                    padding: '10px', 
                    minHeight: '40px', 
                    borderRadius: '10px', 
                    backgroundColor: '#EEEEEE', 
                    border: '1px solid #CBD5E1'
                    }}>
                        <text style = {{fontFamily: 'Asap', color: '#000'}}>
                        {this.state.SIFormData?.attributes?.shipper_state}
                        </text>
                    </div>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={12}
                  xs={12}
                  className="vesselTextfieldgrid"
                >
                  <Typography className="vesselTextfieldtext">
                  Postal/Zip Code
                  </Typography>
        
                  <div style={{
                    padding: '10px', 
                    minHeight: '40px', 
                    borderRadius: '10px', 
                    backgroundColor: '#EEEEEE', 
                    border: '1px solid #CBD5E1'
                    }}>
                        <text style = {{fontFamily: 'Asap', color: '#000'}}>
                        {this.state.SIFormData?.attributes?.shipper_postal_code}
                        </text>
                    </div>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={12}
                  xs={12}
                  className="vesselTextfieldgrid"
                >
                  <Typography className="vesselTextfieldtext">
                  Country
                  </Typography>
        
                  <div style={{
                    padding: '10px', 
                    minHeight: '40px', 
                    borderRadius: '10px', 
                    backgroundColor: '#EEEEEE', 
                    border: '1px solid #CBD5E1'
                    }}>
                        <text style = {{fontFamily: 'Asap', color: '#000'}}>
                        {this.state.SIFormData?.attributes?.shipper_country}
                        </text>
                    </div>
                </Grid>
        
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="vesselTextfieldgrid"
                >
                  <Typography className="vesselTextfieldtext">
                  Shipper Email address
                  </Typography>
        
                  <div style={{
                    padding: '10px', 
                    minHeight: '40px', 
                    borderRadius: '10px', 
                    backgroundColor: '#EEEEEE', 
                    border: '1px solid #CBD5E1'
                    }}>
                        <text style = {{fontFamily: 'Asap', color: '#000'}}>
                        {this.state.SIFormData?.attributes?.shipper_email}
                        </text>
                    </div>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="vesselTextfieldgrid"
                >
                  <Typography className="vesselTextfieldtext">
                  Shipper Contact Number
                  </Typography>
                  <PhoneInput
                    country={"sg"}
                    placeholder={""}
                    value={`${this.state.SIFormData?.attributes?.shipper_phone_number}`}
                    disabled
                    searchPlaceholder="Search for countries"
                    data-test-id="ShipperContact"
                    inputProps={{
                      required: true,
                      id: "ShipperContact",
                      name: "ShipperContact",
                    }}
                    countryCodeEditable={false}
                    enableSearch={true}
                    jumpCursorToEnd={true}
                    inputClass={`PhoneInputPackgeClass`}
                  />
                </Grid>
              </Grid>
            );
          }

          const  ConsigneeAccordionRender = () => {
            return(
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="submittingSecondgrid"
              >
                <Accordion
                  className="submittingAccordiongrid"
                  expanded={this.state.consigneeAccordion}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid container>
                      <Grid
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="vesselInformationtext"
                      >
                        Consignee Details
                        {this.state.consigneeAccordion ? (
                          <span
                            onClick={handleConsigneeaccordion}
                            className="vesselViewlesstext"
                          >
                            <img
                              className="vesselCloseimg"
                              src={require('../../blocks/listdemandpickflow/assets/image_up.png')}
                              alt=""
                            />
                            View Less
                          </span>
                        ) : (
                          <span
                            onClick={handleConsigneeaccordion}
                            className="vesselViewlesstext"
                          >
                            <img
                              className="vesselCloseimg"
                              src={require('../../blocks/listdemandpickflow/assets/image_down.png')}
                              alt=""
                            />
                            View More
                          </span>
                        )}
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        className="vesselTextfieldgrid"
                      >
                        <Typography className="vesselTextfieldtext">
                        Consignee Name
                        </Typography>
                        <div style={{
                    padding: '10px', 
                    minHeight: '40px', 
                    borderRadius: '10px', 
                    backgroundColor: '#EEEEEE', 
                    border: '1px solid #CBD5E1'
                    }}>
                        <text style = {{fontFamily: 'Asap', color: '#000'}}>
                        {this.state.SIFormData?.attributes?.cosnginee_name}
                        </text>
                    </div>
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        className="vesselTextfieldgrid"
                      >
                        <Typography className="vesselTextfieldtext">
                        Consignee Person-In-Charge
                        </Typography>
                        <div style={{
                    padding: '10px', 
                    minHeight: '40px', 
                    borderRadius: '10px', 
                    backgroundColor: '#EEEEEE', 
                    border: '1px solid #CBD5E1'
                    }}>
                        <text style = {{fontFamily: 'Asap', color: '#000'}}>
                        {this.state.SIFormData?.attributes?.consignee_in_charge}
                        </text>
                    </div>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails className="accordionDetails">
                    <Grid container>
                      <Grid
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{ textAlign: "start", marginLeft: "1%" }}
                        className="vesselsubtext"
                      >
                        Consignee Address
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="vesselTextfieldgrid"
                      >
                        <Typography className="vesselTextfieldtext">
                        Street Address
                        </Typography>
        
                        <div style={{
                    padding: '10px', 
                    minHeight: '40px', 
                    borderRadius: '10px', 
                    backgroundColor: '#EEEEEE', 
                    border: '1px solid #CBD5E1'
                    }}>
                        <text style = {{fontFamily: 'Asap', color: '#000'}}>
                        {this.state.SIFormData?.attributes?.cosnginee_address}
                        </text>
                    </div>
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="vesselTextfieldgrid"
                      >
                        <Typography className="vesselTextfieldtext">
                        Street Address Line 2
                        </Typography>
                        <div style={{
                    padding: '10px', 
                    minHeight: '40px', 
                    borderRadius: '10px', 
                    backgroundColor: '#EEEEEE', 
                    border: '1px solid #CBD5E1'
                    }}>
                        <text style = {{fontFamily: 'Asap', color: '#000'}}>
                        {this.state.SIFormData?.attributes?.cosnginee_street_address}
                        </text>
                    </div>
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                        className="vesselTextfieldgrid"
                      >
                        <Typography className="vesselTextfieldtext">
                        City
                        </Typography>
                        <div style={{
                    padding: '10px', 
                    minHeight: '40px', 
                    borderRadius: '10px', 
                    backgroundColor: '#EEEEEE', 
                    border: '1px solid #CBD5E1'
                    }}>
                        <text style = {{fontFamily: 'Asap', color: '#000'}}>
                        {this.state.SIFormData?.attributes?.cosnginee_city}
                        </text>
                    </div>
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                        className="vesselTextfieldgrid"
                      >
                        <Typography className="vesselTextfieldtext">
                        State/Province{" "}
                        </Typography>
                        <div style={{
                        padding: '10px', 
                        minHeight: '40px', 
                        borderRadius: '10px', 
                        backgroundColor: '#EEEEEE', 
                        border: '1px solid #CBD5E1'
                        }}>
                            <text style = {{fontFamily: 'Asap', color: '#000'}}>
                            {this.state.SIFormData?.attributes?.cosnginee_state}
                            </text>
                        </div>
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                        className="vesselTextfieldgrid"
                      >
                        <Typography className="vesselTextfieldtext">
                        Postal/Zip Code
                        </Typography>
                        <div style={{
                    padding: '10px', 
                    minHeight: '40px', 
                    borderRadius: '10px', 
                    backgroundColor: '#EEEEEE', 
                    border: '1px solid #CBD5E1'
                    }}>
                        <text style = {{fontFamily: 'Asap', color: '#000'}}>
                        {this.state.SIFormData?.attributes?.cosnginee_postal_code}
                        </text>
                    </div>
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                        className="vesselTextfieldgrid"
                      >
                        <Typography className="vesselTextfieldtext">
                        Country
                        </Typography>
                        <div style={{
                    padding: '10px', 
                    minHeight: '40px', 
                    borderRadius: '10px', 
                    backgroundColor: '#EEEEEE', 
                    border: '1px solid #CBD5E1'
                    }}>
                        <text style = {{fontFamily: 'Asap', color: '#000'}}>
                        {this.state.SIFormData?.attributes?.cosnginee_country}
                        </text>
                    </div>
                      </Grid>
        
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        className="vesselTextfieldgrid"
                      >
                        <Typography className="vesselTextfieldtext">
                        Consignee Email address
                        </Typography>
                        <div style={{
                    padding: '10px', 
                    minHeight: '40px', 
                    borderRadius: '10px', 
                    backgroundColor: '#EEEEEE', 
                    border: '1px solid #CBD5E1'
                    }}>
                        <text style = {{fontFamily: 'Asap', color: '#000'}}>
                        {this.state.SIFormData?.attributes?.cosnginee_email}
                        </text>
                    </div>
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        className="vesselTextfieldgrid"
                      >
                        <Typography className="vesselTextfieldtext">
                        Consignee Contact Number
                        </Typography>
                        <PhoneInput
                          country={"sg"}
                          placeholder={""}
                          value={`${this.state.SIFormData?.attributes?.cosnginee_phone_number}`}
                          searchPlaceholder="Search for countries"
                          data-testid="ConsigneeContact"
                          disabled
                          inputProps={{
                            required: true,
                            id: "ConsigneeContact",
                            name: "ConsigneeContact",
                          }}
                          countryCodeEditable={false}
                          enableSearch={true}
                          jumpCursorToEnd={true}
                          inputClass={`PhoneInputPackgeClass`}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        className="vesselTextfieldgrid"
                      >
                        <Typography className="vesselTextfieldtext">
                          TAX ID {" "} <span style={{fontWeight: 200, fontSize: '14px', textTransform: 'capitalize', marginLeft: '5px'}}>(optional)</span>
                        <LightTooltip
                            title="Full legal name of your organisation as registered with the local authority."
                            placement="right"
                            className="tooltip"
                          >
                            <InfoOutlinedIcon className="infoIcon" style={{fontSize:"20px",paddingLeft:"5px"}} />
                          </LightTooltip>
                        </Typography>
                        <div style={{
                    padding: '10px', 
                    minHeight: '40px', 
                    borderRadius: '10px', 
                    backgroundColor: '#EEEEEE', 
                    border: '1px solid #CBD5E1'
                    }}>
                        <text style = {{fontFamily: 'Asap', color: '#000'}}>
                        {this.state.SIFormData?.attributes?.cosnginee_tax_id}
                        </text>
                    </div>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )
          }

            const handleContainerData = (
                index:number,
                DynamicValues:{
                    container_number: string,
                    shipper_container_type: string,
                    shipper_container_size: string,
                    shipper_container_codes: any,
                    seal_number: string,
                    hs_code: string,
                    packing_type: string,
                    package_count: string,
                    gwt: string,
                    cargo_description: string,
                }
              ) => {
                return (
                  <Grid container>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      className="vesselTextfieldgrid"
                    >
                      <Typography className="vesselTextfieldtext">
                      Container Number
                      </Typography>
                      <div style={{
                    padding: '10px', 
                    minHeight: '40px', 
                    borderRadius: '10px', 
                    backgroundColor: '#EEEEEE', 
                    border: '1px solid #CBD5E1'
                    }}>
                        <text style = {{fontFamily: 'Asap', color: '#000'}}>
                        {DynamicValues?.container_number}
                        </text>
                    </div>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      className="vesselTextfieldgrid"
                    >
                      <Typography className="vesselTextfieldtext">
                      Container Status
                      </Typography>
                      <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}><text style = {{fontFamily: 'Asap', color: '#000'}}>
                      {DynamicValues?.shipper_container_type}</text></div>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      className="vesselTextfieldgrid"
                    >
                      <Typography className="vesselTextfieldtext">
                      Container Size 
                      </Typography>
                      <div style={{
                    padding: '10px', 
                    minHeight: '40px', 
                    borderRadius: '10px', 
                    backgroundColor: '#EEEEEE', 
                    border: '1px solid #CBD5E1'
                    }}>
                        <text style = {{fontFamily: 'Asap', color: '#000'}}>
                        {DynamicValues?.shipper_container_size}
                        </text>
                    </div>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      className="vesselTextfieldgrid"
                    >
                      <Typography className="vesselTextfieldtext">
                      Container Type
                      </Typography>
                      <div style={{padding: '10px', minHeight: '40px', borderRadius: '10px', backgroundColor: '#EEEEEE', border: '1px solid #CBD5E1'}}>
                        {DynamicValues?.shipper_container_codes?.map((item: any) => {
                            return(
                              <text style = {{fontFamily: 'Asap', color: '#000', padding: '7px 15px', backgroundColor: '#CDCDCD', borderRadius: '15px', marginRight: '10px'}}>{item}</text>
                            )})}</div>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      className="vesselTextfieldgrid"
                    >
                      <Typography className="vesselTextfieldtext">
                      Seal Number
                      </Typography>
                      <div style={{
                    padding: '10px', 
                    minHeight: '40px', 
                    borderRadius: '10px', 
                    backgroundColor: '#EEEEEE', 
                    border: '1px solid #CBD5E1'
                    }}>
                        <text style = {{fontFamily: 'Asap', color: '#000'}}>
                        {DynamicValues?.seal_number}
                        </text>
                    </div>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      className="vesselTextfieldgrid"
                    >
                      <Typography className="vesselTextfieldtext">
                      HS Code
                      </Typography>
                      <div style={{
                    padding: '10px', 
                    minHeight: '40px', 
                    borderRadius: '10px', 
                    backgroundColor: '#EEEEEE', 
                    border: '1px solid #CBD5E1'
                    }}>
                        <text style = {{fontFamily: 'Asap', color: '#000'}}>
                        {DynamicValues?.hs_code}
                        </text>
                    </div>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      className="vesselTextfieldgrid"
                    >
                      <Typography className="vesselTextfieldtext">
                      Packing Type
                      </Typography>
                      <div style={{
                    padding: '10px', 
                    minHeight: '40px', 
                    borderRadius: '10px', 
                    backgroundColor: '#EEEEEE', 
                    border: '1px solid #CBD5E1'
                    }}>
                        <text style = {{fontFamily: 'Asap', color: '#000'}}>
                        {DynamicValues?.packing_type}
                        </text>
                    </div>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      className="vesselTextfieldgrid"
                    >
                      <Typography className="vesselTextfieldtext">
                      Package Count
                      </Typography>
                      <div style={{
                    padding: '10px', 
                    minHeight: '40px', 
                    borderRadius: '10px', 
                    backgroundColor: '#EEEEEE', 
                    border: '1px solid #CBD5E1'
                    }}>
                        <text style = {{fontFamily: 'Asap', color: '#000'}}>
                        {DynamicValues?.package_count}
                        </text>
                    </div>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      className="vesselTextfieldgrid"
                    >
                      <Typography className="vesselTextfieldtext">
                      GWT (kgs)
                      </Typography>
                      <div style={{
                    padding: '10px', 
                    minHeight: '40px', 
                    borderRadius: '10px', 
                    backgroundColor: '#EEEEEE', 
                    border: '1px solid #CBD5E1'
                    }}>
                        <text style = {{fontFamily: 'Asap', color: '#000'}}>
                        {DynamicValues?.gwt}
                        </text>
                    </div>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      className="vesselTextfieldgrid"
                    >
                      <Typography className="vesselTextfieldtext">
                      Cargo Description
                      </Typography>
                      <div style={{
                    padding: '10px', 
                    minHeight: '40px', 
                    borderRadius: '10px', 
                    backgroundColor: '#EEEEEE', 
                    border: '1px solid #CBD5E1'
                    }}>
                        <text style = {{fontFamily: 'Asap', color: '#000'}}>
                        {DynamicValues?.cargo_description}
                        </text>
                    </div>
                    </Grid>
                  </Grid>
                );
              }


          const renderDynamicForm = () => {
            return(
              <>
                {this.state.shipper_container_details?.map((item: {
                            container_number: string,
                            shipper_container_type: string,
                            shipper_container_size: string,
                            shipper_container_codes: any,
                            seal_number: string,
                            hs_code: string,
                            packing_type: string,
                            package_count: string,
                            gwt: string,
                            cargo_description: string,
                          }, index: number) => {
                            return(
                                <Accordion
                                  key={`${String.fromCharCode(94 + index)} this.state.containers`}
                                  className="submittingAccordiongrid"
                                  expanded={true}
                                >
                                  <AccordionSummary
                                    aria-controls={`panel${index}-content`}
                                    id={`panel${index}-header`}
                                  >
                                        <Grid container>
                                          <Grid
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            className="vesselInformationtext"
                                          >
                                            <Typography className="vesselInformationtext">
                                            Container Details
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {handleContainerData(index,item)}
                                  </AccordionDetails>
                                </Accordion>
                              )
                          })}
              </>
            )
          }


           const renderViewSIForm = () => {
            return(
                <>
                    <Grid item lg={12} md={12} sm={12} xs={12} className="submittinggrid" >
                    {handleVesselinformation()}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} className="submittingSecondgrid">
                    <Accordion
                        expanded={this.state.shipperAccordion}
                        className="submittingAccordiongrid"
                    >
                        <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        {handleShippersummary()}
                        </AccordionSummary>
                        <AccordionDetails className="accordionDetails">
                        {handleShipperData()}
                        </AccordionDetails>
                    </Accordion>
                    </Grid>
                    {ConsigneeAccordionRender()}
                    <Grid item lg={12} md={12} sm={12} xs={12} className="submittingSecondgrid">
                    {renderDynamicForm()}
                    </Grid>
                    </>
            )
           }

        return (
            <>
            <CustomBoxWrapper >
                <Stepper
                    activeStep={activeStep}
                    orientation="vertical"
                    connector={ activeConnector ? <ColorlibConnector1  style={{ padding: "0px",height:15 }} /> : <ColorlibConnector  style={{ padding: "0px",height:15 }} />}
                >
                    {steps.slice(0,length).map((step, index) => (
                        
                        
                        <Step key={step.label}>

                            <StepLabel StepIconComponent={props =><ColorlibStepIcon {...props} activeStepData={activeStep} stepIndex={index} Booking={booking.attributes}/>} style={index == 3 && booking.attributes?.draft_file_status ? {alignItems: 'flex-start'} :{ alignItems: 'flex-start' }} icon={index + 1}>

                                     <BoxModel >
                                       {index==0&&<Box style={{ backgroundColor: (activeStep === index&&!booking.attributes.co_authorizer) ? '#FDF2E0' :(activeStep === index&&booking.attributes.co_authorizer) ? '#35D261' : activeStep > index ? '#35D261' : '#ECECEC', color: (activeStep === index&&!booking.attributes.co_authorizer) ? '#EE8F00' : 'black',border:  activeStep === index ? '1px solid #FDF2E0' : activeStep > index ? "1px solid #18B80A":"1px solid #000",display: 'flex'}} className={index == 0 ? "stepLabelboxone" : "stepLabelbox"} >
                                        <Typography className="stepLabelTable" >{step.label}</Typography>
                                        {(activeStep === 0 && index === 0 && !booking.attributes.co_authorizer && (booking.attributes.booking_confirmation_status !== "Pending Confirmation" && booking.attributes.booking_confirmation_status !== "Booking Cancelled"))  ? (<div><button className={ CheckValidation() ? "stepLabelDisableButton":"stepLabelButton"}  disabled={CheckValidation()} style={{padding:'15px 12px 8px 34px',display:'flex',backgroundColor: CheckValidation() ? 'grey': length===3 ?'#DC4923':CheckValidation() ? 'grey':'#EE8F00',textTransform:'uppercase',cursor:'pointer'}} onClick={this.props.handleNominationModal}><span style={{whiteSpace:'nowrap'}}>{ButtonTitle} </span> <span><img src={actionLeft}/></span></button> </div>) : (activeStep>index)&&(index>0&&index<4) ?(<div className="DropDownData" ><img onClick={()=>this.DropDownValue(index)} src={this.state.selectValue==index? arrowUp1:Dropdown}/></div>):null}
                                       </Box>}
                                       
                                       {index == 1 && (
                                              <>
                                                <Box
                                                  style={{
                                                    backgroundColor:
                                                      activeStep === index && !booking.attributes.booking_references_status
                                                        ? '#FDF2E0'
                                                        : activeStep === index && booking.attributes.booking_references_status
                                                        ? '#35D261'
                                                        : activeStep > index
                                                        ? '#35D261'
                                                        : '#ECECEC',
                                                    color:
                                                      activeStep === index && !booking.attributes.booking_references_status
                                                        ? '#EE8F00'
                                                        : 'black',
                                                    border:
                                                      activeStep === index
                                                        ? '1px solid #FDF2E0'
                                                        : activeStep > index
                                                        ? '1px solid #18B80A'
                                                        : '1px solid #000',
                                                    display: 'flex',
                                                  }}
                                                  className={"stepLabelbox"}
                                                >
                                                  <Typography className="stepLabelTable">{step.label}</Typography>
                                                  {activeStep === 1 && index === 1 && !booking.attributes.booking_references_status ? (
                                                    <div>
                                                      <button
                                                        className={CheckValidation() ? "stepLabelDisableButton" : "stepLabelButton"}
                                                        disabled={CheckValidation()}
                                                        style={{
                                                          padding: '15px 12px 8px 34px',
                                                          display: 'flex',
                                                          backgroundColor: CheckValidation()
                                                            ? 'grey'
                                                            : length === 3
                                                            ? '#DC4923'
                                                            : CheckValidation()
                                                            ? 'grey'
                                                            : '#EE8F00',
                                                          textTransform: 'uppercase',
                                                          cursor: 'pointer',
                                                        }}
                                                        onClick={this.props.handleNominationModal}
                                                      >
                                                        <span style={{ whiteSpace: 'nowrap' }}>{ButtonTitle}</span>
                                                        <span>
                                                          <img src={actionLeft} />
                                                        </span>
                                                      </button>
                                                    </div>
                                                  ) : (
                                                    activeStep > index && 
                                                      <div className="DropDownData">
                                                        <img
                                                          onClick={() => this.BookingRefrenceShow()}
                                                          src={this.state.activeBookingReference ? arrowUp1 : Dropdown}
                                                        />
                                                      </div>
                                                    
                                                  )}
                                                </Box>

                                                {this.state.activeBookingReference && (
                                                  <div className="accordianData">
                                                    <Typography className="bookingRefence">
                                                      Booking Reference No. : {booking?.attributes?.booking_reference_number}
                                                    </Typography>
                                                  </div>
                                                )}
                                              </>
                                            )}

                                      {index === 2 && (
                                        <>
                                          <Box
                                            style={{
                                              backgroundColor:
                                                length === 3 && index === 2
                                                  ? '#FCECED'
                                                  : activeStep === index && !booking.attributes.draft_file_status
                                                  ? '#FDF2E0'
                                                  : activeStep === index && booking.attributes.draft_file_status
                                                  ? '#35D261'
                                                  : activeStep > index
                                                  ? '#35D261'
                                                  : '#ECECEC',
                                              color:
                                                length === 3 && index === 2
                                                  ? '#DC4923'
                                                  : activeStep === index && !booking.attributes.draft_file_status
                                                  ? '#EE8F00'
                                                  : 'black',
                                              border:
                                                length === 3 && index === 2
                                                  ? '1px solid #FCECED'
                                                  : activeStep === index
                                                  ? '1px solid #FDF2E0'
                                                  : activeStep > index
                                                  ? '1px solid #18B80A'
                                                  : '1px solid #000',
                                              display: 'flex'
                                            }}
                                            className="stepLabelboxs"
                                          >
                                            <Typography className="stepLabelTable1"
                                              style={
                                                length === 3 && index === 2 ? {backgroundColor: '#fcd7d7', borderTopLeftRadius: '23px', borderBottomLeftRadius: '23px'}
                                                : activeStep === index && !booking.attributes.draft_file_status ? {backgroundColor: '#FDF2E0', borderRadius: '23px'}
                                                : activeStep === index && booking.attributes.draft_file_status ? {backgroundColor: "#35D261", borderRadius: '23px'}
                                                : activeStep > index ? {backgroundColor: "#35D261", borderRadius: '23px'}: {backgroundColor: "#ECECEC", borderRadius: '23px'}
                                              }

                                            >{step.label}</Typography>
                                            {((booking.attributes.submit_si_status &&
                                              !booking.attributes.draft_file_status) ||
                                              (index === 2 && length === 3)) ? (
                                              <div>
                                                <button
                                                  className={
                                                    CheckValidation()
                                                      ? 'stepLabelDisableButton'
                                                      : 'stepLabelButton'
                                                  }
                                                  disabled={CheckValidation()}
                                                  style={{
                                                    padding: '15px 12px 8px 34px',
                                                    display: 'flex',
                                                    backgroundColor: CheckValidation()
                                                      ? 'grey'
                                                      : length === 3
                                                      ? '#DC4923'
                                                      : CheckValidation()
                                                      ? 'grey'
                                                      : '#EE8F00',
                                                    textTransform: 'uppercase',
                                                    cursor: 'pointer',
                                                    borderTopLeftRadius: length === 3 ? '0px' : '23px',
                                                    borderBottomLeftRadius: length === 3 ? '0px' : '23px'
                                                  }}
                                                  onClick={this.props.handleNominationModal}
                                                >
                                                  <span style={{ whiteSpace: 'nowrap' }}>{ButtonTitle} </span>{' '}
                                                  <span>
                                                    <img src={actionLeft} />
                                                  </span>
                                                </button>{' '}
                                              </div>
                                            ) : (booking.attributes.draft_file_status) ? (
                                              <div className="DropDownData">
                                                <img
                                                  onClick={() => this.DropDownValue(index)}
                                                  src={this.state.selectValue === index ? arrowUp1 : Dropdown}
                                                />
                                              </div>
                                            ) : null}
                                          </Box>

                                          {parseInt(this.state.selectValue) === index && index === 2 && (
                                            <div
                                              className="accordianData"
                                              onClick={() => window.open(booking?.attributes?.draft_bl[booking?.attributes?.draft_bl?.length - 1])
                                              }
                                            >
                                              <img src={pdf} alt="upload" className="SmallIcon" />{' '}
                                              <span className="nominationLinktext">
                                                {booking?.attributes?.draft_bl_file_name[
                                                  booking?.attributes?.draft_bl_file_name.length - 1
                                                ]}
                                              </span>
                                            </div>
                                          )}
                                        </>
                                      )}
                                       {index==3&& <><Box style={{ backgroundColor: (activeStep === index&&!booking.attributes.invoice_status) ? '#FDF2E0' :(activeStep === index&&booking.attributes.invoice_status) ? '#35D261' : activeStep > index ? '#35D261' : '#ECECEC', color:  (activeStep === index&&!booking.attributes.invoice_status) ? '#EE8F00' : 'black',border:  activeStep === index ? '1px solid #FDF2E0' : activeStep > index ? "1px solid #18B80A":"1px solid #000",display: 'flex'}} className={"stepLabelbox"} >
                                        <Typography className="stepLabelTable" >{step.label}</Typography>                  
                                          {(booking.attributes.invoice_status) ?
                                          (<div className="DropDownData" ><img onClick={()=>this.ShowInvoice()} src={this.state.activeInvoice? arrowUp1:Dropdown}/></div>)
                                          :null}
                                       </Box>
                                       {this.state.activeInvoice&&(<><div  className="accordianData" onClick={()=>
                                        this.downloadFileFromMinio(booking?.attributes?.upload_invoice, booking?.attributes?.upload_invoice_file_name)
                                        } >
                                         <img src={pdf} alt="upload" className="SmallIcon"/> <span className="nominationLinktext">{booking?.attributes?.upload_invoice_file_name}</span>
                                        </div>
                                        <Typography className="invoiceTerms">
                                          <text className="termsText">{booking?.attributes?.term}</text>
                                          </Typography>
                                        </>)}
                                       </>
                                       }

                                       {index==4&&<Box style={{ backgroundColor:  activeStep === index ? '#FDF2E0' : activeStep > index ? '#35D261' : '#ECECEC', color:  activeStep === index ? '#EE8F00' : 'black',border:  activeStep === index ? '1px solid #FDF2E0' : activeStep > index ? "1px solid #18B80A":"1px solid #000",display: 'flex'}} className={"stepLabelbox"} >
                                        <Typography className="stepLabelTable" >{step.label}</Typography>
                                       </Box>}

                                       {index==5&&<><Box style={{ backgroundColor:  activeStep === index ? '#FDF2E0' : activeStep > index ? '#35D261' : '#ECECEC', color:  activeStep === index ? '#EE8F00' : 'black',border:  activeStep === index ? '1px solid #FDF2E0' : activeStep > index ? "1px solid #18B80A":"1px solid #000",display: 'flex'}} className={"stepLabelbox"} >
                                        <Typography className="stepLabelTable" >{step.label}</Typography>
                                        {(booking.attributes.final_bl_status) ?
                                          (<div className="DropDownData" ><img onClick={()=>this.showFinalBL()} src={this.state.activeFinalDraft? arrowUp1:Dropdown}/></div>)
                                          :null}
                                       </Box>
                                       {this.state.activeFinalDraft&&(<div  className="accordianData" onClick={()=>
                                        this.downloadFileFromMinio(booking?.attributes?.final_bl, booking?.attributes?.final_bl_file_name)
                                        } >
                                         <img src={pdf} alt="upload" className="SmallIcon"/> <span className="nominationLinktext">{booking?.attributes?.final_bl_file_name}</span>
                                        </div>)}
                                       </>
                                       }
                                      


                                      

                                         {(index === 2&&booking?.attributes?.draft_bl_status==false&&booking?.attributes?.draft_file_status==false)&&<Typography className="draftRevision"> <br/> Request Revision ({moment(booking?.attributes?.verified_draft_bl_at).format('D MMM YY').toUpperCase()})<br/>
                                        {booking?.attributes?.request_revision}.</Typography>}
                                      

                                    {(activeStep >= index)&& (<div>
                                        {step?.container && step?.container.map((item, index2) => (
                                            <div > 
                                                <div style={{ height: (index==2&&booking.attributes?.draft_file_status && parseInt(this.state.selectValue)!==index)? "30px": (index==2&&booking.attributes?.draft_bl_status===false && parseInt(this.state.selectValue)!==index)? "0px":(index==1&&this.state.activeBookingReference) ? "60px": (index==3&&this.state.activeInvoice) ? '70px' :  (index==3&&this.state.activePaymentConf) ? '55px' : ((parseInt(this.state.selectValue) === index && index === 2)) ? "50px" : "30px", marginLeft: -36,marginTop:(index==1&&this.state.activeBookingReference) ?-30 : (index==3&&this.state.activeInvoice) ?-55 : (index==3&&this.state.activePaymentConf) ?-35 :((parseInt(this.state.selectValue)==index)&&(index==2)) ?-40 :-5,borderLeft:activeStep === index ? '2px solid #EE8F00' : activeStep > index ? '2px solid #35D261' : '2px solid #ECECEC' }} />
                                                <Step key={item} style={{ marginLeft: -60,marginTop:-7 }}>
                                                    <StepLabel
                                                         StepIconComponent={props => <ColorlibStepIcon2 {...props} activeStepData={activeStep} stepIndex={index2}  booking={booking} activeSI={this.state.activeSI}  activePaymentConf={this.state.activePaymentConf} label={step.label} style={{minHeight:80}} />}icon={index + 1} >
                                                       {step.label=='Authorize Container Operator'&&(
                                                         <>
                                                            {index2==0&&(
                                                                <Box style={{ backgroundColor: booking.attributes.is_nominated ? '#F3FCF6': '#FDF2E0', color: booking.attributes.is_nominated ? '#18B80A' : '#D58000', border: booking.attributes.is_nominated ? "1px solid #35D261" : '1px solid #FDF2E0',display:'flex' }} className={index == 0 ? "stepLabelboxoneChild" : "stepLabelbox"} >
                                                                    <Typography className="stepLabel"   style={{ color:booking.attributes.is_nominated? '#18B80A': '#D58000',fontWeight:booking.attributes.is_nominated ? 800:500,padding:'20px',width:'80%'}}>{item}</Typography>                                                   
                                                                </Box>)}
                                                        </> )
                                                        }
                                                             {(step.label=='Issue Booking Reference Number')&&<>                           
                                                        <Box style={{ backgroundColor:  booking.attributes.submit_si_status ? '#F3FCF6': booking.attributes.is_nominated ? "#FDF2E0" :"#ECECEC", border:  booking.attributes.submit_si_status ? '1px solid #35D261':"1px solid #FDF2E0" ,display:'flex' }} className={"stepLabelboxoneChild"} >
                                                            <Typography className="stepLabel"   style={{ color:  booking.attributes.submit_si_status? '#18B80A': booking.attributes.is_nominated ? '#D58000' : '#000',fontWeight:booking.attributes.is_nominated ? 600:500,padding:'20px' }}>{item}</Typography>
                                                            {(activeStep >index) && this.state.shippingDataAvailable ?(<div className="DropDownDataChild"  style={{width:'20%'}} ><img onClick={()=>this.ActiveSI()} src={(this.state.activeSI)? arrowUp1:Dropdown}/></div>):null}
                                                        </Box>
                                                        {(this.state.activeSI)&&<div  className="accordianDataSub" >
                                                        <span className="nominationLinktext" onClick={()=> openSIViewForm()}>View Shipping Instruction Form</span>
                                                        </div>}
                                                        </>
                                                        }

                                                        {(step.label=='Prepare Draft B/L'&&(booking.attributes?.draft_file_status!==false || booking.attributes?.draft_bl_status!==false))&&
                                                        <Box style={{ backgroundColor: booking.attributes.draft_bl_status  ? '#F3FCF6' : booking.attributes.submit_si_status ? "#FDF2E0" :"#ECECEC", color: booking.attributes.draft_bl_status  ? '#D58000' : booking.attributes.submit_si_status ? "#18B80A" :"#000",border: booking.attributes.draft_bl_status  ? '1px solid #35D261' : booking.attributes.submit_si_status ? '1px solid #FDF2E0':"1px solid #000",display:'flex'}} className={index == 2 ? "stepLabelboxoneChild" : "stepLabelbox"} >
                                                            <Typography className="stepLabel"   style={{color: booking.attributes.draft_bl_status  ? '#18B80A' : booking.attributes.submit_si_status ? '#D58000' : '#000',fontWeight:booking.attributes.draft_bl_status ? 600:500,padding:'20px' }}>{item} </Typography>
                                                        </Box>}
                                                        {step.label=='Share Invoice'&&<>
                                                        <Box style={{ backgroundColor: booking.attributes.payment_status ? "#F3FCF6" : booking.attributes.draft_bl_status ? "#FDF2E0" :'#ECECEC', color: booking.attributes.payment_status ? "#18B80A" : booking.attributes.draft_bl_status ? "#D58000" : '#000',border: booking.attributes.payment_status ? '1px solid #35D261': booking.attributes.draft_bl_status ? '1px solid #FDF2E0':"1px solid #000",display:'flex' }} className={index == 3 ? "stepLabelboxoneChild1" : "stepLabelbox1"} >
                                                            <Typography className="stepLabel"   style={{ padding:'20px',fontWeight:booking.attributes.payment_status ? 600:500}}>{item}</Typography>
                                                            {(activeStep >index) && this.state.shippingDataAvailable ?(<div className="DropDownDataChild"  style={{width:'20%'}} ><img onClick={()=>this.SubCatgDropDown(index2,index)} src={(this.state.catgValue==index&&this.state.subcatgValue==index2)? arrowUp1:Dropdown}/></div>):null}
                                                        </Box>
                                                        {(parseInt(this.state.subcatgValue)==index2&&index==parseInt(this.state.catgValue))&&(<div  className="accordianData" style={{marginLeft: "60px", width: '85%'}}  onClick={()=>
                                                          this.downloadFileFromMinio(booking?.attributes?.payment_confirmation_file, booking?.attributes?.payment_file)
                                                          }>
                                                             <img src={pdf} alt="upload" className="SmallIcon"/> <span className="nominationLinktext">{booking?.attributes?.payment_file}</span>
                                                         </div>)}
                                                        </>}
                                                         </StepLabel></Step> 
                                                         </div>))}
                                    </div>)}
                                </BoxModel>

                            </StepLabel>
                        </Step> 
                    ))}
                </Stepper>
                <Box >
                </Box>
            </CustomBoxWrapper>
            {
                this.state.SIFormVisible &&
                <StyledDialogModal open={this.state.SIFormVisible}>
                    <Grid
                        item
                        className="nominationClose"
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                    >
                        <img
                        className="nominationCloseimg"
                        src={require('../../blocks/listdemandpickflow/assets/AddcloseImg.png')}
                        alt=""
                        data-testid="closeSubmittingmodal"
                        onClick={() => {
                            closeSIViewForm()
                        }}
                        />
                    </Grid>
                    <Grid container>
                        <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="submittingFirstgrid"
                        >
                        View Shipping Instructions
                        </Grid>
                        {renderViewSIForm()}
                    </Grid>
                    
              </StyledDialogModal>
            }
            </>
        );
    }
}

const webstyle = {
    stepBox: {
        backgroundColor: "#ECECEC",
        zIndex: 1,
        width: 50,
        color: "#707070",
        height: 50,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily:'asap',
        fontWeight:600
    },
    CircleIcon:{
        zIndex: 1,
        width: 40,
        height: 40,
        fontFamily:'asap',
        fontWeight:600,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft:15,    
    },
    CircleMainIcon:{
        zIndex: 1,
        width: 50,
        height: 50,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily:'asap',
        fontWeight:600,
        marginLeft:'12px',
    }
}

const CustomBoxWrapper=styled(Box)({
    marginLeft:"35px",
    "@media (max-width: 768px)": {
        marginLeft: '0px',
      },
})

export default withStyles(styles)(SellerVeticalFile);
