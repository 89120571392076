// Customizable Area Start
import React from "react";
import VesselUtilisationController from "./VesselUtilisationController";
import {
    Grid, styled,
    withStyles, Typography, 
    TextField, Button, 
    Box, LinearProgress, 
    Select, MenuItem, 
    Checkbox, ThemeProvider, 
    Dialog, FormControl, 
    InputBase, InputLabel,
    Popper, PopperProps
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Navbar from "../../../components/src/NavBar.web";
import { 
    reloadImg, 
    download, 
    blue, 
    yellow, 
    help, 
    checked, 
    unchecked, 
    Vector, 
    closeicon 
} from "./assets";
import ReactECharts from 'echarts-for-react';
import { createTheme } from '@material-ui/core/styles'
import Spinner from "../../../components/src/ProjectLoader";
import ProjectText from "../../../components/src/ProjectText";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import Autocomplete from "@material-ui/lab/Autocomplete";
// Customizable Area End

// Customizable Area Start
const useStyles = {}

const StyledInputLable = withStyles((theme) => ({
    root: {
      fontSize: "1.125rem", 
      fontWeight: 700,
       color: "#363636",
      textTransform: "uppercase",
      fontFamily: "Asap",
    },
  }))(InputLabel);
  
  const BootstrapInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 5,
      position: "relative",
       backgroundColor: theme.palette.common.white,
        border: ".0625rem solid #ced4da",
      fontSize: "13px",padding: ".625rem .75rem",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      fontFamily: "Asap",
      color: "#363636",
      "&:focus": {
        borderColor: "##DCDCD", boxShadow: "rgba(149, 157, 165, 0.2) 0rem .5rem 1.5rem",
      },
    },
  }))(InputBase);
  const DateBox = withStyles((theme) => ({
    root: {
      "& .DateRangePicker": {
      },
      "& .e-date-range-wrapper": {
        border: ".0625rem solid #ced4da !important",padding: ".3125rem .5625rem",
        marginTop: "1.4375rem !important",borderRadius: ".375rem",
        fontFamily: 'Asap !important',fontSize:"13px !important"
      },
      "& ::before, ::after": {
        background: "transparent !important"
      },
  
    },
  
    "& .e-day-span": {
      background: "yellow !important"
    }
  }))(Box);
  const DatePickerStyle = styled(DateRangePicker)({
    "& .MuiInputLabel-animated": {
      transition: "unset"
    },
    "& .DateMainClassName": {
      "& .MuiPaper-root": {
        paddingBottom: "60px !important", backgroundColor: "grey",
      }
    }
  })
  
  const DateContainer = styled(Box)({
    "& .ButtonContaienr": {
      position: "absolute",display: "flex",
      justifyContent: "flex-end",bottom: "0px",
      right: "0px",zIndex: 9,
      padding: ".625rem .375rem"
    },
    "& .DateMainClassName": {
      "& .MuiPaper-root": {
        paddingBottom: "20px",
        "& .MuiGrid-wrap-xs-nowrap":{
          "&.MuiGrid-root":{
            "& .materialui-daterange-picker-MuiList-root-59":{
              display:"none"
            }
          }
        }
      },
      "& .MuiTypography-root": {
        fontWeight: "900 !important"
      },
      "& .materialui-daterange-picker-makeStyles-highlighted-57":{
        color: "red !important",
      },
      "& ul": { 
        marginBottom: "20px"
    }
    },
  })

// Customizable Area End


class VesselUtilisation extends VesselUtilisationController {
    // Customizable Area Start
    monthlyButtons=(buttons:string[])=>{
        return(
            <div style={webStyle.buttonGroup}>
            {Array.isArray(buttons) &&buttons.map((button) => (
                <Button
                    id="buttonClick"
                    key={button}
                    data-testid="monthlyButton"
                    onClick={() => this.handleButtonClick(button)}
                    style={{
                        ...webStyle.selectButton,
                        backgroundColor:
                            this.state.selectedButton === button ? '#1653DD' : '#EEF2F5',
                        color: this.state.selectedButton === button ? "#FFFFFF" : "#797979",
                        minHeight: "42px"
                    }}
                >
                    {button}
                </Button>
            ))}
        </div>
        )
    }
    monthly=()=>{
        return(
            <div style={webStyle.mainHeadingBox}>
            <div>
                <div style={{ display: "flex" }}>
                    <Typography style={webStyle.monthly}>
                        Service Overview
                    </Typography>
                    {this.state.ServicedataMessagestate ?
                    <Button style={{ background: "#8A99BB", cursor: "none", color: "#FFFFFF", fontFamily: "Asap", fontSize: "14px", fontWeight: 600, marginLeft: "10px" }}>
                       {this.state.ServicedataMessage?.month}
                    </Button>
                      :
                    <Button style={{ background: "#8A99BB", color: "#FFFFFF",cursor: "none",fontFamily: "Asap", fontSize: "14px", fontWeight: 600, marginLeft: "10px" }}>
                       {this.state.ServicedatareponseMessage?.month}
                    </Button>
                   }
                </div>
                <Typography style={webStyle.subHeading}>View all the service overall data</Typography>

            </div>

        </div>
        )
    }
    toolTipmodal=()=>{
        return(
            <DiologStyle open={this.state.openDialog}
            onClose={this.handleDialogCLose}
        >
            <div style={{ textAlign: "end", padding: "10px", cursor: "pointer" }}>
                <img src={closeicon} id="handleDialogCLose" onClick={() => this.handleDialogCLose()} />
            </div>
            <div style={{ padding: "50px" }}>
                <Typography style={webStyle.popHeading}>Trade Route</Typography>
                <Grid container>
                    <Grid item xs={12} sm={12} md={3} style={{ marginTop: "20px", borderRight: "1px solid #DADADA" }}>
                        <Typography style={webStyle.listTrade}>LIST OF TRADE ROUTE</Typography>
                        <ScrollBoxFirst>
                            <div style={{ height: "410px", overflowY: "scroll", direction: "rtl" }}>
                                <div style={{ direction: "ltr", paddingLeft: "30px" }}>
                                    {Array.isArray(this.state.TooltipData) &&this.state.TooltipData?.map((data) => (
                                        <Typography
                                            key={data.id}
                                            className="ButtonText"
                                            data-testid="handleItemclick"
                                            onClick={() => this.handleItemClick(data.id)}
                                            style={webStyle.listContent}
                                        >
                                            {data.attributes.trade_route_classification}
                                        </Typography>
                                    ))}
                                </div>
                            </div>
                        </ScrollBoxFirst>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} style={{ paddingLeft: "50px", paddingRight: "50px" }}>
                        <ScrollBox>
                            <div style={{ height: "503px", overflowY: "scroll", paddingLeft: "20px", }}>
                                {Array.isArray(this.state.TooltipData) &&this.state.TooltipData?.map((data) => (
                                    <div key={data.id} >
                                         <Typography style={webStyle.rightPopHeading}>{data.attributes.trade_route_classification}</Typography>
                                        <Typography style={webStyle.rightPopsub}>{data.attributes.description}</Typography>
                                    </div>
                                ))}
                            </div>
                        </ScrollBox>
                    </Grid>
                </Grid>
            </div>
        </DiologStyle>
        )
    }
    yearFormatter(value: string, index: number) {
        const month = value;
        const year = this.state.yearAverageState;
        return month + '\n' + year;
    }

     formatXAxisData(item: any) {
        let formattedString = `${item.year}\n${item.month}`;

        if (item.hasOwnProperty("week")) {
            formattedString += `-(Week${item.week})\n${new Date(item.from_date).toLocaleDateString()}-${new Date(item.to_date).toLocaleDateString()}`;
        }

        return formattedString;
    }
     determineTimePeriod = (response:any) => {
        if (response.length > 0 && "week" in response[0]) {
            return "Weekly";
        } else {
            return "Monthly";
        }
    };
    percentageBox=()=>{
        return(
            <PercentBox container spacing={2}>
            <Grid item xs={12} sm={12} md={4} style={webStyle.inboundBox}>
                <Typography data-testid="inboundPercentdata" style={webStyle.percent}>{this.state.inboundPercentdata.percentage==null?<span style={{fontSize:"14px"}}>No data</span>:this.state.inboundPercentdata.percentage+"%"}</Typography>
                <Typography style={webStyle.percentType}>{this.state.inboundPercentdata.title}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} style={webStyle.outboundBox}>
                <Typography data-testid="outboundPercentdata" style={webStyle.percent}>{this.state.outboundPercentdata.percentage==null?<span style={{fontSize:"14px"}}>No data</span>:this.state.outboundPercentdata.percentage+"%"}</Typography>
                <Typography style={webStyle.percentType}>{this.state.outboundPercentdata.title}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} style={webStyle.outboundBox}>
                <Typography data-testid="outboundWeightPercentdata" style={webStyle.percent}>{this.state.outboundWeightPercentdata.percentage==null?<span style={{fontSize:"14px"}}>No data</span>:this.state.outboundWeightPercentdata.percentage+"%"}</Typography>
                <Typography style={webStyle.percentType}>{this.state.outboundWeightPercentdata.title}</Typography>
            </Grid>
        </PercentBox>
        )
    }

    filterFileds=(popperProps: Partial<PopperProps>)=>{
        return(
            <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <TextFieldText style={webStyle.textFieldHeading}>TRADE ROUTE</TextFieldText>
                <CustomAutoSelete
                style={webStyle.autoComplete}
                        id="combo-box-demo"
                        options={Array.isArray(this.state.TraderouteData) ? this.state.TraderouteData.map(data => data.route) : []}
                        value={this.state.tradeSelect}
                        data-testid="ChangetradeRoute"
                        onChange={(event, newValue) => this.handleChangetradeRoute(event, newValue)}
                        renderInput={(params) => <TextField {...params} placeholder={this.state.tradeSelect} variant="outlined" />}
                        fullWidth
                        PopperComponent={(props) => <Popper {...props} {...popperProps} />}
                        renderOption={(option:string|unknown) => ( 
                            <option
    
                                style={{
                                        cursor: 'pointer',
                                        color: "#1E1E1E",
                                        fontSize: "14px",
                                        fontWeight: 600,
                                        fontFamily: "Asap",
                                }}
                            >
                                {option as string}
                            </option>
                        )}
                    />
            </Grid>
            <Grid item xs={12} md={3}>
                <TextFieldText style={webStyle.textFieldHeading}>SERVICE</TextFieldText>
                    <CustomAutoSelete
                        id="combo-box-demo"
                        data-testid="ChangeService"
                        options={Array.isArray(this.state.ServiceData) ? this.state.ServiceData.map(data => data.service) : []}
                        value={this.state.serviceSelect}
                        onChange={(event, newValue) => this.handleChangeService(event, newValue)}
                        style={webStyle.autoComplete}

                        renderInput={(params) => <TextField {...params} placeholder={this.state.serviceSelect} variant="outlined" />}
                        fullWidth
                        PopperComponent={(props) => <Popper {...props} {...popperProps} />}
                        renderOption={(option:string|unknown) => ( 
                            <option
    
                                style={{
                                        cursor: 'pointer',
                                        color: "#1E1E1E",
                                        fontSize: "14px",
                                        fontWeight: 600,
                                        fontFamily: "Asap",
                                }}
                            >
                                {option as string}
                            </option>
                        )}
                    />
            </Grid>
            <Grid item xs={12} md={3}>
                <TextFieldText style={webStyle.textFieldHeading}>PREVIOUS PORT LOCATION</TextFieldText>
               
                <CustomAutoSelete
                        id="combo-box-demo"
                        options={Array.isArray(this.state.PortlocationData) ? this.state.PortlocationData.map(data => data.previos_port) : []}
                        value={this.state.PortlocationSelect}
                        onChange={(event, newValue) => this.handleChangePortlocation(event, newValue)}
                        data-testid="ChangePortlocation"
                        style={webStyle.autoComplete}

                        renderInput={(params) => <TextField {...params} placeholder={this.state.PortlocationSelect} variant="outlined" />}
                        fullWidth
                        PopperComponent={(props) => <Popper {...props} {...popperProps} />}
                        renderOption={(option:string|unknown) => ( 
                            <option
                                style={{
                                        cursor: 'pointer',
                                        color: "#1E1E1E",
                                        fontSize: "14px",
                                        fontWeight: 600,
                                        fontFamily: "Asap",
                                }}
                            >
                                 {option as string}
                            </option>
                        )}
                    />
            </Grid>
            <Grid item xs={12} md={3}>
                <TextFieldText style={webStyle.textFieldHeading}>DESTINATION PORT LOCATION</TextFieldText>
                <CustomAutoSelete
                        id="combo-box-demo"
                        options={Array.isArray(this.state.DestinationportData) ? this.state.DestinationportData.map(data => data.destination_port) : []}
                        value={this.state.DestinationlocationSelect}
                        onChange={(event, newValue) => this.handleChangedestinationport(event, newValue)}
                        data-testid="ChangeDestinationlocation"
                        style={webStyle.autoComplete}
                        renderInput={(params) => <TextField {...params} placeholder={this.state.DestinationlocationSelect} variant="outlined" />}
                        fullWidth
                        PopperComponent={(props) => <Popper {...props} {...popperProps} />}
                        renderOption={(option:string|unknown) => ( 
                            <option
    
                                style={{
                                        cursor: 'pointer',
                                        color: "#1E1E1E",
                                        fontSize: "14px",
                                        fontWeight: 600,
                                        fontFamily: "Asap",
                                }}
                            >
                                 {option as string}
                            </option>
                        )}
                    />
            </Grid>
        </Grid>
        )
    }

    datepicker=()=>{
        return(
            <DateRangeGrid item xs={12} sm={6}>
            <FormControl variant="standard" >
                <StyledInputLable
                    shrink
                    style={{ fontWeight: 700 }}
                    htmlFor="ETB DATE RANGE"
                >ETB DATE RANGE</StyledInputLable>
                <BootstrapInput
                    defaultValue=""
                    fullWidth
                    id="ETB DATE RANGE"
                    placeholder="MMM-YYYY - MMM-YYYY"
                    data-test-id="dateopne"
                    onClick={this.TogolFilter}
                    data-testid="TogolFilter"
                    value={this.state.StartDate !== "" && this.state.EndDate !== "" ? `${this.state.RenderStart} - ${this.state.RenderEndDate}` : ""}
                />
                <Box style={{
                    position: "absolute",
                    top: "70px",
                    right: "100px"
                }}>
                    {
                        this.state.open &&
                        <DateContainer style={{ position: "relative" }} data-test-id="DateFilterMainDiv">
                            <DatePickerStyle
                                open={this.state.open}
                                toggle={this.TogolFilter}
                                wrapperClassName="DateMainClassName"
                                className="DateMainClassName"
                                onChange={(range: DateRange) => {
                                    this.SetDateFilterData(range);
                                }}
            data-testid="SetDateFilterData"

                            />
                            <Box className="ButtonContaienr">
                                <Button style={{ marginRight: "10px" }} data-test-id="ClearFilter"  variant="text" onClick={() => {
                                    this.ClearFilterData()
                                }}>
                                    <ProjectText variant="h6" TextSize="16px" textcolor="primary">
                                        clear
                                    </ProjectText>
                                </Button>
                                <Button variant="contained" data-test-id="SelectFilter"  onClick={this.FilterDateSelect} color="primary" className="SelectButton">
                                    <ProjectText variant="h6" TextSize="16px" textcolor="white">
                                        Select
                                    </ProjectText>
                                </Button>
                            </Box>
                        </DateContainer>
                    }
                </Box>
            </FormControl>
        </DateRangeGrid>
        )
    }

     getOption() {
        if (Array.isArray(this.state.TraderouteData)) {
            return this.state.TraderouteData.map(data => data.route);
        } else {
            return [];
        }
    }
   
    render() {
        const { FeedergraphDetails } = this.state;
        const popperProps: Partial<PopperProps> = {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 8],
                },
              },
            ],
          };
        const option = {
            title: {
                text: "",
                textStyle: {
                    fontSize: 16
                },
                left: 'center',
            },
            tooltip: {
                trigger: 'item',
                backgroundColor: '#0099FF',
                formatter: function (params: {
                    dataIndex: number; value: number[];
                }) {
                    const feederInfo:string = FeedergraphDetails[params.dataIndex].feeder;
                    return '<div style="color: #FFFFFF;"><span> Service:</span> '+ feederInfo +'<br/>' +
                        '<span style="color: #FFFFFF;">Inbound:</span> ' + (params.value[1]) + '%<br/>' +
                        '<span style="color: #FFFFFF;">Outbound:</span> ' + (params.value[0]) + '%</div>';
                },
                axisPointer: {
                    type: 'cross',
                    lineStyle: {
                        color: '#33ccff',
                        type: 'dotted',
                        width: 2,
                    },
                    crossStyle: {
                        type: 'dotted',
                        color: '#4dc3ff',
                        width: 4,
                    },
                    
                }
            },
          
            grid: {
                left: '3%',
                right: '20%',
        
                bottom: '3%',
                containLabel: true
            },
            yAxis: {
                type: 'value',
                data: ['0%', '10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%'],
                axisTick: {
                    show: false
                },
                axisLabel: {
                    interval: 0,
                    textStyle: {
                        fontSize: 14,
                        color: '#1E1E1E',
                        fontWeight: 400,
                    },
                    formatter: '{value}%',
                },
                axisLine: {
                    formatter: '{value}%',
                    show: false,
                },
                splitLine: {
                    lineStyle: {
                        type: 'dashed'
                    },
                    show: true,
                },
                boundaryGap: false
            },
            xAxis: {
                type: 'value',
                data: ['0%', '10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%'],
                axisTick: {
                    show: false,
                    formatter: '{value}%'
                },
                axisLabel: {
                    interval: 0,
                    textStyle: {
                        color: '#1E1E1E',
                        fontSize: 14,
                        fontWeight: 400,
                    },
                    formatter: '{value}%',
                },
        
            },
        
            series: [
                {
                    symbolSize: 15,
                    data: this.state.FeedergraphData,
                    type: 'scatter',
                    itemStyle: {
                        color: '#7DB7FF',
                        borderColor: '#6495D2',
                        borderWidth: 1,
                    },
                    emphasis: {
                        itemStyle: {
                            borderColor: '#6495D2',
                            borderWidth: 2,
                        },
                    },
                },
               
            ]
        };
        
        const commonTooltip = {
            yAling: "top",
            trigger: 'item',
            axisPointer: { crossStyle: { type: 'shadow' } },
            formatter: function (params: { seriesName: string; data: string; }) {
                return params.seriesName + ':' + params.data;
            },
            textStyle: {
                color: '#FFFFFF',
            },
        };

       
        
          const xAxisData = this.state.monthAverageState.map(this.formatXAxisData);

          
        
            const timePeriod = this.determineTimePeriod(this.state.monthAverageState);
        const barOption = {
            legend: {
                data: [],
            },
            tooltip: {
                yAling: "top",
                trigger: 'item', axisPointer: { crossStyle: { type: 'shadow' } },
                background: "#E3A917",
                formatter: function (params: { seriesName: string; data: string; }) {
                    return params.seriesName + ':' + params.data;
                }
            },
            xAxis: {
                type: 'category',
                data:xAxisData,
                axisTick: {
                    show: false,
                    formatter: '{value}%'
                },
                axisLabel: {
                    interval: 0,
                    textStyle: {
                        color: '#1E1E1E',
                        fontSize: 14,
                        fontWeight: 400,
                        fontFamily: "Asap",
                        height:"100%",
                        lineHeight:22
                    },
                   
                },
                formatter: function (value: string) {
                    return value.replace(/<br>/g, '\n'); 
                  }
        
            },
            yAxis: {
                data: ['0%', '10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%'],
                type: 'value',
                axisLabel: {
                    interval: 0,
                    textStyle: {
                        color: '#1E1E1E',
                        fontSize: 14,
                        fontWeight: 400,
                    },
                    formatter: '{value}%',
                },
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false,
                    formatter: '{value}%'
                },
                boundaryGap: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: 'dashed'
                    }
                },
            },
            series: [
                {
                    data:this.state.inboundAverageState,
                    name: "Inbound",
                    itemStyle: {
                        color: {
                            x: 0,
                            y: 0,
                            type: 'linear',
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                { offset: 1, color: '#0099FF' },
                                { offset: 0, color: '#1653DD' },
                            ],
                        },
                        barBorderRadius: [4, 4, 0, 0],
                    },
                    type: "bar",
                    barWidth: '20%',
                    tooltip: {
                        ...commonTooltip,
                        backgroundColor: '#1653DD',
                    },
                },
                {
                    data:this.state.outboundAverageState,
                    name: "Outbound",
                    itemStyle: {
                        barBorderRadius: [4, 4, 0, 0],
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                { offset: 1, color: '#F3CC67' },
                                { offset: 0, color: '#E3A917' },
                            ],
                        },
                    },
                    type: "bar",
                    barWidth: '20%',
                    tooltip: {
                        ...commonTooltip,
                        backgroundColor: '#E3A917',
                    },
                },
            ],
        };

        const buttons = ['1M', '6M', '1YR', 'YTD'];
        const inbountOutbound = () => {
            return (
                <>
                    <Grid item xs={12} sm={4}>
                        <div style={{ marginTop: "8px", display: "flex" }}>
                            <Typography style={{ ...webStyle.inoutText, width: "none", marginRight: "5px" }}>
                                Service
                            </Typography>
                            <img src={Vector} data-testid="handleSortinservice" onClick={this.handleSortinservice} style={{ width: "16px", height: "16px" }} />
                        </div>
                    </Grid>
                </>
            )
        }
        const outbountOutbound = () => {
            return (
                <>
                    <Grid item xs={12} sm={4}>
                        <div style={{ marginTop: "8px", display: "flex" }}>
                            <Typography style={{ ...webStyle.inoutText, width: "none", marginRight: "5px" }}>
                                Service
                            </Typography>
                            <img src={Vector} data-testid="handleSortoutservice" onClick={this.handleSortoutservice} style={{ width: "16px", height: "16px" }} />
                        </div>
                    </Grid>
                </>
            )
        }
        
        return (
            <div>
                <Navbar SellerPendingScreen={true} isBuyerScreen={this.state.isBuyerRole} AppBarTransparent={false} />
                <Spinner spinnerModal={this.state.loading} />
                <div style={webStyle.outerBox}>
                    <InnerBox>
                       {this.filterFileds(popperProps)}
                       <Grid style={webStyle.clearFilter}>
                       <div data-testid="clearFilters" onClick={this.handleClearfilters} style={webStyle.downloadBox}>
                                    <ProjectText TextSize="14px" fontweight="700" style={webStyle.download}>Clear Filters</ProjectText>
                                </div>
                       </Grid>
                        <div style={webStyle.mainHeadingBox}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <ProjectText style={webStyle.monthly}>
                                    Monthly Utilization
                                </ProjectText>
                                <div data-testid="downloadfile" onClick={()=>{this.handleDownloadfile(this.state.UserSelect)}} style={webStyle.downloadBox}>
                                    <ProjectText TextSize="14px" fontweight="700" style={webStyle.download}>Download CSV</ProjectText>
                                    <img src={download} style={{ width: "10.67px", height: "10.67px", marginTop: "5px" }} />
                                </div>
                            </div>
                            <Typography style={webStyle.subHeading}>View monthly utilization on your vessels</Typography>
                            <div style={{ display: "flex", marginTop: "5px" }}>
                                <img src={reloadImg} data-testid="updatedTime" onClick={()=>{this.handleUpdatetime(this.state.loginToken,"")}} />
                                <Typography style={webStyle.udatedText}>{this.state.UpdatedtimeData}</Typography>
                            </div>
                        </div>
                        {this.percentageBox()}
                        <BarChartBox>
                            <Grid container >
                                <Grid item xs={12} sm={6} className="BarChartBoxTop">
                                    <Typography style={webStyle.inoutText}>
                                        In/Outbound
                                        Utilization (%)
                                    </Typography>
                                 {this.monthlyButtons(buttons)}
                                </Grid>
                             {this.datepicker()}
                            </Grid>

                            <div style={{ width: '100%', overflowX: 'auto', boxSizing: 'border-box' }}>
                               {this.state.MonthlyMessagestate?
                               <Box style={webStyle.monthltErrorbox} >
                               <Typography style={webStyle.monthltErrormessage}>{this.state.MonthlyMessage.message}</Typography>
                               </Box>
                                :
                                <div style={{ minWidth: '1176px' }}>
                                    <ReactECharts option={barOption} style={{ height: '500px', left: "-5%" }} />
                                </div>
                                 }
                            </div>

                            <div style={{ display: "flex", justifyContent: "center", textAlign: "center", marginTop: "20px" }}>
                                <div>
                                            <Typography style={webStyle.monthltTextChart}>{timePeriod}</Typography>
                                    
                                    <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                        <img src={blue} style={{ width: "12px", height: "12px" }} />
                                        <Typography style={webStyle.colorText}>Inbound</Typography>
                                        <img src={yellow} style={{ width: "12px", height: "12px" }} />
                                        <Typography style={webStyle.colorText}>Outbound</Typography>
                                    </div>
                                </div>
                            </div>
                        </BarChartBox>
                        <Grid container spacing={2} style={{ marginTop: "30px" }}>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <div style={{ display: "flex" }}>
                                    <Typography style={webStyle.tradeText}>Trade Route</Typography>
                                    <img src={help} style={{ marginLeft: "5px", marginTop: "-4px", cursor: "pointer" }}
                                        id="handleDialogOpen"
                                        data-test-id="handleDialogopen" 
                                        onClick={() => this.handleDialogOpen()}
                                    />
                                </div>
                                <Box style={{...webStyle.tradeBox,paddingBottom:"38px"}}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Typography style={webStyle.inoutText}>
                                            Inbound
                                            Utilization (%)
                                        </Typography>
                                        <CustomAutoSelete
                style={{...webStyle.autoComplete,width: "184px",padding: 0, height: "44px"}}
                        id="combo-box-demo"
                        options={this.getOption()}
                        value={this.state.tradeRouteSelect}
                        data-testid="tradeRouteSelect"
                        onChange={(event, newValue) => this.handleChange(event, newValue)}
                        renderInput={(params) => <TextField {...params} placeholder={this.state.tradeRouteSelect} variant="outlined" />}
                        PopperComponent={(props) => <Popper {...props} {...popperProps} />}
                        renderOption={(option:string|unknown) => ( 
                            <option
    
                                style={{
                                        cursor: 'pointer',
                                        color: "#1E1E1E",
                                        fontSize: "14px",
                                        fontWeight: 600,
                                        fontFamily: "Asap",
                                }}
                            >
                                {option as string}
                            </option>
                        )}
                    />
                                    </div>
                                    {this.state.FeederMessagestate ?
                                        <Grid style={webStyle.outBoundstyle}>
                                            <Typography style={webStyle.textStyle}>{this.state.FedderMessage.message}</Typography>
                                        </Grid>
                                        :
                                        <ReactECharts option={option}
                                            style={{ height: '500px', width: '120%' }}
                                        />
                                    }

                                    <div style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                                        <Typography style={webStyle.inoutText}>
                                            Outbound
                                            Utilization (%)
                                        </Typography>
                                    </div>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={3}>
                                <TypographyStatic>Inbound SG</TypographyStatic>
                                <Box style={{ ...webStyle.tradeBox, marginTop: "32px" }}>
                                    <Grid container spacing={2}>
                                        {inbountOutbound()}

                                        <Utilization item xs={12} sm={8}>
                                            <FirstUtilisation>
                                                <Typography style={{ ...webStyle.inoutText, width: "none", marginRight: "5px" }}>
                                                    Utilization
                                                </Typography>
                                                <img onClick={this.handleSortinbound} data-testid="handleSortinbound" src={Vector} style={{ width: "16px", height: "16px" }} />
                                            </FirstUtilisation>
                                            <Select
                                                multiple
                                                value={this.state.inboundSelect.length === this.state.ListofdirectionsData.length ? ["ALL"] : this.state.inboundSelect}
                                                data-testid="inboundSelect"
                                                onChange={(event:any)=>{this.handleChangeIn(event)}}
                                                renderValue={(selected) => (selected as string[]).join(', ')}
                                                MenuProps={{
                                                    getContentAnchorEl: null,
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "right"
                                                    },
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "right"
                                                    },
                                                    MenuListProps: {
                                                        style: {
                                                            maxHeight: 300,
                                                            paddingBlock: 0,
                                                        },
                                                    },
                                                }}
                                                variant="outlined"
                                                style={{ ...webStyle.dropdown, padding: 0, overflow: "hidden", height: "32px", }}
                                               
                                            >
                                                    {Array.isArray(this.state.ListofdirectionsData) &&this.state.ListofdirectionsData?.map((data) => {
                                                    const display=this.getDisplayName(data.direction)
                                                    return(
                                                    <MenuItem data-testid="listofdirection"  key={data.s_no} value={data.direction} style={{ ...webStyle.itemList, ...webStyle.listItem }}>
                                                       {display}
                                                        <ThemeProvider theme={theme}>
                                                            <Checkbox
                                                                checked={this.state.inboundSelect.indexOf(data.direction) > -1}
                                                                style={{ borderRadius: "10px" }}
                                                                
                                                            />
                                                        </ThemeProvider>
                                                    </MenuItem>
                                                )})}
                                            </Select>
                                        </Utilization>
                                    </Grid>
                                    {this.state.InboundSGstate?
                                   <Grid style={webStyle.outBoundstyle}>
                                   <Typography  style={webStyle.textStyle}>No data</Typography>
                               </Grid>
                                    :
                                        <div style={{ flexDirection: "column", display: "flex", marginTop: "20px" }}>
                                            {Array.isArray(this.state.InboundservicesData) && this.state.InboundservicesData?.map((ele) => (
                                                <div style={{ textAlign: "center", display: "flex", marginBottom: "10px", justifyContent: "space-between", }}>
                                                    <Typography data-testid="inboundservicesdata" style={webStyle.agsText}>{ele.service} ({ele.service_direction})</Typography>
                                                    <div style={{ position: 'relative', width: "67%", }}>
                                                        <InLinearProgress
                                                            variant="determinate"
                                                            value={ele.inbound}
                                                            style={{
                                                                borderRadius: '4px',
                                                                height: '32px',
                                                                backgroundColor: ele.inbound >= 100 ? '#0099FF' : "#EBEBEB",
                                                            }}
                                                        />
                                                        <Typography style={webStyle.percentageText}>{ele.inbound}%</Typography>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={3}>
                                <TypographyStatic>Outbound SG</TypographyStatic>
                                <Box style={{ ...webStyle.tradeBox, marginTop: "32px" }}>
                                    <Grid container spacing={2}>
                                        {outbountOutbound()}
                                        <Utilization item xs={12} sm={8}>
                                            <div style={{ marginTop: "8px", display: "flex" }}>
                                                <Typography style={{ ...webStyle.inoutText, width: "none", marginRight: "5px" }}>
                                                    Utilization
                                                </Typography>
                                                <img onClick={this.handleSortoutbound} data-testid="handleSortoutbound" src={Vector} style={{ width: "16px", height: "16px" }} />
                                            </div>
                                            <Select
                                                multiple
                                                value={this.state.outboundSelect.length === this.state.ListofdirectionsData.length ? ["ALL"] : this.state.outboundSelect}
                                                data-testid="outboundSelect"
                                                onChange={(event:any)=>{this.handleChangeOut(event)}}
                                                renderValue={(selected) => (selected as string[]).join(', ')}
                                                variant="outlined"
                                                style={{ ...webStyle.dropdown, padding: 0, overflow: "hidden", height: "32px", }}
                                                MenuProps={{
                                                    getContentAnchorEl: null,
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "right"
                                                    },
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "right"
                                                    },
                                                    MenuListProps: {
                                                        style: {
                                                            maxHeight: 300,
                                                            paddingBlock: 0,
                                                        },
                                                    },
                                                }}
                                            >
                                                {Array.isArray(this.state.ListofdirectionsData) &&this.state.ListofdirectionsData?.map((option) =>{
                                                    const displayname=this.getDisplayName(option.direction)
                                                    return (
                                                    <MenuItem data-testid="listofinbounddata"  key={option.s_no} value={option.direction} style={{ display: "flex", justifyContent: "space-between", width: "188px", ...webStyle.listItem }}>
                                                        {displayname}
                                                        <ThemeProvider theme={theme}>
                                                            <Checkbox
                                                                checked={this.state.outboundSelect.indexOf(option.direction) > -1}
                                                                style={{ borderRadius: "10px" }}
                                                            />
                                                        </ThemeProvider>
                                                    </MenuItem>
                                                )})}
                                            </Select>
                                        </Utilization>
                                    </Grid>
                                    {this.state.OutboundSGstate ?
                                        <Grid style={webStyle.outBoundstyle}>
                                            <Typography  style={webStyle.textStyle}>No data</Typography>
                                        </Grid>
                                        :

                                        <div style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
                                            {Array.isArray(this.state.OutboundservicesData) && this.state.OutboundservicesData?.map((ele) => (
                                                <div style={{ display: "flex", justifyContent: "space-between", textAlign: "center", marginBottom: "10px" }}>
                                                    <Typography data-testid="outboundservicedata" style={webStyle.agsText}>{ele.service} ({ele.service_direction})</Typography>
                                                    <div style={{ width: "67%", position: 'relative' }}>
                                                        <OutLinearProgress
                                                            variant="determinate"
                                                            value={ele.outbound}
                                                            style={{
                                                                backgroundColor: ele.outbound >= 100 ? '#E3A917' : "#EBEBEB",
                                                                borderRadius: '4px',
                                                                height: '32px',
                                                            }}
                                                        />
                                                        <Typography style={{ position: 'absolute', top: 5, left: 10, color: '#FFFFFF', fontWeight: 400, fontFamily: "Asap", fontSize: "14px" }}>{ele.outbound}%</Typography>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                        {this.monthly()}
                        <ServicesOverview container style={webStyle.tableHeadingBox}>
                            <Grid item xs={12} sm={2} style={{ textAlign: "center" }}>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Typography className="tableHeading">SERVICE CODE</Typography>
                                    <img src={Vector} data-testid="handleServicecode" onClick={this.handleServicecode} style={{ width: "16px", height: "16px", marginLeft: "5px" }} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={2} style={{ textAlign: "center" }}>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Typography className="tableHeading">DIRECTION</Typography>
                                    <img src={Vector} data-testid="handleDirection" onClick={this.handleDirection} style={{ width: "16px", height: "16px", marginLeft: "5px" }} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={2} style={{ textAlign: "center" }}>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Typography className="tableHeading">INBOUND (WEIGHT)</Typography>
                                    <img src={Vector} data-testid="handleInbound" onClick={this.handleInbound} style={{ width: "16px", height: "16px", marginLeft: "5px" }} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={2} style={{ textAlign: "center" }}>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Typography className="tableHeading">OUTBOUND (TEUs)</Typography>
                                    <img src={Vector} data-testid="handleoutbound" onClick={this.handleoutbound} style={{ width: "16px", height: "16px", marginLeft: "5px" }} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={2} style={{ textAlign: "center" }}>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Typography className="tableHeading">OUTBOUND (WEIGHT)</Typography>
                                    <img src={Vector} data-testid="handleoutboundweight" onClick={this.handleoutboundweight} style={{ width: "16px", height: "16px", marginLeft: "5px" }} />
                                </div>
                            </Grid>
                        </ServicesOverview>
                        {this.state.ServicedataMessagestate?
                        <Grid container style={{justifyContent:"center",padding:"7%"}}>
                         <Grid item style={{textAlign:"center"}}>
                        <Typography data-testid="servicelistmessage"  style={webStyle.ServicedataMessage}>{this.state.ServicedataMessage?.message}</Typography>
                         </Grid>
                        </Grid>
                        :
                        <>
                        {Array.isArray(this.state.ServicetableData) &&this.state.ServicetableData?.map((row) => (
                            <ServicesOverview container style={webStyle.tableRow}>
                                <>
                                    <Grid item xs={12} sm={2} style={{ textAlign: "center" }}>
                                        <Typography data-testid="servicetabledata"  className="tableHeading">{row.service_code}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={2} style={{ textAlign: "center" }}>
                                        <Typography className="tableHeading">{row.direction}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={2} style={{ textAlign: "center" }}>
                                        <Typography className="tableHeading">{row.inbound_weight}%</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={2} style={{ textAlign: "center" }}>
                                        <Typography style={webStyle.rowText}>{row.outbound_teu}%</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={2} style={{ textAlign: "center" }}>
                                        <Typography style={webStyle.rowText}>{row.outbound_weight}%</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={2} style={{ textAlign: "center" }}>
                                        <div style={webStyle.sellbox}  data-test-id="navigatetoseller"  onClick={this.handleNavigation}>SELL</div>
                                    </Grid>
                                </>
                            </ServicesOverview>
                        ))}
                        </>
                        }

                    </InnerBox>
                </div>
                {this.toolTipmodal()}
              
            </div>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
export { VesselUtilisation };
export default withRouter((withStyles)(useStyles)(VesselUtilisation));
const theme = createTheme({
    props: {
        MuiCheckbox: {
            icon: <img src={unchecked} />,
            checkedIcon: <img src={checked} />,
        },
    },
})
const TypographyStatic = styled(Typography)({
    color: "#1E1E1E",
    fontFamily: "Asap" as "Asap",
    fontSize: "28px",
    fontWeight: 700,
    lineHeight: "32.09%",
    '@media (max-width:1280px)': {
       marginTop: "20px"
    },
})
const Utilization = styled(Grid)({
    display: "flex",
    justifyContent: "space-between",
})
const ServicesOverview = styled(Grid)({
    '@media (max-width:768px)': {
        flexWrap: "inherit",
        overflowX: "auto",
        gap: "18px",
        padding: "0px 10px"
    },
    "& .tableHeading": {
        color: "#1E1E1E",
        fontSize: "14px",
        fontWeight: 700,
        fontFamily: "Asap" as "Asap",
        lineHeught: "16px",
        '@media (max-width:768px)': {
            fontSize: "12px"
        },
    }
})
const FirstUtilisation = styled(Box)({
    marginTop: "8px", 
    display: "flex"
})
const ScrollBox = styled(Box)({
    "& div::-webkit-scrollbar": {
        width: "5px", 
        background: "#DADADA",
        paddingLeft: "-20px"
    },
    "& div::-webkit-scrollbar-track": {
        border: '1px solid #DADADA',
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
        borderRadius: "10px",
        background: "#DADADA"
    },
    "& div::-webkit-scrollbar-thumb": {
        backgroundColor: "#797979",
        borderRadius: "10px"
    }
});
const ScrollBoxFirst = styled(Box)({
    direction: "rtl",
    "& div::-webkit-scrollbar": {
        width: "5px",
        background: "#DADADA",
    },
    "& div::-webkit-scrollbar-track": {
        border: '1px solid #DADADA',
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
        borderRadius: "10px",
        backgroundColor: "#DADADA"
    },
    "& div::-webkit-scrollbar-thumb": {
        background: "#2766DD",
        borderRadius: "10px"
    }
});
const DiologStyle = styled(Dialog)({
    "& .MuiDialog-paperWidthSm": {
        maxWidth: "1199px",
        minWidth: "1199px",
        borderRadius: "24px"
    },
    '@media (max-width:1340px)': {
        "& .MuiDialog-paperWidthSm": {
            minWidth: "unset",
        },
    },
})
const PercentBox = styled(Grid)({
    flexWrap: "unset",
    gap: "8px",
    marginTop: "20px",
    "@media (max-width: 960px)": {
        display: "grid"
    }
})
const TextFieldText = styled(Typography)({
    color: "#363636",
    fontFamily: "Asap" as "Asap",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "16px",
    letterSpacing: "0em",
    textAlign: "left" as "left",
    marginTop: "10px",
})
export const InnerBox = styled("div")({
    padding: "50px",
    background: "#FFFFFF",
    "@media (max-width: 768px)": {
        padding: "12px 16px"
    }
})
const InLinearProgress = styled(LinearProgress)({
    "& .MuiLinearProgress-barColorPrimary": {
        borderRadius: "4px",
        backgroundColor: "#0099FF"
    }
})
const OutLinearProgress = styled(LinearProgress)({
    "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#E3A917",
        borderRadius: "4px"
    }
})
const ListItem = styled(MenuItem)({
    color: "#1E1E1E",
    fontFamily: "Asap" as "Asap",
    fontSize: "14px",
    fontWeight: 600,
    '&:hover': {
        backgroundColor: '#D5E2FF',
    },
})
export const BarChartBox=styled("div")({
    borderRadius: "16px",
    border: "1px solid #CDCDCD",
    padding: "24px 60px 24px 60px",
    marginTop: "30px",
    "& .HistoricalGrid": {
        marginTop: "70px",
        display: "grid",
        justifyContent: "flex-end",
        "@media (max-width: 960px)": {
            justifyContent: "initial",
         },
    },
    "@media (max-width: 775px)": {
        padding:"24px 32px",
     },
     "@media (max-width: 500px)": {
        padding:"24px 15px",
     },
     "& .BarChartBoxTop": {
        display: "flex", flexWrap: "wrap" ,
        "@media (max-width: 775px)": {
            gap:"10px"
         },
     }
  })
 export const DateRangeGrid=styled(Grid)({
    display: "flex", 
    justifyContent: "flex-end",
    "@media (max-width: 775px)": {
        justifyContent: "flex-start",
        marginTop: "20px"
     },
  })
const CustomAutoSelete = styled(Autocomplete)({
    "& ..MuiAutocomplete-root":{
        color: "#1E1E1E",
        fontSize: "14px",
        fontWeight: 600,
        fontFamily: "Asap",
    },
    "& .MuiOutlinedInput-root": {
        cursor: 'pointer',
        color: "#1E1E1E",
        fontSize: "14px",
        fontWeight: 600,
        fontFamily: "Asap",
    },
    "& .MuiAutocomplete-popper":{
        color: "#1E1E1E",
        fontSize: "14px",
        fontWeight: 600,
        fontFamily: "Asap",
    },
    "& .MuiAutocomplete-clearIndicatorDirty": {
      display: "none"
    },
    "& .MuiAutocomplete-noOptions": {
        cursor: 'pointer',
        color: "#1E1E1E",
        fontSize: "14px",
        fontWeight: 600,
        fontFamily: "Asap",
    }
  })
const webStyle = {
    outerBox: {
        background: "#EFEFEF",
        padding: "45px 20px"
    },
    textFieldHeading: {
        color: "#363636",
        fontFamily: "Asap" as "Asap",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "16px",
        letterSpacing: "0em",
        textAlign: "left" as "left",
        marginBottom: "10px"
    },
    autoComplete:{
    width:"100%",
    ".MuiAutocomplete-clearIndicator":{
     display:"none"
    }
    },
    mainHeadingBox: {
        alignItems: "center",
        marginTop: "30px",
    },
    monthly: {
        color: "#1E1E1E",
        fontFamily: "Asap" as "Asap",
        fontSize: "28px",
        fontWeight: 700,
        lineHeight: "36px",
        letterSpacing: "0em",
        textAlign: "left" as "left"
    },
    subHeading: {
        color: "#363636",
        fontFamily: "Asap" as "Asap",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "16px",
        letterSpacing: "0em",
        textAlign: "left" as "left"
    },
    udatedText: {
        color: "#ACACAC",
        fontFamily: "Asap" as "Asap",
        fontSize: "12px",
        fontEeight: 500,
        lineHeight: "24px",
        marginLeft: "5px",
        textTransform:"uppercase" as "uppercase"
    },
    download: {
        color: "#656565",
        lineHeight: "22px",
        fontSize: "14px",
        fontWeight: 700,
        fontFamily: "Asap",
        marginRight: "5px",
    },
    inboundBox: {
        background: "#97CDFF",
        textAlign: "center" as "center",
        paddingBottom: "24px",
        paddingTop: "24px",
        borderRadius: "16px",
        borderLeft: "4px solid #0099FF"
    },
    percent: {
        color: "#000000",
        fontFamily: "Asap" as "Asap",
        fontSize: "32px",
        fontWeight: 700,
        lineHeight: "37px"
    },
    percentType: {
        color: "#000000",
        fontFamily: "Asap",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "18px",
        marginTop: "10px"
    },
    outboundBox: {
        background: "#F3CC67",textAlign: "center" as "center",
        paddingTop: "24px", paddingBottom: "24px",
        borderRadius: "16px",borderLeft: "4px solid #E3A917"
    },
    inoutText: {
        color: "#1E1E1E",fontFamily: "Asap" as "Asap",
        fontSize: "14px",fontWeight: 600,
        lineHeight: "18px",width: "95px",
        marginRight: "25px"
    },
    buttonGroup: {
        background: "#EEF2F5",borderRadius: "20px",
        height:"fit-content"
    },
    selectButton: {
        fontFamily: "Asap",fontSize: "14px",
        fontWeight: 500,borderRadius: "20px",
    },
    dropdown: {
        color: "#1E1E1E",fontFamily: "Asap" as "Asap",
        fontSize: "14px",fontWeight: 600,
        border: "1px solid #CDCDCD",borderRadius: "4px",
    },
    colorText: {
        color: "#0F172A",fontFamily: "Asap" as "Asap",
        fontSize: "14px",fontWeight: 400,
        lineHeight: "18px",marginRight: "10px",
        marginLeft: "10px"
    },
    monthltTextChart: {
        color: "#1E1E1E",fontFamily: "Asap" as "Asap",
        fontSize: "16px",fontWeight: 600,
        lineHeight: "18px"
    },
    monthltErrorbox:{
   width:"100%",
   padding:"5%"
    },
    monthltErrormessage:{
        color: "black",fontFamily: "Asap" as "Asap",
        fontSize: "28px",fontWeight: 700,
        textAlign:"center"as "center"
    },
    tradeText: {
        color: "#1E1E1E",fontFamily: "Asap" as "Asap",
        fontSize: "28px",fontWeight: 700,
        lineHeight: "32.09%"
    },
    outBoundstyle:{
     padding:"25%"
    },
    textStyle:{
        color: "black",fontFamily: "Asap" as "Asap",
        fontSize: "20px",fontWeight: 700,
        lineHeight: "32.09%",textAlign:"center" as "center"
    },
    tradeBox: {
        border: "1px solid #CDCDCD",borderRadius: "16px",
        padding: "20px 20px",marginTop: "25px"
    },
    tableHeadingBox: {
        background: "#E1E5EE", borderBottom: "3px solid #8A99BB",
        borderRadius: "8px",paddingTop: "24px",
        paddingBottom: "24px",marginTop: "30px"
    },
    tableRow: {
        border: "1px solid #CDCDCD",borderRadius: "8px",
        paddingTop: "24px",paddingBottom: "16px",
        marginTop: "10px",
    },
    rowText: {
        color: '#1E1E1E',fontFamily: "Asap" as "Asap",
        fontWeight: 500,fontSze: "14px",
        lineHeight: "16px"
    },
    ServicedataMessage:{
        fontWeight: 600,
        fontSize: "24px",
        fontFamily: "Asap" as "Asap",
        color: "black",
    },
    sellbox: {
        color: "#1E1E1E",
        background: "#FFE8E8",
        border: "1px solid #DB2525",
        padding: "6px 10px",
        width: "53px",
        fontWeight: 600,
        fontSize: "14px",
        fontFamily: "Asap" as "Asap",
        marginTop: "-6px",
        borderRadius: "4px"
    },
    agsText: {
        color: "#363636",
        fontFamily: "Asap" as " Asap",
        fontWeight: 400,
        fontSize: "14px",
        marginTop: "4px"
    },
    listItem: {
        color: "#1E1E1E",
        fontFamily: "Asap" as "Asap",
        fontSize: "14px",
        fontWeight: 600,
    },
    popHeading: {
        color: "#1E1E1E",
        fontWeight: 700,
        fontSize: "28px",
        fontFamily: "Asap" as "Asap",
        lineHeight: "32.09px",
        textAlign:"center" as "center"
    },
    listTrade: {
        color: "#ACACAC",
        fontFamily: "Asap" as "Asap",
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "32px",
        display:"flex",
        justifyContent:"center"
    },
    listContent: {
        color: "#363636",
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "24px",
        fontFamily: "ASap" as "Asap",
        marginTop: "20px"
    },
    rightPopHeading: {
        color: "#1E1E1E",
        fontSize: "24px",
        fontWeight: 600,
        fontFamily: "Asap" as "Asap",
        lineHeight: "27px",
        marginTop: "20px"
    },
    rightPopsub: {
        color: "#363636",
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "Asap" as "Asap",
        lineHeight: "24px",
        marginTop: "20px"
    },
    itemList: {
        display: "flex",
        justifyContent: "space-between",
        width: "188px"
    },
    percentageText: {
        position: 'absolute' as "absolute",
        top: 5, left: 10,
        color: '#FFFFFF',
        fontWeight: 400,
        fontFamily: "Asap" as "Asap",
        fontSize: "14px"
    },
    downloadBox: {
        display: "flex",
        padding: "10px",
        background: "#E2E2E2",
        borderRadius: "6px",
        cursor:"pointer"
    },
    clearFilter:{
        display:"flex",
        justifyContent:"end",
        marginTop:"2%"
    }
};

// Customizable Area End
